import { PipelineModel } from "../../gql-types.generated";
import { GridColumns, GridRowModel, GridOverlay, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { CircularProgress, Typography, Stack, styled } from '@mui/material';
import { TabDataGrid } from '../../util/SharedStyles';
import { useEffect, useState, useMemo } from "react";

const DialogDataGrid = styled(TabDataGrid)((props) => ({
    padding: '8px 0px !important',
    width: '100%',
    height: '400px',
}));

interface PipelinePickerListProps {
    availablePipelines: PipelineModel[];
    selectedPipelineId?: string;
    loading: boolean;
    rowSelection: (
        pipelineId: string
    ) => void;
    error?: Error | undefined;
}

const PipelinePickerList: React.FC<PipelinePickerListProps> = props => {
    const { availablePipelines, selectedPipelineId, loading, rowSelection, error } = props;

    const [pipelineRows, setPipelineRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [selectedPipeline, setSelectedPipeline] = useState<string>('');
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);

    const getPipelineRows = () => {
        if (availablePipelines && availablePipelines.length > 0) {
            return availablePipelines?.map((pipeline) => {
                const { id, description, isTemplate, transactionId } = pipeline;

                return {
                    _raw: pipeline,
                    description,
                    id,
                    isTemplate,
                    transactionId,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    useEffect(() => {
        setPipelineRows(getPipelineRows());
    }, [availablePipelines]);

    const pipelineColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 300,
                flex: 1,
                sortable: true
            }
        ], [],
    );

    useEffect(() => {
        if (selectedPipelineId) {
            if (pipelineRows && pipelineRows.length > 0 && selectedPipelineId) {
                setSelectedPipeline(selectedPipelineId);
                setSelectionModel([selectedPipelineId]);
            }
        }
    }, [pipelineRows, selectedPipelineId]);

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        if (availablePipelines?.length) {
            let currentRowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
            let previousRowId = selectionModel && selectionModel[0] ? selectionModel[0].toString() : undefined;
                
            if (currentRowId && currentRowId !== previousRowId) {
                setSelectedPipeline(currentRowId ?? '');
                setSelectionModel(currentSelectionModel);
                rowSelection(currentRowId ?? '');
            } else {
                setSelectedPipeline('');
                setSelectionModel([]);
                rowSelection('');
            }
        }
        
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'pipelines-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && availablePipelines?.length === 0 && (
                    <Typography variant="body2" align="center">
                        No Records found.
                    </Typography>
                )}
            </GridOverlay>
        );
    };

    return (
        <DialogDataGrid
            loading={availablePipelines === undefined || availablePipelines.length <= 0}
            headerHeight={38}
            rowHeight={52}
            aria-label="Pipelines List"
            hideFooter
            disableColumnMenu
            disableMultipleSelection
            rows={pipelineRows ?? []}
            columns={pipelineColumns}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            components={{
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                LoadingOverlay: loadingOverlay,
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                NoRowsOverlay: noRowsOverlay,
            }}
        />
    );
}

export default PipelinePickerList;