import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientPartnerTransactionMapInput, UpsertClientPartnerTransactionMapPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_MAP = gql`
    mutation upsertClientPartnerTransactionMap($input: UpsertClientPartnerTransactionMapInput!){
        upsertClientPartnerTransactionMap(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientPartnerTransactionMap = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        clientPartnerTransactionId: string;
        transactionMapId: string;
    }
): Promise<UpsertClientPartnerTransactionMapPayload | undefined> => {
    const { clientPartnerTransactionId, transactionMapId } = options as UpsertClientPartnerTransactionMapInput;
    const { data, errors } = await client.mutate<{ upsertClientPartnerTransactionMap: UpsertClientPartnerTransactionMapPayload }>({
        variables: {
            input: {
                clientPartnerTransactionId,
                transactionMapId,
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_MAP
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add a client partner transaction map.');
    }
    if (data && data.upsertClientPartnerTransactionMap) {
        return data.upsertClientPartnerTransactionMap;
    }

    return undefined;
};