import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, styled, Typography, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { IconAvatar } from '../../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import OutboundIcon from '../../../icons/outbound.svg';
import InboundIcon from '../../../icons/inbound.svg';
import { DeleteByIdPayload, NotificationFormatterModel, NotificationFormatterTransactionModel, RequestResult, UserRole, TransactionDirection, AddNotificationFormatterTransactionsInput } from "../../../gql-types.generated";
import { TabContainer, TabToolbar, TabContent, TabDataGridNoRowHover } from '../../../util/SharedStyles';
import CreateNewButton from '../../buttons/CreateNewButton';
import { viewerCanEdit } from '../../../util/ViewerUtility';
import DeleteDialog from '../../dialogs/DeleteDialog';
import NotificationFormatterTransactionPickerDialog from '../../dialogs/NotificationFormatterTransactionPickerDialog';




const TransactionsToolbar = styled(TabToolbar)((props) => ({
    padding: '0 !important',
    alignItems: 'center',
    height: '48px'
}));

const TransactionsContainer = styled(TabContainer)((props) => ({
    maxHeight: '256px',
}));

const TransactionsContent = styled(TabContent)((props) => ({
    height: 'calc(100% - 48px)',
}));

const TransactionsHeader = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.grayscale.dark
}));

interface gridProps {
    height: string;
}
const TransactionsDataGrid = styled(TabDataGridNoRowHover)<gridProps>((props) => ({
    padding: '0 0 12px 0 !important',
    height: props.height
}));

interface FormatterTransactionsListProps {
    viewerRole: UserRole | undefined;
    formatter: NotificationFormatterModel | undefined;
    addTransactions: (
        inputData: AddNotificationFormatterTransactionsInput,
    ) => void;
    deleteTransaction: (
        id: string,
    ) => void;
    addTransactionsResult?: RequestResult | undefined;
    deleteTransactionStatus?: DeleteByIdPayload | undefined;
    error?: Error | undefined;
    isLoading?: boolean;
    clearError: () => void;
}

const NotificationFormatterDialogTransactionList: React.FC<FormatterTransactionsListProps> = props => {
    const { viewerRole, formatter, addTransactions, deleteTransaction, addTransactionsResult, deleteTransactionStatus, error, clearError, isLoading = false } = props;
    const formatterId = formatter?.id;
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openTransactionPicker, setOpenTransactionPicker] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState<NotificationFormatterTransactionModel | undefined>(undefined); 
    const [formatterTransactions, setFormatterTransactions] = useState<NotificationFormatterTransactionModel[] | undefined>(undefined); 
    const [transactionRows, setTransactionRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'type',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingTransaction, setIsSavingOrDeletingTransaction] = useState(false);
    const canEdit = viewerCanEdit(viewerRole);

    useEffect(() => {
        setFormatterTransactions(formatter?.notificationFormatterTransactions as NotificationFormatterTransactionModel[]);
    }, [formatter?.notificationFormatterTransactions]);

    const getGridHeight = useCallback(() => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no transactions, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (formatterTransactions) {
            if (formatterTransactions.length === 1) {
                return '108px';
            } else if (formatterTransactions.length === 2) {
                return '156px';
            } else if (formatterTransactions.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    }, [formatterTransactions]);
   
    const getTransactionRows = useCallback(() => {
        if (formatterTransactions && formatterTransactions.length > 0) {
            return formatterTransactions.map((formatterTransaction: NotificationFormatterTransactionModel) => {
                const transactionNode = formatterTransaction?.transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { name, direction, description } = transactionNode;
                
                return {
                    _raw: transactionNode,
                    id: formatterTransaction?.id, // want the parent id for deleting
                    direction,
                    type: name,
                    description,
                    
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }, [formatterTransactions]);

    useEffect(() => {
        setTransactionRows(getTransactionRows());
        setGridHeight(getGridHeight());
    }, [formatterTransactions, getTransactionRows, getGridHeight]);

    useEffect(() => {
        if (addTransactionsResult) {
            if (addTransactionsResult === RequestResult.Success) {
                onTransactionPickerDialogClose();
            }
            setIsSavingOrDeletingTransaction(false);
            // error will come through props and flow through to Transactions Dialog don't close transaction, leave open to display error
        }

    }, [addTransactionsResult]);

    useEffect(() => {
        if (deleteTransactionStatus) {
            if (deleteTransactionStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
            } else if (deleteTransactionStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteTransactionStatus?.message as string,);
            }
            setIsSavingOrDeletingTransaction(false);
        }
    }, [deleteTransactionStatus]);

    const getSelectedRowTransaction = useCallback((rowId: string) => () => {
        if (rowId && formatterTransactions?.length) {
            let transaction = formatterTransactions.find(transaction => transaction.id === rowId);
            return transaction;
        }
        return undefined;
    }, [formatterTransactions]);

    const deleteTransactionHandler = useCallback((rowId: string) => () => {
        let transaction = getSelectedRowTransaction(rowId);
        if (transaction) {
            setSelectedTransaction(transaction);
            setOpenDelete(true);
        }
    }, [getSelectedRowTransaction]);

    const onAddTransaction = () => {
        setOpenTransactionPicker(true);
    };

    const onTransactionPickerDialogClose = () => {
        setOpenTransactionPicker(false);
    };

    const onTransactionPickerDialogSave = (inputData: AddNotificationFormatterTransactionsInput) => {
        addTransactions(inputData);
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected transaction
        if (selectedTransaction) {
            setIsSavingOrDeletingTransaction(true);
            deleteTransaction(selectedTransaction.id as string);
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'transactions-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };
    
    // using DataGrid, define each column
    const transactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'direction',
                headerName: 'DIRECTION',
                minWidth: 120,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                align: 'left',
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const directionIcon = (value === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;

                    return (
                        <Tooltip title={value}>
                            <IconAvatar aria-label={value?.toLowerCase()}>
                                {directionIcon}
                            </IconAvatar>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'type',
                headerName: 'NAME',
                minWidth: 100,
                sortable: true,
            },
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 150,
                flex: 1,
                sortable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteTransactionHandler, canEdit],
    );

    return (
        <TransactionsContainer>

            <TransactionsToolbar justify="space-between" noborder={+true}>
                <TransactionsHeader>Transactions</TransactionsHeader>
                {(canEdit) &&
                    <CreateNewButton
                        text="Add Transaction"
                        tooltip="Click here to add new transactions"
                        variant="text"
                        onClick={onAddTransaction}
                        disabled={isSavingOrDeletingTransaction || isLoading}
                        data-cy="add-new-transaction"
                    />
                }
            </TransactionsToolbar>

            <TransactionsContent>
                {((formatterTransactions !== undefined && formatterTransactions?.length > 0) || isSavingOrDeletingTransaction) &&
                    <TransactionsDataGrid
                        loading={isSavingOrDeletingTransaction || isLoading || formatterTransactions === undefined}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="Transactions List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableSelectionOnClick
                        rows={transactionRows}
                        columns={transactionColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </TransactionsContent>

            <NotificationFormatterTransactionPickerDialog
                isOpen={openTransactionPicker}
                formatterId={formatterId}
                onClose={onTransactionPickerDialogClose}
                onSave={onTransactionPickerDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedTransaction?.id ?? ''}
                heading={'Delete Notification Formatter Transaction'}
                message={'Are you sure you want to delete \'' + selectedTransaction?.transaction?.name + '\' from this Formatter?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </TransactionsContainer>
    );

}

export default NotificationFormatterDialogTransactionList;
