import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AddressModel, BusinessAreaModel, ClientModel, ClientStatus, EdiXmlConfigModel, EmailModel, EntityType, ErpProductModel, NoteModel, PhoneModel, RequestResult, UpsertClientInput, UpsertNoteInput, NotificationFormatterModel, MissingAcknowledgementNotificationMode } from '../../gql-types.generated';
import { Button, Grid, Typography } from '@mui/material';
import ClientIcon from '@mui/icons-material/Work';
import DeleteIcon from '@mui/icons-material/Delete';
import CompleteIcon from '@mui/icons-material/CheckCircle';
import { ActiveStatusChip, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, IntermediateStatusChip, StatusChip, SuspendedStatusChip, TabHeader, TabLabel, DetailWrapValue } from '../../util/SharedStyles';

import { AddressToDisplayString, getWebsiteLink, getAvatarComponent, getLastModifiedString, useTitle, capitalizeWithUnderscoreRemoval } from '../../util/Common';
import { Viewer, emptyGuid, ToastSeverity, clientDetailsLocalStorageTabKey, clientDetailsNotificationTabIndex } from '../../util/Constants';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';
import { getPrimaryAddressFromArray, getPrimaryEmailFromArray, getPrimaryPhoneFromArray } from '../../util/PrimaryFromModelArray';
import { getEntityFavorite, viewerCanEdit, getLocalStorageItem, setLocalStorageItem, viewerIsAdmin } from '../../util/ViewerUtility';

import { upsertClient, deleteClient, fetchClientById, upsertClientNotes, deleteClientNote, fetchClientTransactionsByClientId } from './ClientDetailsActions';
import { captureUpsertClientStatus, captureDeleteClientStatus, clearError, clearState, selectClient, selectContactInfo, selectError, selectTradingPartners, selectUpsertClientStatus, selectDeleteClientStatus, selectClientNotes, selectUpsertClientNoteStatus, selectDeleteClientNoteStatus, captureUpsertClientNoteStatus, captureDeleteClientNoteStatus, selectClientAttachments, selectDeleteClientPartnerStatus, captureDeleteClientPartnerStatus, selectClientTransactions } from './ClientDetailsSlice';
import { upsertRecentData } from '../EDIContainer/EDIContainerActions';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import TabPanel from '../../components/TabPanel';
import ClientContactList from '../../components/lists/ClientContactList';
import ClientTradingPartnersList from '../../components/lists/ClientTradingPartnerList';
import FavoriteToggleButton from '../../components/buttons/FavoriteToggleButton';
import EditButton from '../../components/buttons/EditButton';
import ClientDialog from '../../components/dialogs/ClientDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ClientAttachmentList from '../../components/lists/attachments/ClientAttachmentList';
import NotesList from '../../components/lists/NotesList';
import ConfigurationFile from '../../components/ConfigurationFile';
import ClientTransactionList from '../../components/lists/transactions/ClientTransactionList';
import ClientBillingAggregatesList from '../../components/lists/ClientBillingAggregatesList';
import DetailsPage from '../../components/DetailsPage';
import ClientNotificationsRecipientsList from '../../components/lists/ClientNotificationRecipientsList';
import CompleteOnboardingDialog from '../../components/dialogs/CompleteOnboardingDialog';

interface ClientDetailsProps {
    viewer: Viewer | undefined;
    openInEditMode?: boolean;
}

const ClientDetails: React.FC<ClientDetailsProps> = (props) => {
    // clientId will be passed in through router
    const { clientId } = useParams();
    const { viewer, openInEditMode = false } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const defaultCurrency = 'USD';
    const canEdit = viewerCanEdit(viewer);
    const isAdmin = viewerIsAdmin(viewer);
    
    const [clientStatus, setClientStatus] = useState<ClientStatus | undefined>(undefined);
    const [clientName, setClientName] = useState<string | undefined>(undefined);
    const [clientCode, setClientCode] = useState<string>('');
    const [internalClientCode, setInternalClientCode] = useState<string>('');
    const [productionDate, setProductionDate] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState(defaultCurrency);
    const [billingDate, setBillingDate] = useState<string>('');
    const [billingGroup, setBillingGroup] = useState<string>('');
    const [tabValue, setTabValue] = useState(0);
    const [openModify, setOpenModify] = useState(false);
    const [openModifyInitiallySet, setOpenModifyInitiallySet] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [primaryAddress, setPrimaryAddress] = useState<AddressModel | undefined>(undefined);
    const [businessArea, setBusinessArea] = useState<BusinessAreaModel | undefined>(undefined);
    const [erpProduct, setErpProduct] = useState<ErpProductModel | undefined>(undefined);
    const [primaryEmail, setPrimaryEmail] = useState<EmailModel | undefined>(undefined);
    const [primaryPhone, setPrimaryPhone] = useState<PhoneModel | undefined>(undefined);
    const [favIconErrored, setFavIconErrored] = useState<boolean>(false);
    const [xmlConfig, setXmlConfig] = useState<EdiXmlConfigModel | undefined>(undefined);
    const [notificationTime, setNotificationTime] = useState<string>('');
    const [errorFormatter, setErrorFormatter] = useState<NotificationFormatterModel | undefined>(undefined);
    const [notificationMode, setNotificationMode] = useState<MissingAcknowledgementNotificationMode | undefined>(undefined);
    const [isCompleteOnboardingOpen, setIsCompleteOnboardingOpen] = useState(false);
    

    const upsertClientStatus = useAppSelector(selectUpsertClientStatus);
    const deleteClientStatus = useAppSelector(selectDeleteClientStatus);
    const error = useAppSelector(selectError);
    const clientInfo = useAppSelector(selectClient);
    const clientContactInfo = useAppSelector(selectContactInfo);
    const clientTradingPartners = useAppSelector(selectTradingPartners);
    const clientNotes = useAppSelector(selectClientNotes);
    const upsertNoteStatus = useAppSelector(selectUpsertClientNoteStatus);
    const deleteNoteStatus = useAppSelector(selectDeleteClientNoteStatus);
    const attachments = useAppSelector(selectClientAttachments);
    const clientTransactions = useAppSelector(selectClientTransactions);
    const deleteClientPartnerStatus = useAppSelector(selectDeleteClientPartnerStatus);

    const localStorageTabIndexName = clientDetailsLocalStorageTabKey(clientId ?? '');

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        // use clientId to get clientDetails on render
        if (clientId) {
            dispatch(fetchClientById(clientId));
            dispatch(fetchClientTransactionsByClientId(clientId));
        }
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh client when a successful mutation occurs
        if (upsertClientStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertClientStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertClientStatus());
            // close the modify dialog
            onClientDialogClose();
            // we should always have the clientId if we upserted, but check to prevent compile error
            if (clientId) {
                if (clientInfo) {
                    // Add the client to the recent viewed list
                    dispatch(upsertRecentData(EntityType.Client, clientInfo.id, clientInfo.name as string));
                }
                dispatch(fetchClientById(clientId));
            }
        }
    }, [upsertClientStatus?.result]);

    useEffect(() => {
        if (deleteClientStatus?.result === RequestResult.Success) {
            // close dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteClientStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // send user back to where they came from
            navigate(-1);
        }
        else if (deleteClientStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteClientStatus.message as string);
        }
    }, [deleteClientStatus?.result])

    useEffect(() => {
        if (deleteClientPartnerStatus) {
            if (deleteClientPartnerStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteClientPartnerStatus.message as string,
                    severity: ToastSeverity.Success
                }));
                dispatch(captureDeleteClientPartnerStatus());
                if (clientId) {
                    dispatch(fetchClientById(clientId));
                }
            }
        }
    }, [deleteClientPartnerStatus?.result]);

    useEffect(() => {
        if (upsertNoteStatus?.result === RequestResult.Success || deleteNoteStatus?.result === RequestResult.Success) {
            if (upsertNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            dispatch(captureUpsertClientNoteStatus());
            dispatch(captureDeleteClientNoteStatus());
            if (clientId) {
                if (clientInfo) {
                    // Add the client to the recent viewed list
                    dispatch(upsertRecentData(EntityType.Client, clientInfo.id, clientInfo.name as string));
                }
                dispatch(fetchClientById(clientId));
            }
        }
    }, [upsertNoteStatus?.result, deleteNoteStatus?.result]);

    useEffect(() => {
        if (clientInfo) {
            setClientName(clientInfo.name as string);
            
            if (clientInfo.clientCode) {
                setClientCode(clientInfo.clientCode);
            }
            if (clientInfo.internalClientCode) {
                setInternalClientCode(clientInfo.internalClientCode);
            }
            if (clientInfo.productionDate) {
                setProductionDate(getShortDateString(clientInfo.productionDate));
            }
            if (clientInfo.billing?.description) {
                let billingDesc = clientInfo.billing?.description;
                let billingDisplay = clientInfo.billing?.currencyCode ? `${billingDesc} (${clientInfo.billing?.currencyCode})` : billingDesc;
                setBillingGroup(billingDisplay);
            } else {
                setBillingGroup("");
            }
            if (clientInfo.billingStartDate) {
                setBillingDate(getShortDateString(clientInfo.billingStartDate));
            } else {
                setBillingDate("");
            }

            if (clientInfo.defaultCurrencyCode) {
                setCurrencyCode(clientInfo.defaultCurrencyCode);
            }

            setClientStatus(clientInfo.statusCode ? clientInfo.statusCode : undefined);
                        
            setBusinessArea(clientInfo.businessArea ? clientInfo.businessArea : undefined);
            
            setErpProduct(clientInfo.erpProduct ? clientInfo.erpProduct : undefined);
            
            if (clientInfo.bulkNotificationTime) {
                setNotificationTime(getTimeString(clientInfo.bulkNotificationTime));
            } else {
                setNotificationTime("");
            }
            
            setErrorFormatter(clientInfo.errorFormatter ? clientInfo.errorFormatter : undefined);
                        
            if (clientInfo.addresses && clientInfo.addresses.length > 0) {
                setPrimaryAddress(getPrimaryAddressFromArray(clientInfo.addresses as AddressModel[]));
            }
            if (clientInfo.emails && clientInfo.emails.length > 0) {
                setPrimaryEmail(getPrimaryEmailFromArray(clientInfo.emails as EmailModel[]));
            }
            if (clientInfo.phones && clientInfo.phones.length > 0) {
                setPrimaryPhone(getPrimaryPhoneFromArray(clientInfo.phones as PhoneModel[]));
            }

            setNotificationMode(clientInfo.missingAcknowledgementNotificationMode ? clientInfo.missingAcknowledgementNotificationMode : undefined);
            
            setXmlConfig(clientInfo.ediXmlConfig ? clientInfo.ediXmlConfig : undefined);

            // restore active tab to be last accessed tab that was saved off
            let tabIndex = getLocalStorageItem(viewer, localStorageTabIndexName);
            if (tabIndex != null && +tabIndex >= 0) {
                setTabValue(+tabIndex);
            }
            // Add the client to the recent viewed list
            dispatch(upsertRecentData(EntityType.Client, clientInfo.id, clientInfo.name as string));

            // need the clientInfo to be loaded before setting if the dialog should be open
            // and only want to set it once so that it doesn't keep opening the dialog when
            // the dialog is closed
            if (!openModifyInitiallySet && canEdit) {
                setOpenModify(openInEditMode);
                setOpenModifyInitiallySet(true);
            }
        }
    }, [clientInfo, canEdit, openInEditMode]);

    useTitle(clientName);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onClientDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error and ClientContact on close.
        handleClearError();
        // Refresh list to bring in potential updates
        dispatch(fetchClientById(clientId as string));
    };

    const refreshClientData = async () => {
        handleClearError();
        await dispatch(fetchClientById(clientId as string));
        await dispatch(fetchClientTransactionsByClientId(clientId as string));
    };

    const refreshClientTransactionsData = () => {
        handleClearError();
        dispatch(fetchClientTransactionsByClientId(clientId as string));
    };

    const onClientDialogSave = (
        clientInput: UpsertClientInput
    ) => {
        dispatch(
            upsertClient(clientInput)
        );
    };

    const onNotesUpsert = (notes: NoteModel[]) => {
        if (notes && clientInfo && clientInfo.tenantId && clientInfo.id) {
            const noteInput = notes as UpsertNoteInput[];
            dispatch(
                upsertClientNotes(
                    clientInfo.tenantId,
                    clientInfo.id,
                    noteInput
                )
            );
        }
    };

    const onNoteDelete = (id: string) => {
        if (id) {
            dispatch(deleteClientNote(id));
        }
    };

    const onNotifyClick = () => {
        setIsCompleteOnboardingOpen(true);
    };

    const handleCloseOnboardingCompleteDialog = () => {
        setIsCompleteOnboardingOpen(false);
    };

    let contactLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Contacts</TabLabel>
            {clientContactInfo && clientContactInfo.length > 0 && (
                <CountBox>{clientContactInfo.length}</CountBox>
            )}

        </Grid>
    );

    let tpLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Trading Partners</TabLabel>
            {clientTradingPartners && clientTradingPartners.length > 0 && (
                <CountBox>{clientTradingPartners.length}</CountBox>
            )}
        </Grid>
    );

    let transactionsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Transactions</TabLabel>
            {clientTransactions && clientTransactions.length > 0 && (
                <CountBox>{clientTransactions.length}</CountBox>
            )}
        </Grid>
    )

    let attachmentsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Attachments</TabLabel>
            {attachments && attachments.length > 0 && (
                <CountBox>{attachments.length}</CountBox>
            )}
        </Grid>
    );

    let notesLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Notes</TabLabel>
            {clientNotes && clientNotes.length > 0 && (
                <CountBox>{clientNotes.length}</CountBox>
            )}
        </Grid>
    );

    const handlefavIconError = () => {
        setFavIconErrored(true);
    }

    const getClientDetails = () => {
        if (clientInfo) {
            const addressString = AddressToDisplayString(primaryAddress, true);
            return (

                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Company Address</Typography>
                            <Typography variant='body1' >{addressString}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Internal Client Code</Typography>
                            <Typography variant='body1' >{internalClientCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Business Area</Typography>
                            <Typography variant='body1' >{businessArea?.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >ERP Product</Typography>
                            <Typography variant='body1' >{erpProduct?.name}</Typography>
                        </Grid>
                        {clientInfo.website && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Website</Typography>
                                <DetailWrapValue variant='body1' >{getWebsiteLink(clientInfo?.website)}</DetailWrapValue>
                            </Grid>
                        )}
                        {clientInfo.isaQualifierCode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >ISA Qualifier</Typography>
                                <Typography variant='body1' >{clientInfo.isaQualifierCode}</Typography>
                            </Grid>
                        )}
                        {primaryEmail?.email && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Email</Typography>
                                <DetailWrapValue variant='body1' >{primaryEmail.email}</DetailWrapValue>
                            </Grid>
                        )}
                        {primaryPhone?.phoneNumber && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Phone</Typography>
                                <Typography variant='body1' >{primaryPhone.phoneNumber}</Typography>
                            </Grid>
                        )}
                        {clientInfo.productionDate && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Production Date</Typography>
                                <Typography variant='body1' >{productionDate}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={1}>
                            <Typography variant='caption' >Default Currency Code</Typography>
                            <Typography variant='body1' >{currencyCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Billing Group</Typography>
                            <Typography variant='body1' >{billingGroup}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Billing Start Date</Typography>
                            <Typography variant='body1' >{billingDate}</Typography>
                        </Grid>
                        {clientInfo.tenantId && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Tenant ID</Typography>
                                <Typography variant='body1' >{clientInfo.tenantId}</Typography>
                            </Grid>
                        )}
                        
                        <Grid item xs={1}>
                            <Typography variant='caption' >Bulk Notification Time</Typography>
                            <Typography variant='body1' >{notificationTime}</Typography>
                        </Grid>
                    
                    
                        <Grid item xs={1}>
                            <Typography variant='caption' >Error Formatter</Typography>
                            <Typography variant='body1' >{errorFormatter?.description}</Typography>
                        </Grid>

                        {notificationMode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Missing Acknowledgement Notification Mode</Typography>
                                <Typography variant='body1' >{capitalizeWithUnderscoreRemoval(notificationMode, " ")}</Typography>
                            </Grid>
                        )}

                        {clientInfo.localeCode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Locale</Typography>
                                <Typography variant='body1' >{clientInfo.localeCode}</Typography>
                            </Grid>
                        )}
                       
                        {(clientInfo.lastModifiedByName || clientInfo.lastModifiedTime) && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Last Modified</Typography>
                                <Typography variant='body1' >{getLastModifiedString(clientInfo.lastModifiedByName, clientInfo.lastModifiedTime)}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

            );
        }
    };

    const getTabbedContainer = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable client tabs"
                    >
                        {/*<TabHeader label="Dashboard" id='vertical-tab-0' />*/}
                        <TabHeader label={contactLabel} id='vertical-tab-client-details-contacts' />
                        <TabHeader label={tpLabel} id='vertical-tab-client-details-trading-partners' />
                        <TabHeader label={transactionsLabel} id='vertical-tab-client-details-transactions' />
                        <TabHeader label={attachmentsLabel} id='vertical-tab-client-details-attachments' />
                        <TabHeader label={notesLabel} id='vertical-tab-client-details-notes' />
                        <TabHeader label="CONFIG" id='vertical-tab-client-details-config' />
                        <TabHeader label="BILLING" id='vertical-tab-client-details-billing' />
                        <TabHeader label="NOTIFICATIONS" id='vertical-tab-client-details-notifications' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    {/*<TabPanel value={tabValue} index={0}>[TODO: Add Transaction Reports]</TabPanel>*/}
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        <ClientContactList
                            viewerRole={viewer?.role}
                            clientContacts={clientContactInfo}
                            clientId={clientInfo?.id}
                            refreshClientData={refreshClientData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={1}>
                        <ClientTradingPartnersList
                            viewerRole={viewer?.role}
                            clientId={clientInfo?.id}
                            clientTradingPartners={clientTradingPartners}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={2}>
                        <ClientTransactionList
                            viewerRole={viewer?.role}
                            client={clientInfo as ClientModel}
                            clientTransactions={clientTransactions}
                            refreshClientData={refreshClientTransactionsData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={3}>
                        <ClientAttachmentList
                            viewerRole={viewer?.role}
                            clientId={clientInfo?.id}
                            attachments={attachments}
                            refreshClientData={refreshClientData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={4}>
                        <NotesList
                            viewerRole={viewer?.role}
                            notes={clientNotes}
                            parentId={clientInfo?.id}
                            saveNotes={onNotesUpsert}
                            deleteNote={onNoteDelete}
                            upsertNoteResult={upsertNoteStatus?.result}
                            deleteNoteStatus={deleteNoteStatus}
                            error={error}
                            clearError={handleClearError}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={5}>
                        <ConfigurationFile
                            file={xmlConfig}
                            clientId={clientInfo?.id}
                            viewerRole={viewer?.role}
                            refreshClientData={refreshClientData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={6}>
                        <ClientBillingAggregatesList internalClientCode={clientInfo?.internalClientCode ?? ''} />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={clientDetailsNotificationTabIndex}>
                        <ClientNotificationsRecipientsList viewerRole={viewer?.role} clientId={clientInfo?.id} />
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );

    };

    const getDialogs = () => [
        <ClientDialog
            isOpen={openModify}
            clientInfo={clientInfo}
            onClose={onClientDialogClose}
            onSave={onClientDialogSave}
            refreshClientData={refreshClientData}
            error={error}
            key="client-dialog"
        />,
        <DeleteDialog
            isOpen={openDelete}
            heading={'Delete Client'}
            message={`Are you sure you want to delete '${clientName}'?`}
            id={clientInfo?.id as string}
            onConfirm={handleDeleteClientConfirm}
            onReject={onDeleteDialogClose}
            errorMessage={deleteErrorMessage}
            key="delete-dialog"
        />,
        <CompleteOnboardingDialog
            isOpen={isCompleteOnboardingOpen}
            tenantId={clientInfo?.tenantId as string}
            onClose={handleCloseOnboardingCompleteDialog}
            key="onboarding-complete-dialog"
        />
    ];

    const getToolbarButtons = () => {
        var buttonsArray = [];
        if (isAdmin && clientStatus === ClientStatus.Onboarding) {
            buttonsArray.push(
                <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CompleteIcon />}
                    onClick={onNotifyClick}
                    key="notify-button"
                    title='Notify Onboarding Complete'
                >
                    Notify Complete
                </Button>
            );
        }
        if (canEdit) {
            buttonsArray.push(
                <Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    disabled={!canDeleteClient}
                    onClick={onDeleteClient}
                    key="delete-button"
                >
                    Delete
                </Button>
            );
        }
        return buttonsArray;
    };

    //edit current client
    const onEditClick = () => {
        setOpenModify(true);
    };

    const onDeleteClient = () => {
        dispatch(captureDeleteClientStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeleteClientConfirm = () => {
        if (clientId) {
            dispatch(deleteClient(clientId));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(clearError());
        dispatch(captureDeleteClientStatus());
        setDeleteErrorMessage('');
    };

    const getFavorite = () => {
        // call utility method to get if this client is a favorite
        return getEntityFavorite(viewer?.favorites, clientId, EntityType.Client);
    };
    
    let canDeleteClient = false;
    // only allow deletion of client if tenantId is blank or set to empty guid
    // (if blank, it gets set to empty guid on backend)
    if (clientInfo) {
        let tenantId = clientInfo.tenantId ?? "";
        if (tenantId === "" || tenantId === emptyGuid) {
            canDeleteClient = true;
        }
    }

    let statusChip: undefined | JSX.Element;
    if (clientStatus !== undefined) {
        if (clientStatus === ClientStatus.Active) {
            statusChip = <ActiveStatusChip label="Active" key="status-chip" />;
        } else if (clientStatus === ClientStatus.Inprocess) {
            statusChip = <IntermediateStatusChip label="Inprocess" key="status-chip" />;
        } else if (clientStatus === ClientStatus.Onboarding) {
            statusChip = <IntermediateStatusChip label="Onboarding" key="status-chip" />;
        } else if (clientStatus === ClientStatus.Suspended) {
            statusChip = <SuspendedStatusChip label="Suspended" key="status-chip" />;
        } else {
            statusChip = <StatusChip label="Inactive" key="status-chip" />;
        }
    }

    useEffect(() => {
        setDetailsRecordLoaded(!!clientInfo);
        if (clientInfo) {
            setDetailsPageTitle(clientName);
            setDetailsAV(getAvatarComponent(
                clientInfo?.website,
                favIconErrored,
                clientName,
                "Client",
                <ClientIcon />,
                handlefavIconError
            ));
            setDetailsHeader(clientName);
            setDetailsSubHeader(clientCode);
            setDetailsChips(statusChip ? [statusChip] : undefined);
            setDetailsFavoriteButton(<FavoriteToggleButton
                entityId={clientId as string}
                entityName={clientName ?? ''}
                entityType={EntityType.Client}
                favorite={getFavorite()}
            ></FavoriteToggleButton>);
            setDetailsToolbarButtons(getToolbarButtons());
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Client button"
                    data-cy="edit-client-button"
                />);
            } else {
                setDetailsEditButton(undefined);
            }
        }
    }, [clientInfo, clientName, deleteErrorMessage, error, favIconErrored, clientCode, clientId, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getClientDetails}
            getTabbedContainer={getTabbedContainer}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    )
};

export default ClientDetails;