import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StateModel } from "../../gql-types.generated";

interface StateFieldProps {
    selectedCountryCode?: string | undefined;
    stateList: StateModel[] | undefined;
    disabled: boolean;
    required: boolean;
    labelId: string;
    ariaLabelledBy: string;
    value?: string;
    onChange: (event: SelectChangeEvent<string | null>) => void;
}

const StateField: React.FC<StateFieldProps> = props => {
    const { selectedCountryCode, stateList, disabled, required, labelId, ariaLabelledBy, value, onChange } = props;

    const getStateOptions = () => {
        if (selectedCountryCode && stateList && stateList.length) {
            return (
                stateList.map((state: StateModel) => {
                    if (state.countryCode && state.countryCode.trim() === selectedCountryCode?.trim()) {
                        return (
                            <MenuItem
                                key={state.id}
                                value={state.id}
                            >
                                {state.description}
                            </MenuItem>
                        );
                    } else {
                        return null;
                    }
                })
            );
        }
        return null;
    };

    const StatesMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };

    return (
        <Select
            labelId={labelId}
            aria-labelledby={ariaLabelledBy}
            value={value}
            onChange={onChange}
            disabled={disabled}
            data-cy="statelist"
            MenuProps={StatesMenuProps}
            required={required}
        >
            {getStateOptions()}
        </Select>
    );
};

export default StateField;