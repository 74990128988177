import { gqlClient } from "../../components/AppProvider";
import { GetCounterInput, PagingResultModelOfCounterModel, RequestResult, UpsertCounterInput } from "../../gql-types.generated";
import { mutationDeleteEntity } from "../../gql/MutationDeleteEntity";
import { mutationUpsertCounter } from "../../gql/MutationUpsertCounter";
import { queryCounterList } from "../../gql/QueryCounterList";
import { AppDispatch, AppThunk } from "../../store";
import { getErrorMessage } from "../../util/Common";
import { captureUpsertCounterStatus, captureDeleteCounterStatus, fetchError, incrementCounterRequestInFlight, fetchCounterListSuccess, decrementCounterRequestsInFlight } from "./CounterTableSlice";

export const fetchCountersList = (
    filters?: GetCounterInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementCounterRequestInFlight());
    try {
        const counterTable = await queryCounterList(gqlClient, filters);
        if (counterTable) {
            dispatch(fetchCounterListSuccess(counterTable as PagingResultModelOfCounterModel));
        }
        dispatch(decrementCounterRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementCounterRequestsInFlight());
    }
}


export const upsertCounter = ( counterInput: UpsertCounterInput) :  AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        const upsertStatus = await mutationUpsertCounter(gqlClient,{
            counterInput
        });
        if (upsertStatus) {
            if (upsertStatus.result === RequestResult.Fail) {
                dispatch(fetchError({ message: upsertStatus.message } as Error));
                getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
            }
            dispatch(captureUpsertCounterStatus(upsertStatus));
         }
    } catch (e) {
        dispatch(fetchError(e as Error));
    }
};

export const deleteCounter = (id: string): AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        const deleteStatus = await mutationDeleteEntity(gqlClient, {
            id,
            entityName: "Counter",
        });
        if (deleteStatus) {
            if (deleteStatus.result === RequestResult.Fail) {
                getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
            }
            dispatch(captureDeleteCounterStatus(deleteStatus));
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
    }
};