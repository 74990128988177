import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { ClientNotificationRecipientsModel, GetClientNotificationRecipientsInput, GetClientNotificationRecipientsQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENT_NOTIFICATION_RECIPIENTS = gql`
    query getClientNotificationRecipients($input: GetClientNotificationRecipientsInput!) {
        clientNotificationRecipients (input: $input) {
            nodes {
                id
                description
                notificationType
                recipients
                receivesNotifications
                receivesErrors
            }
        }
    }
`;

export const queryClientNotificationRecipients = async (
    client: ApolloClient<NormalizedCacheObject>,
    input: GetClientNotificationRecipientsInput
) => {
    const { clientId, description, notificationType, receivesErrors, receivesNotifications, recipients } = input as GetClientNotificationRecipientsInput;
    const { data, errors } = await client.query<GetClientNotificationRecipientsQuery>({
        variables: {
            input: {
                clientId,
                description,
                notificationType,
                receivesErrors,
                receivesNotifications,
                recipients
            }
        },
        query: QUERY_CLIENT_NOTIFICATION_RECIPIENTS,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the ClientNotificationRecipients query.');
    }

    return data?.clientNotificationRecipients?.nodes;
}