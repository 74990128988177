import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EdiStaticFileModel, PagingResultModelOfEdiStaticFileModel, UpsertEdiStaticFilePayload, DeleteByIdPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    staticFilesPagingResult?: PagingResultModelOfEdiStaticFileModel;
    staticFileList?: EdiStaticFileModel[];
    staticFileRequestsInFlight: number;
    upsertStaticFileStatus?: UpsertEdiStaticFilePayload;
    deleteStaticFileStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    staticFilesPagingResult: undefined,
    staticFileList: undefined,
    staticFileRequestsInFlight: 0,
    upsertStaticFileStatus: undefined,
    deleteStaticFileStatus: undefined,    
};

export const slice = createSlice({
    name: 'ediStaticFiles',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.staticFilesPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.staticFileList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.staticFileRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.upsertStaticFileStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteStaticFileStatus = undefined;
        },

        fetchStaticFileListSuccess: (state, action: PayloadAction<PagingResultModelOfEdiStaticFileModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.staticFilesPagingResult = action.payload;
            let files = state.staticFileList;
            if (!files || !state.staticFilesPagingResult.cursor?.previousPage) {
                files = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!files?.some(i => i.id === node?.id)) {
                        files?.push(node as EdiStaticFileModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.staticFileList = files;
        },

        incrementStaticFileRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.staticFileRequestsInFlight += 1;
        },

        decrementStaticFileRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.staticFileRequestsInFlight > 0) state.staticFileRequestsInFlight -= 1;
        },

        captureUpsertStaticFileStatus: (state, action: PayloadAction<UpsertEdiStaticFilePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertStaticFileStatus = action.payload;
        },

        captureDeleteStaticFileStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteStaticFileStatus = action.payload;
        },

        
    }
});

export const selectError = (state: RootState): Error | undefined => state.ediStaticFiles.error;
export const selectStaticFilePagingResult = (state: RootState): PagingResultModelOfEdiStaticFileModel | undefined => state.ediStaticFiles.staticFilesPagingResult;
export const selectStaticFileList = (state: RootState): EdiStaticFileModel[] | undefined => state.ediStaticFiles.staticFileList;
export const selectRequestsInFlight = (state: RootState): number => state.ediStaticFiles.staticFileRequestsInFlight;
export const selectUpsertStaticFileStatus = (state: RootState): UpsertEdiStaticFilePayload | undefined => state.ediStaticFiles.upsertStaticFileStatus;
export const selectDeleteStaticFileStatus = (state: RootState): DeleteByIdPayload | undefined => state.ediStaticFiles.deleteStaticFileStatus;

export const { 
    fetchError, 
    clearError,
    clearState,
    fetchStaticFileListSuccess, 
    incrementStaticFileRequestsInFlight,
    decrementStaticFileRequestsInFlight,
    captureUpsertStaticFileStatus,
    captureDeleteStaticFileStatus,
} = slice.actions;

export default slice.reducer;