import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteErpProductPayload, Scalars } from '../gql-types.generated';

export const MUTATION_DELETE_PRODUCT = gql`
    mutation deleteErpProduct($input: DeleteErpProductInput!){
        deleteErpProduct(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationDeleteProduct = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: Scalars['UUID'];
    }
): Promise<DeleteErpProductPayload | undefined> => {
    const { id } = options;
    const { data, errors } = await client.mutate<{ deleteErpProduct: DeleteErpProductPayload }>({
        variables: {
            input: {
                id: id
            }
        },
        mutation: MUTATION_DELETE_PRODUCT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error('An error has occurred during the ERP Product delete.');
    }
    if (data && data.deleteErpProduct) {
        return data.deleteErpProduct;
    }

    return undefined;
};