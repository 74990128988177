import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { capitalize, isNumber } from 'lodash';

import { MenuItem, FormControl, Grid, IconButton, InputLabel, SelectChangeEvent, Select, Stack, styled, TextField, Typography } from "@mui/material";
import { BlankMenuItem } from "../../util/SharedStyles";
import EditIcon from '@mui/icons-material/Edit';

import { ConfigurationModel, PipelineItemModel, PipelineItemClassNameModel, UpsertPipelineItemInput, UserRole } from '../../gql-types.generated';
import { selectPipelineItemClassList } from "../../features/EDIContainer/EDIContainerSlice";

import AddEditDialog from './AddEditDialog';
import ProcessPickerDialog from './ProcessPickerDialog';
import { isJson } from '../../util/Common';

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface PipelineItemProps {
    isOpen: boolean;
    viewerRole?: UserRole | undefined;
    pipelineId: string;
    pipelineItem?: PipelineItemModel | null | undefined;
    isLegacy?: boolean;
    onClose: () => void;
    onSave: (upsertData: UpsertPipelineItemInput) => void;
    error?: Error | undefined;
}

const PipelineItemDialog: React.FC<PipelineItemProps> = props => {
    const { isOpen, viewerRole, pipelineId, pipelineItem, isLegacy=false, onClose, onSave, error } = props;
    const id = pipelineItem?.id;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [sortOrder, setSortOrder] = useState<number | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [jsonArgs, setJsonArgs] = useState<string | undefined>(undefined);
    const [ediProcessClassName, setEdiProcessClassName] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<ConfigurationModel | undefined>();
    const [isProcessPickerOpen, setIsProcessPickerOpen] = useState(false);
    const [jsonArgsInvalid, setJsonArgsInvalid] = useState(false); // Default invalid state to false
    const [jsonArgsErrorText, setJsonArgsErrorText] = useState(''); // Default invalid state to false
    
    const processClasses = useAppSelector(selectPipelineItemClassList);

    const setFromPipelineItemDetails = useCallback(() => {
        if (pipelineItem) {
            if (pipelineItem.hasOwnProperty("sortOrder")) { //can be zero 
                setSortOrder(pipelineItem.sortOrder);
            }
            setDescription(pipelineItem.description as string);
            setJsonArgs(pipelineItem.jsonArgs as string);
            setEdiProcessClassName(pipelineItem.ediProcessClassName ?? '');

            if (pipelineItem.configuration) {
                setSelectedProcess(pipelineItem.configuration)
            }
        }
    },[pipelineItem]);

    useEffect(() => {
        if (!isOpen) {
            resetInitialState();
        }
    }, [isOpen]);

    useEffect(() => {
        if (pipelineItem) {
            setFromPipelineItemDetails();
        } else {
            resetInitialState();
        }
    }, [pipelineItem, setFromPipelineItemDetails]);

    const resetInitialState = () => {
        setIsFormDirty(false);
        setSubmitted(false);
        setSortOrder(undefined);
        setDescription(undefined);
        setJsonArgs(undefined);
        setEdiProcessClassName('');
        setSelectedProcess(undefined);
        setIsProcessPickerOpen(false);
        setJsonArgsErrorText('');
        setJsonArgsInvalid(false);
    };

    const getClassNameOptions = () => {
        if (processClasses && processClasses.length) {
            return (
                processClasses.map((processClass: PipelineItemClassNameModel) => (
                    <MenuItem
                        key={processClass.typeName}
                        value={processClass.className}
                    >
                        {processClass.description ?? capitalize(processClass.className)}
                    </MenuItem>
                ))
            );
        }
        return null;
    };
   
    const classNameMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave({
                configurationId: selectedProcess?.id,
                pipelineId: pipelineId,
                sortOrder: sortOrder,
                description: description,
                jsonArgs: jsonArgs,
                ediProcessClassName: ediProcessClassName?.length > 0 ? ediProcessClassName : undefined,
                id: id
            } as UpsertPipelineItemInput);
        }
    }

    const isFormValid = () => {
        if (isFormDirty) {
            let isValid = true;
            if (!isNumber(sortOrder)){
                isValid = false;
            }
            else if (isLegacy) {
                if (!selectedProcess?.id) {
                    isValid = false;
                }
            } 
            else {
                if (jsonArgsInvalid) {
                    isValid = false;
                } else if (!ediProcessClassName || ediProcessClassName.length <= 0) {
                    isValid = false;
                }
            }
            
            return isValid;
        }
        return false;
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onSortOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newOrder = event.target.value;
        setIsFormDirty(true);
        let parsed = parseInt(newOrder);
        if (!isNaN(parsed) && parsed > -1) {
            setSortOrder(parsed);
        } else {
            setSortOrder(0);
        }
    };

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onClassNameChange = (event: SelectChangeEvent<string | null>) => {
        if (event.target.value) {
            setIsFormDirty(true);
            setEdiProcessClassName(event.target.value);
        }
    };
    
    const onJsonArgsChange = (event: ChangeEvent<HTMLInputElement>) => {
        let toValidate = event.target.value;
        if (toValidate || toValidate === "") {
            const isValid = isJson(toValidate);
            setJsonArgsInvalid(!isValid);
            if (isValid) {
                setIsFormDirty(true);
                setJsonArgsErrorText('');
            } else {
                setJsonArgsErrorText('Invalid JSON format.  Enter a valid JSON value.');
            }
            setJsonArgs(toValidate);
        }
    };

    const onEditProcess = () => {
        setIsProcessPickerOpen(true);
    };

    const onProcessPickerClose = () => {
        setIsProcessPickerOpen(false);
    };

    const onProcessPickerSave = (process: ConfigurationModel) => {
        if (process) {
            setIsFormDirty(true);
            setSelectedProcess(process);
        }
    };

    const getLegacyProcessContent = () => {
        let processText = selectedProcess?.ediProcess ? selectedProcess.ediProcess : "Process Not Set";
        return (
            <Grid item xs={12}>
                <Typography variant='caption' aria-label='selected-process' justifyContent='flex-start'>Selected Process</Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='body1' >{processText}</Typography>
                    <IconButton color="primary" aria-label="edit-Process" onClick={onEditProcess}>
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Grid>
        );
    };

    const getProcessContent = () => {
        return (
            <>
            <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="classes-label" required >EDI Process Class Name</InputLabel>
                    <Select
                        labelId="classes-label"
                        aria-labelledby="classes-label"
                        value={ediProcessClassName}
                        onChange={onClassNameChange}
                        disabled={submitted}
                        MenuProps={classNameMenuProps}
                        required
                        data-cy="dialog-pipeline-item-class-name-list"
                    >
                        {getClassNameOptions()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    itemID="dialog-pipeline-item-description"
                    fullWidth
                    disabled={submitted}
                    value={description ?? ''}
                    label="Description"
                    inputProps={{
                        'aria-label': 'description',
                        'maxLength': 255,
                    }}
                    onChange={onDescriptionChange}
                    autoComplete="off"
                    data-cy="dialog-pipeline-item-description"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    itemID="dialog-pipeline-item-json-args"
                    fullWidth
                    multiline
                    maxRows={10}
                    disabled={submitted}
                    value={jsonArgs ?? ''}
                    label="JSON Arguments"
                    inputProps={{
                        'aria-label': 'json Arguments',
                        'spellCheck': 'false'
                    }}
                    onChange={onJsonArgsChange}
                    autoComplete="off"
                    error={jsonArgsInvalid}
                    helperText={jsonArgsErrorText}
                    data-cy="dialog-pipeline-item-json-args"
                    variant="standard"
                />
            </Grid>
            </>
        );
    }

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            entityName="Pipeline Process"
            id={id}
            maxWidth='md'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            isReadOnly={false}
            viewerRole={viewerRole}
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-pipeline-item-sort-order"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        required
                        value={sortOrder}
                        label="Sort Order"
                        inputProps={{
                            'aria-label': 'sort order',
                            'maxLength': 10,
                            'inputMode': "numeric",
                            'pattern': '[0-9]*'
                        }}
                        onChange={onSortOrderChange}
                        autoComplete="off"
                        data-cy="dialog-pipeline-item-sort-order"
                        variant="standard"
                    />
                </Grid>
                {!isLegacy && 
                    getProcessContent()
                }
                {isLegacy && 
                    getLegacyProcessContent()
                }
            </GridContainer>
            <ProcessPickerDialog
                isOpen={isProcessPickerOpen}
                onClose={onProcessPickerClose}
                onSave={onProcessPickerSave}
                error={error}
            />
        </AddEditDialog>
    );
}

export default PipelineItemDialog;