import { ChangeEvent, useEffect, useState } from 'react';
import { Grid, TextField } from "@mui/material";
import { PartnerTransactionSchemaModel, UpsertPartnerTransactionSchemaInput } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';
import { maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";

interface DialogProps {
    isOpen: boolean;
    partnerTransactionId: string;
    schema?: PartnerTransactionSchemaModel | undefined;
    onClose: () => void;
    onSave: (inputData: UpsertPartnerTransactionSchemaInput) => void;
    error?: Error | undefined;
}

const PartnerTransactionSchemaDialog: React.FC<DialogProps> = props => {
    const { isOpen, partnerTransactionId, schema, onClose, onSave, error } = props;
    const id = schema?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription] = useState<string>('');
    const [schemaFileContents, setSchemaFileContents] = useState<string | undefined>(undefined);
    const [sourceFileName, setSourceFileName] = useState<string | undefined>(undefined);
    const [existingSchemaFile, setExistingSchemaFile] = useState<File | undefined>(undefined);
    const [standardVersion, setStandardVersion] = useState<string | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    useEffect(() => {
        if (!isOpen) {
            // set submitted to false and clear fields when dialog is closed
            resetInitialState();
        } else {
            setFromSchemaDetails();
        }
    }, [isOpen]);

    useEffect(() => {
        // if have a schema, then populate for Edit
        if (schema) {
            setFromSchemaDetails();
        }
        else {
            resetInitialState();
        }
    }, [schema]);

    const setFromSchemaDetails = () => {
        if (schema) {
            if (schema.description) {
                setDescription(schema.description);
            }
            if (schema.standardVersion) {
                setStandardVersion(schema.standardVersion);
            }
            if (schema.sourceFileName) {
                setSourceFileName(schema.sourceFileName);
            }
            if (schema.documentString) {
                setSchemaFileContents(schema.documentString);
            }
            if (schema.sourceFileName && schema.documentString !== undefined && schema.documentString !== null ) {
                let schemaFile = new File([schema.documentString], schema.sourceFileName);
                setExistingSchemaFile(schemaFile);
            }
        }
    }

    const resetInitialState = () => {
        setSubmitted(false);
        setDescription('');
        setStandardVersion(undefined);
        setSchemaFileContents(undefined);
        setExistingSchemaFile(undefined);
        setIsFormDirty(false);
    };

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = description?.trim().length > 0;

        return isFormDirty && requiredCheck;
    };

    const submitForm = () => {
        if (isFormValid() === true) {
            setSubmitted(true);
            let id = schema?.id;
            onSave({ partnerTransactionId, description, documentString: schemaFileContents, sourceFileName, standardVersion, id } as UpsertPartnerTransactionSchemaInput);
        }
    };

    // onChange handlers
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onStandardVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setStandardVersion(event.target.value);
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            let fileName = fileToUpload?.name;
            let fileContents = await getSchemaFileContents(filesToUpload[0]);
            setSchemaFileContents(fileContents);
            setSourceFileName(fileName);

        } else {
            // when no file, make sure any previous file fields are cleared
            setSchemaFileContents(undefined);
            setSourceFileName(undefined);
        }
    };

    const getSchemaFileContents = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };


    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const standardVersionProps = {
        'aria-label': 'standard-version',
        'maxLength': 20,
    };
    
    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            titlePrefix={id ? 'Edit' : 'Add'}
            entityName="EDI Rules Schema"
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-schema-description"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-schema-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Schema File"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your schema file here"
                        existingFiles={existingSchemaFile ? [existingSchemaFile] : undefined}
                        acceptedFileTypes={{
                            'text/xml': ['.xml', '.xsd'],
                            'text/xsl': ['.xsl', '.xslt'],
                        }}
                        onChange={onFileUploadChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-schema-standard-version"
                        fullWidth
                        disabled={submitted}
                        value={standardVersion}
                        label="Standard Version"
                        inputProps={standardVersionProps}
                        onChange={onStandardVersionChange}
                        autoComplete="off"
                        data-cy="dialog-schema-standard-version"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default PartnerTransactionSchemaDialog;