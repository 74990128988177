import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPipelinePayload, Maybe, UpsertPipelineInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PIPELINE = gql`
    mutation upsertPipeline($input: UpsertPipelineInput!){
        upsertPipeline(input: $input){
            message
            errors {
                code
                message
            }
            result
            pipeline {
                id
            }
        }
    }
`;

export const mutationUpsertPipeline = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        pipelineInput: UpsertPipelineInput
    }
): Promise<UpsertPipelinePayload | undefined> => {
    const { description, transactionId, id } = args.pipelineInput;
    const { data, errors } = await client.mutate<{ upsertPipeline: UpsertPipelinePayload}>({
        variables: { 
            input: { 
                description, 
                transactionId, 
                id,
                isTemplate: false,
                isLegacy: false
            } 
        },
        mutation: MUTATION_UPSERT_PIPELINE
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of Pipeline.');
    }
    if (data && data.upsertPipeline) {
        return data.upsertPipeline;
    }

    return undefined;
};