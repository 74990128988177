import { MessagingLogModel, ActionPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    messageLog?: MessagingLogModel;
    error?: Error;
    updateLogStatus?: ActionPayload;
}

const initialState: SliceState = {
    messageLog: undefined,
    error: undefined,
    updateLogStatus: undefined
};

export const slice = createSlice({
    name: 'messageLogDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.messageLog = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = undefined;
        },

        fetchMessagingLogByIdSuccess: (state, action: PayloadAction<MessagingLogModel[]>) => {
            if (action.payload && action.payload[0]) {          
                // eslint-disable-next-line no-param-reassign
                state.messageLog = action.payload[0];
            }
        },

        captureUpdateLogStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.messageLogDetails.error;
export const selectMessageLog = (state: RootState): MessagingLogModel | undefined => state.messageLogDetails.messageLog;
export const selectUpdateLogStatus = (state: RootState): ActionPayload | undefined => state.messageLogDetails.updateLogStatus;

export const {
    fetchError,
    clearError,
    clearState,
    fetchMessagingLogByIdSuccess,
    captureUpdateLogStatus
} = slice.actions;

export default slice.reducer;