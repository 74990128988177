import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpsertSystemConfigurationPayload, SystemConfigurationModel } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    systemConfiguration?: SystemConfigurationModel;
    upsertSystemConfigurationStatus?: UpsertSystemConfigurationPayload;
}

const initialState: SliceState = {
    error: undefined,
    systemConfiguration: undefined,
    upsertSystemConfigurationStatus: undefined,
};

export const slice = createSlice({
    name: 'systemConfiguration',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        fetchSystemConfigurationSuccess: (state, action: PayloadAction<SystemConfigurationModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.systemConfiguration = action.payload;
        },

        captureUpsertSystemConfigurationStatus: (state, action: PayloadAction<UpsertSystemConfigurationPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertSystemConfigurationStatus = action.payload;
        }
    }
});

export const selectError = (state: RootState): Error | undefined => state.systemConfiguration.error;
export const selectUpsertSystemConfigurationStatus = (state: RootState): UpsertSystemConfigurationPayload | undefined => state.systemConfiguration.upsertSystemConfigurationStatus;
export const selectSystemConfiguration = (state: RootState): SystemConfigurationModel | undefined => state.systemConfiguration.systemConfiguration;

export const {
    clearError,
    fetchError,
    fetchSystemConfigurationSuccess,
    captureUpsertSystemConfigurationStatus,
} = slice.actions;

export default slice.reducer;