import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CircularProgress, Typography, styled } from '@mui/material';
import { GridColumns, GridRowModel, GridOverlay, GridSortModel } from '@mui/x-data-grid-pro';
import { TabContainer, TabContent, TabDataGridNoRowHover } from '../../util/SharedStyles';
import NoRecordsMessage from '../NoRecordsMessage';
import { BillingAggregatesModel } from '../../gql-types.generated';
import { selectError, selectBillingAggregates } from '../../features/ClientDetails/ClientDetailsSlice';
import { fetchBillingAggregatesByClientKey } from '../../features/ClientDetails/ClientDetailsActions';

const TabContainerWithMargin = styled(TabContainer)((props) => ({
    marginTop: '16px'
}));

interface BillingAggregatesListProps {
    internalClientCode: string;
}

const ClientBillingAggregatesList: React.FC<BillingAggregatesListProps> = props => {
    const { internalClientCode } = props;
    const dispatch = useAppDispatch();
    const [billingAggregatesRows, setBillingAggregatesRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'year',
            sort: 'desc',
        },
        {
            field: 'month',
            sort: 'desc',
        },
    ]);

    const billingAggregates = useAppSelector(selectBillingAggregates);
    
    const error = useAppSelector(selectError);

    useEffect(() => {
        if (internalClientCode) {
            dispatch(fetchBillingAggregatesByClientKey(internalClientCode));  
        }
    }, [internalClientCode]);

    useEffect(() => {
        setBillingAggregatesRows(getBillingAggregatesRows());
    }, [billingAggregates]);

   
    const getBillingAggregatesRows = () => {
        if (billingAggregates && billingAggregates.length > 0) {
            return billingAggregates.map((aggregatesRow: BillingAggregatesModel) => {
                const { id, year, month, currencyCode, serviceFee, documentCharges, lineCharges, totalCharges, totalDocuments, totalLines } = aggregatesRow;
                
                return {
                    _raw: aggregatesRow,
                    id,
                    year, 
                    month, 
                    currencyCode, 
                    totalDocuments, 
                    totalLines,
                    serviceFee, 
                    documentCharges, 
                    lineCharges, 
                    totalCharges, 
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const billingAggregatesColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'year',
                headerName: 'YEAR',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'month',
                headerName: 'MONTH',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'currencyCode',
                headerName: 'CURRENCY',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'totalDocuments',
                headerName: 'TOTAL DOCS',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'totalLines',
                headerName: 'TOTAL LINES',
                minWidth: 100,
                flex: 1,
                sortable: true,
            }, {
                field: 'serviceFee',
                headerName: 'FEE',
                minWidth: 60,
                flex: 1,
                sortable: true,
            }, {
                field: 'documentCharges',
                headerName: 'DOC CHARGES',
                minWidth: 120,
                flex: 1,
                sortable: true,
            }, {
                field: 'lineCharges',
                headerName: 'LINE CHARGES',
                minWidth: 120,
                flex: 1,
                sortable: true,
            }, {
                field: 'totalCharges',
                headerName: 'TOTAL CHARGES',
                minWidth: 130,
                flex: 1,
                sortable: true,
            },
        ],
        [],
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'billingAggregates-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && billingAggregates?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };


    return (
        <TabContainerWithMargin>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={billingAggregates === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Billing Aggregates List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={billingAggregatesRows}
                    columns={billingAggregatesColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>

        </TabContainerWithMargin>
    );
}

export default ClientBillingAggregatesList;