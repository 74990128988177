import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertTransactionMapPartnerInput, UpsertTransactionMapPartnerPayload } from "../gql-types.generated";

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRANSACTION_MAP_PARTNER = gql`
    mutation upsertTransactionMapPartner($input: UpsertTransactionMapPartnerInput!){
        upsertTransactionMapPartner(input: $input){
            message
            errors {
                code
                message
            }
            result
            transactionMapPartnerId
        }
    }
`;

export const mutationUpsertTransactionMapPartner = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id?: string,
        partnerId: string,
        transactionMapId: string,
    }
): Promise<UpsertTransactionMapPartnerPayload | undefined> => {
    const { id, partnerId, transactionMapId } = options;
    const { data, errors } = await client.mutate<{ upsertTransactionMapPartner: UpsertTransactionMapPartnerPayload }>({
        variables: { input: { id, partnerId, transactionMapId } },
        mutation: MUTATION_UPSERT_TRANSACTION_MAP_PARTNER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the transaction map partner save.');
    }
    if (data && data.upsertTransactionMapPartner) {
        return data.upsertTransactionMapPartner;
    }
    return undefined;
};