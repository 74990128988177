import { Snackbar, styled } from '@mui/material';
import { ToastSeverity } from '../util/Constants';
import { Toast } from '../util/SharedStyles';

const FloatingToast = styled(Toast)((props) => ({
  width: '100%',
}));

const SnackFullWidth = styled(Snackbar)((props) => ({
  width: 'calc(100% - 48px)',
  boxShadow: '0px 3px 6px #00000029',
}));


type SnackbarPromptOptions = {
  anchorOrigin: {
    horizontal: "center" | "left" | "right";
    vertical: "bottom" | "top";
  };
  //milliseconds
  autoHideDuration: number;
  //milliseconds
  transitionDuration?: number;
  message: string;
  severity: ToastSeverity;
}

interface SnackbarPromptProps {
  open: boolean;
  snackbarPromptOptions: SnackbarPromptOptions;
  handleClose: () => void;
}
const SnackbarPrompt: React.FC<SnackbarPromptProps> = (props: SnackbarPromptProps) => {
  return (
    <SnackFullWidth
      open={props.open}
      anchorOrigin={props.snackbarPromptOptions.anchorOrigin}
      autoHideDuration={props.snackbarPromptOptions.autoHideDuration}
      transitionDuration={props.snackbarPromptOptions.transitionDuration}
      onClose={props.handleClose}
      message={props.snackbarPromptOptions.message}
    >
      <FloatingToast severity={props.snackbarPromptOptions.severity}>{props.snackbarPromptOptions.message}</FloatingToast>
    </SnackFullWidth>
  );
};

export type { SnackbarPromptOptions };
export default SnackbarPrompt;
