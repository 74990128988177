import capitalize from 'lodash/capitalize';
import { Divider, Grid, Typography } from '@mui/material';
import TransactionIcon from "@mui/icons-material/Description";
import ClientIcon from '@mui/icons-material/Work';
import { ClientModel, UserRole, ClientStatus } from '../../gql-types.generated';
import { ActiveStatusChip, SuspendedStatusChip, StatusChip, CardAvatar, CardChip, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';
import { Viewer } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';

interface TradingPartnerClientListItemProps {
    viewerRole: UserRole | undefined;
    client: ClientModel;
    clickHandler: (id: string | undefined) => void | undefined;
}

const TradingPartnerClientListItem: React.FC<TradingPartnerClientListItemProps> = (props: TradingPartnerClientListItemProps) => {
    const { viewerRole, client, clickHandler } = props
    const { id, name, statusCode, businessArea, erpProduct, clientCode, totalTransactions } = client;
    
    const cardClick = () => {
        if (id && clickHandler) {
            clickHandler(id);
        }
    };

    let chipContainerJustify = "flex-end";
    let transactionsElement = null;
    if (totalTransactions && totalTransactions > 0) {
        chipContainerJustify = "space-between";
        transactionsElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <TransactionIcon />
                <Typography>Transactions: {totalTransactions}</Typography>
            </Grid>
        )
    }

    let statusChip;
    let statusLabel = capitalize(statusCode?.toString());
    if (statusCode === ClientStatus.Active) {
        statusChip = <ActiveStatusChip label={statusLabel} />;
    }
    else if (statusCode === ClientStatus.Suspended) {
        statusChip = <SuspendedStatusChip label={statusLabel} />;
    }
    else {
        statusChip = <StatusChip label={statusLabel} />;
    }

    let productChip;
    let erpProductName = erpProduct?.name;
    if (erpProductName && erpProductName.length > 0) {
        productChip = <CardChip label={erpProductName} />;
    }

    let chipsElement = (
        <Grid container item direction="row" gap={'8px'} justifyContent="flex-end" alignItems="center" xs={'auto'}>
            {productChip}
            {statusChip}
        </Grid>
    )

    let businessAreaName = businessArea?.name;
    let businessAreaLabel = (businessAreaName && businessAreaName.length > 0) ? ` | ${businessAreaName}` : '';
    let subHeading = `${clientCode}${businessAreaLabel}`;

    return (
        <ClickableRootCard variant="outlined" onClick={cardClick}>
            <RootCardHeader
                avatar={
                    <CardAvatar alt="Client">
                        <ClientIcon />
                    </CardAvatar>
                }
                title={name}
                titleTypographyProps={{ fontSize: '18px' }}
                subheader={subHeading}
                subheaderTypographyProps={{ variant: 'caption' }}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                <>
                    {transactionsElement}
                    {chipsElement}
                </>
            </CardFooter>
        </ClickableRootCard>
    );
};

export default TradingPartnerClientListItem;