import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { EdiStaticFileModel, PagingResultModelOfEdiStaticFileModel, GetEdiStaticFileInput, UpsertEdiStaticFileInput, RequestResult } from '../../gql-types.generated';
import { queryEdiStaticFileList } from '../../gql/QueryEdiStaticFileList';
import { mutationUpsertEdiStaticFile } from '../../gql/MutationUpsertEdiStaticFile';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { 
    captureDeleteStaticFileStatus, 
    captureUpsertStaticFileStatus, 
    fetchError, 
    fetchStaticFileListSuccess,
    incrementStaticFileRequestsInFlight,
    decrementStaticFileRequestsInFlight
} from './EDIStaticFilesSlice';

export const fetchStaticFileList = (
    filters?: GetEdiStaticFileInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementStaticFileRequestsInFlight());
    try {
        const fileList = await queryEdiStaticFileList(gqlClient, filters);
        if (fileList) {
            dispatch(fetchStaticFileListSuccess(fileList as PagingResultModelOfEdiStaticFileModel))
        }
        dispatch(decrementStaticFileRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementStaticFileRequestsInFlight());
    }
};

// export const fetchStaticFileList = () =>
//     async (dispatch: AppDispatch): Promise<void> => {
//         try {
//             const staticFileList = await queryEdiStaticFileList(gqlClient, {});
//             if (staticFileList) {
//                 dispatch(fetchStaticFileListSuccess(staticFileList as EdiStaticFileModel[]));
//             }
//         } catch (e) {
//             dispatch(fetchError(e as Error));
//         }
//     };

export const upsertEdiStaticFile = (staticFileData: UpsertEdiStaticFileInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertEdiStaticFile(gqlClient, {
                staticFileData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertStaticFileStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteEdiStaticFile = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "EdiStaticFile",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteStaticFileStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };