import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Grid, FormControl, InputLabel, TextField, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { ErpTransactionSchemaModel, TransactionDirection, TransactionModel, UpsertErpTransactionSchemaInput, UserRole } from "../../gql-types.generated";
import { selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';
import { maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';

const DivEllipsis = styled('div')((props) => ({
    display: 'block',
    width: '540px',
    textOverflow: "ellipsis",
    overflow: 'hidden',
}));

interface DialogProps {
    isOpen: boolean;
    schema?: ErpTransactionSchemaModel | null | undefined;
    viewerRole?: UserRole | undefined;
    isReadOnly: boolean;
    onClose: () => void;
    onSave: (schemaDetails: UpsertErpTransactionSchemaInput) => void;
    error?: Error | undefined;
}

const ErpTransactionSchemaDialog: React.FC<DialogProps> = props => {
    const { isOpen, schema, viewerRole, isReadOnly, onClose, onSave, error } = props;
    const id = schema?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription] = useState<string>('');
    const [schemaFileString, setSchemaFileString] = useState<string | undefined>(undefined);
    const [schemaFileName, setSchemaFileName] = useState<string | undefined>(undefined);
    const [existingSchemaFile, setExistingSchemaFile] = useState<File | undefined>(undefined);
    const [transactionId, setTransactionId] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    const [isViewMode, setIsViewMode] = useState(false);

    // get the list of transactions for the dropdown
    const transactions = useAppSelector(selectTransactionList);

    useEffect(() => {
        if (!isOpen) {
            resetInitialState();
        } else {
            setFromSchema();
        }
    }, [isOpen]);

    useEffect(() => {
        if (schema) {
            setFromSchema();
        } else {
            resetInitialState();
        }
    }, [schema]);

    const setFromSchema = () => {
        if (schema) {
            if (schema.description) {
                setDescription(schema.description);
            }
            if (schema.documentString) {
                setSchemaFileString(schema.documentString);
            }
            if (schema.sourceFileName) {
                setSchemaFileName(schema.sourceFileName);
            }
            if (schema.sourceFileName && schema.documentString !== undefined && schema.documentString !== null ) {
                let schemaFile = new File([schema.documentString], schema.sourceFileName);
                setExistingSchemaFile(schemaFile);
            }
            if (schema.transactionId) {
                setTransactionId(schema.transactionId);
            }
        }
    };

    const resetInitialState = () => {
        setSubmitted(false);
        setDescription('');
        setSchemaFileString(undefined);
        setSchemaFileName(undefined);
        setExistingSchemaFile(undefined);
        setTransactionId('');
        setIsFormDirty(false);
    }

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            setFromSchema();
        } else {
            onClose();
        }
    };


    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = description?.trim().length > 0 && transactionId?.trim().length > 0;
        return isFormDirty && requiredCheck;
    }

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            description: description,
            documentString: schemaFileString,
            id: id,
            transactionId: transactionId,
            sourceFileName: schemaFileName
        } as UpsertErpTransactionSchemaInput);
    }

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onTransactionChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        setTransactionId(event.target.value ?? '');
    };

    const getFileString = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            let fileName = fileToUpload?.name;
            let fileString = await getFileString(fileToUpload);
            setSchemaFileString(fileString);
            setSchemaFileName(fileName);

        } else {
            // when no file, make sure any previous file fields are cleared            
            setSchemaFileString(undefined);
            setSchemaFileName(undefined);
        }
    };

    const getTransactionsDropList = () => {
        if (transactions && transactions.length) {
            return (
                transactions.map((transaction: TransactionModel) => {
                    const direction = transaction.direction;
                    let directionIcon;
                    if (direction) {
                        directionIcon = (direction === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;
                    }
                    return (
                        <MenuItem
                            key={transaction.id}
                            value={transaction.id}
                        >
                            <Grid container item direction="row" gap={'8px'} alignItems="center">
                                {`${transaction.name} - ${transaction.description}`}
                                {directionIcon}
                            </Grid>
                        </MenuItem>
                    );
                })
            );
        }
        return null;
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    const TransactionsMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
                maxWidth: '540px',
            },
        },
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };


    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="ERP Transaction Schema Definition"
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            onCancel={onEditCancel}
            isReadOnly={isViewMode}
            onViewModeEdit={viewModeEditForm}
            canEditFromReadOnly={true}
            viewerRole={viewerRole}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-schema-description"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-schema-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                        <InputLabel id="dialog-schema-transactions-label">Transaction</InputLabel>
                        <Select
                            labelId="dialog-schema-transactions-label"
                            aria-labelledby="dialog-schema-transactions-label"
                            value={transactionId}
                            onChange={onTransactionChange}
                            disabled={submitted}
                            MenuProps={TransactionsMenuProps}
                            data-cy="dialog-schema-transaction"
                        >
                            {getTransactionsDropList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Schema File"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your schema file here"
                        existingFiles={existingSchemaFile ? [existingSchemaFile] : undefined}
                        acceptedFileTypes={{
                            'text/xml': ['.xml', '.xsd'],
                        }}
                        onChange={onFileUploadChange}
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    )

}

export default ErpTransactionSchemaDialog;