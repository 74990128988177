import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, styled } from "@mui/material";
import { BillingModel, CurrencyModel } from '../../gql-types.generated';
import { selectCurrencyList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface DialogProps {
    isOpen: boolean;
    billing?: BillingModel | null | undefined;
    onClose: () => void;
    onSave: (currencyCode: string, description?: string, id?: string) => void;
    error?: Error | undefined;
}

const BillingDialog: React.FC<DialogProps> = props => {
    const { isOpen, billing, onClose, onSave, error } = props;
    const id = billing?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    const currencies = useAppSelector(selectCurrencyList);

    useEffect(() => {
        // if have a billing, then populate for Edit
        if (billing) {
            setFromBilling();
        }
        else {
            setToDefaults();
        }
    }, [billing]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
        else {
            setFromBilling();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setCurrencyCode('');
        setDescription('');
    }

    const setFromBilling = () => {
        if (billing) {
            if (billing.currencyCode) {
                setCurrencyCode(billing.currencyCode);
            }

            if (billing.description) {
                setDescription(billing.description);
            }
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && currencyCode?.trim().length > 0 && description?.trim().length > 0;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(currencyCode, description, id);
    };

    // onChange handlers
    const onCurrencyChange = (event: SelectChangeEvent<string | null>) => {
        if (event.target.value) {
            setIsFormDirty(true);
            setCurrencyCode(event.target.value);
        }
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    // get the list of available currencies for the dropdown
    const getCurrenciesDropList = () => {
        if (currencies && currencies.length) {
            return (
                currencies.map((currency: CurrencyModel) => (
                    <MenuItem
                        key={currency.currencyCode}
                        value={currency.currencyCode}
                    >
                    {`${currency.currencyCode} - ${currency.description?.trim()}`}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const currencyMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Billing Group"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-billing-description"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-billing-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required >
                        <InputLabel id="dialog-billing-currency-label">Currency Code</InputLabel>
                        <Select
                            labelId="dialog-billing-currency-label"
                            aria-labelledby="dialog-billing-currency-label"
                            value={currencyCode}
                            onChange={onCurrencyChange}
                            disabled={submitted}
                            MenuProps={currencyMenuProps}
                            data-cy="dialog-billing-currency"
                        >
                            {getCurrenciesDropList()}
                        </Select>
                    </FormControl>
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
};

export default BillingDialog;