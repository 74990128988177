import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { AddPartnerTransactionsPayload, AddPartnerTransactionsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_ADD_PARTNER_TRANSACTIONS = gql`
    mutation addPartnerTransactions($input: AddPartnerTransactionsInput!){
        addPartnerTransactions(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationAddTradingPartnerTransactions = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        partnerId: string;
        transactionIds: string[];
    } 
): Promise<AddPartnerTransactionsPayload | undefined> => {
    const { partnerId, transactionIds } = options as AddPartnerTransactionsInput;
    const { data, errors } = await client.mutate<{ addPartnerTransactions: AddPartnerTransactionsPayload}>({
        variables: { 
            input: { 
                partnerId,
                transactionIds
            } 
        },
        mutation: MUTATION_ADD_PARTNER_TRANSACTIONS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add trading partner transactions.');
    }
    if (data && data.addPartnerTransactions) {
        return data.addPartnerTransactions;
    }

    return undefined;
};