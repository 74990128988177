import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { ErpTransactionSchemaModel, RequestResult, UpsertErpTransactionSchemaInput } from '../../gql-types.generated';
import { queryErpTransactionSchemaList } from '../../gql/QueryErpTransactionSchemas';
import { mutationUpsertErpTransactionSchema } from '../../gql/MutationUpsertErpTransactionSchema';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { captureDeleteSchemaStatus, captureUpsertSchemaStatus, fetchError, fetchSchemaListSuccess } from './ErpTransactionSchemasSlice';

export const fetchSchemaList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const schemaList = await queryErpTransactionSchemaList(gqlClient, {});
            if (schemaList) {
                dispatch(fetchSchemaListSuccess(schemaList as ErpTransactionSchemaModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertErpTransactionSchema = (upsertErpTransactionSchemaData: UpsertErpTransactionSchemaInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertErpTransactionSchema(gqlClient, {
                upsertErpTransactionSchemaData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertSchemaStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteErpTransactionSchema = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "ErpTransactionSchema",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteSchemaStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };