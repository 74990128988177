import { MouseEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { ToggleButton, styled } from '@mui/material';
import FavoriteIconSelected from '@mui/icons-material/Star';
import FavoriteIconUnselected from '@mui/icons-material/StarOutline';
import { EntityType, FavoriteModel } from '../../gql-types.generated';
import theme from '../../Theme';

import { upsertFavoriteData } from '../../features/EDIContainer/EDIContainerActions';

interface ButtonProps {
    entityId: string;
    entityName: string;
    entityType: EntityType;
    favorite: FavoriteModel | undefined;
}

const BorderlessToggleButton = styled(ToggleButton)((props) => ({
    border: '0 !important',
    backgroundColor: 'transparent !important',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
        color: theme.palette.primary.dark,
        borderRadius: '50%'
    }
}));

const FavoriteToggleButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const dispatch = useAppDispatch();
    const { entityId, entityName, entityType, favorite } = props;
    const [isFavorite, setIsFavorite] = useState(false);
    
    useEffect(() => {
        // if favorite is defined, then currently given entity isFavorite true
        if (favorite) {
            setIsFavorite(true);
        } else {
            setIsFavorite(false);
        }
    }, [favorite]);

    const onClick = (event: MouseEvent<HTMLElement>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        let newFavoriteValue = !isFavorite;
        
        // save to or delete from favorites based on isFavorite value
        // now that favorites are in sql instead of mongo, need to pass the guid for delete
        dispatch(upsertFavoriteData(entityType, entityId, newFavoriteValue, entityName ?? '', favorite?.id));
        setIsFavorite(newFavoriteValue);
    }

    let title = `${isFavorite ? 'Clear' : 'Mark as'} Favorite`;
    let icon = isFavorite ? <FavoriteIconSelected/> : <FavoriteIconUnselected/>;

    return (
        <BorderlessToggleButton
            value="favorite"
            selected={isFavorite}
            onClick={onClick}
            aria-label="favorite" 
            color="primary"
            title={title}
        >
            {icon}
        </BorderlessToggleButton>
    );
};

export default FavoriteToggleButton;