import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ClientTransactionModel, GetClientTransactionsInput, GetClientTransactionsByClientIdQuery, Scalars } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENT_TRANSACTIONS_BY_CLIENT_ID = gql`
    query getClientTransactionsByClientId($input: GetClientTransactionsInput!) {
        clientTransactions(input: $input) {
            nodes {
                id
                isActive
                notify
                bulkNotify
                fileNamePattern
                pipeline {
                    description
                    id
                    isTemplate
                    transactionId
                }
                pipelineId
                transaction {
                    id
                    name
                    description
                    direction
                    isActive
                }
                transactionId
                erpTransactionSchema {
                    description
                    id
                }
                notificationFormatter {
                    id
                    description
                }
            }
        }
    }
`;

export const queryClientTransactionsByClientId = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<ClientTransactionModel[] | undefined> => {
    const { data, errors } = await client.query<GetClientTransactionsByClientIdQuery>({
        variables: {
            input: {
                clientId: id
            }
        },
        query: QUERY_CLIENT_TRANSACTIONS_BY_CLIENT_ID,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }
    return data?.clientTransactions?.nodes as ClientTransactionModel[];
}