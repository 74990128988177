import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { PipelineModel, GetPipelinesInput, UpsertPipelineInput, RequestResult } from '../../gql-types.generated';
import { getErrorMessage } from '../../util/Common';
import { fetchError, fetchPipelineListSuccess, captureDeletePipelineStatus, captureUpsertPipelineStatus, captureClonePipelineStatus } from './PipelinesSlice';
import { queryPipelineList } from '../../gql/QueryPipelinesList';
import { mutationUpsertPipeline } from '../../gql/MutationUpsertPipeline';
import { mutationClonePipeline } from '../../gql/MutationClonePipeline';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';

export const fetchPipelineList = (
    filters?: GetPipelinesInput
) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const pipelineList = await queryPipelineList(gqlClient, filters);
            if (pipelineList) {
                dispatch(fetchPipelineListSuccess(pipelineList as PipelineModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertPipeline = (pipelineInput: UpsertPipelineInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPipeline(gqlClient, {
                pipelineInput
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPipelineStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePipeline = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deletePipelineStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Pipeline"
            });
            if (deletePipelineStatus) {
                if (deletePipelineStatus.result === RequestResult.Fail) {
                    getErrorMessage(deletePipelineStatus.message as string, deletePipelineStatus.errors, true, true);
                }
                dispatch(captureDeletePipelineStatus(deletePipelineStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const clonePipeline = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const cloneStatus = await mutationClonePipeline(gqlClient, {
                pipelineId: id,
            });
            if (cloneStatus) {
                if (cloneStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: cloneStatus.message } as Error));
                    getErrorMessage(cloneStatus.message as string, cloneStatus.errors, true, true);
                }
                dispatch(captureClonePipelineStatus(cloneStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


