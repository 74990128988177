import { useCallback, useEffect, useState } from 'react';
import { styled, useTheme, useMediaQuery, CardActions, CardContent, CircularProgress, Collapse, Divider, Grid, Skeleton } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CardFooter, DetailsCard, DetailsCardLandscape, DetailsCardLandscapeContent, DetailsContentBoxLandscape, DetailsPanelFullHeightGrid, DetailsPaperContent, DetailsPaperContentLandscape, DetailsRootCardHeader, ExpandMore, MainContentBox, PageTitleToolbarGrid, PortraitTabbedContentGrid, StackPortDetails, Toast } from "../util/SharedStyles";
import PageTitleBar from "./PageTitleBar";

const Loader = styled('div')((props) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
}));

const EditButtonForcedRight = styled('div')((props) => ({
    marginLeft: 'auto !important',
}));

interface DetailsPageProps {
    recordLoaded: boolean;
    pageTitle: string | undefined;
    toolBarButtons: JSX.Element[] | undefined;
    detailsAV: JSX.Element | undefined;
    detailsHeader: string | JSX.Element | undefined;
    detailsSubHeader: string | undefined;
    detailsChips: JSX.Element[] | undefined;
    detailsEditButton: JSX.Element | undefined;
    detailsFavoriteButton: JSX.Element | undefined;
    error: Error | undefined;
    getDetailsListContainer: () => JSX.Element | undefined;
    getTabbedContainer: () => JSX.Element | undefined;
    getDialogs: () => JSX.Element[] | undefined;
    updateAlignment: (verticalAlign: boolean) => void;
}

const DetailsPage: React.FC<DetailsPageProps> = (props) => {
    const {
        recordLoaded,
        pageTitle,
        toolBarButtons,
        detailsAV,
        detailsHeader,
        detailsSubHeader,
        detailsChips,
        detailsEditButton,
        detailsFavoriteButton,
        error,
        getDetailsListContainer,
        getTabbedContainer,
        getDialogs,
        updateAlignment
    } = props;

    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);
    const [detailsLandscapeContentHeight, setDetailsLandscapeContentHeight] = useState('auto');
    const theme = useTheme();
    const verticalAlign = useMediaQuery(theme.breakpoints.down('lg'));

    // this will be called when recordLoaded is set to true on parent details page.
    // however, the values might not be loaded in each element yet that could change
    // the height of the header,
    // so utilizing useCallback to be able to recalculate height on header values change.
    // without this, the calculated headerHeight could be smaller than it ends up being,
    // resulting in the scrollbox not being able to scroll all the way through the fields
    const getLandscapeDetailsHeight = useCallback(() => {
        // size the details content area to scroll within itself if it is higher than
        // will fit on screen
        const headerHeight = document.getElementById('details-card-header-landscape')?.clientHeight || 0;
        const footerHeight = document.getElementById('details-card-footer-landscape')?.clientHeight || 0;
        let totalHeaderAreaHeight = headerHeight + footerHeight;

        if (totalHeaderAreaHeight > 0) {
            setDetailsLandscapeContentHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    },[detailsHeader, detailsSubHeader]);

    useEffect(() => {
        if (recordLoaded && !verticalAlign) {
            getLandscapeDetailsHeight();
        }
        updateAlignment(verticalAlign);
    }, [recordLoaded, verticalAlign, getLandscapeDetailsHeight]);
    
    const onExpandClick = () => {
        setDetailsExpanded(!detailsExpanded);
    };

    const getDetails = () => {
        if (recordLoaded) {
            return getDetailsListContainer();
        } else if (error) {
            return <Toast severity='error'>{error.message}</Toast>;
        } else {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        }
    }

    const getPortraitDetailsPanel = () => {
        let combinedTitle;

        if (detailsHeader && detailsChips) {
            combinedTitle = (<div>{detailsHeader}&nbsp;&nbsp;{detailsChips}</div>)
        }
        else if (detailsHeader) {
            combinedTitle = detailsHeader;
        }

        return (
            <DetailsCard>
                <DetailsRootCardHeader
                    avatar={detailsAV}
                    title={combinedTitle}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={detailsSubHeader}
                    subheaderTypographyProps={{ variant: 'subtitle2' }}
                    action={
                        <>
                            {detailsEditButton}
                            {detailsFavoriteButton}
                        </>
                    }
                />
                <CardActions disableSpacing>
                    {/*+detailsExpanded turns the boolean to a number (see explanation on ExpandMore in SharedStyles*/}
                    {(recordLoaded) &&
                        <ExpandMore
                            expand={+detailsExpanded}
                            onClick={onExpandClick}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    }
                </CardActions>
                <Collapse in={detailsExpanded}>
                    <Divider light />
                    <CardContent>
                        {getDetails()}
                    </CardContent>
                </Collapse>
            </DetailsCard>
        )
    }

    const getLandscapeDetailsPanel = () => {
        return (
            <DetailsCardLandscape>
                <DetailsRootCardHeader id="details-card-header-landscape"
                    avatar={detailsAV}
                    title={detailsHeader}
                    titleTypographyProps={{ variant: 'h6' }}
                    subheader={detailsSubHeader}
                    subheaderTypographyProps={{ variant: 'subtitle2' }}

                    //action prop to set or unset as favorite
                    action={detailsFavoriteButton}
                />
                <CardFooter id="details-card-footer-landscape" justify='space-between'>
                    {detailsChips}
                    <EditButtonForcedRight>{detailsEditButton}</EditButtonForcedRight>
                </CardFooter>
                <Divider light />
                <DetailsCardLandscapeContent scrollheight={detailsLandscapeContentHeight}>
                    {getDetails()}
                </DetailsCardLandscapeContent>

            </DetailsCardLandscape>
        );
    };

    const getTabbedContent = () => {
        if (recordLoaded) {
            return verticalAlign ? (
                <DetailsPaperContent elevation={1}>
                    {getTabbedContainer()}
                </DetailsPaperContent>
            ) : (
                <DetailsPaperContentLandscape elevation={1}>
                    {getTabbedContainer()}
                </DetailsPaperContentLandscape>
            )
        } else if (error) {
            return <Toast severity='error'>{error.message}</Toast>;
        } else if (verticalAlign) {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        }
    }

    const getContent = () => {
        return verticalAlign ? (
            <StackPortDetails>
                <Grid container>
                    {getPortraitDetailsPanel()}
                </Grid>
                <PortraitTabbedContentGrid container>
                    {getTabbedContent()}
                </PortraitTabbedContentGrid>
            </StackPortDetails>
        ) : (
            <DetailsContentBoxLandscape>
                <DetailsPanelFullHeightGrid container item xs={12} sm={12} md={12} lg={4} xl={3}>
                    {getLandscapeDetailsPanel()}
                </DetailsPanelFullHeightGrid>
                <DetailsPanelFullHeightGrid container item xs={12} sm={12} md={12} lg={8} xl={9}>
                    {getTabbedContent()}
                </DetailsPanelFullHeightGrid>
            </DetailsContentBoxLandscape>
        )
    };

    let myPageTitle = pageTitle ? pageTitle : (<Skeleton animation="wave" height={40} width="400px" />);

    return (
        <MainContentBox>
            <PageTitleBar text={myPageTitle} id="details-title-comp" hasBackButton>
                <PageTitleToolbarGrid item>
                    {toolBarButtons}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            {getContent()}
            {getDialogs()}
        </MainContentBox>
    )
}

export default DetailsPage;