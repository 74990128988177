import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { MapsListQuery, GetTransactionMapInput, PagingResultModelOfTransactionMapModel, TransactionMapOrderByInput, OrderDirection } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_MAPS_LIST = gql`
    query mapsList($input: GetTransactionMapInput!) {
        transactionMaps(input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                description
                isMapDeployed
                isMapLoaded
                standardVersion
                totalPartners
                transaction {
                    id
                    name
                    description
                    direction
                }
            }
        }
    }
`;

export const queryMapList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetTransactionMapInput
) => {
    
    const { after, before, order, limit, id, description, standardVersion, transactionIds } = filters ?? {};
    //const order: TransactionMapOrderByInput = { description: OrderDirection.Asc };
    const { data, errors } = await client.query<MapsListQuery>({
        query: QUERY_MAPS_LIST,
        variables: { 
            input: {
                after,
                before,
                order,
                limit,
                id,
                description,
                standardVersion,
                transactionIds,
            }  
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.transactionMaps as PagingResultModelOfTransactionMapModel;
};