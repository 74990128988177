import { gqlClient } from "../../components/AppProvider";
import { GetCrossReferenceInput, PagingResultModelOfCrossReferenceModel, RequestResult, UpsertCrossReferenceInput } from "../../gql-types.generated";
import { mutationDeleteEntity } from "../../gql/MutationDeleteEntity";
import { mutationUpsertCrossReference } from "../../gql/MutationUpsertCrossReference";
import { queryCrossReferences } from "../../gql/QueryCrossReferences";
import { AppDispatch, AppThunk } from "../../store";
import { getErrorMessage } from "../../util/Common";
import { captureUpsertCrossReferenceStatus, captureDeleteCrossReferenceStatus, fetchError, incrementCrossReferenceRequestInFlight, fetchCrossReferenceTableSuccess, decrementCrossReferenceRequestsInFlight } from "./CrossReferenceTableSlice";

export const fetchCrossReferenceTable = (
    filters?: GetCrossReferenceInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementCrossReferenceRequestInFlight());
    try {
        const crossReferenceTable = await queryCrossReferences(gqlClient, filters);
        if (crossReferenceTable) {
            dispatch(fetchCrossReferenceTableSuccess(crossReferenceTable as PagingResultModelOfCrossReferenceModel));
        }
        dispatch(decrementCrossReferenceRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementCrossReferenceRequestsInFlight());
    }
}


export const upsertCrossReference = ( crossReferenceInput: UpsertCrossReferenceInput) :  AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        dispatch(incrementCrossReferenceRequestInFlight());
        const upsertStatus = await mutationUpsertCrossReference(gqlClient,{
            crossReferenceInput
        });
        if (upsertStatus) {
            if (upsertStatus.result === RequestResult.Fail) {
                dispatch(fetchError({ message: upsertStatus.message } as Error));
                getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
            }
            dispatch(captureUpsertCrossReferenceStatus(upsertStatus));
            dispatch(decrementCrossReferenceRequestsInFlight());
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementCrossReferenceRequestsInFlight());
    }
};

export const deleteCrossReference = (id: string): AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        dispatch(incrementCrossReferenceRequestInFlight());
        const deleteStatus = await mutationDeleteEntity(gqlClient, {
            id,
            entityName: "X12_3060",
        });
        if (deleteStatus) {
            if (deleteStatus.result === RequestResult.Fail) {
                getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
            }
            dispatch(captureDeleteCrossReferenceStatus(deleteStatus));
            dispatch(decrementCrossReferenceRequestsInFlight());
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementCrossReferenceRequestsInFlight());
    }
};