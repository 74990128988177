import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { NotificationFormatterModel, UpsertNotificationFormatterInput, AddNotificationFormatterTransactionsInput, RequestResult, NotificationFormatterBodyType } from '../../gql-types.generated';
import { 
    selectError, 
    clearError,
    clearState, 
    selectNotificationFormatterList,
    selectFormatterPagingResult,
    selectRequestsInFlight,
    selectUpsertNotificationFormatterStatus, 
    selectAddFormatterTransactionsStatus,
    selectDeleteNotificationFormatterStatus,
    selectDeleteFormatterTransactionStatus, 
    captureUpsertNotificationFormatterStatus, 
    captureDeleteNotificationFormatterStatus,
    captureAddFormatterTransactionsStatus,
    captureDeleteFormatterTransactionStatus
 } from './NotificationFormattersSlice';
import { 
    fetchNotificationFormatterList,
    upsertNotificationFormatter, 
    deleteNotificationFormatter,
    addNotificationFormatterTransactions,
    deleteNotificationFormatterTransaction 
} from './NotificationFormattersActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid, WrappedLoadingGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, defaultPageSize, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import NotificationFormatterListItem from '../../components/listItems/NotificationFormatterListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import NotificationFormatterDialog from '../../components/dialogs/NotificationFormatterDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import NotificationFormatterTransactionPickerDialog from '../../components/dialogs/NotificationFormatterTransactionPickerDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import NotificationFormattersFilterBar from '../../components/filters/NotificationFormattersFilterBar';

interface NotificationFormattersProps {
    viewer: Viewer | undefined;
}

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids

const NotificationFormatters: React.FC<NotificationFormattersProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openTransactionSelection, setOpenTransactionSelection] = useState(false);
    const [isFormatterDialogDisplayOnlyMode, setIsFormatterDialogDisplayOnlyMode] = useState(false);
    const [isDataRefreshing, setIsDataRefreshing] = useState<boolean>(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedFormatter, setSelectedFormatter] = useState<undefined | NotificationFormatterModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterBodyType, setFilterBodyType] = useState<NotificationFormatterBodyType | undefined>(undefined);
    const [filterIsForError, setFilterIsForError] = useState<boolean | undefined>(undefined);
    const [filterTransactionId, setFilterTransactionId] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    
    const deleteNotificationFormatterStatus = useAppSelector(selectDeleteNotificationFormatterStatus);
    const upsertNotificationFormatterStatus = useAppSelector(selectUpsertNotificationFormatterStatus);
    const addFormatterTransactionStatus = useAppSelector(selectAddFormatterTransactionsStatus);
    const deleteFormatterTransactionStatus = useAppSelector(selectDeleteFormatterTransactionStatus);
    const error = useAppSelector(selectError);
    const formatterPagingResult = useAppSelector(selectFormatterPagingResult);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    
    const pageSize = defaultPageSize;

    const notificationFormatters = useAppSelector(selectNotificationFormatterList);
    
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Notification Formatters");
    
    const refreshSelectedFormatter = useCallback(() => {
        let currentSelectedFormatterId = selectedFormatter?.id;
        
        // reset selectedFormatter by finding in list to refresh since transactions content might have changed
        let notificationFormatter = notificationFormatters?.find(formatter => formatter.id === currentSelectedFormatterId);
        if (notificationFormatter) {
            setSelectedFormatter(notificationFormatter);
        }
    },[selectedFormatter, notificationFormatters]);
    
    useEffect(() => {
        // display toast message when a successful mutation occurs
        if (upsertNotificationFormatterStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertNotificationFormatterStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertNotificationFormatterStatus());
            // close the modify dialog
            onNotificationFormatterDialogClose();
        }
    }, [upsertNotificationFormatterStatus?.result]);

    useEffect(() => {
        if (deleteNotificationFormatterStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteNotificationFormatterStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteNotificationFormatterStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteNotificationFormatterStatus.message as string);
        }
    }, [deleteNotificationFormatterStatus?.result]);

    useEffect(() => {
        if (addFormatterTransactionStatus?.result === RequestResult.Success || deleteFormatterTransactionStatus?.result === RequestResult.Success) {
            if (addFormatterTransactionStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: addFormatterTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteFormatterTransactionStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteFormatterTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            dispatch(captureAddFormatterTransactionsStatus());
            dispatch(captureDeleteFormatterTransactionStatus());

            setOpenTransactionSelection(false);

            // declare the data refreshing function
            // as async so transactions list will show as loading
            // up until refresh is complete
            const refreshData = async () => {
                setIsDataRefreshing(true);
                await dispatch(fetchNotificationFormatterList());
                setIsDataRefreshing(false);
            };

            // call the refresh function
            refreshData();
        }
    }, [addFormatterTransactionStatus?.result, deleteFormatterTransactionStatus?.result]);
   
    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('formatters-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('formatters-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);

        // refresh selected formatter if have one since transactions content might have changed
        refreshSelectedFormatter();
    }, [notificationFormatters, refreshSelectedFormatter, filtersOpen]);

    useEffect(() => {
        // make sure clients has loaded before we make additional calls
        if (notificationFormatters) {
            // keep track of the number of filters to know if
            // should display badge in Filters button
            let numFilters = 0;
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            if (filterBodyType && filterBodyType.length > 0) {
                numFilters += 1;
            }
            if (filterIsForError === true) {
                numFilters += 1;
            }
            if (filterTransactionId && filterTransactionId.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [notificationFormatters, filterDescription, filterBodyType, filterIsForError, filterTransactionId]);

    useEffect(() => {
        if (!notificationFormatters && requestsInFlight > 0) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [notificationFormatters, requestsInFlight]);

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchNotificationFormatterList({
            after: endEdge,
            limit: pageSize,
            description: filterDescription,
            transactionId: filterTransactionId,
            bodyType: filterBodyType,
            isForError: filterIsForError
        }));
    };

    const handlePageLoad = (page: number) => {
        if (!formatterPagingResult) {
            return;
        }
        if (!formatterPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(formatterPagingResult.cursor.nextPage);
    };

    const hasMoreFormatters = (formatterPagingResult?.cursor?.nextPage !== null && formatterPagingResult?.cursor?.nextPage !== undefined && formatterPagingResult?.cursor?.nextPage !== '') as boolean;
    
    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'formatter-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} md={6} lg={4} xl={3} key={key}>
                    <NotificationFormatterListItem 
                        loading={true} 
                        onEditClick={editFormatter}
                        onDeleteClick={deleteFormatter}
                        onAddTransactionClick={addTransaction}
                        clickAction={cardClickAction}
                    />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'formatter-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the Notification Formatters' error={error}></ErrorMessage>
            );
        }
        if (!notificationFormatters && requestsInFlight > 0) {
            return getLoaders();
        }
        else if (notificationFormatters && notificationFormatters.length > 0) {
            return (
                notificationFormatters.map((notificationFormatter: NotificationFormatterModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={9} md={6} lg={4} xl={3} key={notificationFormatter.id}>
                        <NotificationFormatterListItem
                            formatter={notificationFormatter}
                            canDelete={canAddOrDelete}
                            onEditClick={editFormatter}
                            onDeleteClick={deleteFormatter}
                            onAddTransactionClick={addTransaction}
                            clickAction={cardClickAction}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // No formatters to show 
            // Display appropriate no results/records found image/message
            if (filterCount > 0) {
                return (<NoResultsMessage />);
            }
            else {
                return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New Notification Formatter" actionButtonClick={onAddNotificationFormatterClick} />
                    :
                    <NoRecordsMessage message="" />
                );
            }
        }
    };

    const onAddNotificationFormatterClick = () => {
        // Clear error and open dialog
        handleClearError();
        // ensure no previously selected formatter is set
        if (selectedFormatter) {
            setSelectedFormatter(undefined);
        }
        setIsFormatterDialogDisplayOnlyMode(false);
        setOpenModify(true);
    };

    const onNotificationFormatterDialogViewEditCancelClick = () => {
        // if a user types values in edit mode, and then hits cancel,
        // the values will display in view mode as if they had been saved,
        // so need to clear and reset selected to wipe out edited fields
        let currentFormatter = selectedFormatter;
        
        // first clear selected
        setSelectedFormatter(undefined);

        // wrap in a timeout just to separate the reset from the clear
        // otherwise the clear doesn't trigger the dialog useEffect
        setTimeout(() => {
            // then reset to current to refresh
            setSelectedFormatter(currentFormatter);
        });
    };

    const onNotificationFormatterDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onTransactionPickerDialogClose = () => {
        setOpenTransactionSelection(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteNotificationFormatterStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedFormatter on close.
        handleClearError();
        setSelectedFormatter(undefined);
        // Refresh list to bring in potential updates
        loadPage(undefined);
    };

    const onNotificationFormatterDialogSave = (inputData: UpsertNotificationFormatterInput) => {
        // upsert to save data
        dispatch(upsertNotificationFormatter(inputData));
    };

    const onTransactionPickerDialogSave = (inputData: AddNotificationFormatterTransactionsInput) => {
        selectedFormatterAddTransactions(inputData);
    };

    const onFormatterDialogAddTransactions = (inputData: AddNotificationFormatterTransactionsInput) => {
        selectedFormatterAddTransactions(inputData);
    }

    const selectedFormatterAddTransactions = (inputData: AddNotificationFormatterTransactionsInput) => {
        if (inputData) {
            dispatch(
                addNotificationFormatterTransactions(
                    inputData
                )
            );
        }
    };

    const onDeleteFormatterTransaction = (formatterTransactionId: string) => {
        if (formatterTransactionId) {
            dispatch(deleteNotificationFormatterTransaction(formatterTransactionId));
        }
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete the selected notification formatter
        dispatch(deleteNotificationFormatter(id));
    }

    const openEditFormatterDialog = (id: string) => {
        if (notificationFormatters) {
            if (id && notificationFormatters.length) {
                let notificationFormatter = notificationFormatters.find(formatter => formatter.id === id);
                if (notificationFormatter) {
                    setSelectedFormatter(notificationFormatter);
                    handleClearError();
                    setOpenModify(true);
                }
            }
        }
    }

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            // open the addEditDialog as display only
            setIsFormatterDialogDisplayOnlyMode(true);
            openEditFormatterDialog(id);
        }
    };

    //edit selected notification formatter
    const editFormatter = (id: string | undefined) => {
        if (id) {
            // open the addEditDialog for actual edit
            setIsFormatterDialogDisplayOnlyMode(false);
            openEditFormatterDialog(id);
        }
    }

    const addTransaction = (id: string) => {
        if (notificationFormatters) {
            if (id && notificationFormatters.length) {
                let notificationFormatter = notificationFormatters.find(formatter => formatter.id === id);
                if (notificationFormatter) {
                    setSelectedFormatter(notificationFormatter);
                    handleClearError();
                    dispatch(captureAddFormatterTransactionsStatus());
                    setOpenTransactionSelection(true);
                }
            }
        }
    };

    //delete selected notification formatter
    const deleteFormatter = (id: string | undefined) => {
        // TODO: once notification formatters are set up to be connected to transactions, 
        // don't allow delete if has a transaction (or prompt to warn and confirm and then delete link to transaction as well)
        if (notificationFormatters) {
            if (id && notificationFormatters.length) {
                let notificationFormatter = notificationFormatters.find(formatter => formatter.id === id);
                if (notificationFormatter) {
                    setSelectedFormatter(notificationFormatter);
                    dispatch(captureDeleteNotificationFormatterStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    }

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (descriptionFilter: string | undefined, transactionIdFilter: string | undefined, bodyTypeFilter: NotificationFormatterBodyType | undefined, isForErrorFilter: boolean | undefined) => {
        setFilterDescription(descriptionFilter);
        setFilterIsForError(isForErrorFilter);
        setFilterBodyType(bodyTypeFilter);
        setFilterTransactionId(transactionIdFilter);

        dispatch(clearState());

        // filters changed so refresh the list
        dispatch(fetchNotificationFormatterList({
            after: undefined,
            limit: pageSize,
            description: descriptionFilter,
            bodyType: bodyTypeFilter,
            transactionId: transactionIdFilter,
            isForError: isForErrorFilter,
        }));
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Notification Formatter Management' id="formatters-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Notification Formatter"
                            onClick={onAddNotificationFormatterClick}
                            data-cy="add-new-notification-formatter"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <NotificationFormattersFilterBar
                id="formatters-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}/>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handlePageLoad}
                    hasMore={hasMoreFormatters}
                    loader={getLoaders(true) as ReactElement}
                    useWindow={false}
                    initialLoad={false}
                >
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </InfiniteScroll>
            </CardListScrollBox>
            <NotificationFormatterDialog
                isOpen={openModify}
                viewerRole={viewer}
                isRefreshing={isDataRefreshing}
                isReadOnly={isFormatterDialogDisplayOnlyMode}
                id={selectedFormatter?.id}
                onClose={onNotificationFormatterDialogClose}
                onCancel={onNotificationFormatterDialogViewEditCancelClick}
                onSave={onNotificationFormatterDialogSave}
                onAddTransactions={onFormatterDialogAddTransactions}
                onDeleteTransaction={onDeleteFormatterTransaction}
                clearError={handleClearError}
                saveResult={upsertNotificationFormatterStatus?.result}
                addTransactionsResult={addFormatterTransactionStatus?.result}
                deleteTransactionStatus={deleteFormatterTransactionStatus}
                error={error}
            />
            <NotificationFormatterTransactionPickerDialog
                isOpen={openTransactionSelection}
                formatterId={selectedFormatter?.id}
                onClose={onTransactionPickerDialogClose}
                onSave={onTransactionPickerDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedFormatter?.id}
                heading={'Delete Notification Formatter'}
                message={'Are you sure you want to delete \'' + selectedFormatter?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default NotificationFormatters;