import { useState } from 'react';
import { Divider, IconButton, Skeleton, Menu, MenuItem } from '@mui/material';
import { Maybe } from '../../gql-types.generated';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CardChip, CardFooter, RootCard, RootCardHeader } from '../../util/SharedStyles';

interface ProductListItemProps {
    name?: string | undefined;
    id?: string | undefined;
    description?: string | undefined;
    isAptean?: Maybe<boolean> | undefined;
    loading?: boolean | false;
    canDelete?: boolean;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
}

const ProductListItem: React.FC<ProductListItemProps> = (props: ProductListItemProps) => {
    const { name, description, isAptean = false, loading = false, canDelete = false } = props;
    const id = props.id as string;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(id);
        }
        setAnchorEl(null);
    };

    let action = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        name
    );

    let subheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
    ) : (
       description
    );
    
    let apteanChip;
    if (isAptean === true) {
        apteanChip = <CardChip label="Aptean" />;
    }

    return (
        <RootCard>
            <RootCardHeader
                title={title} 
                subheader={subheader}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light/>
            <CardFooter justify='flex-end'>
                {
                    loading ? (
                        <Skeleton variant="text" animation="wave" height={32} width={92} />
                    ) : (
                        apteanChip
                    )
                }
            </CardFooter>
           
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                { canDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    >
                    Delete    
                </MenuItem>
                }
            </Menu> 
        </RootCard>
    );
};

export default ProductListItem;