import { ChangeEvent, useEffect, useState } from "react";
import { Grid, FormControl, InputLabel, TextField, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";
import { BlankMenuItem } from "../../util/SharedStyles";
import { EdiStaticFileModel, EdiStaticFileDocumentType, UpsertEdiStaticFileInput, UserRole } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';
import { maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";

interface DialogProps {
    isOpen: boolean;
    resource?: EdiStaticFileModel | null | undefined;
    viewerRole?: UserRole | undefined;
    isReadOnly: boolean;
    onClose: () => void;
    onSave: (resourceDetails: UpsertEdiStaticFileInput) => void;
    error?: Error | undefined;
}

const EDIStaticFileDialog: React.FC<DialogProps> = props => {
    const { isOpen, resource, viewerRole, isReadOnly, onClose, onSave, error } = props;
    const id = resource?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [fileString, setFileString] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [fileType, setFileType] = useState<EdiStaticFileDocumentType | undefined>(undefined);
    const [existingResourceFile, setExistingResourceFile] = useState<File | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    const [isViewMode, setIsViewMode] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            resetInitialState();
        } else {
            setFromResource();
        }
    }, [isOpen]);

    useEffect(() => {
        if (resource) {
            setFromResource();
        } else {
            resetInitialState();
        }
    }, [resource]);

    const setFromResource = () => {
        if (resource) {
            if (resource.description) {
                setDescription(resource.description);
            }
            if (resource.documentString) {
                setFileString(resource.documentString);
            }
            if (resource.fileName) {
                setFileName(resource.fileName);
            }
            if (resource.fileName && resource.documentString !== undefined && resource.documentString !== null ) {
                let resourceFile = new File([resource.documentString], resource.fileName);
                setExistingResourceFile(resourceFile);
            }
            if (resource.documentType) {
                setFileType(resource.documentType);
            }
        }
    };

    const resetInitialState = () => {
        setSubmitted(false);
        setDescription(undefined);
        setFileString('');
        setFileName('');
        setFileType(undefined);
        setExistingResourceFile(undefined);
        setIsFormDirty(false);
    }

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            setFromResource();
        } else {
            onClose();
        }
    };


    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = fileName?.trim().length > 0 && fileString?.trim().length > 0 && fileType !== undefined;
        return isFormDirty && requiredCheck;
    }

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            description: description,
            documentString: fileString,
            id: id,
            documentType: fileType,
            fileName: fileName
        } as UpsertEdiStaticFileInput);
    }

    const onFileNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setFileName(event.target.value);
    };

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onFileTypeChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setFileType(event.target.value as EdiStaticFileDocumentType);
        } else {
            setFileType(undefined);
        }
    };

    const getFileString = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            let fileName = fileToUpload?.name;
            var ext =  fileName?.split('.').pop();
            let fileString = await getFileString(fileToUpload);
            setFileString(fileString);
            setFileName(fileName);
            setFileType(ext ? ext.toUpperCase() as EdiStaticFileDocumentType : undefined);

        } else {
            // when no file, make sure any previous file fields are cleared            
            setFileString('');
            setFileName('');
            setFileType(undefined);
        }
    };

    const fileTypes = Object.values(EdiStaticFileDocumentType);
    
    const getFileTypeDropList = () => {
        if (fileTypes && fileTypes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                fileTypes.map((type: EdiStaticFileDocumentType) => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                        {type.toUpperCase()}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };


    const fileNameProps = {
        'aria-label': 'file name',
        'maxLength': 255,
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    const typeProps = {
        'aria-label': 'resource type',
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };


    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Resource"
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            onCancel={onEditCancel}
            isReadOnly={isViewMode}
            onViewModeEdit={viewModeEditForm}
            canEditFromReadOnly={true}
            viewerRole={viewerRole}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Resource File *"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your resource file here"
                        existingFiles={existingResourceFile ? [existingResourceFile] : undefined}
                        onChange={onFileUploadChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                        <InputLabel id="dialog-resource-type-label">Resource Type</InputLabel>
                        <Select
                            labelId="dialog-resource-type-label"
                            aria-labelledby="dialog-resource-type-label"
                            value={fileType ?? ""}
                            onChange={onFileTypeChange}
                            disabled={submitted}
                            MenuProps={typeProps}
                            required
                            data-cy="dialog-resource-type"
                        >
                            {getFileTypeDropList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-resource-name"
                        fullWidth
                        disabled={submitted}
                        value={fileName}
                        label="File Name"
                        inputProps={fileNameProps}
                        onChange={onFileNameChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-resource-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-resource-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-resource-description"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    )

}

export default EDIStaticFileDialog;