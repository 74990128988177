import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { GridColumns, GridRowModel, GridRowParams, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { IconButton, Stack, Tooltip } from '@mui/material';
import { IconAvatar } from '../../../util/SharedStyles';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OutboundIcon from '../../../icons/outbound.svg';
import InboundIcon from '../../../icons/inbound.svg';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import { selectDeleteClientTransactionStatus, selectEligibleTransactions, selectError, selectUpsertClientTransactionStatus, clearError, captureDeleteClientTransactionStatus, captureUpsertClientTransactionStatus } from "../../../features/ClientDetails/ClientDetailsSlice";

import { ClientModel, ClientTransactionModel, TransactionDirection, TransactionModel, UserRole, DeleteByIdPayload, RequestResult, UpsertClientTransactionInput, PipelineModel } from "../../../gql-types.generated";
import { viewerCanEdit } from "../../../util/ViewerUtility";
import { fetchEligibleTransactions, upsertClientTransaction, deleteClientTransaction } from "../../../features/ClientDetails/ClientDetailsActions";
import TransactionList from "./TransactionList";
import EditClientTransactionDialog from "../../dialogs/EditClientTransactionDialog";
import TransactionPipelineDialog from '../../dialogs/TransactionPipelineDialog';

interface ClientTransactionListProps {
    viewerRole: UserRole | undefined;
    client: ClientModel;
    clientTransactions: ClientTransactionModel[] | undefined;
    refreshClientData: () => void;
}

const ClientTransactionList: React.FC<ClientTransactionListProps> = (props: ClientTransactionListProps) => {
    const { viewerRole, client, clientTransactions, refreshClientData } = props;
    const { id: clientId } = client;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [currentTransactionRows, setCurrentTransactionRows] = useState<GridRowModel[]>([]);
    const [availableTransactionRows, setAvailableTransactionRows] = useState<GridRowModel[]>([]);
    const [isLoadingAvailableTransactions, setIsLoadingAvailableTransactions] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState<DeleteByIdPayload | undefined>()
    const [selectedClientTransaction, setSelectedClientTransaction] = useState<ClientTransactionModel>();
    const [addResult, setAddResult] = useState<RequestResult | undefined>();
    const [transactionIsReadMode, setTransactionIsReadMode] = useState(true);
    const [openPipeline, setOpenPipeline] = useState(false);

    const deleteClientTransactionStatus = useAppSelector(selectDeleteClientTransactionStatus);
    const upsertClientTransactionStatus = useAppSelector(selectUpsertClientTransactionStatus);
    const error = useAppSelector(selectError);
    const transactionOptions = useAppSelector(selectEligibleTransactions);

    useEffect(() => {
        setCurrentTransactionRows(getCurrentTransactionRows());
        if (transactionOptions) {
            setAvailableTransactionRows(getAvailableTransactionRows());
        }
    }, [clientTransactions]);

    useEffect(() => {
        setIsLoadingAvailableTransactions(true);
        dispatch(fetchEligibleTransactions());
    }, []);

    useEffect(() => {
        if (transactionOptions) {
            setAvailableTransactionRows(getAvailableTransactionRows());
        }
    }, [transactionOptions])

    useEffect(() => {
        if (upsertClientTransactionStatus && upsertClientTransactionStatus.result) {
            // set the result to be passed to base component
            setAddResult(upsertClientTransactionStatus.result);
            refreshClientData();

            if (upsertClientTransactionStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertClientTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
                onEditTransactionClose();

                // wrap status removal in a timeout
                // otherwise it gets removed before base component receives the result
                // which causes the add dialog to stay open
                setTimeout(() => {
                    // remove upsert status
                    dispatch(captureUpsertClientTransactionStatus());
                });
            }
        }
        else {
            setAddResult(undefined);
        }

        if (deleteClientTransactionStatus && deleteClientTransactionStatus.result) {
            // set the status to be passed to base component
            setDeleteStatus(deleteClientTransactionStatus);
            refreshClientData();
        }
        else {
            setDeleteStatus(undefined);
        }
    }, [deleteClientTransactionStatus?.result, upsertClientTransactionStatus?.result]);

    const deleteTransactionHandler = useCallback((rowId: string) => () => {
        onDeleteClientTransaction(rowId);
        setOpenDelete(true);
    }, [clientTransactions]);

    const editTransactionHandler = useCallback((rowId: string) => () => {
        onEditClientTransaction(rowId);
        setOpenEdit(true);
    }, [clientTransactions]);

    const onDeleteClientTransaction = (selectedRowId: string) => {
        let transaction = findExistingTransaction(selectedRowId);

        if (transaction) {
            setSelectedClientTransaction(transaction);
            // clear the status
            dispatch(captureDeleteClientTransactionStatus());
        }
    };

    const onEditClientTransaction = (selectedRowId: string) => {
        let transaction = findExistingTransaction(selectedRowId);

        if (transaction) {
            setSelectedClientTransaction(transaction);
            // clear the status
            dispatch(captureUpsertClientTransactionStatus());
            setTransactionIsReadMode(false);
        }
    };

    const onRowClick = (selectedRowParams: GridRowParams) => {
        let transaction = findExistingTransaction(selectedRowParams.row.id);
        if (transaction) {
            setSelectedClientTransaction(transaction);
            setTransactionIsReadMode(true);
            setOpenEdit(true);
        }
    }

    const onEditTransactionClose = () => {
        setSelectedClientTransaction(undefined);
        setOpenEdit(false);
        setOpenPipeline(false);
        setTransactionIsReadMode(true);
    }

    const onEditTransactionSave = (upsertClientTransactionData: UpsertClientTransactionInput) => {
        if (upsertClientTransactionData) {
            dispatch(upsertClientTransaction(upsertClientTransactionData));
        }
    }

    const onEditPipeline = (params: GridRenderCellParams) => {
        const rowId = params.row.id;
        let transaction = findExistingTransaction(rowId);
        if (transaction) {
            setSelectedClientTransaction(transaction);
            setOpenPipeline(true);
        }
    };

    const onPipelineClose = () => {
        // unset selected transaction
        setSelectedClientTransaction(undefined);
        // close the dialog
        setOpenPipeline(false);
    }

    const onPipelineSave = (
        pipeline?: PipelineModel
    ) => {
        if (selectedClientTransaction) {
            dispatch(upsertClientTransaction({
                clientId: clientId,
                id: selectedClientTransaction.id,
                pipelineId: pipeline?.id,
                transactionId: selectedClientTransaction.transaction?.id
            } as UpsertClientTransactionInput));
        }
    }

    const getCurrentTransactionRows = () => {
        return clientTransactions
            ? (clientTransactions?.map((clientTransaction: ClientTransactionModel) => {
                const transactionNode = clientTransaction?.transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const schemaDescription = clientTransaction.erpTransactionSchema?.description;
                const { name, direction, description } = transactionNode;
                const pipeline = clientTransaction.pipeline;
                let pipelineDescription = pipeline?.description;

                return {
                    _raw: transactionNode,
                    id: clientTransaction?.transactionId,
                    direction,
                    type: name,
                    description,
                    mapStatus: undefined,
                    pipelineDescription: pipelineDescription,
                    schemaDescription
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
    };

    const transactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'direction',
                headerName: 'DIRECTION',
                minWidth: 120,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                align: 'left',
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const directionIcon = (value === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;

                    return (
                        <Tooltip title={value}>
                            <IconAvatar aria-label={value?.toLowerCase()}>
                                {directionIcon}
                            </IconAvatar>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'type',
                headerName: 'NAME',
                minWidth: 90,
                flex: 1,
                sortable: true,
            },
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 130,
                flex: 2,
                sortable: true,
            },
            {
                hide: true,
                field: 'pipelineDescription',
                headerName: 'PIPELINE',
                minWidth: 144,
                flex: 1,
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const displayString = (value) ? value : "Pipeline Not Set";
                    if (canEdit) {
                        return (
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                {displayString}
                                <IconButton color="primary" aria-label="edit-Pipeline" onClick={(e) => {
                                    e.stopPropagation();
                                    onEditPipeline(params);
                                }}>
                                    <EditIcon />
                                </IconButton>
                            </Stack>
                        );
                    } else {
                        return displayString;
                    }

                }
            },
            {
                field: 'schemaDescription',
                headerName: 'ERP SCHEMA',
                minWidth: 140,
                flex: 1,
                sortable: true,
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                minWidth: 60,
                flex: 1,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteTransactionHandler, editTransactionHandler, canEdit],
    );



    const getAvailableTransactionRows = () => {
        let rows = transactionOptions
            ? (transactionOptions?.flatMap((transaction: TransactionModel) => {
                const transactionNode = transaction;
                if (!transactionNode) {
                    return [];
                }
                const { id, name, description, direction } = transactionNode;
                const existsOnClient = isExistingTransaction(id);

                return existsOnClient ? [] : [
                    {
                        _raw: transactionNode,
                        id: id,
                        direction,
                        type: name,
                        description,
                        isSelectable: !existsOnClient,
                    } as GridRowModel
                ]
            }) as GridRowModel[])
            : [];
        setIsLoadingAvailableTransactions(false);
        return rows;
    };

    const isExistingTransaction = (transactionId: string) => {
        // check if this transaction is already a partner transaction
        if (clientTransactions && transactionId) {
            let matchFound = findExistingTransaction(transactionId);
            return matchFound ? true : false;
        }
        return false;
    }

    const findExistingTransaction = (transactionId: string) => {
        // check if this transaction is already a partner transaction
        if (clientTransactions && transactionId) {
            let matchFound = clientTransactions.find((ct: ClientTransactionModel) => ct.transactionId === transactionId);
            return matchFound;
        }
        return undefined;
    }

    const onDeleteDialogConfirm = () => {
        // delete the selected client transaction
        if (selectedClientTransaction?.id) {
            dispatch(deleteClientTransaction(selectedClientTransaction?.id as string));
        }
    };

    const onDeleteDialogClose = () => {
        // clear the delete status
        dispatch(captureDeleteClientTransactionStatus());
        // unset selected transaction
        setSelectedClientTransaction(undefined);
        // close the dialog
        setOpenDelete(false);
    };

    const onAddDialogSave = (selectedTransactionIds?: string[]) => {
        if (selectedTransactionIds && selectedTransactionIds.length > 0) {
            //returns array,but since single select we only need the 
            const selectedTransactionId = selectedTransactionIds[0];
            // dispatch save of clientPartner
            dispatch(upsertClientTransaction({
                clientId,
                transactionId: selectedTransactionId
            } as UpsertClientTransactionInput));
        }
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <TransactionList
                viewerRole={viewerRole}
                parentEntityTypeName="Client"
                transactionGridColumns={transactionColumns}
                currentTransactionRows={currentTransactionRows}
                availableTransactionRows={availableTransactionRows}
                loadingAvailableRows={isLoadingAvailableTransactions}
                deleteStatus={deleteStatus}
                addResult={addResult}
                singleSelect={true}
                onDeleteDialogConfirm={onDeleteDialogConfirm}
                onDeleteDialogClose={onDeleteDialogClose}
                onAddDialogSave={onAddDialogSave}
                openDeleteDialog={openDelete}
                refreshList={refreshClientData}
                clearError={handleClearError}
                error={error}
                onRowClick={onRowClick}
            />
            <EditClientTransactionDialog
                isOpen={openEdit}
                viewerRole={viewerRole}
                clientId={clientId}
                clientTransaction={selectedClientTransaction as ClientTransactionModel}
                clientHasNotificationRecipients={(client.clientNotificationRecipients && client.clientNotificationRecipients?.length > 0) ? true : false}
                isReadOnly={transactionIsReadMode}
                onClose={onEditTransactionClose}
                onSave={onEditTransactionSave}
                error={error}
            />
            <TransactionPipelineDialog
                isOpen={openPipeline}
                pipeline={selectedClientTransaction?.pipeline as PipelineModel}
                onClose={onPipelineClose}
                onSave={onPipelineSave}
                error={error}
            />
        </>
    );
}

export default ClientTransactionList;