import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertSystemNotificationPayload, UpsertSystemNotificationInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_SYSTEM_NOTIFICATION = gql`
    mutation upsertSystemNotification($input: UpsertSystemNotificationInput!){
        upsertSystemNotification(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertSystemNotification = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertSystemNotificationData: UpsertSystemNotificationInput
    }
): Promise<UpsertSystemNotificationPayload | undefined> => {
    const { id, message, dateStart, dateEnd } = args.upsertSystemNotificationData;
    const { data, errors } = await client.mutate<{ upsertSystemNotification: UpsertSystemNotificationPayload }>({
        variables: {
            input: {
                id,
                message,
                dateStart,
                dateEnd
            }
        },
        mutation: MUTATION_UPSERT_SYSTEM_NOTIFICATION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the system configuration save.');
    }
    if (data && data.upsertSystemNotification) {
        return data.upsertSystemNotification;
    }
    return undefined;
}