import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled, Dialog, DialogTitle, Grid, Typography, Breakpoint } from "@mui/material";
import ConfirmationPrompt from "../ConfirmationPrompt";

import { synchronizeWithPortal } from '../../features/EDIContainer/EDIContainerActions';
import { capturePortalSyncStatus, selectPortalSyncStatus, selectError } from '../../features/EDIContainer/EDIContainerSlice';
import { PaddedDialogContent } from '../../util/SharedStyles';
import { isJson } from "../../util/Common";
import DialogCloseButton from "./DialogCloseButton";


const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));

const TypographyWithReturns = styled(Typography)((props) => ({
    whiteSpace: 'pre-line',
}));


interface PortalSyncDialogProps {
    isOpen: boolean;
    onClose: () => void;
}

const PortalSyncDialog: React.FC<PortalSyncDialogProps> = props => {
    const { isOpen, onClose } = props;
    const dispatch = useAppDispatch();

    const [isSyncing, setIsSyncing] = useState(false);
    const [syncResultMessage, setSyncResultMessage] = useState('');
    
    const error = useAppSelector(selectError);
    const portalSyncStatus = useAppSelector(selectPortalSyncStatus);

    useEffect(() => {
        if (error) {
            setIsSyncing(false);
            return;
        }
    }, [error]);

    useEffect(() => {
        if (portalSyncStatus?.result) {
            setIsSyncing(false);
            
            if (portalSyncStatus.message) {
                setSyncResultMessage(portalSyncStatus.message);
            }
           
            dispatch(capturePortalSyncStatus());
        }
        

    }, [portalSyncStatus?.result]);

    const onSyncClick = () => {
        setIsSyncing(true);
        dispatch(synchronizeWithPortal());
    }

    const handleClose = () => {
        onClose();
        setIsSyncing(false);
        setSyncResultMessage('');
    };

    const title = "Synchronize Portal to EDI";
    
    return (
        <Dialog
            open={isOpen}
            aria-label={title}
            scroll="paper"
        >
            <TitleBar id='dialog-title'>
                <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="body1">{title}</Typography>
                </TitleGrid>
                <DialogCloseButton onClick={handleClose}></DialogCloseButton>
            </TitleBar>
            {!syncResultMessage &&
            <ConfirmationPrompt
                heading=""
                message="Are you sure you want to sync Portal EDI to EDI?"
                confirmButtonText={'Sync'}
                rejectButtonText={'Cancel'}
                handleConfirm={onSyncClick}
                handleReject={handleClose}
                isBusy={isSyncing}
                errorMessage={error?.message ?? undefined}
            />
            }
            {syncResultMessage &&
            <PaddedDialogContent dividers padding={"10px"} maxheight='400px'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TypographyWithReturns variant='body1' >{syncResultMessage}</TypographyWithReturns>
                    </Grid>
                </Grid>
            </PaddedDialogContent>
            }
        </Dialog>
    );
};

export default PortalSyncDialog;