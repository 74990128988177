import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPricingPayload, UpsertPricingInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PRICING = gql`
    mutation upsertPricing($input: UpsertPricingInput!){
        upsertPricing(input: $input){
            message
            errors {
                code
                message
            }
            result
            pricing {
                id
            }
        }
    }
`;

export const mutationUpsertPricing = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        description: string;
        currencyCode: string;
        minServiceFees: number;
        id?: string;
    }
): Promise<UpsertPricingPayload | undefined> => {
    const { minServiceFees, description, currencyCode, id } = options as UpsertPricingInput;
    const { data, errors } = await client.mutate<{ upsertPricing: UpsertPricingPayload}>({
        variables: { 
            input: { 
                description,
                currencyCode,
                minServiceFees, 
                id 
            } 
        },
        mutation: MUTATION_UPSERT_PRICING
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of Pricing.');
    }
    if (data && data.upsertPricing) {
        return data.upsertPricing;
    }

    return undefined;
};