import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { TransactionsListQuery, GetTransactionsInput, TransactionDirection } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRANSACTIONS_LIST = gql`
  query transactionsList($input: GetTransactionsInput!) {
    transactions(input: $input) {
      nodes {
          id 
          name 
          description 
          ediStandard {
            id 
            name 
          } 
          direction 
          isActive 
          isBillable 
          ediStandardId 
          billableFactor
          normalizedDocumentType
          transactionXPath {
            id
          }
      }
    }
  }
`;

export const queryTransactionList = async (
  transaction: ApolloClient<NormalizedCacheObject>,
  filters: {
    id?: string | undefined;
    name?: string | undefined;
    description?: string | undefined;
    ediStandardIds?: string[] | undefined;
    isActive?: boolean | undefined;
    isBillable?: boolean | undefined;
    direction?: TransactionDirection | undefined;
  }
) => {

  const { id, name, description, ediStandardIds, isActive, isBillable, direction } = filters as GetTransactionsInput;
  const { data, errors } = await transaction.query<TransactionsListQuery>({
    query: QUERY_TRANSACTIONS_LIST,
    variables: { 
      input: {
        id: id,
        name: name,
        description: description,
        ediStandardIds: ediStandardIds,
        isActive: isActive,
        isBillable: isBillable,
        direction: direction
      }  
    }
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.transactions?.nodes;
};