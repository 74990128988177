import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PagingResultModelOfEdiStaticFileModel, GetEdiStaticFileInput, EdiStaticFileListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_EDI_STATIC_FILE_LIST = gql`
    query ediStaticFileList($input: GetEdiStaticFileInput!) {
        ediStaticFiles (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                fileName
                description
                documentType
                documentString
            }
        }
    }
`;

export const queryEdiStaticFileList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetEdiStaticFileInput
): Promise<PagingResultModelOfEdiStaticFileModel | undefined> => {
    const { after, limit, fileName, description, documentType, order } = filters ?? {};
    const { data, errors } = await client.query<EdiStaticFileListQuery>({
        variables: {
            input: {
                after,
                limit,
                fileName: fileName?.trim(),
                description: description?.trim(),
                documentType: documentType?.trim(),
                order
            }
        },
        query: QUERY_EDI_STATIC_FILE_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.ediStaticFiles as PagingResultModelOfEdiStaticFileModel;
}