import { ChangeEvent, useEffect, useState } from "react";
import { Grid, TextField, styled } from "@mui/material";
import { UserRole } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '440px', 
}));

interface AliasDialogProps {
    isOpen: boolean;
    parentId: string;
    transactionAlias?: string;
    transactionAliasId?: string;
    viewerRole?: UserRole | undefined;
    onClose: () => void;
    onSave: (parentId: string, alias: string, aliasId?: string) => void;
    error?: Error | undefined;
}

const ClientPartnerTransactionAliasDialog: React.FC<AliasDialogProps> = props => {
    const { isOpen, parentId, transactionAliasId, transactionAlias, viewerRole, onClose, onSave, error } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [alias, setAlias] = useState('');
    
    useEffect(() => {
        if (transactionAlias) {
            setFromTransactionAlias();
        } else {
            setDefaults();
        }
    }, [transactionAlias]);

    useEffect(() => {
        if (!isOpen) {
            setDefaults();
        } else {
            setFromTransactionAlias();
        }
    }, [isOpen]);

    const setFromTransactionAlias = () => {
        if (transactionAlias) {
            setAlias(transactionAlias);
        }
    };

    const setDefaults = () => {
        setIsDirty(false);
        setSubmitted(false);
        setAlias('');
    };

    const saveEntityAlias = () => {
        if (isDirty) {
            setSubmitted(true);
            onSave(parentId, alias, transactionAliasId);
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        let requiredCheck = alias?.trim().length > 0;

        return isDirty && requiredCheck;
    };

    
    const onAliasChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        setAlias(event.target.value);
    };
    
    const aliasProps = {
        'aria-label': 'alias',
        'maxLength': 255,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            titlePrefix={transactionAliasId ? "Edit" : "Add"}
            entityName={`Additional Transaction Alias`}
            id={transactionAliasId}
            viewerRole={viewerRole}
            onCancel={onClose}
            onClose={onClose}
            onSave={saveEntityAlias}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='lg'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-trans-alias"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={alias}
                        label="Alias"
                        inputProps={aliasProps}
                        onChange={onAliasChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-trans-alias"
                        variant="standard"
                    />
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
}

export default ClientPartnerTransactionAliasDialog;