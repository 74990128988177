import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { DateTime } from 'luxon';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from "@mui/x-date-pickers";
import { Stack, Grid, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, styled } from '@mui/material';
import { BlankMenuItem } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Viewer } from "../../util/Constants";
import { getLocalStorageItem, setLocalStorageItem, removeLocalStorageItem } from '../../util/ViewerUtility';
import { capitalizeWithUnderscoreRemoval, PremadeEventLogFilterSet } from '../../util/Common';
import { fetchClientNameSearchResults } from '../../features/Clients/ClientsActions';
import { fetchClientNameSearchResultsSuccess, selectClientNameSearchResults } from '../../features/Clients/ClientsSlice';
import { fetchTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersActions';
import { fetchTradingPartnerNameSearchResultsSuccess, selectTradingPartnerNameSearchResults } from '../../features/TradingPartners/TradingPartnersSlice';
import { DocumentStatus, ProcessName } from '../../gql-types.generated';
import FilterBar from "./FilterBar";
import AutocompleteFilterField from '../fields/AutocompleteFilterField';

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface LogsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    premadeSet?: PremadeEventLogFilterSet | null; 
    onClose: () => void;
    onFilterChanged: (
        filterClient: string | undefined, 
        filterIO?: string | undefined, 
        filterDocType?: string | undefined,
        filterPartner?: string | undefined,
        filterPONum?: string | undefined,
        filterDocNum?: string | undefined,
        filterStatusVal?: DocumentStatus | undefined,
        filterProcess?: ProcessName | undefined,
        filterDocDateFrom?: DateTime | undefined,
        filterDocDateTo?: DateTime | undefined,
        filterOriginalFileName?: string | undefined
    ) => void;
}

const EventLogsFilterBar: React.FC<LogsFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, premadeSet, onFilterChanged } = props;
    const dispatch = useAppDispatch();
    const [filterClientName, setFilterClientName] = useState<string | undefined>();
    const [searchClientName, setSearchClientName] = useState<string>('');
    const [filterDirection, setFilterDirection] = useState<string | undefined>();
    const [filterDocType, setFilterDocType] = useState<string | undefined>();
    const [filterPartnerName, setFilterPartnerName] = useState<string | undefined>(undefined);
    const [searchPartnerName, setSearchPartnerName] = useState<string>('');
    const [filterPO, setFilterPO] = useState<string | undefined>(undefined);
    const [filterDocNumber, setFilterDocNumber] = useState<string | undefined>(undefined);
    const [filterProcessName, setFilterProcessName] = useState<ProcessName | undefined>(undefined);
    const [filterStatus, setFilterStatus] = useState<DocumentStatus | undefined>(undefined);
    const [filterDateFrom, setFilterDateFrom] = useState<DateTime | undefined>(undefined);
    const [filterDateTo, setFilterDateTo] = useState<DateTime | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const fieldsDisabled = visible && loading;

    const clientNameSearchResults = useAppSelector(selectClientNameSearchResults);
    const partnerNameSearchResults = useAppSelector(selectTradingPartnerNameSearchResults);

    const statuses = Object.values(DocumentStatus);
    const processes = Object.values(ProcessName);
    
    const onSearchClick = () => {
        onFilterChanged(filterClientName, filterDirection, filterDocType, filterPartnerName, filterPO, filterDocNumber, filterStatus, filterProcessName, filterDateFrom, filterDateTo, filterFileName);
    }

    const onCloseClick = () => {
        clearAndRemoveLocalFilters();
        props.onClose();
        // trigger a new search with client undefined since state of filter values might not be updated yet
        onFilterChanged(undefined);
    };    

    useEffect(() => {
        // premadeSet has 3 conditions
        // - null - the possible EventLog Param hasn't been set, Do nothing so multiple calls are not made
        // - undefined - there was no EventLog Param, Check for LocalStorage filters
        // - PremadeEventLogFilterSet Object - premade filters to be applied
        if (premadeSet !== null) {
            if (premadeSet) {
                setFromPremade();
            } else {
                setFromLocalStorage();
            }
        }
    }, [premadeSet])

    
    const setFromLocalStorage = () => {
        clearFilters();
        let haveLocalStoredFilters = false;
    
        // get the filters in localStorage if there are any 
        let name = getLocalStorageItem(viewer, "logFilterClientName");
        if (name != null) {
            setFilterClientName(name);
            haveLocalStoredFilters = true;
        }
        let type = getLocalStorageItem(viewer, "logFilterDocType");
        if (type != null) {
            setFilterDocType(type);
            haveLocalStoredFilters = true;
        }
        let po = getLocalStorageItem(viewer, "logFilterPONum");
        if (po != null) {
            setFilterPO(po);
            haveLocalStoredFilters = true;
        }
        let docNum = getLocalStorageItem(viewer, "logFilterDocNum");
        if (docNum != null) {
            setFilterDocNumber(docNum);
            haveLocalStoredFilters = true;
        }
        let partner = getLocalStorageItem(viewer, "logFilterPartnerName");
        if (partner != null) {
            setFilterPartnerName(partner);
            haveLocalStoredFilters = true;
        }
        let direction = getLocalStorageItem(viewer, "logFilterDirection");
        if (direction != null) {
            setFilterDirection(direction);
            haveLocalStoredFilters = true;
        }
        let process = getLocalStorageItem(viewer, "logFilterProcessName");
        let processName = undefined;
        if (process != null) {
            processName = process as ProcessName;
            setFilterProcessName(processName);
            haveLocalStoredFilters = true;
        }
        let status = getLocalStorageItem(viewer, "logFilterStatus");
        let docStatus = undefined;
        if (status != null) {
            docStatus = status as DocumentStatus;
            setFilterStatus(docStatus);
            haveLocalStoredFilters = true;
        }
        let dateFrom = getLocalStorageItem(viewer, "logFilterDateFrom");
        let dateFromAsDateTime = undefined;
        if (dateFrom != null) {
            dateFromAsDateTime = DateTime.fromISO(dateFrom);
            setFilterDateFrom(dateFromAsDateTime);
            haveLocalStoredFilters = true;
        }
        let dateTo = getLocalStorageItem(viewer, "logFilterDateTo");
        let dateToAsDateTime = undefined;
        if (dateTo != null) {
            dateToAsDateTime = DateTime.fromISO(dateTo);
            setFilterDateTo(dateToAsDateTime);
            haveLocalStoredFilters = true;
        }
        let fileName = getLocalStorageItem(viewer, "logFilterFileName");
        if (fileName != null) {
            setFilterFileName(fileName);
            haveLocalStoredFilters = true;
        }

        if (haveLocalStoredFilters) {
            // onSearchClick relies on the all the states to set in time,
            // set them above for filterbar visuals
            // but make the call directly with the local storage values
            onFilterChanged(
                name ?? undefined, 
                direction ?? undefined, 
                type ?? undefined, 
                partner ?? undefined, 
                po ?? undefined, 
                docNum ?? undefined, 
                docStatus, 
                processName, 
                dateFromAsDateTime, 
                dateToAsDateTime,
                fileName ?? undefined
            );
        }
    };

    const setFromPremade = () => {
        if (!premadeSet) return;
        clearFilters();
        if (premadeSet.filterClient) {
            setFilterClientName(premadeSet.filterClient);
        }
        if (premadeSet.filterDocDateFrom) {
            setFilterDateFrom(premadeSet.filterDocDateFrom);
        }
        if (premadeSet.filterDocDateTo) {
            setFilterDateTo(premadeSet.filterDocDateTo);
        }
        if (premadeSet.filterDocNum) {
            setFilterDocNumber(premadeSet.filterDocNum);
        }
        if (premadeSet.filterDocType) {
            setFilterDocType(premadeSet.filterDocType);
        }
        if (premadeSet.filterIO) {
            setFilterDirection(premadeSet.filterIO);
        }
        if (premadeSet.filterPONum) {
            setFilterPO(premadeSet.filterPONum);
        }
        if (premadeSet.filterPartner) {
            setFilterPartnerName(premadeSet.filterPartner);
        }
        if (premadeSet.filterProcess) {
            setFilterProcessName(premadeSet.filterProcess);
        }
        if (premadeSet.filterStatusVal) {
            setFilterStatus(premadeSet.filterStatusVal);
        }
        if (premadeSet.filterFileName) {
            setFilterFileName(premadeSet.filterFileName);
        }

        // onSearchClick relies on the all the states to set in time,
        // set them above for filterbar visuals
        // but make the call directly with the premade filters
        onFilterChanged(
            premadeSet.filterClient, 
            premadeSet.filterIO, 
            premadeSet.filterDocType, 
            premadeSet.filterPartner, 
            premadeSet.filterPONum, 
            premadeSet.filterDocNum, 
            premadeSet.filterStatusVal, 
            premadeSet.filterProcess, 
            premadeSet.filterDocDateFrom, 
            premadeSet.filterDocDateTo,
            premadeSet.filterFileName
        );
    };

    const clearAndRemoveLocalFilters = () => {
        removeLocalStorageItem(viewer, 'logFilterDirection');
        removeLocalStorageItem(viewer, 'logFilterProcessName');
        removeLocalStorageItem(viewer, 'logFilterStatus');
        removeLocalStorageItem(viewer, 'logFilterDateFrom');
        removeLocalStorageItem(viewer, 'logFilterDateTo');
        removeLocalStorageItem(viewer, 'logFilterClientName');
        removeLocalStorageItem(viewer, 'logFilterDocType');
        removeLocalStorageItem(viewer, 'logFilterPONum');
        removeLocalStorageItem(viewer, 'logFilterDocNum');
        removeLocalStorageItem(viewer, 'logFilterPartnerName');
        removeLocalStorageItem(viewer, 'logFilterFileName');

        clearFilters();
    };

    const clearFilters = () => {
        clearClientNameFilter();
        clearDocTypeFilter();
        clearPOFilter();
        clearDocNumFilter();
        clearPartnerNameFilter();
        clearFileNameFilter();
        setFilterDirection(undefined);
        setFilterProcessName(undefined);
        setFilterStatus(undefined);
        setFilterDateFrom(undefined);
        setFilterDateTo(undefined);
    };

    const clearClientNameFilter = () => {
        setFilterClientName(undefined);
        setSearchClientName('');
        dispatch(fetchClientNameSearchResultsSuccess());
    };

    const clearDocTypeFilter = () => {
        setFilterDocType(undefined);
    };
  
    const clearPOFilter = () => {
        setFilterPO(undefined);
    };

    const clearDocNumFilter = () => {
        setFilterDocNumber(undefined);
    };

    const clearFileNameFilter = () => {
        setFilterFileName(undefined);
    };

    const clearPartnerNameFilter = () => {
        setFilterPartnerName(undefined);
        setSearchPartnerName('');
        dispatch(fetchTradingPartnerNameSearchResultsSuccess());
    };

    // Manual clears by adornment needs to adjust the local storage (change event will not be called)
    // Start Adornment manual clear handlers
    const clearClientNameFilterByX = () => {
        clearClientNameFilter();
        removeLocalStorageItem(viewer, 'logFilterClientName');
    };
    const clearDocTypeFilterByX = () => {
        setFilterDocType(undefined);
        removeLocalStorageItem(viewer, 'logFilterDocType');
    };
    const clearPOFilterByX = () => {
        setFilterPO(undefined);
        removeLocalStorageItem(viewer, 'logFilterPONum');
    };
    const clearDocNumFilterByX = () => {
        setFilterDocNumber(undefined);
        removeLocalStorageItem(viewer, 'logFilterDocNum');
    };
    const clearPartnerNameFilterByX = () => {
        clearPartnerNameFilter();    
        removeLocalStorageItem(viewer, 'logFilterPartnerName');
    };
    const clearFileNameFilterByX = () => {
        clearFileNameFilter();    
        removeLocalStorageItem(viewer, 'logFilterFileName');
    };
    // End Adornment manual clear handlers        
    
    const onClientNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch client name search results
        dispatch(fetchClientNameSearchResults(searchValue));
    };

    const onClientNameSearchValueChange = (value: string): void => {
            setSearchClientName(value);
    };

    const onClientNameFilterChange = (value: string): void => {
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterClientName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterClientName');
        }
        setFilterClientName(value);
    };

    const onClientNameFilterBlur = (): void => {
        // filterClientName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchClientName value if it has one
        if (searchClientName && filterClientName !== searchClientName) {
            onClientNameFilterChange(searchClientName);
        }
    };

    const onPOFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterPONum', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterPONum');
        }
        setFilterPO(value);
    };

    const onDocNumFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterDocNum', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDocNum');
        }
        setFilterDocNumber(value);
    };

    const onFileNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterFileName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterFileName');
        }
        setFilterFileName(value);
    };
    
    const onPartnerNameSearch = (searchValue: string) => {
        // once the delay time has elapsed in the autocomplete component
        // the search will be triggered here to fetch partner name search results
        dispatch(fetchTradingPartnerNameSearchResults(searchValue));
    };

    const onPartnerNameSearchValueChange = (value: string): void => {
            setSearchPartnerName(value);
    };

    const onPartnerNameFilterChange = (value: string): void => {
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterPartnerName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterPartnerName');
        }
        setFilterPartnerName(value);
    };

    const onPartnerNameFilterBlur = (): void => {
        // filterPartnerName will only be populated if a user selects a search result,
        // so if it doesn't have a value or hasn't been updated to the search value, 
        // set it to the searchPartnerName value if it has one
        if (searchPartnerName && filterPartnerName !== searchPartnerName) {
            onPartnerNameFilterChange(searchPartnerName);
        }
    };

    const onProcessFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterProcessName', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterProcessName');
        }

        let currentValue = value?.length > 0 ? value as ProcessName : undefined;
        
        setFilterProcessName(currentValue);
    };
    const onStatusFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterStatus', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterStatus');
        }

        let currentValue = value?.length > 0 ? value as DocumentStatus : undefined;
        setFilterStatus(currentValue);
    };
    
    const onDateFromFilterChange = (value: DateTime | null) => {
        let newDateFrom = value ?? undefined;
        if (newDateFrom) {
            setLocalStorageItem(viewer, 'logFilterDateFrom', newDateFrom.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDateFrom');
        }
        setFilterDateFrom(newDateFrom);

        let newDateTo = newDateFrom?.endOf('month') ?? undefined;
        if (newDateTo) {
            setLocalStorageItem(viewer, 'logFilterDateTo', newDateTo.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDateTo');
        }
        setFilterDateTo(newDateTo);
    };
    
    const onDateToFilterChange = (value: DateTime | null) => {
        if (value) {
            setLocalStorageItem(viewer, 'logFilterDateTo', value.toISO());
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDateTo');
        }
        setFilterDateTo(value ?? undefined);
    };
    
    const onDirectionFilterChanged = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        if (value?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterDirection', value);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDirection');
        }

        let currentValue = value?.length > 0 ? value : undefined;
        setFilterDirection(currentValue);
    };

    const onDocTypeFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
        let typeValue = event.target.value;
        if (typeValue?.length > 0) {
            setLocalStorageItem(viewer, 'logFilterDocType', typeValue);
        }
        else {
            removeLocalStorageItem(viewer, 'logFilterDocType');
        }
        setFilterDocType(typeValue);
    };

   // generate direction filter list 
   const getDirectionFilterList = () => {
       
        let items = [];
        const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
        const inboundItem = <MenuItem key="1" value="I">I</MenuItem>;
        const outboundItem = <MenuItem key="2" value="O">O</MenuItem>;
        items.push(blankItem);
        items.push(inboundItem);
        items.push(outboundItem);
        return items;
    };

    const getProcessFilterList = () => {
        if (processes && processes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                processes.map((process: ProcessName) => (
                    <MenuItem
                        key={process}
                        value={process}
                        disabled={fieldsDisabled}
                    >
                        {capitalizeWithUnderscoreRemoval(process)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    const getStatusFilterList = () => {
        if (statuses && statuses.length) {
            let items = [];
            // Errors now have their own list, so they should not be included in the status filter
            const nonErrorStatuses = statuses.filter(status => status !== DocumentStatus.Error && status !== DocumentStatus.ResolvedError);
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                nonErrorStatuses?.map((status: DocumentStatus) => (
                    <MenuItem
                        key={status}
                        value={status}
                        disabled={fieldsDisabled}
                    >
                        {capitalizeWithUnderscoreRemoval(status, ' ')}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    const docTypeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear document type filter"
                    onClick={clearDocTypeFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterDocType && filterDocType.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const pOFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear po filter"
                    onClick={clearPOFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterPO && filterPO.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const docNumFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear document number filter"
                    onClick={clearDocNumFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterDocNumber && filterDocNumber.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const fileNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear file name filter"
                    onClick={clearFileNameFilterByX}
                    disabled={fieldsDisabled}
                >
                    {filterFileName && filterFileName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick} onSearch={onSearchClick} barHeight="140px">
            <Stack direction={'column'} justifyContent="center" rowGap={2} width="100%" >
                <Grid container item direction={'row'} ml={{xs:1, sm:3, md:6, lg:10, xl:14}} columnGap={2} justifyContent="flex-start" xs={12}>
                    <Grid item xs={2}>
                        <AutocompleteFilterField
                            fieldId="event-log-filter-client-name"
                            searchTerm={searchClientName}
                            onSearchTermValueChange={onClientNameSearchValueChange}
                            filterValue={filterClientName ?? ''}
                            onFilterValueChange={onClientNameFilterChange}
                            clearIndicatorOnClick={clearClientNameFilterByX}
                            searchResults={clientNameSearchResults}
                            autoFocus={true}
                            label="Client Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onClientNameFilterBlur}
                            search={onClientNameSearch}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            itemID="event-log-filter-doc-num"
                            fullWidth
                            value={filterDocNumber ?? ''}
                            label="Document Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'document number', 'maxLength': 50, }}
                            InputProps={docNumFilterProps}
                            onChange={onDocNumFilterChange}
                            autoComplete="off"
                            data-cy="event-log-filter-doc-num"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="event-log-filter-process-label">Process Name</InputLabel>
                            <Select
                                labelId="event-log-filter-process-label"
                                aria-labelledby="event-log-filter-process-label"
                                id="event-log-filter-process"
                                value={filterProcessName ?? ''}
                                MenuProps={{
                                    'aria-label': 'edi process name',
                                }}
                                onChange={onProcessFilterChange}
                                data-cy="event-log-filter-process"
                            >
                                {getProcessFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DateTimePicker
                                    label="S/R Date From"
                                    value={filterDateFrom ?? null}
                                    views={['year','month','day','hours','minutes']}
                                    openTo={'month'}
                                    disabled={fieldsDisabled}
                                    onChange={onDateFromFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date from'}
                                        },
                                    }}
                                    data-cy="event-log-filter-date-from"
                                ></DateTimePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerWrapper>
                                <DateTimePicker
                                    label="S/R Date To"
                                    value={filterDateTo ?? null}
                                    views={['year','month','day','hours','minutes']}
                                    openTo={'day'}
                                    minDateTime={filterDateFrom}
                                    disabled={fieldsDisabled}
                                    onChange={onDateToFilterChange}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            inputProps: {'aria-label': 'date to'}
                                        },
                                    }}
                                    data-cy="event-log-filter-date-to"
                                ></DateTimePicker>
                            </DatePickerWrapper>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Grid container item direction={'row'} ml={{xs:1, sm:3, md:6, lg:10, xl:14}} columnGap={2} xs={12} justifyContent="flex-start">
                    <Grid item xs={2}>
                        <TextField
                            itemID="event-log-filter-po"
                            fullWidth
                            value={filterPO ?? ''}
                            label="PO Number"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'po number', 'maxLength': 50, }}
                            InputProps={pOFilterProps}
                            onChange={onPOFilterChange}
                            autoComplete="off"
                            data-cy="event-log-filter-po"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            itemID="event-log-filter-file-name"
                            fullWidth
                            value={filterFileName ?? ''}
                            label="File Name"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'file name', 'maxLength': 50, }}
                            InputProps={fileNameFilterProps}
                            onChange={onFileNameFilterChange}
                            autoComplete="off"
                            data-cy="event-log-filter-file-name"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <AutocompleteFilterField
                            fieldId="event-log-filter-partner-name"
                            searchTerm={searchPartnerName}
                            onSearchTermValueChange={onPartnerNameSearchValueChange}
                            filterValue={filterPartnerName ?? ''}
                            onFilterValueChange={onPartnerNameFilterChange}
                            clearIndicatorOnClick={clearPartnerNameFilterByX}
                            searchResults={partnerNameSearchResults}
                            label="Trading Partner Name"
                            disabled={fieldsDisabled}
                            onFieldBlur={onPartnerNameFilterBlur}
                            search={onPartnerNameSearch}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="event-log-filter-status-label">Status</InputLabel>
                            <Select
                                labelId="event-log-filter-status-label"
                                aria-labelledby="event-log-filter-status-label"
                                id="event-log-filter-status"
                                value={filterStatus ?? ''}
                                MenuProps={{
                                    'aria-label': 'status',
                                }}
                                onChange={onStatusFilterChange}
                                data-cy="event-log-filter-status"
                            >
                                {getStatusFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            itemID="event-log-filter-doc-type"
                            fullWidth
                            value={filterDocType ?? ''}
                            label="Type"
                            disabled={fieldsDisabled}
                            inputProps={{ 'aria-label': 'document type', 'maxLength': 50, }}
                            InputProps={docTypeFilterProps}
                            onChange={onDocTypeFilterChange}
                            autoComplete="off"
                            data-cy="event-log-filter-doc-type"
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                            <InputLabel id="event-log-filter-direction-label">I/O</InputLabel>
                            <Select
                                labelId="event-log-filter-direction-label"
                                aria-labelledby="event-log-filter-direction-label"
                                id="event-log-filter-direction"
                                value={filterDirection ?? ''}
                                MenuProps={{
                                    'aria-label': 'transaction direction',
                                }}
                                onChange={onDirectionFilterChanged}
                                data-cy="event-log-filter-direction"
                            >
                                {getDirectionFilterList()}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Stack>
        </FilterBar>
    );
};

export default EventLogsFilterBar;