import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { BillingItemListQuery, GetBillingItemsInput, Scalars, PagingResultModelOfBillingItemModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PRICING_GROUPS_LIST = gql`
    query billingItemList($input: GetBillingItemsInput!) {
        billingItems(input: $input) {
            nodes {
                id
                billingId
                dateFrom
                dateTo
                pricingId
                pricing {
                    id
                    description,
                    minServiceFees,
                    pricingItems {
                        id
                        pricingId
                        description
                        feePerDocument
                        feePerLine
                        maxNumberOfTransactions
                    }
                }
            }
        }
    }
`;

export const queryBillingItemList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters: {
        id?: string | undefined;
        billingId?: string | undefined;
        pricingId?: string | undefined;
        dateFrom?: Scalars['DateTime'] | undefined;
        dateTo?: Scalars['DateTime'] | undefined;
    }
): Promise<PagingResultModelOfBillingItemModel | null | undefined> => {
    
    const { id, billingId, pricingId, dateFrom, dateTo } = filters as GetBillingItemsInput;

    const { data, errors } = await client.query<BillingItemListQuery>({
        query: QUERY_PRICING_GROUPS_LIST,
        variables: { 
            input: {
              id,
              billingId, 
              pricingId, 
              dateFrom, 
              dateTo
            }  
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.billingItems as PagingResultModelOfBillingItemModel;
};