import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { CardListContentGrid, TabCardListBox, TabContainer, TabToolbar } from '../../util/SharedStyles';
import { ClientModel, UserRole } from '../../gql-types.generated';
import TradingPartnerClientListItem from '../listItems/TradingPartnerClientListItem';
import SearchBar from '../SearchBar';
import NoResultsMessage from '../NoResultsMessage';
import NoRecordsMessage from '../NoRecordsMessage';

interface PartnerClientsListProps {
    partnerId: string;
    viewerRole: UserRole | undefined;
    partnerClients: ClientModel[] | undefined;
}

const PartnerClientsList: React.FC<PartnerClientsListProps> =
    (props: PartnerClientsListProps) => {
        const { partnerId, viewerRole, partnerClients } = props;
        const navigate = useNavigate();
        const [searchText, setSearchText] = useState('');
       
        const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
        };

        const clearSearch = () => {
            setSearchText('');
        };

        const cardClickAction = (id: string | undefined) => {
            if (id) {
                let route = "/client/" + id;
                navigate(route);
            }
        };
      
        const getContent = () => {
            if (partnerClients && partnerClients.length > 0) {
                // the utility FilterArray function does not handle nested properties
                // since need to look at the name on client of partnerClient, doing a simplistic filter 
                // here that won't account for soundex, but this list should never get overly large where
                // that would actually be necessary
                const filteredClients = partnerClients.filter(pc => pc.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
                if (filteredClients && filteredClients.length) {
                    return (
                        filteredClients.map((client: ClientModel) => (
                            <Grid item xs={12} sm={6} md={6} lg={4} key={client.id}>
                                <TradingPartnerClientListItem
                                    viewerRole={viewerRole}
                                    client={client}
                                    clickHandler={cardClickAction}
                                />
                            </Grid>
                        ))
                    );
                } else {
                    // Filtered too hard
                    // Display no results found image/message
                    let message = `No Clients found match '${searchText}'. Remove or modify the keyword search to show results.`;
                    return (<NoResultsMessage topMargin={2} message={message} />);
                }
            } else {
                return (<NoRecordsMessage topMargin={2} message="" />);
            }
        };

        return (
            <TabContainer>
                <TabToolbar justify="space-between">
                    <SearchBar
                        searchText={searchText}
                        onSearch={handleSearch}
                        onClearSearch={clearSearch}
                    />
                </TabToolbar>
                <TabCardListBox>
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </TabCardListBox>
            </TabContainer>
        );
    }

export default PartnerClientsList;