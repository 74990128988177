import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteCurrencyPayload, DeleteCurrencyInput } from '../gql-types.generated';

export const MUTATION_DELETE_CURRENCY = gql`
    mutation deleteCurrency($input: DeleteCurrencyInput!){
        deleteCurrency(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationDeleteCurrency = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        currencyCode: string;
    }
): Promise<DeleteCurrencyPayload | undefined> => {
    const { currencyCode } = args as DeleteCurrencyInput;
    const { data, errors } = await client.mutate<{ deleteCurrency: DeleteCurrencyPayload }>({
        variables: { 
            input: {
                currencyCode
            }
         },
        mutation: MUTATION_DELETE_CURRENCY
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error('An error has occurred during the currency delete.');
    }
    if (data && data.deleteCurrency) {
        return data.deleteCurrency;
    }

    return undefined;
};