import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { BusinessAreaModel, RequestResult, Maybe } from '../../gql-types.generated';
import { selectError, clearError, selectUpsertBusinessAreaStatus, selectDeleteBusinessAreaStatus, captureUpsertBusinessAreaStatus, captureDeleteBusinessAreaStatus } from './BusinessAreasSlice';
import { selectBusinessAreaList } from '../EDIContainer/EDIContainerSlice';
import { upsertBusinessAreaData, deleteBusinessAreaData } from './BusinessAreasActions';
import { fetchBusinessAreaList } from '../EDIContainer/EDIContainerActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import BusinessAreaListItem from '../../components/listItems/BusinessAreaListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import BusinessAreaDialog from '../../components/dialogs/BusinessAreaDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';

interface BusinessAreasProps {
    viewer: Viewer | undefined;
}

const BusinessAreas: React.FC<BusinessAreasProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedArea, setSelectedArea] = useState<undefined | BusinessAreaModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const deleteBusinessAreaStatus = useAppSelector(selectDeleteBusinessAreaStatus);
    const upsertBusinessAreaStatus = useAppSelector(selectUpsertBusinessAreaStatus);
    const error = useAppSelector(selectError);

    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Business Areas");

    useEffect(() => {
        // fetch areas list when a successful mutation occurs
        if (upsertBusinessAreaStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertBusinessAreaStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertBusinessAreaStatus());
            // close the modify dialog
            onBusinessAreaDialogClose();
        }
        if (deleteBusinessAreaStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteBusinessAreaStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteBusinessAreaStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteBusinessAreaStatus.message as string);
        }
    }, [upsertBusinessAreaStatus?.result, deleteBusinessAreaStatus?.result]);

    const businessAreas = useAppSelector(selectBusinessAreaList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('buisnessareas-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [businessAreas]);

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the Business Areas' error={error}></ErrorMessage>
            );
        }

        if (businessAreas && businessAreas.length === 0) {
            // No areas to show 
            return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New Business Area" actionButtonClick={onAddBusinessAreaClick} />
                :
                <NoRecordsMessage message="" />
            );
        } else if (businessAreas && businessAreas.length) {
            return (
                businessAreas.map((businessArea: BusinessAreaModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={businessArea.id}>
                        <BusinessAreaListItem
                            name={businessArea.name}
                            id={businessArea.id}
                            description={businessArea.description ?? ''}
                            canDelete={canAddOrDelete}
                            onEditClick={editArea}
                            onDeleteClick={deleteArea}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'businessArea-skeleton-' + i;
                loaders.push(
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <BusinessAreaListItem loading={true} onEditClick={editArea} onDeleteClick={deleteArea} />
                    </CardListItemWrapperGrid>
                )
            }
            return loaders;
        }
    };

    const onAddBusinessAreaClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected area is set
        if (selectedArea) {
            setSelectedArea(undefined);
        }
        setOpenModify(true);
    };

    const onBusinessAreaDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteBusinessAreaStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedArea on close.
        dispatch(clearError());
        setSelectedArea(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchBusinessAreaList());
    };

    const onBusinessAreaDialogSave = (name: string, description: string, id?: Maybe<string>) => {
        // upsert to save data
        dispatch(upsertBusinessAreaData(name, description, id));
    };

    const onDeleteDialogConfirm = (businessAreaId: string) => {
        // delete the selected business area
        dispatch(deleteBusinessAreaData(businessAreaId));
    }

    //edit selected business area
    const editArea = (businessAreaId: string | undefined) => {
        if (businessAreas) {
            if (businessAreaId && businessAreas.length) {
                let businessArea = businessAreas.find(area => area.id === businessAreaId);
                if (businessArea) {
                    setSelectedArea(businessArea);
                    dispatch(clearError());
                    setOpenModify(true);
                }
            }
        }
    }

    //delete selected business area
    const deleteArea = (businessAreaId: string | undefined) => {
        // TODO: once business areas are set up to be connected to trading partners, don't
        // allow delete if is a trading partner (or prompt to warn and confirm and then delete link to trading partner as well)
        if (businessAreas) {
            if (businessAreaId && businessAreas.length) {
                let businessArea = businessAreas.find(area => area.id === businessAreaId);
                if (businessArea) {
                    setSelectedArea(businessArea);
                    dispatch(captureDeleteBusinessAreaStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Business Areas' id="buisnessareas-title-comp">
                <PageTitleToolbarGrid item>
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Business Area"
                            onClick={onAddBusinessAreaClick}
                            data-cy="add-new-business-area"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <BusinessAreaDialog
                isOpen={openModify}
                businessArea={selectedArea}
                onClose={onBusinessAreaDialogClose}
                onSave={onBusinessAreaDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedArea?.id}
                heading={'Delete Business Area'}
                message={'Are you sure you want to delete \'' + selectedArea?.name + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default BusinessAreas;