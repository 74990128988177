import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteTransactionPayload, DeleteTransactionInput, Scalars } from '../gql-types.generated';

export const MUTATION_DELETE_TRANSACTION = gql`
    mutation deleteTransaction($input: DeleteTransactionInput!){
        deleteTransaction(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationDeleteTransaction = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: Scalars['UUID'];
    }
): Promise<DeleteTransactionPayload | undefined> => {
    const { id } = options;
    const { data, errors } = await client.mutate<{ deleteTransaction: DeleteTransactionPayload }>({
        variables: { 
            input: {
                id: id
            }
         },
        mutation: MUTATION_DELETE_TRANSACTION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error('An error has occurred during the edi transaction delete.');
    }
    if (data && data.deleteTransaction) {
        return data.deleteTransaction;
    }

    return undefined;
};