import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetDashboardCountsInput, GetDashboardCountsQuery, DashboardModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_DASHBOARD_COUNTS = gql`
    query getDashboardCounts($input: GetDashboardCountsInput!) {
        dashboardCounts (input: $input) {
            errorsCount
            mappingErrorsCount
            onboardingClientsCount
            rejectedTransactionsCount
            unacknowledgedTransactionsCount
            notificationNotSentTransactionsCount
            clientsNotificationsNoRecipientsCount
            clientsBillingNotSetCount
        }
    }
`;

export const queryDashboardCounts = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetDashboardCountsInput
): Promise<DashboardModel | undefined> => {
    const { errorDateFrom, unacknowledgedDateTo } = filters ?? {};
    const { data, errors } = await client.query<GetDashboardCountsQuery>({
        variables: {
            input: {
                errorDateFrom,
                unacknowledgedDateTo
            }
        },
        query: QUERY_DASHBOARD_COUNTS,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.dashboardCounts as DashboardModel;
}