import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { Maybe, RequestResult } from '../../gql-types.generated';
import { queryBillingList } from '../../gql/QueryBillingList';
import { fetchError, fetchBillingListSuccess, captureUpsertBillingStatus, captureDeleteBillingStatus, incrementBillingRequestsInFlight, decrementBillingRequestsInFlight } from './BillingSlice';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpsertBilling } from '../../gql/MutationUpsertBilling';
import { BillingModel } from '../../gql-types.generated';


export const fetchBillingList = (
    id?: string | undefined,
    description?: string | undefined,
    currencyCode?: string | undefined,
) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementBillingRequestsInFlight());
        try {
            const billingList = await queryBillingList(gqlClient, { id, description, currencyCode });
            if (billingList) {
                dispatch(fetchBillingListSuccess(billingList as BillingModel[]));
            }
            dispatch(decrementBillingRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertBillingData = (currencyCode: string, description?: string, id?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertBillingStatus = await mutationUpsertBilling(gqlClient, {
                currencyCode,
                description,
                id,
            });
            if (upsertBillingStatus) {
                if (upsertBillingStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertBillingStatus.message } as Error));
                    getErrorMessage(upsertBillingStatus.message as string, upsertBillingStatus.errors, true, true);
                }
                dispatch(captureUpsertBillingStatus(upsertBillingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteBillingData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteBillingStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Billing"
            });
            if (deleteBillingStatus) {
                if (deleteBillingStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteBillingStatus.message as string, deleteBillingStatus.errors, true, true);
                }
                dispatch(captureDeleteBillingStatus(deleteBillingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };





