import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingModel, BillingItemModel, UpsertBillingPayload, UpsertBillingItemPayload, PagingResultModelOfBillingModel, DeleteByIdPayload, UpsertPricingPayload, RequestResult, PagingResultModelOfBillingItemModel, PricingModel } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    billingPagingResult?: PagingResultModelOfBillingModel;
    billing?: BillingModel;
    upsertBillingStatus?: UpsertBillingPayload;
    deleteBillingStatus?: DeleteByIdPayload;
    upsertBillingItemStatus?: UpsertBillingItemPayload;
    deleteBillingItemStatus?: DeleteByIdPayload;
    upsertPricingsStatus?: UpsertPricingPayload;
    deletePricingStatus?: DeleteByIdPayload;
    billingItemsPagingResult?: PagingResultModelOfBillingItemModel;
    billingItems?: BillingItemModel[];
}

const initialState: SliceState = {
    error: undefined,
    billingPagingResult: undefined,
    billing: undefined,
    upsertBillingStatus: undefined,
    deleteBillingStatus: undefined,
    upsertBillingItemStatus: undefined,
    deleteBillingItemStatus: undefined,
    upsertPricingsStatus: undefined,
    deletePricingStatus: undefined,
    billingItemsPagingResult: undefined,
    billingItems: undefined,
};

export const slice = createSlice({
    name: 'billingDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billing = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingItems = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertBillingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteBillingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePricingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingItemsPagingResult = undefined;
        },

        fetchBillingByIdSuccess: (state, action: PayloadAction<PagingResultModelOfBillingModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.billingPagingResult = action.payload;
            
            if (action.payload.nodes && action.payload.nodes[0]) {
                let billing = action.payload.nodes[0];
                if (billing) {
                    // eslint-disable-next-line no-param-reassign
                    state.billing = billing;
                }
            }
        },

        fetchBillingItemsByBillingIdSuccess: (state, action: PayloadAction<PagingResultModelOfBillingItemModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.billingItemsPagingResult = action.payload;
            
            if (action.payload.nodes) {
                // eslint-disable-next-line no-param-reassign
                state.billingItems = action.payload.nodes;
            }
        },

        captureUpsertBillingStatus: (state, action: PayloadAction<UpsertBillingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertBillingStatus = action.payload;
        },

        captureDeleteBillingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteBillingStatus = action.payload;
        },

        captureUpsertBillingItemStatus: (state, action: PayloadAction<UpsertBillingItemPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertBillingItemStatus = action.payload;
        },

        captureDeleteBillingItemStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteBillingItemStatus = action.payload;
        },

        captureUpsertPricingsStatus: (state, action: PayloadAction<UpsertPricingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingsStatus = action.payload;
        },

        captureDeletePricingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePricingStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.billingDetails.error;
export const selectBillingPagingResult = (state: RootState): PagingResultModelOfBillingModel | undefined => state.billingDetails.billingPagingResult;
export const selectBilling = (state: RootState): BillingModel | undefined => state.billingDetails.billing;
export const selectBillingItems = (state: RootState): BillingItemModel[] | undefined => state.billingDetails.billingItems;
export const selectUpsertBillingStatus = (state: RootState): UpsertBillingPayload | undefined => state.billingDetails.upsertBillingStatus;
export const selectDeleteBillingStatus = (state: RootState): DeleteByIdPayload | undefined => state.billingDetails.deleteBillingStatus;
export const selectUpsertBillingItemStatus = (state: RootState): UpsertBillingItemPayload | undefined => state.billingDetails.upsertBillingItemStatus;
export const selectDeleteBillingItemStatus = (state: RootState): DeleteByIdPayload | undefined => state.billingDetails.deleteBillingItemStatus;
export const selectUpsertPricingsStatus = (state: RootState): UpsertPricingPayload | undefined => state.billingDetails.upsertPricingsStatus;
export const selectDeletePricingStatus = (state: RootState): DeleteByIdPayload | undefined => state.billingDetails.deletePricingStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchBillingByIdSuccess,
    fetchBillingItemsByBillingIdSuccess: fetchBillingItemsByBillingIdSuccess,
    captureUpsertBillingStatus,
    captureDeleteBillingStatus,
    captureUpsertBillingItemStatus: captureUpsertBillingItemStatus,
    captureDeleteBillingItemStatus: captureDeleteBillingItemStatus,
    captureUpsertPricingsStatus,
    captureDeletePricingStatus,
} = slice.actions;

export default slice.reducer;