import { useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, DateValidationError } from "@mui/x-date-pickers";
import { DateTime } from 'luxon';
import { TextFieldProps } from '@mui/material/TextField';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, styled } from "@mui/material";
import { DialogGridWithTopMargin, DialogGridColumn } from "../../util/SharedStyles";
import { BillingItemModel, PricingModel, } from '../../gql-types.generated';
import { selectPricingList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";

const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface DialogProps {
    isOpen: boolean;
    currencyCode: string;
    billingItem?: BillingItemModel | null | undefined;
    onClose: () => void;
    onSave: (dateFrom: DateTime, dateTo: DateTime, pricingId: string, id?: string) => void;
    error?: Error | undefined;
}

const BillingItemDialog: React.FC<DialogProps> = props => {
    const { isOpen, currencyCode, billingItem, onClose, onSave, error } = props;
    const id = billingItem?.id;
    
    const currentDate = DateTime.now();
    // if current date is the first of the month, set the default from-date to current date, otherwise set it to the first of the next month
    const defaultDateFrom = currentDate.day > 1 ? currentDate.plus({ months: 1}).startOf('month') : currentDate; 
    // default the to-date to be the end of the same month as the from-date
    const defaultDateTo = defaultDateFrom.endOf('month');
    // make the minimum date to be selected be 6 months prior to defaultDateFrom so that can't go previous beyond 6 months of the default date
    const minDateFrom = defaultDateFrom.minus({ months: 6});
    
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [dateFrom, setDateFrom] = useState<DateTime>(defaultDateFrom);
    const [dateTo, setDateTo] = useState<DateTime>(defaultDateTo);
    const [dateFromErrorText, setDateFromErrorText] = useState('');
    const [dateFromInvalid, setDateFromInvalid] = useState(false);
    const [dateToErrorText, setDateToErrorText] = useState('');
    const [dateToInvalid, setDateToInvalid] = useState(false);
    const [pricingId, setPricingId] = useState<string | undefined>('');
    const [currencyPricings, setCurrencyPricings] = useState<PricingModel[]>([]);
    const [currencyPricingsCount, setCurrencyPricingsCount] = useState<number>(0);
    
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    const pricings = useAppSelector(selectPricingList);
    
    const setBillingItemState = () => {
        // if have a billingItem, then populate for Edit
        if (billingItem) {
            if (billingItem.dateFrom) {
                setDateFrom(DateTime.fromISO(billingItem.dateFrom));
            }
            if (billingItem.dateTo) {
                setDateTo(DateTime.fromISO(billingItem.dateTo));
            }
            if (billingItem.pricing?.id) {
                setPricingId(billingItem.pricing.id);
            }
        }
        else {
            resetInitialState();
        }
    };

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (isOpen) {
            setBillingItemState();
        } else {
            resetInitialState();
        }
    }, [isOpen]);

    useEffect(() => {
        let filteredPricings: PricingModel[] | undefined = pricings?.filter(p => p.currencyCode === currencyCode);
        if (filteredPricings && filteredPricings.length) {
            setCurrencyPricings(filteredPricings);
            setCurrencyPricingsCount(filteredPricings.length);
        }
    }, [pricings, currencyCode]);

    const resetInitialState = () => {
        setSubmitted(false);
        setIsFormDirty(false);
        setDateFrom(defaultDateFrom);
        setDateTo(defaultDateTo);
        setDateFromErrorText('');
        setDateToErrorText('');
        setDateFromInvalid(false);
        setDateToInvalid(false);
        setPricingId('');
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        let requiredCheck = dateFrom !== undefined && dateTo !== undefined && (pricingId !== undefined && pricingId !== null && pricingId !== '');
        let validDateFrom = !dateFromInvalid;
        let validDateTo = !dateToInvalid;
        
        return isFormDirty && validDateFrom && validDateTo && requiredCheck;
    };

    const submitForm = () => {
        if (dateFrom && dateTo && pricingId) {
            setSubmitted(true);
            onSave(dateFrom, dateTo, pricingId, id);
        }
    };

    const disableAllButFirstDay = (date: DateTime) => {
        return date.day !== 1;
    }

    const disableAllButLastDay = (date: DateTime) => {
        return date.day !== date.daysInMonth;
    }

    // onChange, onError handlers
    const onDateFromChange = (value: DateTime | null) => {
        setIsFormDirty(true);
        let newDateFrom = value ?? defaultDateFrom;
        setDateFrom(newDateFrom);
        setDateTo(newDateFrom.endOf('month'));
    };
    const onDateFromError = (reason: DateValidationError, value: DateTime | null) => {
        let errorReason = reason;
        if (errorReason) {
            setDateFromInvalid(true);
            if (errorReason === "minDate") {
                setDateFromErrorText("Date must be no more than six months prior.")
            } else if (errorReason === "shouldDisableDate") {
                setDateFromErrorText("Date must be the first day of a month.")
            } else {
                setDateFromErrorText("Invalid Date");
            }
        } else {
            // null errorReason means valid
            setDateFromInvalid(false);
            setDateFromErrorText("");
        }
    };
    const onDateToChange = (value: DateTime | null) => {
        setIsFormDirty(true);
        setDateTo(value ?? defaultDateTo);
    };
    const onDateToError = (reason: DateValidationError, value: DateTime | null) => {
        let errorReason = reason;
        if (errorReason) {
            setDateToInvalid(true);
            if (errorReason === "minDate") {
                setDateToErrorText("Date cannot be prior to 'Date From'")
            } else if (errorReason === "shouldDisableDate") {
                setDateToErrorText("Date must be the last day of a month.")
            } else { 
                setDateToErrorText("Invalid Date");
            }
        } else {
            // null errorReason means valid
            setDateToInvalid(false);
            setDateToErrorText("");
        }
    };
    const onPricingChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        setPricingId(event.target.value ?? undefined);
    };
    
    // get the list of pricings for currency billing currency for the dropdown
    const getPricingsDropList = () => {
        if (currencyPricings && currencyPricings.length) {
            return (
                currencyPricings.map((pricing: PricingModel) => (
                    <MenuItem
                        key={pricing.id}
                        value={pricing.id}
                    >
                        {pricing.description?.trim()}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const PricingsMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
                maxWidth: '200px'
            },
        },
    };
        
    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Price Range"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={4} xs={12}>
                    <DialogGridColumn container item xs={12} sm={6}>
                        <Grid item xs={12}>
                           <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePicker
                                    label="Date From"
                                    value={dateFrom}
                                    views={['year','month','day']}
                                    openTo={'month'}
                                    minDate={minDateFrom}
                                    disableHighlightToday
                                    disabled={submitted}
                                    shouldDisableDate={disableAllButFirstDay}
                                    onChange={onDateFromChange}
                                    onError={onDateFromError}
                                    slotProps={{
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            helperText: dateFromErrorText,
                                            inputProps: {'aria-label': 'date from'}
                                        },
                                    }}
                                    data-cy="dialog-billingItem-date-from"
                                ></DatePicker>
                            </LocalizationProvider>
                       </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn container item xs={12} sm={6}>
                        <Grid item xs={12}>
                           <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePickerWrapper>
                                    <DatePicker
                                        label="Date To"
                                        value={dateTo}
                                        views={['year','month','day']}
                                        openTo={'month'}
                                        minDate={dateFrom}
                                        disableHighlightToday
                                        disabled={submitted}
                                        shouldDisableDate={disableAllButLastDay}
                                        onChange={onDateToChange}
                                        onError={onDateToError}
                                        slotProps={{
                                            textField: {
                                                variant: "standard",
                                                fullWidth: true,
                                                helperText: dateToErrorText,
                                                inputProps: {'aria-label': 'date to'}
                                            },
                                        }}
                                        data-cy="dialog-billingItem-date-to"
                                    ></DatePicker>
                                </DatePickerWrapper>
                            </LocalizationProvider>
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required error={currencyPricingsCount <= 0}>
                        <InputLabel id="dialog-billingItem-pricing-label">Pricing</InputLabel>
                        <Select
                            labelId="dialog-billingItem-pricing-label"
                            aria-labelledby="dialog-billingItem-pricing-label"
                            value={pricingId}
                            onChange={onPricingChange}
                            disabled={submitted}
                            MenuProps={PricingsMenuProps}
                            data-cy="dialog-billingItem-pricing"
                        >
                            {getPricingsDropList()}
                        </Select>
                        {currencyPricingsCount <= 0 && 
                        <FormHelperText>{`No Pricing exists for Currency ${currencyCode}. One must be created first.`}</FormHelperText>
                        }
                    </FormControl>
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default BillingItemDialog;