import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteByIdPayload, DeleteByIdInput } from '../gql-types.generated';

export const MUTATION_DELETE_ENTITY = gql`
    mutation deleteEntityById($input: DeleteByIdInput!){
        deleteById(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationDeleteEntity = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: string;
        entityName: string;
    }
): Promise<DeleteByIdPayload | undefined> => {
    const { id, entityName } = options;
    const { data, errors } = await client.mutate<{ deleteById: DeleteByIdPayload }>({
        variables: {
            input: {
                id: id,
                entityName: entityName
            }
        },
        mutation: MUTATION_DELETE_ENTITY
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error(`An error has occurred during the ${entityName} delete.`);
    }
    if (data && data.deleteById) {
        return data.deleteById;
    }

    return undefined;
};