import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { UpsertPricingItemPayload, UpsertPricingItemInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PRICING_ITEM = gql`
    mutation upsertPricingItem($input: UpsertPricingItemInput!){
        upsertPricingItem(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertPricingItem = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        pricingId: string,
        maxNumberOfTransactions: number,
        feePerDocument: number,
        feePerLine: number,
        id?: string,
    }
): Promise<UpsertPricingItemPayload | undefined> => {
    const {
        pricingId,
        maxNumberOfTransactions,
        feePerDocument,
        feePerLine,
        id,
    } = args as UpsertPricingItemInput;
    const { data, errors } = await client.mutate<{ upsertPricingItem: UpsertPricingItemPayload }>({
        variables: {
            input: {
                pricingId,
                maxNumberOfTransactions,
                feePerDocument,
                feePerLine,
                id,
            }
        },
        mutation: MUTATION_UPSERT_PRICING_ITEM
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the pricing item save.');
    }
    if (data && data.upsertPricingItem) {
        return data.upsertPricingItem;
    }
    return undefined;
};