import { ApolloError } from '@apollo/client/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientModel, BusinessAreaModel, EdiStandardModel, ErpProductModel, DashboardModel, TransactionModel, PhoneTypeModel, IsaQualifierModel, LocaleModel, UpsertFavoritePayload, UpsertRecentPayload, StateModel, UserModel, PagingResultModelOfClientModel, PipelineItemClassNameModel, PricingModel, ErpTransactionSchemaModel, CurrencyModel, MessagingLogModel, PagingResultModelOfMessagingLogModel, ActionPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

import { ToastConfig, Viewer } from '../../util/Constants';

interface SliceState {
    error?: Error | ApolloError | any;
    user?: UserModel;
    viewer?: Viewer;
    upsertRecentStatus?: UpsertRecentPayload;
    upsertFavoriteStatus?: UpsertFavoritePayload;
    erpProductList?: ErpProductModel[];
    businessAreaList?: BusinessAreaModel[];
    ediStandardList?: EdiStandardModel[];
    transactionList?: TransactionModel[];
    statesList?: StateModel[];
    countryList?: string[];
    phoneTypeList?: PhoneTypeModel[];
    isaQualifierList?: IsaQualifierModel[];
    localeList?: LocaleModel[];
    onboardingClientsList?: ClientModel[];
    onboardingClientsError?: Error | ApolloError | any;
    //transactionPipelines?: PipelineModel[];
    pipelineItemClassList?: PipelineItemClassNameModel[];
    pricingList?: PricingModel[];
    toastConfig?: ToastConfig;
    transactionErpSchemas?: ErpTransactionSchemaModel[];
    currencyList?: CurrencyModel[];
    errorLogs?: MessagingLogModel[];
    dashboardCounts?: DashboardModel;
    generateBillingStatus?: ActionPayload;
    portalSyncStatus?: ActionPayload;
    clientsMissingRecipientsList?: ClientModel[];
    clientsMissingBillingList?: ClientModel[];
}

const initialState: SliceState = {
    error: undefined,
    user: undefined,
    viewer: undefined,
    upsertRecentStatus: undefined,
    upsertFavoriteStatus: undefined,
    erpProductList: undefined,
    businessAreaList: undefined,
    ediStandardList: undefined,
    transactionList: undefined,
    statesList: undefined,
    countryList: undefined,
    phoneTypeList: undefined,
    isaQualifierList: undefined,
    localeList: undefined,
    onboardingClientsList: undefined,
    onboardingClientsError: undefined,
    //transactionPipelines: undefined,
    pipelineItemClassList: undefined,
    pricingList: undefined,
    toastConfig: undefined,
    transactionErpSchemas: undefined,
    currencyList: undefined,
    errorLogs: undefined,
    dashboardCounts: undefined,
    generateBillingStatus: undefined,
    portalSyncStatus: undefined,
    clientsMissingRecipientsList: undefined,
    clientsMissingBillingList: undefined,
};

export const slice = createSlice({
    name: 'ediContainer',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<ApolloError>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertRecentStatus: (state, action: PayloadAction<UpsertRecentPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertRecentStatus = action.payload;
        },

        captureUpsertFavoriteStatus: (state, action: PayloadAction<UpsertFavoritePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertFavoriteStatus = action.payload;
        },

        fetchBusinessAreaListSuccess: (state, action: PayloadAction<BusinessAreaModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.businessAreaList = action.payload;
        },

        fetchErpProductListSuccess: (state, action: PayloadAction<ErpProductModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.erpProductList = action.payload;
        },

        fetchEdiStandardListSuccess: (state, action: PayloadAction<EdiStandardModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.ediStandardList = action.payload;
        },

        fetchTransactionListSuccess: (state, action: PayloadAction<TransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactionList = action.payload;
        },

        fetchStatesListSuccess: (state, action: PayloadAction<StateModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.statesList = action.payload;

            //TODO - remove custom country code when country query exists
            let countries = [] as string[];
            const stateNumber = action.payload.length;
            if (stateNumber) {
                let i = 0;
                for (i; i < stateNumber; ++i) {
                    if (action.payload[i].countryCode) {
                        let code = action.payload[i].countryCode as string;
                        if (countries.indexOf(code) == -1) {
                            countries.push(code)
                        }
                    }
                }
            }
            state.countryList = countries;
        },

        fetchPhoneTypeListSuccess: (state, action: PayloadAction<PhoneTypeModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.phoneTypeList = action.payload;
        },

        fetchIsaQualifierListSuccess: (state, action: PayloadAction<IsaQualifierModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.isaQualifierList = action.payload;
        },

        fetchLocaleListSuccess: (state, action: PayloadAction<LocaleModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.localeList = action.payload;
        },

        fetchUserByEmailSuccess: (state, action: PayloadAction<UserModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.user = action.payload;
        },

        fetchClientsOnboardingSuccess: (state, action: PayloadAction<PagingResultModelOfClientModel>) => {
            if (action.payload.nodes) {
                if (action.payload.nodes.length > 0) {
                    // eslint-disable-next-line no-param-reassign
                    state.onboardingClientsList = action.payload.nodes as ClientModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.onboardingClientsList = [] as ClientModel[];
                }
            }
        },

        clearClientsOnboardingList: state => {
            // eslint-disable-next-line no-param-reassign
            state.onboardingClientsList = undefined;
        },

        fetchOnboardingClientsError: (state, action: PayloadAction<ApolloError>) => {
            // eslint-disable-next-line no-param-reassign
            state.onboardingClientsError = action.payload;
        },

        clearOnboardingClientsError: state => {
            // eslint-disable-next-line no-param-reassign
            state.onboardingClientsError = undefined;
        },

        fetchClientsMissingRecipientsSuccess: (state, action: PayloadAction<ClientModel[]>) => {
           // eslint-disable-next-line no-param-reassign
            state.clientsMissingRecipientsList = action.payload;
        },

        clearClientsMissingRecipientsList: state => {
            // eslint-disable-next-line no-param-reassign
            state.clientsMissingRecipientsList = undefined;
        },

        fetchClientsMissingBillingSuccess: (state, action: PayloadAction<PagingResultModelOfClientModel>) => {
            if (action.payload.nodes) {
                if (action.payload.nodes.length > 0) {
                    // eslint-disable-next-line no-param-reassign
                    state.clientsMissingBillingList = action.payload.nodes as ClientModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.clientsMissingBillingList = [] as ClientModel[];
                }
            }
        },

        clearClientsMissingBillingList: state => {
             // eslint-disable-next-line no-param-reassign
             state.clientsMissingRecipientsList = undefined;
        },

        // fetchPipelinesForTransactionSuccess: (state, action: PayloadAction<PipelineModel[]>) => {
        //     // eslint-disable-next-line no-param-reassign
        //     state.transactionPipelines = action.payload;
        // },

        // clearTransactionPipelines: state => {
        //     // eslint-disable-next-line no-param-reassign
        //     state.transactionPipelines = undefined;
        // },

        fetchPipelineItemClassListSuccess: (state, action: PayloadAction<PipelineItemClassNameModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.pipelineItemClassList = action.payload;
        },

        fetchPricingListSuccess: (state, action: PayloadAction<PricingModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.pricingList = action.payload;
        },

        setToastConfig: (state, action: PayloadAction<ToastConfig | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.toastConfig = action.payload;
        },

        fetchErpSchemasForTransactionSuccess: (state, action: PayloadAction<ErpTransactionSchemaModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactionErpSchemas = action.payload;
        },

        clearTransactionErpSchemas: state => {
            // eslint-disable-next-line no-param-reassign
            state.transactionErpSchemas = undefined;
        },
       
        fetchCurrencyListSuccess: (state, action: PayloadAction<CurrencyModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.currencyList = action.payload;
        },

        fetchErrorLogsSuccess: (state, action: PayloadAction<PagingResultModelOfMessagingLogModel>) => {
            // eslint-disable-next-line no-param-reassign
            if (action.payload.nodes) {
                if (action.payload.nodes.length > 0) {
                    // eslint-disable-next-line no-param-reassign
                    state.errorLogs = action.payload.nodes as MessagingLogModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.errorLogs = [] as MessagingLogModel[];
                }
            }
        },

        clearErrorLogList: state => {
            // eslint-disable-next-line no-param-reassign
            state.errorLogs = undefined;
        },

        fetchDashboardCountsSuccess: (state, action: PayloadAction<DashboardModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.dashboardCounts = action.payload;
        },

        clearDashboardCounts: state => {
            // eslint-disable-next-line no-param-reassign
            state.dashboardCounts = undefined;
        },

        captureGenerateBillingStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.generateBillingStatus = action.payload;
        },

        capturePortalSyncStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.portalSyncStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): ApolloError | undefined => state.ediContainer.error;
export const selectUser = (state: RootState): UserModel | undefined => state.ediContainer.user;
export const selectViewer = (state: RootState): Viewer | undefined => state.ediContainer.viewer;
export const selectUpsertRecentStatus = (state: RootState): UpsertRecentPayload | undefined => state.ediContainer.upsertRecentStatus;
export const selectUpsertFavoriteStatus = (state: RootState): UpsertFavoritePayload | undefined => state.ediContainer.upsertFavoriteStatus;
export const selectBusinessAreaList = (state: RootState): BusinessAreaModel[] | undefined => state.ediContainer.businessAreaList;
export const selectErpProductList = (state: RootState): ErpProductModel[] | undefined => state.ediContainer.erpProductList;
export const selectEdiStandardList = (state: RootState): EdiStandardModel[] | undefined => state.ediContainer.ediStandardList;
export const selectTransactionList = (state: RootState): TransactionModel[] | undefined => state.ediContainer.transactionList;
export const selectStatesList = (state: RootState): StateModel[] | undefined => state.ediContainer.statesList;
export const selectCountriesList = (state: RootState): string[] | undefined => state.ediContainer.countryList;
export const selectPhoneTypeList = (state: RootState): PhoneTypeModel[] | undefined => state.ediContainer.phoneTypeList;
export const selectIsaQualifierList = (state: RootState): IsaQualifierModel[] | undefined => state.ediContainer.isaQualifierList;
export const selectLocaleList = (state: RootState): LocaleModel[] | undefined => state.ediContainer.localeList;
export const selectOnboardingClientsList = (state: RootState): ClientModel[] | undefined => state.ediContainer.onboardingClientsList;
export const selectOnboardingClientsError = (state: RootState): ApolloError | undefined => state.ediContainer.onboardingClientsError;
//export const selectTransactionPipelines = (state: RootState): PipelineModel[] | undefined => state.ediContainer.transactionPipelines;
export const selectPipelineItemClassList = (state: RootState): PipelineItemClassNameModel[] | undefined => state.ediContainer.pipelineItemClassList;
export const selectPricingList = (state: RootState): PricingModel[] | undefined => state.ediContainer.pricingList;
export const selectToastConfig = (state: RootState): ToastConfig | undefined => state.ediContainer.toastConfig;
export const selectTransactionSchemas = (state: RootState): ErpTransactionSchemaModel[] | undefined => state.ediContainer.transactionErpSchemas;
export const selectCurrencyList = (state: RootState): CurrencyModel[] | undefined => state.ediContainer.currencyList;
export const selectErrorLogs = (state: RootState): MessagingLogModel[] | undefined => state.ediContainer.errorLogs;
export const selectDashboardCounts = (state: RootState): DashboardModel | undefined => state.ediContainer.dashboardCounts;
export const selectGenerateBillingStatus =(state: RootState): ActionPayload | undefined => state.ediContainer.generateBillingStatus;
export const selectPortalSyncStatus =(state: RootState): ActionPayload | undefined => state.ediContainer.portalSyncStatus;
export const selectClientsMissingNotificationRecipientsList = (state: RootState): ClientModel[] | undefined => state.ediContainer.clientsMissingRecipientsList;
export const selectClientsMissingBillingList = (state: RootState): ClientModel[] | undefined => state.ediContainer.clientsMissingBillingList;


export const {
    fetchError,
    clearError,
    fetchUserByEmailSuccess,
    captureUpsertRecentStatus,
    captureUpsertFavoriteStatus,
    fetchBusinessAreaListSuccess,
    fetchErpProductListSuccess,
    fetchEdiStandardListSuccess,
    fetchTransactionListSuccess,
    fetchStatesListSuccess,
    fetchPhoneTypeListSuccess,
    fetchIsaQualifierListSuccess,
    fetchClientsOnboardingSuccess,
    clearClientsOnboardingList,
    fetchOnboardingClientsError,
    clearOnboardingClientsError,
    fetchPricingListSuccess,
    setToastConfig,
    fetchErpSchemasForTransactionSuccess,
    clearTransactionErpSchemas,
    fetchCurrencyListSuccess,
    fetchErrorLogsSuccess,
    clearErrorLogList,
    fetchDashboardCountsSuccess,
    clearDashboardCounts,
    captureGenerateBillingStatus,
    fetchLocaleListSuccess,
    capturePortalSyncStatus,
    fetchPipelineItemClassListSuccess,
    fetchClientsMissingRecipientsSuccess,
    clearClientsMissingRecipientsList,
    fetchClientsMissingBillingSuccess,
    clearClientsMissingBillingList
} = slice.actions;

export default slice.reducer;