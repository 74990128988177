import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { BillingModel, RequestResult } from '../../gql-types.generated';
import { selectBillingList, selectError, clearError, clearState, selectDeleteBillingStatus, selectUpsertBillingStatus, captureUpsertBillingStatus, captureDeleteBillingStatus, selectRequestsInFlight } from './BillingSlice'
import { fetchBillingList, upsertBillingData, deleteBillingData } from './BillingActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import BillingListItem from '../../components/listItems/BillingListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import BillingDialog from '../../components/dialogs/BillingDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import BillingFilterBar from '../../components/filters/BillingFilterBar';


const WrappedLoadingGrid = styled(Grid)((props) => ({
    paddingTop: '16px',
    width: '100% !important',
    marginLeft: '0px !important',
}));

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids

interface BillingProps {
    viewer: Viewer | undefined;
}

const Billing: React.FC<BillingProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedBilling, setSelectedBilling] = useState<undefined | BillingModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterCurrencyCode, setFilterVersion] = useState<string | undefined>(undefined);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);

    const deleteBillingStatus = useAppSelector(selectDeleteBillingStatus);
    const upsertBillingStatus = useAppSelector(selectUpsertBillingStatus);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const canEdit = viewerCanEdit(viewer);

    useTitle("Billing");

    // Normally we would have the initial fetch, but the filterbar hasn't loaded/pulled from local storage yet.
    // If we fetch here and there are filters in storage we will fire two requests, the last to return would overwrite the first.
    // BillingFilterBar is equipped to evaluate local storage and then kick off a request if any filters are there or not.
    // Leaving this here and commented out in the case this query returns paged results in the future (currently not taking limit/after/before in input)
    /*useEffect(() => {
        // fetch billing List on component render
        dispatch(fetchBillingList(undefined, filterDescription, filterCurrencyCode));
    }, []);
    */

    useEffect(() => {
        if (upsertBillingStatus?.result === RequestResult.Success) {
            let wasAddMode = openAdd;

            // close the modify dialog
            onBillingDialogClose();

            dispatch(setToastConfig({
                message: upsertBillingStatus.message as string,
                severity: ToastSeverity.Success
            }));

            // remove upsert status
            dispatch(captureUpsertBillingStatus());

            // if coming from adding new, and a billingId was successfully returned, navigate 
            // to the new Billing details to allow user to 
            // add price ranges right away
            if (wasAddMode && upsertBillingStatus.billing?.id) {
                // simulate a card click to navigate
                cardClickAction(upsertBillingStatus?.billing.id);
            }
        }
   }, [upsertBillingStatus?.result]);

    useEffect(() => {
        if (deleteBillingStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteBillingStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteBillingStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteBillingStatus.message as string);
        }
    }, [deleteBillingStatus?.result]);

    const billings = useAppSelector(selectBillingList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('billing-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [billings]);

    useEffect(() => {
        if (billings) {
            let numFilters = 0;
            if (filterCurrencyCode && filterCurrencyCode.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [billings, filterCurrencyCode, filterDescription]);

    useEffect(() => {
        if (!billings && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [billings, requestsInFlight]);

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/billingdetails/" + id;
            navigate(route);
        }
    };

    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'billing-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} sm={12} md={6} lg={4} xl={3} key={key}>
                    <BillingListItem loading={true} onEditClick={editBilling} onDeleteClick={deleteBilling} clickAction={cardClickAction} />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'billing-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load Billing' error={error}></ErrorMessage>
            );
        }

        if (!billings) {
            return getLoaders();
        }
        else if (billings && billings.length > 0) {
            return (
                billings.map((billing: BillingModel) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={billing.id}>
                        <BillingListItem
                            billingItem={billing}
                            onEditClick={editBilling}
                            onDeleteClick={deleteBilling}
                            clickAction={cardClickAction}
                        />
                    </Grid>
                ))
            );
        } else {
            // No Billing to show 
            return (canEdit ? <NoRecordsMessage actionButtonText="Add New Transaction Billing" actionButtonClick={onAddBillingClick} />
                :
                <NoRecordsMessage message="" />
            );
        }
    };

    const onAddBillingClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedBilling) {
            setSelectedBilling(undefined);
        }
        setOpenAdd(true);
    };

    const onBillingDialogClose = () => {
        setOpenEdit(false);
        setOpenAdd(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteBillingStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedBilling on close.
        dispatch(clearError());
        setSelectedBilling(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchBillingList(undefined, filterDescription, filterCurrencyCode));
    };

    const onBillingDialogSave = (currencyCode: string, description?: string, id?: string) => {
        // upsert to save data
        dispatch(upsertBillingData(currencyCode, description, id));
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete the selected billing
        dispatch(deleteBillingData(id));
    }

    //edit selected billing
    const editBilling = (id: string | undefined) => {
        if (id && billings?.length) {
            let billing = billings.find(t => t.id === id);
            if (billing) {
                setSelectedBilling(billing);
                dispatch(clearError());
                setOpenEdit(true);
            }
        }
    };

    //delete selected billing
    const deleteBilling = (id: string | undefined) => {
        if (id && billings?.length) {
            let billing = billings.find(t => t.id === id);
            if (billing) {
                setSelectedBilling(billing);
                dispatch(captureDeleteBillingStatus());
                setDeleteErrorMessage('');
                setOpenDelete(true);
            }
        }
    }

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterCurrency: string | undefined, filterDesc: string | undefined) => {
        setFilterVersion(filterCurrency);
        setFilterDescription(filterDesc);
        dispatch(clearState());
        dispatch(fetchBillingList(undefined, filterDesc, filterCurrency));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Billing' id="billing-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="billing-filters"
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="New Billing Group"
                            onClick={onAddBillingClick}
                            data-cy="add-new-billing"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>

            <BillingFilterBar
                id="billing-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />

            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>

            <BillingDialog
                isOpen={openEdit || openAdd}
                billing={selectedBilling}
                onClose={onBillingDialogClose}
                onSave={onBillingDialogSave}
                error={error}
           />

            <DeleteDialog
                isOpen={openDelete}
                id={selectedBilling?.id}
                heading={'Delete Billing Group'}
                message={'Are you sure you want to delete \'' + selectedBilling?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Billing;