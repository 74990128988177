import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Grid } from '@mui/material';

import { CardListContentGrid, TabCardListBox, TabContainer, TabToolbar } from '../../util/SharedStyles';

import { ClientPartnerModel, RequestResult, UserRole } from '../../gql-types.generated';
import { selectError, captureAddClientPartnerStatus, selectAddClientPartnerStatus, captureDeleteClientPartnerStatus, selectDeleteClientPartnerStatus } from '../../features/ClientDetails/ClientDetailsSlice';
import { addClientPartner } from '../../features/ClientDetails/ClientDetailsActions';

import ClientTradingPartnerListItem from '../listItems/ClientTradingPartnerListItem';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import CreateNewButton from '../buttons/CreateNewButton';
import AddClientPartnerDialog from '../dialogs/AddClientPartnerDialog';
import DeleteDialog from '../dialogs/DeleteDialog';
import SearchBar from '../SearchBar';
import NoResultsMessage from '../NoResultsMessage';
import NoRecordsMessage from '../NoRecordsMessage';
import { deleteClientPartnerData } from '../../features/ClientDetails/ClientDetailsActions';

interface ClientTradingPartnerListProps {
    clientId: string;
    viewerRole: UserRole | undefined;
    clientTradingPartners: ClientPartnerModel[] | undefined;
}

const ClientTradingPartnerList: React.FC<ClientTradingPartnerListProps> =
    (props: ClientTradingPartnerListProps) => {
        const { clientId, viewerRole, clientTradingPartners } = props;
        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const [searchText, setSearchText] = useState('');
        const [openAdd, setOpenAdd] = useState(false);
        const [selectedPartner, setSelectedPartner] = useState<ClientPartnerModel | undefined>(undefined);
        const [openDelete, setOpenDelete] = useState(false);
        const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

        const addPartnerStatus = useAppSelector(selectAddClientPartnerStatus);
        const error = useAppSelector(selectError);
        const deleteClientPartnerStatus = useAppSelector(selectDeleteClientPartnerStatus);

        const canEdit = viewerCanEdit(viewerRole);

        useEffect(() => {
            // save off add result for base component
            if (addPartnerStatus && addPartnerStatus.result) {
                if (addPartnerStatus.result === RequestResult.Success) {
                    // close the add dialog
                    setOpenAdd(false);

                    // if a clientPartnerId was successfully returned, navigate 
                    // to the new clientPartner details to allow user to 
                    // add transactions right away
                    if (addPartnerStatus.clientPartnerId) {
                        // simulate a card click to navigate
                        cardClickAction(addPartnerStatus.clientPartnerId);
                    }

                    dispatch(setToastConfig({
                        message: addPartnerStatus.message as string,
                        severity: ToastSeverity.Success
                    }));

                    // remove add status
                    dispatch(captureAddClientPartnerStatus());
                }
            }
        }, [addPartnerStatus?.result]);

        useEffect(() => {
            if (deleteClientPartnerStatus?.result === RequestResult.Success) {
                onDeleteDialogClose();
            }
            else if (deleteClientPartnerStatus?.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteClientPartnerStatus.message as string);
            }
        }, [deleteClientPartnerStatus?.result])

        const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
        };

        const clearSearch = () => {
            setSearchText('');
        };

        const cardClickAction = (id: string | undefined) => {
            if (id) {
                let route = "/clientpartner/" + id;
                navigate(route);
            }
        };

        const onDeleteDialogClose = () => {
            setOpenDelete(false);
            dispatch(captureDeleteClientPartnerStatus());
            setDeleteErrorMessage('');
        };

        const onDeleteDialogConfirm = () => {
            // delete the selected business area
            dispatch(deleteClientPartnerData(selectedPartner?.id as string));
        };

        const deleteClientPartner = (id: string) => {
            if (id && clientTradingPartners && clientTradingPartners.length) {
                let partner = clientTradingPartners.find(partnerInfo => partnerInfo.id === id);
                if (partner) {
                    setSelectedPartner(partner);
                    dispatch(captureDeleteClientPartnerStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        };

        const onAddParnterClick = () => {
            setOpenAdd(true);
        };

        const onAddClientPartnerDialogClose = () => {
            setOpenAdd(false);
        };

        const onAddClientPartnerDialogSave = (selectedPartnerId?: string | undefined) => {
            //setOpenAdd(false);
            if (selectedPartnerId) {
                // save client partner on parent
                dispatch(addClientPartner(
                    clientId,
                    selectedPartnerId
                ));
            }
        };

        // get array of partnerIds of partners already on the Client
        const getExistingPartnerIds = () => {

            let partnerIds: string[] = [];
            clientTradingPartners?.forEach(clientPartner => partnerIds.push(clientPartner.partner?.id));

            return partnerIds;
        };

        const getContent = () => {
            if (clientTradingPartners && clientTradingPartners.length > 0) {
                // the utility FilterArray function does not handle nested properties
                // since need to look at the name on partner of clientPartner, doing a simplistic filter 
                // here that won't account for soundex, but this list should never get overly large where
                // that would actually be necessary
                const filteredTradingPartners = clientTradingPartners.filter(cp => cp.partner?.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
                if (filteredTradingPartners && filteredTradingPartners.length) {
                    return (
                        filteredTradingPartners.map((clientTradingPartner: ClientPartnerModel) => (
                            (clientTradingPartner.partner &&
                                <Grid item xs={12} sm={6} md={6} lg={4} key={clientTradingPartner.id}>
                                    <ClientTradingPartnerListItem
                                        viewerRole={viewerRole}
                                        clientPartner={clientTradingPartner}
                                        clickHandler={cardClickAction}
                                        deleteClientPartner={deleteClientPartner}
                                    />
                                </Grid>
                            )
                        ))
                    );
                } else {
                    // Filtered too hard
                    // Display no results found image/message
                    let message = `No Trading Partners found match '${searchText}'. Remove or modify the keyword search to show results.`;
                    return (<NoResultsMessage topMargin={2} message={message} />);
                }
            } else {
                return (canEdit ?
                    <NoRecordsMessage topMargin={2} actionButtonText="Add Trading Partner" actionButtonClick={onAddParnterClick} />
                    :
                    <NoRecordsMessage topMargin={2} message="" />
                );
            }
        };

        return (
            <TabContainer>
                <TabToolbar justify="space-between">
                    <SearchBar
                        searchText={searchText}
                        onSearch={handleSearch}
                        onClearSearch={clearSearch}
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="Add Partner"
                            onClick={onAddParnterClick}
                            data-cy="add-client-partner"
                        />}
                </TabToolbar>
                <TabCardListBox>
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </TabCardListBox>
                <AddClientPartnerDialog
                    isOpen={openAdd}
                    onClose={onAddClientPartnerDialogClose}
                    onSave={onAddClientPartnerDialogSave}
                    existingPartnerIds={getExistingPartnerIds()}
                    error={error}
                />
                <DeleteDialog
                    isOpen={openDelete}
                    id={selectedPartner?.id ?? ''}
                    heading={'Delete Trading Partner'}
                    message={'Are you sure you want to delete this Trading Partner?'}
                    onConfirm={onDeleteDialogConfirm}
                    onReject={onDeleteDialogClose}
                    errorMessage={deleteErrorMessage}
                />
            </TabContainer>
        );
    }

export default ClientTradingPartnerList;