import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { PagingResultModelOfBillingModel, GetBillingByIdQuery, GetBillingsInput, Scalars } from '../gql-types.generated';

const QUERY_BILLING_BY_ID = gql`
query getBillingById($input: GetBillingsInput!) {
    billings (input: $input){
      nodes {
        id
        description
        currencyCode
        billingItemCount
        clientCount
        lastModifiedBy
        lastModifiedByName
        lastModifiedTime
      }
    }
  }
`;

export const queryBillingById = async (
  client: ApolloClient<NormalizedCacheObject>,
  id: Scalars['UUID']
): Promise<PagingResultModelOfBillingModel | null | undefined> => {
  const { data, errors } = await client.query<GetBillingByIdQuery>({
    variables: {
      input: {
        id
      }
    },
    query: QUERY_BILLING_BY_ID,
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.billings as PagingResultModelOfBillingModel;
};