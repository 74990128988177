import { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { Autocomplete, ListItem, TextField, styled } from '@mui/material';

const AutocompleteFilter = styled(Autocomplete)((props) => ({
    marginTop: '3px',
}));

interface AutocompleteFieldProps {
    disabled: boolean;
    autoFocus?: boolean;
    fieldId: string;
    label: string;
    maxLength?: number;
    filterValue?: string;
    searchTerm?: string;
    searchResults?: string[];
    clearIndicatorOnClick: () => void;
    onFilterValueChange: (value: string) => void;
    onSearchTermValueChange: (value: string) => void;
    onFieldBlur: () => void;
    search: (searchValue: string) => void;
}

const AutocompleteFilterField: React.FC<AutocompleteFieldProps> = props => {
    const { disabled, autoFocus=false, fieldId, label, maxLength=100, filterValue, searchTerm, searchResults, clearIndicatorOnClick, onFieldBlur, onSearchTermValueChange, onFilterValueChange, search } = props;

    const [searchResultsDialogOpen, setSearchResultsDialogOpen] = useState<boolean>(false);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    
    const toggleDialogState = () => {
        setSearchResultsDialogOpen(!searchResultsDialogOpen);
    };

    // Debounced functions would be recreated on every render, so to prevent this,
    // Wrap in React useRef to store the debounced function across renders
    const debouncedOnSearchTermValueChanged = useRef(
        debounce((searchTermValue) => {
            // once the delay time has elapsed, call to parent to do actual search/fetch
            search(searchTermValue);
            setIsSearching(true);
        }, 1000)
    ).current;

    const debouncedClearIndicatorOnClick = useRef(
        debounce(() => {
            // once the delay time has elapsed, call to parent to clear the field
            clearIndicatorOnClick();
        }, 1000)
    ).current;

    useEffect(() => {
        setIsSearching(false);
    }, [searchResults]);

    // wrapper to keep unneeded SyntheticEvent out of parent and to call debounce
    const onSearchInputChange = (event: SyntheticEvent, value: string): void => {
        onSearchTermValueChange(value);

        if (value.length >= 3) {
            debouncedOnSearchTermValueChanged(value);
        } else if (value.length === 0) {
            // if user manually clears out value, simulate the clear onClick
            // using debounce incase the debounced search was triggered while
            // user was backspacing on a longer than 3 length search term
            debouncedClearIndicatorOnClick();
        }
    };

    // wrapper to keep unneeded SyntheticEvent out of parent
    const onValueChange = (event: SyntheticEvent, value: any): void => {
        onFilterValueChange(value as string);
    };

    return (
        <AutocompleteFilter
            id={fieldId}
            size="small"
            freeSolo
            clearOnBlur={false}
            loading={isSearching}
            inputValue={searchTerm}
            onInputChange={onSearchInputChange}
            value={filterValue ?? ''}
            onChange={onValueChange}
            componentsProps={{
                clearIndicator: {
                    size: "small",
                    title: `Clear ${label} filter`,
                    onClick: () => {
                        clearIndicatorOnClick();
                    },
                },
            }}
            filterOptions={x => x}
            options={searchResults || []}
            noOptionsText="No Results"
            getOptionLabel={option => option as string || ''}
            renderOption={(props: any, option: any) => {
                props.key = props.id;
                return (
                    <ListItem {...props}>{option}</ListItem>
                );
            }}
            renderInput={params => (
                <TextField 
                    {...params} 
                    autoFocus={autoFocus}
                    fullWidth
                    label={label} 
                    placeholder="Enter at least 3 characters"
                    variant="standard"
                    disabled={disabled}
                    inputProps={{...params.inputProps, 'aria-label': `${label}`, 'maxLength': {maxLength}, }}
                    data-cy={fieldId}
                />
            )}
            onBlur={onFieldBlur}
            onKeyDown={event => {
                if (['enter', 'Enter', 'NumpadEnter'].includes(event.code) && (searchTerm as string)?.length >= 3) {
                    toggleDialogState();
                }
            }}
        />
    );
};

export default AutocompleteFilterField;