import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { RequestResult, ConfigurationModel } from '../../gql-types.generated';
import { Tooltip } from '@mui/material';
import { MainContentBox, MainDataGrid, DataGridListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import { GridActionsCellItem, GridColumns, GridRowModel, GridOverlay, GridSortModel, GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete, viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import { captureDeleteProcessStatus, captureUpsertProcessStatus, clearError, clearState, selectDeleteProcessStatus, selectError, selectProcessList, selectRequestsInFlight, selectUpsertProcessStatus } from './ProcessesSlice';
import { fetchProcessList, deleteProcess, upsertProcess } from './ProcessesActions';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import ProcessesFilterBar from '../../components/filters/ProcessesFilterBar';
import ProcessDialog from '../../components/dialogs/ProcessDialog';


interface ProcessesProps {
    viewer: Viewer | undefined;
}

const Processes: React.FC<ProcessesProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedProcess, setSelectedProcess] = useState<ConfigurationModel | undefined>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [processIsReadMode, setProcessIsReadMode] = useState(true);
    const [processRows, setProcessRows] = useState<GridRowModel[] | undefined>(undefined);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);

    const processes = useAppSelector(selectProcessList);
    const deleteProcessStatus = useAppSelector(selectDeleteProcessStatus);
    const upsertProcessStatus = useAppSelector(selectUpsertProcessStatus);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("EDI Processes");

    useEffect(() => {
        setProcessRows(getProcessRows());

        if (!processes && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [processes, requestsInFlight]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('processes-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('process-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [processes, filtersOpen]);

    useEffect(() => {
        if (upsertProcessStatus?.result === RequestResult.Success) {
            onProcessDialogClose();
            dispatch(setToastConfig({
                message: upsertProcessStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertProcessStatus());
        }
    }, [upsertProcessStatus?.result]);

    useEffect(() => {
        if (deleteProcessStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteProcessStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteProcessStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteProcessStatus.message as string);
        }
    }, [deleteProcessStatus?.result]);

    useEffect(() => {
        if (processes) {
            let numFilters = 0;
            if (filterName && filterName.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [processes, filterName, filterDescription]);

    const getSelectedRowProcess = useCallback((rowId: string) => () => {
        if (rowId && processes?.length) {
            let process = processes.find(process => process.id === rowId);
            return process;
        }
        return undefined;
    }, [processes]);

    const deleteProcessHandler = useCallback((rowId: string) => () => {
        let process = getSelectedRowProcess(rowId);
        if (process) {
            setSelectedProcess(process);
            dispatch(captureDeleteProcessStatus());
            setDeleteErrorMessage('');
            setOpenDelete(true);
        }
    }, [getSelectedRowProcess, dispatch]);

    const editProcessHandler = useCallback((rowId: string) => () => {
        let process = getSelectedRowProcess(rowId);
        if (process) {
            setSelectedProcess(process);
            dispatch(clearError());
            setProcessIsReadMode(false);
            setOpenModify(true);
        }
    }, [getSelectedRowProcess, dispatch]);

    const getProcessRows = () => {
        if (processes && processes.length > 0) {
            return processes.map((process) => {
                const { id, ediProcess, description, destPath, watcherPath } = process;

                return {
                    _raw: process,
                    id,
                    name: ediProcess,
                    description,
                    destinationPath: destPath,
                    watcherPath
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    const processColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'DESCRIPTION',
                field: 'description',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild ediDataGridWrapCellContent-alwaysLeft"
            }, {
                headerName: 'NAME',
                field: 'name',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent",
            }, {
                headerName: 'DESTINATION',
                field: 'destinationPath',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent",
            }, {
                headerName: 'WATCHER PATH',
                field: 'watcherPath',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent",
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editProcessHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteProcessHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editProcessHandler, deleteProcessHandler, canEdit, canAddOrDelete],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the EDI Processes' error={error}></ErrorMessage>
                )}
                {!error && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the EDI Processes' error={error}></ErrorMessage>
                )}
                {!error && (processes && processes.length <= 0 && filterCount === 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
                {!error && (processes && processes.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onRowClickHandler = (params: GridRowParams) => {
        if (params && params.id) {
            let process = getSelectedRowProcess(params.id as string);
            //let process = processes.find(t => t.id === params.id);
            if (process) {
                setSelectedProcess(process);
                dispatch(clearError());
                setProcessIsReadMode(true);
                setOpenModify(true);
            }
        }
    }

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onAddProcessClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedProcess) {
            setSelectedProcess(undefined);
        }
        setProcessIsReadMode(false);
        setOpenModify(true);
    };

    const onProcessDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteProcessStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedProcess(undefined);
        setProcessIsReadMode(true);
        dispatch(fetchProcessList({
            processName: filterName,
            processDescription: filterDescription,
        }));
    };

    const onProcessDialogSave = (
        destPath: string,
        ediProcess: string,
        executionMode: string,
        extension: string,
        tpgClient: string,
        watcherPath: string,
        description?: string,
        id?: string,
        p1?: string,
        p2?: string,
        p3?: string,
        p4?: string,
        p5?: string,
        p6?: string,
    ) => {
        dispatch(upsertProcess(
            destPath,
            ediProcess,
            executionMode,
            extension,
            tpgClient,
            watcherPath,
            description,
            id,
            p1,
            p2,
            p3,
            p4,
            p5,
            p6,
        ));
    };

    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deleteProcess(id));
    }

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterProcessName: string | undefined, filterProcessDescription: string | undefined) => {
        setFilterName(filterProcessName);
        setFilterDescription(filterProcessDescription);
        dispatch(clearState());
        dispatch(fetchProcessList({
            processName: filterProcessName,
            processDescription: filterProcessDescription,
        }));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='EDI Processes' id="processes-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="processes-filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Process"
                            onClick={onAddProcessClick}
                            data-cy="add-new-processes"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <ProcessesFilterBar
                id="process-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGrid
                    loading={isLoading}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Processes List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    onRowClick={onRowClickHandler}
                    rows={processRows ?? []}
                    columns={processColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            <ProcessDialog
                isOpen={openModify}
                process={selectedProcess}
                onClose={onProcessDialogClose}
                onSave={onProcessDialogSave}
                error={error}
                viewerRole={viewer?.role}
                isReadOnly={processIsReadMode}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedProcess?.id}
                heading={'Delete Process'}
                message={'Are you sure you want to delete \'' + selectedProcess?.ediProcess + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
}

export default Processes;