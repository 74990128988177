import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { PagingResultModelOfMessagingLogModel, GetMessagingLogsInput, RequestResult, DocumentStatus } from '../../gql-types.generated';
import { queryMessagingLogList } from "../../gql/QueryMessagingLogList";
import { captureUpdateLogsStatus, decrementEventLogRequestsInFlight, fetchError, fetchEventLogListSuccess, incrementEventLogRequestsInFlight } from './EventLogsSlice';
import { mutationExecuteEdiActionSvcAction } from '../../gql/MutationExecuteEdiActionSvcAction';
import { getErrorMessage } from '../../util/Common';

export const fetchEventLogList = (
    filters?: GetMessagingLogsInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementEventLogRequestsInFlight());
    try {
        const eventLogList = await queryMessagingLogList(gqlClient, filters);
        if (eventLogList) {
            dispatch(fetchEventLogListSuccess(eventLogList as PagingResultModelOfMessagingLogModel))
        }
        dispatch(decrementEventLogRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementEventLogRequestsInFlight());
    }
};

export const updateEventLogsDocumentStatus = (ids: string[], status: DocumentStatus): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{MessagingLogIds: ${JSON.stringify(ids)}, Status: ${JSON.stringify(status)}}`;
            const updateStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "UpdateMessagingLogStatusAction",
                actionArguments: actionArguments
            });
            if (updateStatus) {
                if (updateStatus.result === RequestResult.Fail) {
                    getErrorMessage(updateStatus.message as string, updateStatus.errors, true, true);
                }
                dispatch(captureUpdateLogsStatus(updateStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

