import { Grid, IconButton, Typography, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import EditIcon from '@mui/icons-material/Edit';

import { PartnerTransactionSchemaModel, PipelineModel, TransactionDirection } from "../../gql-types.generated";
import { DialogDynamicListHeader, DialogDataGridWrapper } from "../../util/SharedStyles";
import AddEditDialog from "./AddEditDialog";
import TransactionPipelineDialog from "./TransactionPipelineDialog";
import DialogPartnerTransactionSchemaList from "../lists/transactions/DialogPartnerTransactionSchemaList";

interface EditPartnerTransactionDialogProps {
    isOpen: boolean;
    partnerTransactionId: string;
    transactionDirection: TransactionDirection;
    transactionSchemas?: PartnerTransactionSchemaModel[] | undefined;
    pipeline?: PipelineModel;
    onClose: () => void;
    onSave: (
        partnerTransactionId: string,
        pipelineId?: string
    ) => void;
    refreshPartnerData: () => void;
    error?: Error | undefined;
}

const EditPartnerTransactionDialog: React.FC<EditPartnerTransactionDialogProps> = props => {
    const { isOpen, partnerTransactionId, transactionDirection, transactionSchemas, pipeline, onClose, onSave, refreshPartnerData, error } = props;
    const isInboundTransaction = transactionDirection === TransactionDirection.Inbound;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [pipelineIdValue, setPipelineIdValue] = useState<string>('');
    const [pipelineDescription, setPipelineDescription] = useState<string>('');
    const [pipelineDialogOpen, setPipelineDialogOpen] = useState(false);
    
    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setPipelineIdValue('');
            setPipelineDescription('');
        } 
    }, [isOpen]);

    useEffect(() => {
        if (pipeline) {
            setPipelineIdValue(pipeline.id);
            setPipelineDescription(pipeline.description as string);
        } 
    }, [pipeline]);

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(
                partnerTransactionId,
                pipelineIdValue
            );
        }
    };

    const closeEditDialog = () => {
        onClose();
    };

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const onPipelineDialogClose = () => {
        setPipelineDialogOpen(false);
    };

    const onEditPipeline = () => {
        setPipelineDialogOpen(true);
    };

    const savePipelineFromDialogPicker = (selectedPipeline?: PipelineModel) => {
        setIsFormDirty(true);
        if (selectedPipeline) {
            setPipelineIdValue(selectedPipeline.id);
            setPipelineDescription(selectedPipeline.description as string)
        } else {
            setPipelineIdValue('');
            setPipelineDescription('');
        }
        // close the dialog after setting values
        onPipelineDialogClose();
    };

    const getPipelineContent = () => {
        let pipelineText = pipelineDescription ? pipelineDescription : "Pipeline Not Set";
        
        return (
            <Grid>
                <DialogDynamicListHeader aria-label='selected-pipeline' justifyContent='flex-start'>Selected Pipeline</DialogDynamicListHeader>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                    <Typography variant='body1' >{pipelineText}</Typography>
                    <IconButton color="primary" aria-label="edit-Pipeline" onClick={onEditPipeline}>
                        <EditIcon />
                    </IconButton>
                </Stack>
            </Grid>
        );
    };

    const getSchemaContent = () => {
        return (
            <DialogPartnerTransactionSchemaList 
                parentId={partnerTransactionId ?? ''}
                schemas={transactionSchemas} 
                isLoading={submitted}
                isParentTransactionInbound={isInboundTransaction}
                refreshPartnerData={refreshPartnerData}
            />
        );
    };
   
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={partnerTransactionId}
            entityName="Partner Transaction"
            maxWidth='md'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <DialogDataGridWrapper container>
                    {getPipelineContent()}
                </DialogDataGridWrapper>
                <DialogDataGridWrapper container>
                    {getSchemaContent()}
                </DialogDataGridWrapper>
            </Grid>    
            <TransactionPipelineDialog
                isOpen={pipelineDialogOpen}
                pipeline={pipeline}
                onClose={onPipelineDialogClose}
                onSave={savePipelineFromDialogPicker}
                error={error}
            />
        </AddEditDialog>
    );
}

export default EditPartnerTransactionDialog;