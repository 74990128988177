import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { Viewer, ToastSeverity } from "../../util/Constants";
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridRowParams, GridSelectionModel, GridSortItem, GridSortModel, useGridApiContext } from "@mui/x-data-grid-pro";
import { CounterModel, CounterOrderByInput, CounterSegmentType, GetCounterInput, OrderDirection, RequestResult, UpsertCounterInput } from "../../gql-types.generated";
import { useSelector } from "react-redux";
import { captureDeleteCounterStatus, captureUpsertCounterStatus, clearError, clearState, selectCounterPagingResult, selectCounterList, selectDeleteCounterStatus, selectError, selectRequestsInFlight, selectUpsertCounterStatus } from "./CounterTableSlice";
import { useTitle } from "../../util/Common";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { debounce } from "lodash";
import { deleteCounter, fetchCountersList, upsertCounter } from "./CounterTableActions";
import ErrorMessage from "../../components/ErrorMessage";
import { defaultPageSize } from "../../util/Constants";
import { CircularProgress, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MainDataGridLoadingSkeleton from "../../components/MainDataGridLoadingSkeleton";
import NoResultsMessage from "../../components/NoResultsMessage";
import CreateNewButton from '../../components/buttons/CreateNewButton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import { viewerCanAddDelete, viewerCanEdit } from "../../util/ViewerUtility";
import { DataGridListScrollBox, MainContentBox, MainDataGridNoRowHover, PageTitleToolbarGrid } from "../../util/SharedStyles";
import PageTitleBar from "../../components/PageTitleBar";
import FiltersButton from "../../components/buttons/FiltersButton";
import CounterDialog from "../../components/dialogs/CounterDialog";
import CounterFilterBar from "../../components/filters/CounterFilterBar";




interface CounterTableProps {
    viewer: Viewer | undefined;
}

const CounterTable: React.FC<CounterTableProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedCounter, setSelectedCounter] = useState<CounterModel | undefined>(undefined);
    const [filtersOpen, setFiltersOpen] = useState(true);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterSenderId, setFilterSenderId] = useState<string | undefined>(undefined);
    const [filterControlNumber, setFilterControlNumber] = useState<string | undefined>(undefined);
    const [filterSegment, setFilterSegment] = useState<CounterSegmentType | undefined>(undefined);
    const [filterMachine, setFilterMachine] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [counterTableRows, setCounterTableRows] = useState<GridRowModel[] | undefined>(undefined);
    const [debounceOn, setDebounceOn] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'senderId',
            sort: 'asc',
        },
    ]);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);
    const [serverSortModel, setServerSortModel] = useState<CounterOrderByInput[] |undefined>([{
        senderId: OrderDirection.Asc
    } as CounterOrderByInput]);

    const pageSize = defaultPageSize;

    const error = useSelector(selectError);
    const requestsInFlight = useSelector(selectRequestsInFlight);
    const counters = useSelector(selectCounterList);
    const pagingResult = useSelector(selectCounterPagingResult);
    const upsertCounterStatus = useSelector(selectUpsertCounterStatus);
    const deleteCounterStatus = useSelector(selectDeleteCounterStatus);

    useTitle("Counter Table");

    const debounceOnSortingChanged = useRef(
        debounce((servSortModel) => {
            onSortModelChanged(servSortModel);
        }, 1000)
    ).current;

    useEffect(() => {
        dispatch(clearState());
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    },[]);

    useEffect(() => {
        setCounterTableRows(getCounterTableRows());

        if (!counters && requestsInFlight > 0) {
            setIsLoading(true);
        } else if (requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [counters, requestsInFlight]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('counter-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('counter-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0) {
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
        }
    }, [counters, filtersOpen]);

    useEffect(() => {
        if (upsertCounterStatus?.result === RequestResult.Success) {
            onCounterDialogClose();
            dispatch(setToastConfig({
                message: upsertCounterStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertCounterStatus());
        }
    }, [upsertCounterStatus?.result]);

    useEffect(() => {
        if (deleteCounterStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteCounterStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteCounterStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteCounterStatus.message as string);
        }
    }, [deleteCounterStatus?.result]);

    useEffect(() => {
        if (counters) {
            let numFilters =0;
            if (filterSenderId && filterSenderId.length > 0) {
                numFilters += 1;
            }
            if (filterControlNumber && filterControlNumber.length > 0) {
                numFilters += 1;
            }
            if (filterSegment && filterSegment.length > 0) {
                numFilters += 1;
            }
            if (filterMachine && filterMachine.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [counters, filterSenderId, filterControlNumber, filterSegment, filterMachine]);

    useEffect(() => {
        return () => {
            debounceOnSortingChanged.cancel();
        };
    }, [debounceOnSortingChanged]);

    const getSortRefreshInput = () => {
        return {
            after: undefined,
            limit: pageSize,
            senderId: filterSenderId,
            controlNumber: filterControlNumber,
            order: serverSortModel,
            segment: filterSegment,
            machine: filterMachine
        } as GetCounterInput
    }

    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getSortRefreshInput();
            if (debounceOn !== true) {
                onSortModelChanged(sortRefreshData);
            } else {
                debounceOnSortingChanged(sortRefreshData);
            }
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    const onSortModelChanged = (refreshInput: GetCounterInput) => {
        dispatch(clearState());
        dispatch(fetchCountersList(refreshInput));
    }

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setSelectionModel(currentSelectionModel);
    };


    const GetApiRef = () => {
        return useGridApiContext();
    }

    const loadingOverlay = () => {

        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Counter Table' error={error}></ErrorMessage>
                )}
                {!error && (counters && counters.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!counters) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Counter Table' error={error}></ErrorMessage>
                )}
                {!error && (counters && counters.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} message="Try different filters to see results." />
                )}
            </GridOverlay>
        );
    };    

    const getSelectedRowCounter = useCallback((rowId: string) => () => {
        if (rowId && counters?.length) {
            let counter = counters.find(crossRef => crossRef.id === rowId);
            return counter;
        }
        return undefined;
    }, [counters]);

    const editCounterHandler = useCallback((rowId: string) => () => {
        let crossRef = getSelectedRowCounter(rowId);
        if (crossRef) {
            setSelectedCounter(crossRef);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowCounter, dispatch]);

    const deleteCounterHandler = useCallback((rowId: string) => () => {
        let crossRef = getSelectedRowCounter(rowId);
        if (crossRef) {
            setSelectedCounter(crossRef);
            dispatch(captureDeleteCounterStatus());
            setDeleteErrorMessage('');
            setOpenDelete(true);
        }
    }, [getSelectedRowCounter, dispatch]);

    const getCounterTableRows = () => {
        if (counters && counters.length > 0) {
            return counters.map((counter) => {
                const {
                    id,
                    senderId,
                    controlNumber,
                    segment,
                    machine,
                    comments
                } = counter;

                return {
                    _raw: counter,
                    id,
                    senderId,
                    controlNumber,
                    segment,
                    machine,
                    comments
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return[];
        }
    };

    const counterColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                // SenderId
                headerName: "SENDER ID",
                field: 'senderId',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                // Segment
                headerName: "SEGMENT",
                field: 'segment',
                minWidth: 200,                
                flex: 1,
                sortable: false,
            }, {
                // ControlNumber
                headerName: "CONTROL NUMBER",
                field: 'controlNumber',
                minWidth: 200,                
                flex: 1,
                sortable: true,
            }, {
                // Machine
                headerName: "MACHINE",
                field: 'machine',
                minWidth: 200,                
                flex: 2,
                sortable: true,
            }, {
                // Comments
                headerName: "COMMENTS",
                field: 'comments',
                minWidth: 200,                
                flex: 2,
                sortable: false,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editCounterHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteCounterHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editCounterHandler, deleteCounterHandler, canAddOrDelete, canEdit],
    );

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as CounterOrderByInput;
        switch (entry.field) {
            case 'senderId':
                newModel.senderId = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'controlNumber':
                newModel.controlNumber = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'segment':
                newModel.segment = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
            case 'machine':
                newModel.machine = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
                break;
        }
        return newModel;
    }

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as CounterOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onAddCounterClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedCounter) {
            setSelectedCounter(undefined);
        }
        setOpenModify(true);
    };

    const onCounterDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteCounterStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedCounter(undefined);
        dispatch(fetchCountersList({
            after: undefined,
            limit: pageSize,
            senderId: filterSenderId,
            controlNumber: filterControlNumber,
            order: serverSortModel,
            segment: filterSegment,
            machine: filterMachine
        }));
    };

    const onCounterDialogSave = (
        crossRef: UpsertCounterInput
    ) => {
        dispatch(upsertCounter(crossRef));
    };
    
    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deleteCounter(id));
    }

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchCountersList({
            after: endEdge,
            limit: pageSize,
            senderId: filterSenderId,
            controlNumber: filterControlNumber,
            order: serverSortModel,
            segment: filterSegment,
            machine: filterMachine
        }));
    };

    const handlePageLoad = () => {
        if (!pagingResult) {
            return;
        }
        if (!pagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(pagingResult.cursor.nextPage);
    };

    const refreshFilters = (
        filterSendId: string | undefined, 
        filterControlNum: string | undefined,
        filterSeg: CounterSegmentType | undefined,
        filterMach: string | undefined) => {
        setFilterSenderId(filterSendId);
        setFilterControlNumber(filterControlNum);
        setFilterSegment(filterSeg);
        setFilterMachine(filterMach);
        dispatch(clearState());
        dispatch(fetchCountersList({
            after: undefined,
            limit: pageSize,
            senderId: filterSendId,
            controlNumber: filterControlNum,
            order: serverSortModel,
            segment: filterSeg,
            machine: filterMach
        }));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Counter Table' id="counter-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton 
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Counter Entry"
                            onClick={onAddCounterClick}
                            data-cy="add-new-counter-entry"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CounterFilterBar
                id="counter-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    rowHeight={52}
                    aria-label="Counter Table"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    rows={counterTableRows ?? []}
                    columns={counterColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </DataGridListScrollBox>
            <CounterDialog
                isOpen={openModify}
                counter={selectedCounter}
                onClose={onCounterDialogClose}
                onSave={onCounterDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedCounter?.id}
                heading={'Delete Counter Entry'}
                message={'Are you sure you want to delete this row?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    )
};

export default CounterTable;