import { ChangeEvent, useEffect, useState } from "react";
import { PostProcessingBlobModel, UpsertPostProcessingBlobInput } from "../../gql-types.generated";
import { Grid, styled, TextField } from "@mui/material";
import FileDropZone from '../FileDropZone';
import AddEditDialog from "./AddEditDialog";
import { maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface DialogProps {
    isOpen: boolean;
    postProcessingBlob?: PostProcessingBlobModel | null | undefined;
    onClose: () => void;
    onSave: (upsertPostProcessingData: UpsertPostProcessingBlobInput) => void;
    error?: Error | undefined;
}

const PostProcessingBlobDialog: React.FC<DialogProps> = props => {
    const { isOpen, postProcessingBlob, onClose, onSave, error } = props;
    const id = postProcessingBlob?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription]= useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    const [assemblyFileContents, setAssemblyFileContents] = useState<string | undefined>(undefined);
    const [existingAssemblyFile, setExistingAssemblyFile] = useState<File | undefined>(undefined);
    const [existingAssemblyString, setExistingAssemblyString] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (postProcessingBlob) {
            setFromModel();
        } else {
            setToDefaults();
        }
    }, [postProcessingBlob]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
        else {
            setFromModel();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setDescription('');        
        setAssemblyFileContents(undefined);
        setExistingAssemblyFile(undefined);
        setExistingAssemblyString(undefined);
    }

    const setFromModel = () => {
        if (postProcessingBlob) {
            if (postProcessingBlob.description) {
                setDescription(postProcessingBlob.description);
            }
            if (postProcessingBlob.processBlobString !== undefined && postProcessingBlob.processBlobString !== null) {
                setExistingAssemblyString(postProcessingBlob.processBlobString);
                setAssemblyFileContents(postProcessingBlob.processBlobString);
                // this File will be used for display purposes only, and we are not saving the document title.  
                // processBlobString could be quite large, so using a small string (again display only).
                // Actual data will be used when saving.
                let assemblyFile = new File(["Sm9lIG1hZGUgdGhpcyBzYW1wbGUgc3RyaW5nLCB5b3Ugc2hvdWxkbid0IGJlIHNlZWluZyBpdC4gIA=="], "previously uploaded assembly",{type:"text/plain"});
                setExistingAssemblyFile(assemblyFile);
            }
        }
    }

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileContents = await getAssemblyFileContents(filesToUpload[0]);
            setAssemblyFileContents(fileContents);

        } else {
            // when no file, make sure any previous file fields are cleared
            setAssemblyFileContents(undefined);
        }
    };

    const getAssemblyFileContents = async (file: File) => {
        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let fileContents = assemblyFileContents ?? "";
        return (isFormDirty && description?.trim().length > 0 && fileContents?.trim().length > 0);
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            id,
            description,
            processBlobString: assemblyFileContents
        } as UpsertPostProcessingBlobInput);
    };

    // onChange handlers
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    
    // property props
    const descriptionProps = {        
        'aria-label': 'description',
        'maxLength': 100,
    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Post Processing Assembly"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-post-processing-blob-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-post-processing-blob-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Post Processing Assembly File*"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your assembly file here"
                        existingFiles={existingAssemblyFile ? [existingAssemblyFile] : undefined}
                        onChange={onFileUploadChange}
                    />
                </Grid>            
            </GridContainer>
        </AddEditDialog>
    );
};

export default PostProcessingBlobDialog;