import { useState } from 'react';
import { IconButton, Menu, MenuItem, Divider, Grid, Skeleton, Typography } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TransactionIcon from "@mui/icons-material/Description";
import { NotificationFormatterModel, NotificationFormatterBodyType } from "../../gql-types.generated";
import { CardChip, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';

interface NotificationFormatterListItemProps {
    formatter?: NotificationFormatterModel | undefined;
    loading?: boolean | false;
    canDelete?: boolean;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    onAddTransactionClick: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const NotificationFormatterListItem: React.FC<NotificationFormatterListItemProps> = (props: NotificationFormatterListItemProps) => {
    const { formatter, loading = false, canDelete = false } = props;
    const id = formatter?.id as string;
    const description = formatter?.description;
    const totalTransactions = formatter?.totalTransactions;
    const bodyType = formatter?.bodyType;
    const isForError = formatter?.isForError ?? false as boolean;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (!loading) {
            props.clickAction(id);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();
        
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "transaction") {
            if (id) {
                props.onAddTransactionClick(id);
            }
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(id);
        }
        setAnchorEl(null);
    };

    let cardAction = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let cardTitle = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        description
    );

    let chipContainerJustify = "flex-end";
    let transactionElement = null;
    if (totalTransactions && totalTransactions > 0) {
        chipContainerJustify = "space-between";
        transactionElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <TransactionIcon fontSize='small' />
                <Typography>Transactions: {totalTransactions}</Typography>
            </Grid>
        )
    }

    let bodyTypeChip;
    let typeDisplay = bodyType === NotificationFormatterBodyType.Text ? "Text" : "HTML";
    bodyTypeChip = <CardChip label={typeDisplay} />

    let forErrorChip;
    if (isForError === true) {
        forErrorChip = <CardChip label="For Error" />
    }
        
    let chipsElement = (
        <Grid container item xs={'auto'} direction="row" gap={'4px'}>
            {forErrorChip}
            {bodyTypeChip}
        </Grid>
    )

    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={cardTitle} 
                titleTypographyProps={{
                    noWrap: true,
                    textOverflow: 'ellipsis'
                  }}
                //action prop for adding 3-dot menu options button
                action={cardAction}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {transactionElement}
                            {chipsElement}
                        </>
                    )
                }
            </CardFooter>
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                <MenuItem
                    data-operation="transaction"
                    onClick={handleMenuClose}
                >
                    Add Transaction
                </MenuItem>
                { canDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    >
                    Delete    
                </MenuItem>
                }
            </Menu> 
        </ClickableRootCard>
    );
};

export default NotificationFormatterListItem;

