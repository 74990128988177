import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ClientPartnerTransactionPostProcessingModel, UpsertClientPartnerTransactionPostProcessingInput, ProcessName, PostProcessingBlobModel } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import { BlankMenuItem } from "../../util/SharedStyles";
import { capitalizeWithUnderscoreRemoval, isJson } from "../../util/Common";

interface TransactionPostProcessingDialogProps {
    isOpen: boolean;
    clientPartnerTransactionId: string;
    currentPostProcess?: ClientPartnerTransactionPostProcessingModel;
    availablePostProcessingBlobs?: PostProcessingBlobModel[];
    existingBlobsIds?: string[];
    onClose: () => void;
    onSave: (
        postProcessingInput: UpsertClientPartnerTransactionPostProcessingInput
    ) => void;
    error?: Error | undefined;
}

const TransactionPostProcessingDialog: React.FC<TransactionPostProcessingDialogProps> = props => {
    const { isOpen, clientPartnerTransactionId, currentPostProcess, availablePostProcessingBlobs, existingBlobsIds, onClose, onSave, error } = props;
    const id = currentPostProcess?.id;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedBlobId, setSelectedBlobId] = useState<string>('');
    const [selectedProcess, setSelectedProcess] = useState<ProcessName | undefined>(undefined);
    const [processName, setProcessName] = useState<string | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<number | undefined>(1);
    const [processArguments, setProcessArguments] = useState<string | undefined>(undefined);
    const [processArgsInvalid, setProcessArgsInvalid] = useState(false); // Default invalid state to false
    const [processArgsErrorText, setProcessArgsErrorText] = useState(''); 
    
    const processes = Object.values(ProcessName);

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
        } else {
            setFromRecord();
        }
    }, [isOpen]);

    useEffect(() => {
        if (currentPostProcess) {
            setFromRecord();
        } else {
            setToDefaults();
        }
    }, [currentPostProcess]);

    const setToDefaults = () => {
        setIsFormDirty(false);
        setSubmitted(false);
        setSelectedBlobId('');
        setSelectedProcess(undefined);
        setProcessArguments(undefined);
        setProcessName(undefined);
        setSortOrder(1);
    };

    

    const setFromRecord = () => {
        if (currentPostProcess) {
            if (currentPostProcess.processArguments){
                setProcessArguments(currentPostProcess.processArguments);
            }
            if (currentPostProcess.ediProcessName) {
                setSelectedProcess(currentPostProcess.ediProcessName as ProcessName);
            }
            if (currentPostProcess.postProcessingBlobId) {
                setSelectedBlobId(currentPostProcess.postProcessingBlobId);
            }
            if (currentPostProcess.processName) {
                setProcessName(currentPostProcess.processName);
            }
            if (!isNaN(currentPostProcess.sortOrder)) {
                setSortOrder(currentPostProcess.sortOrder);
            }
        }
    };

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(
                {
                    clientPartnerTransactionId: clientPartnerTransactionId,
                    ediProcessName: selectedProcess,
                    id: id,
                    postProcessingBlobId: selectedBlobId,
                    processArguments: processArguments,
                    processName: processName,
                    sortOrder: sortOrder
                } as UpsertClientPartnerTransactionPostProcessingInput);
        }
        closeEditDialog();
    };

    const validation = () => {
        if (isFormDirty) {
            let isValid = true;
            if (!sortOrder || isNaN(sortOrder)){
                isValid = false;
            }
            else if (!(clientPartnerTransactionId && processName && selectedBlobId && processArguments)) {
                isValid = false;
            } 
            else if (processArgsInvalid) {
                isValid = false;
            }
            
            return isValid;
        } 
        return false;
    };

    const closeEditDialog = () => {
        onClose();
    };

    const onError = () => {
        setSubmitted(false);
    };

    const getBlobDescriptionList = () => {
        if (availablePostProcessingBlobs && availablePostProcessingBlobs.length) {
            const mappedItems = (
                availablePostProcessingBlobs.map((ppBlob: PostProcessingBlobModel) => (
                    <MenuItem
                        key={ppBlob.id}
                        value = {ppBlob.id}
                    >
                        {ppBlob.description}
                    </MenuItem>
                ))
            );
            return mappedItems;
        }
    };

    const getEdiProcessList = () => {
        if (processes && processes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                processes.map((process: ProcessName) => (
                    <MenuItem
                        key={process}
                        value={process}
                    >
                        {capitalizeWithUnderscoreRemoval(process)}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    const onProcessNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setProcessName(event.target.value);
    };

    const onProcessArgumentsChange = (event: ChangeEvent<HTMLInputElement>) => {
        let toValidate = event.target.value;
        if (toValidate || toValidate === "") {
            const isValid = isJson(toValidate);
            setProcessArgsInvalid(!isValid);
            if (isValid) {
                setIsFormDirty(true);
                setProcessArgsErrorText('');
            } else {
                setProcessArgsErrorText('Invalid JSON format.  Enter a valid JSON value.');
            }
            setProcessArguments(toValidate);
        }
    };

    const onSortOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        let val = parseInt(event.target.value);
        setSortOrder(!isNaN(val) ? val : undefined);
    };

    const onSelectedBlobChange = (event: SelectChangeEvent<string>) => {        
        setSelectedBlobId(event.target.value);
    };

    const onSelectedProcessChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        let currentValue = value?.length > 0 ? value as ProcessName : undefined;
        setSelectedProcess(currentValue);
    };

    const processNameProps = {
        'aria-label': 'process name',
        'maxLength': 16000,
    };

    const processArgumentsProps = {
        'aria-label': 'process arguments',
        'maxLength': 16000,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id="transaction-post-processing-dialog"
            entityName="Transaction Post Processing"
            maxWidth="sm"
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2} columns={1}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-trans-post-processing-process-name"
                        fullWidth
                        disabled={submitted}
                        value={processName}
                        label="Process Name"
                        inputProps={processNameProps}
                        onChange={onProcessNameChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-trans-post-processing-process-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl required variant="standard" fullWidth >
                        <InputLabel id="dialog-trans-post-processing-blob-label">Post Process Assembly</InputLabel>
                        <Select
                            labelId="dialog-trans-post-processing-blob-label"
                            aria-labelledby="dialog-trans-post-processing-blob-label"
                            id="dialog-trans-post-processing-blob"
                            value={selectedBlobId ?? ''}
                            MenuProps={{
                                'aria-label': 'post process assembly',
                            }}
                            onChange={onSelectedBlobChange}
                            data-cy="dialog-trans-post-processing-blob"
                        >
                            {getBlobDescriptionList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-trans-post-processing-process-arguments"
                        fullWidth
                        multiline
                        maxRows={10}
                        disabled={submitted}
                        value={processArguments}
                        label="Process Arguments (JSON format)"
                        inputProps={processArgumentsProps}
                        onChange={onProcessArgumentsChange}
                        required
                        error={processArgsInvalid}
                        helperText={processArgsErrorText}
                        autoComplete="off"
                        data-cy="dialog-trans-post-processing-process-arguments"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth >
                        <InputLabel id="dialog-trans-post-processing-edi-process-label">EDI Process Name</InputLabel>
                        <Select
                            labelId="dialog-trans-post-processing-edi-process-label"
                            aria-labelledby="dialog-trans-post-processing-edi-process-label"
                            id="dialog-trans-post-processing-edi-process"
                            value={selectedProcess ?? ''}
                            MenuProps={{
                                'aria-label': 'edi process name',
                            }}
                            onChange={onSelectedProcessChange}
                            data-cy="dialog-trans-post-processing-edi-process"
                        >
                            {getEdiProcessList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-trans-post-processing-sort-order"
                        fullWidth
                        disabled={submitted}
                        value={sortOrder !== undefined ? sortOrder : ""}
                        label="Sort Order"
                        inputProps={{
                            'aria-label': 'sort order',
                            'max': '9999999',
                            'min': '0',
                            'inputMode': "numeric",
                            'pattern': '[0-9]*'
                        }}
                        onChange={onSortOrderChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-trans-post-processing-sort-order"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default TransactionPostProcessingDialog;