import { useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { capitalize, debounce } from 'lodash';

import { CircularProgress, Typography, Stack } from '@mui/material';
import { GridRowModel, GridColDef, GridOverlay, GridCellParams, GridRenderCellParams, GridSortModel, GridSelectionModel } from '@mui/x-data-grid-pro';

import { PartnerStatus } from '../../gql-types.generated';
import { clearState, clearError, selectError, selectRequestsInFlight, selectTradingPartnersList, selectTradingPartnersConnection } from '../../features/TradingPartners/TradingPartnersSlice';
import { fetchTradingPartnersList } from '../../features/TradingPartners/TradingPartnersActions';
import { DialogDataGridWrapper, DialogDataGrid } from '../../util/SharedStyles';
import GridCellDualVert from '../listItems/GridCellDualVert';
import FilterInput from '../FilterInput';
import AddEditDialog from "./AddEditDialog";


interface AddClientPartnerDialogProps {
    isOpen: boolean;
    existingPartnerIds: string[];
    onClose: () => void;
    onSave: (partnerId?: string) => void;
    error?: Error | undefined;
}


const AddClientPartnerDialog: React.FC<AddClientPartnerDialogProps> = props => {
    const { isOpen, existingPartnerIds, onClose, onSave, error } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc',
        },
    ]);

    const fetchError = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const partnerPagingResult = useAppSelector(selectTradingPartnersConnection);

    const pageSize = 10;

    // always want to filter by active
    const activeStatusFilter = [capitalize(PartnerStatus.Active)];

    const partners = useAppSelector(selectTradingPartnersList);

    // Debounced function would be recreated on every render, so to prevent this,
    // Wrap in React useRef to store the debounced function across renders
    const debouncedOnFilterChanged = useRef(
        debounce((nameFilterValue) => {
            // once the delay time has elapsed, call parent method to handle filter changes to reload
            onFilterChanged(nameFilterValue);
        }, 1000)
    ).current;

    useEffect(() => {
        if (isOpen) {
            // dialog opened so refresh the list
            dispatch(clearState());
            dispatch(fetchTradingPartnersList(undefined, pageSize, undefined, undefined, activeStatusFilter, undefined, undefined));
        } else {
            setSelectionModel([]);
            setSubmitted(false);
            setIsFormDirty(false);
            setNameFilter(undefined);
            dispatch(clearError());
        }
    }, [isOpen]);

    useEffect(() => {
        if (!partners || requestsInFlight > 0) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [partners, requestsInFlight]);


    const loadPage = (endEdge: string) => {
        dispatch(fetchTradingPartnersList(endEdge, pageSize, nameFilter, undefined, activeStatusFilter, undefined, undefined));
    };

    const handlePageLoad = () => {
        if (!partnerPagingResult) {
            return;
        }
        if (!partnerPagingResult.cursor?.nextPage) {
            return;
        }
        loadPage(partnerPagingResult.cursor.nextPage);
    };

    const getPartnerRows = () => {
        if (partners && partners.length > 0) {
            return partners?.map((partner) => {
                const { id, name, totalTransactions } = partner;

                return {
                    _raw: partner,
                    id,
                    name,
                    transactionCount: totalTransactions,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    // using DataGrid, define each column
    const partnerColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'PARTNER',
            flex: 1,
            sortable: true,
            cellClassName: (cell: GridCellParams) => {
                // workaround to have the appearance of a disabled row
                // when a partner already exists on a client
                let rowId = cell.row.id;
                if (existingPartnerIds?.indexOf(rowId) >= 0) {
                    return 'ediDataGridCellDisabled';
                }
                return "";
            },
            renderCell: (params: GridRenderCellParams) => {
                const { value, row } = params;
                const numTransactions = row.transactionCount;
                const transactionDescription = numTransactions > 0 ? `Transactions: ${numTransactions}` : '';
                return (
                    <GridCellDualVert header={value} sub={transactionDescription} boldHeader />
                )
            }
        }

    ];

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'partners-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error || fetchError) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && !fetchError && partners?.length === 0 && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            No Records found.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please clear or try a different filter.
                        </Typography>
                    </Stack>
                )}
            </GridOverlay>
        );
    };


    // Filter the partner list
    const filterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        let filterText = event.target.value;
        debouncedOnFilterChanged(filterText);
    }

    // Filter the partner list
    const onFilterChanged = (filterText: string) => {
        dispatch(clearState());
        if (filterText?.length > 0) {
            // filter by name
            setNameFilter(filterText);
            dispatch(fetchTradingPartnersList(undefined, pageSize, filterText, undefined, activeStatusFilter, undefined, undefined));
        }
        else {
            // text was cleared, so refresh list without name filter
            setNameFilter(undefined);
            dispatch(fetchTradingPartnersList(undefined, pageSize, undefined, undefined, activeStatusFilter, undefined, undefined));
        }
    }

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {

        let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
        //setSelectedRowId(rowId);

        if (rowId) {
            // workaround for not being able to disable row
            // only set new selection if not "disabled", meaning not already on the client
            if (existingPartnerIds?.indexOf(rowId) < 0) {
                setSelectionModel(currentSelectionModel);
                setIsFormDirty(true);
            }
        }
        else {
            setIsFormDirty(false);
        }



    };

    const isFormValid = () => {
        if (selectionModel && selectionModel.length > 0 && isFormDirty) {
            return true
        }
        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const submitForm = () => {
        if (isFormValid()) {
            setSubmitted(true);
            // convert GridRowId to string for saving
            let rowId = selectionModel && selectionModel[0].toString();
            onSave(rowId);
        }
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            titlePrefix="Select a"
            entityName="Partner"
            padding='16px'
            maxWidth='md'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error || fetchError}
        >
            <FilterInput
                id="partner-filter"
                placeholder='Filter Partners'
                onFilter={filterHandler}
            />
            <DialogDataGridWrapper container>
                <DialogDataGrid
                    rowCount={0}
                    headerHeight={36}
                    rowHeight={52}
                    aria-label="Partners Pick List"
                    paginationMode="server"
                    pageSize={pageSize}
                    rowsPerPageOptions={[pageSize]}
                    hideFooter
                    onPageChange={handlePageLoad}
                    loading={isLoading}
                    rows={getPartnerRows()}
                    columns={partnerColumns}
                    disableColumnMenu
                    disableMultipleSelection
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}

                />
            </DialogDataGridWrapper>
        </AddEditDialog>
    );
};

export default AddClientPartnerDialog;