import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import { Grid, FormControl, InputLabel, TextField, MenuItem, Select, SelectChangeEvent, styled } from "@mui/material";

import { TransactionMapModel, TransactionModel, TransactionDirection } from '../../gql-types.generated';
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import { selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';
import { maxMapUploadFiles, maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";

const DivEllipsis = styled('div')((props) => ({
    display: 'block',
    width: '540px',
    textOverflow: "ellipsis",
    overflow: 'hidden',
}));

const DirectionImg = styled('img')((props) => ({
    verticalAlign: 'text-top',
    marginRight: '8px'
}));

interface DialogProps {
    isOpen: boolean;
    map?: TransactionMapModel | null | undefined;
    onClose: () => void;
    onSave: (transactionId: string, description: string, documentString?: string, sourceFileName?: string, standardVersion?: string, id?: string) => void;
    error?: Error | undefined;
}

const MapDialog: React.FC<DialogProps> = props => {
    const { isOpen, map, onClose, onSave, error } = props;
    const id = map?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [description, setDescription] = useState<string>('');
    const [mapFileContents, setMapFileContents] = useState<string | undefined>(undefined);
    const [sourceFileName, setSourceFileName] = useState<string | undefined>(undefined);
    const [existingMapFile, setExistingMapFile] = useState<File | undefined>(undefined);
    const [standardVersion, setStandardVersion] = useState<string | undefined>(undefined);
    const [transactionId, setTransactionId] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    // get the list of transactions for the dropdown
    const transactions = useAppSelector(selectTransactionList);

    useEffect(() => {
        if (!isOpen) {
            // set submitted to false and clear fields when dialog is closed
            resetInitialState();
        } else {
            setFromMapDetails();
        }
    }, [isOpen]);

    useEffect(() => {
        // if have a map, then populate for Edit
        if (map) {
            setFromMapDetails();
        }
        else {
            resetInitialState();
        }
    }, [map]);

    const setFromMapDetails = () => {
        if (map) {
            if (map.description) {
                setDescription(map.description);
            }
            if (map.standardVersion) {
                setStandardVersion(map.standardVersion);
            }
            if (map.sourceFileName) {
                setSourceFileName(map.sourceFileName);
            }
            if (map.documentString) {
                setMapFileContents(map.documentString);
            }
            if (map.sourceFileName && map.documentString !== undefined && map.documentString !== null ) {
                let mapFile = new File([map.documentString], map.sourceFileName);
                setExistingMapFile(mapFile);
            }
            if (map.transactionId) {
                setTransactionId(map.transactionId);
            } else if (map.transaction?.id) {
                setTransactionId(map.transaction?.id);
            }
        }
    }

    const resetInitialState = () => {
        setSubmitted(false);
        setDescription('');
        setStandardVersion(undefined);
        setMapFileContents(undefined);
        setExistingMapFile(undefined);
        setTransactionId('');
        setIsFormDirty(false);
    };

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = description?.trim().length > 0 && transactionId?.trim().length > 0;

        return isFormDirty && requiredCheck;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(transactionId, description, mapFileContents, sourceFileName, standardVersion, id);
    };

    // onChange handlers
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onTransactionChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        setTransactionId(event.target.value ?? '');
    };

    const onStandardVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setStandardVersion(event.target.value);
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            let fileName = fileToUpload?.name;
            let fileContents = await getMapFileContents(filesToUpload[0]);
            setMapFileContents(fileContents);
            setSourceFileName(fileName);

        } else {
            // when no file, make sure any previous file fields are cleared
            setMapFileContents(undefined);
            setSourceFileName(undefined);
        }
    };

    const getMapFileContents = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const getTransactionsDropList = () => {
        if (transactions && transactions.length) {
            return (
                transactions.map((transaction: TransactionModel) => {
                    const directionIcon = (transaction.direction === TransactionDirection.Inbound) ? <DirectionImg src={InboundIcon} alt="Inbound" aria-label='inbound'></DirectionImg> : <DirectionImg src={OutboundIcon} alt="Outbound" aria-label='outbound'></DirectionImg>;
                    return (
                        <MenuItem
                            key={transaction.id}
                            value={transaction.id}
                        >
                            <DivEllipsis>
                                {directionIcon}{`${transaction.name} - ${transaction.description}`}
                            </DivEllipsis>
                        </MenuItem>
                    );
                })
            );
        }
        return null;
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const standardVersionProps = {
        'aria-label': 'standard-version',
        'maxLength': 20,
    };
    const TransactionsMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
                maxWidth: '540px',
            },
        },

    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Map Definition"
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-map-description"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-map-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                        <InputLabel id="dialog-map-transactions-label">Transaction</InputLabel>
                        <Select
                            labelId="dialog-map-transactions-label"
                            aria-labelledby="dialog-map-transactions-label"
                            value={transactionId}
                            onChange={onTransactionChange}
                            disabled={submitted}
                            MenuProps={TransactionsMenuProps}
                            data-cy="dialog-map-transaction"
                        >
                            {getTransactionsDropList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Map File"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={maxMapUploadFiles}
                        dropInstructionText="Drop your map file here"
                        existingFiles={existingMapFile ? [existingMapFile] : undefined}
                        acceptedFileTypes={{
                            'text/xsl': ['.xsl', '.xslt'],
                        }}
                        onChange={onFileUploadChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-map-standard-version"
                        fullWidth
                        disabled={submitted}
                        value={standardVersion}
                        label="Standard Version"
                        inputProps={standardVersionProps}
                        onChange={onStandardVersionChange}
                        autoComplete="off"
                        data-cy="dialog-map-standard-version"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default MapDialog;