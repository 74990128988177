import { gqlClient } from "../../components/AppProvider";
import { GetPostProcessingBlobInput, PagingResultModelOfPostProcessingBlobModel, RequestResult, UpsertPostProcessingBlobInput } from "../../gql-types.generated";
import { mutationDeleteEntity } from "../../gql/MutationDeleteEntity";
import { mutationUpsertPostProcessingBlob } from "../../gql/MutationUpsertPostProcessingBlobs";
import { queryPostProcessingBlobs } from "../../gql/QueryPostProcessingBlobs";
import { AppDispatch, AppThunk } from "../../store";
import { getErrorMessage } from "../../util/Common";
import { captureDeletePostProcessingBlobStatus, captureUpsertPostProcessingBlobStatus, decrementPostProcessingblobsRequestsInFlight, fetchError, fetchPostProcessingBlobsSuccess, incrementPostProcessingblobsRequestsInFlight } from "./PostProcessingBlobsSlice";



export const fetchPostProcessingBlobs = (
    filters?: GetPostProcessingBlobInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementPostProcessingblobsRequestsInFlight());
    try {
        const postBlobPage = await queryPostProcessingBlobs(gqlClient, filters);
        if (postBlobPage) {
            dispatch(fetchPostProcessingBlobsSuccess(postBlobPage as PagingResultModelOfPostProcessingBlobModel));
        }
        dispatch(decrementPostProcessingblobsRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementPostProcessingblobsRequestsInFlight());
    }
}

export const upsertPostProcessingBlob = ( upsertPostProcessingData: UpsertPostProcessingBlobInput) : AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        dispatch(incrementPostProcessingblobsRequestsInFlight());
        const upsertStatus = await mutationUpsertPostProcessingBlob(
            gqlClient, {upsertPostProcessingData}
        );
        if (upsertStatus) {
            if (upsertStatus.result === RequestResult.Fail) {
                dispatch(fetchError({message:upsertStatus.message as string} as Error));
                getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
            }
            dispatch(captureUpsertPostProcessingBlobStatus(upsertStatus));
            dispatch(decrementPostProcessingblobsRequestsInFlight());
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementPostProcessingblobsRequestsInFlight());
    }
};

export const deletePostProcessingBlob = (id: string): AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        dispatch(incrementPostProcessingblobsRequestsInFlight());
        const deleteStatus = await mutationDeleteEntity(gqlClient, {
            id,
            entityName: "PostProcessingBlob",
        });
        if (deleteStatus) {
            if (deleteStatus.result === RequestResult.Fail) {
                getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
            }
            dispatch(captureDeletePostProcessingBlobStatus(deleteStatus));
            dispatch(decrementPostProcessingblobsRequestsInFlight());
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementPostProcessingblobsRequestsInFlight());
    }
}