import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionMapModel, DeleteByIdPayload, TransactionMapPartnerModel, UpsertTransactionMapPartnerPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    transactionMap?: TransactionMapModel;
    deleteMapStatus?: DeleteByIdPayload;
    transactionMapPartners?: TransactionMapPartnerModel[];
    upsertTransactionMapPartnerStatus?: UpsertTransactionMapPartnerPayload;
}

const initialState: SliceState = {
    error: undefined,
    transactionMap: undefined,
    deleteMapStatus: undefined,
    transactionMapPartners: undefined,
    upsertTransactionMapPartnerStatus: undefined
}

export const slice = createSlice({
    name: 'mapDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactionMap = undefined
            // eslint-disable-next-line no-param-reassign
            state.transactionMapPartners = undefined;
        },

        fetchTransactionMapByIdSuccess: (state, action: PayloadAction<TransactionMapModel | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactionMap = action.payload;
            /*
            let map = action.payload;
            if (map) {
                // set any specific properties needed fo tabbed content;
            }
            */
        },

        captureDeleteMapStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteMapStatus = action.payload;
        },

        fetchTransactionMapPartnersSuccess: (state, action: PayloadAction<TransactionMapPartnerModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactionMapPartners = action.payload;
        },

        captureUpsertTransactionMapPartnerStatus: (state, action: PayloadAction<UpsertTransactionMapPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertTransactionMapPartnerStatus = action.payload;
        }

    }
});

export const selectError = (state: RootState): Error | undefined => state.mapDetails.error;
export const selectMap = (state: RootState): TransactionMapModel | undefined => state.mapDetails.transactionMap;
export const selectDeleteMapStatus = (state: RootState): DeleteByIdPayload | undefined => state.mapDetails.deleteMapStatus;
export const selectMapPartners = (state: RootState): TransactionMapPartnerModel[] | undefined => state.mapDetails.transactionMapPartners;
export const selectUpsertTransactionMapPartnerStatus = (state: RootState): UpsertTransactionMapPartnerPayload | undefined => state.mapDetails.upsertTransactionMapPartnerStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchTransactionMapByIdSuccess,
    captureDeleteMapStatus,
    fetchTransactionMapPartnersSuccess,
    captureUpsertTransactionMapPartnerStatus
} = slice.actions;

export default slice.reducer;