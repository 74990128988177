import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Box, CircularProgress, Grid, styled } from '@mui/material';

import { CardListContentGrid, TabCardListBox, TabToolbar } from '../../util/SharedStyles';

import { TransactionMapPartnerModel, RequestResult } from '../../gql-types.generated';

import TradingPartnerListItem from '../listItems/TradingPartnerListItem';
import { viewerCanEdit } from '../../util/ViewerUtility';
import CreateNewButton from '../buttons/CreateNewButton';
import AddClientPartnerDialog from '../dialogs/AddClientPartnerDialog';
import SearchBar from '../SearchBar';
import NoResultsMessage from '../NoResultsMessage';
import NoRecordsMessage from '../NoRecordsMessage';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { upsertTransactionMapPartner } from '../../features/MapDetails/MapDetailsActions';
import { selectUpsertTransactionMapPartnerStatus, selectError, captureUpsertTransactionMapPartnerStatus } from '../../features/MapDetails/MapDetailsSlice';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';

const TPCardBox = styled(Box)((props) => ({
    width: '100%',
}));

const CardListGridLowerPadded = styled(CardListContentGrid)((props) => ({
    paddingBottom: '16px',
}));

const Loader = styled('div')((props) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
}));

interface TransactionMapPartnerListProps {
    mapId: string;
    viewer: Viewer | undefined;
    mapPartners: TransactionMapPartnerModel[] | undefined;
    refreshPartners: () => void;
}

const TransactionMapPartnerList: React.FC<TransactionMapPartnerListProps> =
    (props: TransactionMapPartnerListProps) => {
        const { mapId, viewer, mapPartners, refreshPartners } = props;

        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const [searchText, setSearchText] = useState('');
        const [openAdd, setOpenAdd] = useState(false);

        const addPartnerStatus = useAppSelector(selectUpsertTransactionMapPartnerStatus);
        const error = useAppSelector(selectError);

        const viewerRole = viewer?.role;
        const canEdit = viewerCanEdit(viewerRole);

        useEffect(() => {
            // save off add result for base component
            if (addPartnerStatus && addPartnerStatus.result) {
                if (addPartnerStatus.result === RequestResult.Success) {
                    // close the add dialog
                    setOpenAdd(false);

                    //Let Details Page know it has to run the Partners Query again to reflect changes
                    refreshPartners();

                    dispatch(setToastConfig({
                        message: addPartnerStatus.message as string,
                        severity: ToastSeverity.Success
                    }));

                    // remove add status
                    dispatch(captureUpsertTransactionMapPartnerStatus());
                }
            }
        }, [addPartnerStatus?.result]);

        const onAddParnterClick = () => {
            setOpenAdd(true);
        };

        const onAddPartnerDialogClose = () => {
            setOpenAdd(false);
        };

        const onAddPartnerDialogSave = (selectedPartnerId?: string | undefined) => {
            if (selectedPartnerId) {
                // save client partner on parent
                dispatch(upsertTransactionMapPartner(selectedPartnerId, mapId));
            }
        };

        const getExistingPartnerIds = () => {

            let partnerIds: string[] = [];
            mapPartners?.forEach(mapPartner => partnerIds.push(mapPartner.partner?.id));

            return partnerIds;
        };

        const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
        };

        const clearSearch = () => {
            setSearchText('');
        };

        const cardClickAction = (id: string | undefined) => {
            if (id) {
                let route = "/partner/" + id;
                navigate(route);
            }
        };

        const getContent = () => {
            if (mapPartners) {
                if (mapPartners.length > 0) {
                    const filteredPartners = mapPartners.filter(mp => mp.partner?.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
                    if (filteredPartners && filteredPartners.length) {
                        return (
                            filteredPartners.map((TransactionMapPartner: TransactionMapPartnerModel) => (
                                (TransactionMapPartner.partner &&
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={TransactionMapPartner.id}>
                                        <TradingPartnerListItem
                                            tradingPartner={TransactionMapPartner.partner}
                                            viewer={viewer}
                                            clickAction={cardClickAction}
                                        />
                                    </Grid>
                                )
                            ))
                        )
                    } else {
                        // Filtered too hard
                        // Display no results found image/message
                        let message = `No Partners found match '${searchText}'. Remove or modify the keyword search to show results.`;
                        return (<NoResultsMessage message={message} />);
                    }
                } else {
                    return (canEdit ?
                        <NoRecordsMessage actionButtonText="Add Partner" actionButtonClick={onAddParnterClick} />
                        :
                        <NoRecordsMessage message="" />
                    );
                }
            } else {
                return (
                    <Loader id='loader' >
                        <CircularProgress />
                    </Loader>
                );
            }
        }

        return (
            <TPCardBox>
                <TabToolbar justify="space-between">
                    <SearchBar
                        searchText={searchText}
                        onSearch={handleSearch}
                        onClearSearch={clearSearch}
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="Add Partner"
                            onClick={onAddParnterClick}
                            data-cy="add-transaction-map-partner"
                        />}
                </TabToolbar>
                <TabCardListBox>
                    <CardListGridLowerPadded container spacing={2}>
                        {getContent()}
                    </CardListGridLowerPadded>
                </TabCardListBox>
                <AddClientPartnerDialog
                    isOpen={openAdd}
                    onClose={onAddPartnerDialogClose}
                    onSave={onAddPartnerDialogSave}
                    existingPartnerIds={getExistingPartnerIds()}
                    error={error}
                />
            </TPCardBox>
        )
    }

export default TransactionMapPartnerList;