import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DateTime } from 'luxon';
import { RequestResult } from '../../gql-types.generated';
import { Button, Grid, Typography } from '@mui/material';
import BillingIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardAvatar, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, TabHeader,TabLabel, } from '../../util/SharedStyles';

import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { viewerCanEdit } from '../../util/ViewerUtility';

import { upsertBilling, deleteBilling, fetchBillingById, fetchBillingItems } from './BillingDetailsActions';
import { captureUpsertBillingStatus, captureDeleteBillingStatus, clearError, clearState, selectBilling, selectError, selectBillingItems, selectUpsertBillingStatus, selectDeleteBillingStatus } from './BillingDetailsSlice';
import TabPanel from '../../components/TabPanel';
import BillingItemsList from '../../components/lists/BillingItemsList';
import EditButton from '../../components/buttons/EditButton';
import BillingDialog from '../../components/dialogs/BillingDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import DetailsPage from '../../components/DetailsPage';

interface BillingDetailsProps {
    viewer: Viewer | undefined;
}

const BillingDetails: React.FC<BillingDetailsProps> = (props) => {
    // billingId will be passed in through router
    const { billingId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [billingName, setBillingName] = useState<string | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [tabValue, setTabValue] = useState(0);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [lastModifiedBy, setLastModifiedBy] = useState<string | undefined>(undefined);
    const [lastModifiedTime, setLastModifiedTime] = useState<string | undefined>(undefined);
    const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);

    const upsertBillingStatus = useAppSelector(selectUpsertBillingStatus);
    const deleteBillingStatus = useAppSelector(selectDeleteBillingStatus);
    const error = useAppSelector(selectError);
    const billingInfo = useAppSelector(selectBilling);
    const billingItems = useAppSelector(selectBillingItems);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        // use billingId to get billingDetails on render
        if (billingId) {
            dispatch(fetchBillingById(billingId));
            dispatch(fetchBillingItems(billingId));
        }
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh billing when a successful mutation occurs
        if (upsertBillingStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertBillingStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertBillingStatus());
            // close the modify dialog
            onBillingDialogClose();
            // we should always have the billingId if we upserted, but check to prevent compile error
            if (billingId) {
                dispatch(fetchBillingById(billingId));
            }
        }
    }, [upsertBillingStatus?.result]);

    useEffect(() => {
        if (deleteBillingStatus?.result === RequestResult.Success) {
            // close dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteBillingStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // send user back to where they came from
            navigate(-1);
        }
        else if (deleteBillingStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteBillingStatus.message as string);
        }
    }, [deleteBillingStatus?.result])

    useEffect(() => {
        if (billingInfo) {
            if (billingInfo.description) {
                setBillingName(billingInfo.description);
            }
            if (billingInfo.currencyCode) {
                setCurrencyCode(billingInfo.currencyCode);
            }
            if (billingInfo.lastModifiedByName) {
                setLastModifiedBy(billingInfo.lastModifiedByName);
            }
            if (billingInfo.lastModifiedTime) {
                let formattedDate = getFormattedDateTimeString(billingInfo.lastModifiedTime, { format: DateTime.DATETIME_FULL });
                setLastModifiedTime(formattedDate);
            }
        }
    }, [billingInfo]);

    useTitle(billingName);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        //setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onBillingDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error and BillingContact on close.
        handleClearError();
    };

    const refreshBillingItems = async () => {
        if (billingId) {
            await dispatch(fetchBillingItems(billingId as string));
        }
    };

    const onBillingDialogSave = (
        currencyCode: string,
        description?: string,
        id?: string,
    ) => {
        dispatch(
            upsertBilling(
                currencyCode,
                description,
                id,
            )
        );
    };

    const onExpandClick = () => {
        setDetailsExpanded(!detailsExpanded);
    };

    let detailsAvatar = (
        <CardAvatar aria-label="billing" alt="billing" >
            {<BillingIcon />}
        </CardAvatar>
    );

    const getBillingDetails = () => {
        if (billingInfo) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Last Updated</Typography>
                            <Typography variant='body1' >{lastModifiedTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Updated By</Typography>
                            <Typography variant='body1' >{lastModifiedBy}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    let countBoxElement;
    if (billingItems && billingItems.length > 0) {
        countBoxElement = <CountBox>{billingItems.length}</CountBox>
    }

    let billingItemLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Price Ranges</TabLabel>
            {countBoxElement}
        </Grid>
    );

    const getTabbedContent = () => {
        if (billingInfo) {
            return (
                <DetailsTabContainer>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                            <TabHeader label={billingItemLabel} id='vertical-tab-0' />
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>
                    <DetailsTabPanelContainer>
                        <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                            {
                                <BillingItemsList
                                    viewerRole={viewer?.role}
                                    billingId={billingInfo.id}
                                    billingCurrencyCode={currencyCode}
                                    billingItems={billingItems}
                                    refreshBillingItems={refreshBillingItems}
                                />
                            }
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </DetailsTabContainer>
            );
        }
    }

    //edit current billing
    const onEditClick = () => {
        setOpenModify(true);
    }

    const onDeleteBilling = () => {
        dispatch(captureDeleteBillingStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeleteBillingConfirm = () => {
        if (billingId) {
            dispatch(deleteBilling(billingId));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(clearError());
        dispatch(captureDeleteBillingStatus());
        setDeleteErrorMessage('');
    };

    const canEdit = viewerCanEdit(viewer);
    let canDeleteBilling = false;
    // only allow deletion of billing if no clientCount 
    if (canEdit && billingInfo) {
        let clientCount = billingInfo.clientCount ?? 0;
        if (clientCount <= 0) {
            canDeleteBilling = true;
        }
    }

    const getDialogs = () => [
        <BillingDialog
            isOpen={openModify}
            billing={billingInfo}
            onClose={onBillingDialogClose}
            onSave={onBillingDialogSave}
            error={error}
            key="billing-dialog"
        />,
        <DeleteDialog
            isOpen={openDelete}
            heading={'Delete Billing'}
            message={`Are you sure you want to delete '${billingName}'?`}
            id={billingInfo?.id as string}
            onConfirm={handleDeleteBillingConfirm}
            onReject={onDeleteDialogClose}
            errorMessage={deleteErrorMessage}
            key="delete-dialog"
        />
    ];

    useEffect(() => {
        setDetailsRecordLoaded(!!billingInfo);
        if (billingInfo) {
            setDetailsPageTitle(billingName);
            setDetailsAV(<CardAvatar aria-label="billing" alt="billing" >
                {<BillingIcon />}
            </CardAvatar>);
            setDetailsHeader(billingName);
            setDetailsSubHeader(currencyCode);
            setDetailsToolbarButtons([<Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                disabled={!canDeleteBilling}
                onClick={onDeleteBilling}
                key="delete-button"
            >
                Delete
            </Button>]);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Billing button"
                    data-cy="edit-billing-button"
                ></EditButton>);
            }
        }
    }, [billingInfo, billingName, currencyCode, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getBillingDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
};

export default BillingDetails;