import { Button, styled } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

interface ButtonProps {
    onClick: () => void;
    text?: string | undefined;
    title?: string | undefined;
    ariaLabel?: string | undefined;
    disabled?: boolean; 
}

const StyledButton = styled(Button)((props) => ({
    padding: '0 !important',
}));

const EditButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { onClick, text = 'Edit', title, ariaLabel = 'Edit Button', disabled = false } = props;

    return (
        <StyledButton
            color="primary"
            startIcon={<EditIcon/>}
            onClick={onClick}
            aria-label={ariaLabel}
            title={title}
            disabled={disabled}
        >
            {text}</StyledButton>
    );
};

export default EditButton;