import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { GridColumns, GridRowModel, GridRowParams, GridRenderCellParams, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Tooltip, Typography } from '@mui/material';
import { IconAvatar } from '../../../util/SharedStyles';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import OutboundIcon from '../../../icons/outbound.svg';
import InboundIcon from '../../../icons/inbound.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import { PartnerModel, PartnerTransactionModel, TransactionModel, UserRole, RequestResult, DeleteByIdPayload, TransactionDirection, PipelineModel, PartnerTransactionSchemaModel } from '../../../gql-types.generated';
import { selectError, clearError, captureAddTradingPartnerTransactionsStatus, captureDeleteTradingPartnerTransactionStatus, selectAddTradingPartnerTransactionsStatus, selectDeleteTradingPartnerTransactionStatus, selectPartnerTransactions, selectEligibleTransactions, captureUpdatePartnerTransactionStatus, selectUpdatePartnerTransactionStatus, selectPartnerTransactionSchemasByPartnerTransactionId } from '../../../features/TradingPartnerDetails/TradingPartnerDetailsSlice';
import { deleteTradingPartnerTransaction, addTradingPartnerTransactions, fetchEligibleTransactions, updatePartnerTransaction } from '../../../features/TradingPartnerDetails/TradingPartnerDetailsActions';
import { viewerCanEdit } from '../../../util/ViewerUtility';
import TransactionList from './TransactionList';
import EditPartnerTransactionDialog from '../../dialogs/EditPartnerTransactionDialog';

interface PartnerTransactionListProps {
    viewerRole: UserRole | undefined;
    partner: PartnerModel;
    refreshPartnerData: () => void;
}

const PartnerTransactionList: React.FC<PartnerTransactionListProps> = (props: PartnerTransactionListProps) => {
    const { viewerRole, partner, refreshPartnerData } = props;
    const { id: partnerId, ediStandards } = partner;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewerRole);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [currentTransactionRows, setCurrentTransactionRows] = useState<GridRowModel[]>([]);
    const [availableTransactionRows, setAvailableTransactionRows] = useState<GridRowModel[]>([]);
    const [isLoadingAvailableTransactions, setIsLoadingAvailableTransactions] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState<DeleteByIdPayload | undefined>()
    const [addResult, setAddResult] = useState<RequestResult | undefined>()
    const [selectedPartnerTransaction, setSelectedPartnerTransaction] = useState<PartnerTransactionModel>();
    
    const deletePartnerTransactionStatus = useAppSelector(selectDeleteTradingPartnerTransactionStatus);
    const addPartnerTransactionsStatus = useAppSelector(selectAddTradingPartnerTransactionsStatus);
    const updateTransactionStatus = useAppSelector(selectUpdatePartnerTransactionStatus);
    const error = useAppSelector(selectError);
    const partnerTransactions = useAppSelector(selectPartnerTransactions);
    const transactionOptions = useAppSelector(selectEligibleTransactions);

    // because schemas save separately on the edit dialog, need to use
    // a selector to have the notesList on the dialog properly refresh with the updates
    const selectedPartnerTransactionSchemas = useAppSelector(selectPartnerTransactionSchemasByPartnerTransactionId(selectedPartnerTransaction?.id));

    useEffect(() => {
        setCurrentTransactionRows(getCurrentTransactionRows());
    }, [partnerTransactions]);

    useEffect(() => {
        if (ediStandards && ediStandards.length > 0) {
            let standardIds: string[] = [];
            ediStandards.forEach(s => s?.id && standardIds.push(s.id));
            setIsLoadingAvailableTransactions(true);
            dispatch(fetchEligibleTransactions(standardIds));
        }
    }, [ediStandards]);

    useEffect(() => {
        if (transactionOptions) {
            setAvailableTransactionRows(getAvailableTransactionRows());
        }
    }, [transactionOptions]);

    useEffect(() => {
        // save off add result for base component
        if (addPartnerTransactionsStatus && addPartnerTransactionsStatus.result) {
            // set the result to be passed to base component
            setAddResult(addPartnerTransactionsStatus.result);

            if (addPartnerTransactionsStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: addPartnerTransactionsStatus.message as string,
                    severity: ToastSeverity.Success
                }));

                // wrap status removal in a timeout
                // otherwise it gets removed before base component receives the result
                // which causes the add dialog to stay open
                setTimeout(() => {
                    // remove add status
                    dispatch(captureAddTradingPartnerTransactionsStatus());
                });
            }
        }
        else {
            setAddResult(undefined);
        }

        if (deletePartnerTransactionStatus && deletePartnerTransactionStatus.result) {
            // set the status to be passed to base component
            setDeleteStatus(deletePartnerTransactionStatus);
        }
        else {
            setDeleteStatus(undefined);
        }
    }, [deletePartnerTransactionStatus?.result, , addPartnerTransactionsStatus?.result]);

    const deleteTransactionHandler = useCallback((rowId: string) => () => {
        onDeleteTransaction(rowId);
        //setDeleteErrorMessage('');
        setOpenDelete(true);
    }, [partnerTransactions]);

    const editTransactionHandler = useCallback((rowId: string) => () => {
        onEditTransaction(rowId);
        setOpenEdit(true);
    }, [partnerTransactions]);

    const onEditTransaction = (selectedRowId: string) => {
        if (selectedRowId && partnerTransactions?.length) {
            let partnerTransaction = partnerTransactions.find(t => t.id === selectedRowId);
            if (partnerTransaction) {
                setSelectedPartnerTransaction(partnerTransaction);
            }
        }
    };

    const onEditTransactionClose = () => {
        // unset selected transaction
        setSelectedPartnerTransaction(undefined);
        // close the dialog
        setOpenEdit(false);
    }

    const onEditTransactionSave = (
        partnerTransactionId: string,
        pipelineId?: string
    ) => {
        if (partnerTransactionId) {
            dispatch(updatePartnerTransaction(partnerTransactionId, pipelineId));
        }
    }

    useEffect(() => {
        if (updateTransactionStatus && updateTransactionStatus.result) {
            if (updateTransactionStatus.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: updateTransactionStatus.message as string,
                    severity: ToastSeverity.Success
                }));
                dispatch(captureUpdatePartnerTransactionStatus());
                onEditTransactionClose();
            }
            refreshPartnerData();
        }
    }, [updateTransactionStatus?.result]);


    const isExistingTransaction = (transactionId: string) => {
        // check if this transaction is already a partner transaction
        if (partnerTransactions && transactionId) {
            let matchFound = partnerTransactions.find((pt: PartnerTransactionModel) => pt.transaction?.id === transactionId);
            return matchFound ? true : false;
        }
        return false;
    };

    const getAvailableTransactionRows = () => {

        let rows = transactionOptions
            ? (transactionOptions?.map((transaction: TransactionModel) => {
                const transactionNode = transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { id, name, description, direction } = transactionNode;
                const existsOnPartner = isExistingTransaction(id);

                return {
                    _raw: transactionNode,
                    id: id,
                    direction,
                    type: name,
                    description,
                    isSelectable: !existsOnPartner,
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
        setIsLoadingAvailableTransactions(false);
        return rows;
    };

    // get the transactions from within transactions 
    // and put them into DataGrid rowModel for rendering
    const getCurrentTransactionRows = () => {
        return partnerTransactions
            ? (partnerTransactions?.map((partnerTransaction: PartnerTransactionModel) => {
                const transactionNode = partnerTransaction?.transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { name, direction, description } = transactionNode;
                const hasClientTransactions = partnerTransaction?.clientPartnerTransactions && partnerTransaction?.clientPartnerTransactions.length > 0;
                const hasSchema = partnerTransaction?.partnerTransactionSchemas && partnerTransaction?.partnerTransactionSchemas.length > 0;
                const pipeline = partnerTransaction.pipeline;
                let pipelineDescription = pipeline?.description;

                return {
                    _raw: transactionNode,
                    id: partnerTransaction?.id, // want the parent id for deleting
                    direction,
                    type: name,
                    description,
                    disableDelete: hasClientTransactions,
                    pipelineDescription: pipelineDescription,
                    hasSchema
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
    };

    // using DataGrid, define each column
    const transactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'direction',
                headerName: 'DIRECTION',
                minWidth: 120,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                align: 'left',
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const directionIcon = (value === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;

                    return (
                        <Tooltip title={value}>
                            <IconAvatar aria-label={value?.toLowerCase()}>
                                {directionIcon}
                            </IconAvatar>
                        </Tooltip>
                    );
                },
            },
            {
                field: 'type',
                headerName: 'NAME',
                minWidth: 100,
                sortable: true,
            },
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                cellClassName: "ediDataGridCellOverflowHidden",
                minWidth: 200,
                flex: 2,
                sortable: true,
            },
            {
                field: 'pipelineDescription',
                headerName: 'PIPELINE',
                minWidth: 200,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let displayValue = value ? value : "Pipeline Not Set";
                    return (<div className='ediDataGridCellOverflowHidden'>{displayValue}</div>);
                }
            },
            {
                field: 'hasSchema',
                headerName: 'HAS SCHEMA',
                width: 120,
                align: 'left',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <div style={{ width: '80px' }}><CheckCircleIcon color="success" /></div>
                    }
                    return (<div></div>);
                }
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteTransactionHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteTransactionHandler, editTransactionHandler, canEdit],
    );


    // use the selectedRowId to find the parent partnerTransaction and set it as selected
    const onDeleteTransaction = (selectedRowId: string) => {
        if (selectedRowId && partnerTransactions?.length) {
            let partnerTransaction = partnerTransactions.find(t => t.id === selectedRowId);
            if (partnerTransaction) {
                setSelectedPartnerTransaction(partnerTransaction);
                dispatch(captureDeleteTradingPartnerTransactionStatus());
            }
        }
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected Trading partner transaction
        if (selectedPartnerTransaction?.id) {
            dispatch(deleteTradingPartnerTransaction(selectedPartnerTransaction?.id as string));
        }
    };

    const onDeleteDialogClose = () => {
        // clear the delete status
        dispatch(captureDeleteTradingPartnerTransactionStatus());
        // unset selected transaction
        setSelectedPartnerTransaction(undefined);
        // close the dialog
        setOpenDelete(false);
    };

    const onAddDialogSave = (selectedTransactionIds?: string[]) => {
        if (selectedTransactionIds && selectedTransactionIds.length > 0) {
            // dispatch save of clientPartner with array of Ids
            dispatch(addTradingPartnerTransactions(
                partnerId,
                selectedTransactionIds
            ));
        }
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <>
            <TransactionList
                viewerRole={viewerRole}
                parentEntityTypeName="Trading Partner"
                transactionGridColumns={transactionColumns}
                currentTransactionRows={currentTransactionRows}
                availableTransactionRows={availableTransactionRows}
                loadingAvailableRows={isLoadingAvailableTransactions}
                deleteStatus={deleteStatus}
                addResult={addResult}
                onDeleteDialogConfirm={onDeleteDialogConfirm}
                onDeleteDialogClose={onDeleteDialogClose}
                onAddDialogSave={onAddDialogSave}
                openDeleteDialog={openDelete}
                refreshList={refreshPartnerData}
                clearError={handleClearError}
                error={error}
            />
            <EditPartnerTransactionDialog
                isOpen={openEdit}
                partnerTransactionId={selectedPartnerTransaction?.id as string}
                transactionDirection={selectedPartnerTransaction?.transaction?.direction as TransactionDirection}
                transactionSchemas={selectedPartnerTransactionSchemas}
                pipeline={selectedPartnerTransaction?.pipeline as PipelineModel}
                onClose={onEditTransactionClose}
                onSave={onEditTransactionSave}
                refreshPartnerData={refreshPartnerData}
                error={error}
            />
        </>
    );

}

export default PartnerTransactionList;