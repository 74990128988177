import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { GridRowModel } from '@mui/x-data-grid-pro';
import { selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';
import { selectFormatterTransactions } from '../../features/NotificationFormatters/NotificationFormattersSlice';
import { fetchNotificationFormatterTransactions } from '../../features/NotificationFormatters/NotificationFormattersActions';
import { calculateAddTransactionDialogMinWidth } from '../../util/Common';
import { NotificationFormatterTransactionModel, TransactionModel, AddNotificationFormatterTransactionsInput } from '../../gql-types.generated';
import AddTransactionDialog from './AddTransactionDialog';

interface FormatterTransactionPickerProps {
    isOpen: boolean;
    formatterId: string;
    onClose: () => void;
    onSave: (
        inputData: AddNotificationFormatterTransactionsInput
    ) => void;
    error?: Error | undefined;
}

const NotificationFormatterTransactionPickerDialog: React.FC<FormatterTransactionPickerProps> = (props) => {
    const { isOpen, formatterId, error, onClose, onSave } = props;
    const dispatch = useAppDispatch();
    const [availableTransactionRows, setAvailableTransactionRows] = useState<GridRowModel[]>([]);
    const [addDialogMinWidth, setAddDialogMinWidth] = useState<string>();

    const formatterTransactions = useAppSelector(selectFormatterTransactions);
    const transactionOptions = useAppSelector(selectTransactionList);
    
    useEffect(() => {
        if (formatterId && isOpen) {
            dispatch(fetchNotificationFormatterTransactions(formatterId));
        }
    }, [formatterId, isOpen]);
    
    useEffect(() => {
        if (transactionOptions) {
            setAvailableTransactionRows(getAvailableTransactionRows());
        }
    }, [transactionOptions, formatterTransactions]);

    useEffect(() => {
        if (availableTransactionRows && availableTransactionRows.length > 0) {
            let minWidth = calculateAddTransactionDialogMinWidth(availableTransactionRows as GridRowModel[]);
            if (minWidth > 0) {
                setAddDialogMinWidth(`${minWidth}px`);
            }
        }
    }, [availableTransactionRows]);
    
    const getAvailableTransactionRows = () => {

        let rows = transactionOptions
            ? (transactionOptions?.map((transaction: TransactionModel) => {
                const transactionNode = transaction;
                if (!transactionNode) {
                    return {} as GridRowModel;
                }
                const { id, name, description, direction } = transactionNode;
                const existsOnNotificationFormatter = isExistingTransaction(id);

                return {
                    _raw: transactionNode,
                    id: id,
                    direction,
                    type: name,
                    description,
                    isSelectable: !existsOnNotificationFormatter,
                } as GridRowModel;
            }) as GridRowModel[])
            : [];
        return rows;
    };

    const isExistingTransaction = (transactionId: string) => {
        // check if this transaction is already a formatter transaction
        if (formatterTransactions && transactionId) {
            let matchFound = formatterTransactions.find((ft: NotificationFormatterTransactionModel) => ft.transaction?.id === transactionId);
            return matchFound ? true : false;
        }
        return false;
    }

    const onAddDialogSave = (transactionIds?: string[]) => {
        onSave({notificationFormatterId: formatterId, transactionIds});
    }

    return (
        <AddTransactionDialog
            isOpen={isOpen}
            transactionRows={availableTransactionRows}
            minWidth={addDialogMinWidth}
            hideMultiselectHeaderCheckbox
            onClose={onClose}
            onSave={onAddDialogSave}
            error={error}
        />
    );

}

export default NotificationFormatterTransactionPickerDialog;