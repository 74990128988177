import { ConfigurationModel } from "../../gql-types.generated";
import { GridActionsCellItem, GridColumns, GridRowModel, GridOverlay, GridRowParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { CircularProgress, Tooltip, Typography, Stack, styled } from '@mui/material';
import { TabDataGrid } from '../../util/SharedStyles';
import { useCallback, useEffect, useState, useMemo } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ProcessDialog from "../dialogs/ProcessDialog";

const DialogDataGrid = styled(TabDataGrid)((props) => ({
    padding: '8px 0px !important',
    width: '100%',
    height: '400px',
}));

interface ProcessPickerListProps {
    processes: ConfigurationModel[] | undefined;
    selectedProcessId?: string;
    loading: boolean;
    rowSelection: (
        processId: string
    ) => void;
    error?: Error | undefined;
}

const ProcessPickerList: React.FC<ProcessPickerListProps> = props => {
    const { processes, selectedProcessId, loading, rowSelection, error } = props;

    const [viewIsOpen, setViewIsOpen] = useState(false);
    const [viewConfig, setViewConfig] = useState<ConfigurationModel | undefined>(undefined);
    const [processRows, setProcessRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'ediProcess',
            sort: 'asc',
        },
    ]);

    const getProcessRows = () => {
        if (processes && processes.length > 0) {
            return processes?.map((proc) => {
                const { ediProcess, id } = proc;
                return {
                    _raw: proc,
                    ediProcess,
                    id,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    useEffect(() => {
        setProcessRows(getProcessRows());
    }, [processes]);

    const viewProcessHandler = useCallback((processId: string) => () => {
        if (processId && processes) {
            let process = processes.find(p => p.id === processId);
            if (process) {
                setViewConfig(process as ConfigurationModel);
                setViewIsOpen(true);
            }
        }
    }, [processes]);

    const onViewClose = () => {
        setViewIsOpen(false);
        setViewConfig(undefined);
    };

    const onViewSave = (
        destPath: string,
        ediProcess: string,
        executionMode: string,
        extension: string,
        tpgClient: string,
        watcherPath: string,
        description?: string,
        id?: string,
        p1?: string,
        p2?: string,
        p3?: string,
        p4?: string,
        p5?: string,
        p6?: string) => {
        // save function needed but should only ever show in view mode.
    };

    const processColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'ediProcess',
                headerName: 'EDI PROCESS',
                minWidth: 300,
                flex: 1,
                sortable: true
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                minWidth: 40,
                headerAlign: 'center',
                align: 'right',
                cellClassName: "ediDataGridCellLastChild",
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="View Process"><VisibilityIcon /></Tooltip>}
                        label="View Process"
                        color="primary"
                        onClick={viewProcessHandler(params.row.id)}
                    />,
                ],
            }
        ], [viewProcessHandler],
    );

    useEffect(() => {
        if (selectedProcessId) {
            if (processRows && processRows.length > 0 && selectedProcessId) {
                setSelectionModel([selectedProcessId]);
            }
        }
    }, [processRows, selectedProcessId]);

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
        //setSelectedRowId(rowId);

        if (rowId) {
            setSelectionModel(currentSelectionModel);
            rowSelection(rowId ?? '');
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'process-picker-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && processes?.length === 0 && (
                    <Typography variant="body2" align="center">
                        No Records found.
                    </Typography>
                )}
            </GridOverlay>
        );
    };

    return (
        <>
            <DialogDataGrid
                loading={processes === undefined || processes.length <= 0}
                headerHeight={38}
                rowHeight={52}
                aria-label="Process List"
                hideFooter
                disableColumnMenu
                disableMultipleSelection
                rows={processRows ?? []}
                columns={processColumns}
                sortingOrder={['asc', 'desc']}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionModelChange}
                components={{
                    // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                    LoadingOverlay: loadingOverlay,
                    // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                    NoRowsOverlay: noRowsOverlay,
                }}
            />
            <ProcessDialog
                isOpen={viewIsOpen}
                process={viewConfig}
                isReadOnly={true}
                onClose={onViewClose}
                onSave={onViewSave}
            />
        </>
    );
}

export default ProcessPickerList;