import { Dialog } from "@mui/material";
import { useEffect, useState } from 'react';
import ConfirmationPrompt from "../ConfirmationPrompt";

interface DeleteDialogProps {
    isOpen: boolean;
    id: string;
    heading: string;
    message: string;
    onConfirm: (id: string) => void;
    onReject: () => void;
    confirmButtonText?: string | undefined;
    errorMessage?: string | undefined;
}

const DeleteDialog: React.FC<DeleteDialogProps> = props => {
    const { isOpen, id, heading = 'Delete', message, onConfirm, onReject, errorMessage, confirmButtonText = 'Delete' } = props;
    const [isDeleteBusy, setIsDeleteBusy] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setIsDeleteBusy(false);
            return;
        }
    }, [errorMessage]);

    useEffect(() => {
        // set submitted to false when dialog is closed
        if (!isOpen) {
            setIsDeleteBusy(false);
        }
    }, [isOpen]);

    const handleDeleteAreaConfirm = () => {
        setIsDeleteBusy(true);
        onConfirm(id);
    }
    const handleDeleteAreaReject = () => {
        setIsDeleteBusy(false);
        onReject();
    }

    return (
        <Dialog
            open={isOpen}>
            <ConfirmationPrompt
                heading={heading}
                message={message}
                confirmButtonText={confirmButtonText}
                rejectButtonText={'Cancel'}
                confirmButtonColor={'error'}
                rejectButtonColor={'primary'}
                handleConfirm={handleDeleteAreaConfirm}
                handleReject={handleDeleteAreaReject}
                isBusy={isDeleteBusy}
                errorMessage={errorMessage}
            />
        </Dialog>
    );
};

export default DeleteDialog;