import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientNotificationRecipientsInput, UpsertClientNotificationRecipientsPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_NOTIFICATION_RECIPIENTS = gql`
    mutation upsertClientNotificationRecipients($input: UpsertClientNotificationRecipientsInput!){
        upsertClientNotificationRecipients(input: $input){
            message
            errors {
                code
                message
            }
            result
            clientNotificationRecipients {
                id
            }
        }
    }
`;

export const mutationUpsertClientNotificationRecipients = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        input: UpsertClientNotificationRecipientsInput
    }
): Promise<UpsertClientNotificationRecipientsPayload | undefined> => {
    const {
        clientId,
        description,
        id,
        notificationType,
        receivesErrors,
        receivesNotifications,
        recipients
    } = args.input;

    const { data, errors } = await client.mutate<{ upsertClientNotificationRecipients: UpsertClientNotificationRecipientsPayload }>({
        variables: {
            input: {
                clientId,
                description,
                id,
                notificationType,
                receivesErrors,
                receivesNotifications,
                recipients
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_NOTIFICATION_RECIPIENTS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the ClientNotificationRecipients save.');
    }
    if (data && data.upsertClientNotificationRecipients) {
        return data.upsertClientNotificationRecipients;
    }
    return undefined;
};