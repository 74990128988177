import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Grid, Tooltip } from "@mui/material";
import { ErpTransactionSchemaModel, RequestResult, UpsertErpTransactionSchemaInput, TransactionDirection, TransactionModel } from "../../gql-types.generated";
import { useTitle } from "../../util/Common";
import { ToastSeverity, Viewer } from "../../util/Constants";
import { viewerCanAddDelete, viewerCanEdit } from "../../util/ViewerUtility";
import { selectTransactionList, setToastConfig } from "../EDIContainer/EDIContainerSlice";
import { deleteErpTransactionSchema, fetchSchemaList, upsertErpTransactionSchema } from "./ErpTransactionSchemasActions";
import ErrorMessage from '../../components/ErrorMessage';
import { captureDeleteSchemaStatus, captureUpsertSchemaStatus, clearError, clearState, selectDeleteSchemaStatus, selectError, selectRequestsInFlight, selectSchemaList, selectUpsertSchemaStatus } from "./ErpTransactionSchemasSlice";
import { MainContentBox, MainDataGridNoRowHover, DataGridListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import PageTitleBar from '../../components/PageTitleBar';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import { GridActionsCellItem, GridColumns, GridRenderCellParams, GridRowModel, GridOverlay, GridSortModel, GridRowParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { downloadDocument } from "../../util/Common";
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDialog from "../../components/dialogs/DeleteDialog";
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import ErpTransactionSchemaDialog from "../../components/dialogs/ErpTransactionSchemaDialog";

interface ERPTransactionSchemasProps {
    viewer: Viewer | undefined;
}

const ERPTransactionSchemas: React.FC<ERPTransactionSchemasProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [schemaRows, setSchemaRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectedSchema, setSelectedSchema] = useState<ErpTransactionSchemaModel | undefined>(undefined);
    const [schemaIsReadMode, setSchemaIsReadMode] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);

    const schemaList = useAppSelector(selectSchemaList);
    const upsertSchemaStatus = useAppSelector(selectUpsertSchemaStatus);
    const deleteSchemaStatus = useAppSelector(selectDeleteSchemaStatus);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const error = useAppSelector(selectError);
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    const transactions = useAppSelector(selectTransactionList);

    useTitle("ERP Transaction Schemas");

    useEffect(() => {
        dispatch(fetchSchemaList());
        return () => {
            dispatch(clearState());
        }
    }, []);

    useEffect(() => {
        const listHeaderHeight = document.getElementById('erpschemas-title-comp')?.clientHeight || 0;
        if (listHeaderHeight) {
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
        }
    }, [schemaList]);

    useEffect(() => {
        setSchemaRows(getSchemaRows());
        if (!schemaList && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [schemaList, requestsInFlight]);

    useEffect(() => {
        if (deleteSchemaStatus?.result === RequestResult.Success) {
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteSchemaStatus.message as string,
                severity: ToastSeverity.Success
            }));
        } else if (deleteSchemaStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteSchemaStatus.message as string);
        }
    }, [deleteSchemaStatus?.result])

    useEffect(() => {
        if (upsertSchemaStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertSchemaStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertSchemaStatus());
            onSchemaDialogClose();
        }
    }, [upsertSchemaStatus?.result])

    const onAddSchemaClick = () => {
        dispatch(clearError());
        if (selectedSchema) {
            setSelectedSchema(undefined);
        }
        setSchemaIsReadMode(false);
        setOpenModify(true);
    }

    const onSchemaDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteSchemaStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedSchema(undefined);
        setSchemaIsReadMode(true);
        dispatch(fetchSchemaList());
    };

    const onSchemaDialogSave = (schemaDetails: UpsertErpTransactionSchemaInput) => {
        dispatch(upsertErpTransactionSchema(schemaDetails));
    }

    const onDeleteDialogConfirm = (id: string) => {
        dispatch(deleteErpTransactionSchema(id));
    }

    const getSelectedRowSchema = useCallback((rowId: string) => () => {
        if (rowId && schemaList?.length) {
            let schema = schemaList.find(schema => schema.id === rowId);
            return schema;
        }
        return undefined;
    }, [schemaList]);


    const editSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            setSelectedSchema(schema);
            setSchemaIsReadMode(false);
            setOpenModify(true);
        }
    }, [getSelectedRowSchema]);

    const deleteSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            setSelectedSchema(schema);
            setOpenDelete(true);
        }
    }, [getSelectedRowSchema]);

    const onDownloadSchema = (schemaFileString: string, schemaFileName: string) => {
        downloadDocument(schemaFileString, schemaFileName ?? "Schema-Document", "text/xml", true);
    };

    const downloadSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            // because coming from callback, need to treat return value as function
            let document = schema()?.documentString as string;
            let fileName = schema()?.sourceFileName as string;
            if (document !== undefined && document !== null) {
                onDownloadSchema(document, fileName);
            }
        }
    }, [getSelectedRowSchema]);

    const getSchemaRows = () => {
        if (schemaList && schemaList.length > 0) {
            return schemaList.map((schema) => {
                const { description, documentString, id, transactionId } = schema;
                let hasDoc = documentString && documentString.length;

                return {
                    _raw: schema,
                    id,
                    description,
                    transactionId,
                    hasDocument: hasDoc,
                }

            }) as GridRowModel[];
        }
    };

    const getTransactionById = (id: string) => {
        if (transactions) {
            let transaction = transactions.find(t => t.id === id);
            if (transaction) {
                return transaction;
            }
        }
    }


    const schemaColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'DESCRIPTION',
                field: 'description',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild ediDataGridWrapCellContent-alwaysLeft"
            },
            {
                field: 'transactionId',
                headerName: 'TRANSACTION',
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        let transaction = getTransactionById(value);
                        if (transaction) {
                            let displayString = transaction.name + ' - ' + transaction.description;
                            let directionIcon;
                            if (transaction.direction) {
                                directionIcon = (transaction.direction === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;
                            }
                            return (
                                <Grid container item direction="row" gap={'8px'} alignItems="center">
                                    {displayString}
                                    {directionIcon}
                                </Grid>
                            );
                        }
                    }
                    return (<div></div>);
                }
            },
            {
                field: 'hasDocument',
                headerName: 'SCHEMA',
                minWidth: 200,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const hasDoc = value;

                    return (
                        <GridActionsCellItem
                            label="Download"
                            color="primary"
                            disabled={!hasDoc}
                            onClick={downloadSchemaHandler(params.row.id)}
                            icon={<Tooltip title="Download"><DownloadIcon /></Tooltip>}
                        />
                    );
                }
            },
            {
                field: 'actions',
                type: 'actions',
                sortable: false,
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit Schema"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editSchemaHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete Schema"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteSchemaHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            },
        ], [canEdit, canAddOrDelete, transactions, editSchemaHandler, deleteSchemaHandler, downloadSchemaHandler]
    )

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the ERP Schemas' error={error}></ErrorMessage>
                )}
                {!error && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the ERP Schemas' error={error}></ErrorMessage>
                )}
                {!error && (schemaList && schemaList.length <= 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    return (
        <MainContentBox>
            <PageTitleBar text='ERP Transaction Schemas' id="erpschemas-title-comp">
                <PageTitleToolbarGrid item>
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Schema"
                            onClick={onAddSchemaClick}
                            data-cy="add-new-map"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading || schemaList === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="ERP Transaction Schema List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={schemaRows ?? []}
                    columns={schemaColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            <ErpTransactionSchemaDialog
                isOpen={openModify}
                schema={selectedSchema}
                onClose={onSchemaDialogClose}
                onSave={onSchemaDialogSave}
                error={error}
                viewerRole={viewer?.role}
                isReadOnly={schemaIsReadMode}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedSchema?.id ?? ''}
                heading={'Delete Schema'}
                message={'Are you sure you want to delete \'' + selectedSchema?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    )
}

export default ERPTransactionSchemas;