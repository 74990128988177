
interface timeOffsets {
    now: number,
    minuteOffset: number,
    xminutes: number,
    oneHour: number,
    xHours: number,
    oneDay: number,
    xDays: number,
    oneMonth: number,
    xMonths: number,
    oneYear: number,
};

const offsets: timeOffsets = {
    now: 45,
    minuteOffset: 120,
    xminutes: 50,
    oneHour: 80,
    xHours: 24,
    oneDay: 48,
    xDays: 30,
    oneMonth: 60,
    xMonths: 365,
    oneYear: 2,
};

/*
    lastViewed: string or Date - time to compare
    now: Date - time to compare against
    returns - string for display or empty string if missing param 
*/
export const getTimeSinceString = (lastViewed: string | Date, now: Date) => {
    if (!lastViewed || !now) {
        return '';
    }
    const lvDate = new Date(lastViewed);
    const deltaTime = now.getTime() - lvDate.getTime();
    const deltaInDays = deltaTime / (1000 * 3600 * 24) | 0; // to seconds / to hours (60*60 to minutes to hours) / to days
    if (deltaInDays > 30) {
        //months
        const approximateMonths = deltaInDays / 30 | 0;
        if (approximateMonths === 1) {
            return (approximateMonths).toString() + ' month ago';
        }
        return (approximateMonths).toString() + ' months ago';
    } else if (deltaInDays < 1) {
        //minutes
        const deltaInMins = deltaTime / (1000 * 60) | 0;
        if (deltaInMins < 2) {
            return 'just now';
        } else if (deltaInMins > 60) {
            const deltaInHours = deltaInMins / 60 | 0;
            if (deltaInHours === 1) {
                return (deltaInHours).toString() + ' hour ago';
            }
            return (deltaInHours).toString() + ' hours ago';
        }
        if (deltaInMins === 1) {
            return (deltaInMins).toString() + ' minute ago';
        }
        return (deltaInMins).toString() + ' minutes ago';
    }
    if (deltaInDays === 1) {
        return (deltaInDays).toString() + ' day ago';
    }
    return (deltaInDays).toString() + ' days ago';
};

/*
    *Adapted version of ABP utility*
    lastViewed: string or Date - time to compare
    now: Date - time to compare against
    compressed: boolean - optional (defaults false),  true to use shorter versions of relative display strings
    returns - string for display or empty string if missing param 
*/
export const getRelativeTimeSince = (lastViewed: string | Date, now: Date, compressed?: boolean) => {
    if (!lastViewed || !now) {
        return '';
    }
    const lvDate = new Date(lastViewed);
    const deltaTime = now.getTime() - lvDate.getTime();

    const isFuture = deltaTime < 0;
    const seconds = Math.abs(deltaTime);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);
    const suffix = isFuture ? ' from now' : ' ago';
    let text = '';

    if (compressed === undefined) {
        compressed = false;
    }

    if (seconds < offsets.now) {// 'less than a minute'
        text = compressed ? 'few seconds' : 'less than a minute';
    }
    else if (seconds < offsets.minuteOffset) { // 'about a minute'
        text = compressed ? '1 min' : 'about a minute';
    }
    else if (minutes < offsets.xminutes) {// '{0} minutes'
        text = compressed ? Math.round(minutes) + ' mins' : 'about ' + Math.round(minutes) + ' minutes';
    }
    else if (minutes < offsets.oneHour) {// 'about an hour'
        text = compressed ? '1 hour' : 'about an hour';
    }
    else if (hours < offsets.xHours) {// 'about {0} hours'
        text = compressed ? Math.round(hours) + ' hours' : 'about ' + Math.round(hours) + ' hours';
    }
    else if (hours < offsets.oneDay) {// 'about a day' 
        text = compressed ? '1 day' : 'about a day';
    }
    else if (days < offsets.xDays) {// 'about {0} days' 
        text = compressed ? Math.floor(days) + ' days' : 'about ' + Math.floor(days) + ' days';
    }
    else if (days < offsets.oneMonth) {// 'about a month' 
        text = compressed ? '1 month' : 'about a month';
    }
    else if (days < offsets.xMonths) {// 'about {0} months'
        text = compressed ? Math.floor(days / 30) + ' months' : 'about ' + Math.floor(days / 30) + ' months';
    }
    else if (years < offsets.oneYear) {// 'about a year'
        text = compressed ? '1 year' : 'about a year';
    }
    else {// 'about {0} years' 
        text = compressed ? Math.floor(years) + ' years' : 'about ' + Math.floor(years) + ' years';
    }

    return text + suffix;

};