import { FormControlLabel, Grid, Switch, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { ErpProductModel, Maybe } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";

interface DialogProps {
    isOpen: boolean;
    product?: ErpProductModel | null | undefined;
    onClose: () => void;
    onSave: (name: string, description: string, isAptean: boolean, id?: Maybe<string>) => void;
    error?: Error | undefined;
}

const ProductDialog: React.FC<DialogProps> = props => {
    const { isOpen, product, onClose, onSave, error } = props;
    const id = product?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [name, setName] = useState<string>(''); 
    const [description, setDescription] = useState<string>('');
    const [isAptean, setIsAptean] = useState(false);
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    
    useEffect(() => {
        // if have a product, then populate for Edit
        if (product){
            setName(product.name);
            setDescription(product.description);
            if (product.isAptean !== undefined) {
                setIsAptean(product.isAptean as boolean);
            }
        }
        else {
            setName('');
            setDescription('');
            setIsAptean(false);
        }
    },[product]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setSubmitted(false);
            setName('');
            setDescription('');
            setIsAptean(false);
            setIsFormDirty(false);
        }
    }, [isOpen]);
    
    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && name?.trim().length > 0 && description?.trim().length > 0;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(name, description, isAptean, id);
    };

    // onChange handlers
    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setName(event.target.value);
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onIsApteanChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsAptean(event.target.checked);
    };

    const heading = `${product ? 'Edit' : 'Create New'} ERP Product`;

    const nameProps = {
        'aria-label': 'name',
        'maxLength': 50,
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const isApteanProps = {
        'aria-label': 'is aptean',
    };

    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isSubmitted={submitted}
            id={id} 
            entityName="ERP Product" 
            onClose={onClose} 
            onSave={submitForm} 
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-product-name"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={name}
                        label="Name"
                        inputProps={nameProps}
                        onChange={onNameChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-product-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-product-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-product-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel 
                        label="Is Aptean" 
                        control={
                            <Switch
                                itemID="dialog-product-is-aptean"
                                color="primary"
                                disabled={submitted}
                                checked={isAptean}
                                inputProps={isApteanProps}
                                onChange={onIsApteanChange}
                                data-cy="dialog-product-isaptean"
                            />
                        } 
                    />
                    
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default ProductDialog;