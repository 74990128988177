import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingModel, DeleteByIdPayload, UpsertBillingPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    billingList?: BillingModel[];
    upsertBillingStatus?: UpsertBillingPayload;
    deleteBillingStatus?: DeleteByIdPayload;
    billingRequestsInFlight: number;
}

const initialState: SliceState = {
    error: undefined,
    billingList: undefined,
    upsertBillingStatus: undefined,
    deleteBillingStatus: undefined,
    billingRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'billings',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.billingRequestsInFlight = 0;
        },

        fetchBillingListSuccess: (state, action: PayloadAction<BillingModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.billingList = action.payload;
        },

        captureUpsertBillingStatus: (state, action: PayloadAction<UpsertBillingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertBillingStatus = action.payload;
        },

        captureDeleteBillingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteBillingStatus = action.payload;
        },

        incrementBillingRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.billingRequestsInFlight += 1;
        },

        decrementBillingRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.billingRequestsInFlight > 0) state.billingRequestsInFlight -= 1;
        }
    },
});

export const selectError = (state: RootState): Error | undefined => state.billing.error;
export const selectBillingList = (state: RootState): BillingModel[] | undefined => state.billing.billingList;
export const selectUpsertBillingStatus = (state: RootState): UpsertBillingPayload | undefined => state.billing.upsertBillingStatus;
export const selectDeleteBillingStatus = (state: RootState): DeleteByIdPayload | undefined => state.billing.deleteBillingStatus;
export const selectRequestsInFlight = (state: RootState): number => state.billing.billingRequestsInFlight;

export const {
    clearError,
    fetchError,
    clearState,
    fetchBillingListSuccess,
    captureUpsertBillingStatus,
    captureDeleteBillingStatus,
    incrementBillingRequestsInFlight,
    decrementBillingRequestsInFlight
} = slice.actions;

export default slice.reducer;