import { MouseEvent } from 'react';
import { CloseButtonTopRight } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';

interface ButtonProps {
    onClick: (event?: MouseEvent<HTMLElement>) => void;
}

const DialogCloseButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { onClick } = props;

    return (
        <CloseButtonTopRight
            aria-label="close"
            onClick={onClick}>
            <CloseIcon />
        </CloseButtonTopRight>
    );
};

export default DialogCloseButton;