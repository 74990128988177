import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Viewer } from "../../util/Constants";
import { debounce } from "lodash";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../util/ViewerUtility";
import { useAppSelector } from "../../app/hooks";
import CloseIcon from '@mui/icons-material/Close';
import { selectCrossReferences } from "../../features/CrossReferenceTable/CrossReferenceTableSlice";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import FilterBar from "./FilterBar";

interface CrossReferenceFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterCode: string | undefined, 
        filterDescription: string | undefined,
        filterVal: string | undefined,
        filterVersion: string | undefined) => void;
}

const CrossReferenceFilterBar: React.FC<CrossReferenceFilterBarProps> = props => { 
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [debounceOn, setDebounceOn] = useState(false);
    const [filterCode, setFilterCode] = useState<string | undefined>();
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const [filterVal, setFilterVal] = useState<string | undefined>();
    const [filterVersion, setFilterVersion] = useState<string | undefined>();
    const [filterBarFirstLoad, setFilterBarFirstLoad] = useState(true);
    const fieldsDisabled = visible && loading;
    const searchFieldMinLength = 3;

    const debouncedOnFilterChanged = useRef(
        debounce((filterCode, filterDescription, filterVal, filterVersion) => {
            onFilterChanged(filterCode, filterDescription, filterVal, filterVersion);
        }, 1000)
    ).current;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let crossRefCode = getLocalStorageItem(viewer, "crossReferenceFilterCode");
        if (crossRefCode != null) {
            setFilterCode(crossRefCode);
        }
        let crossRefDescription = getLocalStorageItem(viewer, "crossReferenceFilterDescription");
        if (crossRefDescription != null) {
            setFilterDescription(crossRefDescription);
        }
        let crossRefVal = getLocalStorageItem(viewer, "crossReferenceFilterVal");
        if (crossRefVal != null) {
            setFilterVal(crossRefVal);
        }
        let crossRefVersion = getLocalStorageItem(viewer, "crossReferenceFilterVersion");
        if (crossRefVersion != null) {
            setFilterCode(crossRefVersion);
        }
        // because cross references are currently not paged lets send out an initial state to kick a fetch 
        onFilterChanged(
            crossRefCode as string | undefined, 
            crossRefDescription as string | undefined,
            crossRefVal as string | undefined,
            crossRefVersion as string | undefined,
        );

        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    }, []);

    // When the component goes to be unmounted, cancel the debounced function in case it is in progress.
    useEffect(() => {
        return () => {
            debouncedOnFilterChanged.cancel();
        };
    }, [debouncedOnFilterChanged]);

    useEffect(() => {
        // checking for any changes, even all filters off, however we don't it firing immediately on load
        if (!filterBarFirstLoad) {
            let code = filterCode ?? '';
            let description = filterDescription ?? '';
            let val = filterVal ?? '';
            let version = filterVersion ?? '';
            // only trigger search if minimums are met
            if ((code.length <= 0 || code.length >= searchFieldMinLength) && 
                (description.length <= 0 || description.length >= searchFieldMinLength) && 
                (val.length <= 0 || val.length >= 1) && 
                (version.length <= 0 || version.length >= searchFieldMinLength)) {
                if (debounceOn !== true) {
                    onFilterChanged(filterCode, filterDescription, filterVal, filterVersion);
                }
                else {
                    debouncedOnFilterChanged(filterCode, filterDescription, filterVal, filterVersion);
                }
            }
        } else {
            setFilterBarFirstLoad(false);
        }
    }, [filterCode, filterDescription, filterVal, filterVersion]);

    const crossReferences = useAppSelector(selectCrossReferences);

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
    };

    const clearFilters = () => {
        clearCodeFilter();
        clearDescriptionFilter();
        clearValFilter();
        clearVersionFilter();
    };

    const clearCodeFilter = () => {
        removeLocalStorageItem(viewer, "crossReferenceFilterCode");
        setFilterCode(undefined);
    };

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "crossReferenceFilterDescription");
        setFilterDescription(undefined);
    };

    const clearValFilter = () => {
        removeLocalStorageItem(viewer, "crossReferenceFilterVal");
        setFilterVal(undefined);
    };

    const clearVersionFilter = () => {
        removeLocalStorageItem(viewer, "crossReferenceFilterVersion");
        setFilterVersion(undefined);
    };

    // on Filter Change Handlers
    const onCodeFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "crossReferenceFilterCode", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "crossReferenceFilterCode");
        }        
        setFilterCode(changedValue);
    };

    const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "crossReferenceFilterDescription", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "crossReferenceFilterDescription");
        }        
        setFilterDescription(changedValue);
    };

    const onValFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "crossReferenceFilterVal", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "crossReferenceFilterVal");
        }        
        setFilterVal(changedValue);
    };

    const onVersionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "crossReferenceFilterVersion", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "crossReferenceFilterVersion");
        }        
        setFilterVersion(changedValue);
    };

    // FIlter Object Props
    const codeFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear code filter"
                    onClick={clearCodeFilter}
                    disabled={fieldsDisabled}
                >
                    {filterCode && filterCode.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    const valFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear val filter"
                    onClick={clearValFilter}
                    disabled={fieldsDisabled}
                >
                    {filterVal && filterVal.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
    const versionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear version filter"
                    onClick={clearVersionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterVersion && filterVersion.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick}>            
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="cross-reference-filter-code"
                    fullWidth
                    value={filterCode ?? ''}
                    label="Code"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'code', 'maxLength': 50, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={codeFilterProps}
                    onChange={onCodeFilterChanged}
                    autoComplete="off"
                    data-cy="cross-reference-code-filter"
                    variant="standard"
                />
            </Grid>          
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="cross-reference-filter-val"
                    fullWidth
                    value={filterVal ?? ''}
                    label="Val"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'val', 'maxLength': 50}}
                    InputProps={valFilterProps}
                    onChange={onValFilterChanged}
                    autoComplete="off"
                    data-cy="cross-reference-val-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="cross-reference-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 100, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="cross-reference-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="cross-reference-filter-version"
                    fullWidth
                    value={filterVersion ?? ''}
                    label="Version"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'version', 'maxLength': 10, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={versionFilterProps}
                    onChange={onVersionFilterChanged}
                    autoComplete="off"
                    data-cy="cross-reference-version-filter"
                    variant="standard"
                />
            </Grid>
            
        </FilterBar>
    );
};

export default CrossReferenceFilterBar;