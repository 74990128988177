import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AttachmentModel, UserRole, DeleteByIdPayload, RequestResult, UpsertAttachmentInput } from '../../../gql-types.generated';
import { selectError, clearError, captureDeleteClientAttachmentStatus, captureUpsertClientAttachmentsStatus, selectDeleteClientAttachmentStatus, selectUpsertClientAttachmentsStatus, selectAttachmentToDownload, clearClientAttachment } from '../../../features/ClientDetails/ClientDetailsSlice';
import { deleteClientAttachment, upsertClientAttachments, fetchClientAttachment } from '../../../features/ClientDetails/ClientDetailsActions';
import AttachmentList from './AttachmentList';
import { downloadBlobAsFile, getFileDataString, openBlobAsFileInNewTab } from '../../../util/Common';

interface ClientAttachmentListProps {
    viewerRole: UserRole | undefined;
    clientId: string;
    attachments: AttachmentModel[] | undefined;
    refreshClientData: () => void;
}

const ClientAttachmentList: React.FC<ClientAttachmentListProps> = (props: ClientAttachmentListProps) => {
    const { viewerRole, clientId, attachments, refreshClientData } = props;
    const dispatch = useAppDispatch();
    const [deleteStatus, setDeleteStatus] = useState<DeleteByIdPayload | undefined>();
    const [addResult, setAddResult] = useState<RequestResult | undefined>();
    const [addResultMessage, setAddResultMessage] = useState<string | undefined>();
    const [openAttachment, setOpenAttachment] = useState<boolean | undefined>();

    const upsertClientAttachmentStatus = useAppSelector(selectUpsertClientAttachmentsStatus);
    const deleteClientAttachmentStatus = useAppSelector(selectDeleteClientAttachmentStatus);
    const attachmentToDownload = useAppSelector(selectAttachmentToDownload);
    const error = useAppSelector(selectError);

    useEffect(() => {
        // save off add result for base component
        if (upsertClientAttachmentStatus && upsertClientAttachmentStatus.result) {
            // set the result to be passed to base component
            setAddResult(upsertClientAttachmentStatus.result);
            setAddResultMessage(upsertClientAttachmentStatus.message as string);
        }
        else {
            setAddResult(undefined);
        }

        if (deleteClientAttachmentStatus && deleteClientAttachmentStatus.result) {
            // set the status to be passed to base component
            setDeleteStatus(deleteClientAttachmentStatus);
        }
        else {
            setDeleteStatus(undefined);
        }
    }, [deleteClientAttachmentStatus?.result, upsertClientAttachmentStatus?.result]);

    useEffect(() => {
        if (attachmentToDownload && attachmentToDownload[0] && attachmentToDownload[0] && attachmentToDownload[0].fileName) {
            const attachment = attachmentToDownload[0];
            if (attachment.attachmentString !== undefined && attachment.attachmentString !== null && attachment.fileName && attachment.mimeType) {
                const fileData = getFileDataString(true, attachment.mimeType);
                fetch(fileData + "," + attachment.attachmentString)
                    .then(res => res.blob())
                    .then(blob => {
                        if (openAttachment) {
                            openBlobAsFileInNewTab(blob, attachment.fileName as string);
                        } else {
                            downloadBlobAsFile(blob, attachment.fileName as string);
                        }

                        dispatch(clearClientAttachment());
                        setOpenAttachment(undefined);
                    });
            }
        }
    }, [attachmentToDownload]);


    const onDeleteDialogClose = () => {
        // clear the delete status
        dispatch(captureDeleteClientAttachmentStatus());
    };

    const onAttachmentsDialogClose = () => {
        // clear the upsert status
        dispatch(captureUpsertClientAttachmentsStatus());
    };

    const onAttachmentsSave = (attachments: UpsertAttachmentInput[]) => {
        if (attachments && clientId) {
            dispatch(
                upsertClientAttachments(
                    clientId,
                    attachments
                )
            );
        }
    };

    const onAttachmentDelete = (id: string) => {
        if (id) {
            dispatch(deleteClientAttachment(id));
        }
    }

    const onAttachmentDownload = (id: string) => {
        if (id) {
            setOpenAttachment(false);
            dispatch(fetchClientAttachment(id));
        }
    }

    const onAttachmentOpen = (id: string) => {
        if (id) {
            setOpenAttachment(true);
            dispatch(fetchClientAttachment(id));
        }
    }

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <AttachmentList
            viewerRole={viewerRole}
            attachments={attachments}
            deleteAttachmentStatus={deleteStatus}
            upsertAttachmentResult={addResult}
            upsertAttachmentResultMessage={addResultMessage}
            deleteAttachment={onAttachmentDelete}
            downloadAttachment={onAttachmentDownload}
            openAttachment={onAttachmentOpen}
            onDeleteDialogClose={onDeleteDialogClose}
            onAttachmentsDialogClose={onAttachmentsDialogClose}
            saveAttachments={onAttachmentsSave}
            refreshList={refreshClientData}
            clearError={handleClearError}
            error={error}
        />

    );

}

export default ClientAttachmentList;