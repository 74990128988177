import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetMessagingLogsInput, MessagingLogByIdQuery, MessagingLogModel, Scalars } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_MESSAGING_LOG_BY_ID = gql`
    query messagingLogById($input: GetMessagingLogsInput!) {
        messagingLogs (input: $input) {
            nodes {
                id
                documentId
                description
                direction
                documentDate
                documentType
                documentReferenceId
                fileName
                internalClientCode
                internalPartnerCode
                invoice
                isAcknowledged
                messageType
                originalFileName
                pO
                processName
                rawMessage
                receiverId
                senderId
                status
                totalLines
                totalQuantity
                totalValue
                createdTime
                client {
                    id
                    name
                }
                partner {
                    id
                    name
                }
                clientPartner {
                    id
                }
            }
        }
    }
`;

export const queryMessagingLogById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<MessagingLogModel[] | undefined> => {
    const {data, errors} = await client.query<MessagingLogByIdQuery>({
        variables: {
            input: {
                id: id
            }
        },
        query: QUERY_MESSAGING_LOG_BY_ID,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.messagingLogs?.nodes as MessagingLogModel[];
    
};