import { ChangeEvent, useEffect, useRef, useState } from "react";
import { Viewer } from "../../util/Constants";
import { debounce } from "lodash";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../util/ViewerUtility";
import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import FilterBar from "./FilterBar";

interface PostProcessingBlobsFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterDescription: string | undefined
        ) => void;
}

const PostProcessingBlobsFilterBar: React.FC<PostProcessingBlobsFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [debounceOn, setDebounceOn] = useState(false);
    const [filterDescription, setFilterDescription] = useState<string | undefined>();
    const [filterBarFirstLoad, setFilterBarFirstLoad] = useState(true);
    const fieldsDisabled = visible && loading;
    const searchFieldMinLength = 3;

    const debouncedOnFilterChanged = useRef(
        debounce((filterDescription) => {
            onFilterChanged(filterDescription);
        }, 1000)
    ).current;

    useEffect(() => {
        // filters from local storage to be remembered across sessions
        let postProssesBlobDescription = getLocalStorageItem(viewer, "postProcessingBlobsFilterDescription");
        if (postProssesBlobDescription != null) {
            setFilterDescription(postProssesBlobDescription);
        }
        // because cross references are currently not paged lets send out an initial state to kick a fetch 
        onFilterChanged(
            postProssesBlobDescription as string | undefined
        );

        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    }, []);

    // When the component goes to be unmounted, cancel the debounced function in case it is in progress.
    useEffect(() => {
        return () => {
            debouncedOnFilterChanged.cancel();
        };
    }, [debouncedOnFilterChanged]);

    useEffect(() => {
        // checking for any changes, even all filters off, however we don't it firing immediately on load
        if (!filterBarFirstLoad) {
            let description = filterDescription ?? '';
            // only trigger search if minimums are met
            if ((description.length <= 0 || description.length >= searchFieldMinLength)) {
                if (debounceOn !== true) {
                    onFilterChanged(filterDescription);
                }
                else {
                    debouncedOnFilterChanged(filterDescription);
                }
            }
        } else {
            setFilterBarFirstLoad(false);
        }
    }, [filterDescription]);

    const onCloseClick = () => {
        clearFilters();
        props.onClose();
    };

    const clearFilters = () => {
        clearDescriptionFilter();
    };

    const clearDescriptionFilter = () => {
        removeLocalStorageItem(viewer, "postProcessingBlobsFilterDescription");
        setFilterDescription(undefined);
    };

     // on Filter Change Handlers
     const onDescriptionFilterChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let changedValue = event.target.value;
        if (changedValue?.length > 0) {
            // only set local storage if minimum is met
            if (changedValue?.length >= event.target.minLength) {
                setLocalStorageItem(viewer, "postProcessingBlobsFilterDescription", changedValue);
            }
        }
        else {
            removeLocalStorageItem(viewer, "postProcessingBlobsFilterDescription");
        }        
        setFilterDescription(changedValue);
    };

    // FIlter Object Props
    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick}>            
            <Grid item xs={3} xl={2}>
                <TextField
                    itemID="post-processing-blob-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    title="Enter at least 3 characters"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 100, 'minLength': {minLengthSearchField: searchFieldMinLength} }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChanged}
                    autoComplete="off"
                    data-cy="post-processing-blob-description-filter"
                    variant="standard"
                />
            </Grid>            
        </FilterBar>
    );
};

export default PostProcessingBlobsFilterBar;