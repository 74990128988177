import { useState } from 'react';
import { Avatar, Box, CircularProgress, Grid, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Typography, styled } from '@mui/material';
import { EntityType, FavoriteModel, RecentModel } from '../../gql-types.generated';
import ClientIcon from '@mui/icons-material/Work';
import PartnerIcon from '@mui/icons-material/SafetyDivider';
import FavoritesIcon from '@mui/icons-material/StarOutline';
import RecentsIcon from '@mui/icons-material/AccessTime';
import { DetailsTabPanelContainer, DetailsTabHeader, DetailsTabHeaderWrapper, TabbedListCard, TabbedListCardTabList, TabHeader, TabLabel } from '../../util/SharedStyles';
import { getTimeSinceString } from '../../util/TimeSince';
import { Viewer } from '../../util/Constants';
import ListItemTextDoubleSecondary from './ListItemTextDoubleSecondary';
import TabPanel from '../TabPanel';

const Loader = styled('div')((props) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
}));


interface FavoritesRecentsCardProps {
    loading?: boolean | false;
    viewer?: Viewer | undefined;
}

const FavoritesRecentsTile: React.FC<FavoritesRecentsCardProps> = (props: FavoritesRecentsCardProps) => {
    const { viewer } = props;
    const [tabValue, setTabValue] = useState(0);
    
    const getFavoriteListItems = (favorites: FavoriteModel[]) => {
        return (
            favorites.map((favorite: FavoriteModel, index) => {
                const entityType = favorite.entityType as EntityType;
                let url = '/error/unknowntype';
                let entityIcon;

                // favorites are displayed for Clients and Partners
                switch (entityType) {
                    case EntityType.Client:
                        url = '/client/' + favorite.entityId;
                        entityIcon = <ClientIcon />;
                        break;
                    case EntityType.Partner:
                        url = '/partner/' + favorite.entityId;
                        entityIcon = <PartnerIcon />;
                        break;
                }

                return (
                    <ListItem disableGutters disablePadding key={favorite.entityId}>
                        <ListItemButton id={favorite.id} component="a" href={url} >
                            <ListItemAvatar>
                                <Avatar>
                                    {entityIcon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={favorite.name} secondary={entityType} />
                        </ListItemButton>
                    </ListItem>
                )
            })
        );
    }

    const getRecentListItems = (recents: RecentModel[]) => {
        const now = new Date();
        return (
            recents.map((recent: RecentModel, index) => {
                // Only show 6 recent items
                if (index >= 6) {
                    return null;
                }
                
                let timeSince = 'No last visit data';
                if (recent.lastViewed) {
                    timeSince = getTimeSinceString(recent.lastViewed, now);
                }
                
                let entityIcon;

                const entityType = recent.entityType as EntityType;
                let url = '/error/unknowntype';
                switch (entityType) {
                    case EntityType.Client:
                        url = '/client/' + recent.entityId;
                        entityIcon = <ClientIcon />;
                        break;
                    case EntityType.Partner:
                        url = '/partner/' + recent.entityId;
                        entityIcon = <PartnerIcon />;
                        break;
                }

                return (
                    <ListItem disableGutters disablePadding key={recent.entityId}>
                        <ListItemButton id={recent.entityId} component="a" href={url} >
                            <ListItemAvatar>
                                <Avatar>
                                    {entityIcon}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemTextDoubleSecondary
                                primaryText={recent.name}
                                secondaryLeft={entityType as string}
                                secondaryRight={timeSince}
                            />
                        </ListItemButton>
                    </ListItem>
                )
            })
        );
    };

    // TODO use an image instead of text
    let favoriteList = <Typography>No Favorite Items</Typography>;
    if (viewer?.favorites && viewer?.favorites.length > 0) {
        favoriteList = (<TabbedListCardTabList dense>
            {getFavoriteListItems(viewer?.favorites)}
        </TabbedListCardTabList>);
    };

    let recentList = <Typography>No Recent Items</Typography>;
    if (viewer?.recents) {
        recentList = (<TabbedListCardTabList dense>
            {getRecentListItems(viewer?.recents)}
        </TabbedListCardTabList>);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    let favoritesTabLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <FavoritesIcon />
            <TabLabel>Favorites</TabLabel>
        </Grid>
    );

    let recentsTabLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <RecentsIcon />
            <TabLabel>Recent</TabLabel>
        </Grid>
    );

    const getTabbedContent = () => {
        if (viewer?.favorites) {
            return (
                <Box>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader
                            value={tabValue}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable recents and favorites dashboard tabs"
                        >
                            <TabHeader label={recentsTabLabel} aria-label="recents list"/>
                            <TabHeader label={favoritesTabLabel} aria-label="favorites list"/>
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>                    
                    <DetailsTabPanelContainer>
                        <TabPanel value={tabValue} index={0} verticalAlign>
                            {recentList}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1} verticalAlign>
                            {favoriteList}
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </Box>
            );
        } else {
            //loading
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        }
    };

    return (
        <TabbedListCard>
            {getTabbedContent()}
        </TabbedListCard>
    );
};

export default FavoritesRecentsTile;
