import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PagingResultModelOfTransactionErrorModel, GetTransactionErrorsInput, ErrorLogListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_MESSAGING_LOG_LIST = gql`
    query errorLogList($input: GetTransactionErrorsInput!) {
        transactionErrors (input: $input) {
            cursor {
                nextPage
                previousPage
              }
            nodes {
                id
                documentId
                description
                direction
                documentType
                documentReferenceId
                messageType
                originalFileName
                purchaseOrderNumber
                processName
                rawMessage
                status
                createdTime
                clientId
                clientName
                client {
                    id
                    name
                }
                partnerId
                partnerName
                partner {
                    id
                    name
                }
            }
        }
    }
`;

export const queryErrorLogList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetTransactionErrorsInput
): Promise<PagingResultModelOfTransactionErrorModel | undefined> => {
    const { after, limit, clientName, direction, documentType, order, partnerName, processName, status, documentId, purchaseOrderNumber, dateFrom, dateTo, originalFileName } = filters ?? {};
    const { data, errors } = await client.query<ErrorLogListQuery>({
        variables: {
            input: {
                after,
                limit,
                clientName: clientName?.trim(),
                direction,
                documentType: documentType?.trim(),
                order,
                partnerName: partnerName?.trim(),
                processName,
                status,
                documentId: documentId?.trim(),
                purchaseOrderNumber: purchaseOrderNumber?.trim(),
                dateFrom,
                dateTo,
                originalFileName: originalFileName?.trim()
            }
        },
        query: QUERY_MESSAGING_LOG_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.transactionErrors as PagingResultModelOfTransactionErrorModel;
}