import { Divider, Grid, IconButton, Typography, Skeleton, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { PipelineModel } from "../../gql-types.generated";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ProcessIcon from "@mui/icons-material/AccountTree";
import { CardFooter, ClickableRootCard, RootCardHeader, SuspendedStatusChip } from '../../util/SharedStyles';

interface PipelineListItemProps {
    loading?: boolean | false;
    canEdit?: boolean;
    canAddOrDelete?: boolean;
    pipeline?: PipelineModel | undefined;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    onCloneClick: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const PipelineListItem: React.FC<PipelineListItemProps> = (props: PipelineListItemProps) => {
    const { loading, pipeline, canEdit=false, canAddOrDelete=false } = props;
    const description = pipeline?.description;
    const isLegacy = pipeline?.isLegacy;

    const id = pipeline?.id as string;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (id) {
            props.clickAction(id);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        let buttonDataSet = (event.target as HTMLElement).dataset;

        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete") {
            props.onDeleteClick(id);
        }
        else if (buttonDataSet.operation === "clone") {
            props.onCloneClick(id);
        }

        setAnchorEl(null);
    };

    // only display menu if have edit permissions and not while loading
    let action = loading || !canEdit ? undefined : (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
       description
    );
    
    let chipContainerJustify = "flex-end";
    let processesElement = null;
    let numProcesses = pipeline?.pipelineItems?.length ?? 0;
    if (numProcesses && numProcesses > 0) {
        chipContainerJustify = "space-between";
        processesElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <ProcessIcon fontSize='small'/>
                <Typography>Processes: {numProcesses}</Typography>
            </Grid>
        )
    }

    let legacyChip;
    if (isLegacy) {
        // using SuspendedStatus to get warning coloration
        legacyChip = <SuspendedStatusChip label="Is Legacy" />
    }

    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={title}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {processesElement}
                            {legacyChip}
                        </>
                    )
                }
            </CardFooter>
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                >
                    Edit
                </MenuItem>
                {canAddOrDelete &&
                <MenuItem
                    data-operation="clone"
                    onClick={handleMenuClose}
                >
                    Clone
                </MenuItem>
                }
                {canAddOrDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                >
                    Delete
                </MenuItem>
                }
            </Menu>
        </ClickableRootCard>
    )
}

export default PipelineListItem;