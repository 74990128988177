import { useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { AppBar, Toolbar, Grid, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TitleBar = styled(AppBar)(({ theme }) => ({
    height: '64px',
    zIndex: 1,
}));

const TitleToolbar = styled(Toolbar)((props) => ({
    justifyContent: 'space-between',
    height: '100%',
}));

const TitleBarContainer = styled(Grid)((props) => ({
    alignItems: 'center',
    zIndex: 1,
    width: '100% !important', // override default Grid width
    marginLeft: '0px !important', //remove default Grid left margin
    marginTop: '0px !important', //remove default Grid top margin
}));

const TitleLabelGrid = styled(Grid)((props) => ({
    paddingTop: '0px !important', //remove default Grid top padding
    display: 'flex',
    alignItems: 'center',
    width: '100% !important'
}));

const TitleLabel = styled(Typography)((props) => ({
    fontWeight: 'bold',
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
}));

interface TitleBarProps {
    children?: React.ReactNode;
    text?: string | ReactElement;
    id?: string;
    hasBackButton?: boolean;
}

const PageTitleBar: React.FC<TitleBarProps> = props => {
    const { text, id, hasBackButton = false } = props;
    const navigate = useNavigate();

    const onBackClick = () => {
        // navigate to previous route
        navigate(-1);
    }

    let backButton = null;
    if (hasBackButton) {
        backButton = (
            < IconButton
                aria-label="back button"
                id="back-button"
                size="large"
                onClick={onBackClick}
            >
                <ArrowBackIcon />
            </IconButton >
        );
    }

    return (
        <TitleBarContainer container spacing={2} id={id}>
            <TitleBar position="static" color="inherit">
                <TitleToolbar variant='dense'>
                    <TitleLabelGrid item xs={'auto'}>
                        {backButton}
                        <TitleLabel variant='h5' >{text}</TitleLabel>
                        {props.children}
                    </TitleLabelGrid>
                </TitleToolbar>
            </TitleBar>
        </TitleBarContainer>
    );
};

export default PageTitleBar;