import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetNotificationFormatterInput, NotificationFormattersListQuery, PagingResultModelOfNotificationFormatterModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_NOTIFICATION_FORMATTERS_LIST = gql`
    query notificationFormattersList($input: GetNotificationFormatterInput!) {
        notificationFormatters (input: $input) {
            cursor {
                nextPage
                previousPage
            }
            nodes {
                id
                description
                bodyType
                asAttachment
                isForError
                totalTransactions
            }
        }
    }
`;

export const queryNotificationFormatterList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetNotificationFormatterInput
) : Promise<PagingResultModelOfNotificationFormatterModel | undefined> => {
    const { after, before, limit, description, bodyType, isForError, transactionId, order } = filters ?? {};
    const { data, errors } = await client.query<NotificationFormattersListQuery>({
        query: QUERY_NOTIFICATION_FORMATTERS_LIST,
        variables: {
            input: {
                after,
                before,
                limit,
                order,
                description,
                bodyType,
                isForError,
                transactionId
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.notificationFormatters as PagingResultModelOfNotificationFormatterModel;
};