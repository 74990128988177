import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DateTime } from 'luxon';
import { UpsertBillingItemPayload, UpsertBillingItemInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_BILLING_ITEM = gql`
    mutation upsertBillingItem($input: UpsertBillingItemInput!){
        upsertBillingItem(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertBillingItem = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        billingId: string,
        dateFrom: DateTime,
        dateTo: DateTime,
        pricingId: string,
        id?: string,
    }
): Promise<UpsertBillingItemPayload | undefined> => {
    const {
        billingId,
        dateFrom,
        dateTo,
        pricingId,
        id,
    } = args as UpsertBillingItemInput;
    const { data, errors } = await client.mutate<{ upsertBillingItem: UpsertBillingItemPayload }>({
        variables: {
            input: {
                billingId,
                dateFrom,
                dateTo,
                pricingId,
                id,
            }
        },
        mutation: MUTATION_UPSERT_BILLING_ITEM
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the billing item save.');
    }
    if (data && data.upsertBillingItem) {
        return data.upsertBillingItem;
    }
    return undefined;
};