import { Button, styled } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const StyledButton = styled(Button)((props) => ({
    maxHeight: '38px',
}));
interface ButtonProps {
    onClick: () => void;
    text?: string | undefined;
    tooltip?: string | undefined;
    disabled?: boolean;
    variant?: "text" | "contained" | "outlined" | undefined;
}

const CreateNewButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { onClick, disabled, text = "Create New", tooltip, variant = "contained" } = props;

    return (
        <StyledButton
            variant={variant}
            color="primary"
            title={tooltip}
            startIcon={<AddCircleIcon />}
            onClick={onClick}
            disabled={disabled}
        >
            {text}</StyledButton>
    );
};

export default CreateNewButton;