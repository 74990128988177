import { Grid, MenuItem, TextField, FormControlLabel, Switch } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Maybe, UserRole, UserModel } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import { isEmailValid } from "../../util/Validation";

interface UserDialogProps {
    isOpen: boolean;
    onClose: () => void;
    user?: UserModel | null | undefined;
    error?: Error | undefined;
    onSave: (firstName: string, lastName: string, email: string, role: UserRole, isActive: boolean, id?: Maybe<string>) => void;
}

const roleOptions = [
    {
        value: UserRole.Reader,
        label: 'Reader',
    },
    {
        value: UserRole.Editor,
        label: 'Editor',
    },
    {
        value: UserRole.Admin,
        label: 'Admin',
    },
];

const UserDialog: React.FC<UserDialogProps> = props => {
    const { isOpen, user, error, onClose, onSave } = props;
    const id = user?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<UserRole>(UserRole.Reader);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [emailInvalid, setEmailInvalid] = useState(false); // Default invalid state to false
    const [emailErrorText, setEmailErrorText] = useState(''); // Default invalid state to false
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    useEffect(() => {
        // if have a user, then populate for Edit
        if (user) {
            if (user.firstName) {
                setFirstName(user.firstName);
            }
            if (user.lastName) {
                setLastName(user.lastName);
            }
            if (user.email) {
                setEmail(user.email);
            }
            if (user.role) {
                setRole(user.role);
            }
            if (user.isActive !== undefined && user.isActive !== null) {
                setIsActive(user.isActive);
            }
        }
        else {
            setToDefaults();
        }
    }, [user]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsFormDirty(false);
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setRole(UserRole.Reader);
        setIsActive(true);
    };

    const onError = () => {
        setSubmitted(false);
    }

    const validateAndSetEmail = (toValidate: string) => {
        if (toValidate || toValidate === "") {
            const isValid = isEmailValid(toValidate);
            setEmailInvalid(!isValid);
            if (isValid) {
                setIsFormDirty(true);
                setEmailErrorText('');
            } else {
                setEmailErrorText('Invalid format.  Enter a valid email address');
            }
            setEmail(toValidate);
        }
    }

    const isFormValid = () => {
        let requiredCheck = firstName?.length > 0 && lastName?.length > 0 && email?.length > 0 && role !== null;
        return isFormDirty && requiredCheck && !emailInvalid;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(firstName, lastName, email, role, isActive, id);
    };

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setFirstName(event.target.value);
    };
    const handleLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setLastName(event.target.value);
    };
    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        validateAndSetEmail(event.target.value);
    };

    const handleRoleChange = (event: ChangeEvent<HTMLInputElement>) => {
        // Set as the Role value structure.
        setIsFormDirty(true);
        const roleChangeValue = event.target.value || UserRole.Reader;
        setRole(roleChangeValue as UserRole);
    };

    const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsActive(event.target.checked);
    };

    const firstNameProps = {
        'aria-label': 'first name',
        'maxLength': 100,
    };
    const lastNameProps = {
        'aria-label': 'last name',
        'maxLength': 100,
    };
    const emailProps = {
        "aria-label": "email",
        maxLength: 100,
    };

    const isEmailDisabled = () => {
        /**disable the email if the form
        is submitted or its an update 
        operation(id is not null)**/
        if (submitted || id) {
            return true;
        }
        return false;
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id ?? undefined}
            entityName="User"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-user-first-name"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={firstName}
                        label="First Name"
                        inputProps={firstNameProps}
                        onChange={handleFirstNameChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-user-first-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-user-second-name"
                        fullWidth
                        disabled={submitted}
                        value={lastName}
                        label="Last Name"
                        inputProps={lastNameProps}
                        onChange={handleLastNameChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-user-last-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-user-email"
                        fullWidth
                        error={emailInvalid}
                        helperText={emailErrorText}
                        disabled={isEmailDisabled()}
                        value={email}
                        label="Email"
                        inputProps={emailProps}
                        onChange={handleEmailChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-user-email"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-user-role"
                        fullWidth
                        onChange={handleRoleChange}
                        defaultValue={UserRole.Reader}
                        disabled={submitted}
                        inputProps={{ 'aria-label': 'role' }}
                        label="Role"
                        value={role}
                        data-cy="dialog-user-role"
                        variant="standard"
                        select
                    >
                        {roleOptions.map(roleValue => (
                            <MenuItem key={roleValue.value} value={roleValue.value}>
                                {roleValue.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Is Active"
                        control={
                            <Switch
                                itemID="dialog-user-is-active"
                                color="primary"
                                disabled={submitted}
                                checked={isActive}
                                inputProps={{ 'aria-label': 'is active' }}
                                onChange={onIsActiveChange}
                                data-cy="dialog-user-is-active"
                            />
                        }
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default UserDialog;