import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import gql from "graphql-tag";
import { GetPostProcessingBlobInput, PagingResultModelOfPostProcessingBlobModel, PostProcessingBlobsQuery } from "../gql-types.generated";

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_POST_PROCESSING_BLOBS = gql`
    query postProcessingBlobs($input: GetPostProcessingBlobInput!) {
        postProcessingBlobs(input: $input) {
            cursor {
                nextPage
                previousPage
            }
            nodes {
                description
                id
                lastModifiedBy
                lastModifiedTime
                processBlobString
            }
        }
    }
`;

export const queryPostProcessingBlobs = async(
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetPostProcessingBlobInput
): Promise<PagingResultModelOfPostProcessingBlobModel | undefined> => {
    const {after, before, description, id, limit, order} = filters ?? {};
    const { data, errors } = await client.query<PostProcessingBlobsQuery>({
        variables: {
            input: {
                after, before, description, id, limit, order,
            }
        },
        query: QUERY_POST_PROCESSING_BLOBS
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.postProcessingBlobs as PagingResultModelOfPostProcessingBlobModel;
}