import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { 
    fetchError, 
    fetchXPathError,
    fetchTransactionByIdSuccess,
    fetchTransactionListSuccess, 
    captureUpsertTransactionStatus, 
    captureDeleteTransactionStatus, 
    incrementTransactionRequestsInFlight, 
    decrementTransactionRequestsInFlight,
    captureUpsertTransactionXPathStatus,
    captureDeleteTransactionXPathStatus
 } from './TransactionsSlice';
import { TransactionDirection, TransactionModel, RequestResult, UpsertTransactionInput, UpsertTransactionXPathInput } from '../../gql-types.generated';
import { queryTransactionList } from '../../gql/QueryTransactionsList';
import { queryTransactionById } from '../../gql/QueryTransactionById';
import { mutationUpsertTransaction } from '../../gql/MutationUpsertTransaction';
import { mutationDeleteTransaction } from '../../gql/MutationDeleteTransaction';
import { mutationUpsertTransactionXPath } from '../../gql/MutationUpsertTransactionXPath';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';


export const fetchTransactionById = (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const transaction = await queryTransactionById(gqlClient, id);
            if (transaction) {
                dispatch(fetchTransactionByIdSuccess(transaction))
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchTransactionList = (
    id?: string | undefined,
    name?: string | undefined,
    description?: string | undefined,
    ediStandardIds?: string[] | undefined,
    isActive?: boolean | undefined,
    isBillable?: boolean | undefined,
    direction?: TransactionDirection | undefined
) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementTransactionRequestsInFlight());
        try {
            const transactionList = await queryTransactionList(gqlClient, { id, name, description, ediStandardIds, isActive, isBillable, direction });
            if (transactionList) {
                dispatch(fetchTransactionListSuccess(transactionList as TransactionModel[]));
            }
            dispatch(decrementTransactionRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertTransactionData = (upsertTransactionData: UpsertTransactionInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertTransactionStatus = await mutationUpsertTransaction(gqlClient, {
                upsertTransactionData
            });
            if (upsertTransactionStatus) {
                if (upsertTransactionStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertTransactionStatus.message } as Error));
                    getErrorMessage(upsertTransactionStatus.message as string, upsertTransactionStatus.errors, true, true);
                }
                dispatch(captureUpsertTransactionStatus(upsertTransactionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteTransactionData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteTransactionStatus = await mutationDeleteTransaction(gqlClient, {
                id,
            });
            if (deleteTransactionStatus) {
                if (deleteTransactionStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteTransactionStatus.message as string, deleteTransactionStatus.errors, true, true);
                }
                dispatch(captureDeleteTransactionStatus(deleteTransactionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertTransactionXPath = (
        transactionXPathInputData: UpsertTransactionXPathInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const upsertStatus = await mutationUpsertTransactionXPath(gqlClient, { transactionXPathInputData });
                if (upsertStatus) {
                    if (upsertStatus.result === RequestResult.Fail) {
                        dispatch(fetchXPathError({ message: upsertStatus.message } as Error));
                        getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                    }
                    dispatch(captureUpsertTransactionXPathStatus(upsertStatus));
                }
            } catch (e) {
                dispatch(fetchXPathError(e as Error));
            }
        };
    
export const deleteTransactionXPath = (transactionXPathId: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id: transactionXPathId,
                entityName: "TransactionXPath",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteTransactionXPathStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchXPathError(e as Error));
        }
    };



