import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientModel, PagingResultModelOfClientModel, UpsertClientPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    clientPagingResult?: PagingResultModelOfClientModel;
    clientList?: ClientModel[];
    clientNameSearchList?: string[];
    clientRequestsInFlight: number;
    upsertClientStatus?: UpsertClientPayload;
}

const initialState: SliceState = {
    error: undefined,
    clientPagingResult: undefined,
    clientList: undefined,
    clientNameSearchList: undefined,
    clientRequestsInFlight: 0,
    upsertClientStatus: undefined,
};

export const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientNameSearchList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientStatus = undefined;
        },

        fetchClientListSuccess: (state, action: PayloadAction<PagingResultModelOfClientModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.clientPagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let clients = state.clientList;
            if (!clients || !state.clientPagingResult.cursor?.previousPage) {
                clients = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!clients?.some(i => i.id === node?.id)) {
                        clients?.push(node as ClientModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.clientList = clients;
        },

        fetchClientNameSearchResultsSuccess: (state, action: PayloadAction<PagingResultModelOfClientModel | undefined>) => {
            let pagingResult = action.payload;
            let clientNames: string[] = [];
            
            if (pagingResult?.nodes) {
                pagingResult.nodes.forEach(node => {
                    if (node.name) {
                        clientNames.push(node.name);
                    }
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.clientNameSearchList = clientNames;
        },

        incrementClientRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.clientRequestsInFlight += 1;
        },

        decrementClientRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.clientRequestsInFlight > 0) state.clientRequestsInFlight -= 1;
        },

        captureUpsertClientStatus: (state, action: PayloadAction<UpsertClientPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientStatus = action.payload;
        },
    },
});

export const selectError = (state: RootState): Error | undefined => state.clients.error;
export const selectClientPagingResult = (state: RootState): PagingResultModelOfClientModel | undefined => state.clients.clientPagingResult;
export const selectClientList = (state: RootState): ClientModel[] | undefined => state.clients.clientList;
export const selectClientNameSearchResults = (state: RootState): string[] | undefined => state.clients.clientNameSearchList;
export const selectRequestsInFlight = (state: RootState): number => state.clients.clientRequestsInFlight;
export const selectNetworkBusy = (state: RootState): boolean => state.clients.clientRequestsInFlight > 0;
export const selectUpsertClientStatus = (state: RootState): UpsertClientPayload | undefined => state.clients.upsertClientStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchClientListSuccess,
    fetchClientNameSearchResultsSuccess,
    incrementClientRequestsInFlight,
    decrementClientRequestsInFlight,
    captureUpsertClientStatus
} = slice.actions;

export default slice.reducer;