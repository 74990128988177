import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ErpProductsListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PRODUCTS_LIST = gql`
    query erpProductsList {
        erpProducts {
            nodes {
                name
                description
                id
                isAptean
            }
        }
    }
`;

export const queryProductList = async (client: ApolloClient<NormalizedCacheObject>) => {
    
    const { data, errors } = await client.query<ErpProductsListQuery>({
        query: QUERY_PRODUCTS_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.erpProducts?.nodes;
};