import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { StateModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_STATES = gql`
    query statesList {
        states ( 
            input: {
                order: [
                    {countryCode:ASC},
                    {stateCode:ASC}
                ],
                limit: 250
            }
        ){
            nodes {
                countryCode
                description
                stateCode
                id
            }
        }
    }
`;

export const queryStatesList = async (client: ApolloClient<NormalizedCacheObject>) => {
    const { data, errors } = await client.query({
        query: QUERY_STATES,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.states?.nodes as StateModel[];
}