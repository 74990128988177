import { useEffect, useState } from 'react';
import { Badge, ToggleButton, styled, Typography  } from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';

const FilterToggleButton = styled(ToggleButton)((props) => ({
    height: '40px',
}));

const FilterBadge = styled(Badge)((props) => ({
    '& .MuiBadge-badge': {
        cursor: 'none',
        pointerEvents: 'none',
    },
}));

interface ButtonProps {
    filterCount?: number;
    filtersCleared: boolean;
    disabled: boolean;
    onClick: () => void;
}

const FiltersButton: React.FC<ButtonProps> = (props: ButtonProps) => {
    const { onClick, filterCount=0, filtersCleared=false, disabled=false } = props;
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (filtersCleared) {
            // if the filters were cleared, set as unselected
            setSelected(false);
        }
     }, [filtersCleared]);
    
    return (
        <FilterBadge color="primary" overlap='rectangular' badgeContent={filterCount}>
            <FilterToggleButton
                value="filter"
                selected={selected}
                onClick={onClick}
                aria-label="filter" 
                color="primary"
                title={"Filter"}
                disabled={disabled}
                onChange={() => {
                    setSelected(!selected);
                }}
            >
                <FilterIcon /> <Typography fontSize={16} marginLeft="8px" marginRight="4px">FILTERS</Typography>
            </FilterToggleButton>
        </FilterBadge>
        
    );
};

export default FiltersButton;