import { useState } from 'react';
import { EntityType, Maybe, ClientStatus } from '../../gql-types.generated';
import { Divider, Skeleton, Grid, Typography } from '@mui/material';
import PartnersIcon from "@mui/icons-material/SafetyDivider";
import capitalize from 'lodash/capitalize';
import { ActiveStatusChip, SuspendedStatusChip, StatusChip, CardChip, CardFooter, ClickableRootCard, RootCardHeader, CardAvatar } from '../../util/SharedStyles';
import FavoriteToggleButton from '../buttons/FavoriteToggleButton';
import ClientIcon from '@mui/icons-material/Work';
import { getAvatarComponent } from '../../util/Common';
import { getEntityFavorite } from '../../util/ViewerUtility';
import { Viewer } from '../../util/Constants';

interface ClientListItemProps {
    name?: string | undefined;
    id?: string | undefined;
    code?: Maybe<string> | undefined;
    status?: Maybe<ClientStatus> | undefined;
    loading?: boolean | false;
    numTradingPartners?: Maybe<number> | undefined;
    viewer?: Viewer | undefined;
    businessAreaName?: string | undefined;
    erpProductName?: string | undefined;
    website?: Maybe<string> | undefined;
    clickAction: (id: string | undefined) => void;
}

const ClientListItem: React.FC<ClientListItemProps> = (props: ClientListItemProps) => {
    const { name, id, code, viewer, status = ClientStatus.Active, loading = false, numTradingPartners, businessAreaName, erpProductName, website, clickAction } = props;
    const [favIconErrored, setFavIconErrored] = useState<boolean>(false);

    const cardClick = () => {
        if (!loading) {
            clickAction(id)
        }
    };

    let chipContainerJustify = "flex-end";
    let tradingPartnersElement = null;
    if (numTradingPartners && numTradingPartners > 0) {
        chipContainerJustify = "space-between";
        tradingPartnersElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <PartnersIcon />
                <Typography>Partners: {numTradingPartners}</Typography>
            </Grid>
        )
    }

    let statusChip;
    let statusLabel = capitalize(status?.toString());
    if (status === ClientStatus.Active) {
        statusChip = <ActiveStatusChip label={statusLabel} />;
    }
    else if (status === ClientStatus.Suspended) {
        statusChip = <SuspendedStatusChip label={statusLabel} />;
    }
    else {
        statusChip = <StatusChip label={statusLabel} />;
    }

    let productChip;
    if (erpProductName && erpProductName.length > 0) {
        productChip = <CardChip label={erpProductName} />;
    }

    let chipsElement = (
        <Grid container item direction="row" gap={'8px'} justifyContent="flex-end" alignItems="center" xs={'auto'}>
            {productChip}
            {statusChip}
        </Grid>
    )

    let businessAreaLabel = (businessAreaName && businessAreaName.length > 0) ? ` | ${businessAreaName}` : '';
    let subHeading = `${code}${businessAreaLabel}`;

    const handlefavIconError = () => {
        console.log('favIconError!');
        setFavIconErrored(true);
    }

    let avatar = loading ? (
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
    ) : (
        getAvatarComponent(
            website,
            favIconErrored,
            name,
            "Client",
            <ClientIcon />,
            handlefavIconError
        )
    );

    const getFavorite = () => {
        // call utility method to get if this client is a favorite
        return getEntityFavorite(viewer?.favorites, id, EntityType.Client);
    }
    
    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={
                    loading ? (
                        <Skeleton animation="wave" height={32} width="40%" />
                    ) : (
                        name
                    )
                }
                subheader={
                    loading ? (
                        <Skeleton animation="wave" height={24} width="30%" />
                    ) : (
                        subHeading
                    )
                }
                avatar={avatar}
                //action prop to set or unset as favorite
                action={
                    loading ? (
                        <Skeleton animation="wave" height={24} width={24} />
                    ) : (
                        <FavoriteToggleButton
                            entityId={id as string}
                            entityName={name ?? ''}
                            entityType={EntityType.Client}
                            favorite={getFavorite()}
                        ></FavoriteToggleButton>
                    )
                }
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {tradingPartnersElement}
                            {chipsElement}
                        </>
                    )
                }
            </CardFooter>
        </ClickableRootCard>
    )
};

export default ClientListItem;