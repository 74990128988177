import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import TabPanel from "../../components/TabPanel";
import { PipelineItemModel, RequestResult, TransactionModel, UpsertPipelineInput } from "../../gql-types.generated";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, SuspendedStatusChip, TabHeader } from "../../util/SharedStyles";
import { fetchPipelineById, upsertPipeline } from "./PipelineDetailsActions";
import { clearError, clearState, selectError, selectPipeline, captureUpsertPipelineStatus, selectUpsertPipelineStatus } from "./PipelineDetailsSlice";
import { viewerCanEdit } from '../../util/ViewerUtility';
import { useTitle } from "../../util/Common";
import { getFormattedDateTimeString } from "../../util/DateTimeUtility";
import EditButton from '../../components/buttons/EditButton';
import { DateTime } from "luxon";
import PipelineDialog from "../../components/dialogs/PipelineDialog";
import PipelineItemsList from "../../components/lists/PipelineItemsList";
import DetailsPage from '../../components/DetailsPage';

interface PipelineDetailsProps {
    viewer: Viewer | undefined;
}

const PipelineDetails: React.FC<PipelineDetailsProps> = (props) => {
    const { pipelineId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);
    const [openEdit, setOpenEdit] = useState(false);
    const [createdBy, setCreatedBy] = useState<string | undefined>(undefined);
    const [createdTime, setCreatedTime] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [isLegacy, setIsLegacy] = useState<boolean>(false);
    const [lastModifiedBy, setLastModifiedBy] = useState<string | undefined>(undefined);
    const [lastModifiedTime, setLastModifiedTime] = useState<string | undefined>(undefined);
    const [transaction, setTransaction] = useState<TransactionModel | undefined>(undefined);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);

    const pipelineInfo = useAppSelector(selectPipeline);
    const error = useAppSelector(selectError);
    const upsertPipelineStatus = useAppSelector(selectUpsertPipelineStatus);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    const canEdit = viewerCanEdit(viewer);

    useEffect(() => {
        // get Pipeline on page entry with passed in pipelineId
        if (pipelineId) {
            dispatch(fetchPipelineById(pipelineId));
        }
        // on page exit, set state to defaults (undefined)
        return () => {
            dispatch(clearState());
        }
    }, []);

    useEffect(() => {
        if (pipelineInfo) {
            //set field data
            if (pipelineInfo.createdByName) {
                setCreatedBy(pipelineInfo.createdByName);
            }
            if (pipelineInfo.createdTime) {
                let formattedDate = getFormattedDateTimeString(pipelineInfo.createdTime, { format: DateTime.DATETIME_FULL });
                setCreatedTime(formattedDate);
            }
            if (pipelineInfo.description) {
                setDescription(pipelineInfo.description);
            }
            if (pipelineInfo.isTemplate) {
                setIsTemplate(pipelineInfo.isTemplate);
            }
            if (pipelineInfo.lastModifiedByName) {
                setLastModifiedBy(pipelineInfo.lastModifiedByName);
            }
            if (pipelineInfo.lastModifiedTime) {
                let formattedDate = getFormattedDateTimeString(pipelineInfo.lastModifiedTime, { format: DateTime.DATETIME_FULL });
                setLastModifiedTime(formattedDate);
            }
            if (pipelineInfo.transaction) {
                setTransaction(pipelineInfo.transaction);
            }
            if (pipelineInfo.transactionId) {
                setTransactionId(pipelineInfo.transactionId);
            }
            setIsLegacy(pipelineInfo.isLegacy ?? false);
        }
    }, [pipelineInfo]);

    useEffect(() => {
        if (upsertPipelineStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertPipelineStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertPipelineStatus());
            editDialogClose();
            if (pipelineId) {
                dispatch(fetchPipelineById(pipelineId));
            }
        }
    }, [upsertPipelineStatus?.result]);

    useTitle(description);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const refreshPipelineData = async () => {
        await dispatch(fetchPipelineById(pipelineId as string));
    }

    const onEditClick = () => {
        setOpenEdit(true);
    }

    const editDialogClose = () => {
        setOpenEdit(false);
        onDialogClose();
    }

    const editDialogSave = (saveInput: UpsertPipelineInput) => {
        dispatch(upsertPipeline(saveInput));
    }

    const onDialogClose = () => {
        dispatch(clearError());
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const getPipelineDetails = () => {
        if (pipelineInfo) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Date Created</Typography>
                            <Typography variant='body1' >{createdTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Created By</Typography>
                            <Typography variant='body1' >{createdBy}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Last Updated</Typography>
                            <Typography variant='body1' >{lastModifiedTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Updated By</Typography>
                            <Typography variant='body1' >{lastModifiedBy}</Typography>
                        </Grid>
                        { isLegacy && 
                        <Grid item xs={1}>
                            <Typography variant='caption' >Transaction</Typography>
                            <Typography variant='body1' >{transaction?.name}</Typography>
                            <Typography variant='body1' >{transaction?.direction}</Typography>
                        </Grid>
                        }
                    </Grid>
                </Grid>
            );
        }
    };

    const getTabbedContent = () => {
        if (pipelineInfo) {
            return (
                <DetailsTabContainer>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                            <TabHeader label="Pipeline Processes" id='vertical-tab-0' />
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>
                    <DetailsTabPanelContainer>
                        <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                            <PipelineItemsList 
                                viewerRole={viewer?.role} 
                                isLegacy={isLegacy}
                                pipelineId={pipelineId as string} 
                                pipelineItems={pipelineInfo.pipelineItems as PipelineItemModel[]} 
                                refreshPipelineData={refreshPipelineData} 
                            />
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </DetailsTabContainer>
            );
        }
    };

    const getDialogs = () => [
        <PipelineDialog
            isOpen={openEdit}
            pipeline={pipelineInfo}
            onClose={editDialogClose}
            onSave={editDialogSave}
            error={error}
            key="pipeline-dialog"
        />
    ];

    let legacyChip: undefined | JSX.Element;
    if (isLegacy === true) {
        legacyChip = <SuspendedStatusChip label="Is Legacy" key="legacy-chip" />;
    }

    useEffect(() => {
        setDetailsRecordLoaded(!!pipelineInfo);
        if (pipelineInfo) {
            setDetailsPageTitle(description);
            setDetailsHeader(description);
            setDetailsSubHeader(transaction?.name);
            setDetailsChips(legacyChip ? [legacyChip] : undefined);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Pipeline button"
                    data-cy="edit-pipeline-button"
                ></EditButton>);
            }
        }
    }, [pipelineInfo, description, transaction, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getPipelineDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
}

export default PipelineDetails;