import { AppBar, Toolbar, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../Theme';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import FilterIcon from '@mui/icons-material/FilterList';

interface barProps {
    barheight?: string;
}

const Bar = styled(AppBar)<barProps>((props) => ({
    height: props.barheight,
    zIndex: 1,
    color: theme.palette.grayscale.dark,
    backgroundColor: theme.palette.background.subbar,
    border: `solid 1px ${theme.palette.border.main}`,
    boxShadow: '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 2px 3px 0px rgb(0 0 0 / 14%), 0px 1px 10px -10px rgb(0 0 0 / 12%)'
}));
Bar.defaultProps = {
    barheight: '60px',
};

const FilterToolbar = styled(Toolbar)((props) => ({
    justifyContent: 'space-between',
    height: '100%',
}));

const FilterBarContainer = styled(Grid)((props) => ({
    alignItems: 'center',
    zIndex: 1,
    width: '100% !important', // override default Grid width
    marginLeft: '0px !important', //remove default Grid left margin
    marginTop: '0px !important', //remove default Grid top margin
}));

interface FilterBarProps {
    children?: React.ReactNode;
    id?: string;
    visible: boolean;
    barHeight?: string;
    onClose: () => void;
    onSearch?: () => void;
}

const FilterBar: React.FC<FilterBarProps> = props => {
    const { id, visible=false, barHeight='60px' } = props;
    
    const isManualSearchVisible = (props.onSearch !== undefined);

    const onCloseClick = () => {
        props.onClose();
    }

    const closeButton = (
        < IconButton
            aria-label="clear and reset filters button"
            id="close-button"
            size="large"
            onClick={onCloseClick}
            title="Clear and reset default filters"
        >
            <CloseIcon />
        </IconButton >
    );

    const onSearchClick = () => {
        if (props.onSearch) {
            props.onSearch();
        }
    }

    const searchButton = (
        < IconButton
            aria-label="search"
            id="search-button"
            size="large"
            color="primary"
            onClick={onSearchClick}
            title="Search using current filters"
        >
            <SearchIcon />
        </IconButton >
    );
   
    let visibilityState = visible ? "flex" : "none";

    return (
        <FilterBarContainer container spacing={2} id={id} sx={{display: visibilityState}}>
            <Bar position="static" barheight={barHeight}>
                <FilterToolbar variant='dense'>
                    <FilterIcon fontSize="large" />
                    <Grid container item gap={'8px'} justifyContent={'flex-end'} alignItems='center' >
                        {props.children}
                    </Grid>
                    {isManualSearchVisible && searchButton}
                    {closeButton}
                </FilterToolbar>
            </Bar>
        </FilterBarContainer>
    );
};

export default FilterBar;