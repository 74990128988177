import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdateClientPartnerInput, UpdateClientPartnerPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_CLIENT_PARTNER = gql`
    mutation updateClientPartner($input: UpdateClientPartnerInput!){
        updateClientPartner(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpdateClientPartner = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        clientPartnerData: UpdateClientPartnerInput
    }
): Promise<UpdateClientPartnerPayload | undefined> => {
    const { clientPartnerId, defaultCurrencyCode } = args.clientPartnerData;

    const { data, errors } = await client.mutate<{ updateClientPartner: UpdateClientPartnerPayload }>({
        variables: {
            input: {
                clientPartnerId, 
                defaultCurrencyCode, 
            }
        },
        mutation: MUTATION_UPDATE_CLIENT_PARTNER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the client partner save.');
    }
    if (data && data.updateClientPartner) {
        return data.updateClientPartner;
    }
    return undefined;
};