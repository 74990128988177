import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { NotificationFormatterTransactionsByTransactionIdQuery, GetNotificationFormatterTransactionsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_NOTIFICATION_FORMATTER_TRANSACTIONS_BY_TRANSACTION_ID = gql`
    query notificationFormatterTransactionsByTransactionId($input: GetNotificationFormatterTransactionsInput!) {
        notificationFormatterTransactions (input: $input) {
            nodes {
                id
                notificationFormatterId
                notificationFormatter {
                    id
                    description
                }
                transactionId
            }
        }
    }
`;

export const queryNotificationFormatterTransactionsByTransactionId = async (
    client: ApolloClient<NormalizedCacheObject>,
    transactionId?: string | undefined
) => {
    const { data, errors } = await client.query<NotificationFormatterTransactionsByTransactionIdQuery>({
        variables: {
            input: {
                transactionId,
            }
        },
        query: QUERY_NOTIFICATION_FORMATTER_TRANSACTIONS_BY_TRANSACTION_ID,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.notificationFormatterTransactions?.nodes;
};