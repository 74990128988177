import { useState } from 'react';
import { Divider, Grid, IconButton, Skeleton, Menu, MenuItem, Typography } from '@mui/material';
import { TransactionMapModel } from '../../gql-types.generated';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PartnersIcon from "@mui/icons-material/SafetyDivider";
import { ActiveStatusChip, StatusChip, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';

interface MapListItemProps {
    loading?: boolean | false;
    map?: TransactionMapModel | undefined;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const MapListItem: React.FC<MapListItemProps> = (props: MapListItemProps) => {
    const { map, loading = false } = props;
    const standardVersion = map?.standardVersion;
    const description = map?.description;
    const isLoaded = map?.isMapLoaded;
    const isDeployed = map?.isMapDeployed;
    const transactionName = map?.transaction?.name;
    const partnerCount = map?.totalPartners;
    const id = map?.id as string;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (id) {
            props.clickAction(id);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        let buttonDataSet = (event.target as HTMLElement).dataset;

        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete") {
            props.onDeleteClick(id);
        }

        setAnchorEl(null);
    };

    let action = loading ? undefined : (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        description
    );

    let subheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
    ) : (
        `Transaction: ${transactionName}, Version: ${standardVersion ?? ''}`
    );

    let loadedChip;
    if (isLoaded) {
        loadedChip = <StatusChip label="Loaded" />;
    }

    let deployedChip;
    if (isDeployed) {
        deployedChip = <ActiveStatusChip label="Deployed" />;
    }

    let chipsElement = (
        <Grid container item direction="row" gap={'8px'} justifyContent="flex-end" alignItems="center" xs={'auto'}>
            {loadedChip}
            {deployedChip}
        </Grid>
    )

    let chipContainerJustify = "flex-end";
    let partnersElement = null;
    if (partnerCount && partnerCount > 0) {
        chipContainerJustify = "space-between";
        partnersElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <PartnersIcon />
                <Typography>Partners: {partnerCount}</Typography>
            </Grid>
        )
    }
    
    const deleteDisabled = ((partnerCount && partnerCount > 0) || isDeployed);

    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={title}
                subheader={subheader}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {partnersElement}
                            {chipsElement}
                        </>
                    )
                }
            </CardFooter>

            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    disabled={deleteDisabled}
                >
                    Delete
                </MenuItem>
            </Menu>
        </ClickableRootCard>
    );
};

export default MapListItem;