import { ChangeEvent, useEffect, useState } from "react";
import { CrossReferenceModel, UpsertCrossReferenceInput } from "../../gql-types.generated";
import { Grid, styled, TextField } from "@mui/material";
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface DialogProps {
    isOpen: boolean;
    crossReference?: CrossReferenceModel | null | undefined;
    onClose: () => void;
    onSave: (crossRef: UpsertCrossReferenceInput) => void;
    error?: Error | undefined;
}

const CrossReferenceDialog: React.FC<DialogProps> = props => {
    const { isOpen, crossReference, onClose, onSave, error } = props;
    const id = crossReference?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [code, setCode]= useState<string>('');
    const [description, setDescription]= useState<string>('');
    const [val, setVal]= useState<string>('');
    const [version, setVersion]= useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    useEffect(() => {
        if (crossReference){
            setFromCrossReference();
        } else {
            setToDefaults();
        }
    }, [crossReference]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
        else {
            setFromCrossReference();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setCode('');
        setDescription('');
        setVal('');
        setVersion('');
    }

    const setFromCrossReference = () => {
        if (crossReference) {
            if (crossReference.code) {
                setCode(crossReference.code);
            }
            if (crossReference.description) {
                setDescription(crossReference.description);
            }
            if (crossReference.val) {
                setVal(crossReference.val);
            }
            if (crossReference.version) {
                setVersion(crossReference.version);
            }
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && code?.trim().length > 0 && description?.trim().length > 0 && val?.trim().length > 0 ;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            code,
            description,
            id,
            val,
            version
        } as UpsertCrossReferenceInput);
    };

    // onChange handlers
    const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setCode(event.target.value);
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onValChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setVal(event.target.value);
    };
    const onVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setVersion(event.target.value);
    };
    
    // property props
    const codeProps = {        
        'aria-label': 'code',
        'maxLength': 50,
    };
    const descriptionProps = {        
        'aria-label': 'description',
        'maxLength': 100,
    };
    const valProps = {        
        'aria-label': 'val',
        'maxLength': 50,
    };
    const versionProps = {        
        'aria-label': 'version',
        'maxLength': 10,
    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Cross Reference Entry"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-cross-reference-code"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={code}
                        label="Code"
                        inputProps={codeProps}
                        onChange={onCodeChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-cross-reference-code"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-cross-reference-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-cross-reference-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-cross-reference-val"
                        fullWidth
                        disabled={submitted}
                        value={val}
                        label="Val"
                        inputProps={valProps}
                        onChange={onValChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-cross-reference-val"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-cross-reference-version"
                        fullWidth
                        disabled={submitted}
                        value={version}
                        label="Version"
                        inputProps={versionProps}
                        onChange={onVersionChange}
                        autoComplete="off"
                        data-cy="dialog-cross-reference-version"
                        variant="standard"
                    />
                </Grid>
            </GridContainer>
        </AddEditDialog>
    )
};

export default CrossReferenceDialog;