import { emptyGuid } from "./Constants";

export const isEmailValid = (emailString: string) => {
    if (!emailString) {
        return true;
    }
    const matches = emailString ? emailString.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) || [] : [];
    return (matches.length > 0);
}

export const isURLValid = (website: string) => {
    if (website) {
        website = website.trim();
        if (website) {
            const regex = /(?:<\w+.*?>|[^=!:'"/]|^)((?:https?:\/\/|www\.)[-\w]+(?:\.[-\w]+)*(?::\d+)?(?:\/(?:(?:[~\w\+%-]|(?:[,.;@:][^\s$]))+)?)*(?:\?[\w\+%&=.;:-]+)?(?:\#[\w\-\.]*)?)(?:\p{P}|\s|<|$)/g;
            return regex.test(website);
        }
        return false;
    }
    // empty is allowed
    return true;
}

export const isGuidValid = (guid: string, passIfEmpty = false) => {
    if (guid) {
        guid = guid.trim();
        if (passIfEmpty) {
            if (guid && guid === emptyGuid) {
                return true;
            }
        }
        if (guid) {
            const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
            return regex.test(guid);
        }
        return false;
    }
    // undefined/null is not allowed 
    return false;
}