import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router';
import { Dialog } from '@mui/material';
import { CardListItemWrapperGrid, CardListContentGrid, CardListScrollBox, MainContentBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import { PipelineModel, RequestResult, UpsertPipelineInput } from '../../gql-types.generated';
import { selectError, clearError, selectPipelineList, selectUpsertPipelineStatus, selectDeletePipelineStatus, selectClonePipelineStatus, captureUpsertPipelineStatus, captureDeletePipelineStatus, captureClonePipelineStatus } from './PipelinesSlice';
import { fetchPipelineList, upsertPipeline, deletePipeline, clonePipeline } from './PipelinesActions';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete, viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import PipelineDialog from '../../components/dialogs/PipelineDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ConfirmationPrompt from '../../components/ConfirmationPrompt';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import ErrorMessage from '../../components/ErrorMessage';
import PipelineListItem from '../../components/listItems/PipelineListItem';

interface PipelinesProps {
    viewer: Viewer | undefined;
}

const Pipelines: React.FC<PipelinesProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openCloneConfirm, setOpenCloneConfirm] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [cloneErrorMessage, setCloneErrorMessage] = useState('');
    const [isCloning, setIsCloning] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState<undefined | PipelineModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    
    const deletePipelineStatus = useAppSelector(selectDeletePipelineStatus);
    const upsertPipelineStatus = useAppSelector(selectUpsertPipelineStatus);
    const clonePipelineStatus = useAppSelector(selectClonePipelineStatus);
    const error = useAppSelector(selectError);

    const pipelines = useAppSelector(selectPipelineList);

    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Pipelines");

    useEffect(() => {
        dispatch(fetchPipelineList());
    }, []);

    useEffect(() => {
        if (upsertPipelineStatus?.result === RequestResult.Success) {
            let wasAddMode = openAdd;
           
            // close the modify dialog
            onPipelineDialogClose();

            dispatch(setToastConfig({
                message: upsertPipelineStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertPipelineStatus());

            // if coming from adding new, and a pipeline id was successfully returned, navigate 
            // to the new Pipeline details to allow user to 
            // add items right away
            if (wasAddMode && upsertPipelineStatus.pipeline?.id) {
                // simulate a card click to navigate
                cardClickAction(upsertPipelineStatus.pipeline.id);
            }
        }
    }, [upsertPipelineStatus?.result]);

    useEffect(() => {
        if (deletePipelineStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deletePipelineStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deletePipelineStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePipelineStatus.message as string);
        }
    }, [deletePipelineStatus?.result]);

    useEffect(() => {
        if (clonePipelineStatus?.result === RequestResult.Success) {
            // close the clone dialog
            onCloneDialogClose();
            // get the newly cloned pipeline and open it right away for edit
            if (clonePipelineStatus?.pipeline) {
                setSelectedPipeline(clonePipelineStatus.pipeline);
                setOpenEdit(true);
            }
        }
        else if (clonePipelineStatus?.result === RequestResult.Fail) {
            setIsCloning(false);
            setCloneErrorMessage(clonePipelineStatus.message as string);
        }
    }, [clonePipelineStatus?.result]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('pipelines-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [pipelines]);

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/pipeline/" + id;
            navigate(route);
        }
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load Pricing' error={error}></ErrorMessage>
            );
        }

        if (pipelines && pipelines.length === 0) {
            // No areas to show 
            return (canEdit ? <NoRecordsMessage actionButtonText="Add New Pipeline" actionButtonClick={onAddPipelineClick} />
                :
                <NoRecordsMessage message="" />
            );
        } else if (pipelines && pipelines.length) {
            return (
                pipelines.map((pipeline: PipelineModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={pipeline.id}>
                        <PipelineListItem
                            pipeline={pipeline}
                            canAddOrDelete={canAddOrDelete}
                            canEdit={canEdit}
                            onEditClick={editPipelineHandler}
                            onDeleteClick={deletePipelineHandler}
                            onCloneClick={clonePipelineHandler}
                            clickAction={cardClickAction}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'pipeline-skeleton-' + i;
                loaders.push(
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <PipelineListItem 
                            loading={true} 
                            onEditClick={editPipelineHandler} 
                            onDeleteClick={deletePipelineHandler} 
                            onCloneClick={clonePipelineHandler} 
                            clickAction={cardClickAction}
                        />
                    </CardListItemWrapperGrid>
                )
            }
            return loaders;
        }
    };


    const getSelectedPipeline = (id: string | undefined) => {
        if (id && pipelines?.length) {
            let pipeline = pipelines.find(pipeline => pipeline.id === id);
            return pipeline;
        }
        return undefined;
    };

    const deletePipelineHandler = (id: string) => {
        let pipeline = getSelectedPipeline(id);
        if (pipeline) {
            setSelectedPipeline(pipeline);
            setOpenDelete(true);
        }
    };

    const editPipelineHandler = (id: string | undefined) => {
        let pipeline = getSelectedPipeline(id);
        if (pipeline) {
            setSelectedPipeline(pipeline);
            dispatch(clearError());
            setOpenEdit(true);
        }
    };

    const clonePipelineHandler = (id: string) => {
        let pipeline = getSelectedPipeline(id);
        if (pipeline) {
            setSelectedPipeline(pipeline);
            dispatch(clearError());
            setOpenCloneConfirm(true);
        }
    };
 
    const onAddPipelineClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected area is set
        if (selectedPipeline) {
            setSelectedPipeline(undefined);
        }
        setOpenAdd(true);
    };

    const onPipelineDialogClose = () => {
        setOpenEdit(false);
        setOpenAdd(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeletePipelineStatus());
        setDeleteErrorMessage('');
    };

    const onCloneDialogClose = () => {
        setOpenCloneConfirm(false);
        onDialogClose();
        dispatch(captureClonePipelineStatus());
        setIsCloning(false);
        setCloneErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedArea on close.
        dispatch(clearError());
        setSelectedPipeline(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchPipelineList());
    };

    const onPipelineDialogSave = (saveInput: UpsertPipelineInput) => {
        // upsert to save data
        dispatch(upsertPipeline(saveInput));
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete the selected pipeline
        dispatch(deletePipeline(id));
    };

    const onClonePipelineConfirm = () => {
        // clone the selected pipeline
        if (selectedPipeline && selectedPipeline.id) {
            setIsCloning(true);
            dispatch(clonePipeline(selectedPipeline.id));
        }
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Pipelines' id="pipelines-title-comp">
                <PageTitleToolbarGrid item >
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Pipeline"
                            onClick={onAddPipelineClick}
                            data-cy="add-new-pipeline"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <PipelineDialog
                isOpen={openEdit || openAdd}
                pipeline={selectedPipeline}
                onClose={onPipelineDialogClose}
                onSave={onPipelineDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedPipeline?.id}
                heading={'Delete Pipeline'}
                message={'Are you sure you want to delete \'' + selectedPipeline?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
            <Dialog
                open={openCloneConfirm}>
                <ConfirmationPrompt
                    heading="Clone Pipeline"
                    message={'Are you sure you want to create a duplicate of \'' + selectedPipeline?.description + '\'?'}
                    handleConfirm={onClonePipelineConfirm}
                    handleReject={onCloneDialogClose}
                    isBusy={isCloning}
                    errorMessage={cloneErrorMessage}
                />
            </Dialog>
        </MainContentBox>
    );
};

export default Pipelines;