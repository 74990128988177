import { Alert, AlertTitle, Button, Chip, CircularProgress, Dialog, DialogActions, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, styled, TextField, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ChangeEvent, useEffect, useState } from "react";
import { useDropzone, FileRejection, FileError } from 'react-dropzone';
import { PaddedDialogContent } from "../../util/SharedStyles";
import DialogCloseButton from "./DialogCloseButton";
import { isURLValid } from "../../util/Validation";
import { maxAttachingFiles, maxFileSize } from "../../util/Constants";
import { getBase64 } from "../../util/Common";
import ConfirmationPrompt from "../ConfirmationPrompt";
import { UpsertAttachmentInput, Maybe, Scalars } from "../../gql-types.generated";
import theme from "../../Theme";

const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));

const FileChip = styled(Chip)((props) => ({
    backgroundColor: theme.palette.toast.main,
    borderColor: theme.palette.toast.contrastText,
    borderWidth: '1px',
    borderStyle: 'solid',
    color: theme.palette.toast.contrastText,
    marginRight: 5,
    marginBottom: 5,
}));

const LinkChip = styled(Chip)((props) => ({
    backgroundColor: theme.palette.toast.main,
    marginBottom: 5,
    borderRadius: '3px',
    width: '100%',
    justifyContent: 'space-between',
    height: 40,
}));

const LinkChipText = styled('div')((props) => ({
    color: theme.palette.toast.contrastText,
    fontWeight: 'bold',
    fontSize: '0.85em',
}));

const LinkChipUrl = styled('div')((props) => ({
    color: theme.palette.grayscale.dark,
}));

const RejectionChip = styled(LinkChip)((props) => ({
    backgroundColor: 'transparent',
    borderColor: theme.palette.error.main,
    borderWidth: '1px',
    borderStyle: 'solid',
}));

const RejectionChipText = styled(LinkChipText)((props) => ({
    color: theme.palette.error.main,
}));


const ChipPaper = styled(Paper)((props) => ({
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
}));

const DropZoneOverlayText = styled('div')((props) => ({
    padding: '0px 24px',
    textAlign: 'center',
    alignContent: 'center',
}));

const BrowseText = styled('span')((props) => ({
    color: theme.palette.primary.main,
}));

const UploadCloud = styled(CloudUploadIcon)((props) => ({
    color: theme.palette.primary.main,
    width: '100%',
    fontSize: 60,
}));

const DropSection = styled(Grid)((props) => ({
    minHeight: 'unset',
    border: '1px dashed #00000099',
    borderRadius: 4,
    backgroundColor: '#FAFAFF',
    marginLeft: 16,
    paddingLeft: '0px !important',
    paddingTop: '16px !important',
    paddingBottom: '16px !important',
}));

const AddLinkButton = styled(Button)((props) => ({
    marginRight: 'auto',
}));

interface AttachmentsDialogProps {
    isOpen: boolean;
    isSubmitted: boolean;
    onClose: () => void;
    onSave: (attachments: UpsertAttachmentInput[]) => void;
    onError: () => void;
    error?: Error | undefined;
}

type LinkAttachment = {
    __typename?: 'LinkAttachment';
    text?: Maybe<Scalars['String']>;
    link: Scalars['String'];
};

const AttachmentsDialog: React.FC<AttachmentsDialogProps> = props => {
    const { isOpen, isSubmitted, onClose, onSave, onError, error } = props;
    const [attachmentType, setAttachmentType] = useState<string>("file");
    const [linkTitle, setLinkTitle] = useState<string>("");
    const [linkAddress, setLinkAddress] = useState<string>("");
    const [websiteErrorText, setWebsiteErrorText] = useState('');
    const [websiteInvalid, setWebsiteInvalid] = useState(false);
    const [filesToAttach, setFilesToAttach] = useState<File[]>([]);
    const [filesToReject, setFilesToReject] = useState<FileRejection[]>([]);
    const [linksToAttach, setLinksToAttach] = useState<LinkAttachment[]>([]);
    const [clearConfirmationOpen, setClearConfirmationOpen] = useState(false);

    const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
        maxFiles: maxAttachingFiles,
        maxSize: maxFileSize,
    });

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
        }
    }, [isOpen]);

    useEffect(() => {
        if (error) {
            onError();
            return;
        }
        // Error value can be stale if the effect was ran via a different dependency, must also have a success message to close.
        if (!error && isSubmitted) {
            onCancelClick();
        }
    }, [error]);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            // purge any duplicate named files that might already be in filesToAttach
            let filteredAcceptedFiles = filterOutDuplicateFiles(acceptedFiles);
            let newArr = filesToAttach.concat(filteredAcceptedFiles);
            setFilesToAttach(newArr);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections && fileRejections.length > 0) {
            // add rejected files to array for display;
            let newArr = filesToReject.concat(fileRejections);
            setFilesToReject(newArr);
        }
    }, [fileRejections]);

    const setToDefaults = () => {
        setAttachmentType("file");
        setLinkTitle("");
        setLinkAddress("");
        setFilesToAttach([]);
        setFilesToReject([]);
        setLinksToAttach([]);
    };

    const filterOutDuplicateFiles = (acceptedFiles: File[]) => {
        // purge any duplicate named files and move the duplicates 
        // to filesToReject to display an error message as to why it wasn't added
        let existingFileNames: string[] = [];
        filesToAttach.forEach(f => existingFileNames.push(f.name));

        let fileDuplicates = acceptedFiles.filter(f => existingFileNames.indexOf(f.name) >= 0);
        if (fileDuplicates.length > 0) {
            setDuplicateFilesAsRejected(fileDuplicates);
            // return filtered files without duplicates
            return acceptedFiles.filter(f => fileDuplicates.indexOf(f) < 0);
        }
        // no duplicates so return all files
        return acceptedFiles;
    };

    const setDuplicateFilesAsRejected = (duplicateFiles: File[]) => {
        if (duplicateFiles && duplicateFiles.length > 0) {
            let rejections = duplicateFiles?.map((file) => {
                let error: FileError = { message: "Duplicate file name.", code: "DUP" };
                return {
                    file: file,
                    errors: [error]
                } as FileRejection;
            }) as FileRejection[];

            let newArray = filesToReject.concat(rejections);
            setFilesToReject(newArray);
        }
    };

    const submitAttachments = async () => {
        let attachmentInputs: UpsertAttachmentInput[] = [];
        if (filesToAttach && filesToAttach.length > 0) {
            attachmentInputs = await Promise.all(filesToAttach.map(mapFileToAttachmentInput));
        }
        else if (linksToAttach && linksToAttach.length > 0) {
            attachmentInputs = mapLinksToAttachmentInput();
        }

        if (attachmentInputs && attachmentInputs.length > 0) {
            onSave(attachmentInputs);
        }
    };

    const mapFileToAttachmentInput = async (file: File) => {

        const { name, lastModified } = file;

        let fileContentBase64 = "";
        let mimeType = "";
        let lastModifiedDate = lastModified ? new Date(lastModified).toISOString() : null;

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                let fileTypeData = resultParts[0] ?? '';
                mimeType = fileTypeData.substring(fileTypeData.indexOf('data:') + 5, fileTypeData.lastIndexOf(";"));
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return {
            isLink: false,
            fileName: name,
            fileLastModifiedTime: lastModifiedDate,
            mimeType: mimeType,
            attachmentString: fileContentBase64,
        } as UpsertAttachmentInput;
    };


    const mapLinksToAttachmentInput = () => {
        let attachmentInputs = linksToAttach?.map((linkToAttach) => {
            const { link, text } = linkToAttach;
            return {
                isLink: true,
                fileName: link,
                description: text
            } as UpsertAttachmentInput;
        }) as UpsertAttachmentInput[];

        return attachmentInputs;
    };

    const onCancelClick = () => {
        setToDefaults();
        onClose();
    };

    const onAddLinkClick = () => {
        if (attachmentType === "link") {
            if (linkAddress && !websiteInvalid) {
                // TODO purge any duplicate named files (show error);
                const linkArr = [];
                const linkText = linkTitle || undefined;
                linkArr.push({ text: linkText, link: linkAddress } as LinkAttachment);
                let newArr = linksToAttach.concat(linkArr);
                setLinksToAttach(newArr);
                setLinkAddress('');
                setLinkTitle('');
            }
        }
    };

    const isFormValid = () => {
        let filesValid = filesToAttach && filesToAttach.length > 0;
        let linksValid = linksToAttach && linksToAttach.length > 0;
        return (filesValid || linksValid);
    };

    const checkLinkData = () => {
        if (linkTitle !== "" || linkAddress !== "" || (linksToAttach && linksToAttach.length)) {
            // Warn user about input loss
            setClearConfirmationOpen(true);
        }
    };

    const clearLinkData = () => {
        setLinkAddress("");
        setLinkTitle("");
        setLinksToAttach([] as LinkAttachment[]);
    };

    const checkFileData = () => {
        if (filesToAttach && filesToAttach.length) {
            // Warn user about upload loss            
            setClearConfirmationOpen(true);
        }
    };

    const clearFileData = () => {
        setFilesToAttach([] as File[]);
        setFilesToReject([] as FileRejection[]);
    };

    const confirmTypeSwitch = () => {
        if (attachmentType === 'file') {
            clearLinkData();
        } else if (attachmentType === 'link') {
            clearFileData();
        }
        setClearConfirmationOpen(false);
    };

    const rejectTypeSwitch = () => {
        if (attachmentType === 'file') {
            setAttachmentType('link');
        } else if (attachmentType === 'link') {
            setAttachmentType('file');
        }
        setClearConfirmationOpen(false);
    };

    const onTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const type = event.target.value;
        if (type === 'file') {
            checkLinkData();
        } else if (type === 'link') {
            checkFileData();
        }
        setAttachmentType(type);
    };

    const nameLabelProps = {
        'aria-label': 'name',
        'maxLength': 50,
    };
    const websiteProps = {
        'aria-label': 'link',
        'maxLength': 255,
    };

    const onLinkTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLinkTitle(event.target.value);
    };

    const validateAndSetURL = (toValidate: string) => {
        if (toValidate || toValidate === "") {
            const isValid = isURLValid(toValidate);
            setWebsiteInvalid(!isValid);
            if (isValid) {
                setWebsiteErrorText('');
            } else {
                setWebsiteErrorText('Invalid format.  Enter a valid URL.');
            }
            setLinkAddress(toValidate);
        }
    };

    const onLinkAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
        validateAndSetURL(event.target.value);
    };

    const getInnerContent = () => {
        if (attachmentType === "file") {
            return getDropZoneContent();
        } else if (attachmentType === "link") {
            return getLinkContent();
        }
    };

    const getDropZoneContent = () => {
        return (
            <Grid container spacing={2}>
                <DropSection item xs={12}>
                    <div {...getRootProps()}>
                        <input data-cy="file-upload-input" {...getInputProps()} />
                        <div>
                            <UploadCloud fontSize={'large'} />
                            <div>
                                <DropZoneOverlayText>
                                    <div>
                                        <span >
                                            Drop your file here or <BrowseText >Browse</BrowseText>
                                        </span>
                                    </div>
                                </DropZoneOverlayText>
                            </div>
                        </div>
                    </div>
                </DropSection>
                <Grid item xs={12}>
                    {getFileChips()}
                </Grid>
                <Grid item xs={12}>
                    {getFileRejectionChips()}
                </Grid>
            </Grid >
        );
    };

    const onFileChipDelete = (file: File) => {
        // for advanced logic around maintaining the file list
        if (file && filesToAttach) {
            let newArray = filesToAttach.filter((x) => { return x.name !== file.name });
            if (newArray && newArray.length < filesToAttach.length) {
                setFilesToAttach(newArray);
            }
        }
    };

    const onFileRejectChipDelete = (fileReject: FileRejection) => {
        // in case user doesn't want to see their rejected file anymore, allow removal
        if (fileReject && filesToReject) {
            let newArray = filesToReject.filter((x) => { return x.file.name !== fileReject.file.name });
            if (newArray && newArray.length < filesToReject.length) {
                setFilesToReject(newArray);
            }
        }
    };

    const getFileChips = () => {
        return (
            <ChipPaper elevation={0} >
                {filesToAttach.map((file: File) => (
                    <Grid key={file.name}>
                        <FileChip label={file.name} onDelete={() => onFileChipDelete(file)} />
                    </Grid>
                ))}
            </ChipPaper>
        );
    };

    const getFileRejectionChips = () => {
        return (
            < Paper elevation={0} >
                {filesToReject.map((fileReject: FileRejection) => (
                    <Grid key={fileReject.file.name}>
                        <RejectionChip
                            label={<div><RejectionChipText>{`${fileReject.file.name}: ${fileReject.errors[0].message}`}</RejectionChipText></div>}
                            onDelete={() => onFileRejectChipDelete(fileReject)}
                        />
                    </Grid>
                ))}
            </Paper>
        );
    };

    const getLinkContent = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-attachment-link-name"
                        fullWidth
                        value={linkTitle}
                        label="Link Name"
                        inputProps={nameLabelProps}
                        onChange={onLinkTitleChange}
                        autoComplete="off"
                        data-cy="dialog-attachment-link-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-attachment-link-address"
                        fullWidth
                        value={linkAddress}
                        label="Link URL"
                        inputProps={websiteProps}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LinkIcon />
                                </InputAdornment>
                            ),
                        }}
                        onChange={onLinkAddressChange}
                        autoComplete="off"
                        data-cy="dialog-attachment-link-address"
                        variant="standard"
                        error={websiteInvalid}
                        helperText={websiteErrorText}
                    />
                </Grid>
                <Grid item xs={12}>
                    {getLinkChips()}
                </Grid>
            </Grid>
        );
    };

    const onLinkChipDelete = (link: LinkAttachment) => {
        // for advanced logic around maintaining the file list
        if (linksToAttach && link) {
            let newArray = linksToAttach.filter((x) => { return x.link !== link.link });

            if (newArray && newArray.length < linksToAttach.length) {
                setLinksToAttach(newArray);
            }
        }
    };

    const getLinkChips = () => {
        return (
            < Paper elevation={0} >
                {linksToAttach.map((link: LinkAttachment) => (
                    <Grid key={link.link}>
                        <LinkChip
                            label={<div><LinkChipText>{link.text}</LinkChipText><LinkChipUrl>{link.link}</LinkChipUrl></div>}
                            onDelete={() => onLinkChipDelete(link)}
                        />
                    </Grid>
                ))}
            </Paper>
        );
    };

    return (
        <Dialog
            aria-label="New Attachment"
            maxWidth='sm'
            open={isOpen}
            scroll="paper"
        >
            <TitleBar id='attachments-dialog-title'>
                <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11} >
                    <Typography variant="body1">New Attachments</Typography>
                </TitleGrid>
                <DialogCloseButton onClick={onClose} />
            </TitleBar>
            <PaddedDialogContent dividers>
                {error && (
                    <Alert severity="error">
                        <AlertTitle>Unable to Add Attachment</AlertTitle>
                        {error.message}
                    </Alert>
                )}

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl>
                            <FormLabel id="dialog-attachment-type-group-label">Attachment Type</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="dialog-attachment-type-group-label"
                                name="dialog-attachment-type-group"
                                onChange={onTypeChange}
                                value={attachmentType}
                            >
                                <FormControlLabel value="file" control={<Radio />} label="File" />
                                <FormControlLabel value="link" control={<Radio />} label="Link" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {getInnerContent()}
                    </Grid>
                </Grid>
            </PaddedDialogContent>
            <DialogActions>
                {attachmentType === "link" &&
                    <AddLinkButton
                        onClick={onAddLinkClick}
                        disabled={isSubmitted || websiteInvalid}
                        data-cy="dialog-attachment-cancel"
                        variant="outlined"
                    >
                        Add Link
                    </AddLinkButton>}
                <Button
                    onClick={onCancelClick}
                    disabled={isSubmitted}
                    data-cy="dialog-attachment-cancel"
                    variant="outlined"
                >
                    CANCEL
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={submitAttachments}
                    disabled={!isFormValid() || isSubmitted}
                    data-cy="dialog-attachment-save"
                >
                    SAVE
                </Button>
            </DialogActions>
            {(isSubmitted) &&
                <CircularProgress
                    aria-label={'progress spinner'}
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-24px',
                        marginLeft: '-24px',
                    }}
                />
            }
            <Dialog
                open={clearConfirmationOpen}>
                <ConfirmationPrompt
                    heading="Data Loss"
                    message="Switching between types will clear any data for this type."
                    handleConfirm={confirmTypeSwitch}
                    handleReject={rejectTypeSwitch}
                />
            </Dialog>
        </Dialog>
    )
};

export default AttachmentsDialog;