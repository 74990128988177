import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { TransactionModel, RequestResult, UpsertTransactionInput, UpsertTransactionXPathInput } from '../../gql-types.generated';
import { 
    selectTransaction,
    selectTransactionList, 
    selectError, 
    selectXPathError,
    clearError, 
    clearState, 
    selectDeleteTransactionStatus, 
    selectUpsertTransactionStatus, 
    captureUpsertTransactionStatus, 
    captureDeleteTransactionStatus, 
    selectRequestsInFlight,
    captureUpsertTransactionXPathStatus, 
    selectUpsertTransactionXPathStatus
} from './TransactionsSlice';
import { fetchTransactionById, fetchTransactionList, upsertTransactionData, deleteTransactionData, upsertTransactionXPath, deleteTransactionXPath } from './TransactionsActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import TransactionListItem from '../../components/listItems/TransactionListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import TransactionDialog from '../../components/dialogs/TransactionDialog';
import TransactionXPathDialog from '../../components/dialogs/TransactionXPathDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import TransactionsFilterBar from '../../components/filters/TransactionsFilterBar';


const WrappedLoadingGrid = styled(Grid)((props) => ({
    paddingTop: '16px',
    width: '100% !important',
    marginLeft: '0px !important',
}));

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids

interface TransactionsProps {
    viewer: Viewer | undefined;
}

const Transactions: React.FC<TransactionsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openXPathDialog, setOpenXPathDialog] = useState<boolean>(false);
    const [editClicked, setEditClicked] = useState(false);
    const [xPathClicked, setXPathClicked] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedTransaction, setSelectedTransaction] = useState<undefined | TransactionModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterStandardIds, setFilterStandardIds] = useState<string[]>([]);
    const [filterCount, setFilterCount] = useState(0);

    const activeTransaction = useAppSelector(selectTransaction);
    const deleteTransactionStatus = useAppSelector(selectDeleteTransactionStatus);
    const upsertTransactionStatus = useAppSelector(selectUpsertTransactionStatus);
    const upsertTransactionXPathStatus = useAppSelector(selectUpsertTransactionXPathStatus);
    const error = useAppSelector(selectError);
    const xPathError = useAppSelector(selectXPathError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Transactions");

    // Normally we would have the initial fetch, but the filterbar hasn't loaded/pulled from local storage yet.
    // If we fetch here and there are filters in storage we will fire two requests, the last to return would overwrite the first.
    // TransactionFilterBar is equipped to evaluate local storage and then kick off a request if any filters are there or not.
    // Leaving this here and commented out in the case this query returns paged results in the future (currently not taking limit/after/before in input)
    /*useEffect(() => {
        // fetch transaction List on component render
        dispatch(fetchTransactionList(undefined, filterName, filterDescription, filterStandardIds));
    }, []);*/

    useEffect(() => {
        // fetch transactions list when a successful mutation occurs
        if (upsertTransactionStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertTransactionStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertTransactionStatus());
            // close the modify dialog
            onTransactionDialogClose();
        }
        if (deleteTransactionStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteTransactionStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteTransactionStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteTransactionStatus.message as string);
        }
    }, [upsertTransactionStatus?.result, deleteTransactionStatus?.result]);

    useEffect(() => {
        if (upsertTransactionXPathStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertTransactionXPathStatus.message as string,
                severity: ToastSeverity.Success
            }));
            

            // remove upsert status
            dispatch(captureUpsertTransactionXPathStatus());

            // if xpath is open, then refresh entire list to bring in the change
            if (openXPathDialog) {
                setOpenXPathDialog(false);
                refreshList();
            } else {
                // refresh selected to bring in new value
                refreshSelectedTransaction();
            }
        }
    }, [upsertTransactionXPathStatus?.result]);

    const transactions = useAppSelector(selectTransactionList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('transactions-title-comp')?.clientHeight || 0;
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('transaction-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderHeight = listHeaderHeight + filterBarHeight;
        if (totalHeaderHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderHeight}px)`);
    }, [transactions, filtersOpen]);

    useEffect(() => {
        if (transactions) {
            let numFilters = 0;
            if (filterName && filterName.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            if (filterStandardIds && filterStandardIds.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [transactions, filterName, filterDescription, filterStandardIds]);

    useEffect(() => {
        if (!transactions && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [transactions, requestsInFlight]);

    useEffect(() => {
        if (activeTransaction) {
            setSelectedTransaction(activeTransaction);
            dispatch(clearError());
            // both Edit and Add/Edit XPath come into here, so need to
            // check flag to know which dialog to open
            if (editClicked) {
                setOpenModify(true);
                // reset for when clicked again
                setEditClicked(false);
            } else if (xPathClicked){
                setOpenXPathDialog(true);
                // reset for when clicked again
                setXPathClicked(false);
            }
        }
    }, [activeTransaction]);

    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'transaction-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} sm={12} md={6} lg={4} xl={3} key={key}>
                    <TransactionListItem loading={true} onEditClick={editTransaction} onDeleteClick={deleteTransaction} onXPathClick={addEditTransactionXPath} />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'transaction-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the EDI Transactions' error={error}></ErrorMessage>
            );
        }

        if (!transactions) {
            return getLoaders();
        }
        else if (transactions && transactions.length > 0) {
            return (
                transactions.map((transaction: TransactionModel) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={transaction.id}>
                        <TransactionListItem
                            transaction={transaction}
                            canDelete={canAddOrDelete}
                            onEditClick={editTransaction}
                            onDeleteClick={deleteTransaction}
                            onXPathClick={addEditTransactionXPath}
                        />
                    </Grid>
                ))
            );
        } else {
            // No transactions to show 
            return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New EDI Transaction" actionButtonClick={onAddTransactionClick} />
                :
                <NoRecordsMessage message="" />
            );
        }
    };

    const onAddTransactionClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected transaction is set
        if (selectedTransaction) {
            setSelectedTransaction(undefined);
        }
        setOpenModify(true);
    };

    const onTransactionDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteTransactionStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedTransaction on close.
        dispatch(clearError());
        setSelectedTransaction(undefined);
        refreshList();
    };

    const refreshList = () => {
        // Refresh list to bring in potential updates
        dispatch(fetchTransactionList(undefined, filterName, filterDescription, filterStandardIds));
    };

    const onTransactionDialogSave = (upsertTransactionInput: UpsertTransactionInput) => {
        // upsert to save data
        dispatch(upsertTransactionData(upsertTransactionInput));
    };

    const onTransactionXPathDialogSave = (upsertXPathData: UpsertTransactionXPathInput) => {
        // upsert to save xpath data
        dispatch(upsertTransactionXPath(upsertXPathData));
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete the selected transaction
        dispatch(deleteTransactionData(id));
    };

    const onXPathDialogClose = () => {
        setOpenXPathDialog(false);
        setSelectedTransaction(undefined);
    };

    const refreshSelectedTransaction = () => {
        if (selectedTransaction) {
            dispatch(fetchTransactionById(selectedTransaction.id));
        }
    };

    //edit selected transaction
    const editTransaction = (id: string | undefined) => {
        // rather than using the model directly, call to get by id so that 
        // the xpath is brought in as well and can be refreshed without
        // having to refresh the entire list
        if (id && transactions?.length) {
            setEditClicked(true);
            dispatch(fetchTransactionById(id));
        }
    };

    //delete selected transaction
    const deleteTransaction = (id: string | undefined) => {
        if (id && transactions?.length) {
            let transaction = transactions.find(t => t.id === id);
            if (transaction) {
                setSelectedTransaction(transaction);
                dispatch(captureDeleteTransactionStatus());
                setDeleteErrorMessage('');
                setOpenDelete(true);
            }
        }
    };

    //edit selected transaction
    const addEditTransactionXPath = (id: string | undefined) => {
        // rather than using the model directly, call to get by id so that 
        // the xpath is brought in as well and can be refreshed without
        // having to refresh the entire list
        if (id && transactions?.length) {
            setXPathClicked(true);
            dispatch(fetchTransactionById(id));
        }
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterName: string | undefined, filterDescription: string | undefined, filterStandardIds: string[]) => {
        setFilterName(filterName);
        setFilterDescription(filterDescription);
        setFilterStandardIds(filterStandardIds);
        dispatch(clearState());
        dispatch(fetchTransactionList(undefined, filterName, filterDescription, filterStandardIds));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='EDI Transactions' id="transactions-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="transaction-filters"
                    />
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Transaction"
                            onClick={onAddTransactionClick}
                            data-cy="add-new-transaction"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <TransactionsFilterBar
                id="transaction-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <TransactionDialog
                isOpen={openModify}
                transaction={selectedTransaction}
                onClose={onTransactionDialogClose}
                onSave={onTransactionDialogSave}
                onSaveXPath={onTransactionXPathDialogSave}
                refreshTransactionData={refreshSelectedTransaction}
                saveTransactionXPathResult={upsertTransactionXPathStatus?.result}
                error={error}
                xPathError={xPathError}
            />
            <TransactionXPathDialog
                isOpen={openXPathDialog}
                parentId={selectedTransaction?.id}
                xPathJson={selectedTransaction?.transactionXPath?.xPathJson as string}
                transactionXPathId={selectedTransaction?.transactionXPath?.id}
                onClose={onXPathDialogClose}
                onSave={onTransactionXPathDialogSave}
                refreshTransactionData={refreshList}
                error={xPathError}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedTransaction?.id}
                heading={'Delete EDI Transaction'}
                message={'Are you sure you want to delete \'' + selectedTransaction?.name + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Transactions;