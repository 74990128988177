import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { DeleteUserInput, DeleteUserPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MUTATION_USER_DELETE = gql`
mutation deleteEdiUser($input:DeleteUserInput!){
  deleteUser(input:$input){
    message
    result
    userId
  }
}
`;

export const mutationDeleteUser = async (
  client: ApolloClient<NormalizedCacheObject>,
  options: {
    id: string
  }
): Promise<DeleteUserPayload | undefined> => {
  const { id } = options as DeleteUserInput;
  const { data, errors } = await client.mutate<{ deleteUser: DeleteUserPayload }>({
    variables: {
      input: {
          id
      }
    },
    mutation: MUTATION_USER_DELETE
  });
  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the person.
    throw new Error('An error has occurred during the person delete.');
  }
  if (data && data.deleteUser) {
    return data.deleteUser;
  }

  return undefined;
};