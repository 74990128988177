import { MouseEvent, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchOnboardingClients } from '../../features/EDIContainer/EDIContainerActions';
import { selectOnboardingClientsList, selectOnboardingClientsError } from '../../features/EDIContainer/EDIContainerSlice';
import { Skeleton, Grid, Typography, ListItemText } from '@mui/material';
import { ClickableDashboardCard, DashboardCardContent, DashboardRootCardHeader } from '../../util/SharedStyles';
import { ClientModel } from '../../gql-types.generated';
import { Viewer } from '../../util/Constants';
import OnboardingDialog from '../dialogs/OnboardingDialog';

interface OnboardingCardProps {
    viewer?: Viewer | undefined;
}

const OnboardingCard: React.FC<OnboardingCardProps> = (props: OnboardingCardProps) => {
    const { viewer } = props;
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const cardRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [onboardingCount, setOnboardingCount] = useState<number>(0);
    const [singleOnboarding, setSingleOnboarding] = useState<ClientModel | undefined>(undefined);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
        
    const clientsOnboarding = useAppSelector(selectOnboardingClientsList);
    const error = useAppSelector(selectOnboardingClientsError);

    useEffect(() => {
        dispatch(fetchOnboardingClients());
    }, []);

    useEffect(() => {
        if (error) {
            setLoading(false);
        } else if (clientsOnboarding) {
            setLoading(false);
            let count = clientsOnboarding.length ?? 0;
            setOnboardingCount(count);

            if (count === 1) {
                setSingleOnboarding(clientsOnboarding[0]);
            }
        } else {
            setLoading(true);
        }
    }, [clientsOnboarding, error]);

    const cardClick = () => {
        if (!loading) {
            if (onboardingCount > 0) {
                if (singleOnboarding) {
                    // navigate to the particular client
                    let url = '/error/unknowntype';
                    
                    // onboarding are displayed for Clients
                    if (singleOnboarding.id){
                        url = '/client/' + singleOnboarding.id;
                    }
                    
                    navigate(url);
                } else {
                    // open dialog for onboarding list
                    setOpenDialog(true);
                }
            }
        }
    };

    const getCardContent = () => {
        if (error) {
            return (
                <Typography variant="body1" color="error">
                   Unable to load.
                </Typography>
            );
        } else {
            if (singleOnboarding) {
                return (
                    <Grid item>
                        <ListItemText primary={singleOnboarding.name} secondary={singleOnboarding.lastModifiedByName} />
                    </Grid>
                );
            } else {
                var color = onboardingCount > 0 ? "primary" : "text";
                return (
                    <Typography variant="h3" color={color}>
                        {onboardingCount}
                    </Typography>
                );
            }
        }
    };

    const onDialogClose = (event?: MouseEvent<HTMLElement>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event?.stopPropagation();

        // need to wrap in timeout, otherwise dialog doesn't receive the changed state
        setTimeout(() => {
            setOpenDialog(false);
        });
    };

    const getOffsetTop = () => {
        // want the dialog to display right below the card
        // so use height and top to get the top for dialog
        if (cardRef?.current) {
            const { offsetHeight, offsetTop } = cardRef.current;
            if (offsetTop > offsetHeight) {
                return offsetTop - offsetHeight;
            } else {
                return offsetTop + offsetHeight;
            }
        }
        return 0;
    };

    const getDialogHeight = () => {
        if (cardRef?.current) {
            const { offsetParent } = cardRef.current;
            if (offsetParent && offsetParent.clientHeight) {
                let headerAndCardHeight = 250;
                return `${offsetParent.clientHeight - headerAndCardHeight}px`;
            } 
        }
        return '600px';
    };
    
    return (
        <ClickableDashboardCard ref={cardRef} onClick={cardClick} disabled={loading || onboardingCount < 1}>
            <DashboardRootCardHeader
                title={
                    loading ? (
                        <Skeleton animation="wave" height={32} width="60%" />
                    ) : (
                        <Typography variant="h6">Onboarding</Typography>
                    )
                }
            />
            <DashboardCardContent>
                {
                    loading ? (
                        <Grid container item justifyContent={"center"}>
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        </Grid>
                    ) : (
                        <Grid container item justifyContent={"center"}>
                            {getCardContent()}
                        </Grid>
                    )
                }
            </DashboardCardContent>
            <OnboardingDialog 
                isOpen={openDialog} 
                clientsOnboarding={clientsOnboarding ?? []}
                onClose={onDialogClose}
                maxWidth='md'
                height={getDialogHeight()}
                offsetLeft={cardRef?.current?.offsetLeft ?? 0}
                offsetTop={getOffsetTop()}
                error={error}
            />
        </ClickableDashboardCard>
    )
};

export default OnboardingCard;