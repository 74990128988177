import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { DialogDynamicListToolbar, DialogDynamicListContainer, DialogDynamicListContent, DialogDynamicListHeader, DialogDynamicListDataGrid } from '../../../util/SharedStyles';
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { PartnerTransactionSchemaModel, RequestResult, UpsertPartnerTransactionSchemaInput } from "../../../gql-types.generated";
import { selectError, selectDeletePartnerTransactionSchemaStatus, selectUpsertPartnerTransactionSchemaStatus, captureDeletePartnerTransactionSchemaStatus, captureUpsertPartnerTransactionSchemaStatus } from "../../../features/TradingPartnerDetails/TradingPartnerDetailsSlice";
import { upsertPartnerTransactionSchema, deletePartnerTransactionSchema } from '../../../features/TradingPartnerDetails/TradingPartnerDetailsActions';
import { downloadDocument } from "../../../util/Common";
import CreateNewButton from '../../buttons/CreateNewButton';
import DeleteDialog from '../../dialogs/DeleteDialog';
import PartnerTransactionSchemaDialog from '../../dialogs/PartnerTransactionSchemaDialog';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';


interface PartnerTransactionSchemaListProps {
    parentId: string;
    schemas: PartnerTransactionSchemaModel[] | undefined;
    isParentTransactionInbound: boolean;
    isLoading?: boolean;
    refreshPartnerData?: () => void;
}

const DialogPartnerTransactionSchemaList: React.FC<PartnerTransactionSchemaListProps> = props => {
    const { parentId, schemas, refreshPartnerData, isParentTransactionInbound, isLoading = false } = props;
    const dispatch = useAppDispatch();
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openPartnerTransactionSchemaDialog, setOpenPartnerTransactionSchemaDialog] = useState(false);
    const [selectedSchema, setSelectedSchema] = useState<PartnerTransactionSchemaModel | undefined>(undefined); 
    const [schemaRows, setSchemaRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingSchema, setIsSavingOrDeletingSchema] = useState(false);

    const error = useAppSelector(selectError);
    const deleteSchemaStatus = useAppSelector(selectDeletePartnerTransactionSchemaStatus);
    const saveSchemaStatus = useAppSelector(selectUpsertPartnerTransactionSchemaStatus);

    useEffect(() => {
        setSchemaRows(getSchemaRows());
        setGridHeight(getGridHeight());
    }, [schemas]);

    
    // declare the data refreshing function
    // as async so list will show as loading
    // up until refresh is complete
    const refreshData = async () => {
        if (refreshPartnerData) {
            setIsSavingOrDeletingSchema(true);
            await refreshPartnerData();
        }
        setIsSavingOrDeletingSchema(false);
    };

    useEffect(() => {
        if (saveSchemaStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: saveSchemaStatus.message as string,
                severity: ToastSeverity.Success
            }));
            onPartnerTransactionSchemasDialogClose();
            refreshData();
        }
    }, [saveSchemaStatus?.result]);

    useEffect(() => {
        if (deleteSchemaStatus) {
            if (deleteSchemaStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
                refreshData();
            } else if (deleteSchemaStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteSchemaStatus?.message as string);
                setIsSavingOrDeletingSchema(false);
            }
        }
    }, [deleteSchemaStatus?.result]);

    const getSelectedRowSchema = useCallback((rowId: string) => () => {
        if (rowId && schemas?.length) {
            let schema = schemas.find(schema => schema.id === rowId);
            return schema;
        }
        return undefined;
    }, [schemas]);

    const deleteSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            setSelectedSchema(schema);
            setOpenDelete(true);
        }
    }, [getSelectedRowSchema]);

    const editSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            setSelectedSchema(schema);
            setOpenPartnerTransactionSchemaDialog(true);
        }
    }, [getSelectedRowSchema]);

    const onDownloadSchema = (schemaFileString: string, schemaFileName: string) => {
        downloadDocument(schemaFileString, schemaFileName ?? "Schema-Document", "text/xml", true);
    };

    const downloadSchemaHandler = useCallback((rowId: string) => () => {
        let schema = getSelectedRowSchema(rowId);
        if (schema) {
            // because coming from callback, need to treat return value as function
            let document = schema()?.documentString as string;
            let fileName = schema()?.sourceFileName as string;
            if (document !== undefined && document !== null) {
                onDownloadSchema(document, fileName);
            }
        }
    }, [getSelectedRowSchema]);

    const setDefaults = () => {
        setSelectedSchema(undefined);
    };

    const onAddSchema = () => {
        setDefaults();
        setOpenPartnerTransactionSchemaDialog(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onPartnerTransactionSchemasDialogClose = () => {
        setOpenPartnerTransactionSchemaDialog(false);
        dispatch(captureUpsertPartnerTransactionSchemaStatus());
        onDialogClose();
    };

    const onPartnerTransactionSchemasDialogSave = (inputData: UpsertPartnerTransactionSchemaInput) => {
        if (inputData) {
            setIsSavingOrDeletingSchema(true);
            dispatch(upsertPartnerTransactionSchema(inputData));
        }
    }

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(captureDeletePartnerTransactionSchemaStatus());
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected schema
        if (selectedSchema) {
            setIsSavingOrDeletingSchema(true);
            dispatch(deletePartnerTransactionSchema(selectedSchema.id as string));
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'schema-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no schemas, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (schemas) {
            if (schemas.length === 1) {
                return '108px';
            } else if (schemas.length === 2) {
                return '156px';
            } else if (schemas.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    };

    const getSchemaRows = () => {
        if (schemas && schemas.length > 0) {
            return schemas.map((schema) => {
                const { id, description, sourceFileName, standardVersion, documentString } = schema;
                let hasDoc = documentString && documentString.length;

                return {
                    _raw: schema,
                    id,
                    description,
                    sourceFileName,
                    standardVersion,
                    hasDoc
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const schemaColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                flex: 2,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            }, {
                field: 'standardVersion',
                headerName: 'VERSION',
                flex: 1,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                minWidth: 110,
                headerAlign: 'center',
                align: 'center',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Download Schema"><DownloadIcon /></Tooltip>}
                        label="Download"
                        color="primary"
                        disabled={!params.row.hasDoc}
                        onClick={downloadSchemaHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editSchemaHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteSchemaHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteSchemaHandler, editSchemaHandler, downloadSchemaHandler],
    );

    return (
        <DialogDynamicListContainer>

            <DialogDynamicListToolbar justify="space-between" noborder={+true}>
                <DialogDynamicListHeader>EDI Rules Schema</DialogDynamicListHeader>
                
                    <CreateNewButton
                        text="Add Schema"
                        tooltip="Click here to add another EDI Rule Schema"
                        variant="text"
                        onClick={onAddSchema}
                        disabled={isSavingOrDeletingSchema || isLoading}
                        data-cy="add-new-schema"
                    />
               
            </DialogDynamicListToolbar>

            <DialogDynamicListContent>
                {((schemas !== undefined && schemas?.length > 0) || isSavingOrDeletingSchema) &&
                    <DialogDynamicListDataGrid
                        loading={isSavingOrDeletingSchema || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="Partner Transaction Schemas List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={schemaRows}
                        columns={schemaColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </DialogDynamicListContent>

            <PartnerTransactionSchemaDialog
                isOpen={openPartnerTransactionSchemaDialog}
                partnerTransactionId={parentId}
                schema={selectedSchema}
                onClose={onPartnerTransactionSchemasDialogClose}
                onSave={onPartnerTransactionSchemasDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedSchema?.id ?? ''}
                heading={'Delete EDI Rule Schema'}
                message={'Are you sure you want to delete \'' + selectedSchema?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </DialogDynamicListContainer>
    );

}

export default DialogPartnerTransactionSchemaList;
