import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteBusinessAreaPayload, UpsertBusinessAreaPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    upsertBusinessAreaStatus?: UpsertBusinessAreaPayload;
    deleteBusinessAreaStatus?: DeleteBusinessAreaPayload;
}

const initialState: SliceState = {
    error: undefined,
    upsertBusinessAreaStatus: undefined,
    deleteBusinessAreaStatus: undefined,
};

export const slice = createSlice({
    name: 'businessAreas',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertBusinessAreaStatus: (state, action: PayloadAction<UpsertBusinessAreaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertBusinessAreaStatus = action.payload;
        },

        captureDeleteBusinessAreaStatus: (state, action: PayloadAction<DeleteBusinessAreaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteBusinessAreaStatus = action.payload;
        }
    }
});

export const selectError = (state: RootState): Error | undefined => state.businessAreas.error;
export const selectUpsertBusinessAreaStatus = (state: RootState): UpsertBusinessAreaPayload | undefined => state.businessAreas.upsertBusinessAreaStatus;
export const selectDeleteBusinessAreaStatus = (state: RootState): DeleteBusinessAreaPayload | undefined => state.businessAreas.deleteBusinessAreaStatus;

export const {
    clearError,
    fetchError,
    captureUpsertBusinessAreaStatus,
    captureDeleteBusinessAreaStatus
} = slice.actions;

export default slice.reducer;