import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, styled } from "@mui/material";
import { PricingModel, CurrencyModel } from '../../gql-types.generated';
import { selectCurrencyList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface DialogProps {
    isOpen: boolean;
    pricing?: PricingModel | null | undefined;
    onClose: () => void;
    onSave: (description: string, currencyCode: string, minServiceFees: number, id?: string) => void;
    error?: Error | undefined;
}

const PricingDialog: React.FC<DialogProps> = props => {
    const { isOpen, pricing, onClose, onSave, error } = props;
    const id = pricing?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [minServiceFees, setMinServiceFees] = useState<number | undefined>(0);
    const [serviceFeesInvalid, setServiceFeesInvalid] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    const currencies = useAppSelector(selectCurrencyList);

    useEffect(() => {
        // if have a pricing, then populate for Edit
        if (pricing) {
            setFromPricing();
        }
        else {
            setToDefaults();
        }
    }, [pricing]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
        else {
            setFromPricing();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setMinServiceFees(0);
        setDescription('');
        setCurrencyCode('');
    }

    const setFromPricing = () => {
        if (pricing) {
            if (pricing.minServiceFees) {
                setMinServiceFees(pricing.minServiceFees);
            }

            if (pricing.description) {
                setDescription(pricing.description);
            }

            if (pricing.currencyCode) {
                setCurrencyCode(pricing.currencyCode);
            }
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = (description && description?.length > 0 && currencyCode?.trim().length > 0) ? true : false;
        return isFormDirty && requiredCheck && !serviceFeesInvalid;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(description, currencyCode, minServiceFees ?? 0, id);
    };

    // onChange handlers
    const onServiceFeesChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value);

        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {

            // if value not in valid range, set invalid
            if (targetValue < minValue || targetValue > maxValue) {
                setServiceFeesInvalid(true);
            } // else if value is in range, but length greater than maxLength (too many decimal places), set invalid
            else if (event.target.value.length > event.target.maxLength) {
                setServiceFeesInvalid(true);
            }
            else {
                // value is valid
                setServiceFeesInvalid(false);
            }

            setIsFormDirty(true);
            setMinServiceFees(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as invalid
            // and undefined to force user to enter a valid number
            setServiceFeesInvalid(true);
            setMinServiceFees(undefined);
        }
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onCurrencyChange = (event: SelectChangeEvent<string | null>) => {
        if (event.target.value) {
            setIsFormDirty(true);
            setCurrencyCode(event.target.value);
        }
    };

    // get the list of available currencies for the dropdown
    const getCurrenciesDropList = () => {
        if (currencies && currencies.length) {
            return (
                currencies.map((currency: CurrencyModel) => (
                    <MenuItem
                        key={currency.currencyCode}
                        value={currency.currencyCode}
                    >
                    {`${currency.currencyCode} - ${currency.description?.trim()}`}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const serviceFeesProps = {
        'aria-label': 'min-service-fees',
        'maxLength': 9,
        'min': '0',
        'max': '999999.99',
        'step': '0.1',
        'precision': 2
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const currencyMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Pricing"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-pricing-description"
                        fullWidth
                        autoFocus
                        required
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-pricing-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required >
                        <InputLabel id="dialog-pricing-currency-label">Currency Code</InputLabel>
                        <Select
                            labelId="dialog-pricing-currency-label"
                            aria-labelledby="dialog-pricing-currency-label"
                            value={currencyCode}
                            onChange={onCurrencyChange}
                            disabled={submitted}
                            MenuProps={currencyMenuProps}
                            data-cy="dialog-pricing-currency"
                        >
                            {getCurrenciesDropList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        itemID="dialog-pricing-serviceFees"
                        fullWidth
                        disabled={submitted}
                        value={minServiceFees}
                        label="Minimum Service Fees"
                        inputProps={serviceFeesProps}
                        onChange={onServiceFeesChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-pricing-serviceFees"
                        variant="standard"
                        error={serviceFeesInvalid}
                        helperText={serviceFeesInvalid ? "Valid value 0 to 999,999.99" : ""}
                    />
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
};

export default PricingDialog;