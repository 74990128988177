import { useNavigate } from 'react-router-dom';
import { Skeleton, Grid, Typography } from '@mui/material';
import { ClickableDashboardCard, DashboardCardContent, DashboardRootCardHeader } from '../../util/SharedStyles';
import { Viewer } from '../../util/Constants';
import theme from '../../Theme';

interface DashboardCardProps {
    viewer?: Viewer | undefined;
    loading?: boolean | false;
    countColor?: string;
    count?: number;
    title: string;
    secondaryTitle?: string;
    navigatePath: string;
}

const DashboardCard: React.FC<DashboardCardProps> = (props: DashboardCardProps) => {
    const { viewer, loading, count = 0, countColor = "primary", navigatePath, title, secondaryTitle } = props;
    const navigate = useNavigate();
    
    const cardClick = () => {
        if (!loading) {
            if (count > 0) {
                // navigate to given path
                navigate(navigatePath);
            }
        }
    };

    const getCardContent = () => {
        var color = count > 0 ? countColor : theme.palette.grayscale.light;
        return (
            <Typography variant="h3" color={color}>
                {count}
            </Typography>
        );
    };
    
    return (
        <ClickableDashboardCard onClick={cardClick} disabled={loading || count < 1}>
            <DashboardRootCardHeader
                title={
                    loading ? (
                        <Skeleton animation="wave" height={32} width="60%" />
                    ) : (
                        <Grid container item direction={"row"} gap={1}>
                            <Typography variant="h6" alignSelf={"flex-end"}>{title}</Typography>
                            <Typography variant="body2" alignSelf={"center"}>{secondaryTitle}</Typography>
                        </Grid>
                    )
                }
                
            />
            <DashboardCardContent>
                {
                    loading ? (
                        <Grid container item justifyContent={"center"}>
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                        </Grid>
                    ) : (
                        <Grid container item justifyContent={"center"}>
                            {getCardContent()}
                        </Grid>
                    )
                }
            </DashboardCardContent>
        </ClickableDashboardCard>
    );
};

export default DashboardCard;