import { styled, Grid, TextField } from "@mui/material";
import { useEffect, useState, ChangeEvent } from "react";

import { EdiXmlConfigModel, UpsertEdiXmlConfigInput } from "../../gql-types.generated";
import { maxFileSize } from "../../util/Constants";
import AddEditDialog from "./AddEditDialog";
import FileDropZone from '../FileDropZone';

const TextFieldWithMargin = styled(TextField)((props) => ({
    marginBottom: 20,
}));

interface AddEdiXmlConfigDialogProps {
    isOpen: boolean;
    clientId: string;
    config?: EdiXmlConfigModel | undefined;
    onClose: () => void;
    onSave: (upsertData: UpsertEdiXmlConfigInput) => void;
    error?: Error | undefined;
}

const AddEdiXmlConfigDialog: React.FC<AddEdiXmlConfigDialogProps> = props => {
    const { isOpen, clientId, config, onClose, onSave, error } = props;
    const id = config?.id;
    const [filesToSubmit, setFilesToSubmit] = useState<File[]>([]);
    const [existingFile, setExistingFile] = useState<File | undefined>(undefined);
    const [description, setDescription] = useState<string>('');
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
        } else {
            if (config) {
                setFromConfig();
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (config) {
            setFromConfig();
        } else {
            setToDefaults();
        }
    }, [config]);

    const setToDefaults = () => {
        setSubmitted(false);
        setIsFormDirty(false);
        setDescription('');
        setFilesToSubmit([]);
        setExistingFile(undefined);
    }

    const setFromConfig = () => {
        if (config) {
            if (config.description) {
                setDescription(config.description);
            }
            if (config.documentString !== undefined && config.documentString !== null && config.fileName) {
                let mockFile = new File([config.documentString], config.fileName);
                setExistingFile(mockFile);
            }
        }
    }

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileToUpload = filesToUpload[0];
            setFilesToSubmit([fileToUpload]);
        } else {
            // when no file, make sure any previous file fields are cleared
            setFilesToSubmit([]);
        }
    }

    const fileToBase64 = async (file: Blob): Promise<string | undefined> => {
        var reader = new FileReader();
        reader.readAsDataURL(file as Blob);

        return new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result as any);
            reader.onerror = (error) => reject(error);
        });
    };

    const onCloseHandler = () => {
        onClose();
    }

    const submitForm = async () => {
        if (filesToSubmit[0]) {
            setSubmitted(true);
            let xmlToBase64 = "";
            await fileToBase64(filesToSubmit[0]).then(result => {
                if (result) {
                    // strip off the mimeType data
                    let resultParts = result?.split(',');
                    if (resultParts) {
                        xmlToBase64 = resultParts[1] ?? '';
                    }
                }
            });
            onSave({
                clientId: clientId,
                description: description,
                documentString: xmlToBase64,
                fileName: filesToSubmit[0].name,
                id: id
            })
        } else {
            if (existingFile) {
                setSubmitted(true);
                onSave({
                    clientId: clientId,
                    description: description,
                    documentString: undefined,
                    fileName: undefined,
                    id: id
                })
            }
        }
    }

    const onSaveHandler = () => {
        if (isFormValid()) {
            submitForm();
        }
    };

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return (isFormDirty && (filesToSubmit.length > 0 || existingFile !== undefined));
    }

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const descriptionProps = {
        "aria-label": "file description",
        maxLength: 255,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id ?? undefined}
            entityName="Configuration"
            onClose={onCloseHandler}
            onSave={onSaveHandler}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextFieldWithMargin
                        itemID="dialog-edixmlconfig-description"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={description}
                        label="Configuration Description"
                        inputProps={descriptionProps}
                        onChange={handleDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-edixmlconfig-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FileDropZone
                        label="Configuration File*"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your configuration file here"
                        existingFiles={existingFile ? [existingFile] : undefined}
                        acceptedFileTypes={{
                            'application/xml': ['.xml']
                        }}
                        onChange={onFileUploadChange}
                    />
                </Grid>
            </Grid>

        </AddEditDialog>
    )
}

export default AddEdiXmlConfigDialog;