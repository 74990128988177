import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
    AttachmentModel, 
    ClientModel,
    DeleteByIdPayload, 
    PagingResultModelOfAttachmentModel, 
    PartnerContactModel, 
    PartnerTransactionModel, 
    TransactionModel, 
    UpsertPartnerContactPayload, 
    AddPartnerTransactionsPayload, 
    PartnerModel, 
    UpsertPartnerPayload, 
    PagingResultModelOfPartnerModel, 
    NoteModel, 
    UpsertPartnerAttachmentPayload, 
    UpdatePartnerTransactionsPayload,
    UpsertPartnerTransactionSchemaPayload,
    PartnerTransactionSchemaModel,
    UpsertPartnerAkaPayload,
    UpsertPartnerNotificationRecipientsPayload,
    PartnerNotificationRecipientsModel,
 } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    partnerPagingResult?: PagingResultModelOfPartnerModel;
    partner?: PartnerModel;
    partnerContactInfo?: PartnerContactModel[];
    clients?: ClientModel[];
    partnerNotes?: NoteModel[];
    partnerTransactions?: PartnerTransactionModel[];
    transactions?: TransactionModel[];
    partnerAttachments?: AttachmentModel[];
    upsertPartnerContactStatus?: UpsertPartnerContactPayload;
    deletePartnerContactStatus?: DeleteByIdPayload;
    upsertPartnerStatus?: UpsertPartnerPayload;
    deletePartnerStatus?: DeleteByIdPayload;
    addPartnerTransactionsStatus?: AddPartnerTransactionsPayload;
    deletePartnerTransactionStatus?: DeleteByIdPayload;
    upsertPartnerNoteStatus?: UpsertPartnerPayload;
    deletePartnerNoteStatus?: DeleteByIdPayload;
    upsertPartnerContactNoteStatus?: UpsertPartnerContactPayload;
    deletePartnerContactNoteStatus?: DeleteByIdPayload;
    upsertPartnerAttachmentsStatus?: UpsertPartnerAttachmentPayload;
    deletePartnerAttachmentStatus?: DeleteByIdPayload;
    attachmentsPagingResult?: AttachmentModel[];
    updatePartnerTransactionStatus?: UpdatePartnerTransactionsPayload;
    upsertPartnerTransactionSchemaStatus?: UpsertPartnerTransactionSchemaPayload;
    deletePartnerTransactionSchemaStatus?: DeleteByIdPayload;
    upsertPartnerAkaStatus?: UpsertPartnerAkaPayload;
    deletePartnerAkaStatus?: DeleteByIdPayload;
    partnerNotificationRecipients?: PartnerNotificationRecipientsModel[],
    upsertPartnerNotificationRecipientsStatus?: UpsertPartnerNotificationRecipientsPayload,
    deletePartnerNotificationRecipientsStatus?: DeleteByIdPayload,
}

const initialState: SliceState = {
    error: undefined,
    partnerPagingResult: undefined,
    partner: undefined,
    partnerContactInfo: undefined,
    clients: undefined,
    partnerNotes: undefined,
    partnerTransactions: undefined,
    transactions: undefined,
    partnerAttachments: undefined,
    upsertPartnerContactStatus: undefined,
    deletePartnerContactStatus: undefined,
    upsertPartnerStatus: undefined,
    deletePartnerStatus: undefined,
    addPartnerTransactionsStatus: undefined,
    deletePartnerTransactionStatus: undefined,
    upsertPartnerNoteStatus: undefined,
    deletePartnerNoteStatus: undefined,
    upsertPartnerContactNoteStatus: undefined,
    deletePartnerContactNoteStatus: undefined,
    upsertPartnerAttachmentsStatus: undefined,
    deletePartnerAttachmentStatus: undefined,
    attachmentsPagingResult: undefined,
    updatePartnerTransactionStatus: undefined,
    upsertPartnerTransactionSchemaStatus: undefined,
    deletePartnerTransactionSchemaStatus: undefined,
    upsertPartnerAkaStatus: undefined,
    deletePartnerAkaStatus: undefined,
    partnerNotificationRecipients: undefined,
    upsertPartnerNotificationRecipientsStatus: undefined,
    deletePartnerNotificationRecipientsStatus: undefined,
}

export const slice = createSlice({
    name: 'tradingPartnerDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partner = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerContactInfo = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clients = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerNotes = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerTransactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerAttachments = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerContactStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerContactStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addPartnerTransactionsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerAttachmentsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerAttachmentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updatePartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerTransactionSchemaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerTransactionSchemaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerAkaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerAkaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerNotificationRecipients = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerNotificationRecipientsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerNotificationRecipientsStatus = undefined;
        },

        fetchTradingPartnerByIdSuccess: (state, action: PayloadAction<PagingResultModelOfPartnerModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.partnerPagingResult = action.payload;
            if (action.payload.nodes && action.payload.nodes[0]) {
                let tradingPartner = action.payload.nodes[0];
                if (tradingPartner) {
                    // eslint-disable-next-line no-param-reassign
                    state.partner = tradingPartner;
                    if (tradingPartner.partnerContacts) {
                        // eslint-disable-next-line no-param-reassign
                        state.partnerContactInfo = tradingPartner.partnerContacts as PartnerContactModel[];
                    }
                    if (tradingPartner.clients) {
                        // eslint-disable-next-line no-param-reassign
                        state.clients = tradingPartner.clients as ClientModel[];
                    }
                    if (tradingPartner.notes) {
                        // eslint-disable-next-line no-param-reassign
                        state.partnerNotes = tradingPartner.notes as NoteModel[];
                    }
                    if (tradingPartner.partnerTransactions) {
                        // eslint-disable-next-line no-param-reassign
                        state.partnerTransactions = tradingPartner.partnerTransactions as PartnerTransactionModel[];
                    }
                    if (tradingPartner.attachments) {
                        // eslint-disable-next-line no-param-reassign
                        state.partnerAttachments = tradingPartner.attachments as AttachmentModel[];
                    }
                }
            }
        },

        fetchTransactionsSuccess: (state, action: PayloadAction<TransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactions = action.payload;
        },

        captureUpsertTradingPartnerContactStatus: (state, action: PayloadAction<UpsertPartnerContactPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerContactStatus = action.payload;
        },

        captureDeleteTradingPartnerContactStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerContactStatus = action.payload;
        },

        captureUpsertTradingPartnerStatus: (state, action: PayloadAction<UpsertPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerStatus = action.payload;
        },

        captureDeleteTradingPartnerStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerStatus = action.payload;
        },

        captureAddTradingPartnerTransactionsStatus: (state, action: PayloadAction<AddPartnerTransactionsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addPartnerTransactionsStatus = action.payload;
        },

        captureDeleteTradingPartnerTransactionStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerTransactionStatus = action.payload;
        },

        captureUpsertTradingPartnerNoteStatus: (state, action: PayloadAction<UpsertPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerNoteStatus = action.payload;
        },

        captureDeleteTradingPartnerNoteStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerNoteStatus = action.payload;
        },

        captureUpsertPartnerContactNoteStatus: (state, action: PayloadAction<UpsertPartnerContactPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerContactNoteStatus = action.payload;
        },

        captureDeletePartnerContactNoteStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerContactNoteStatus = action.payload;
        },

        captureUpsertTradingPartnerAttachmentsStatus: (state, action: PayloadAction<UpsertPartnerAttachmentPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerAttachmentsStatus = action.payload;
        },

        captureDeleteTradingPartnerAttachmentStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerAttachmentStatus = action.payload;
        },

        fetchPartnerAttachmentSuccess: (state, action: PayloadAction<PagingResultModelOfAttachmentModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = action.payload.nodes as AttachmentModel[];
        },

        clearPartnerAttachment: state => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
        },

        captureUpdatePartnerTransactionStatus: (state, action: PayloadAction<UpdatePartnerTransactionsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updatePartnerTransactionStatus = action.payload;
        },

        captureUpsertPartnerTransactionSchemaStatus: (state, action: PayloadAction<UpsertPartnerTransactionSchemaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerTransactionSchemaStatus = action.payload;
        },

        captureDeletePartnerTransactionSchemaStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerTransactionSchemaStatus = action.payload;
        },

        captureUpsertPartnerAkaStatus: (state, action: PayloadAction<UpsertPartnerAkaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerAkaStatus = action.payload;
        },

        captureDeletePartnerAkaStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerAkaStatus = action.payload;
        },

        fetchPartnerNotificationRecipientsSuccess: (state, action: PayloadAction<PartnerNotificationRecipientsModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.partnerNotificationRecipients = action.payload;
        },

        captureUpsertPartnerNotificationRecipientsStatus: (state, action: PayloadAction<UpsertPartnerNotificationRecipientsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerNotificationRecipientsStatus = action.payload;
        },

        captureDeletePartnerNotificationRecipientsStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePartnerNotificationRecipientsStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.tradingPartnerDetails.error;
export const selectTradingPartnerConnection = (state: RootState): PagingResultModelOfPartnerModel | undefined => state.tradingPartnerDetails.partnerPagingResult;
export const selectTradingPartner = (state: RootState): PartnerModel | undefined => state.tradingPartnerDetails.partner;
export const selectContactInfo = (state: RootState): PartnerContactModel[] | undefined => state.tradingPartnerDetails.partnerContactInfo;
export const selectClients = (state: RootState): ClientModel[] | undefined => state.tradingPartnerDetails.clients;
export const selectPartnerNotes = (state: RootState): NoteModel[] | undefined => state.tradingPartnerDetails.partnerNotes;
export const selectPartnerTransactions = (state: RootState): PartnerTransactionModel[] | undefined => state.tradingPartnerDetails.partnerTransactions;
export const selectPartnerAttachments = (state: RootState): AttachmentModel[] | undefined => state.tradingPartnerDetails.partnerAttachments;
export const selectEligibleTransactions = (state: RootState): TransactionModel[] | undefined => state.tradingPartnerDetails.transactions;
export const selectUpsertTradingPartnerContactStatus = (state: RootState): UpsertPartnerContactPayload | undefined => state.tradingPartnerDetails.upsertPartnerContactStatus;
export const selectDeleteTradingPartnerContactStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerContactStatus;
export const selectUpsertTradingPartnerStatus = (state: RootState): UpsertPartnerPayload | undefined => state.tradingPartnerDetails.upsertPartnerStatus;
export const selectDeleteTradingPartnerStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerStatus;
export const selectAddTradingPartnerTransactionsStatus = (state: RootState): AddPartnerTransactionsPayload | undefined => state.tradingPartnerDetails.addPartnerTransactionsStatus;
export const selectDeleteTradingPartnerTransactionStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerTransactionStatus;
export const selectUpsertTradingPartnerNoteStatus = (state: RootState): UpsertPartnerPayload | undefined => state.tradingPartnerDetails.upsertPartnerNoteStatus;
export const selectDeleteTradingPartnerNoteStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerNoteStatus;
export const selectUpsertPartnerContactNoteStatus = (state: RootState): UpsertPartnerContactPayload | undefined => state.tradingPartnerDetails.upsertPartnerContactNoteStatus;
export const selectDeletePartnerContactNoteStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerContactNoteStatus;
export const selectUpsertTradingPartnerAttachmentsStatus = (state: RootState): UpsertPartnerAttachmentPayload | undefined => state.tradingPartnerDetails.upsertPartnerAttachmentsStatus;
export const selectDeleteTradingPartnerAttachmentStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerAttachmentStatus;
export const selectAttachmentToDownload = (state: RootState): AttachmentModel[] | undefined => state.tradingPartnerDetails.attachmentsPagingResult;
export const selectUpdatePartnerTransactionStatus = (state: RootState): UpdatePartnerTransactionsPayload | undefined => state.tradingPartnerDetails.updatePartnerTransactionStatus;
export const selectUpsertPartnerTransactionSchemaStatus = (state: RootState): UpsertPartnerTransactionSchemaPayload | undefined => state.tradingPartnerDetails.upsertPartnerTransactionSchemaStatus;
export const selectDeletePartnerTransactionSchemaStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerTransactionSchemaStatus;
export const selectUpsertPartnerAkaStatus = (state: RootState): UpsertPartnerAkaPayload | undefined => state.tradingPartnerDetails.upsertPartnerAkaStatus;
export const selectDeletePartnerAkaStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerAkaStatus;
export const selectPartnerNotificationsRecipients = (state: RootState): PartnerNotificationRecipientsModel[] | undefined => state.tradingPartnerDetails.partnerNotificationRecipients;
export const selectUpsertPartnerNotificationsRecipientsStatus = (state: RootState): UpsertPartnerNotificationRecipientsPayload | undefined => state.tradingPartnerDetails.upsertPartnerNotificationRecipientsStatus;
export const selectDeletePartnerNotificationsRecipientsStatus = (state: RootState): DeleteByIdPayload | undefined => state.tradingPartnerDetails.deletePartnerNotificationRecipientsStatus;

// since contact notes is a list in the contact dialog and it has a separate save from contact,
// this select will be used in a selector to refresh the notes list on the given contact
export const selectContactNotesByPartnerContactId = (id: string) => (state: RootState): NoteModel[] | undefined => {
    let contacts = state?.tradingPartnerDetails?.partnerContactInfo?.filter((c) => c.id === id);
    if (contacts && contacts.length && contacts[0] && contacts[0].notes) {
        return contacts[0].notes as NoteModel[];
    }
    return undefined;
};

// since partner schemas is a list in the edit partner transaction dialog and it has a separate save from partnerTransactionSchema,
// this select will be used in a selector to refresh the schemas list on the given partner transaction
export const selectPartnerTransactionSchemasByPartnerTransactionId = (id: string | undefined) => (state: RootState): PartnerTransactionSchemaModel[] | undefined => {
    if (id) {
        let transactions = state?.tradingPartnerDetails?.partnerTransactions?.filter((pt) => pt.id === id);
        if (transactions && transactions.length && transactions[0] && transactions[0].partnerTransactionSchemas) {
            return transactions[0].partnerTransactionSchemas as PartnerTransactionSchemaModel[];
        }
    }
    return undefined;
};

export const {
    clearError,
    clearState,
    fetchError,
    fetchTradingPartnerByIdSuccess,
    fetchTransactionsSuccess,
    captureUpsertTradingPartnerContactStatus,
    captureDeleteTradingPartnerContactStatus,
    captureUpsertTradingPartnerStatus,
    captureDeleteTradingPartnerStatus,
    captureAddTradingPartnerTransactionsStatus,
    captureDeleteTradingPartnerTransactionStatus,
    captureUpsertTradingPartnerNoteStatus,
    captureDeleteTradingPartnerNoteStatus,
    captureUpsertPartnerContactNoteStatus,
    captureDeletePartnerContactNoteStatus,
    captureUpsertTradingPartnerAttachmentsStatus,
    captureDeleteTradingPartnerAttachmentStatus,
    fetchPartnerAttachmentSuccess,
    clearPartnerAttachment,
    captureUpdatePartnerTransactionStatus,
    captureUpsertPartnerTransactionSchemaStatus,
    captureDeletePartnerTransactionSchemaStatus,
    captureUpsertPartnerAkaStatus,
    captureDeletePartnerAkaStatus,
    fetchPartnerNotificationRecipientsSuccess,
    captureDeletePartnerNotificationRecipientsStatus,
    captureUpsertPartnerNotificationRecipientsStatus,
} = slice.actions;

export default slice.reducer;