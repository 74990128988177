import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { AUTH_DOMAIN, AUTH_CLIENTID } from './Constants';

interface AuthProviderProps {
    children?: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = (props) => {

    const onRedirectCallback = (appState: AppState | undefined) => {
        if (appState?.returnTo) {
            // We have no access to the router at this point. 
            // Page loaded so we have stored that token, just reload to desired hash.
            window.location.href = appState?.returnTo;
        }
    };

    return (
        <Auth0Provider
            domain={AUTH_DOMAIN}
            clientId={AUTH_CLIENTID}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
        >
            {props.children}
        </Auth0Provider>
    );
};

export default AuthProvider;