import { useState } from 'react';
import { Divider, Grid, IconButton, Skeleton, Menu, MenuItem, Typography, Tooltip } from '@mui/material';
import { NormalizedDocumentType, TransactionDirection, TransactionModel } from '../../gql-types.generated';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import AcknowledgementIcon from '@mui/icons-material/HandshakeOutlined';
import { ActiveStatusChip, IconAvatar, StatusChip, CardChip, CardFooter, RootCard, RootCardHeader } from '../../util/SharedStyles';

interface TransactionListItemProps {
    loading?: boolean | false;
    transaction?: TransactionModel | undefined;
    canDelete?: boolean;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    onXPathClick: (id: string) => void;
}

const TransactionListItem: React.FC<TransactionListItemProps> = (props: TransactionListItemProps) => {
    const { transaction, loading = false, canDelete = false } = props;
    const name = transaction?.name;
    const description = transaction?.description;
    const isActive = transaction?.isActive;
    const isBillable = transaction?.isBillable;
    const billableFactor = transaction?.billableFactor;
    const direction = transaction?.direction;
    const ediStandardName = transaction?.ediStandard?.name;
    const id = transaction?.id as string;
    const docType = transaction?.normalizedDocumentType;
    const xPathId = transaction?.transactionXPath?.id;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(id);
        }
        else if (buttonDataSet.operation === "xpath")
        {
            props.onXPathClick(id);
        }
        
        setAnchorEl(null);
    };

    let action = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    const directionIcon = (direction === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;
    
    let avatar= loading ? (
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
    ) : (
        <IconAvatar aria-label={direction?.toLowerCase()}>
            {directionIcon}
        </IconAvatar> 
    );
 
    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        name 
    );

    let subheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
    ) : (
       description
    );
    
    let statusChip = isActive ? <ActiveStatusChip label="Active" /> : <StatusChip label="Disabled" />;
    
    let billableChip;
    if (isBillable) {
        let factorLabel = (billableFactor && billableFactor !== 1) ? `: ${billableFactor}` : '';
        let chipLabel = `Billable${factorLabel}`;
        billableChip = <CardChip label={chipLabel} />;
    }
    
    let chipsElement = (
        <Grid container item direction="row" gap={'8px'} justifyContent="flex-end" alignItems="center" xs={'auto'}>
            {billableChip}
            {statusChip}
        </Grid>
    )

    let chipContainerJustify = "flex-end";
    let ediStandardElement = null;
    if (ediStandardName) {
        chipContainerJustify = "space-between";
        ediStandardElement = (
            <Grid container item direction="row" justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <Typography>{ediStandardName}</Typography>
            </Grid>
        )
    };

    const xPathButtonText = `${xPathId ? "Edit" : "Add"} Search Helper`;

    return (
        <RootCard>
            <RootCardHeader
                avatar={avatar}
                title={title} 
                subheader={subheader}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light/>
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {ediStandardElement}                        
                            {chipsElement}
                        </>
                    )
                }
            </CardFooter>
           
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                { canDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    >
                    Delete    
                </MenuItem>
                }
                <MenuItem
                    data-operation="xpath"
                    onClick={handleMenuClose}
                    >
                    {xPathButtonText}
                </MenuItem>
            </Menu> 
        </RootCard>
    );
};

export default TransactionListItem;