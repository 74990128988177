import { ChangeEvent, useEffect, useState, useRef } from 'react';
import { debounce } from 'lodash';
import { Grid, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { BlankMenuItem } from '../../util/SharedStyles';
import CloseIcon from '@mui/icons-material/Close';
import { EdiStaticFileDocumentType } from '../../gql-types.generated';
import { Viewer } from '../../util/Constants';
import FilterBar from './FilterBar';

interface StaticFilesFilterBarProps {
    id?: string;
    loading: boolean;
    visible: boolean;
    viewer: Viewer | undefined;
    onClose: () => void;
    onFilterChanged: (
        filterDescription: string | undefined, 
        filterFileName: string | undefined, 
        filterDocumentType: EdiStaticFileDocumentType | undefined, 
    ) => void;
}

const EDIStaticFilesFilterBar: React.FC<StaticFilesFilterBarProps> = props => {
    const { id, loading = false, visible = false, viewer, onFilterChanged } = props;
    const [debounceOn, setDebounceOn] = useState(false);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterFileName, setFilterFileName] = useState<string | undefined>(undefined);
    const [filterDocumentType, setFilterDocumentType] = useState<EdiStaticFileDocumentType | undefined>(undefined);
    const [filterBarFirstLoad, setFilterBarFirstLoad] = useState(true);
    
    const fieldsDisabled = visible && loading;

    // Debounced function would be recreated on every render, so to prevent this,
    // Wrap in React useRef to store the debounced function across renders
    const debouncedOnFilterChanged = useRef(
        debounce((descriptionFilterValue, fileNameFilterValue, documentTypeFilterValue) => {
            // once the delay time has elapsed, call parent method to handle filter changes to reload
            onFilterChanged(descriptionFilterValue, fileNameFilterValue, documentTypeFilterValue);
        }, 1000)
    ).current;

    useEffect(() => {
        // need to send out an initial fetch 
        onFilterChanged(filterDescription, filterFileName, filterDocumentType);
       
        // give a little time delay before turning on debounce so that the initial 
        // filter listeners have time trigger the first user search
        // If don't do this, the debouncer will delay the initial search, causing a
        // brief display of either "no users found" or the results from the previous session
        setTimeout(() => {
            setDebounceOn(true);
        }, 200);
    }, []);

    // When the component goes to be unmounted, cancel the debounced function in case it is in progress.
    useEffect(() => {
        return () => {
            debouncedOnFilterChanged.cancel();
        };
    }, [debouncedOnFilterChanged]);

    useEffect(() => {
        // checking for any changes, even all filters off, however we don't want it firing immediately on load
        if (!filterBarFirstLoad) {
            if (debounceOn !== true) {
                onFilterChanged(filterDescription, filterFileName, filterDocumentType);
            }
            else {
                debouncedOnFilterChanged(filterDescription, filterFileName, filterDocumentType);
            }
        } else {
            setFilterBarFirstLoad(false);
        }
    }, [filterDescription, filterFileName, filterDocumentType]);

    // get the list of document types for the filter dropdown
    const docTypes = Object.values(EdiStaticFileDocumentType); 

    const onCloseClick = () => {
        // remove any filters
        clearFilters();

        // call parent to hide bar
        props.onClose();
    };

    const clearFilters = () => {
        clearDescriptionFilter();
        clearFileNameFilter();
        setFilterDocumentType(undefined);
    };

    const clearDescriptionFilter = () => {
        setFilterDescription(undefined);
    };

    const clearFileNameFilter = () => {
        setFilterFileName(undefined);
    };
  
    const getDocumentTypeFilterList = () => {
        if (docTypes && docTypes.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                docTypes.map((type: EdiStaticFileDocumentType) => (
                    <MenuItem
                        key={type}
                        value={type}
                        disabled={fieldsDisabled}
                    >
                        {type.toUpperCase()}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
    
    const onDescriptionFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterDescription(nameValue);
    };

    const onFileNameFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

        let nameValue = event.target.value;
        setFilterFileName(nameValue);
    };
    
    const onDocumentTypeFilterChange = (event: SelectChangeEvent<string>) => {
        const {
            target: { value },
        } = event;
        
        let currentValue = value?.length > 0 ? value as EdiStaticFileDocumentType : undefined;
        
        setFilterDocumentType(currentValue);
    };
    
    const descriptionFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear description filter"
                    onClick={clearDescriptionFilter}
                    disabled={fieldsDisabled}
                >
                    {filterDescription && filterDescription.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };

    const fileNameFilterProps = {
        endAdornment: (
            <InputAdornment position="end">
                <IconButton
                    aria-label="clear file name filter"
                    onClick={clearFileNameFilter}
                    disabled={fieldsDisabled}
                >
                    {filterFileName && filterFileName.length > 0 ? <CloseIcon fontSize='small' sx={{ padding: '2px' }} /> : null}
                </IconButton>
            </InputAdornment>
        )
    };
   
    return (
        <FilterBar id={id} visible={visible} onClose={onCloseClick}>
            <Grid item xs={2}>
                <TextField
                    itemID="formatter-filter-file-name"
                    fullWidth
                    value={filterFileName ?? ''}
                    label="File Name"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'file name', 'maxLength': 50, }}
                    InputProps={fileNameFilterProps}
                    onChange={onFileNameFilterChange}
                    autoComplete="off"
                    data-cy="formatter-filter-file-name-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={2}>
                <TextField
                    itemID="formatter-filter-description"
                    fullWidth
                    value={filterDescription ?? ''}
                    label="Description"
                    disabled={fieldsDisabled}
                    inputProps={{ 'aria-label': 'description', 'maxLength': 50, }}
                    InputProps={descriptionFilterProps}
                    onChange={onDescriptionFilterChange}
                    autoComplete="off"
                    data-cy="formatter-filter-description-filter"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={1} lg={2}>
                <FormControl variant="standard" fullWidth disabled={fieldsDisabled}>
                    <InputLabel id="formatter-filter-doc-type-label">Document Type</InputLabel>
                    <Select
                        labelId="formatter-filter-doc-type-label"
                        aria-labelledby="formatter-filter-doc-type-label"
                        id="formatter-filter-doc-type"
                        value={filterDocumentType ?? ''}
                        MenuProps={{
                            'aria-label': 'document type',
                        }}
                        onChange={onDocumentTypeFilterChange}
                        data-cy="formatter-filter-doc-type"
                    >
                        {getDocumentTypeFilterList()}
                    </Select>
                </FormControl>
            </Grid>
        </FilterBar>
    );
};

export default EDIStaticFilesFilterBar;