import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Typography, styled } from "@mui/material";
import { BlankMenuItem } from "../../util/SharedStyles";
import { fetchErpSchemasByTransactionId } from "../../features/EDIContainer/EDIContainerActions";
import { selectTransactionSchemas } from "../../features/EDIContainer/EDIContainerSlice";
import { fetchEligibleNotificationFormatters } from "../../features/ClientDetails/ClientDetailsActions";
import { selectEligibleNotificationFormatters } from "../../features/ClientDetails/ClientDetailsSlice";
import { ClientTransactionModel, UpsertClientTransactionInput, UserRole, NotificationFormatterModel, ErpTransactionSchemaModel } from "../../gql-types.generated";
import { emptyGuid } from '../../util/Constants';
import AddEditDialog from "./AddEditDialog";


export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

export enum NotificationType {
    Notify = 'Notify',
    Bulk = 'Bulk Notify',
    Disabled = 'Disabled'
}
interface EditClientTransactionDialogProps {
    isOpen: boolean;
    viewerRole?: UserRole | undefined;
    clientId: string;
    clientTransaction: ClientTransactionModel;
    clientHasNotificationRecipients?: boolean;
    isReadOnly: boolean;
    onClose: () => void;
    onSave: (
        clientTransactionData: UpsertClientTransactionInput
    ) => void;
    error?: Error | undefined;
}

const EditClientTransactionDialog: React.FC<EditClientTransactionDialogProps> = props => {
    const { isOpen, viewerRole, clientId, clientTransaction, isReadOnly, onClose, onSave, error, clientHasNotificationRecipients = false } = props;
    const dispatch = useAppDispatch();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [erpSchemaId, setErpSchemaId] = useState<string | undefined>(undefined);
    const [formatterId, setFormatterId] = useState<string | undefined>(undefined);
    const [isViewMode, setIsViewMode] = useState(false);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isNotify, setIsNotify] = useState<boolean>(false);
    const [isBulkNotify, setIsBulkNotify] = useState<boolean>(false);
    const [emailNotificationType, setEmailNotificationType] = useState<NotificationType>(NotificationType.Disabled);
    const [fileNamePattern, setFileNamePattern] = useState<string | undefined>(undefined);

    const transactionSchemas = useAppSelector(selectTransactionSchemas);
    const transactionFormatters = useAppSelector(selectEligibleNotificationFormatters);
  
    const setFromClientTransaction = useCallback(() => {
        setErpSchemaId(clientTransaction?.erpTransactionSchema?.id);
        setFormatterId(clientTransaction?.notificationFormatter?.id);
        setFileNamePattern(clientTransaction?.fileNamePattern ?? undefined);
                
        if (clientTransaction?.isActive !== null && clientTransaction?.isActive !== undefined) {
            setIsActive(clientTransaction?.isActive as boolean);
        }
        if ((clientTransaction?.notify !== null && clientTransaction?.notify !== undefined) || (clientTransaction?.bulkNotify !== null && clientTransaction?.bulkNotify !== undefined)) {
            if (clientTransaction?.notify === true) {
                setEmailNotificationType(NotificationType.Notify);
                setIsNotify(true);
                setIsBulkNotify(false);
            } else if (clientTransaction?.bulkNotify === true) {
                setEmailNotificationType(NotificationType.Bulk);
                setIsNotify(false);
                setIsBulkNotify(true);
            } else {
                setEmailNotificationType(NotificationType.Disabled);
                setIsNotify(false);
                setIsBulkNotify(false);
            }
        } 
    },[clientTransaction]);

    useEffect(() => {
        let transactionId = clientTransaction?.transaction?.id;
        if (transactionId) {
            dispatch(fetchErpSchemasByTransactionId(transactionId));
            dispatch(fetchEligibleNotificationFormatters(transactionId));
        }
    }, [clientTransaction?.transaction?.id]);

    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setIsViewMode(false);
            setToDefaults();
            
        } else {
            setIsViewMode(isReadOnly);
            setFromClientTransaction();
        }
    }, [isOpen, isReadOnly, setFromClientTransaction]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly);
    }, [isReadOnly]);

    const setToDefaults = () => {
        setErpSchemaId(undefined);
        setFormatterId(undefined);
        setIsActive(true);
        setIsNotify(false);
        setIsBulkNotify(false);
        setEmailNotificationType(NotificationType.Disabled);
        setFileNamePattern(undefined);
    };

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            setFromClientTransaction();
        } else {
            closeEditDialog();
        }
    };

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave({
                clientId: clientId,
                id: clientTransaction?.id,
                isActive: isActive,
                erpTransactionSchemaId: erpSchemaId,
                notificationFormatterId: formatterId,
                transactionId: clientTransaction?.transaction?.id,
                notify: isNotify,
                bulkNotify: isBulkNotify,
                fileNamePattern: fileNamePattern
            } as UpsertClientTransactionInput);
        }
    };

    const closeEditDialog = () => {
        onClose();
    };

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };

    const getViewFields = () => {
        let schemaText = clientTransaction?.erpTransactionSchema?.description ? clientTransaction?.erpTransactionSchema.description : "ERP Schema Not Set";
        let formatterText = clientTransaction?.notificationFormatter?.description ? clientTransaction?.notificationFormatter.description : "Notification Formatter Not Set";
        
        return (
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='transaction active'>Client Transaction Active</Typography>
                    <Typography variant='body1'>{isActive ? 'Active' : 'Not Active'}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='selected-schema'>ERP Schema</Typography>
                    <Typography variant='body1'>{schemaText}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='body type'>Email Notification</Typography>
                    <Typography variant='body1'>{emailNotificationType}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='selected-formatter'>Notification Formatter</Typography>
                    <Typography variant='body1'>{formatterText}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='file name pattern'>File Name Pattern</Typography>
                    <Typography variant='body1'>{fileNamePattern}</Typography>
                </Grid>
            </GridContainer>
        )
    };

    const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsActive(event.target.checked);
    };

    const onNotificationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        let type = event.target.value as NotificationType;
        setEmailNotificationType(type);
        if (type === NotificationType.Bulk) {
            setIsNotify(false);
            setIsBulkNotify(true);
        } else if (type === NotificationType.Notify) {
            setIsNotify(true);
            setIsBulkNotify(false);
        } else {
            setIsNotify(false);
            setIsBulkNotify(false);
        }
    };

    const onSchemaChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setErpSchemaId(event.target.value);
        } else {
            setErpSchemaId(undefined);
        }
    };

    const onFormatterChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setFormatterId(event.target.value);
        } else {
            setFormatterId(undefined);
        }
    };

    const onFileNamePatternChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        if (event.target.value) {
            setFileNamePattern(event.target.value);
        } else {
            setFileNamePattern(undefined);
        }
    };

    const getSchemaOptions = () => {
        if (transactionSchemas && transactionSchemas.length) {
            let items = [];
            // using emptyGuid value to pass to backend as way to indicate to save a null value
            const blankItem = <BlankMenuItem key="0" value={emptyGuid}></BlankMenuItem>;
            const dynamicItems = transactionSchemas.map((schema: ErpTransactionSchemaModel) => (
                <MenuItem
                    key={schema.id}
                    value={schema.id}
                >
                {schema.description}
                </MenuItem>
            ));
            items.push(blankItem);
            items.push(...dynamicItems);
            return items;
        }
        return null;
    };

    const getSchemaContent = () => {
        if (transactionSchemas && transactionSchemas.length) {
            return (
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="dialog-edit-transaction-erp-schema-label">ERP Schema</InputLabel>
                    <Select
                        labelId="dialog-edit-transaction-schema-label"
                        aria-labelledby="dialog-edit-transaction-schema-label"
                        value={erpSchemaId ?? ''}
                        onChange={onSchemaChange}
                        disabled={submitted}
                        MenuProps={{
                            'aria-label': 'erp schema selection',
                        }}
                        data-cy="dialog-edit-transaction-schema"
                    >
                        {getSchemaOptions()}
                    </Select>
                </FormControl>
            );
        } else {
            return "No ERP Schemas associated with this transaction"
        }
    }
    
    const getFormatterOptions = () => {
        if (transactionFormatters && transactionFormatters.length) {
            let items = [];
            // using emptyGuid value to pass to backend as way to indicate to save a null value
            const blankItem = <BlankMenuItem key="0" value={emptyGuid}></BlankMenuItem>;
            const dynamicItems = transactionFormatters.map((formatter: NotificationFormatterModel) => (
                <MenuItem
                    key={formatter.id}
                    value={formatter.id}
                >
                {formatter.description}
                </MenuItem>
            ));
            items.push(blankItem);
            items.push(...dynamicItems);
            return items;
        }
        return null;
    };

    const getFormatterContent = () => {
        if (transactionFormatters && transactionFormatters.length) {
            return (
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="dialog-edit-transaction-formatter-label">Notification Formatter</InputLabel>
                    <Select
                        labelId="dialog-edit-transaction-formatter-label"
                        aria-labelledby="dialog-edit-transaction-formatter-label"
                        value={formatterId ?? ''}
                        onChange={onFormatterChange}
                        disabled={submitted}
                        MenuProps={{
                            'aria-label': 'notification formatter selection',
                        }}
                        data-cy="dialog-edit-transaction-formatter"
                    >
                        {getFormatterOptions()}
                    </Select>
                </FormControl>
            );
        } else {
            return "No Notification Formatters associated with this transaction"
        }
    };

    const getEditFields = () => {
        return (
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-edit-transaction-is-active"
                                disabled={submitted}
                                checked={isActive as boolean}
                                inputProps={{
                                    'aria-label': 'is Transaction Active'
                                }}
                                onChange={onIsActiveChange}
                                data-cy="dialog-edit-transaction-is-active"
                            />
                        }
                        label="Client Transaction Active"
                    />
                </Grid>
                <Grid item xs={12}>
                    {getSchemaContent()}
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="dialog-edit-transaction-notification-type-label"><Typography variant="caption">Email Notification</Typography></FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="dialog-edit-transaction-notification-type-label"
                            name="notification-type-radio-buttons-group"
                            value={emailNotificationType}
                            onChange={onNotificationTypeChange}
                            data-cy="dialog-edit-transaction-notification-type"
                        >
                            <FormControlLabel value={NotificationType.Notify} control={<Radio />} label={NotificationType.Notify} disabled={submitted} aria-label={NotificationType.Notify} />
                            <FormControlLabel value={NotificationType.Bulk} control={<Radio />} label={NotificationType.Bulk} disabled={submitted} aria-label={NotificationType.Bulk} />
                            <FormControlLabel value={NotificationType.Disabled} control={<Radio />} label={NotificationType.Disabled} disabled={submitted} aria-label={NotificationType.Disabled} />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    {getFormatterContent()}
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-edit-transaction-file-name-pattern"
                        fullWidth
                        disabled={submitted}
                        value={fileNamePattern}
                        label="File Name Pattern"
                        inputProps={{
                            'aria-label': 'file name pattern',
                            'maxLength': 255,
                        }}
                        onChange={onFileNamePatternChange}
                        autoComplete="off"
                        data-cy="dialog-edit-transaction-file-name-pattern"
                        variant="standard"
                    />
                </Grid>
            </GridContainer>
        )
    };

    const getContent = () => {
        return isViewMode ? getViewFields() : getEditFields();
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={clientTransaction?.id}
            entityName="Client Transaction"
            maxWidth='xs'
            warningMessage={(isBulkNotify || isNotify) && !clientHasNotificationRecipients ? "Transaction is set to produce notifications, but there are no notification recipients set for this client.\nGo to the NOTIFICATIONS tab to configure them." : undefined}
            validate={validation}
            onCancel={onEditCancel}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
            isReadOnly={isViewMode}
            onViewModeEdit={viewModeEditForm}
            canEditFromReadOnly={true}
            viewerRole={viewerRole}
        >
            {getContent()}
        </AddEditDialog>
    );
}

export default EditClientTransactionDialog;