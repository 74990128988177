import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { PartnerNotificationRecipientsModel, GetPartnerNotificationRecipientsInput, GetPartnerNotificationRecipientsQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PARTNER_NOTIFICATION_RECIPIENTS = gql`
    query getPartnerNotificationRecipients($input: GetPartnerNotificationRecipientsInput!) {
        partnerNotificationRecipients (input: $input) {
            nodes {
                id
                description
                notificationType
                recipients
                receivesNotifications
                receivesErrors
            }
        }
    }
`;

export const queryPartnerNotificationRecipients = async (
    partner: ApolloClient<NormalizedCacheObject>,
    input: GetPartnerNotificationRecipientsInput
) => {
    const { partnerId, description, notificationType, receivesErrors, receivesNotifications, recipients } = input as GetPartnerNotificationRecipientsInput;
    const { data, errors } = await partner.query<GetPartnerNotificationRecipientsQuery>({
        variables: {
            input: {
                partnerId,
                description,
                notificationType,
                receivesErrors,
                receivesNotifications,
                recipients
            }
        },
        query: QUERY_PARTNER_NOTIFICATION_RECIPIENTS,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the PartnerNotificationRecipients query.');
    }

    return data?.partnerNotificationRecipients?.nodes;
}