import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertAttachmentInput, UpsertPartnerAttachmentPayload, UpsertPartnerAttachmentInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRADING_PARTNER_ATTACHMENTS = gql`
    mutation upsertPartnerAttachments($input: UpsertPartnerAttachmentInput!){
        upsertPartnerAttachment(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertTradingPartnerAttachments = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        attachments?: UpsertAttachmentInput[];
        partnerId: string;
    } 
): Promise<UpsertPartnerAttachmentPayload | undefined> => {
    const { attachments, partnerId } = options as UpsertPartnerAttachmentInput;
    const { data, errors } = await client.mutate<{ upsertPartnerAttachment: UpsertPartnerAttachmentPayload}>({
        variables: { 
            input: { 
                attachments,
                partnerId,
            } 
        },
        mutation: MUTATION_UPSERT_TRADING_PARTNER_ATTACHMENTS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add partner attachments.');
    }
    if (data && data.upsertPartnerAttachment) {
        return data.upsertPartnerAttachment;
    }

    return undefined;
};