import { ChangeEvent, useEffect, useState } from "react";
import { CounterModel, CounterSegmentType, UpsertCounterInput } from "../../gql-types.generated";
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, styled, TextField } from "@mui/material";
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '400px', 
}));

interface DialogProps {
    isOpen: boolean;
    counter?: CounterModel | null | undefined;
    onClose: () => void;
    onSave: (counter: UpsertCounterInput) => void;
    error?: Error | undefined;
}

const CounterDialog: React.FC<DialogProps> = props => {
    const { isOpen, counter, onClose, onSave, error } = props;
    const id = counter?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [senderId, setSenderId]= useState<string>('');
    const [controlNumber, setControlNumber]= useState<string>('');
    const [segment, setSegment]= useState<CounterSegmentType>(CounterSegmentType.Ffo);
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    useEffect(() => {
        if (counter){
            setFromCounter();
        } else {
            setToDefaults();
        }
    }, [counter]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
        else {
            setFromCounter();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setSenderId('');
        setControlNumber('');
        setSegment(CounterSegmentType.Ffo);
    }

    const setFromCounter = () => {
        if (counter) {
            if (counter.senderId) {
                setSenderId(counter.senderId);
            }
            if (counter.controlNumber) {
                setControlNumber(counter.controlNumber);
            }
            if (counter.segment) {
                setSegment(counter.segment);
            }
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && senderId?.trim().length > 0 && segment !== undefined;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave({
            senderId,
            controlNumber,
            id,
            segment,
        } as UpsertCounterInput);
    };

    // onChange handlers
    const onSenderIdChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setSenderId(event.target.value);
    };
    const onControlNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setControlNumber(event.target.value);
    };
    const onSegmentChange = (event: SelectChangeEvent<string | null>) => {
        if (event.target.value) {
            setIsFormDirty(true);
            setSegment(event.target.value as CounterSegmentType);
        }
    };
    
    const segmentTypes = Object.values(CounterSegmentType);
    
    const getSegmentTypeDropList = () => {
        if (segmentTypes && segmentTypes.length) {
            return (
                segmentTypes.map((type: CounterSegmentType) => (
                    <MenuItem
                        key={type}
                        value={type}
                    >
                        {type.toUpperCase()}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    // property props
    const senderIdProps = {        
        'aria-label': 'sender id',
        'maxLength': 35,
    };
    const controlNumberProps = {        
        'aria-label': 'control number',
        'maxLength': 9,
    };
    const segmentTypeMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };
   
    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Counter Entry"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-counter-sender-id"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={senderId}
                        label="Sender ID"
                        inputProps={senderIdProps}
                        onChange={onSenderIdChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-counter-sender-id"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        itemID="dialog-counter-control-number"
                        fullWidth
                        disabled={submitted}
                        value={controlNumber}
                        label="Control Number"
                        inputProps={controlNumberProps}
                        onChange={onControlNumberChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-counter-control-number"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                        <InputLabel id="dialog-counter-segment-label">Segment</InputLabel>
                        <Select
                            labelId="dialog-counter-segment-label"
                            aria-labelledby="dialog-counter-segment-label"
                            value={segment}
                            onChange={onSegmentChange}
                            disabled={submitted}
                            MenuProps={segmentTypeMenuProps}
                            data-cy="dialog-counter-segment"
                        >
                            {getSegmentTypeDropList()}
                        </Select>
                    </FormControl>
                </Grid>
            </GridContainer>
        </AddEditDialog>
    )
};

export default CounterDialog;