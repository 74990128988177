import { ChangeEvent, useEffect, useState } from "react";
import { Grid, TextField } from '@mui/material';

import { NoteModel, UserRole } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import NoteField from "../fields/NoteField";

interface NotesDialogProps {
    isOpen: boolean;
    note: NoteModel | undefined;
    isReadOnly?: boolean;
    canEditFromReadOnly?: boolean;
    viewerRole?: UserRole | undefined;
    onCancel: () => void;
    onClose: () => void;
    onSave: (
        text: string,
        title: string,
        id?: string | undefined
    ) => void;
    error?: Error | undefined;
}

const NotesDialog: React.FC<NotesDialogProps> = props => {
    const { isOpen, note, isReadOnly, canEditFromReadOnly = true, viewerRole, onCancel, onClose, onSave, error } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [noteContent, setNoteContent] = useState('');
    const [noteHeader, setNoteHeader] = useState('');
    const [isViewMode, setIsViewMode] = useState(false);

    useEffect(() => {
        if (note) {
            if (note.note) {
                setNoteContent(note.note);
            }
            if (note.header) {
                setNoteHeader(note.header);
            }
        } else {
            setDefaults();
        }
    }, [note]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly || false);
    }, [isReadOnly]);

    useEffect(() => {
        if (!isOpen) {
            setDefaults();
        }
    }, [isOpen]);

    const setDefaults = () => {
        setIsDirty(false);
        setSubmitted(false);
        setNoteContent('');
        setNoteHeader('');
    };

    const saveNote = () => {
        if (isDirty) {
            setSubmitted(true);
            onSave(noteContent, noteHeader, note?.id as string | undefined);
        }
    };

    const onError = () => {
        setSubmitted(false);
    }

    const IsFormValid = () => {
        return isDirty;
    }

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    }

    const onNoteChange = (newValue: string) => {
        setIsDirty(true);
        //setTimeout(() => {
        setNoteContent(newValue);
        //});
    }

    const onNoteTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        setNoteHeader(event.target.value);
    };

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            onCancel();
        } else {
            onClose();
        }
    }

    const titleProps = {
        'aria-label': 'Note Title',
        'maxLength': 100,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            isReadOnly={isViewMode}
            canEditFromReadOnly={canEditFromReadOnly}
            entityName="Note"
            id={note?.id as string | undefined}
            viewerRole={viewerRole}
            onCancel={onEditCancel}
            onClose={onClose}
            onSave={saveNote}
            onViewModeEdit={viewModeEditForm}
            validate={IsFormValid}
            onError={onError}
            error={error}
            maxWidth='md'
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-note-title"
                        fullWidth
                        disabled={submitted || isViewMode}
                        autoFocus
                        value={noteHeader}
                        label="Note Heading"
                        inputProps={titleProps}
                        onChange={onNoteTitleChange}
                        autoComplete="off"
                        data-cy="dialog-note-title"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <NoteField
                        value={noteContent}
                        disabled={isViewMode}
                        onChange={onNoteChange}
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
}

export default NotesDialog;