import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertSystemConfigurationPayload, UpsertSystemConfigurationInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_SYSTEM_CONFIGURATION = gql`
    mutation upsertSystemConfiguration($input: UpsertSystemConfigurationInput!){
        upsertSystemConfiguration(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertSystemConfiguration = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertSystemConfigurationData: UpsertSystemConfigurationInput
    }
): Promise<UpsertSystemConfigurationPayload | undefined> => {
    const { billingRecipientsEmail, errorRecipientsEmail, ediDirectRecipientsEmail, id } = args.upsertSystemConfigurationData;
    const { data, errors } = await client.mutate<{ upsertSystemConfiguration: UpsertSystemConfigurationPayload }>({
        variables: {
            input: {
                billingRecipientsEmail,
                errorRecipientsEmail,
                ediDirectRecipientsEmail,
                id,
            }
        },
        mutation: MUTATION_UPSERT_SYSTEM_CONFIGURATION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the system configuration save.');
    }
    if (data && data.upsertSystemConfiguration) {
        return data.upsertSystemConfiguration;
    }
    return undefined;
}