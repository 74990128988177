import { PagingResultModelOfTransactionErrorModel, TransactionErrorModel, ActionPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    errorLogPagingResult?: PagingResultModelOfTransactionErrorModel;
    errorLogList?: TransactionErrorModel[];
    errorLogRequestsInFlight: number;
    updateLogsStatus?: ActionPayload;
};

const initialState: SliceState = {
    error: undefined,
    errorLogPagingResult: undefined,
    errorLogList: undefined,
    errorLogRequestsInFlight: 0,
    updateLogsStatus: undefined
};

export const slice = createSlice({
    name: 'errorLogs',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorLogList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorLogRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.errorLogPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogsStatus = undefined;
        },

        fetchErrorLogListSuccess: (state, action: PayloadAction<PagingResultModelOfTransactionErrorModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.errorLogPagingResult = action.payload;
            let errors = state.errorLogList;
            if (!errors || !state.errorLogPagingResult.cursor?.previousPage) {
                errors = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!errors?.some(i => i.id === node?.id)) {
                        errors?.push(node as TransactionErrorModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.errorLogList = errors;
        },

        incrementErrorLogRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.errorLogRequestsInFlight += 1;
        },

        decrementErrorLogRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.errorLogRequestsInFlight > 0) state.errorLogRequestsInFlight -= 1;
        },

        captureUpdateLogsStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogsStatus = action.payload;
        },
    },

});

export const selectError = (state: RootState): Error | undefined => state.errorLogs.error;
export const selectErrorLogList = (state: RootState): TransactionErrorModel[] | undefined => state.errorLogs.errorLogList;
export const selectRequestsInFlight = (state: RootState): number => state.errorLogs.errorLogRequestsInFlight;
export const selectErrorLogPagingResult = (state: RootState): PagingResultModelOfTransactionErrorModel | undefined => state.errorLogs.errorLogPagingResult;
export const selectUpdateLogsStatus = (state: RootState): ActionPayload | undefined => state.errorLogs.updateLogsStatus;

export const {
    clearError,
    fetchError,
    clearState,
    fetchErrorLogListSuccess,
    incrementErrorLogRequestsInFlight,
    decrementErrorLogRequestsInFlight,
    captureUpdateLogsStatus
} = slice.actions;

export default slice.reducer;