import { useState } from 'react';
import { Divider, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import theme from '../../Theme';

import { ClientContactModel, PartnerContactModel, EmailModel, PhoneModel, UserRole } from '../../gql-types.generated';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { CardAvatar, CardAvatarInitials, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';
import { getPrimaryPhoneFromArray, getPrimaryEmailFromArray } from '../../util/PrimaryFromModelArray';
import { nameStringToInitials } from '../../util/Common';

interface ContactListItemProps {
    viewerRole: UserRole | undefined;
    contact: ClientContactModel | PartnerContactModel;
    deleteContact: (id: string) => void;
    editContact: (id: string) => void;
    addContactNote: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const ContactListItem: React.FC<ContactListItemProps> = (props: ContactListItemProps) => {
    const { contact, clickAction, editContact, deleteContact, addContactNote } = props;
    const { id, name } = contact;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (id) {
            clickAction(id);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // this button could be inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        let buttonDataSet = (event.target as HTMLElement).dataset;

        if (buttonDataSet.operation === "edit") {
            if (id) {
                editContact(id);
            }
        }
        else if (buttonDataSet.operation === "note") {
            if (id) {
                addContactNote(id);
            }
        }
        else if (buttonDataSet.operation === "delete") {
            if (id) {
                deleteContact(id);
            }
        }
        setAnchorEl(null);
    };
  
    const canEdit = viewerCanEdit(props.viewerRole);
    const contactAction = (
        canEdit ? 
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
        :
        null
    );

    const getPhoneContent = () => {
        if (contact.phones && contact.phones.length) {
            const primary = getPrimaryPhoneFromArray(contact.phones as PhoneModel[]);
            if (primary && primary.phoneNumber) {
                return (
                    <Stack direction="row" spacing={.5}>
                        <PhoneAndroid fontSize="small" htmlColor={theme.palette.grayscale.dark} />
                        <Typography variant="subtitle2">{primary.phoneNumber}</Typography>
                    </Stack>
                );
            }
        }
    }

    const getEmailContent = () => {
        if (contact.emails && contact.emails.length) {
            const primary = getPrimaryEmailFromArray(contact.emails as EmailModel[]);
            if (primary && primary.email) {
                return (primary.email);
            }
        }
    }

    const initials = name ? nameStringToInitials(name, 2) : '';

    return (
        <ClickableRootCard variant="outlined" onClick={cardClick}>
            <RootCardHeader
                avatar={
                    <CardAvatar background={theme.palette.secondary.main} foreground={theme.palette.secondary.contrastText} aria-label="Initials" >
                        {<CardAvatarInitials>{initials}</CardAvatarInitials>}
                    </CardAvatar>
                }
                title={name}
                titleTypographyProps={{ fontSize: '18px' }}
                subheader={getEmailContent()}
                subheaderTypographyProps={{ variant: 'caption' }}



                action={contactAction}
            />
            <Divider light />
            <CardFooter justify="flex-start">
                {getPhoneContent()}
            </CardFooter>
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    data-operation="note"
                    onClick={handleMenuClose}
                >
                    Add Note
                </MenuItem>
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                >
                    Delete
                </MenuItem>
            </Menu>
        </ClickableRootCard>
    );

};

export default ContactListItem;