import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertTransactionPayload, TransactionDirection, Maybe, UpsertTransactionInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRANSACTION = gql`
    mutation upsertTransaction($input: UpsertTransactionInput!){
        upsertTransaction(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertTransaction = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertTransactionData: UpsertTransactionInput
    }
): Promise<UpsertTransactionPayload | undefined> => {
    const { billableFactor, description, direction, documentTransformationSchema, ediStandardId, id, isActive, isBillable, name, normalizedDocumentType, skipTargetDocumentCreation } = args.upsertTransactionData;
    const { data, errors } = await client.mutate<{ upsertTransaction: UpsertTransactionPayload}>({
        variables: { input: { billableFactor, description, direction, documentTransformationSchema, ediStandardId, id, isActive, isBillable, name, normalizedDocumentType, skipTargetDocumentCreation} },
        mutation: MUTATION_UPSERT_TRANSACTION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the transaction save.');
    }
    if (data && data.upsertTransaction) {
        return data.upsertTransaction;
    }

    return undefined;
};