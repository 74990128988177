import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionMapModel, DeleteByIdPayload, UpsertTransactionMapPayload, PagingResultModelOfTransactionMapModel } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    mapPagingResult?: PagingResultModelOfTransactionMapModel;
    mapList?: TransactionMapModel[];
    upsertMapStatus?: UpsertTransactionMapPayload;
    deleteMapStatus?: DeleteByIdPayload;
    mapRequestsInFlight: number;
}

const initialState: SliceState = {
    error: undefined,
    mapPagingResult: undefined,
    mapList: undefined,
    upsertMapStatus: undefined,
    deleteMapStatus: undefined,
    mapRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'maps',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.mapList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.mapRequestsInFlight = 0;
        },

        fetchMapListSuccess: (state, action: PayloadAction<PagingResultModelOfTransactionMapModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.mapPagingResult = action.payload;
            let maps = state.mapList;
            if (!maps || !state.mapPagingResult.cursor?.previousPage) {
                maps = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!maps?.some(i => i.id === node?.id)) {
                        maps?.push(node as TransactionMapModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.mapList = maps;
        },
        
        captureUpsertMapStatus: (state, action: PayloadAction<UpsertTransactionMapPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertMapStatus = action.payload;
        },

        captureDeleteMapStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteMapStatus = action.payload;
        },

        incrementMapRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.mapRequestsInFlight += 1;
        },

        decrementMapRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.mapRequestsInFlight > 0) state.mapRequestsInFlight -= 1;
        }
    },
});

export const selectError = (state: RootState): Error | undefined => state.maps.error;
export const selectMapList = (state: RootState): TransactionMapModel[] | undefined => state.maps.mapList;
export const selectUpsertMapStatus = (state: RootState): UpsertTransactionMapPayload | undefined => state.maps.upsertMapStatus;
export const selectDeleteMapStatus = (state: RootState): DeleteByIdPayload | undefined => state.maps.deleteMapStatus;
export const selectRequestsInFlight = (state: RootState): number => state.maps.mapRequestsInFlight;
export const selectMapPagingResult = (state: RootState): PagingResultModelOfTransactionMapModel | undefined => state.maps.mapPagingResult;

export const {
    clearError,
    fetchError,
    clearState,
    fetchMapListSuccess,
    captureUpsertMapStatus,
    captureDeleteMapStatus,
    incrementMapRequestsInFlight,
    decrementMapRequestsInFlight
} = slice.actions;

export default slice.reducer;