import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ErpTransactionSchemasQuery, GetErpTransactionSchemaInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_ERP_TRANSACTION_SCHEMA_LIST = gql`
    query erpTransactionSchemas($input: GetErpTransactionSchemaInput!) {
        erpTransactionSchemas (input: $input) {
          nodes {
            id
            transactionId
            description
            documentString
            sourceFileName
          }
        }
      }
`;

export const queryErpTransactionSchemaList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters: {
        id?: string | undefined;
        description?: string | undefined;
        transactionId?: string | undefined;
    }
) => {
    const { id, description, transactionId } = filters as GetErpTransactionSchemaInput;
    const { data, errors } = await client.query<ErpTransactionSchemasQuery>({
        query: QUERY_ERP_TRANSACTION_SCHEMA_LIST,
        variables: {
            input: {
                id,
                description,
                transactionId
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.erpTransactionSchemas?.nodes;
}