import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertErpProductPayload, Maybe } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PRODUCT = gql`
    mutation upsertErpProduct($input: UpsertErpProductInput!){
        upsertErpProduct(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertProduct = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        name?: string;
        description?: string;
        id?: Maybe<string>;
        isAptean?: boolean;
    }
): Promise<UpsertErpProductPayload | undefined> => {
    const { name, description, id, isAptean } = options;
    const { data, errors } = await client.mutate<{ upsertErpProduct: UpsertErpProductPayload}>({
        variables: { input: { name, description, id, isAptean } },
        mutation: MUTATION_UPSERT_PRODUCT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of ERP Product.');
    }
    if (data && data.upsertErpProduct) {
        return data.upsertErpProduct;
    }

    return undefined;
};