import { DateTime } from 'luxon';

export const getShortDateString = (isoDate: string, locale?: string) => {
    let dateTimeFromISO = DateTime.fromISO(isoDate);
    // If given a locale, use that, otherwise use system default
    if (locale) {
        let shortLocaleDate = dateTimeFromISO.setLocale(locale).toLocaleString();
        return shortLocaleDate;
    }
    let shortDate = dateTimeFromISO.toLocaleString();
    return shortDate;
};

export const getTimeString = (isoDate: string, options?: { includeSeconds?: boolean, use24HourFormat?: boolean, locale?: string }) => {
    const { includeSeconds=false, use24HourFormat=false, locale } = options || {};
    let dateTimeFromISO = DateTime.fromISO(isoDate);

    // either get DateTime from ISO using passed in locale or system default
    let localeDateTime = locale ? dateTimeFromISO.setLocale(locale) : dateTimeFromISO;

    // Based on option flags, either format Time for 24Hour and/or with/without seconds
    if (use24HourFormat) {
        let format24 = includeSeconds ? DateTime.TIME_24_WITH_SECONDS : DateTime.TIME_24_SIMPLE;
        let time24String = localeDateTime.toLocaleString(format24);
        return time24String;
    }
    let format = includeSeconds ? DateTime.TIME_WITH_SECONDS : DateTime.TIME_SIMPLE;
    let timeString = localeDateTime.toLocaleString(format);
    return timeString;
};

export const getFormattedDateTimeString = (isoDate: string, options?: { locale?: string, format?: Intl.DateTimeFormatOptions }) => {
    const { locale, format = DateTime.DATETIME_SHORT } = options || {};
    
    let dateTimeFromISO = DateTime.fromISO(isoDate);

    // either get DateTime from ISO using passed in locale or system default
    let localeDateTime = locale ? dateTimeFromISO.setLocale(locale) : dateTimeFromISO;

    let dateTimeString = localeDateTime.toLocaleString(format);
    return dateTimeString;
};

