import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, Link } from '@mui/material';
import PartnerIcon from "@mui/icons-material/SafetyDivider";
import DeleteIcon from '@mui/icons-material/Delete';
import { ActiveStatusChip, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, StatusChip, TabHeader, TabLabel } from '../../util/SharedStyles';

import { DetailWrapValue } from '../../util/SharedStyles';
import { getAvatarComponent, getWebsiteLink, useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { getShortDateString } from '../../util/DateTimeUtility';
import { EdiStandardModel, PartnerStatus, UpdateClientPartnerInput, RequestResult } from '../../gql-types.generated';
import { clearError, selectError, clearState, selectClientPartner, selectClientPartnerTransactions, selectPartnerTransactions, selectAttachments, selectDeleteClientPartnerStatus, captureDeleteClientPartnerStatus, captureUpdateClientPartnerStatus, selectUpdateClientPartnerStatus } from './ClientPartnerDetailsSlice';
import { fetchClientPartnerById, deleteClientPartnerData, updateClientPartner } from './ClientPartnerDetailsActions';
import TabPanel from '../../components/TabPanel';
import EditButton from '../../components/buttons/EditButton';
import ClientPartnerTransactionList from '../../components/lists/transactions/ClientPartnerTransactionList';
import ClientPartnerAttachmentList from '../../components/lists/attachments/ClientPartnerAttachmentList';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import DetailsPage from '../../components/DetailsPage';
import EditClientPartnerDialog from '../../components/dialogs/EditClientPartnerDialog';

interface ClientPartnerDetailsProps {
    viewer: Viewer | undefined;
}

const ClientTradingPartnerDetails: React.FC<ClientPartnerDetailsProps> = (props) => {
    //partnerId will be passed through router
    const { clientPartnerId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [partnerId, setPartnerId] = useState<string | undefined>(undefined);
    const [partnerName, setPartnerName] = useState<string | undefined>(undefined);
    const [clientName, setClientName] = useState<string | undefined>(undefined);
    const [clientDefaultCurrencyCode, setClientDefaultCurrencyCode] = useState<string | undefined>(undefined);
    const [partnerCode, setPartnerCode] = useState<string | undefined>(undefined);
    const [website, setWebsite] = useState<string | undefined>(undefined);
    const [ediStandard, setEdiStandard] = useState<EdiStandardModel | undefined>(undefined);
    const [activationDate, setActivationDate] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<PartnerStatus | undefined>(undefined);
    const [defaultCurrencyCode, setDefaultCurrencyCode] = useState<string | undefined>(undefined);
    const [tabValue, setTabValue] = useState(0);
    const [favIconErrored, setFavIconErrored] = useState<boolean>(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [navTitle, setNavTitle] = useState('');

    const deleteClientPartnerStatus = useAppSelector(selectDeleteClientPartnerStatus);
    const error = useAppSelector(selectError);
    const clientPartnerInfo = useAppSelector(selectClientPartner);
    const clientPartnerTransactions = useAppSelector(selectClientPartnerTransactions);
    const partnerTransactions = useAppSelector(selectPartnerTransactions);
    const attachments = useAppSelector(selectAttachments);
    const updateClientPartnerStatus = useAppSelector(selectUpdateClientPartnerStatus);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | JSX.Element | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined); //not currently used on this page, will remain undefined
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined); //not currently used on this page, will remain undefined

    useEffect(() => {
        // use clientPartnerId to get ClientPartnerDetails on render
        if (clientPartnerId) {
            dispatch(fetchClientPartnerById(clientPartnerId));
        }

        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh client partner when a successful mutation occurs
        if (updateClientPartnerStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: updateClientPartnerStatus.message as string,
                severity: ToastSeverity.Success
            }));
            
            // close the modify dialog
            onClientPartnerDialogClose();
            
            refreshClientPartnerData();
        }
    }, [updateClientPartnerStatus?.result]);

    useEffect(() => {
        if (deleteClientPartnerStatus?.result === RequestResult.Success) {
            // close dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteClientPartnerStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // send user back to where they came from
            navigate(-1);
        }
        else if (deleteClientPartnerStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteClientPartnerStatus.message as string);
        }
    }, [deleteClientPartnerStatus?.result])

    useEffect(() => {
        if (clientPartnerInfo) {

            if (clientPartnerInfo.createdTime) {
                let activatedDate = getShortDateString(clientPartnerInfo.createdTime);
                setActivationDate(activatedDate);
            }
            
            setDefaultCurrencyCode(clientPartnerInfo.defaultCurrencyCode as string);
            
            if (clientPartnerInfo.client) {
                let client = clientPartnerInfo.client;

                if (client.name) {
                    setClientName(client.name);
                }

                // force default to USD if not set yet
                setClientDefaultCurrencyCode(client.defaultCurrencyCode ?? "USD");
            }
            
            if (clientPartnerInfo.partner) {
                let partner = clientPartnerInfo.partner;

                if (partner.id) {
                    setPartnerId(partner.id);
                }
                if (partner.name) {
                    setPartnerName(partner.name);
                }
                if (partner.partnerCode) {
                    setPartnerCode(partner.partnerCode);
                }
                if (partner.ediStandards && partner.ediStandards.length > 0) {
                    setEdiStandard(partner.ediStandards[0] as EdiStandardModel);
                }
                if (partner.website) {
                    setWebsite(partner.website);
                }
                if (partner.statusCode) {
                    setStatus(partner.statusCode);
                }
            }

        }
    }, [clientPartnerInfo]);

    useEffect(() => {
        if (clientName && partnerName) {
            setNavTitle(clientName + ' / ' + partnerName);
        }
    }, [clientName, partnerName])

    useTitle(navTitle);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const refreshClientPartnerData = () => {
        dispatch(fetchClientPartnerById(clientPartnerId as string));
    };

    const onClientPartnerDialogSave = (
        clientPartnerInput: UpdateClientPartnerInput
    ) => {
        dispatch(
            updateClientPartner(clientPartnerInput)
        );
    };

    const onClientPartnerDialogClose = () => {
        setOpenModify(false);
        handleClearError();
        dispatch(captureUpdateClientPartnerStatus());
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        handleClearError();
        dispatch(captureDeleteClientPartnerStatus());
        setDeleteErrorMessage('');
    };
    
    const handlefavIconError = () => {
        setFavIconErrored(true);
    }

    let transactionsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Transactions</TabLabel>
            {clientPartnerTransactions && clientPartnerTransactions.length > 0 && (
                <CountBox>{clientPartnerTransactions.length}</CountBox>
            )}
        </Grid>
    );

    let attachmentsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Attachments</TabLabel>
            {attachments && attachments.length > 0 && (
                <CountBox>{attachments.length}</CountBox>
            )}
        </Grid>
    );


    const getClientPartnerDetails = () => {
        if (clientPartnerInfo) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Website</Typography>
                            <DetailWrapValue variant='body1' >{getWebsiteLink(website)}</DetailWrapValue>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >EDI Standard</Typography>
                            <Typography variant='body1' >{ediStandard?.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Activation Date</Typography>
                            <Typography variant='body1' >{activationDate}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Default Currency Code</Typography>
                            <Typography variant='body1' >{defaultCurrencyCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Client Currency Code</Typography>
                            <Typography variant='body1' >{clientDefaultCurrencyCode}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            );
        }
    };

    const getTabbedContent = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                        <TabHeader label={transactionsLabel} id='vertical-tab-0' />
                        <TabHeader label={attachmentsLabel} id='vertical-tab-1' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        <ClientPartnerTransactionList
                            viewerRole={viewer?.role}
                            clientPartnerId={clientPartnerId ?? ''}
                            clientPartnerTransactions={clientPartnerTransactions}
                            partnerTransactions={partnerTransactions}
                            refreshClientPartnerData={refreshClientPartnerData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={1}>
                        <ClientPartnerAttachmentList
                            viewerRole={viewer?.role}
                            clientPartnerId={clientPartnerId ?? ''}
                            attachments={attachments}
                            refreshClientPartnerData={refreshClientPartnerData}
                        />
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );
    }

    const getDialogs = () => [
        <EditClientPartnerDialog
            isOpen={openModify}
            clientPartnerInfo={clientPartnerInfo}
            onClose={onClientPartnerDialogClose}
            onSave={onClientPartnerDialogSave}
            error={error}
            key="edit-client-partner-dialog"
        />,
        <DeleteDialog
            isOpen={openDelete}
            heading={'Delete Trading Partner from Client'}
            message={`Are you sure you want to remove '${partnerName}' from '${clientName}'?`}
            id={clientPartnerInfo?.id as string}
            onConfirm={handleDeleteClientPartnerConfirm}
            onReject={onDeleteDialogClose}
            errorMessage={deleteErrorMessage}
            key="delete-dialog"
        />
    ];

    //edit current client partner
    const onEditClick = () => {
        setOpenModify(true);
    };

    const onDeleteClick = () => {
        dispatch(captureDeleteClientPartnerStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeleteClientPartnerConfirm = () => {
        if (clientPartnerId) {
            dispatch(deleteClientPartnerData(clientPartnerId));
        }
    };

    const canEdit = viewerCanEdit(viewer);

    let statusChip: undefined | JSX.Element;
    if (status !== undefined) {
        if (status === PartnerStatus.Active) {
            statusChip = <ActiveStatusChip label="Active" key="status-chip" />;
        } else if (status === PartnerStatus.New) {
            statusChip = <ActiveStatusChip label="New" key="status-chip" />;
        } else {
            statusChip = <StatusChip label="Inactive" key="status-chip" />;
        }
    }

    let titleText = clientName && partnerName ? (
        `${clientName} > Trading Partners > ${partnerName}`
    ) : '';

    const onDetailsHeaderClick = () => {
        if (partnerId) {
            let route = "/partner/" + partnerId;
            navigate(route);
        }
    };

    const getDetailsHeaderDisplay = () => {
        if (partnerId) {
            return (
                <Link
                    component="button"
                    variant="h6"
                    onClick={() => {
                        onDetailsHeaderClick();
                    }}
                >
                    {partnerName}
                </Link>
            );
        }
        return partnerName;
    };

    useEffect(() => {
        setDetailsRecordLoaded(!!clientPartnerInfo);
        if (clientPartnerInfo) {
            setDetailsPageTitle(titleText);
            setDetailsAV(getAvatarComponent(
                website,
                favIconErrored,
                partnerName,
                "Partner",
                <PartnerIcon />,
                handlefavIconError
            ));
            setDetailsHeader(getDetailsHeaderDisplay());
            setDetailsSubHeader(partnerCode);
            setDetailsChips(statusChip ? [statusChip] : undefined);
            if (canEdit && clientPartnerInfo.totalTransactions === 0) {
                setDetailsToolbarButtons([
                    <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={onDeleteClick}
                        key="delete-button"
                    >
                        Delete
                    </Button>
                ])
            }
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Client Partner button"
                    data-cy="edit-client-partner-button"
                />);
            } else {
                setDetailsEditButton(undefined);
            }
        }
    }, [clientPartnerInfo, partnerName, partnerCode, error, favIconErrored, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getClientPartnerDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    )
};

export default ClientTradingPartnerDetails;