import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { TransactionMapModel } from "../../gql-types.generated";
import { DialogDataGridWrapper } from "../../util/SharedStyles";
import TransactionMapPickerList from "../lists/TransactionMapPickerList";
import AddEditDialog from "./AddEditDialog";
interface TransactionMapPickerDialogProps {
    isOpen: boolean;
    availableMaps: TransactionMapModel[];
    existingMapIds: string[];
    onClose: () => void;
    onSave: (
        transactionMapId: string
    ) => void;
    error?: Error | undefined;
}

const TransactionMapPickerDialog: React.FC<TransactionMapPickerDialogProps> = props => {
    const { isOpen, availableMaps, existingMapIds, onClose, onSave, error } = props;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedMapId, setSelectedMapId] = useState<string>('');

    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setSelectedMapId("");
        }
    }, [isOpen]);

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(selectedMapId);
        }

        closeEditDialog();
    }
    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    }

    const closeEditDialog = () => {
        onClose();
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onMapSelection = (mapId: string) => {
        setIsFormDirty(true);
        if (mapId) {
            setSelectedMapId(mapId);
        } else {
            setSelectedMapId('');
        }
    }

    const getMapContent = () => {
        if (availableMaps && availableMaps.length > 0) {
            return (
                <TransactionMapPickerList
                    availableMaps={availableMaps}
                    existingMapIds={existingMapIds}
                    loading={false}
                    rowSelection={onMapSelection}
                />
            )
        } else {
            return "No transaction maps available to be added"
        }
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id="map-picker"
            entityName="Map"
            maxWidth='md'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <DialogDataGridWrapper container>
                <Grid item xs={12}>
                    {getMapContent()}
                </Grid>
            </DialogDataGridWrapper>

        </AddEditDialog>
    );
};

export default TransactionMapPickerDialog;