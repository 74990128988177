import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { CircularProgress, Grid, styled, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridFilterModel, GridLinkOperator, GridRowModel, GridRenderCellParams, GridOverlay, GridSortModel, GridEventListener, GridCallbackDetails, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteByIdPayload, NoteModel, RequestResult, UserRole } from "../../gql-types.generated";
import { TabContainer, TabToolbar, TabContent, TabDataGrid } from '../../util/SharedStyles';
import CreateNewButton from '../buttons/CreateNewButton';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { getShortDateString, getTimeString } from '../../util/DateTimeUtility';
import { getDataGridCellValuePreview } from '../../util/Common';
import DeleteDialog from '../dialogs/DeleteDialog';
import NotesDialog from '../dialogs/NotesDialog';
import SearchBar from '../SearchBar';
import NoRecordsMessage from '../NoRecordsMessage';
import NoResultsMessage from '../NoResultsMessage';
import GridCellDualVert from '../listItems/GridCellDualVert';

const FileCellContainer = styled(Grid)((props) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
}));

interface NotesListProps {
    viewerRole: UserRole | undefined;
    parentId: string;
    notes: NoteModel[] | undefined;
    saveNotes: (
        notes: NoteModel[],
    ) => void;
    deleteNote: (
        id: string,
    ) => void;
    upsertNoteResult: RequestResult | undefined;
    deleteNoteStatus: DeleteByIdPayload | undefined;
    error?: Error | undefined;
    clearError: () => void;
}



const NotesList: React.FC<NotesListProps> = props => {
    const { viewerRole, parentId, notes, saveNotes, deleteNote, upsertNoteResult, deleteNoteStatus, error, clearError } = props;
    const [searchText, setSearchText] = useState('');
    const [openNoteDetails, setOpenNoteDetails] = useState(false);
    const [selectedNote, setSelectedNote] = useState<NoteModel | undefined>(undefined); //TODO: string-> Note
    const [displayOnly, setDisplayOnly] = useState(true);
    const [noteRows, setNoteRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'header',
            sort: 'asc',
        },
    ]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const canEdit = viewerCanEdit(viewerRole);

    // Debounced function would be recreated on every render, so to prevent this,
    // Wrap in React useRef to store the debounced function across renders
    const debouncedOnFilterChange = useRef(
        debounce((searchValue: string) => {
            // once the delay time has elapsed, call actual method to handle filter changes
            onFilterChange(searchValue);
        }, 1000)
    ).current;

    useEffect(() => {
        setNoteRows(getNoteRows());
    }, [notes]);

    useEffect(() => {
        if (upsertNoteResult) {
            if (upsertNoteResult === RequestResult.Success) {
                onNotesDialogClose();
            }
            // error will come through props and flow through to Notes Dialog don't close note, leave open to display error
        }

    }, [upsertNoteResult]);

    useEffect(() => {
        if (deleteNoteStatus) {
            if (deleteNoteStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
            } else if (deleteNoteStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteNoteStatus?.message as string);
            }
        }
    }, [deleteNoteStatus]);

    const getSelectedRowNote = useCallback((rowId: string) => () => {
        if (rowId && notes?.length) {
            let note = notes.find(note => note.id === rowId);
            return note;
        }
        return undefined;
    }, [notes]);

    const deleteNoteHandler = useCallback((rowId: string) => () => {
        let note = getSelectedRowNote(rowId);
        if (note) {
            setSelectedNote(note);
            setOpenDelete(true);
        }
    }, [getSelectedRowNote]);

    const editNoteHandler = useCallback((rowId: string) => () => {
        let note = getSelectedRowNote(rowId);
        if (note) {
            setSelectedNote(note);
            setDisplayOnly(false);
            setOpenNoteDetails(true);
        }
    }, [getSelectedRowNote]);

    const onRowClick: GridEventListener<'rowClick'> = (params: GridRowParams, event: React.MouseEvent<HTMLElement>, details: GridCallbackDetails) => {
        const buttonCell = event.currentTarget.lastChild;
        const clicked = event.target;
        if (buttonCell?.contains(clicked as Node)) {
            event.defaultPrevented = true;
        } else {
            const rowId = params.id;
            if (rowId && notes?.length) {
                let note = notes.find(note => note.id === rowId);
                if (note) {
                    setSelectedNote(note);
                    setDisplayOnly(true);
                    setOpenNoteDetails(true);
                }
            }
        }
    };

    const onAddNote = () => {
        setSelectedNote(undefined);
        setDisplayOnly(false);
        setOpenNoteDetails(true);
    };

    const onDialogClose = () => {
        setSelectedNote(undefined);
        setDisplayOnly(false);
        clearError();
    };

    const onNotesDialogClose = () => {
        setOpenNoteDetails(false);
        onDialogClose();
    };

    const onNotesDialogSave = (text: string, title: string, id?: string | undefined) => {
        setSelectedNote(undefined);
        if (id && notes) {
            saveNotes([{ header: title, note: text, id: id } as NoteModel]);
        } else {
            if (notes) {
                saveNotes([{ header: title, note: text } as NoteModel]);
            }
        }
    };

    const onNoteViewEditCancel = () => {
        clearError();
        let currentNote = selectedNote;
        setSelectedNote(undefined);
        setTimeout(() => {
            setSelectedNote(currentNote);
        });
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected note
        if (selectedNote) {
            deleteNote(selectedNote.id as string);
        }
    };

    const noteToDisplayPreview = (params: GridValueGetterParams) => {
        let value = params.row.noteContent;
        let ret = getDataGridCellValuePreview(value);
        return ret;
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'notes-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && notes?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const noResultsOverlay = () => (
        <GridOverlay>
            {error && (
                <Typography variant="body2">
                    Unable to load data. Please try again later.
                </Typography>
            )}
            {!error && (notes && notes.length > 0 && searchText !== '') && (
                <NoResultsMessage topMargin={6} message="" />
            )}
        </GridOverlay>
    );

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        // set searchText with each value change
        let searchValue = event.target.value;
        setSearchText(searchValue);

        // since hit this function for each keystroke, debounce the actual search
        debouncedOnFilterChange(searchValue);
    };

    const clearSearch = () => {
        setSearchText('');
        onFilterChange('');
    };

    const onFilterChange = (searchValue: string) => {
        // create a new filter model using the new search value
        let newFilterModel: GridFilterModel = {
            items: [
                { id: 1, columnField: 'header', operatorValue: 'contains', value: searchValue },
                { id: 2, columnField: 'noteContent', operatorValue: 'contains', value: searchValue },
            ],
            linkOperator: GridLinkOperator.Or
        };
        // setting the new model as the filterModel here will trigger the datagrid to apply the new filters
        setFilterModel(newFilterModel);
    };

    const getNoteRows = () => {
        if (notes && notes.length > 0) {
            return notes.map((note) => {
                const { id, header, lastModifiedTime, lastModifiedByName } = note;
                const noteContent = note.note;
                return {
                    _raw: note,
                    id,
                    header,
                    noteContent,
                    lastModifiedTime,
                    lastModifiedBy: lastModifiedByName,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const noteColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'header',
                headerName: 'NAME',
                minWidth: 280,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let cellContainer = <FileCellContainer container item alignItems="center">{value}</FileCellContainer>;
                    if (value.length > 20) {
                        return (
                            <Tooltip title={value}>
                                {cellContainer}
                            </Tooltip>
                        );
                    } else {
                        return (
                            cellContainer
                        );
                    }

                }
            }, {
                field: 'noteContent',
                headerName: 'DESCRIPTION',
                cellClassName: 'ediDataGridLongDescriptionCell',
                valueGetter: noteToDisplayPreview,
                flex: 1,
                sortable: false,
            }, {
                field: 'lastModifiedTime',
                headerName: 'LAST UPDATED',
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    let timeValue = getTimeString(value, { includeSeconds: true });
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} boldHeader />
                    );
                },
                minWidth: 150,
                sortable: true,
            }, {
                field: 'lastModifiedBy',
                headerName: 'UPDATED BY',
                minWidth: 150,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editNoteHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteNoteHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteNoteHandler, editNoteHandler, canEdit],
    );

    return (
        <TabContainer>
            <TabToolbar justify="space-between">
                <SearchBar
                    searchText={searchText}
                    onSearch={handleSearch}
                    onClearSearch={clearSearch}
                />
                {canEdit &&
                    <CreateNewButton
                        text="New Note"
                        onClick={onAddNote}
                        data-cy="add-new-note"
                    />}
            </TabToolbar>
            <TabContent>
                <TabDataGrid
                    loading={notes === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    onRowClick={onRowClick}
                    aria-label="Notes List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={noteRows}
                    columns={noteColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    filterMode="client"
                    filterModel={filterModel}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoResultsOverlay: noResultsOverlay,
                    }}
                />
            </TabContent>
            <NotesDialog
                isOpen={openNoteDetails}
                note={selectedNote}
                isReadOnly={displayOnly}
                viewerRole={viewerRole}
                onCancel={onNoteViewEditCancel}
                onClose={onNotesDialogClose}
                onSave={onNotesDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedNote?.id ?? ''}
                heading={'Delete Note'}
                message={'Are you sure you want to delete \'' + selectedNote?.header + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    );

}

export default NotesList;
