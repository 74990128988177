import * as React from "react";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  DialogActions, DialogContent, DialogContentText, DialogTitle,
  Paper,
  styled,
  Typography,
} from "@mui/material";

const ConfirmationPromptStyled = styled(Paper)((props) => ({
  "& .MuiDialog-paper": { width: "80%", maxHeight: 435 },
}));
const StyledDialogActions = styled(DialogActions)((props) => ({    
  margin: "0 8px 8px 0"
}));
const StyledDialogContentText = styled(DialogContentText)((props) => ({    
  whiteSpace: 'pre-line' //to allow for line breaks in the string message
}));
const StyledTypography = styled(Typography)((props) => ({    
  whiteSpace: 'pre-line', //to allow for line breaks in the string message
  fontSize: '14px'
}));

interface ConfirmationPromptProps {
  heading: string;
  message: string;
  confirmButtonText?: string;
  rejectButtonText?: string;
  isBusy?: boolean;
  errorMessage?: string;
  confirmButtonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  rejectButtonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  handleConfirm: () => void;
  handleReject: () => void;
}

const ConfirmationPrompt: React.FC<ConfirmationPromptProps> = (
  props: ConfirmationPromptProps
) => {
  
  const { heading, message, confirmButtonText = "OK", rejectButtonText = "Cancel", confirmButtonColor = "primary", rejectButtonColor = "primary", errorMessage, isBusy = false } = props;
  let isDisabled = (errorMessage && errorMessage.length > 0) || isBusy === true;
  
  return (
    <ConfirmationPromptStyled>
      <DialogTitle>{heading}</DialogTitle>
      {errorMessage && (
        <Alert severity="error">
          <AlertTitle>Unable to {heading}</AlertTitle>
          <StyledTypography>{errorMessage}</StyledTypography>
        </Alert>
      )}
      <DialogContent>
        <StyledDialogContentText>{message}</StyledDialogContentText>
      </DialogContent>
      <StyledDialogActions>
        <CircularProgress
          aria-label={"progress spinner"}
          size={20}
          sx={{
            display: isBusy ? "block" : "none",
            margin: "0 10px 0 0",
          }}
        />
        <Button
          disabled={isBusy}
          autoFocus
          color={rejectButtonColor}
          onClick={props.handleReject}
          sx={{
            margin: "0 5px 0 0",
          }}
        >
          {rejectButtonText}
        </Button>
        <Button
          variant="contained"
          disabled={isDisabled}
          color={confirmButtonColor}
          onClick={props.handleConfirm}
        >
          {confirmButtonText}
        </Button>
      </StyledDialogActions>
    </ConfirmationPromptStyled>
  );
};

export default ConfirmationPrompt;
