import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Typography } from '@mui/material';
import capitalize from 'lodash/capitalize';
import PartnerIcon from "@mui/icons-material/SafetyDivider";
import DeleteIcon from '@mui/icons-material/Delete';
import { ActiveStatusChip, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, StatusChip, TabHeader, TabLabel } from '../../util/SharedStyles';

import { AddressToDisplayString, getWebsiteLink, getAvatarComponent, useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { getPrimaryAddressFromArray, getPrimaryEmailFromArray, getPrimaryPhoneFromArray } from '../../util/PrimaryFromModelArray';
import { getEntityFavorite, viewerCanEdit, getLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';
import TabPanel from '../../components/TabPanel';
import FavoriteToggleButton from '../../components/buttons/FavoriteToggleButton';
import EditButton from '../../components/buttons/EditButton';
import {
    clearError,
    clearState,
    selectError,
    selectTradingPartner,
    selectContactInfo,
    selectClients,
    selectPartnerNotes,
    selectPartnerAttachments,
    selectUpsertTradingPartnerStatus,
    selectDeleteTradingPartnerStatus,
    selectUpsertTradingPartnerNoteStatus,
    selectDeleteTradingPartnerNoteStatus,
    captureUpsertTradingPartnerStatus,
    captureDeleteTradingPartnerStatus,
    captureUpsertTradingPartnerNoteStatus,
    captureDeleteTradingPartnerNoteStatus
} from './TradingPartnerDetailsSlice';
import { AddressModel, BusinessAreaModel, EdiStandardModel, EmailModel, EntityType, NoteModel, PartnerModel, PartnerStatus, PhoneModel, RequestResult, UpsertNoteInput, UpsertPartnerInput, FunctionalReconciliationMode } from '../../gql-types.generated';
import { deleteTradingPartnerData, deleteTradingPartnerNote, fetchTradingPartnerById, upsertTradingPartner, upsertTradingPartnerNotes } from './TradingPartnerDetailsActions';
import { upsertRecentData } from '../EDIContainer/EDIContainerActions';
import TradingPartnerContactList from '../../components/lists/TradingPartnerContactList';
import TradingPartnerDialog from '../../components/dialogs/TradingPartnerDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import PartnerTransactionList from '../../components/lists/transactions/PartnerTransactionList';
import TradingPartnerAttachmentList from '../../components/lists/attachments/TradingPartnerAttachmentList';
import NotesList from '../../components/lists/NotesList';
import PartnerNotificationsRecipientsList from '../../components/lists/PartnerNotificationRecipientsList';
import PartnerClientsList from '../../components/lists/PartnerClientsList';
import { DetailWrapValue } from '../../util/SharedStyles';
import DetailsPage from '../../components/DetailsPage';

interface TradingPartnerDetailsProps {
    viewer: Viewer | undefined;
}

const TradingPartnerDetails: React.FC<TradingPartnerDetailsProps> = (props) => {
    //partnerId will be passed through router
    const { partnerId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [status, setStatus] = useState<PartnerStatus | undefined>(undefined);
    const [name, setName] = useState<string | undefined>(undefined);
    const [partnerCode, setPartnerCode] = useState<string | undefined>(undefined);
    const [internalPartnerCode, setInternalPartnerCode] = useState<string | undefined>(undefined);
    const [isaQualifierCode, setISAQualifierCode] = useState<string | undefined>(undefined);
    const [website, setWebsite] = useState<string | undefined>(undefined);
    const [businessArea, setBusinessArea] = useState<BusinessAreaModel | undefined>(undefined);
    const [ediStandard, setEdiStandard] = useState<EdiStandardModel | undefined>(undefined);
    const [acknowledgementMode, setAcknowledgementMode] = useState<string | undefined>(undefined);
    const [primaryAddress, setPrimaryAddress] = useState<AddressModel | undefined>(undefined);
    const [primaryEmail, setPrimaryEmail] = useState<EmailModel | undefined>(undefined);
    const [primaryPhone, setPrimaryPhone] = useState<PhoneModel | undefined>(undefined);
    const [transactionCount, setTransactionCount] = useState<number>(0);
    const [tabValue, setTabValue] = useState(0);
    const [openModify, setOpenModify] = useState(false);
    const [favIconErrored, setFavIconErrored] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const upsertTradingPartnerStatus = useAppSelector(selectUpsertTradingPartnerStatus);
    const deleteTradingPartnerStatus = useAppSelector(selectDeleteTradingPartnerStatus);
    const error = useAppSelector(selectError);
    const tradingPartnerInfo = useAppSelector(selectTradingPartner);
    const contactInfo = useAppSelector(selectContactInfo);
    const partnerClients = useAppSelector(selectClients);
    const partnerNotes = useAppSelector(selectPartnerNotes);
    const upsertNoteStatus = useAppSelector(selectUpsertTradingPartnerNoteStatus);
    const deleteNoteStatus = useAppSelector(selectDeleteTradingPartnerNoteStatus);
    const attachments = useAppSelector(selectPartnerAttachments);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    const localStorageTabIndexName = "partnerDetailsTabIndex_" + partnerId;

    useEffect(() => {
        // use partnerId to get tradingPArtnerDetails on render
        if (partnerId) {
            dispatch(fetchTradingPartnerById(partnerId));
        }
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh tradingPartner when a successful mutation occurs
        if (upsertTradingPartnerStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertTradingPartnerStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertTradingPartnerStatus());
            // close the modify dialog
            onTradingPartnerDialogClose();
            // we should always have the clientId if we upserted, but check to prevent compile error
            if (partnerId) {
                if (tradingPartnerInfo) {
                    // Add the client to the recent viewed list
                    dispatch(upsertRecentData(EntityType.Partner, tradingPartnerInfo.id, tradingPartnerInfo.name));
                }
                dispatch(fetchTradingPartnerById(partnerId));
            }
        }
    }, [upsertTradingPartnerStatus?.result]);

    useEffect(() => {
        if (deleteTradingPartnerStatus?.result === RequestResult.Success) {
            // close dialog
            onDeleteDialogClose();
            // send user back to where they came from
            navigate(-1);
            dispatch(setToastConfig({
                message: deleteTradingPartnerStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteTradingPartnerStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteTradingPartnerStatus.message as string);
        }
    }, [deleteTradingPartnerStatus?.result])

    useEffect(() => {
        if (upsertNoteStatus?.result === RequestResult.Success || deleteNoteStatus?.result === RequestResult.Success) {
            if (upsertNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }

            dispatch(captureUpsertTradingPartnerNoteStatus());
            dispatch(captureDeleteTradingPartnerNoteStatus());
            if (partnerId) {
                if (tradingPartnerInfo) {
                    // Add the client to the recent viewed list
                    dispatch(upsertRecentData(EntityType.Partner, tradingPartnerInfo.id, tradingPartnerInfo.name));
                }
                dispatch(fetchTradingPartnerById(partnerId));
            }
        }
    }, [upsertNoteStatus?.result, deleteNoteStatus?.result]);

    useEffect(() => {
        if (tradingPartnerInfo) {
            setName(tradingPartnerInfo.name as string);
            
            if (tradingPartnerInfo.ediStandards && tradingPartnerInfo.ediStandards.length > 0) {
                setEdiStandard(tradingPartnerInfo.ediStandards[0] as EdiStandardModel);
            } else {
                setEdiStandard(undefined);
            }

            setPartnerCode(tradingPartnerInfo.partnerCode as string);
            
            setInternalPartnerCode(tradingPartnerInfo.internalPartnerCode as string);
            
            setISAQualifierCode(tradingPartnerInfo.isaQualifierCode as string);
            
            setWebsite(tradingPartnerInfo.website as string);
            
            setBusinessArea(tradingPartnerInfo.businessArea ? tradingPartnerInfo.businessArea : undefined);
                        
            setStatus(tradingPartnerInfo.statusCode ? tradingPartnerInfo.statusCode : undefined);
            
            setAcknowledgementMode(tradingPartnerInfo.functionalReconciliationMode ? capitalize(tradingPartnerInfo.functionalReconciliationMode) : undefined);
            
            if (tradingPartnerInfo.addresses && tradingPartnerInfo.addresses.length > 0) {
                setPrimaryAddress(getPrimaryAddressFromArray(tradingPartnerInfo.addresses as AddressModel[]));
            }
            if (tradingPartnerInfo.emails && tradingPartnerInfo.emails.length > 0) {
                setPrimaryEmail(getPrimaryEmailFromArray(tradingPartnerInfo.emails as EmailModel[]));
            }
            if (tradingPartnerInfo.phones && tradingPartnerInfo.phones.length > 0) {
                setPrimaryPhone(getPrimaryPhoneFromArray(tradingPartnerInfo.phones as PhoneModel[]));
            }

            setTransactionCount(tradingPartnerInfo.totalTransactions as number);
            
            // restore active tab to be last accessed tab that was saved off
            let tabIndex = getLocalStorageItem(viewer, localStorageTabIndexName);
            if (tabIndex != null && +tabIndex >= 0) {
                setTabValue(+tabIndex);
            }

            // Add the trading partner to the recent viewed list
            dispatch(upsertRecentData(EntityType.Partner, tradingPartnerInfo.id, tradingPartnerInfo.name));
        }
    }, [tradingPartnerInfo]);

    useTitle(name);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const refreshTradingPartnerData = async () => {
        handleClearError();
        await dispatch(fetchTradingPartnerById(partnerId as string));
    };


    const getFavorite = () => {
        // call utility method to get if this partner is a favorite
        return getEntityFavorite(viewer?.favorites, partnerId, EntityType.Partner);
    }

    const onTradingPartnerDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        handleClearError();
        dispatch(captureDeleteTradingPartnerStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and ClientContact on close.
        handleClearError();
        // Refresh list to bring in potential updates
        dispatch(fetchTradingPartnerById(partnerId as string));
    };

    const onTradingPartnerDialogSave = (
        partnerInput: UpsertPartnerInput
    ) => {
        dispatch(
            upsertTradingPartner(partnerInput)
        );
    };

    const onNotesUpsert = (notes: NoteModel[]) => {
        if (notes && tradingPartnerInfo && tradingPartnerInfo.isaQualifierCode && tradingPartnerInfo.id) {
            const noteInput = notes as UpsertNoteInput[];
            dispatch(
                upsertTradingPartnerNotes(
                    tradingPartnerInfo.isaQualifierCode,
                    tradingPartnerInfo.id,
                    noteInput
                )
            );
        }
    };

    const onNoteDelete = (id: string) => {
        if (id) {
            dispatch(deleteTradingPartnerNote(id));
        }
    }


    let contactLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Contacts</TabLabel>
            {contactInfo && contactInfo.length > 0 && (
                <CountBox>{contactInfo.length}</CountBox>
            )}
        </Grid>
    );

    let clientsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Clients</TabLabel>
            {partnerClients && partnerClients.length > 0 && (
                <CountBox>{partnerClients.length}</CountBox>
            )}
        </Grid>
    );

    let transactionsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Transactions</TabLabel>
            {transactionCount > 0 && (
                <CountBox>{transactionCount}</CountBox>
            )}
        </Grid>
    );

    let attachmentsLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Attachments</TabLabel>
            {attachments && attachments.length > 0 && (
                <CountBox>{attachments.length}</CountBox>
            )}
        </Grid>
    );

    let notesLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Notes</TabLabel>
            {partnerNotes && partnerNotes.length > 0 && (
                <CountBox>{partnerNotes.length}</CountBox>
            )}
        </Grid>
    );

    const handlefavIconError = () => {
        setFavIconErrored(true);
    };

    const getPartnerDetails = () => {
        if (tradingPartnerInfo) {
            const addressString = AddressToDisplayString(primaryAddress);
            return (

                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >EDI Standard</Typography>
                            <Typography variant='body1' >{ediStandard?.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Internal Partner Code</Typography>
                            <Typography variant='body1' >{internalPartnerCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Company Address</Typography>
                            <Typography variant='body1' >{addressString}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Region</Typography>
                            <Typography variant='body1' >{primaryAddress?.countryCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Business Area</Typography>
                            <Typography variant='body1' >{businessArea?.name}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >ISA Qualifier</Typography>
                            <Typography variant='body1' >{isaQualifierCode}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Partner Code</Typography>
                            <Typography variant='body1' >{partnerCode}</Typography>
                        </Grid>
                        {website && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Website</Typography>
                                <DetailWrapValue variant='body1' >{getWebsiteLink(website)}</DetailWrapValue>
                            </Grid>
                        )}
                        {primaryEmail?.email && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Email</Typography>
                                <DetailWrapValue variant='body1' >{primaryEmail.email}</DetailWrapValue>
                            </Grid>
                        )}
                        {primaryPhone?.phoneNumber && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Phone</Typography>
                                <Typography variant='body1' >{primaryPhone.phoneNumber}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={1}>
                            <Typography variant='caption' >Functional Acknowledgement Mode</Typography>
                            <Typography variant='body1' >{acknowledgementMode}</Typography>
                        </Grid>
                        {tradingPartnerInfo.localeCode && (
                            <Grid item xs={1}>
                                <Typography variant='caption' >Locale</Typography>
                                <Typography variant='body1' >{tradingPartnerInfo.localeCode}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

            );
        }
    }

    const getTabbedContainer = () => {
        return (
            <DetailsTabContainer>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                        <TabHeader label={contactLabel} id='vertical-tab-partner-details-contacts' />
                        <TabHeader label={clientsLabel} id='vertical-tab-partner-details-clients' />
                        <TabHeader label={transactionsLabel} id='vertical-tab-partner-details-transactions' />
                        <TabHeader label={attachmentsLabel} id='vertical-tab-partner-details-attachments' />
                        <TabHeader label={notesLabel} id='vertical-tab-partner-details-notes' />
                        <TabHeader label="NOTIFICATIONS" id='vertical-tab-partner-details-notifications' />
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>
                <DetailsTabPanelContainer>
                    {/*<TabPanel value={tabValue} index={0}>[TODO: Add Transaction Reports]</TabPanel>*/}
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                        <TradingPartnerContactList viewerRole={viewer?.role} tradingPartnerContacts={contactInfo} tradingPartnerId={tradingPartnerInfo?.id} refreshPartnerData={refreshTradingPartnerData} />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={1}>
                        <PartnerClientsList
                            viewerRole={viewer?.role}
                            partnerId={partnerId as string}
                            partnerClients={partnerClients}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={2}>
                        <PartnerTransactionList
                            viewerRole={viewer?.role}
                            partner={tradingPartnerInfo as PartnerModel}
                            refreshPartnerData={refreshTradingPartnerData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={3}>
                        <TradingPartnerAttachmentList
                            viewerRole={viewer?.role}
                            partnerId={tradingPartnerInfo?.id}
                            attachments={attachments}
                            refreshPartnerData={refreshTradingPartnerData}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={4}>
                        <NotesList
                            viewerRole={viewer?.role}
                            parentId={tradingPartnerInfo?.id}
                            notes={partnerNotes}
                            saveNotes={onNotesUpsert}
                            deleteNote={onNoteDelete}
                            upsertNoteResult={upsertNoteStatus?.result}
                            deleteNoteStatus={deleteNoteStatus}
                            error={error}
                            clearError={handleClearError}
                        />
                    </TabPanel>
                    <TabPanel verticalAlign={verticalAlign} value={tabValue} index={5}>
                        <PartnerNotificationsRecipientsList viewerRole={viewer?.role} partnerId={tradingPartnerInfo?.id} />
                    </TabPanel>
                </DetailsTabPanelContainer>
            </DetailsTabContainer>
        );
    };

    const getDialogs = () => [
        <TradingPartnerDialog
            isOpen={openModify}
            partnerInfo={tradingPartnerInfo}
            onClose={onTradingPartnerDialogClose}
            onSave={onTradingPartnerDialogSave}
            refreshPartnerData={refreshTradingPartnerData}
            error={error}
            key="trading-partner-dialog"
        />,
        <DeleteDialog
            isOpen={openDelete}
            heading={'Delete Trading Partner'}
            message={'Are you sure you want to delete \'' + tradingPartnerInfo?.name + '\'?'}
            id={tradingPartnerInfo?.id as string}
            onConfirm={handleDeleteTradingPartnerConfirm}
            onReject={onDeleteDialogClose}
            errorMessage={deleteErrorMessage}
            key="delete-dialog"
        />
    ];


    //edit current client
    const onEditClick = () => {
        setOpenModify(true);
    }

    const deleteTradingPartner = () => {
        dispatch(captureDeleteTradingPartnerStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeleteTradingPartnerConfirm = () => {
        if (partnerId) {
            dispatch(deleteTradingPartnerData(partnerId));
        }
    }

    const canEdit = viewerCanEdit(viewer);

    let statusChip: undefined | JSX.Element;
    if (status !== undefined) {
        if (status === PartnerStatus.Active) {
            statusChip = <ActiveStatusChip label="Active" key="status-chip" />;
        } else if (status === PartnerStatus.New) {
            statusChip = <ActiveStatusChip label="New" key="status-chip" />;
        } else {
            statusChip = <StatusChip label="Inactive" key="status-chip" />;
        }
    }

    useEffect(() => {
        setDetailsRecordLoaded(!!tradingPartnerInfo);
        if (tradingPartnerInfo) {
            setDetailsPageTitle(name);
            setDetailsAV(getAvatarComponent(
                website,
                favIconErrored,
                name,
                "Partner",
                <PartnerIcon />,
                handlefavIconError
            ));
            setDetailsHeader(name);
            setDetailsSubHeader(partnerCode);
            setDetailsChips(statusChip ? [statusChip] : undefined);
            setDetailsFavoriteButton(<FavoriteToggleButton
                entityId={partnerId as string}
                entityName={name ?? ''}
                entityType={EntityType.Partner}
                favorite={getFavorite()}
            ></FavoriteToggleButton>);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Client button"
                    data-cy="edit-client-button"
                ></EditButton>);
                setDetailsToolbarButtons([<Button
                    variant="outlined"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={deleteTradingPartner}
                    key="delete-button"
                >
                    Delete
                </Button>])
            }
        }
    }, [tradingPartnerInfo, name, partnerCode, partnerId, favIconErrored, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getPartnerDetails}
            getTabbedContainer={getTabbedContainer}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
};

export default TradingPartnerDetails;