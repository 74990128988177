import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertTransactionMapPayload, UpsertTransactionMapInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_MAP = gql`
    mutation upsertMap($input: UpsertTransactionMapInput!){
        upsertTransactionMap(input: $input){
            message
            errors {
                code
                message
            }
            result
            transactionMapId
        }
    }
`;

export const mutationUpsertMap = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        transactionId: string;
        description?: string;
        documentString?: string;
        sourceFileName?: string;
        standardVersion?: string;
        id?: string;
    }
): Promise<UpsertTransactionMapPayload | undefined> => {
    const { transactionId, description, documentString, sourceFileName, standardVersion, id } = options as UpsertTransactionMapInput;
    const { data, errors } = await client.mutate<{ upsertTransactionMap: UpsertTransactionMapPayload}>({
        variables: { 
            input: { 
                description, 
                documentString,
                id,
                sourceFileName, 
                standardVersion, 
                transactionId, 
            } 
        },
        mutation: MUTATION_UPSERT_MAP
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the transaction map save.');
    }
    if (data && data.upsertTransactionMap) {
        return data.upsertTransactionMap;
    }

    return undefined;
};