import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import {  RequestResult } from '../../gql-types.generated';
import { fetchError, captureUpsertPricingStatus, captureDeletePricingStatus, captureClonePricingStatus } from './PricingSlice';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpsertPricing } from '../../gql/MutationUpsertPricing';
import { mutationClonePricing } from '../../gql/MutationClonePricing';

export const upsertPricingData = (description: string, currencyCode: string, minServiceFees: number, id?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertPricingStatus = await mutationUpsertPricing(gqlClient, {
                description,
                currencyCode,
                minServiceFees,
                id,
            });
            if (upsertPricingStatus) {
                if (upsertPricingStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertPricingStatus.message } as Error));
                    getErrorMessage(upsertPricingStatus.message as string, upsertPricingStatus.errors, true, true);
                }
                dispatch(captureUpsertPricingStatus(upsertPricingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePricingData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deletePricingStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Pricing"
            });
            if (deletePricingStatus) {
                if (deletePricingStatus.result === RequestResult.Fail) {
                    getErrorMessage(deletePricingStatus.message as string, deletePricingStatus.errors, true, true);
                }
                dispatch(captureDeletePricingStatus(deletePricingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const clonePricingData = (id: string, description?: string, minServiceFees?: number): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const cloneStatus = await mutationClonePricing(gqlClient, {
                id,
                description,
                minServiceFees
            });
            if (cloneStatus) {
                if (cloneStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: cloneStatus.message } as Error));
                    getErrorMessage(cloneStatus.message as string, cloneStatus.errors, true, true);
                }
                dispatch(captureClonePricingStatus(cloneStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };





