import { useAppSelector } from "../../app/hooks";
import { MenuItem, FormControl, InputLabel, Select, SelectChangeEvent, Tooltip, styled } from "@mui/material";
import { IsaQualifierModel } from "../../gql-types.generated";
import { selectIsaQualifierList } from "../../features/EDIContainer/EDIContainerSlice";

const DivEllipsis = styled('div')((props) => ({
    display: 'block',
    width: '340px',
    textOverflow: "ellipsis",
    overflow: 'hidden',
}));

interface QualifierFieldProps {
    disabled: boolean;
    required: boolean;
    value?: string;
    onChange: (event: SelectChangeEvent<string | null>) => void;
}

const IsaQualifierField: React.FC<QualifierFieldProps> = props => {
    const { disabled, required, value, onChange } = props;

    const qualifiers = useAppSelector(selectIsaQualifierList);

    const getQualifierOptions = () => {
        if (qualifiers && qualifiers.length) {
            return (
                qualifiers.map((qualifier: IsaQualifierModel) => (
                    <MenuItem
                        key={qualifier.isaQualifierCode}
                        value={qualifier.isaQualifierCode}
                    >
                        <Tooltip title={qualifier.description ?? ''}>
                            <DivEllipsis>{`${qualifier.isaQualifierCode} : ${qualifier.description}`}</DivEllipsis>
                        </Tooltip>
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const QualifiersMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
                maxWidth: '340px',
                display: 'block',
                textOverflow: "ellipsis", 
            },
        },
        display: 'block',
            textOverflow: "ellipsis", 
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="isa-qualifiers-label" required={required}>ISA Qualifier</InputLabel>
            <Select
                labelId="isa-qualifiers-label"
                aria-labelledby="isa-qualifiers-label"
                value={value}
                onChange={onChange}
                disabled={disabled}
                data-cy="isa-qualifiers-list"
                MenuProps={QualifiersMenuProps}
                required={required}
            >
                {getQualifierOptions()}
            </Select>
        </FormControl>
    );
};

export default IsaQualifierField;