import { Button, Grid, IconButton, Stack, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DateTime } from 'luxon';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from "@mui/icons-material/Download";
import TabPanel from "../../components/TabPanel";
import { TransactionModel, RequestResult } from "../../gql-types.generated";
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, ActiveStatusChip, StatusChip, TabHeader, TabLabel } from "../../util/SharedStyles";
import { fetchTransactionMapById, deleteMapData, fetchTransactionMapPartners } from "./MapDetailsActions";
import { clearState, selectError, clearError, selectMap, selectDeleteMapStatus, captureDeleteMapStatus, selectMapPartners } from "./MapDetailsSlice";
import { viewerCanEdit } from '../../util/ViewerUtility';
import { downloadDocument, useTitle } from "../../util/Common";
import { getFormattedDateTimeString } from "../../util/DateTimeUtility";
import EditButton from '../../components/buttons/EditButton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import TransactionMapPartnerList from "../../components/lists/TransactionMapPartnersList";
import MapDialog from "../../components/dialogs/MapDialog";
import { upsertMapData } from "../Maps/MapsActions";
import { captureUpsertMapStatus, selectUpsertMapStatus } from "../Maps/MapsSlice";
import DetailsPage from '../../components/DetailsPage';

const DownloadButton = styled(IconButton)((props) => ({
    height: '24px'
}));

interface MapDetailsProps {
    viewer: Viewer | undefined;
}

const MapDetails: React.FC<MapDetailsProps> = (props) => {
    //mapIs should be passed through router
    const { mapId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState(0);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [createdBy, setCreatedBy] = useState<string | undefined>(undefined);
    const [createdTime, setCreatedTime] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [documentString, setDocumentString] = useState<string | undefined>(undefined);
    const [isMapDeployed, setIsMapDeployed] = useState<boolean>(false);
    const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
    const [lastModifiedBy, setLastModifiedBy] = useState<string | undefined>(undefined);
    const [lastModifiedTime, setLastModifiedTime] = useState<string | undefined>(undefined);
    const [sourceFileName, setSourceFileName] = useState<string | undefined>(undefined);
    const [standardVersion, setStandardVersion] = useState<string | undefined>(undefined);
    const [totalPartners, setTotalPartners] = useState<number | undefined>(undefined);
    const [transaction, setTransaction] = useState<TransactionModel | undefined>(undefined);
    const [transactionId, setTransactionId] = useState<string | undefined>(undefined);

    const mapInfo = useAppSelector(selectMap);
    const deleteMapStatus = useAppSelector(selectDeleteMapStatus);
    const mapPartners = useAppSelector(selectMapPartners);
    const upsertMapStatus = useAppSelector(selectUpsertMapStatus);
    const error = useAppSelector(selectError);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    const canEdit = viewerCanEdit(viewer);

    // If multiple tabs uncomment and import
    //const localStorageTabIndexName = "TransactionMapDetailsTabIndex_" + mapId;

    useEffect(() => {
        // get Map on page entry with passed in mapId
        if (mapId) {
            dispatch(fetchTransactionMapById(mapId));
            dispatch(fetchTransactionMapPartners(mapId));
        }
        // on page exit, set state to defaults (undefined)
        return () => {
            dispatch(clearState());
        }
    }, []);

    useEffect(() => {
        if (deleteMapStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteMapStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // send user back to where they came from
            navigate(-1);

        }
        else if (deleteMapStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteMapStatus.message as string);
        }
    }, [deleteMapStatus?.result]);


    useEffect(() => {
        if (mapInfo) {
            //set field data
            if (mapInfo.createdByName) {
                setCreatedBy(mapInfo.createdByName);
            }
            if (mapInfo.createdTime) {
                let formattedDate = getFormattedDateTimeString(mapInfo.createdTime, { format: DateTime.DATETIME_FULL });
                setCreatedTime(formattedDate);
            }
            if (mapInfo.description) {
                setDescription(mapInfo.description);
            }
            setDocumentString(mapInfo.documentString ?? undefined);
            if (mapInfo.isMapDeployed) {
                setIsMapDeployed(mapInfo.isMapDeployed);
            }
            if (mapInfo.isMapLoaded) {
                setIsMapLoaded(mapInfo.isMapLoaded);
            }
            if (mapInfo.lastModifiedByName) {
                setLastModifiedBy(mapInfo.lastModifiedByName);
            }
            if (mapInfo.lastModifiedTime) {
                let formattedDate = getFormattedDateTimeString(mapInfo.lastModifiedTime, { format: DateTime.DATETIME_FULL });
                setLastModifiedTime(formattedDate);
            }
            setSourceFileName(mapInfo.sourceFileName ?? undefined);
            if (mapInfo.standardVersion) {
                setStandardVersion(mapInfo.standardVersion);
            }
            if (mapInfo.totalPartners) {
                setTotalPartners(mapInfo.totalPartners);
            }
            if (mapInfo.transaction) {
                setTransaction(mapInfo.transaction);
            }
            if (mapInfo.transactionId) {
                setTransactionId(mapInfo.transactionId);
            }

            // If multiple tabs uncomment and import
            // restore active tab to be last accessed tab that was saved off
            /*let tabIndex = getLocalStorageItem(viewer, localStorageTabIndexName);
            if (tabIndex != null && +tabIndex >= 0) {
                setTabValue(+tabIndex);
            }*/
        }
    }, [mapInfo]);

    useEffect(() => {
        // refresh client when a successful mutation occurs
        if (upsertMapStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertMapStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertMapStatus());
            // close the modify dialog
            onCloseEdit();
            // we should always have the clientId if we upserted, but check to prevent compile error
            if (mapId) {
                dispatch(fetchTransactionMapById(mapId));
            }
        }
    }, [upsertMapStatus?.result]);

    useTitle(description);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const onEditClick = () => {
        setOpenEdit(true);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // If multiple tabs uncomment and import
        //setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const onDeleteMap = () => {
        dispatch(captureDeleteMapStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeleteMapConfirm = () => {
        if (mapId) {
            dispatch(deleteMapData(mapId));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteMapStatus());
        setDeleteErrorMessage('');
    };

    const onCloseEdit = () => {
        setOpenEdit(false);
        onDialogClose();
    };

    const onSaveEdit = (
        transactionId: string,
        description: string,
        documentString?: string,
        sourceFileName?: string,
        standardVersion?: string,
        id?: string
    ) => {
        dispatch(upsertMapData(transactionId, description, documentString, sourceFileName, standardVersion, id));
    };

    const onDialogClose = () => {
        // Clear error and selectedMap on close.
        dispatch(clearError());
    };

    let loadedChip: React.ReactElement | undefined;
    if (isMapLoaded) {
        loadedChip = <StatusChip label="Loaded" key="loaded-chip" />;
    }

    let deployedChip: React.ReactElement | undefined;
    if (isMapDeployed) {
        deployedChip = <ActiveStatusChip label="Deployed" key="deployed-chip" />;
    }

    const refreshPartners = () => {
        if (mapId) {
            dispatch(fetchTransactionMapPartners(mapId));
        }
    }

    const onDownloadMap = () => {
        downloadDocument(documentString, sourceFileName, "text/xsl", true);
    };

    const getMapDownloadButton = () => {
        let docCheck = documentString !== undefined && documentString !== null
        let isDisabled = (docCheck && sourceFileName) ? false : true;
        
        return (
            <DownloadButton color="primary" aria-label="download map fle" onClick={onDownloadMap} disabled={isDisabled}>
                <DownloadIcon fontSize="small" />
            </DownloadButton>
        );
    }

    const getMapDetails = () => {
        if (mapInfo) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Stack direction="row" justifyContent="flex-start" alignItems="center" >
                                <Typography variant='caption' aria-label='map file'>Map File</Typography>
                                {getMapDownloadButton()}
                            </Stack>
                            <Typography variant='body1' >{sourceFileName}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' aria-label='standard version'>Standard Version</Typography>
                            <Typography variant='body1' >{standardVersion}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' aria-label='date created'>Date Created</Typography>
                            <Typography variant='body1' >{createdTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' aria-label='created by'>Created By</Typography>
                            <Typography variant='body1' >{createdBy}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' aria-label='last updated'>Last Updated</Typography>
                            <Typography variant='body1' >{lastModifiedTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' aria-label='updated by'>Updated By</Typography>
                            <Typography variant='body1' >{lastModifiedBy}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
            );
        }
    }

    let partnersLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Partners</TabLabel>
            {mapPartners && mapPartners.length > 0 && (
                <CountBox>{mapPartners.length}</CountBox>
            )}
        </Grid>
    );

    const getTabbedContent = () => {
        if (mapInfo) {
            return (
                <DetailsTabContainer>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                            <TabHeader label={partnersLabel} id='vertical-tab-0' />
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>
                    <DetailsTabPanelContainer>
                        <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                            <TransactionMapPartnerList viewer={viewer} mapId={mapId as string} mapPartners={mapPartners} refreshPartners={refreshPartners} />
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </DetailsTabContainer>
            );
        }
    };

    const isDeleteDisabled = ((totalPartners && totalPartners > 0) || isMapDeployed);

    const getChips = () => {
        let ret = [];
        if (loadedChip) {
            ret.push(loadedChip);
        }
        if (deployedChip) {
            ret.push(deployedChip);
        }
        return ret.length > 0 ? ret : undefined;
    }

    const getDialogs = () => [
        <DeleteDialog
            isOpen={openDelete}
            heading={'Delete Transaction Map'}
            message={`Are you sure you want to delete '${description}'?`}
            id={mapInfo?.id as string}
            onConfirm={handleDeleteMapConfirm}
            onReject={onDeleteDialogClose}
            errorMessage={deleteErrorMessage}
            key="delete-dialog"
        />,
        <MapDialog
            isOpen={openEdit}
            map={mapInfo}
            onClose={onCloseEdit}
            onSave={onSaveEdit}
            error={error}
            key="map-dialog"
        />
    ]

    useEffect(() => {
        setDetailsRecordLoaded(!!mapInfo);
        if (mapInfo) {
            setDetailsPageTitle(description);
            setDetailsHeader(description);
            setDetailsSubHeader(transaction?.name);
            setDetailsChips(getChips());
            setDetailsToolbarButtons([<Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                disabled={isDeleteDisabled}
                onClick={onDeleteMap}
                key="delete-button"
            >
                Delete</Button>]);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Map button"
                    data-cy="edit-map-button"
                />);
            }
        }
    }, [mapInfo, description, transaction, isDeleteDisabled]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getMapDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
}

export default MapDetails;