import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ClientsListQuery, PagingResultModelOfClientModel, GetClientsInput, OrderDirection, ClientOrderByInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENTS_LIST = gql`
  query clientsList($input: GetClientsInput!) {
    clients(input: $input) {
      cursor {
        nextPage
        previousPage
      }
      nodes {
        name
        id
        statusCode
        clientCode
        website
        businessArea {
          name
          id
        }
        erpProduct {
          name
          id
        }
        totalPartners
      }
    }
  }
`;

export const queryClientList = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  limit: number | undefined,
  filters: {
    name?: string | undefined;
    businessAreaIds?: string[] | undefined;
    productIds?: string[] | undefined;
    clientStatuses?: string[] | undefined;
    city?: string | undefined;
  }
): Promise<PagingResultModelOfClientModel | undefined> => {

  const { name, businessAreaIds, productIds, clientStatuses, city } = filters;
  const order: ClientOrderByInput = { name: OrderDirection.Asc };
  const { data, errors } = await client.query<ClientsListQuery>({
    query: QUERY_CLIENTS_LIST,
    variables: {
      input: {
        after: after,
        limit: limit,
        order: order,
        name: name,
        statusCodes: clientStatuses,
        businessAreaIds: businessAreaIds,
        erpProductIds: productIds,
        addressFilter: { cities: city ? [city] : null },
      }
    }
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.clients as PagingResultModelOfClientModel;
};