import { TransactionErrorModel, ActionPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    errorLog?: TransactionErrorModel;
    error?: Error;
    updateLogStatus?: ActionPayload;
}

const initialState: SliceState = {
    errorLog: undefined,
    error: undefined,
    updateLogStatus: undefined
};

export const slice = createSlice({
    name: 'errorLogDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorLog = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = undefined;
        },

        fetchErrorLogByIdSuccess: (state, action: PayloadAction<TransactionErrorModel[]>) => {
            if (action.payload && action.payload[0]) {          
                // eslint-disable-next-line no-param-reassign
                state.errorLog = action.payload[0];
            }
        },

        captureUpdateLogStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.errorLogDetails.error;
export const selectErrorLog = (state: RootState): TransactionErrorModel | undefined => state.errorLogDetails.errorLog;
export const selectUpdateLogStatus = (state: RootState): ActionPayload | undefined => state.errorLogDetails.updateLogStatus;

export const {
    fetchError,
    clearError,
    clearState,
    fetchErrorLogByIdSuccess,
    captureUpdateLogStatus
} = slice.actions;

export default slice.reducer;