import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { useNavigate } from 'react-router-dom';

import { PartnerModel, RequestResult, UpsertPartnerInput } from '../../gql-types.generated';
import { captureUpsertTradingPartnerStatus, clearState, selectError, selectRequestsInFlight, selectTradingPartnersList, selectTradingPartnersConnection, selectUpsertTradingPartnerStatus, clearError } from './TradingPartnersSlice';
import { fetchTradingPartnersList, upsertTradingPartner } from './TradingPartnersActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid, WrappedLoadingGrid } from '../../util/SharedStyles';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, defaultPageSize, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { useTitle } from '../../util/Common';
import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import TradingPartnerListItem from '../../components/listItems/TradingPartnerListItem';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import TradingPartnerDialog from '../../components/dialogs/TradingPartnerDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import TradingPartnersFilterBar from '../../components/filters/TradingPartnersFilterBar';


interface PartnerProps {
    viewer: Viewer | undefined;
}

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids

const TradingPartners: React.FC<PartnerProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [selectedTradingPartner, setSelectedTradingPartner] = useState<PartnerModel | undefined>(undefined);

    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterStandardIds, setFilterStandardIds] = useState<string[]>([]);
    const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
    const [filterPartnerCode, setFilterPartnerCode] = useState<string | undefined>(undefined);
    const [filterBusinessAreas, setFilterBusinessAreas] = useState<string[]>([]);
    const [filterCount, setFilterCount] = useState(0);

    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const partnerPagingResult = useAppSelector(selectTradingPartnersConnection);
    const upsertTradingPartnerStatus = useAppSelector(selectUpsertTradingPartnerStatus);

    const pageSize = defaultPageSize;

    const tradingPartners = useAppSelector(selectTradingPartnersList);

    const canEdit = viewerCanEdit(viewer);

    useTitle("Trading Partners");

    useEffect(() => {
        if (upsertTradingPartnerStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertTradingPartnerStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertTradingPartnerStatus());
            onTradingPartnerDialogClose();

            // if partnerId was successfully returned, navigate 
            // to the new Partner details
            if (upsertTradingPartnerStatus.partner?.id) {
                // simulate a card click to navigate
                cardClickAction(upsertTradingPartnerStatus.partner.id);
            }
        }
    }, [upsertTradingPartnerStatus?.result]);

    useEffect(() => {
        // resize when content is present
        const titleHeight = document.getElementById('partners-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('partners-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [tradingPartners, filtersOpen]);

    useEffect(() => {
        // make sure partners has loaded before we make additional calls
        if (tradingPartners) {
            // keep track of the number of filters to know if
            // should display badge in Filters button
            let numFilters = 0;
            if (filterName && filterName.length > 0) {
                numFilters += 1;
            }
            if (filterStandardIds.length > 0) {
                numFilters += 1;
            }
            if (filterStatuses.length > 0) {
                numFilters += 1;
            }
            if (filterPartnerCode && filterPartnerCode.length > 0) {
                numFilters += 1;
            }
            if (filterBusinessAreas.length > 0) {
                numFilters += 1;
            }

            setFilterCount(numFilters);
        }
    }, [tradingPartners, filterName, filterStandardIds, filterStatuses, filterPartnerCode, filterBusinessAreas]);

    useEffect(() => {
        if (!tradingPartners && requestsInFlight > 0) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [tradingPartners, requestsInFlight]);

    const loadPage = (endEdge: string) => {
        dispatch(fetchTradingPartnersList(endEdge, pageSize, filterName, filterStandardIds, filterStatuses, filterPartnerCode, filterBusinessAreas));
    };

    const handlePageLoad = (page: number) => {
        if (!partnerPagingResult) {
            return;
        }
        if (!partnerPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(partnerPagingResult.cursor.nextPage);
    };

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/partner/" + id;
            navigate(route);
        }
    };

    const hasMorePartners = (partnerPagingResult?.cursor?.nextPage !== null && partnerPagingResult?.cursor?.nextPage !== undefined && partnerPagingResult?.cursor?.nextPage !== '') as boolean;

    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'partner-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} sm={12} md={6} lg={4} xl={3} key={key}>
                    <TradingPartnerListItem loading={true} viewer={viewer} clickAction={cardClickAction} />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'partner-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load Trading Partners' error={error}></ErrorMessage>
            );
        }
        if (!tradingPartners && requestsInFlight > 0) {
            return getLoaders();
        }
        else if (tradingPartners && tradingPartners.length) {
            return (
                tradingPartners.map((partner: PartnerModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={12} md={6} lg={4} xl={3} key={partner.id}>
                        <TradingPartnerListItem
                            tradingPartner={partner}
                            viewer={viewer}
                            clickAction={cardClickAction}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // No trading partners to show 
            // Display appropriate no results/records found image/message
            // based on permission level and filters
            if (filterCount > 0) {
                return (<NoResultsMessage />);
            }
            else if (canEdit) {
                return (<NoRecordsMessage actionButtonText="Add New Trading Partner" actionButtonClick={onAddTradingPartnerClick} />);
            }
            else {
                return (<NoRecordsMessage message="" />);
            }
        }
    };
    const submitUpsertTradingPartner = (
        partnerInput: UpsertPartnerInput
    ) => {
        dispatch(
            upsertTradingPartner(partnerInput)
        );
    };

    const onAddTradingPartnerClick = () => {
        // Clear error 
        dispatch(clearError());
        // ensure no previously selected user is set
        if (selectedTradingPartner) {
            setSelectedTradingPartner(undefined);
        }
        setOpenModify(true);
    };

    const onTradingPartnerDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error and selectedUser on close.
        dispatch(clearError());
        setSelectedTradingPartner(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchTradingPartnersList(undefined, pageSize));
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterName: string | undefined, filterStandardIds: string[], filterStatuses: string[], filterPartnerCode: string | undefined, filterBusinessAreas: string[]) => {
        setFilterName(filterName);
        setFilterStandardIds(filterStandardIds);
        setFilterStatuses(filterStatuses);
        setFilterPartnerCode(filterPartnerCode);
        setFilterBusinessAreas(filterBusinessAreas);

        // filters changed so refresh the list
        dispatch(clearState());
        dispatch(fetchTradingPartnersList(undefined, pageSize, filterName, filterStandardIds, filterStatuses, filterPartnerCode, filterBusinessAreas));
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Trading Partners' id="partners-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="partner-filters"
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="New Partner"
                            onClick={onAddTradingPartnerClick}
                            data-cy="add-new-trading-partner"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>

            <TradingPartnersFilterBar
                id="partners-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />

            <CardListScrollBox scrollheight={contentAreaHeight}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handlePageLoad}
                    hasMore={hasMorePartners}
                    loader={getLoaders(true) as ReactElement}
                    useWindow={false}
                    initialLoad={false}
                >
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </InfiniteScroll>
            </CardListScrollBox>
            <TradingPartnerDialog
                isOpen={openModify}
                partnerInfo={selectedTradingPartner}
                onClose={onTradingPartnerDialogClose}
                onSave={submitUpsertTradingPartner}
                error={error}
            />
        </MainContentBox>
    );
};

export default TradingPartners;