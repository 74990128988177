
import { GridApi } from '@mui/x-data-grid-pro';
import { Box, Skeleton, Stack, styled } from '@mui/material'

const LoadingContainer = styled(Stack)((props) => ({
    height: '100%',
    width: '100%',
}));

interface rowProps {
    rowheight: string;
    bottommargin: number;
}
const SkeletonRow = styled(Box)<rowProps>(({ theme, rowheight, bottommargin }) => ({
    height: rowheight,
    marginBottom: bottommargin,
    background: theme.palette.background.paper,
    width: '100%',
    borderRadius: '4px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));



/** Component for displaying no filter results on list pages */
interface SkeletonProps {
    apiRef: React.MutableRefObject<GridApi>,
    rowBottomMargin?: number,
}

const MainDataGridLoadingSkeleton: React.FC<SkeletonProps> = (props) => {
    const { apiRef, rowBottomMargin = 0 } = props;
    const dimensions = apiRef.current?.getRootDimensions();
        const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;

        // @ts-expect-error Function signature expects to be called with parameters, but the implementation suggests otherwise
        const rowHeight = apiRef.current.unstable_getRowHeight();
        const rowAndMarginHeight = rowHeight + rowBottomMargin;
        const skeletonRowsCount = Math.ceil(viewportHeight / rowAndMarginHeight);

        let skeletonLoaders = [];
        let skeletonKey = 0;
        for (let i = 0; i < skeletonRowsCount; i++) {
            const key = 'process-skeleton-' + skeletonKey++;
            skeletonLoaders.push(
                <SkeletonRow key={key} rowheight={`${rowHeight}px`} bottommargin={rowBottomMargin}>
                    <Skeleton animation="wave" variant="rectangular" height={24} width="98%" />
                </SkeletonRow>
            )
        }

        return (
            <LoadingContainer>
                {skeletonLoaders}
            </LoadingContainer>
        );
};

export default MainDataGridLoadingSkeleton;