import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { EntityType, UpsertRecentPayload, UpsertRecentInput } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_RECENT = gql`
    mutation upsertRecent($input: UpsertRecentInput!) {
        upsertRecent(input: $input) {
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertRecent = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        entityType: EntityType;
        entityId: string;
        name: string;
    }
): Promise<UpsertRecentPayload | undefined> => {
    const { entityType, entityId, name } = options as UpsertRecentInput;
    const { data, errors } = await client.mutate<{ upsertRecent: UpsertRecentPayload }>({
        variables: { 
            input: { 
                entityType, 
                entityId, 
                name 
            } 
        },
        mutation: MUTATION_UPSERT_RECENT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the person.
        throw new Error(`An error has occurred while setting '${name}' as a recent.`);
    }
    if (data && data.upsertRecent) {
        return data.upsertRecent;
    }

    return undefined;
};