import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientTransactionInput, UpsertClientTransactionPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_TRANSACTION = gql`
    mutation upsertClientTransaction($input: UpsertClientTransactionInput!){
        upsertClientTransaction(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientTransaction = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertClientTransactionData: UpsertClientTransactionInput
    }
): Promise<UpsertClientTransactionPayload | undefined> => {
    const { clientId, id, isActive, pipelineId, transactionId, erpTransactionSchemaId, notificationFormatterId, notify, bulkNotify, fileNamePattern } = args.upsertClientTransactionData;
    const { data, errors } = await client.mutate<{ upsertClientTransaction: UpsertClientTransactionPayload }>({
        variables: {
            input: {
                clientId,
                id,
                isActive,
                pipelineId,
                transactionId,
                erpTransactionSchemaId,
                notificationFormatterId,
                notify,
                bulkNotify,
                fileNamePattern
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_TRANSACTION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add client transaction.');
    }
    if (data && data.upsertClientTransaction) {
        return data.upsertClientTransaction;
    }

    return undefined;
}