import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientPartnerTransactionSchemaInput, UpsertClientPartnerTransactionSchemaPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_SCHEMA = gql`
    mutation upsertClientPartnerTransactionSchema($input: UpsertClientPartnerTransactionSchemaInput!){
        upsertClientPartnerTransactionSchema(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientPartnerTransactionSchema = async (
    client: ApolloClient<NormalizedCacheObject>,
    clientPartnerTransactionSchemaInputData: UpsertClientPartnerTransactionSchemaInput
): Promise<UpsertClientPartnerTransactionSchemaPayload | undefined> => {
    const { clientPartnerTransactionId, partnerTransactionSchemaId, id } = clientPartnerTransactionSchemaInputData;
    const { data, errors } = await client.mutate<{ upsertClientPartnerTransactionSchema: UpsertClientPartnerTransactionSchemaPayload }>({
        variables: {
            input: {
                clientPartnerTransactionId,
                partnerTransactionSchemaId,
                id
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_SCHEMA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add a client partner transaction schema.');
    }
    if (data && data.upsertClientPartnerTransactionSchema) {
        return data.upsertClientPartnerTransactionSchema;
    }

    return undefined;
};