import { Link, Tooltip, styled } from '@mui/material';

const GridCell = styled('div')(({ theme }) => ({
    textAlign: 'start',
    overflow: 'hidden',
}));
const DualGridHeader = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));
const DualGridHeaderWithClick = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main,
    '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
    }
}));
const DualGridBoldHeader = styled(DualGridHeader)((props) => ({
    fontWeight: 'bold',
}));
const DualGridSub = styled('div')(({ theme }) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));
const DualGridSubNoLink = styled(DualGridSub)(({ theme }) => ({
    color: theme.palette.grayscale.main
}));

interface GridCellDualVertProps {
    header: string | undefined;
    sub?: string | undefined;
    tooltip?: string;
    boldHeader?: boolean;
    subLink?: boolean;
    headerOnClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

}

const GridCellDualVert: React.FC<GridCellDualVertProps> = props => {
    let href = '';
    if (props.sub) {
        href = props.sub;
        if (!href.startsWith('http')) {
            href = 'https://' + props.sub;
        }
    }
    let headerElement = (props.headerOnClick ?
        <DualGridHeaderWithClick onClick={props.headerOnClick}>{props.header}</DualGridHeaderWithClick>
        : (props.boldHeader === true ?
            <DualGridBoldHeader>{props.header}</DualGridBoldHeader>
            :
            <DualGridHeader>{props.header}</DualGridHeader>
        ));
    let subHeaderElement = (props.subLink === true ?
        <DualGridSub>
            <Link href={href} underline="hover" target="_blank" rel="noopener">{props.sub}</Link>
        </DualGridSub>
        :
        <DualGridSubNoLink>{props.sub}</DualGridSubNoLink>
    );
    return (
        <Tooltip title={props.tooltip ?? ''}>
            <GridCell>
                {headerElement}
                {subHeaderElement}
            </GridCell>
        </Tooltip>
    )
}

export default GridCellDualVert;