import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpdatePartnerTransactionInput, UpdatePartnerTransactionsPayload } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPDATE_PARTNER_TRANSACTION = gql`
    mutation updatePartnerTransaction($input: UpdatePartnerTransactionInput!){
        updatePartnerTransaction(input: $input){
            message
            errors {
                code
                message
            }
            result
            partnerTransaction {
                id
            }
        }
    }
`;

export const mutationUpdatePartnerTransaction = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: string,
        pipelineId?: string
    }
): Promise<UpdatePartnerTransactionsPayload | undefined> => {
    const {
        id, pipelineId
    } = options as UpdatePartnerTransactionInput;

    const { data, errors } = await client.mutate<{ updatePartnerTransaction: UpdatePartnerTransactionsPayload }>({
        variables: {
            input: {
                id, pipelineId
            }
        },
        mutation: MUTATION_UPDATE_PARTNER_TRANSACTION
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the client contact save.');
    }
    if (data && data.updatePartnerTransaction) {
        return data.updatePartnerTransaction;
    }
    return undefined;
};