import { gqlClient } from "../../components/AppProvider";
import { queryTransactionMapById } from "../../gql/QueryTransactionMapById";
import { AppDispatch, AppThunk } from "../../store";
import { getErrorMessage } from '../../util/Common';
import { fetchError, fetchTransactionMapByIdSuccess, captureDeleteMapStatus, fetchTransactionMapPartnersSuccess, captureUpsertTransactionMapPartnerStatus } from "./MapDetailsSlice";
import { mutationDeleteEntity } from "../../gql/MutationDeleteEntity";
import { queryTransactionMapPartners } from "../../gql/QueryTransactionMapPartners";
import { RequestResult, TransactionMapPartnerModel } from "../../gql-types.generated";
import { mutationUpsertTransactionMapPartner } from "../../gql/MutationUpsertTransactionMapPartner";



export const fetchTransactionMapById = (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const transactionMap = await queryTransactionMapById(gqlClient, id);
            if (transactionMap) {
                dispatch(fetchTransactionMapByIdSuccess(transactionMap))
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


export const deleteMapData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteMapStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "TransactionMap"
            });
            if (deleteMapStatus) {
                if (deleteMapStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteMapStatus.message as string, deleteMapStatus.errors, true, true);
                }
                dispatch(captureDeleteMapStatus(deleteMapStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchTransactionMapPartners = (mapId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const transactionMapPartners = await queryTransactionMapPartners(gqlClient, undefined, undefined, mapId);
            if (transactionMapPartners) {
                dispatch(fetchTransactionMapPartnersSuccess(transactionMapPartners as TransactionMapPartnerModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertTransactionMapPartner = (
    partnerId: string,
    transactionMapId: string,
    id?: string
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertTransactionMapPartnerStatus = await mutationUpsertTransactionMapPartner(gqlClient, {
                partnerId, transactionMapId, id
            });
            if (upsertTransactionMapPartnerStatus) {
                if (upsertTransactionMapPartnerStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertTransactionMapPartnerStatus.message } as Error));
                    getErrorMessage(upsertTransactionMapPartnerStatus.message as string, upsertTransactionMapPartnerStatus.errors, true, true);
                }
                dispatch(captureUpsertTransactionMapPartnerStatus(upsertTransactionMapPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };