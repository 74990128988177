import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertEdiStaticFileInput, UpsertEdiStaticFilePayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_EDI_STATIC_FILE = gql`
    mutation upsertEdiStaticFile($input: UpsertEdiStaticFileInput!){
        upsertEdiStaticFile(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertEdiStaticFile = async (
    client: ApolloClient<NormalizedCacheObject>, 
    args: {
        staticFileData: UpsertEdiStaticFileInput
    }
): Promise<UpsertEdiStaticFilePayload | undefined> => {
    const { description, documentString, id, documentType, fileName } = args.staticFileData;
    const { data, errors } = await client.mutate<{ upsertEdiStaticFile: UpsertEdiStaticFilePayload }>({
        variables: {
            input: {
                description,
                documentString,
                id,
                documentType,
                fileName
            }
        },
        mutation: MUTATION_UPSERT_EDI_STATIC_FILE
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the EDI Static Resource save.');
    }
    if (data && data.upsertEdiStaticFile) {
        return data.upsertEdiStaticFile;
    }

    return undefined;
}