import { Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { ConfigurationModel, UserRole } from "../../gql-types.generated";
import AddEditDialog from './AddEditDialog';

interface DialogProps {
    isOpen: boolean;
    process?: ConfigurationModel | null | undefined;
    viewerRole?: UserRole | undefined;
    isReadOnly: boolean;
    onClose: () => void;
    onSave: (
        destPath: string,
        ediProcess: string,
        executionMode: string,
        extension: string,
        tpgClient: string,
        watcherPath: string,
        description?: string,
        id?: string,
        p1?: string,
        p2?: string,
        p3?: string,
        p4?: string,
        p5?: string,
        p6?: string,
    ) => void;
    error?: Error | undefined;
}

const ProcessDialog: React.FC<DialogProps> = props => {
    const { isOpen, process, viewerRole, isReadOnly, onClose, onSave, error } = props;
    const id = process?.id;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [destPath, setDestPath] = useState<string>("");
    const [ediProcess, setEdiProcess] = useState<string>("");
    const [executionMode, setExecutionMode] = useState<string>("");
    const [extension, setExtension] = useState<string>("");
    const [p1, setP1] = useState<string | undefined>(undefined);
    const [p2, setP2] = useState<string | undefined>(undefined);
    const [p3, setP3] = useState<string | undefined>(undefined);
    const [p4, setP4] = useState<string | undefined>(undefined);
    const [p5, setP5] = useState<string | undefined>(undefined);
    const [p6, setP6] = useState<string | undefined>(undefined);
    const [tpgClient, setTpgClient] = useState<string>("");
    const [watcherPath, setWatcherPath] = useState<string>("");
    const [isViewMode, setIsViewMode] = useState(false);

    useEffect(() => {
        if (!isOpen) {
            // set submitted to false and clear fields when dialog is closed
            resetInitialState();
        } else {
            setFromProcessDetails();
            setIsViewMode(isReadOnly);
        }
    }, [isOpen]);

    useEffect(() => {
        // if have a process, then populate for Edit
        if (process) {
            setFromProcessDetails();
        }
        else {
            resetInitialState();
        }
    }, [process]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly);
    }, [isReadOnly]);

    const resetInitialState = () => {
        setSubmitted(false);
        setDescription(undefined);
        setDestPath("");
        setEdiProcess("");
        setExecutionMode("");
        setExtension("");
        setP1(undefined);
        setP2(undefined);
        setP3(undefined);
        setP4(undefined);
        setP5(undefined);
        setP6(undefined);
        setTpgClient("");
        setWatcherPath("");
        setIsFormDirty(false);
    };

    const setFromProcessDetails = () => {
        if (process) {
            if (process.description) {
                setDescription(process.description);
            }
            if (process.destPath) {
                setDestPath(process.destPath);
            }
            if (process.ediProcess) {
                setEdiProcess(process.ediProcess);
            }
            if (process.executionMode) {
                setExecutionMode(process.executionMode);
            }
            if (process.extension) {
                setExtension(process.extension);
            }
            if (process.p1) {
                setP1(process.p1);
            }
            if (process.p2) {
                setP2(process.p2);
            }
            if (process.p3) {
                setP3(process.p3);
            }
            if (process.p4) {
                setP4(process.p4);
            }
            if (process.p5) {
                setP5(process.p5);
            }
            if (process.p6) {
                setP6(process.p6);
            }
            if (process.tpgClient) {
                setTpgClient(process.tpgClient);
            }
            if (process.watcherPath) {
                setWatcherPath(process.watcherPath);
            }
        }
    };

    const onEditCancel = () => {
        if (isReadOnly) {
            setIsViewMode(true);
            setFromProcessDetails();
        } else {
            onClose();
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        let requiredCheck = (
            destPath.trim().length > 0 &&
            ediProcess.trim().length > 0 &&
            executionMode.trim().length > 0 &&
            extension.trim().length > 0 &&
            tpgClient.trim().length > 0 &&
            watcherPath.trim().length > 0
        );

        return isFormDirty && requiredCheck;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(destPath, ediProcess, executionMode, extension, tpgClient, watcherPath, description, id, p1, p2, p3, p4, p5, p6);
    };

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onDestPathChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDestPath(event.target.value);
    };

    const onEdiProcessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setEdiProcess(event.target.value);
    };

    const onExecutionModeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setExecutionMode(event.target.value);
    };

    const onExtensionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setExtension(event.target.value);
    };

    const onP1Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP1(event.target.value);
    };

    const onP2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP2(event.target.value);
    };

    const onP3Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP3(event.target.value);
    };

    const onP4Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP4(event.target.value);
    };

    const onP5Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP5(event.target.value);
    };

    const onP6Change = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setP6(event.target.value);
    };

    const onTpgClientChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setTpgClient(event.target.value);
    };

    const onWatcherPathChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setWatcherPath(event.target.value);
    };

    const viewModeEditForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 250,
    };

    const destPathProps = {
        'aria-label': 'destination-path',
        'maxLength': 250,
    };

    const ediProcessProps = {
        'aria-label': 'edi-process',
        'maxLength': 70,
    };

    const executionModeProps = {
        'aria-label': 'execution-mode',
        'maxLength': 50,
    };

    const extensionProps = {
        'aria-label': 'extension',
        'maxLength': 50,
    };

    const p1Props = {
        'aria-label': 'p1',
        'maxLength': 250,
    };

    const p2Props = {
        'aria-label': 'p2',
        'maxLength': 250,
    };

    const p3Props = {
        'aria-label': 'p3',
        'maxLength': 250,
    };

    const p4Props = {
        'aria-label': 'p4',
        'maxLength': 1000,
    };

    const p5Props = {
        'aria-label': 'p5',
        'maxLength': 250,
    };

    const p6Props = {
        'aria-label': 'p6',
        'maxLength': 250,
    };

    const tpgClientProps = {
        'aria-label': 'tpg-client',
        'maxLength': 50,
    };

    const watcherPathProps = {
        'aria-label': 'watcher-path',
        'maxLength': 250,
    };

    const getViewFields = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='edi-process'>EDI Process</Typography>
                    <Typography variant='body1' >{ediProcess}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='destination-path'>Destination Path</Typography>
                    <Typography variant='body1' >{destPath}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='description'>Description</Typography>
                    <Typography variant='body1' >{description}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='execution-mode'>Execution Mode</Typography>
                    <Typography variant='body1' >{executionMode}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='extension'>Extension</Typography>
                    <Typography variant='body1' >{extension}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='tpg-client'>Tpg Client</Typography>
                    <Typography variant='body1' >{tpgClient}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p1'>P1</Typography>
                    <Typography variant='body1' >{p1}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p2'>P2</Typography>
                    <Typography variant='body1' >{p2}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p3'>P3</Typography>
                    <Typography variant='body1' >{p3}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p4'>P4</Typography>
                    <Typography variant='body1' >{p4}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p5'>P5</Typography>
                    <Typography variant='body1' >{p5}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant='caption' aria-label='p6'>P6</Typography>
                    <Typography variant='body1' >{p6}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='caption' aria-label='watcher-path'>Watcher Path</Typography>
                    <Typography variant='body1' >{watcherPath}</Typography>
                </Grid>
            </Grid>
        );
    };

    const getEditFields = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-process-edi-process"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={ediProcess}
                        label="EDI Process"
                        inputProps={ediProcessProps}
                        onChange={onEdiProcessChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-edi-process"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-process-dest-path"
                        fullWidth
                        disabled={submitted}
                        value={destPath}
                        label="Destination Path"
                        inputProps={destPathProps}
                        onChange={onDestPathChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-dest-path"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-process-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-execution-mode"
                        fullWidth
                        disabled={submitted}
                        value={executionMode}
                        label="Execution Mode"
                        inputProps={executionModeProps}
                        onChange={onExecutionModeChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-execution-mode"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-extension"
                        fullWidth
                        disabled={submitted}
                        value={extension}
                        label="Extension"
                        inputProps={extensionProps}
                        onChange={onExtensionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-extension"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-tpg-client"
                        fullWidth
                        disabled={submitted}
                        value={tpgClient}
                        label="Tpg Client"
                        inputProps={tpgClientProps}
                        onChange={onTpgClientChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-tpg-client"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p1"
                        fullWidth
                        disabled={submitted}
                        value={p1}
                        label="P1"
                        inputProps={p1Props}
                        onChange={onP1Change}
                        autoComplete="off"
                        data-cy="dialog-process-p1"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p2"
                        fullWidth
                        disabled={submitted}
                        value={p2}
                        label="P2"
                        inputProps={p2Props}
                        onChange={onP2Change}
                        autoComplete="off"
                        data-cy="dialog-process-p2"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p3"
                        fullWidth
                        disabled={submitted}
                        value={p3}
                        label="P3"
                        inputProps={p3Props}
                        onChange={onP3Change}
                        autoComplete="off"
                        data-cy="dialog-process-p3"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p4"
                        fullWidth
                        disabled={submitted}
                        value={p4}
                        label="P4"
                        inputProps={p4Props}
                        onChange={onP4Change}
                        autoComplete="off"
                        data-cy="dialog-process-p4"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p5"
                        fullWidth
                        disabled={submitted}
                        value={p5}
                        label="P5"
                        inputProps={p5Props}
                        onChange={onP5Change}
                        autoComplete="off"
                        data-cy="dialog-process-p5"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        itemID="dialog-process-p6"
                        fullWidth
                        disabled={submitted}
                        value={p6}
                        label="P6"
                        inputProps={p6Props}
                        onChange={onP6Change}
                        autoComplete="off"
                        data-cy="dialog-process-p6"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-process-watcher-path"
                        fullWidth
                        disabled={submitted}
                        value={watcherPath}
                        label="Watcher Path"
                        inputProps={watcherPathProps}
                        onChange={onWatcherPathChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-process-watcher-path"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        );
    };

    const getContent = () => {
        return isViewMode ? getViewFields() : getEditFields();
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="EDI Process"
            maxWidth='md'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            onCancel={onEditCancel}
            isReadOnly={isViewMode}
            onViewModeEdit={viewModeEditForm}
            canEditFromReadOnly={true}
            viewerRole={viewerRole}
        >
            {getContent()}
        </AddEditDialog>
    );
};

export default ProcessDialog;