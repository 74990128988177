import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { DateTime } from 'luxon';
import { RequestResult } from '../../gql-types.generated';
import { Button, Grid, Typography } from '@mui/material';
import PricingIcon from '@mui/icons-material/ReceiptLong';
import DeleteIcon from '@mui/icons-material/Delete';
import { CardAvatar, CountBox, DetailsTabContainer, DetailsTabHeader, DetailsTabHeaderWrapper, DetailsTabPanelContainer, TabHeader, TabLabel } from '../../util/SharedStyles';

import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { getFormattedDateTimeString } from '../../util/DateTimeUtility';
import { viewerCanEdit } from '../../util/ViewerUtility';

import { upsertPricing, deletePricing, fetchPricingById } from './PricingDetailsActions';
import { captureUpsertPricingStatus, captureDeletePricingStatus, clearError, clearState, selectPricing, selectError, selectPricingItems, selectUpsertPricingStatus, selectDeletePricingStatus } from './PricingDetailsSlice';
import TabPanel from '../../components/TabPanel';
import PricingItemsList from '../../components/lists/PricingItemsList';
import EditButton from '../../components/buttons/EditButton';
import PricingDialog from '../../components/dialogs/PricingDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import DetailsPage from '../../components/DetailsPage';

interface PricingDetailsProps {
    viewer: Viewer | undefined;
}

const PricingDetails: React.FC<PricingDetailsProps> = (props) => {
    // pricingId will be passed in through router
    const { pricingId } = useParams();
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [pricingDescription, setPricingDescription] = useState<string | undefined>(undefined);
    const [currencyCode, setCurrencyCode] = useState<string>('');
    const [serviceFees, setServiceFees] = useState<number>(0);
    const [lastModifiedBy, setLastModifiedBy] = useState<string | undefined>(undefined);
    const [lastModifiedTime, setLastModifiedTime] = useState<string | undefined>(undefined);

    const [tabValue, setTabValue] = useState(0);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const upsertPricingStatus = useAppSelector(selectUpsertPricingStatus);
    const deletePricingStatus = useAppSelector(selectDeletePricingStatus);
    const error = useAppSelector(selectError);
    const pricingInfo = useAppSelector(selectPricing);
    const pricingItems = useAppSelector(selectPricingItems);

    const [verticalAlign, setVerticalAlign] = useState<boolean>(false);
    // detailsPageProps
    const [detailsRecordLoaded, setDetailsRecordLoaded] = useState<boolean>(false);
    const [detailsPageTitle, setDetailsPageTitle] = useState<string | undefined>(undefined);
    const [detailsToolbarButtons, setDetailsToolbarButtons] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsAV, setDetailsAV] = useState<JSX.Element | undefined>(undefined);
    const [detailsHeader, setDetailsHeader] = useState<string | undefined>(undefined);
    const [detailsSubHeader, setDetailsSubHeader] = useState<string | undefined>(undefined);
    const [detailsChips, setDetailsChips] = useState<JSX.Element[] | undefined>(undefined);
    const [detailsEditButton, setDetailsEditButton] = useState<JSX.Element | undefined>(undefined);
    const [detailsFavoriteButton, setDetailsFavoriteButton] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        // use pricingId to get pricingDetails on render
        if (pricingId) {
            dispatch(fetchPricingById(pricingId));
        }
        return () => {
            dispatch(clearState());
        };
    }, []);

    useEffect(() => {
        // refresh pricing when a successful mutation occurs
        if (upsertPricingStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertPricingStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertPricingStatus());
            // close the modify dialog
            onPricingDialogClose();
            // we should always have the pricingId if we upserted, but check to prevent compile error
            if (pricingId) {
                dispatch(fetchPricingById(pricingId));
            }
        }
    }, [upsertPricingStatus?.result]);

    useEffect(() => {
        if (deletePricingStatus?.result === RequestResult.Success) {
            // close dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deletePricingStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // send user back to where they came from
            navigate(-1);
        }
        else if (deletePricingStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePricingStatus.message as string);
        }
    }, [deletePricingStatus?.result])

    useEffect(() => {
        if (pricingInfo) {
            if (pricingInfo.description) {
                setPricingDescription(pricingInfo.description);
            }
            if (pricingInfo.currencyCode) {
                setCurrencyCode(pricingInfo.currencyCode);
            }
            if (pricingInfo.minServiceFees) {
                setServiceFees(pricingInfo.minServiceFees);
            }
            if (pricingInfo.lastModifiedByName) {
                setLastModifiedBy(pricingInfo.lastModifiedByName);
            }
            if (pricingInfo.lastModifiedTime) {
                let formattedDate = getFormattedDateTimeString(pricingInfo.lastModifiedTime, { format: DateTime.DATETIME_FULL });
                setLastModifiedTime(formattedDate);
            }
        }
    }, [pricingInfo]);

    useTitle(pricingDescription);

    const updateAlignment = (verticalAlign: boolean) => {
        setVerticalAlign(verticalAlign);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        // save off the latest selected tab so that if user navigates to another
        // screen and comes back, their last active tab is remembered
        //setLocalStorageItem(viewer, localStorageTabIndexName, newValue.toString());
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onPricingDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        // Clear error and PricingContact on close.
        handleClearError();
    };

    const refreshPricingItems = async () => {
        if (pricingId) {
            await dispatch(fetchPricingById(pricingId as string));
        }
    };

    const onPricingDialogSave = (
        description: string,
        currencyCode: string,
        minServiceFees: number,
        id?: string) => {
        if (pricingId) {
            // upsert to save data
            dispatch(
                upsertPricing(
                    description,
                    currencyCode,
                    minServiceFees,
                    pricingId
                )
            );
        }
    };

    const getPricingDetails = () => {
        if (pricingInfo) {
            return (
                <Grid container alignItems="flex-start">
                    <Grid container spacing={2} columns={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Minimum Service Fees</Typography>
                            <Typography variant='body1' >{serviceFees.toFixed(2)}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Last Updated</Typography>
                            <Typography variant='body1' >{lastModifiedTime}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='caption' >Updated By</Typography>
                            <Typography variant='body1' >{lastModifiedBy}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    let countBoxElement;
    if (pricingItems && pricingItems.length > 0) {
        countBoxElement = <CountBox>{pricingItems.length}</CountBox>
    }

    let pricingItemLabel = (
        <Grid container item gap={"4px"} alignItems="center">
            <TabLabel>Transaction Pricing</TabLabel>
            {countBoxElement}
        </Grid>
    );

    const getTabbedContent = () => {
        if (pricingInfo) {
            return (
                <DetailsTabContainer>
                    <DetailsTabHeaderWrapper>
                        <DetailsTabHeader value={tabValue} onChange={handleTabChange} >
                            <TabHeader label={pricingItemLabel} id='vertical-tab-0' />
                        </DetailsTabHeader>
                    </DetailsTabHeaderWrapper>
                    <DetailsTabPanelContainer>
                        <TabPanel verticalAlign={verticalAlign} value={tabValue} index={0}>
                            {
                                <PricingItemsList
                                    viewerRole={viewer?.role}
                                    pricingId={pricingInfo.id}
                                    pricingCurrencyCode={pricingInfo.currencyCode}
                                    pricingItems={pricingItems}
                                    refreshPricingItems={refreshPricingItems}
                                />
                            }
                        </TabPanel>
                    </DetailsTabPanelContainer>
                </DetailsTabContainer>
            );
        }
    };

    const getDialogs = () => [<PricingDialog
        isOpen={openModify}
        pricing={pricingInfo}
        onClose={onPricingDialogClose}
        onSave={onPricingDialogSave}
        error={error}
        key="pricing-dialog"
    />,
    <DeleteDialog
        isOpen={openDelete}
        heading={'Delete Pricing'}
        message={`Are you sure you want to delete '${pricingDescription}'?`}
        id={pricingInfo?.id as string}
        onConfirm={handleDeletePricingConfirm}
        onReject={onDeleteDialogClose}
        errorMessage={deleteErrorMessage}
        key="delete-dialog"
    />];

    //edit current pricing
    const onEditClick = () => {
        setOpenModify(true);
    }

    const onDeletePricing = () => {
        dispatch(captureDeletePricingStatus());
        setDeleteErrorMessage('');
        setOpenDelete(true);
    };

    const handleDeletePricingConfirm = () => {
        if (pricingId) {
            dispatch(deletePricing(pricingId));
        }
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(clearError());
        dispatch(captureDeletePricingStatus());
        setDeleteErrorMessage('');
    };


    const canEdit = viewerCanEdit(viewer);
    let canDeletePricing = false;
    // only allow deletion of pricing if no billingItemCount 
    if (canEdit && pricingInfo) {
        let billingItemCount = pricingInfo.billingItemCount ?? 0;
        if (billingItemCount <= 0) {
            canDeletePricing = true;
        }
    }

    useEffect(() => {
        setDetailsRecordLoaded(!!pricingInfo);
        if (pricingInfo) {
            setDetailsPageTitle(pricingDescription);
            setDetailsAV(<CardAvatar aria-label="pricing" alt="pricing" >
                {<PricingIcon />}
            </CardAvatar>);
            setDetailsHeader(pricingDescription);
            setDetailsSubHeader(currencyCode);
            if (canEdit) {
                setDetailsEditButton(<EditButton
                    onClick={onEditClick}
                    ariaLabel="Edit Pricing button"
                    data-cy="edit-pricing-button"
                ></EditButton>);
            }
            setDetailsToolbarButtons([<Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                disabled={!canDeletePricing}
                onClick={onDeletePricing}
                key="delete-button"
            >
                Delete</Button>])
        }
    }, [pricingInfo, pricingDescription, currencyCode, canEdit]);

    return (
        <DetailsPage
            recordLoaded={detailsRecordLoaded}
            pageTitle={detailsPageTitle}
            toolBarButtons={detailsToolbarButtons}
            detailsAV={detailsAV}
            detailsHeader={detailsHeader}
            detailsSubHeader={detailsSubHeader}
            detailsChips={detailsChips}
            detailsEditButton={detailsEditButton}
            detailsFavoriteButton={detailsFavoriteButton}
            getDetailsListContainer={getPricingDetails}
            getTabbedContainer={getTabbedContent}
            getDialogs={getDialogs}
            error={error}
            updateAlignment={updateAlignment}
        />
    );
};

export default PricingDetails;