import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { Maybe, RequestResult, SystemConfigurationModel, UpsertSystemConfigurationInput } from '../../gql-types.generated';
import { fetchError, fetchSystemConfigurationSuccess, captureUpsertSystemConfigurationStatus } from './SystemConfigurationSlice';
import { querySystemConfiguration } from '../../gql/QuerySystemConfigurations';
import { mutationUpsertSystemConfiguration } from '../../gql/MutationUpsertSystemConfiguration';

export const fetchSystemConfiguration = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try { 
            const systemConfiguration = await querySystemConfiguration(gqlClient);
            if (systemConfiguration) {
                dispatch(fetchSystemConfigurationSuccess(systemConfiguration as SystemConfigurationModel));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertSystemConfiguration = (upsertSystemConfigurationData: UpsertSystemConfigurationInput): AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        const upsertStatus = await mutationUpsertSystemConfiguration(gqlClient, {
            upsertSystemConfigurationData
        });
        if (upsertStatus) {
            if (upsertStatus.result === RequestResult.Fail) {
                dispatch(fetchError({ message: upsertStatus.message } as Error));
                getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
            }
            dispatch(captureUpsertSystemConfigurationStatus(upsertStatus));
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
    }
};