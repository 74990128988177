import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { fetchSystemConfiguration, upsertSystemConfiguration } from './SystemConfigurationActions';
import { captureUpsertSystemConfigurationStatus, clearError, selectError, selectSystemConfiguration, selectUpsertSystemConfigurationStatus } from './SystemConfigurationSlice';
import { RequestResult, UpsertSystemConfigurationInput } from '../../gql-types.generated';
import { MainContentBox, PageTitleToolbarGrid, ScrollableGrid, GridItemMinWidth, ListCard, ListCardContentScrolling } from '../../util/SharedStyles';
import PageTitleBar from '../../components/PageTitleBar';
import EditButton from '../../components/buttons/EditButton';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { CardHeader, CircularProgress, styled, Typography } from '@mui/material';
import SystemConfigurationDialog from '../../components/dialogs/SystemConfigurationDialog';

const Loader = styled('div')((props) => ({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
}));

interface SystemConfigurationProps {
    viewer: Viewer | undefined;
}

const SystemConfiguration: React.FC<SystemConfigurationProps> = (props) => {
    const {viewer} = props;
    const dispatch = useAppDispatch();
    const canEdit = viewerCanEdit(viewer);
    
    const [openModify, setOpenModify] = useState(false);
    const [billingEmails, setBillingEmails] = useState<string[]>([]);
    const [errorEmails, setErrorEmails] = useState<string[]>([]);
    const [ediDirectEmails, setEdiDirectEmails] = useState<string[]>([]);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    
    
    const systemConfiguration = useAppSelector(selectSystemConfiguration);
    const upsertSystemConfigurationStatus = useAppSelector(selectUpsertSystemConfigurationStatus);
    const error = useAppSelector(selectError);

    useTitle("System Configuration");

    useEffect(() => {
        // get existing system configuration upon entering
        dispatch(fetchSystemConfiguration());
    }, []);

    useEffect(() => {
        if (upsertSystemConfigurationStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertSystemConfigurationStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertSystemConfigurationStatus());
            onDialogClose();
        }
    }, [upsertSystemConfigurationStatus?.result]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('system-config-title-comp')?.clientHeight || 0;
        if (titleHeight)
            setContentAreaHeight(`calc(100% - ${titleHeight}px)`);

        //setup display lists
        if (systemConfiguration?.billingRecipientsEmail) {
            setBillingEmails(stringToEmailArray(systemConfiguration.billingRecipientsEmail));
        } else {
            setBillingEmails([]);
        }
        if (systemConfiguration?.errorRecipientsEmail) {
            setErrorEmails(stringToEmailArray(systemConfiguration.errorRecipientsEmail));
        } else {
            setErrorEmails([]);
        }
        if (systemConfiguration?.ediDirectRecipientsEmail) {
            setEdiDirectEmails(stringToEmailArray(systemConfiguration.ediDirectRecipientsEmail));
        } else {
            setEdiDirectEmails([]);
        }
    }, [systemConfiguration]);

    const stringToEmailArray = (input:string) => {
        return input.split(',');
    }

    const onDialogClose = () => {
        setOpenModify(false);
        dispatch(clearError());
        dispatch(fetchSystemConfiguration());
    }

    const onEditClick = () => {
        setOpenModify(true);
    }

    const submitUpsertSystemConfiguration = (upsertSystemConfigurationData: UpsertSystemConfigurationInput) => {
        dispatch(upsertSystemConfiguration(upsertSystemConfigurationData))
    }

    const getErrorContent = () => {
        if (systemConfiguration) {
            if (errorEmails) {
                return (
                    errorEmails.map((email, i)=>{
                        const myKey = "error-email-" + i;
                        return (
                            <Typography key={myKey}>{email}</Typography>
                        )
                    })
                )
            } else {
                <Typography>No Error Recipients</Typography>
            }
        } else {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            )
        }
    };

    const getBillingContent = () => {
        if (systemConfiguration) {
            if (billingEmails) {
                return (
                    billingEmails.map((email, i)=>{
                        const myKey = "billing-email-" + i;
                        return (
                            <Typography key={myKey}>{email}</Typography>
                        )
                    })
                );
            } else {
                <Typography>No Billing Recipients</Typography>
            }
        } else {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        }
    };

    const getEdiDirectContent = () => {
        if (systemConfiguration) {
            if (ediDirectEmails) {
                return (
                    ediDirectEmails.map((email, i)=>{
                        const myKey = "edi-direct-email-" + i;
                        return (
                            <Typography key={myKey}>{email}</Typography>
                        )
                    })
                );
            } else {
                <Typography>No EDI Direct Recipients</Typography>
            }
        } else {
            return (
                <Loader id='loader' >
                    <CircularProgress />
                </Loader>
            );
        }
    };

    return (
        <MainContentBox>
            <PageTitleBar text='System Configuration' id="system-config-title-comp">
                    <PageTitleToolbarGrid item>
                        {canEdit &&
                            <EditButton
                                text="Edit"
                                onClick={onEditClick}
                                data-cy="edit-system-configuration"
                            />}
                    </PageTitleToolbarGrid>
            </PageTitleBar>
            <ScrollableGrid container justifyContent="center" spacing={4} scrollheight={contentAreaHeight}>
                    <GridItemMinWidth item xs={12} sm={8} md={3}>
                        <ListCard>
                            <CardHeader title="Billing Recipients"/>
                            <ListCardContentScrolling>
                                {getBillingContent()}
                            </ListCardContentScrolling>
                        </ListCard>
                    </GridItemMinWidth>
                    <GridItemMinWidth item xs={12} sm={8} md={3}>
                        <ListCard>
                            <CardHeader title="Error Recipients"/>
                            <ListCardContentScrolling>
                                {getErrorContent()}
                            </ListCardContentScrolling>
                        </ListCard>
                    </GridItemMinWidth>
                    <GridItemMinWidth item xs={12} sm={8} md={3}>
                        <ListCard>
                            <CardHeader title="EDI Direct Recipients"/>
                            <ListCardContentScrolling>
                                {getEdiDirectContent()}
                            </ListCardContentScrolling>
                        </ListCard>
                    </GridItemMinWidth>
            </ScrollableGrid>
            <SystemConfigurationDialog 
                isOpen={openModify}
                systemConfiguration={systemConfiguration}
                onClose={onDialogClose}
                onSave={submitUpsertSystemConfiguration}
                error={error}
            />
        </MainContentBox>
    );

}

export default SystemConfiguration;