import { styled, Alert, AlertTitle, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, Typography, Breakpoint, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { PaddedDialogContent } from '../../util/SharedStyles';
import { UserRole } from "../../gql-types.generated";
import { viewerCanEdit } from "../../util/ViewerUtility";
import EditButton from "../buttons/EditButton";
import DialogCloseButton from "./DialogCloseButton";

const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));

interface AddEditDialogProps {
    children?: React.ReactNode;
    isOpen: boolean;
    isSubmitted: boolean;
    isReadOnly?: boolean;
    canEditFromReadOnly?: boolean;
    id?: string;
    isLoading?: boolean;
    entityName: string;
    titlePrefix?: string;
    saveButtonText?: string;
    viewerRole?: UserRole | undefined;
    onClose: () => void;
    onCancel?: () => void;
    onSave: () => void;
    onViewModeEdit?: () => void;
    validate: () => boolean;
    onError: () => void;
    error?: Error | undefined;
    maxWidth?: Breakpoint | undefined;
    padding?: string;
    warningMessage?: string;
}

const AddEditDialog: React.FC<AddEditDialogProps> = props => {
    const { isOpen, isSubmitted, isReadOnly = false, canEditFromReadOnly = true, isLoading = false, id, entityName, titlePrefix, saveButtonText = "SAVE", onClose, onCancel = onClose, onSave, validate, onError, error, viewerRole, maxWidth, padding, warningMessage } = props;
    const [isViewMode, setIsViewMode] = useState(false);
    const [headingPrefix, setHeadingPrefix] = useState('');

    const isViewModeEditEnabled = viewerCanEdit(viewerRole) && canEditFromReadOnly;

    useEffect(() => {
        if (error) {
            onError();
            return;
        }
        // Error value can be stale if the effect was ran via a different dependency, must also have a success message to close.
        if (!error && isSubmitted) {
            onClose();
        }
    }, [error]);

    useEffect(() => {
        // set initial mode
        setIsViewMode(isReadOnly);
    }, [isReadOnly]);

    useEffect(() => {
        let prefix = 'Create New';
        if (titlePrefix) {
            // set to passed in prefix if there was one
            prefix = titlePrefix;
        }
        else if (isViewMode) {
            prefix = 'View';
        }
        else if (id) {
            prefix = 'Edit';
        }

        setHeadingPrefix(prefix);
    }, [isViewMode, id, titlePrefix]);

    const isFormValid = () => {
        return validate();
    };

    const submitForm = () => {
        if (isFormValid()) {
            onSave();
            if (isReadOnly) {
                // revert back to viewMode
                setIsViewMode(true);
            }
        }
    }

    const editForm = () => {
        // turn off viewMode to allow for editing
        setIsViewMode(false);
        if (props.onViewModeEdit) {
            props.onViewModeEdit();
        }
    }

    const cancelAddEdit = () => {
        if (isReadOnly) {
            // revert back to viewMode
            setIsViewMode(true);
        }
        onCancel();
    }

    const heading = `${headingPrefix} ${entityName}`;
    const maxW = maxWidth || 'xs';
    const inProgressState = isSubmitted || isLoading;

    return (
        <Dialog
            aria-label={heading}
            maxWidth={maxW}
            open={isOpen}
            scroll="paper"
        >
            <TitleBar id='dialog-title'>
                <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="body1">{heading}</Typography>
                    {(isViewMode && isViewModeEditEnabled) &&
                        <EditButton onClick={editForm} disabled={inProgressState}></EditButton>
                    }
                </TitleGrid>
                <DialogCloseButton onClick={onClose}></DialogCloseButton>
            </TitleBar>
            <PaddedDialogContent dividers padding={padding}>
                {error && (
                    <Alert severity="error">
                        <AlertTitle>Unable to {id ? 'Modify' : 'Add'} {entityName}</AlertTitle>
                        {error.message}
                    </Alert>
                )}
                {props.children}
            </PaddedDialogContent>
            {warningMessage &&
                <>
                <Alert severity="warning" sx={{ width: '100%', whiteSpace: 'pre-line' }} >
                    {warningMessage}
                </Alert>

                <Divider/>
                </>
            }
            
            {(!isViewMode) &&
                <DialogActions>
                    <Button
                        onClick={cancelAddEdit}
                        disabled={inProgressState}
                        data-cy="dialog-add-edit-entity-cancel"
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={submitForm}
                        disabled={!isFormValid() || inProgressState}
                        data-cy="dialog-add-edit-entity-save"
                    >
                        {saveButtonText}
                    </Button>
                </DialogActions>
            }
            {(inProgressState) &&
                <CircularProgress
                    aria-label={'progress spinner'}
                    size={48}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-24px',
                        marginLeft: '-24px',
                    }}
                />
            }

        </Dialog>
    );
};

export default AddEditDialog;