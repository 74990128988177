import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { ClientModel, RequestResult, UpsertClientInput } from '../../gql-types.generated';
import { fetchClientList, upsertClient } from './ClientsActions';
import { clearState, clearError, captureUpsertClientStatus, selectClientPagingResult, selectClientList, selectError, selectRequestsInFlight, selectUpsertClientStatus } from './ClientsSlice';

import { MainContentBox, CardListContentGrid, CardListScrollBox, CardListItemWrapperGrid, PageTitleToolbarGrid, WrappedLoadingGrid } from '../../util/SharedStyles';
import { useNavigate } from 'react-router-dom';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, defaultPageSize, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { useTitle } from '../../util/Common';

import PageTitleBar from '../../components/PageTitleBar';
import ClientDialog from '../../components/dialogs/ClientDialog';
import ClientListItem from '../../components/listItems/ClientListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import FiltersButton from '../../components/buttons/FiltersButton';
import ClientsFilterBar from '../../components/filters/ClientsFilterBar';

interface ClientsProps {
    viewer: Viewer | undefined;
}

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids


const Clients: React.FC<ClientsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterName, setFilterName] = useState<string | undefined>(undefined);
    const [filterStatuses, setFilterStatuses] = useState<string[]>([]);
    const [filterBusinessAreaIds, setFilterBusinessAreaIds] = useState<string[]>([]);
    const [filterProductIds, setFilterProductIds] = useState<string[]>([]);
    const [filterCity, setFilterCIty] = useState<string | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const error = useAppSelector(selectError);
    const clientPagingResult = useAppSelector(selectClientPagingResult);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const upsertClientStatus = useAppSelector(selectUpsertClientStatus);

    const pageSize = defaultPageSize;

    const clients = useAppSelector(selectClientList);

    const canEdit = viewerCanEdit(viewer);

    useTitle("Clients");

    useEffect(() => {
        if (upsertClientStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertClientStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertClientStatus());
            onClientDialogClose();
            // if a client Id was successfully returned, navigate 
            // to the new client details
            if (upsertClientStatus?.client?.id) {
                // simulate a card click to navigate
                cardClickAction(upsertClientStatus?.client?.id);
            }
        }
    }, [upsertClientStatus?.result]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('clients-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('clients-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [clients, filtersOpen]);

    useEffect(() => {
        // make sure clients has loaded before we make additional calls
        if (clients) {
            // keep track of the number of filters to know if
            // should display badge in Filters button
            let numFilters = 0;
            if (filterName && filterName.length > 0) {
                numFilters += 1;
            }
            if (filterStatuses.length > 0) {
                numFilters += 1;
            }
            if (filterBusinessAreaIds.length > 0) {
                numFilters += 1;
            }
            if (filterProductIds.length > 0) {
                numFilters += 1;
            }
            if (filterCity && filterCity.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [clients, filterName, filterStatuses, filterBusinessAreaIds, filterProductIds, filterCity]);

    useEffect(() => {
        if (!clients && requestsInFlight > 0) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [clients, requestsInFlight]);


    const loadPage = (endEdge: string | undefined) => {
        dispatch(fetchClientList(endEdge, pageSize, filterName, filterBusinessAreaIds, filterProductIds, filterStatuses, filterCity));
    };

    const handlePageLoad = (page: number) => {
        if (!clientPagingResult) {
            return;
        }
        if (!clientPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(clientPagingResult.cursor.nextPage);
    };

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/client/" + id;
            navigate(route);
        }
    };

    const hasMoreClients = (clientPagingResult?.cursor?.nextPage !== null && clientPagingResult?.cursor?.nextPage !== undefined && clientPagingResult?.cursor?.nextPage !== '') as boolean;
    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'client-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} md={6} lg={4} xl={3} key={key}>
                    <ClientListItem loading={true} clickAction={cardClickAction} />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'client-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the Clients' error={error}></ErrorMessage>
            );
        }
        if (!clients && requestsInFlight > 0) {
            return getLoaders();
        }
        else if (clients && clients.length) {
            return (
                clients.map((client: ClientModel) => (
                    <CardListItemWrapperGrid item xs={12} md={6} lg={4} xl={3} key={client.id}>
                        <ClientListItem
                            name={client.name as string}
                            id={client.id}
                            code={client.clientCode}
                            status={client.statusCode}
                            viewer={viewer}
                            numTradingPartners={client.totalPartners}
                            businessAreaName={client.businessArea?.name}
                            erpProductName={client.erpProduct?.name}
                            clickAction={cardClickAction}
                            website={client.website}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // No clients to show 
            // Display appropriate no results/records found image/message
            if (filterCount > 0) {
                return (<NoResultsMessage />);
            }
            else {
                return (<NoRecordsMessage message="" />);
            }
        }
    };

    const onAddClientClick = () => {
        // Clear error 
        handleClearError();
        setOpenModify(true);
    };

    const onClientDialogSave = (
        clientInput: UpsertClientInput
    ) => {
        dispatch(
            upsertClient(clientInput)
        );
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onClientDialogClose = () => {
        setOpenModify(false);
        // Clear error and ClientContact on close.
        handleClearError();
        // Refresh list to bring in potential updates
        loadPage(undefined);
    };

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterName: string | undefined, filterBusinessAreaIds: string[], filterProductIds: string[], filterStatuses: string[], filterCity: string | undefined) => {
        setFilterName(filterName);
        setFilterBusinessAreaIds(filterBusinessAreaIds);
        setFilterProductIds(filterProductIds);
        setFilterStatuses(filterStatuses);
        setFilterCIty(filterCity);

        // filters changed so refresh the list
        dispatch(clearState());
        dispatch(fetchClientList(undefined, pageSize, filterName, filterBusinessAreaIds, filterProductIds, filterStatuses, filterCity));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Clients' id="clients-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="filters"
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="New Client"
                            onClick={onAddClientClick}
                            data-cy="add-new-client"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>

            <ClientsFilterBar
                id="clients-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}/>

            <CardListScrollBox scrollheight={contentAreaHeight}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handlePageLoad}
                    hasMore={hasMoreClients}
                    loader={getLoaders(true) as ReactElement}
                    useWindow={false}
                    initialLoad={false}
                >
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </InfiniteScroll>
            </CardListScrollBox>
            <ClientDialog
                isOpen={openModify}
                onClose={onClientDialogClose}
                onSave={onClientDialogSave}
                error={error}
            />
        </MainContentBox>
    );
};

export default Clients;