import { TextField, InputAdornment } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';

/** Component for dislpaying the filter input control on list pages */

interface FilterInputProps {
    placeholder?: string | undefined;
    id?: string | undefined;
    className?: string | undefined;
    onFilter?: any;
}

const FilterInput: React.FC<FilterInputProps> = (props: FilterInputProps) => {
    const { placeholder = 'Filter', onFilter, className } = props;

    return (
        <TextField
            placeholder={placeholder}
            type="search"
            // variant="filled"
            size="small"
            onChange={onFilter}
            className={className}
            autoComplete="off"
            InputProps={{
                startAdornment: (
                <InputAdornment position="start">
                    <FilterListIcon />
                </InputAdornment>
                ),
            }}
    />
    )
};

export default FilterInput;