import { useEffect, useState } from 'react';
import { GridColumns, GridRowModel, GridOverlay, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { CircularProgress, Typography, Skeleton } from '@mui/material';
import { TabContainer, TabContent, TabToolbar, TabDataGrid, TabDataGridNoRowHover } from '../../../util/SharedStyles';
import { RequestResult, UserRole, DeleteByIdPayload } from '../../../gql-types.generated';
import CreateNewButton from '../../buttons/CreateNewButton';
import { calculateAddTransactionDialogMinWidth } from '../../../util/Common';
import { viewerCanEdit } from '../../../util/ViewerUtility';
import AddTransactionDialog from '../../dialogs/AddTransactionDialog';
import DeleteDialog from '../../dialogs/DeleteDialog';
import NoRecordsMessage from '../../NoRecordsMessage';

interface TransactionListProps {
    viewerRole: UserRole | undefined;
    transactionGridColumns: GridColumns<GridRowModel>;
    currentTransactionRows: GridRowModel[] | undefined;
    availableTransactionRows: GridRowModel[];
    parentEntityTypeName: string;
    error?: Error | undefined;
    loadingAvailableRows?: boolean;
    deleteStatus?: DeleteByIdPayload | undefined;
    addResult?: RequestResult | undefined;
    singleSelect?: boolean;
    outboundOnly?: boolean;
    onDeleteDialogConfirm: () => void;
    onDeleteDialogClose: () => void;
    onAddDialogSave: (selectedTransactionIds?: string[]) => void;
    openDeleteDialog: boolean;
    refreshList: () => void;
    clearError: () => void;
    onRowClick?: (selectedRowParams: GridRowParams) => void;
}

const TransactionList: React.FC<TransactionListProps> = (props: TransactionListProps) => {
    const { viewerRole, parentEntityTypeName, transactionGridColumns, currentTransactionRows, availableTransactionRows, error, openDeleteDialog, deleteStatus, addResult, loadingAvailableRows = false, singleSelect = false, outboundOnly = false, refreshList, clearError, onRowClick } = props;
    const canEdit = viewerCanEdit(viewerRole);
    const [openAdd, setOpenAdd] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedRowId, setSelectedRowId] = useState<string>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'direction',
            sort: 'asc',
        },
    ]);
    const [addDialogMinWidth, setAddDialogMinWidth] = useState<string>();

    const hasRowClick = onRowClick ? true : false;

    useEffect(() => {
        if (deleteStatus?.result === RequestResult.Success) {
            deleteDialogCloseHandler();
            // Refresh list to bring in potential updates
            refreshList();
        }
        else if (deleteStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteStatus?.message as string);
        }
    }, [deleteStatus?.result]);

    useEffect(() => {
        if (addResult === RequestResult.Success) {
            onTransactionDialogClose();
            // Refresh list to bring in potential updates
            refreshList();
        }
    }, [addResult]);

    useEffect(() => {
        if (availableTransactionRows && availableTransactionRows.length > 0) {
            let minWidth = calculateAddTransactionDialogMinWidth(availableTransactionRows as GridRowModel[], singleSelect);
            if (minWidth > 0) {
                setAddDialogMinWidth(`${minWidth}px`);
            }
        }
    }, [availableTransactionRows]);

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'transactions-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && currentTransactionRows?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };
    
    // add transaction from list of existing
    const onAddTransactionClick = () => {
        // Clear error and open dialog
        clearError();
        setOpenAdd(true);
    };

    const onTransactionDialogClose = () => {
        setOpenAdd(false);
        onDialogClose();
    };

    const deleteDialogCloseHandler = () => {
        props.onDeleteDialogClose();
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear selectedRow and error on close.
        setSelectedRowId(undefined);
        clearError();
    };

    const deleteDialogConfirmHandler = (id: string) => {
        // delete the selected transaction through child component
        // don't need to pass the id because child will determine id 
        props.onDeleteDialogConfirm();
    }

    const onTransactionDialogSave = (selectedTransactionIds?: string[]) => {
        props.onAddDialogSave(selectedTransactionIds);
    };

    let addButton = loadingAvailableRows ? (
        <Skeleton animation="wave" height={40} width={190} />
    ) : (
        <CreateNewButton
            text={`Add ${outboundOnly ? 'Outbound' : ''} Transaction`}
            disabled={availableTransactionRows?.length <= 0}
            onClick={onAddTransactionClick}
            data-cy="add-existing-transaction"
        />
    );

    return (
        <TabContainer>
            <TabToolbar justify="flex-end">
                {canEdit &&
                    addButton
                }
            </TabToolbar>
            <TabContent>
            {hasRowClick &&
                <TabDataGrid
                    loading={currentTransactionRows === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Transactions List"
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    rows={currentTransactionRows ?? []}
                    columns={transactionGridColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    onRowClick={onRowClick}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            }
            {!hasRowClick &&
                <TabDataGridNoRowHover
                    loading={currentTransactionRows === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Transactions List"
                    hideFooter
                    disableSelectionOnClick
                    disableColumnMenu
                    rows={currentTransactionRows ?? []}
                    columns={transactionGridColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            }
            </TabContent>
            
            <DeleteDialog
                isOpen={openDeleteDialog}
                id={selectedRowId ?? ''}
                heading={'Delete Transaction'}
                message={`Are you sure you want to remove this transaction from this ${parentEntityTypeName}?`}
                onConfirm={deleteDialogConfirmHandler}
                onReject={deleteDialogCloseHandler}
                errorMessage={deleteErrorMessage}
            />
            <AddTransactionDialog
                isOpen={openAdd}
                transactionRows={availableTransactionRows}
                minWidth={addDialogMinWidth}
                singleSelect={singleSelect}
                outboundOnly={outboundOnly}
                onClose={onTransactionDialogClose}
                onSave={onTransactionDialogSave}
                error={error}
            />
        </TabContainer>
    );

}

export default TransactionList;