import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertAttachmentInput, UpsertClientAttachmentPayload, UpsertClientAttachmentInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_ATTACHMENTS = gql`
    mutation upsertClientAttachments($input: UpsertClientAttachmentInput!){
        upsertClientAttachment(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientAttachments = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        attachments?: UpsertAttachmentInput[];
        clientId: string;
    } 
): Promise<UpsertClientAttachmentPayload | undefined> => {
    const { attachments, clientId } = options as UpsertClientAttachmentInput;
    const { data, errors } = await client.mutate<{ upsertClientAttachment: UpsertClientAttachmentPayload}>({
        variables: { 
            input: { 
                attachments,
                clientId,
            } 
        },
        mutation: MUTATION_UPSERT_CLIENT_ATTACHMENTS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add client attachments.');
    }
    if (data && data.upsertClientAttachment) {
        return data.upsertClientAttachment;
    }

    return undefined;
};