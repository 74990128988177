import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ErpTransactionSchemasQuery, GetErpTransactionSchemaInput, ErpTransactionSchemaModel, Scalars } from '../gql-types.generated';



// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_ERP_SCHEMAS = gql`
query erpTransactionSchemas($input: GetErpTransactionSchemaInput!) {
    erpTransactionSchemas (input: $input) {
      nodes {
        id
        transactionId
        description
        documentString
        sourceFileName
      }
    }
}`;

export const queryErpSchemasByTransactionId = async (
    client: ApolloClient<NormalizedCacheObject>,
    transactionId: Scalars['UUID']
): Promise<ErpTransactionSchemaModel[] | undefined> => {
    const { data, errors } = await client.query({
        variables: {
            input: {
                transactionId: transactionId
            }
        },
        query: QUERY_ERP_SCHEMAS,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.erpTransactionSchemas?.nodes as ErpTransactionSchemaModel[];
};