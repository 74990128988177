import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPipelineItemPayload, Maybe, UpsertPipelineItemInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PIPELINE_ITEM = gql`
    mutation upsertPipelineItem($input: UpsertPipelineItemInput!){
        upsertPipelineItem(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertPipelineItem = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertPipelineItemData: UpsertPipelineItemInput
    }
): Promise<UpsertPipelineItemPayload | undefined> => {
    const { configurationId, pipelineId, sortOrder, description, ediProcessClassName, jsonArgs, id } = args.upsertPipelineItemData;
    const { data, errors } = await client.mutate<{ upsertPipelineItem: UpsertPipelineItemPayload }>({
        variables: {
            input: {
                configurationId,
                pipelineId,
                sortOrder,
                description,
                ediProcessClassName,
                jsonArgs,
                id
            }
        },
        mutation: MUTATION_UPSERT_PIPELINE_ITEM
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of PipelineItem.');
    }
    if (data && data.upsertPipelineItem) {
        return data.upsertPipelineItem;
    }

    return undefined;
};