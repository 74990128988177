import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CounterModel, DeleteByIdPayload, PagingResultModelOfCounterModel, UpsertCounterPayload } from "../../gql-types.generated";
import { RootState } from "../../store";



interface SliceState {
    error?: Error;
    counterList?: CounterModel[];
    counterPagingResult?: PagingResultModelOfCounterModel;
    upsertCounterStatus?: UpsertCounterPayload;
    deleteCounterStatus?: DeleteByIdPayload;
    counterRequestsInFlight: number;
};

const initialState: SliceState = {
    error: undefined,
    counterList: undefined,
    counterPagingResult: undefined,
    upsertCounterStatus: undefined,
    deleteCounterStatus: undefined,
    counterRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'counterTable',
    initialState,
    reducers:{
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.counterList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.counterPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertCounterStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteCounterStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.counterRequestsInFlight = 0;
        },

        fetchCounterListSuccess: (state, action: PayloadAction<PagingResultModelOfCounterModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.counterPagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let counters = state.counterList;
            if (!counters || !state.counterPagingResult.cursor?.previousPage) {
                counters = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!counters?.some(i => i.id === node.id)) {
                        counters?.push(node as CounterModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.counterList = counters;
        },

        incrementCounterRequestInFlight: state => {            
            // eslint-disable-next-line no-param-reassign
            state.counterRequestsInFlight += 1;
        },

        decrementCounterRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.counterRequestsInFlight > 0) state.counterRequestsInFlight -= 1;
        },

        captureUpsertCounterStatus: (state, action: PayloadAction<UpsertCounterPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertCounterStatus = action.payload;
        },

        captureDeleteCounterStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteCounterStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.counterTable.error;
export const selectRequestsInFlight = (state: RootState): number => state.counterTable.counterRequestsInFlight;
export const selectCounterList = (state: RootState): CounterModel[] | undefined => state.counterTable.counterList;
export const selectCounterPagingResult = (state: RootState): PagingResultModelOfCounterModel | undefined => state.counterTable.counterPagingResult;
export const selectUpsertCounterStatus = (state: RootState): UpsertCounterPayload | undefined => state.counterTable.upsertCounterStatus;
export const selectDeleteCounterStatus = (state: RootState): DeleteByIdPayload | undefined => state.counterTable.deleteCounterStatus;

export const {
    clearError,
    clearState,
    fetchCounterListSuccess,
    fetchError,
    incrementCounterRequestInFlight,
    decrementCounterRequestsInFlight,
    captureUpsertCounterStatus,
    captureDeleteCounterStatus
} = slice.actions;

export default slice.reducer;