import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertUserPayload, UserRole, Maybe } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_USER = gql`
    mutation upsertUser($input: UpsertUserInput!) {
        upsertUser(input: $input) {
            user {                
                id
                firstName
                lastName
                email
                role
                isActive
                __typename
              }
            message
            errors {
                code
                message
            }
            result
            isReactivated
        }
    }
`;

export const mutationUpsertUser = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        role: UserRole;
        email?: string;
        firstName?: string;
        lastName?: string;
        id?: Maybe<string>;
        isActive?: boolean;
    }
): Promise<UpsertUserPayload | undefined> => {
    const { role, email, firstName, lastName, id, isActive } = options;
    const { data, errors } = await client.mutate<{ upsertUser: UpsertUserPayload }>({
        variables: { input: { role, email, firstName, lastName, id, isActive } },
        mutation: MUTATION_UPSERT_USER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the person.
        throw new Error('An error has occurred during the person upsert.');
    }
    if (data && data.upsertUser) {
        return data.upsertUser;
    }

    return undefined;
};