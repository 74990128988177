import { useState } from 'react';
import { Divider, Grid, Skeleton, Typography } from "@mui/material";
import PartnerIcon from "@mui/icons-material/SafetyDivider";
import TransactionIcon from "@mui/icons-material/Description";

import { EntityType, PartnerModel } from "../../gql-types.generated";
import { CardChip, CardFooter, ClickableRootCard, RootCardHeader } from '../../util/SharedStyles';
import { getAvatarComponent, getWebsiteLink } from '../../util/Common';
import { getEntityFavorite } from '../../util/ViewerUtility';
import FavoriteToggleButton from '../buttons/FavoriteToggleButton';
import { Viewer } from '../../util/Constants';

interface TradingPartnerListItemProps {
    loading?: boolean | false;
    tradingPartner?: PartnerModel | undefined;
    viewer: Viewer | undefined;
    clickAction: (id: string | undefined) => void;
}

const TradingPartnerListItem: React.FC<TradingPartnerListItemProps> = (props: TradingPartnerListItemProps) => {
    const { loading, tradingPartner, viewer, clickAction } = props;
    const name = tradingPartner?.name;
    const id = tradingPartner?.id;
    const website = tradingPartner?.website;
    const ediStandards = tradingPartner?.ediStandards;
    const totalTransactions = tradingPartner?.totalTransactions;
    
    const [favIconErrored, setFavIconErrored] = useState<boolean>(false);

    const cardClick = () => {
        if (!loading) {
            clickAction(id)
        }
    };

    const getFavorite = () => {
        // call utility method to get if this partner is a favorite
        return getEntityFavorite(viewer?.favorites, id, EntityType.Partner);
    }

    let action = loading ? (
        <Skeleton animation="wave" height={24} width={24} />
    ) : (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <FavoriteToggleButton
                entityId={id as string}
                entityName={name ?? ''}
                entityType={EntityType.Partner}
                favorite={getFavorite()}
            ></FavoriteToggleButton>
        </Grid>
    );

    const handlefavIconError = () => {
        setFavIconErrored(true);
    }

    let avatar = loading ? (
        <Skeleton animation="wave" variant="circular" width={40} height={40} />
    ) : (
        getAvatarComponent(
            website,
            favIconErrored,
            name,
            "Partner",
            <PartnerIcon />,
            handlefavIconError
        )
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        name
    );

    let subheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
    ) : (
        getWebsiteLink(website as string)
    );

    let chipContainerJustify = "flex-end";
    let transactionElement = null;
    if (totalTransactions && totalTransactions > 0) {
        chipContainerJustify = "space-between";
        transactionElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <TransactionIcon fontSize='small' />
                <Typography>Transactions: {totalTransactions}</Typography>
            </Grid>
        )
    }
    
    let standardChip;
    if (ediStandards && ediStandards[0] && ediStandards[0].name) {
        standardChip = <CardChip label={ediStandards[0].name} />
    }
    let chipsElement = (
        <Grid item xs={'auto'}>
            {standardChip}
        </Grid>
    )

    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                avatar={avatar}
                title={title}
                subheader={subheader}
                subheaderTypographyProps={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light />
            <CardFooter justify={chipContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {transactionElement}
                            {chipsElement}
                        </>
                    )
                }
            </CardFooter>
        </ClickableRootCard>
    );
};

export default TradingPartnerListItem;