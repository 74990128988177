import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import capitalize from 'lodash/capitalize';
import { GridColumns, GridRowModel, GridOverlay, GridRenderCellParams, GridSelectionModel, GridSortModel } from '@mui/x-data-grid-pro';
import { Grid, Checkbox, CircularProgress, FormControlLabel, TextField, Typography, Stack, styled, Tooltip } from '@mui/material';
import { TabDataGrid } from '../../util/SharedStyles';
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import { PipelineModel, TransactionDirection, UpsertPipelineInput } from '../../gql-types.generated';
import { clearError, selectError, selectTransactionList } from '../../features/EDIContainer/EDIContainerSlice';
import GridCellDualVert from '../listItems/GridCellDualVert';
import AddEditDialog from "./AddEditDialog";


const DialogContentWrapper = styled(Grid)((props) => ({
    minWidth: '500px',
}));

const DialogDataGrid = styled(TabDataGrid)((props) => ({
    padding: '8px 0px !important',
    width: '100%',
    height: '400px',
}));


interface PipelineDialogProps {
    isOpen: boolean;
    pipeline?: PipelineModel | null | undefined;
    onClose: () => void;
    onSave: (saveData: UpsertPipelineInput) => void;
    error?: Error | undefined;
}


const PipelineDialog: React.FC<PipelineDialogProps> = props => {
    const { isOpen, pipeline, onClose, onSave, error } = props;
    const id = pipeline?.id;
    const dispatch = useAppDispatch();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [description, setDescription] = useState<string>('');
    //const [isLegacy, setIsLegacy] = useState<boolean | undefined>(undefined);
    const [transactionId, setTransactionId] = useState<string>('');
    const [transactionName, setTransactionName] = useState<string>('');
    const [transactionRows, setTransactionsRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'name',
            sort: 'asc',
        },
    ]);

    const fetchError = useAppSelector(selectError);

    const transactions = useAppSelector(selectTransactionList);

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsFormDirty(false);
        } else {
            setFromPipeline();
        }
    }, [isOpen]);

    useEffect(() => {
        setTransactionsRows(getTransactionRows());
    }, [transactions]);

    useEffect(() => {
        // if have a pipeline, then populate for Edit
        if (pipeline) {
            setFromPipeline();
        } else {
            setToDefaults();
        }
    }, [transactionRows, pipeline]);

    const setToDefaults = () => {
        setSelectionModel([]);
        setDescription('');
        setTransactionId('');
        setTransactionName('');
        dispatch(clearError());
    }

    const setFromPipeline = () => {
        if (pipeline) {
            setDescription(pipeline.description ?? '');

            // once have rows, set the selected row if we are in Edit mode/have a pipeline
            if (transactionRows && transactionRows.length > 0 && pipeline.transaction && pipeline.transaction.id) {
                setTransactionId(pipeline.transaction.id);
                let displayName = `${pipeline.transaction.name} - ${pipeline.transaction.description}`
                setTransactionName(displayName);
                setSelectionModel([pipeline.transaction.id]);
            }
        }
    }

    const getTransactionRows = () => {
        if (transactions && transactions.length > 0) {
            return transactions?.map((transaction) => {
                const { id, name, description, direction, ediStandard } = transaction;

                return {
                    _raw: transaction,
                    id,
                    direction,
                    name,
                    description,
                    standard: ediStandard?.name
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    // using DataGrid, define each column
    const transactionColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'name',
                headerName: 'TRANSACTION',
                minWidth: 350,
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const description = row.description;
                    const displayValue = `${value} - ${description}`;
                    const standard = row.standard;
                    return (
                        <GridCellDualVert header={displayValue} sub={standard} boldHeader />
                    )
                }
            },
            {
                field: 'direction',
                headerName: 'DIRECTION',
                minWidth: 60,
                sortable: true,
                headerAlign: 'center',
                align: 'center',
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const formattedValue = capitalize(value);
                    const directionIcon = (value === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;

                    return (
                        <Tooltip title={formattedValue}>
                            {directionIcon}
                        </Tooltip>

                    );
                },
            },

        ], [],
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'pipelines-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error || fetchError) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && !fetchError && transactions?.length === 0 && (
                    <Typography variant="body2" align="center">
                        No Records found.
                    </Typography>
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        if (currentSelectionModel && transactions?.length) {

            let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
            setIsFormDirty(true);
            setTransactionId(rowId ?? '');
            setSelectionModel(currentSelectionModel);

            // for display, find the associated transaction name
            if (rowId) {
                let transaction = transactions.find(t => t.id === rowId);
                if (transaction) {
                    let displayName = `${transaction.name} - ${transaction.description}`
                    setTransactionName(displayName);
                }
            }
            else {
                setTransactionName('');
            }
        }
    };

    const isFormValid = () => {
        if (isFormDirty) {
            let isValid = true;
            if (description?.length <= 0) { 
                isValid = false;
            } 
            // else if (isLegacy === true && transactionId?.length <= 0) {
            //     isValid = false;
            // } 
            return isValid;
        }

        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    // const onIsLegacyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsFormDirty(true);
    //     setIsLegacy(event.target.checked);
    // };

    const submitForm = () => {
        if (isFormValid()) {
            setSubmitted(true);
            // convert GridRowId to string as the transactionId for saving
            //onSave({description, transactionId, id, isLegacy});
            onSave({description, transactionId, id});
        }
    };

    const getLegacyTransactionFields = () => {
        return (
            <>
                <Grid marginTop={'12px'}>
                    <TextField
                        itemID="dialog-pipeline-transaction"
                        fullWidth
                        disabled
                        value={transactionName}
                        label="Selected Transaction"
                        inputProps={{
                            'aria-label': 'transaction',
                        }}
                        autoComplete="off"
                        required
                        data-cy="dialog-pipeline-transaction"
                        variant="standard"
                    />
                </Grid>
                
                <Grid marginTop={2}>
                    <Typography variant="body1">
                        Select a Transaction
                    </Typography>
                </Grid>
                <Grid>
                    <DialogDataGrid
                        loading={transactionRows === undefined || transactionRows.length <= 0}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="Transactions List"
                        hideFooter
                        disableColumnMenu
                        disableMultipleSelection
                        rows={transactionRows ?? []}
                        columns={transactionColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            NoRowsOverlay: noRowsOverlay,
                        }}
                    />
                </Grid>
            </>
        );
    }
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            entityName="Pipeline"
            id={id}
            padding='16px'
            maxWidth='md'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error || fetchError}
        >
            <DialogContentWrapper container direction={"column"}>
                <Grid>
                    <TextField
                        itemID="dialog-pipeline-description"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-pipeline-description"
                        variant="standard"
                    />
                </Grid>
                {
                // <Grid item xs={12}>                    
                //     <FormControlLabel 
                //         control={
                //             <Checkbox 
                //                 checked={isLegacy} 
                //                 onChange={onIsLegacyChange} 
                //             />
                //         } 
                //         label="Is Legacy" 
                //     />
                // </Grid>
                }
                {//isLegacy === true &&
                //     getLegacyTransactionFields()
                }
            </DialogContentWrapper>
        </AddEditDialog>
    );
};

export default PipelineDialog;