import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { CurrencyModel, UpsertCurrencyInput } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";

interface CurrencyDialogProps {
    isOpen: boolean;
    currency?: CurrencyModel | null | undefined;
    onClose: () => void;
    onSave: (upsertCurrencyData: UpsertCurrencyInput) => void;
    error?: Error | undefined;
}

const CurrencyDialog: React.FC<CurrencyDialogProps> = props => {
    const { isOpen, currency, onClose, onSave, error } = props;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [id, setId] = useState<string>(''); 
    const [currencyCode, setCurrencyCode] = useState<string>(''); 
    const [description, setDescription] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    
    useEffect(() => {
        // if have a currency, then populate for Edit
        if (currency){
            setFromCurrency();
        }
        else {
            setToDefaults();
        }
    },[currency]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsFormDirty(false);
        }
        else {
            setFromCurrency();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setId('');
        setCurrencyCode('');
        setDescription('');
    }

    const setFromCurrency = () => {
        if (currency){
            if (currency.currencyCode) {
                // need to set a separate Id field for indication of add vs edit
                setId(currency.currencyCode);
                setCurrencyCode(currency.currencyCode);
            }

            if (currency.description) {
                setDescription(currency.description);
            }
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && currencyCode?.trim().length > 0 && description?.trim().length > 0;
    };
    const submitForm = () => {
        setSubmitted(true);
        onSave({ currencyCode, description });
    };

    // onChange handlers
    const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setCurrencyCode(event.target.value?.toUpperCase());
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const codeProps = {
        'aria-label': 'currency code',
        'maxLength': 3,
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 100,
    };

    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isSubmitted={submitted}
            id={id} 
            entityName="Currency" 
            onClose={onClose} 
            onSave={submitForm} 
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-currency-code"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={currencyCode}
                        label="Currency Code"
                        inputProps={codeProps}
                        onChange={onCodeChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-currency-code"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-currency-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-currency-description"
                        variant="standard"
                    />
                </Grid>
            </Grid>
                
        </AddEditDialog>
    );
};

export default CurrencyDialog;