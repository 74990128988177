import { ReactNode } from "react";
import { styled } from '@mui/material';

interface panelContainerProps {
    minheight?: string;
    children: Element | Element[] | ReactNode;
}

const PanelContainer = styled('div')<panelContainerProps>((props) => ({
    height: '100%',
    minHeight: props.minheight ? props.minheight : '500px',

}));

const PanelContent = styled('div')((props) => ({
    height: '100%',
}));

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    verticalAlign?: boolean;
}

const TabPanel: React.FC<TabPanelProps> = props => {
    const { children, value, index, verticalAlign } = props;
    let calcHeight;
    if (verticalAlign === false) {
        calcHeight = "unset";
    }

    return (
        <PanelContainer
            role="tabpanel"
            minheight={calcHeight}
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
        >
            {value === index && (
                <PanelContent >
                    {children}
                </PanelContent>
            )}
        </PanelContainer>
    );
};

export default TabPanel;