import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AddressModel, ClientContactModel, EmailModel, NoteModel, PhoneModel, RequestResult, UserRole, } from '../../gql-types.generated';

import { convertAddressModelToUpsertAddressInput, convertEmailModelToUpsertEmailInput, convertNoteModelsToUpsertNoteInputs, convertPhoneModelToUpsertPhoneInput } from '../../util/Common';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import { CardListContentGrid, TabCardListBox, TabContainer, TabToolbar } from '../../util/SharedStyles';
import {
    clearError,
    captureUpsertClientContactStatus,
    captureDeleteClientContactStatus,
    captureUpsertClientContactNoteStatus,
    captureDeleteClientContactNoteStatus,
    selectError,
    selectDeleteClientContactStatus,
    selectUpsertClientContactStatus,
    selectUpsertClientContactNoteStatus,
    selectDeleteClientContactNoteStatus,
    selectContactNotesByClientContactId
} from '../../features/ClientDetails/ClientDetailsSlice';
import {
    deleteClientContact,
    upsertClientContact,
    upsertClientContactNotes,
    deleteClientContactNote
} from '../../features/ClientDetails/ClientDetailsActions';
import ContactListItem from '../listItems/ContactListItem';
import DeleteDialog from '../dialogs/DeleteDialog';
import ContactDialog from '../dialogs/ContactDialog';
import NotesDialog from '../dialogs/NotesDialog';
import CreateNewButton from '../buttons/CreateNewButton';
import FilterArray from '../../util/ArrayFilter';
import SearchBar from '../SearchBar';
import NoResultsMessage from '../NoResultsMessage';
import NoRecordsMessage from '../NoRecordsMessage';

interface ClientContactListProps {
    viewerRole: UserRole | undefined;
    clientContacts: ClientContactModel[] | undefined;
    clientId: string;
    refreshClientData: () => void;
}

const ClientContactList: React.FC<ClientContactListProps> = props => {
    const { viewerRole, clientContacts, clientId, refreshClientData } = props;
    const dispatch = useAppDispatch();
    const [selectedContact, setSelectedContact] = useState<ClientContactModel | undefined>(undefined);
    const [isDataRefreshing, setIsDataRefreshing] = useState<boolean>(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openNoteDetails, setOpenNoteDetails] = useState(false);
    const [isContactDialogDisplayOnlyMode, setIsContactDialogDisplayOnlyMode] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [searchText, setSearchText] = useState('');
    const upsertClientContactStatus = useAppSelector(selectUpsertClientContactStatus);
    const deleteClientContactStatus = useAppSelector(selectDeleteClientContactStatus);
    const upsertContactNoteStatus = useAppSelector(selectUpsertClientContactNoteStatus);
    const deleteContactNoteStatus = useAppSelector(selectDeleteClientContactNoteStatus);

    const error = useAppSelector(selectError);

    // because notes save separately on the contact dialog, need to use
    // a selector to have the notesList on the dialog properly refresh with the updates
    const selectedContactNotes = useAppSelector(selectContactNotesByClientContactId(selectedContact?.id));

    useEffect(() => {
        // fetch areas list when a successful mutation occurs
        if (upsertClientContactStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertClientContactStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertClientContactStatus());
            // close the modify dialog if not reverting back to viewMode
            if (!isContactDialogDisplayOnlyMode) {
                onContactDialogClose();
            }
        }
        if (deleteClientContactStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteClientContactStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteClientContactStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteClientContactStatus.message as string);
        }
    }, [upsertClientContactStatus?.result, deleteClientContactStatus?.result]);

    useEffect(() => {
        if (upsertContactNoteStatus?.result === RequestResult.Success || deleteContactNoteStatus?.result === RequestResult.Success) {
            if (upsertContactNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: upsertContactNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            if (deleteContactNoteStatus?.result === RequestResult.Success) {
                dispatch(setToastConfig({
                    message: deleteContactNoteStatus.message as string,
                    severity: ToastSeverity.Success
                }));
            }
            dispatch(captureUpsertClientContactNoteStatus());
            dispatch(captureDeleteClientContactNoteStatus());

            setOpenNoteDetails(false);

            // declare the data refreshing function
            // as async so notes list will shoe as loading
            // up until refresh is complete
            const refreshData = async () => {
                setIsDataRefreshing(true);
                await refreshClientData();
                setIsDataRefreshing(false);
            };

            // call the refresh function
            refreshData();
        }
    }, [upsertContactNoteStatus?.result, deleteContactNoteStatus?.result]);

    const handleClearError = () => {
        dispatch(clearError());
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const clearSearch = () => {
        setSearchText('');
    }

    const onContactDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteClientContactStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and ClientContact on close.
        dispatch(clearError());
        setSelectedContact(undefined);
        setIsContactDialogDisplayOnlyMode(false);
        // Refresh list to bring in potential updates
        refreshClientData();
    };

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            // open the addEditDialog as display only
            setIsContactDialogDisplayOnlyMode(true);
            openEditContactDialog(id);
        }
    };

    const editContact = (id: string) => {
        if (id) {
            // open the addEditDialog for actual edit
            setIsContactDialogDisplayOnlyMode(false);
            openEditContactDialog(id);
        }
    };

    const openEditContactDialog = (id: string) => {
        if (id && clientContacts && clientContacts.length) {
            let contact = clientContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                handleClearError();
                setOpenModify(true);
            }
        }
    }

    const deleteContact = (id: string) => {
        if (id && clientContacts && clientContacts.length) {
            let contact = clientContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                dispatch(captureDeleteClientContactStatus());
                setDeleteErrorMessage('');
                setOpenDelete(true);
            }
        }
    };

    const onContactDialogViewEditCancelClick = () => {
        let currentContact = selectedContact;
        // first clear selected
        setSelectedContact(undefined);

        // wrap in a timeout just to separate the reset from the clear
        // otherwise the clear doesn't trigger the dialog useEffect
        setTimeout(() => {
            // then reset to current to refresh
            setSelectedContact(currentContact);
        });
    };

    const onAddContactClick = () => {
        // make sure we don't pass old contact info
        setSelectedContact(undefined);
        setIsContactDialogDisplayOnlyMode(false);
        setOpenModify(true);
    };

    const addContactNote = (id: string) => {
        if (id && clientContacts && clientContacts.length) {
            let contact = clientContacts.find(contactInfo => contactInfo.id === id);
            if (contact) {
                setSelectedContact(contact);
                dispatch(captureUpsertClientContactNoteStatus());
                handleClearError();
                setOpenNoteDetails(true);
            }
        }
    };

    const onNotesDialogClose = () => {
        setOpenNoteDetails(false);
        clearError();
    };

    const onNotesDialogSave = (text: string, title: string, noteId?: string | undefined) => {
        onSaveContactNote({ header: title, note: text, parentId: selectedContact?.id } as NoteModel);
    };

    const onSaveContactNote = (note: NoteModel) => {
        if (note && note.parentId && clientId) {
            let noteInputs = convertNoteModelsToUpsertNoteInputs([note]);
            let id = note.parentId;
            dispatch(upsertClientContactNotes(
                clientId,
                id,
                noteInputs
            ));
        }
    };

    const onDeleteContactNote = (id: string) => {
        if (id) {
            dispatch(deleteClientContactNote(id));
        }
    };

    const onContactDialogSave = (
        address?: AddressModel,
        description?: string,
        email?: EmailModel,
        id?: string,
        isActive?: boolean,
        isPrimary?: boolean,
        jobTitle?: string,
        name?: string,
        phone?: PhoneModel,
        notes?: NoteModel[],
    ) => {
        let addressInput = convertAddressModelToUpsertAddressInput(address);
        const addresses = addressInput ? [addressInput] : undefined;
        let emailInput = convertEmailModelToUpsertEmailInput(email);
        const emails = emailInput ? [emailInput] : undefined;
        let phoneInput = convertPhoneModelToUpsertPhoneInput(phone);
        const phones = phoneInput ? [phoneInput] : undefined;
        let noteInputs = convertNoteModelsToUpsertNoteInputs(notes);

        dispatch(upsertClientContact(
            clientId,
            addresses,
            description,
            emails,
            id,
            isActive,
            isPrimary,
            jobTitle,
            name,
            phones,
            noteInputs
        ));
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected business area
        dispatch(deleteClientContact(selectedContact?.id as string));
    };

    const canEdit = viewerCanEdit(viewerRole);

    const getContent = () => {
        if (clientContacts && clientContacts.length > 0) {
            const filteredClientContacts = FilterArray(clientContacts, searchText, ['name']);
            if (filteredClientContacts && filteredClientContacts.length) {
                return (
                    filteredClientContacts.map((clientContact: ClientContactModel) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={clientContact.id}>
                            <ContactListItem
                                viewerRole={viewerRole}
                                contact={clientContact}
                                deleteContact={deleteContact}
                                editContact={editContact}
                                addContactNote={addContactNote}
                                clickAction={cardClickAction}
                            />
                        </Grid>
                    ))
                );
            } else {
                // Filtered too hard
                // Display no results found image/message
                let message = `No Contacts found match '${searchText}'. Remove or modify the keyword search to show results.`;
                return (<NoResultsMessage topMargin={2} message={message} />);
            }

        } else {
            let noRecords = (canEdit ?
                <NoRecordsMessage topMargin={2} actionButtonText="Add New Contact" actionButtonClick={onAddContactClick} />
                :
                <NoRecordsMessage topMargin={2} message="" />
            );
            return noRecords;
        }
    };

    const displaySearchBar = (clientContacts && clientContacts.length > 0);
    return (
        <TabContainer>
            <TabToolbar justify={displaySearchBar ? "space-between" : "flex-end"}>
                {displaySearchBar &&
                    <SearchBar
                        searchText={searchText}
                        onSearch={handleSearch}
                        onClearSearch={clearSearch}
                    />
                }
                {canEdit &&
                    <CreateNewButton
                        text="New Contact"
                        onClick={onAddContactClick}
                        data-cy="add-new-contact"
                    />}
            </TabToolbar>
            <TabCardListBox>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </TabCardListBox>

            <ContactDialog
                isOpen={openModify}
                contactInfo={selectedContact}
                contactNotes={selectedContactNotes}
                isReadOnly={isContactDialogDisplayOnlyMode}
                isRefreshing={isDataRefreshing}
                viewerRole={viewerRole}
                onClose={onContactDialogClose}
                onCancel={onContactDialogViewEditCancelClick}
                onSave={onContactDialogSave}
                saveResult={upsertClientContactStatus?.result}
                error={error}
                clearError={handleClearError}
                onSaveNote={onSaveContactNote}
                onDeleteNote={onDeleteContactNote}
                saveNoteResult={upsertContactNoteStatus?.result}
                deleteNoteStatus={deleteContactNoteStatus}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedContact?.id ?? ''}
                heading={'Delete Contact'}
                message={'Are you sure you want to delete \'' + selectedContact?.name + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
            <NotesDialog
                isOpen={openNoteDetails}
                note={undefined}
                viewerRole={viewerRole}
                onCancel={onNotesDialogClose}
                onClose={onNotesDialogClose}
                onSave={onNotesDialogSave}
                error={error}
            />
        </TabContainer>
    );
}

export default ClientContactList;