import { ChangeEvent, useEffect, useState } from 'react';
import { Grid, TextField } from "@mui/material";
import { PricingItemModel } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";

interface DialogProps {
    isOpen: boolean;
    currencyCode: string;
    pricingItem?: PricingItemModel | null | undefined;
    findExistingMaxTransaction: (maxNumTrans: number) => boolean;
    onClose: () => void;
    onSave: (maxNumberOfTransactions: number, feePerDocument: number, feePerLine: number, id?: string) => void;
    error?: Error | undefined;
}

const PricingItemDialog: React.FC<DialogProps> = props => {
    const { isOpen, currencyCode, pricingItem, findExistingMaxTransaction, onClose, onSave, error } = props;
    const id = pricingItem?.id;

    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [maxNumTransactions, setMaxNumTransactions] = useState<number | undefined>(undefined);
    const [maxTransInvalid, setMaxTransInvalid] = useState(false);
    const [maxTransInvalidMessage, setMaxTransInvalidMessage] = useState<string>('');
    const [feePerDocument, setFeePerDocument] = useState<number | undefined>(undefined);
    const [feeDocInvalid, setFeeDocInvalid] = useState(false);
    const [feePerLine, setFeePerLine] = useState<number | undefined>(undefined);
    const [feeLineInvalid, setFeeLineInvalid] = useState(false);
    
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    
    const setPricingItemState = () => {
        // if have a pricingItem, then populate for Edit
        if (pricingItem) {
            if (pricingItem.maxNumberOfTransactions) {
                setMaxNumTransactions(pricingItem.maxNumberOfTransactions);
            }
            if (pricingItem.feePerDocument) {
                setFeePerDocument(pricingItem.feePerDocument);
            }
            if (pricingItem.feePerLine) {
                setFeePerLine(pricingItem.feePerLine);
            }
        }
        else {
            resetInitialState();
        }
    };

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (isOpen) {
            setPricingItemState();
        } else {
            resetInitialState();
        }
    }, [isOpen]);

    const resetInitialState = () => {
        setSubmitted(false);
        setIsFormDirty(false);
        setMaxNumTransactions(undefined);
        setFeePerDocument(undefined);
        setFeePerLine(undefined);
        setFeeDocInvalid(false);
        setFeeLineInvalid(false);
        setMaxTransInvalid(false);
        setMaxTransInvalidMessage('');
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        let requiredCheck = maxNumTransactions !== undefined && feePerDocument !== undefined && feePerLine !== undefined;
        return isFormDirty && requiredCheck && !maxTransInvalid && !feeDocInvalid && !feeLineInvalid;
    };

    const submitForm = () => {
        setSubmitted(true);
        onSave(maxNumTransactions ?? 0, feePerDocument ?? 0, feePerLine ?? 0, id);
    };
   
    const isNumericFieldInvalid = (targetValue: number, targetValueLength: number, minValue: number, maxValue: number, maxLength: number) => {
        // if value not in valid range, set invalid
        if (targetValue < minValue || targetValue > maxValue) {
            return true
        } // else if value is in range, but length greater than maxLength (too many decimal places), set invalid
        else if (targetValueLength > maxLength) {
            return true;
        }
        else {
            // value is valid
            return false;
        }
    };

    // onChange handlers
    const onMaxNumTransactionsChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value?.replace(/\D/g, ''));
        
        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {
            let isInvalid = isNumericFieldInvalid(targetValue, targetValue.toString().length, minValue, maxValue, event.target.maxLength);
            setMaxTransInvalid(isInvalid);

            if (isInvalid) {
                setMaxTransInvalidMessage('Value must be greater than 0');
            } else {
                // check if number entered already has been entered
                let maxAlreadyExists = findExistingMaxTransaction(targetValue);
                setMaxTransInvalid(maxAlreadyExists);
                setMaxTransInvalidMessage(maxAlreadyExists ? 'Max Transaction Value already entered' : '');
            }
            
            setIsFormDirty(true);
            setMaxNumTransactions(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as invalid
            // and undefined to force user to enter a valid number
            setMaxTransInvalid(true);
            setMaxTransInvalidMessage('Value must be a number greater than 0');
            setMaxNumTransactions(undefined);
        }
        else {
            // value entered is not a number so disallow it by wiping out value
            setMaxNumTransactions(undefined);
        }
    };

    const onFeePerDocumentChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value);

        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {
            let isInvalid = isNumericFieldInvalid(targetValue, event.target.value.length, minValue, maxValue, event.target.maxLength);
            setFeeDocInvalid(isInvalid);
            
            setIsFormDirty(true);
            setFeePerDocument(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as invalid
            // and undefined to force user to enter a valid number
            setFeeDocInvalid(true);
            setFeePerDocument(undefined);
        }
    };

    const onFeePerLineChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value);

        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {
            let isInvalid = isNumericFieldInvalid(targetValue, event.target.value.length, minValue, maxValue, event.target.maxLength);
            setFeeLineInvalid(isInvalid);
            
            setIsFormDirty(true);
            setFeePerLine(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as invalid
            // and undefined to force user to enter a valid number
            setFeeLineInvalid(true);
            setFeePerLine(undefined);
        }
    };

    const feePerDocProps = {
        'aria-label': 'fee-per-document',
        'maxLength': 7,
        'min': '0',
        'max': '999.999',
        'step': '0.001',
        'precision': 3
    };

    const feePerLineProps = {
        'aria-label': 'fee-per-line',
        'maxLength': 7,
        'min': '0',
        'max': '999.999',
        'step': '0.001',
        'precision': 3
    };

    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isSubmitted={submitted}
            id={id} 
            entityName={`Price ${currencyCode ? '(' + currencyCode + ')' : ''}`}
            onClose={onClose} 
            onSave={submitForm} 
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-pricing-item-max-trans"
                        fullWidth
                        disabled={submitted}
                        value={maxNumTransactions ?? ''}
                        label="Maximum Number Transactions"
                        inputProps={{ 
                                        inputMode: 'numeric', 
                                        maxLength: 6,
                                        min: '1',
                                        max: '999999',
                                        'aria-label': 'max-number-transactions'
                                    }}
                        onChange={onMaxNumTransactionsChange}
                        autoComplete="off"
                        autoFocus
                        required
                        data-cy="dialog-pricing-item-max-transactions"
                        variant="standard"
                        error={maxTransInvalid}
                        helperText={maxTransInvalidMessage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        itemID="dialog-pricing-item-fee-per-document"
                        fullWidth
                        disabled={submitted}
                        value={feePerDocument}
                        label="Fee Per Document"
                        inputProps={feePerDocProps}
                        onChange={onFeePerDocumentChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-pricing-item-fee-per-document"
                        variant="standard"
                        error={feeDocInvalid}
                        helperText={feeDocInvalid ? "Valid value 0 to 999.999" : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        itemID="dialog-pricing-item-fee-per-line"
                        fullWidth
                        disabled={submitted}
                        value={feePerLine}
                        label="Fee Per Line"
                        inputProps={feePerLineProps}
                        onChange={onFeePerLineChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-pricing-item-fee-per-line"
                        variant="standard"
                        error={feeLineInvalid}
                        helperText={feeLineInvalid ? "Valid value 0 to 999.999" : ""}
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default PricingItemDialog;