import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Button, Grid, TextField, styled } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { setToastConfig } from "../../features/EDIContainer/EDIContainerSlice";
import { clearXPathError, captureDeleteTransactionXPathStatus, selectDeleteTransactionXPathStatus } from '../../features/Transactions/TransactionsSlice';
import { deleteTransactionXPath } from "../../features/Transactions/TransactionsActions";

import { UserRole, RequestResult, UpsertTransactionXPathInput } from "../../gql-types.generated";

import { ToastSeverity } from "../../util/Constants";
import AddEditDialog from "./AddEditDialog";
import DeleteDialog from "./DeleteDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '440px', 
}));

interface XPathDialogProps {
    isOpen: boolean;
    parentId: string;
    xPathJson?: string;
    transactionXPathId?: string;
    viewerRole?: UserRole | undefined;
    onClose: () => void;
    onSave: (upsertXPathData: UpsertTransactionXPathInput) => void;
    refreshTransactionData: () => void;
    error?: Error | undefined;
}

const TransactionXPathDialog: React.FC<XPathDialogProps> = props => {
    const { isOpen, parentId, transactionXPathId, xPathJson, viewerRole, onClose, onSave, refreshTransactionData, error } = props;
    const dispatch = useAppDispatch();
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [xPath, setXPath] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    
    const deleteTransactionXPathStatus = useAppSelector(selectDeleteTransactionXPathStatus);

    useEffect(() => {
        if (xPathJson) {
            setFromXPathJson();
        } else {
            setDefaults();
        }
    }, [xPathJson]);

    useEffect(() => {
        if (!isOpen) {
            setDefaults();
        } else {
            setFromXPathJson();
        }
    }, [isOpen]);

    useEffect(() => {
        if (deleteTransactionXPathStatus?.result === RequestResult.Success) {
            //refresh data to bring in deletion
            refreshTransactionData();
            
            // close the delete dialog and this dialog
            onDeleteDialogClose();
            onClose();

            dispatch(setToastConfig({
                message: deleteTransactionXPathStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteTransactionXPathStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteTransactionXPathStatus.message as string);
        }
    }, [deleteTransactionXPathStatus?.result]);

    const setFromXPathJson = () => {
        if (xPathJson) {
            setXPath(xPathJson);
        }
    };

    const setDefaults = () => {
        dispatch(clearXPathError());
        setIsDirty(false);
        setSubmitted(false);
        setXPath('');
    };

    const saveEntityXPath = () => {
        if (isDirty) {
            setSubmitted(true);
            onSave({
                xPathJson: xPath,
                transactionId: parentId,
                id: transactionXPathId
            });
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const onDeleteClick = () => {
        setOpenDelete(true);
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        dispatch(clearXPathError());
        dispatch(captureDeleteTransactionXPathStatus());
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete this xpath
        dispatch(deleteTransactionXPath(id));
    }

    const isFormValid = () => {
        let requiredCheck = xPath?.trim().length > 0;

        return isDirty && requiredCheck;
    };

    
    const onXPathChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        setXPath(event.target.value);
    };
    
    const xpathProps = {
        'aria-label': 'xpath',
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            titlePrefix={transactionXPathId ? "Edit" : "Add"}
            entityName={`Document Search Helper`}
            id={transactionXPathId}
            viewerRole={viewerRole}
            onCancel={onClose}
            onClose={onClose}
            onSave={saveEntityXPath}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='lg'
        >
            <GridContainer container spacing={2}>
                {transactionXPathId && 
                <Grid container item xs={12} justifyContent={"flex-end"}>
                    <Grid item>
                        <Button
                            startIcon={<DeleteIcon />}
                            variant="text"
                            onClick={onDeleteClick}
                            color="error"
                            disabled={submitted}
                            data-cy="dialog-trans-xpath-delete"
                        >Delete</Button>
                    </Grid>
                </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-trans-xpath-json"
                        fullWidth
                        multiline
                        maxRows={10}
                        autoFocus
                        disabled={submitted}
                        value={xPath}
                        label="Helper (JSON format)"
                        inputProps={xpathProps}
                        onChange={onXPathChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-trans-xpath-json"
                        variant="standard"
                    />
                </Grid>
            </GridContainer>
            <DeleteDialog
                isOpen={openDelete}
                id={transactionXPathId ?? ''}
                heading={'Delete Transaction Document Search Helper'}
                message={'Are you sure you want to delete this helper?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </AddEditDialog>
    );
}

export default TransactionXPathDialog;