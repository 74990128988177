import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import gql from "graphql-tag";
import { CounterListQuery, GetCounterInput, PagingResultModelOfCounterModel } from "../gql-types.generated";



// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_COUNTER_LIST = gql`
    query counterList($input: GetCounterInput!) {
        counters(input: $input) {
            cursor {
                nextPage
                previousPage
            }
            nodes {
                id
                segment
                senderId
                controlNumber
                comments
                machine
            }
        }
    }
`;

export const queryCounterList = async(
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetCounterInput
): Promise<PagingResultModelOfCounterModel | undefined> => {
    const {after, limit, segment, senderId, controlNumber, machine, order} = filters ?? {};
    const { data, errors } = await client.query<CounterListQuery>({
        variables: {
            input: {
                after, 
                limit, 
                segment: segment ?? undefined, 
                senderId: senderId?.trim(),
                controlNumber: controlNumber?.trim(), 
                machine: machine?.trim(), 
                order
            }
        },
        query: QUERY_COUNTER_LIST
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.counters as PagingResultModelOfCounterModel;
}