import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { Scalars, PagingResultModelOfPricingModel, PricingsListQuery, GetPricingsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PRICING_BY_ID = gql`
    query getPricingById($input: GetPricingsInput!) {
        pricings (input: $input) {
            nodes {
                description
                id
                minServiceFees
                currencyCode
                billingItemCount
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                pricingItems
                {
                    id
                    description
                    feePerDocument
                    feePerLine
                    maxNumberOfTransactions
                    pricingId
                }
            }
        }
    }
`;

export const queryPricingById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<PagingResultModelOfPricingModel | null | undefined> => {
    
    const { data, errors } = await client.query<PricingsListQuery>({
        query: QUERY_PRICING_BY_ID,
        variables: { 
          input: {
            id,
          }  
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.pricings as PagingResultModelOfPricingModel;
};