import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import { PartnerModel, PagingResultModelOfPartnerModel, UpsertPartnerPayload } from '../../gql-types.generated';

interface SliceState {
    error?: Error;
    partnerPagingResult?: PagingResultModelOfPartnerModel;
    partnerList?: PartnerModel[];
    partnerNameSearchList?: string[];
    partnerRequestsInFlight: number;
    upsertPartnerStatus?: UpsertPartnerPayload;
}

const initialState: SliceState = {
    error: undefined,
    partnerPagingResult: undefined,
    partnerList: undefined,
    partnerNameSearchList: undefined,
    partnerRequestsInFlight: 0,
    upsertPartnerStatus: undefined,
};

export const slice = createSlice({
    name: 'tradingPartners',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerNameSearchList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerRequestsInFlight = 0;
        },

        fetchTradingPartnersListSuccess: (state, action: PayloadAction<PagingResultModelOfPartnerModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.partnerPagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let partners = state.partnerList;
            if (!partners || !state.partnerPagingResult.cursor?.previousPage) {
                partners = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!partners?.some(i => i.id === node?.id)) {
                        partners?.push(node as PartnerModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.partnerList = partners;
        },

        fetchTradingPartnerNameSearchResultsSuccess: (state, action: PayloadAction<PagingResultModelOfPartnerModel | undefined>) => {
            let pagingResult = action.payload;
            let partnerNames: string[] = [];
            
            if (pagingResult?.nodes) {
                pagingResult.nodes.forEach(node => {
                    if (node.name) {
                        partnerNames.push(node.name);
                    }
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.partnerNameSearchList = partnerNames;
        },

        captureUpsertTradingPartnerStatus: (state, action: PayloadAction<UpsertPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPartnerStatus = action.payload;
        },

        incrementTradingPartnerRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.partnerRequestsInFlight += 1;
        },

        decrementTradingPartnerRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.partnerRequestsInFlight > 0) state.partnerRequestsInFlight -= 1;
        }
    },
});

export const selectError = (state: RootState): Error | undefined => state.tradingPartners.error;
export const selectTradingPartnersConnection = (state: RootState): PagingResultModelOfPartnerModel | undefined => state.tradingPartners.partnerPagingResult;
export const selectTradingPartnersList = (state: RootState): PartnerModel[] | undefined => state.tradingPartners.partnerList;
export const selectTradingPartnerNameSearchResults = (state: RootState): string[] | undefined => state.tradingPartners.partnerNameSearchList;
export const selectUpsertTradingPartnerStatus = (state: RootState): UpsertPartnerPayload | undefined => state.tradingPartners.upsertPartnerStatus;
export const selectRequestsInFlight = (state: RootState): number => state.tradingPartners.partnerRequestsInFlight;
export const selectNetworkBusy = (state: RootState): boolean => state.tradingPartners.partnerRequestsInFlight > 0;

export const {
    clearError,
    clearState,
    fetchError,
    fetchTradingPartnersListSuccess,
    fetchTradingPartnerNameSearchResultsSuccess,
    captureUpsertTradingPartnerStatus,
    incrementTradingPartnerRequestsInFlight,
    decrementTradingPartnerRequestsInFlight
} = slice.actions;

export default slice.reducer;