import { Grid, ListItemText, styled } from '@mui/material';

import { HSCPrimaryTextNoWrap } from '../../util/SharedStyles';

const SecondaryText = styled('p')((props) => ({
    // copied from secondary p created by ListItemText
    margin: 0,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    color: 'rgba(0,0,0,0.6)',
    display: 'block',
}));
const SecondaryTextRight = styled(SecondaryText)((props) => ({
    flex: '0 0 auto',
    textAlign: 'end',
}));

interface ListItemTextDoubleSecondaryProps {
    primaryText: string;
    secondaryLeft: string;
    secondaryRight: string;
}

const ListItemTextDoubleSecondary: React.FC<ListItemTextDoubleSecondaryProps> = (props: ListItemTextDoubleSecondaryProps) => {
    const { primaryText, secondaryLeft, secondaryRight } = props;
    const getRecentsSecondaryListItem = (leftText: string, rightText: string) => {
        return (
            <Grid container >
                <Grid item xs={6}>
                    <SecondaryText>{leftText}</SecondaryText>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryTextRight>{rightText}</SecondaryTextRight>
                </Grid>
            </Grid>
        )
    }
    return (
        <ListItemText primary={<HSCPrimaryTextNoWrap>{primaryText}</HSCPrimaryTextNoWrap>} secondary={getRecentsSecondaryListItem(secondaryLeft, secondaryRight)} disableTypography={true} />

    );
}

export default ListItemTextDoubleSecondary;