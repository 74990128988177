import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { EntityType, UpsertFavoritePayload, UpsertFavoriteInput } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_FAVORITE = gql`
    mutation upsertFavorite($input: UpsertFavoriteInput!) {
        upsertFavorite(input: $input) {
            message
            errors {
                code
                message
            }
            result
            favorite {
                id
            }
        }
    }
`;

export const mutationUpsertFavorite = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        entityType: EntityType;
        entityId: string;
        isFavorite: boolean;
        name: string;
        id?: string;
    }
): Promise<UpsertFavoritePayload | undefined> => {
    const { entityId, entityType, id, isFavorite, name } = options as UpsertFavoriteInput;
    const { data, errors } = await client.mutate<{ upsertFavorite: UpsertFavoritePayload }>({
        variables: { 
            input: { 
                entityId,
                entityType, 
                id, 
                isFavorite, 
                name 
            } 
        },
        mutation: MUTATION_UPSERT_FAVORITE
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the person.
        throw new Error(`An error has occurred during the toggle of '${name}' as a favorite.`);
    }
    if (data && data.upsertFavorite) {
        return data.upsertFavorite;
    }

    return undefined;
};