import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { GetConfigurationsInput, ConfigurationModel, RequestResult } from '../../gql-types.generated';
import { queryProcessList } from "../../gql/QueryProcessList";
import { captureDeleteProcessStatus, captureUpsertProcessStatus, decrementProcessRequestsInFlight, fetchError, fetchProcessListSuccess, incrementProcessRequestsInFlight } from './ProcessesSlice';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpsertProcess } from '../../gql/MutationUpsertProcess';

export const fetchProcessList = (
    filters?: GetConfigurationsInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementProcessRequestsInFlight());
    try {
        const processList = await queryProcessList(gqlClient, filters);
        if (processList) {
            dispatch(fetchProcessListSuccess(processList as ConfigurationModel[]))
        }
        dispatch(decrementProcessRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementProcessRequestsInFlight());
    }
};

export const upsertProcess = (
    destPath: string,
    ediProcess: string,
    executionMode: string,
    extension: string,
    tpgClient: string,
    watcherPath: string,
    description?: string,
    id?: string,
    p1?: string,
    p2?: string,
    p3?: string,
    p4?: string,
    p5?: string,
    p6?: string,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertProcessStatus = await mutationUpsertProcess(gqlClient, {
                destPath,
                ediProcess,
                executionMode,
                extension,
                tpgClient,
                watcherPath,
                description,
                id,
                p1,
                p2,
                p3,
                p4,
                p5,
                p6,
            });
            if (upsertProcessStatus) {
                if (upsertProcessStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertProcessStatus.message } as Error));
                    getErrorMessage(upsertProcessStatus.message as string, upsertProcessStatus.errors, true, true);
                }
                dispatch(captureUpsertProcessStatus(upsertProcessStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteProcess = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteProcessStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Configuration"
            });
            if (deleteProcessStatus) {
                if (deleteProcessStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteProcessStatus.message as string, deleteProcessStatus.errors, true, true);
                }
                dispatch(captureDeleteProcessStatus(deleteProcessStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    }