import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PipelineModel, DeleteByIdPayload, UpsertPipelinePayload, ClonePipelinePayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    pipelineList?: PipelineModel[];
    upsertPipelineStatus?: UpsertPipelinePayload;
    deletePipelineStatus?: DeleteByIdPayload;
    clonePipelineStatus?: ClonePipelinePayload;
}

const initialState: SliceState = {
    error: undefined,
    pipelineList: undefined,
    upsertPipelineStatus: undefined,
    deletePipelineStatus: undefined,
    clonePipelineStatus: undefined,
};

export const slice = createSlice({
    name: 'Pipelines',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        fetchPipelineListSuccess: (state, action: PayloadAction<PipelineModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.pipelineList = action.payload;
        },

        captureUpsertPipelineStatus: (state, action: PayloadAction<UpsertPipelinePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPipelineStatus = action.payload;
        },

        captureDeletePipelineStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePipelineStatus = action.payload;
        },

        captureClonePipelineStatus: (state, action: PayloadAction<ClonePipelinePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.clonePipelineStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.pipelines.error;
export const selectPipelineList = (state: RootState): PipelineModel[] | undefined => state.pipelines.pipelineList;
export const selectUpsertPipelineStatus = (state: RootState): UpsertPipelinePayload | undefined => state.pipelines.upsertPipelineStatus;
export const selectDeletePipelineStatus = (state: RootState): DeleteByIdPayload | undefined => state.pipelines.deletePipelineStatus;
export const selectClonePipelineStatus = (state: RootState): ClonePipelinePayload | undefined => state.pipelines.clonePipelineStatus;

export const {
    clearError,
    fetchError,
    fetchPipelineListSuccess,
    captureUpsertPipelineStatus,
    captureDeletePipelineStatus,
    captureClonePipelineStatus
} = slice.actions;

export default slice.reducer;