import { MouseEvent } from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Alert, AlertTitle, Grid, Typography, Breakpoint, List, ListItemButton, ListItem  } from "@mui/material";
import { OffsetDialog, OffsetDialogStyledTypography, OffsetDialogTitleBar, OffsetDialogTitleGrid, PaddedDialogContent } from '../../util/SharedStyles';
import DialogCloseButton from "./DialogCloseButton";
import { ClientModel } from '../../gql-types.generated';
import { getTimeSinceString } from '../../util/TimeSince';
import ListItemTextDoubleSecondary from "../dashboard/ListItemTextDoubleSecondary";

interface OnboardingDialogProps {
    isOpen: boolean;
    clientsOnboarding: ClientModel[];
    onClose: () => void;
    maxWidth?: Breakpoint | undefined;
    height?: string;
    padding?: string;
    offsetTop?: number;
    offsetLeft?: number;
    error?: ApolloError | undefined;
}

const OnboardingDialog: React.FC<OnboardingDialogProps> = props => {
    const { isOpen, clientsOnboarding, onClose, maxWidth, padding = "10px", height = "100%", offsetTop, offsetLeft, error } = props;
    
    const maxW = maxWidth || 'xs';
    
    const handleListItemClick = (event: MouseEvent<HTMLElement>) => {
        // because this dialog is opened from within a card with an action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();
    }

    const getOnboardingListItems = () => {
        if (clientsOnboarding) {
            return (
                clientsOnboarding?.map((client: ClientModel, index) => {
                    const now = new Date();
                    const url = '/client/' + client.id;
                    
                    // Leave for now in case they decide they want to see exact timestamp
                    //const timeString = getLastModifiedString(null, client.lastModifiedTime);
                    let timeSince = '';
                    if (client.lastModifiedTime) {
                        timeSince = getTimeSinceString(client.lastModifiedTime, now);
                    }
    
                    return (
                        <ListItem disableGutters disablePadding key={client.id}>
                            <ListItemButton id={client.id} component="a" href={url} onClick={handleListItemClick}>
                                <ListItemTextDoubleSecondary
                                    primaryText={client.name as string}
                                    secondaryLeft={client.lastModifiedByName as string}
                                    secondaryRight={timeSince}
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                })
            );
        } else if (error) {
            return (
                <Alert severity="error">
                    <AlertTitle>Unable to Load Onboarding Clients</AlertTitle>
                    <OffsetDialogStyledTypography>{error.message}</OffsetDialogStyledTypography>
                </Alert>
            );
        }
        
    };
    
    return (
        <OffsetDialog
            aria-label={'onboarding list'}
            maxWidth={maxW}
            open={isOpen}
            scroll="paper"
            top={offsetTop}
            left={offsetLeft}
            height={height}
        >
            <OffsetDialogTitleBar id='dialog-title'>
                <OffsetDialogTitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="h5">Onboarding</Typography>
                </OffsetDialogTitleGrid>
                <DialogCloseButton onClick={onClose}></DialogCloseButton>
            </OffsetDialogTitleBar>
            <PaddedDialogContent dividers padding={padding} minwidth="400px">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <List dense>
                            {getOnboardingListItems()}
                        </List>
                    </Grid>
                </Grid>
            </PaddedDialogContent>
        </OffsetDialog>
    );
};

export default OnboardingDialog;