import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { DateTime } from 'luxon';
import { getErrorMessage } from '../../util/Common';
import { PagingResultModelOfBillingModel, RequestResult, PagingResultModelOfBillingItemModel } from '../../gql-types.generated';
import { queryBillingById } from '../../gql/QueryBillingById';
import { queryBillingItemList } from '../../gql/QueryBillingItemsList';
import { captureUpsertBillingStatus, captureDeleteBillingStatus, captureUpsertBillingItemStatus, captureDeleteBillingItemStatus, fetchBillingByIdSuccess, fetchBillingItemsByBillingIdSuccess, fetchError } from './BillingDetailsSlice';
import { mutationUpsertBilling } from '../../gql/MutationUpsertBilling';
import { mutationUpsertBillingItem } from '../../gql/MutationUpsertBillingItem';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';

export const fetchBillingById =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const billing = await queryBillingById(gqlClient, id);
                if (billing) {
                    dispatch(fetchBillingByIdSuccess(billing as PagingResultModelOfBillingModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const deleteBilling = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteBillingStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Billing"
            });
            if (deleteBillingStatus) {
                dispatch(captureDeleteBillingStatus(deleteBillingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const upsertBilling = (currencyCode: string, description?: string, id?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertBillingStatus = await mutationUpsertBilling(gqlClient, {
                currencyCode,
                description,
                id,
            });
            if (upsertBillingStatus) {
                if (upsertBillingStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertBillingStatus.message } as Error));
                    getErrorMessage(upsertBillingStatus.message as string, upsertBillingStatus.errors, true, true);
                }
                dispatch(captureUpsertBillingStatus(upsertBillingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchBillingItems =
    (billingId: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const groups = await queryBillingItemList(gqlClient, { billingId });
                if (groups) {
                    dispatch(fetchBillingItemsByBillingIdSuccess(groups as PagingResultModelOfBillingItemModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const upsertBillingItem = (
    billingId: string,
    dateFrom: DateTime,
    dateTo: DateTime,
    pricingId: string,
    id?: string,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertBillingItem(gqlClient, {
                billingId,
                dateFrom,
                dateTo,
                pricingId,
                id,
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertBillingItemStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteBillingItem = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteBillingItemStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "BillingItem"
            });
            if (deleteBillingItemStatus) {
                if (deleteBillingItemStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteBillingItemStatus.message as string, deleteBillingItemStatus.errors, true, true);
                }
                dispatch(captureDeleteBillingItemStatus(deleteBillingItemStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };



