import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertConfigurationInput, UpsertConfigurationPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PROCESS = gql`
    mutation upsertProcess($input: UpsertConfigurationInput!){
        upsertConfiguration(input: $input){
            message
            errors {
                code
                message
            }
            result
            configuration{
                id
            }
        }
    }
`;

export const mutationUpsertProcess = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        destPath: string;
        ediProcess: string;
        executionMode: string;
        extension: string;
        tpgClient: string;
        watcherPath: string;
        description?: string;
        id?: string;
        p1?: string;
        p2?: string;
        p3?: string;
        p4?: string;
        p5?: string;
        p6?: string;
    }
): Promise<UpsertConfigurationPayload | undefined> => {
    const { destPath, ediProcess, executionMode, extension, tpgClient, watcherPath, description, id, p1, p2, p3, p4, p5, p6 } = options as UpsertConfigurationInput;
    const { data, errors } = await client.mutate<{ upsertConfiguration: UpsertConfigurationPayload }>({
        variables: {
            input: {
                destPath,
                ediProcess,
                executionMode,
                extension,
                tpgClient,
                watcherPath,
                description,
                id,
                p1,
                p2,
                p3,
                p4,
                p5,
                p6,
            }
        },
        mutation: MUTATION_UPSERT_PROCESS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the process save.');
    }
    if (data && data.upsertConfiguration) {
        return data.upsertConfiguration;
    }

    return undefined;
};