import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteClientContactPayload } from '../gql-types.generated';

export const MUTATION_DELETE_CLIENT_CONTACT = gql`
    mutation deleteClientContact($input: DeleteClientContactInput!){
        deleteClientContact(input: $input){
            message
            result
            errors {
                code
                message
            }
            id
        }
    }
`;

export const mutationDeleteClientContact = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: string;
    }
): Promise<DeleteClientContactPayload | undefined> => {
    const { id } = options;
    const { data, errors } = await client.mutate<{ deleteClientContact: DeleteClientContactPayload }>({
        variables: {
            input: {
                id: id
            }
        },
        mutation: MUTATION_DELETE_CLIENT_CONTACT
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error('An error has occurred deleting the Client Contact.');
    }
    if (data && data.deleteClientContact) {
        return data.deleteClientContact;
    }

    return undefined;
};