import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { styled, Dialog, DialogTitle, Grid, Typography } from "@mui/material";
import ConfirmationPrompt from "../ConfirmationPrompt";
import { completeOnboarding } from '../../features/ClientDetails/ClientDetailsActions';
import { selectError, selectOnboardingCompleteStatus, captureOnboardingCompleteStatus } from '../../features/ClientDetails/ClientDetailsSlice';
import { PaddedDialogContent } from '../../util/SharedStyles';
import DialogCloseButton from "./DialogCloseButton";

const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));

const TypographyWithReturns = styled(Typography)((props) => ({
    whiteSpace: 'pre-line',
}));


interface CompleteOnboardingDialogProps {
    isOpen: boolean;
    tenantId: string;
    onClose: () => void;
}

const CompleteOnboardingDialog: React.FC<CompleteOnboardingDialogProps> = props => {
    const { isOpen, tenantId, onClose } = props;
    const dispatch = useAppDispatch();

    const [isCompleting, setIsCompleting] = useState(false);
    const [completionResultMessage, setCompletionResultMessage] = useState('');
    
    const error = useAppSelector(selectError);
    const completionStatus = useAppSelector(selectOnboardingCompleteStatus);

    useEffect(() => {
        if (error) {
            setIsCompleting(false);
            return;
        }
    }, [error]);

    useEffect(() => {
        if (completionStatus?.result) {
            // for now, close the dialog after completion
            dispatch(captureOnboardingCompleteStatus());
            handleClose();
            // setIsCompleting(false);
            
            // if (completionStatus.message) {
            //     setCompletionResultMessage(completionStatus.message);
            // }
           
            //dispatch(captureOnboardingCompleteStatus());
        }
    }, [completionStatus?.result]);

    const onCompleteClick = () => {
        setIsCompleting(true);
        dispatch(completeOnboarding(tenantId));
    }

    const handleClose = () => {
        onClose();
        setIsCompleting(false);
        setCompletionResultMessage('');
    };

    const title = "Notify Onboarding Complete";
    
    return (
        <Dialog
            open={isOpen}
            aria-label={title}
            scroll="paper"
        >
            <TitleBar id='dialog-title'>
                <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="body1">{title}</Typography>
                </TitleGrid>
                <DialogCloseButton onClick={handleClose}></DialogCloseButton>
            </TitleBar>
            {!completionResultMessage &&
            <ConfirmationPrompt
                heading=""
                message={`Are you sure you want to notify Aptean Central\n that onboarding is complete?`}
                confirmButtonText={'Notify'}
                rejectButtonText={'Cancel'}
                handleConfirm={onCompleteClick}
                handleReject={handleClose}
                isBusy={isCompleting}
                errorMessage={error?.message ?? undefined}
            />
            }
            {completionResultMessage &&
            <PaddedDialogContent dividers padding={"10px"} maxheight='400px'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TypographyWithReturns variant='body1' >{completionResultMessage}</TypographyWithReturns>
                    </Grid>
                </Grid>
            </PaddedDialogContent>
            }
        </Dialog>
    );
};

export default CompleteOnboardingDialog;