import { AddressModel, EmailModel, PhoneModel } from "../gql-types.generated";

export const getPrimaryAddressFromArray = (addresses: AddressModel[]) => {
    if (addresses) {
        const numAddresses = addresses.length;
        if (numAddresses > 1) {
            let i = 0;
            let primary;
            for (i; i < numAddresses; ++i) {
                if (addresses[i].isPrimary) {
                    primary = addresses[i];
                    break;
                }
            }
            if (!primary) {
                primary = addresses[0];
            }
            return primary;
        } else if (numAddresses === 1) {
            return addresses[0];
        }
    }
};

export const getPrimaryEmailFromArray = (emails: EmailModel[]) => {
    if (emails) {
        const numEmails = emails.length;
        if (numEmails > 1) {
            let i = 0;
            let primary;
            for (i; i < numEmails; ++i) {
                if (emails[i].isPrimary) {
                    primary = emails[i];
                    break;
                }
            }
            if (!primary) {
                primary = emails[0];
            }
            return primary;
        } else if (numEmails === 1) {
            return emails[0];
        }
    }
};

export const getPrimaryPhoneFromArray = (phoneNumbers: PhoneModel[]) => {
    if (phoneNumbers) {
        const numNumbers = phoneNumbers.length;
        if (numNumbers > 1) {
            let i = 0;
            let primary;
            for (i; i < numNumbers; ++i) {
                if (phoneNumbers[i].isPrimary) {
                    primary = phoneNumbers[i];
                    break;
                }
            }
            if (!primary) {
                primary = phoneNumbers[0];
            }
            return primary;
        } else if (numNumbers === 1) {
            return phoneNumbers[0];
        }
    }
};