import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, styled, Typography, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridOverlay, GridRowModel, GridSelectionModel, GridSortModel, GridEventListener, GridCallbackDetails, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DeleteByIdPayload, NoteModel, RequestResult, UserRole } from "../../gql-types.generated";
import { TabContainer, TabToolbar, TabContent, TabDataGrid } from '../../util/SharedStyles';
import CreateNewButton from '../buttons/CreateNewButton';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { getDataGridCellValuePreview } from '../../util/Common';
import DeleteDialog from '../dialogs/DeleteDialog';
import NotesDialog from '../dialogs/NotesDialog';



const NotesToolbar = styled(TabToolbar)((props) => ({
    padding: '0 !important',
    alignItems: 'center',
    height: '48px'
}));

const NotesContainer = styled(TabContainer)((props) => ({
    maxHeight: '256px',
}));

const NotesContent = styled(TabContent)((props) => ({
    height: 'calc(100% - 48px)',
}));

const NotesHeader = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.palette.grayscale.dark
}));

interface gridProps {
    height: string;
}
const NotesDataGrid = styled(TabDataGrid)<gridProps>((props) => ({
    padding: '0 0 12px 0 !important',
    height: props.height
}));

interface NotesListProps {
    viewerRole: UserRole | undefined;
    parentId: string | undefined;
    notes: NoteModel[] | undefined;
    saveNote: (
        note: NoteModel,
    ) => void;
    deleteNote: (
        id: string,
    ) => void;
    saveNoteResult?: RequestResult | undefined;
    deleteNoteStatus?: DeleteByIdPayload | undefined;
    error?: Error | undefined;
    isReadOnly?: boolean;
    isLoading?: boolean;
    clearError: () => void;
}

const DialogNotesList: React.FC<NotesListProps> = props => {
    const { viewerRole, parentId, notes, saveNote, deleteNote, saveNoteResult, deleteNoteStatus, error, clearError, isReadOnly = false, isLoading = false } = props;
    const [gridHeight, setGridHeight] = useState<string>('48px');
    const [openNoteDetails, setOpenNoteDetails] = useState(false);
    const [selectedNote, setSelectedNote] = useState<NoteModel | undefined>(undefined); //TODO: string-> Note
    const [displayOnly, setDisplayOnly] = useState(true);
    const [noteRows, setNoteRows] = useState<GridRowModel[]>([]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'header',
            sort: 'asc',
        },
    ]);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [isSavingOrDeletingNote, setIsSavingOrDeletingNote] = useState(false);

    const canEdit = viewerCanEdit(viewerRole);

    useEffect(() => {
        setNoteRows(getNoteRows());
        setGridHeight(getGridHeight());
    }, [notes]);

    useEffect(() => {
        if (saveNoteResult) {
            if (saveNoteResult === RequestResult.Success) {
                onNotesDialogClose();
            }
            setIsSavingOrDeletingNote(false);
            // error will come through props and flow through to Notes Dialog don't close note, leave open to display error
        }

    }, [saveNoteResult]);

    useEffect(() => {
        if (deleteNoteStatus) {
            if (deleteNoteStatus.result === RequestResult.Success) {
                onDeleteDialogClose();
            } else if (deleteNoteStatus.result === RequestResult.Fail) {
                setDeleteErrorMessage(deleteNoteStatus?.message as string,);
            }
            setIsSavingOrDeletingNote(false);
        }
    }, [deleteNoteStatus]);

    const getSelectedRowNote = useCallback((rowId: string) => () => {
        if (rowId && notes?.length) {
            let note = notes.find(note => note.id === rowId);
            return note;
        }
        return undefined;
    }, [notes]);

    const deleteNoteHandler = useCallback((rowId: string) => () => {
        let note = getSelectedRowNote(rowId);
        if (note) {
            setSelectedNote(note);
            setOpenDelete(true);
        }
    }, [getSelectedRowNote]);

    const editNoteHandler = useCallback((rowId: string) => () => {
        let note = getSelectedRowNote(rowId);
        if (note) {
            setSelectedNote(note);
            setDisplayOnly(false);
            setOpenNoteDetails(true);
        }
    }, [getSelectedRowNote]);


    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;

        if (rowId && notes?.length) {
            let note = notes.find(note => note.id === rowId);
            if (note) {
                setSelectedNote(note);
                setDisplayOnly(true);
                setOpenNoteDetails(true);
            }
        }
    };

    const setDefaults = () => {
        setSelectedNote(undefined);
        setSelectionModel([]);
        setDisplayOnly(false);
    };

    const onAddNote = () => {
        setDefaults();
        setOpenNoteDetails(true);
    };

    const onDialogClose = () => {
        setDefaults();
    };

    const onNotesDialogClose = () => {
        setOpenNoteDetails(false);
        onDialogClose();
    };

    const onNotesDialogSave = (text: string, title: string, id?: string | undefined) => {
        setOpenNoteDetails(false);
        setSelectedNote(undefined);
        if (id && notes) {
            setIsSavingOrDeletingNote(true);
            saveNote({ header: title, note: text, id: id, parentId: parentId } as NoteModel);
        } else {
            if (notes) {
                setIsSavingOrDeletingNote(true);
                saveNote({ header: title, note: text, parentId: parentId } as NoteModel);
            }
        }
    };

    const onNoteViewEditCancel = () => {
        clearError();
        let currentNote = selectedNote;
        setSelectedNote(undefined);
        setTimeout(() => {
            setSelectedNote(currentNote);
        });
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected note
        if (selectedNote) {
            setIsSavingOrDeletingNote(true);
            deleteNote(selectedNote.id as string);
        }
    };

    const noteToDisplayPreview = (params: GridValueGetterParams) => {
        let value = params.row.noteContent;
        let ret = getDataGridCellValuePreview(value);
        return ret;
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'notes-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const getGridHeight = () => {
        // either the grid or its container needs a set height to render properly
        // the container only has maxHeight so that it doesn't display a lot of 
        // white space when there are no notes, so need to calculate a height
        // based on the number of rows since want a scroll bar instead of paging
        if (notes) {
            if (notes.length === 1) {
                return '108px';
            } else if (notes.length === 2) {
                return '156px';
            } else if (notes.length >= 3) {
                return '208px';
            }
        }
        return '48px';
    };

    const getNoteRows = () => {
        if (notes && notes.length > 0) {
            return notes.map((note) => {
                const { id, header } = note;
                const noteContent = note.note;

                return {
                    _raw: note,
                    id,
                    header,
                    noteContent
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const noteColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'header',
                headerName: 'NAME',
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
            }, {
                field: 'noteContent',
                headerName: 'DESCRIPTION',
                cellClassName: 'ediDataGridLongDescriptionCell',
                valueGetter: noteToDisplayPreview,
                flex: 2,
                sortable: false,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                minWidth: 100,
                headerAlign: 'center',
                align: 'center',
                hide: !canEdit || isReadOnly, // hide column for reader role or if readonly mode
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editNoteHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteNoteHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],

            },
        ],
        [deleteNoteHandler, editNoteHandler, canEdit, isReadOnly],
    );

    return (
        <NotesContainer>

            <NotesToolbar justify="space-between" noborder={+true}>
                <NotesHeader>Notes</NotesHeader>
                {(canEdit) &&
                    <CreateNewButton
                        text="Add Note"
                        tooltip="Click here to add new notes"
                        variant="text"
                        onClick={onAddNote}
                        disabled={isSavingOrDeletingNote || isLoading}
                        data-cy="add-new-note"
                    />
                }
            </NotesToolbar>

            <NotesContent>
                {((notes !== undefined && notes?.length > 0) || isSavingOrDeletingNote) &&
                    <NotesDataGrid
                        loading={isSavingOrDeletingNote || isLoading}
                        height={gridHeight}
                        headerHeight={38}
                        rowHeight={52}
                        aria-label="Notes List"
                        hideFooter
                        disableColumnMenu
                        disableColumnFilter
                        disableMultipleSelection
                        rows={noteRows}
                        columns={noteColumns}
                        sortingOrder={['asc', 'desc']}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                        selectionModel={selectionModel}
                        onSelectionModelChange={onSelectionModelChange}
                        components={{
                            // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                            LoadingOverlay: loadingOverlay,
                        }}
                    />
                }
            </NotesContent>

            <NotesDialog
                isOpen={openNoteDetails}
                note={selectedNote}
                isReadOnly={displayOnly}
                viewerRole={viewerRole}
                onCancel={onNoteViewEditCancel}
                onClose={onNotesDialogClose}
                onSave={onNotesDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedNote?.id ?? ''}
                heading={'Delete Note'}
                message={'Are you sure you want to delete \'' + selectedNote?.header + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </NotesContainer>
    );

}

export default DialogNotesList;
