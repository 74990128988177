import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetTransactionMapPartnerInput, TransactionMapPartnerModel, TransactionMapPartnersQuery } from '../gql-types.generated';


// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRANSACTION_MAP_PARTNERS = gql`
    query transactionMapPartners($input: GetTransactionMapPartnerInput!) {
        transactionMapPartners(input: $input) {
            nodes {
                id
                partner {
                    ediStandards {
                        id
                        name
                      }
                    name
                    id
                    totalTransactions
                    website
                }
                partnerId
            }
        }
    }
`;

export const queryTransactionMapPartners = async (
    client: ApolloClient<NormalizedCacheObject>,
    id?: string | undefined,
    partnerId?: string | undefined,
    transactionMapId?: string | undefined,
) => {
    const { data, errors } = await client.query<TransactionMapPartnersQuery>({
        query: QUERY_TRANSACTION_MAP_PARTNERS,
        variables: {
            input: {
                id,
                partnerId,
                transactionMapId
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    if (data?.transactionMapPartners && data?.transactionMapPartners.nodes) {
        return data?.transactionMapPartners.nodes as TransactionMapPartnerModel[];
    }
    return undefined;
}