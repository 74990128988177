import { Box, styled, Typography } from '@mui/material';

import { useTitle } from '../util/Common';

const UsersBox = styled(Box)((props) => ({
    width: '95%',
}));
const UsersBoxLabel = styled(Typography)((props) => ({
    marginBottom: '20px',
}));

const UnauthorizedView: React.FC = () => {
    useTitle();
    return (
        <UsersBox>
            <UsersBoxLabel variant="h3">
                Not Authorized
            </UsersBoxLabel>
            <UsersBoxLabel>
                User is not authorized to use this application.  If you feel this is a mistake, please contact your admin.
            </UsersBoxLabel>
        </UsersBox>
    );

};

export default UnauthorizedView;