import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import WarningIcon from '@mui/icons-material/Warning';
import AlertIcon from '@mui/icons-material/NotificationImportant';
import InfoIcon from '@mui/icons-material/Info';
import { SystemNotificationModel, SystemNotificationType } from '../gql-types.generated';

interface bannerProps {
    severity: SystemNotificationType | undefined;
}
const BannerContainer = styled(Grid)<bannerProps>(({ theme, severity }) => ({
    height: 'auto',
    minHeight: '46px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `solid 1px ${theme.palette.border.main}`,
    ...(severity === SystemNotificationType.Alert && {
        color: theme.palette.toastError.main,
        background: theme.palette.toastError.light
    }),
    ...(severity === SystemNotificationType.Warning && {
        color: theme.palette.toastWarning.main,
        background: theme.palette.toastWarning.light
    }),
    ...(severity === SystemNotificationType.Information && {
        color: theme.palette.toastInfo.main,
        background: theme.palette.toastInfo.light
    }),
    ...(severity === undefined && {
        display: 'none'
    }),
}));


interface NotificationBannerProps {
    notification: SystemNotificationModel | undefined;
}

const NotificationBanner: React.FC<NotificationBannerProps> = props => {
    const { notification } = props;
    
    const getBannerIcon = () => {
        switch (notification?.notificationType) {
            case SystemNotificationType.Alert:
            return <AlertIcon color="error" style={{marginRight:'16px'}}></AlertIcon>;
            case SystemNotificationType.Warning:
            return <WarningIcon color="warning" style={{marginRight:'16px'}}></WarningIcon>;
            case SystemNotificationType.Information:
            return <InfoIcon color="info" style={{marginRight:'16px'}}></InfoIcon>;
            default:
            return <WarningIcon color="warning" style={{marginRight:'16px'}}></WarningIcon>;
        };
    };
       
    return (
        <BannerContainer id='notification-banner' container severity={notification?.notificationType ?? undefined} >
            {getBannerIcon()}
            <Typography>{notification?.message}</Typography>
        </BannerContainer>
    );
};

export default NotificationBanner;