import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { CircularProgress, Link, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowModel, GridRenderCellParams, GridOverlay, GridSortModel, GridRowParams } from '@mui/x-data-grid-pro';
import { TabContainer, TabToolbar, TabContent, TabDataGridNoRowHover } from '../../util/SharedStyles';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CreateNewButton from '../buttons/CreateNewButton';
import { getShortDateString } from '../../util/DateTimeUtility';
import DeleteDialog from '../dialogs/DeleteDialog';
import BillingItemDialog from '../dialogs/BillingItemDialog';
import NoRecordsMessage from '../NoRecordsMessage';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../util/Constants';
import { BillingItemModel, RequestResult, UserRole } from '../../gql-types.generated';
import {
    clearError,
    captureUpsertBillingItemStatus,
    captureDeleteBillingItemStatus,
    selectError,
    selectDeleteBillingItemStatus,
    selectUpsertBillingItemStatus,
} from '../../features/BillingDetails/BillingDetailsSlice';
import { deleteBillingItem, upsertBillingItem } from '../../features/BillingDetails/BillingDetailsActions';


interface BillingItemsListProps {
    viewerRole: UserRole | undefined;
    billingId: string;
    billingCurrencyCode: string;
    billingItems: BillingItemModel[] | undefined;
    refreshBillingItems: () => void;
}

const BillingItemsList: React.FC<BillingItemsListProps> = props => {
    const { viewerRole, billingId, billingCurrencyCode, billingItems, refreshBillingItems } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [selectedBillingItem, setSelectedBillingItem] = useState<BillingItemModel | undefined>(undefined);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [billingItemRows, setBillingItemRows] = useState<GridRowModel[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'dateFrom',
            sort: 'desc',
        },
    ]);

    const upsertBillingItemStatus = useAppSelector(selectUpsertBillingItemStatus);
    const deleteBillingItemStatus = useAppSelector(selectDeleteBillingItemStatus);

    const error = useAppSelector(selectError);

    useEffect(() => {
        setBillingItemRows(getBillingItemRows());
    }, [billingItems]);

    useEffect(() => {
        // fetch list when a successful mutation occurs
        if (upsertBillingItemStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertBillingItemStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status and close dialog
            dispatch(captureUpsertBillingItemStatus());
            onBillingItemDialogClose();
        }
        if (deleteBillingItemStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteBillingItemStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteBillingItemStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteBillingItemStatus.message as string);
        }
    }, [upsertBillingItemStatus?.result, deleteBillingItemStatus?.result]);

    const getSelectedRowItem = useCallback((rowId: string) => () => {
        if (rowId && billingItems?.length) {
            let item = billingItems.find(bi => bi.id === rowId);
            return item;
        }
        return undefined;
    }, [billingItems]);

    const deleteItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedBillingItem(item);
            setOpenDelete(true);
        }
    }, [getSelectedRowItem]);

    const editItemHandler = useCallback((rowId: string) => () => {
        let item = getSelectedRowItem(rowId);
        if (item) {
            setSelectedBillingItem(item);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowItem, dispatch]);

    const isEditDisabled = (rowDateFrom: string) => {
        if (rowDateFrom) {
            let dateFrom = DateTime.fromISO(rowDateFrom).startOf('day');
            let sixMonthsAgo = DateTime.now().startOf('day').minus({ months: 6});
            // if the dateFrom date is more than 6 months in the past, then the range is too far
            // in the past and editing of it should not be allowed
            return sixMonthsAgo >= dateFrom;
        }
        return undefined;
    };

    const isDeleteDisabled = (rowDateFrom: string) => {
        if (rowDateFrom) {
            let dateFrom = DateTime.fromISO(rowDateFrom).startOf('day');
            let today = DateTime.now().startOf('day');
            // only can delete future dates, so if current date is after 
            // the dateFrom date, then disable since either past or present, not future
            return today >= dateFrom;
        }
        return undefined;
    };



    const billingItemColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'dateFrom',
                headerName: 'DATE FROM',
                minWidth: 180,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    return (
                        dateValue
                    );
                },
            }, {
                field: 'dateTo',
                headerName: 'DATE TO',
                minWidth: 180,
                sortable: true,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    return (
                        dateValue
                    );
                },
            }, {
                field: 'pricingDescription',
                headerName: 'PRICING',
                flex: 1,
                sortable: true,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const pricingId = params.row.pricingId;
                    
                    return (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={() => {
                                onRowPricingClick(pricingId);
                            }}
                        >
                            {value}
                        </Link>
                    );
                },
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                width: 120,
                headerAlign: 'center',
                align: 'right',
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editItemHandler(params.row.id)}
                        disabled={isEditDisabled(params.row.dateFrom)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        onClick={deleteItemHandler(params.row.id)}
                        disabled={isDeleteDisabled(params.row.dateFrom)}
                        showInMenu={false}
                     />
                ],
            },
        ],
        [editItemHandler, deleteItemHandler],
    );

    const getBillingItemRows = () => {
        if (billingItems && billingItems.length > 0) {
            return billingItems.map((billingItem: BillingItemModel) => {
                const { id, dateFrom, dateTo, pricing } = billingItem;
                const pricingDescription = pricing?.description;
                const pricingId = pricing?.id;
                return {
                    _raw: billingItem,
                    id,
                    dateFrom,
                    dateTo,
                    pricingDescription,
                    pricingId
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'billingItems-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && billingItems?.length === 0 && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onBillingItemDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteBillingItemStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and BillingItem on close.
        dispatch(clearError());
        setSelectedBillingItem(undefined);
        // Refresh list from parent to bring in potential updates
        refreshBillingItems();
    };

    const onAddBillingItemClick = () => {
        // make sure we don't pass old item info
        setSelectedBillingItem(undefined);
        setOpenModify(true);
    };

    const onBillingItemDialogSave = (
        dateFrom: DateTime,
        dateTo: DateTime,
        pricingId: string,
        id?: string,
    ) => {

        dispatch(upsertBillingItem(
            billingId,
            dateFrom,
            dateTo,
            pricingId,
            id,
        ));
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected billing item
        dispatch(deleteBillingItem(selectedBillingItem?.id as string));
    };

    const onRowPricingClick = (id: string | undefined) => {
        if (id) {
            let route = "/pricingdetails/" + id;
            navigate(route);
        }
    };

    const selectedDateFrom = getShortDateString(selectedBillingItem?.dateFrom);
    const SelectedDateTo = getShortDateString(selectedBillingItem?.dateTo);
    const dateRangeDisplay = `${selectedDateFrom} - ${SelectedDateTo}`;

    return (
        <TabContainer>
            <TabToolbar justify="flex-end">
                <CreateNewButton
                    text="New Price Range"
                    onClick={onAddBillingItemClick}
                    data-cy="add-new-price-range"
                />
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={billingItems === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Price Ranges List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={billingItemRows}
                    columns={billingItemColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </TabContent>

            <BillingItemDialog
                isOpen={openModify}
                currencyCode={billingCurrencyCode}
                billingItem={selectedBillingItem}
                onClose={onBillingItemDialogClose}
                onSave={onBillingItemDialogSave}
                error={error}
            />

            <DeleteDialog
                isOpen={openDelete}
                id={selectedBillingItem?.id ?? ''}
                heading={'Delete Price Range'}
                message={'Are you sure you want to delete \'' + dateRangeDisplay + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    );
}

export default BillingItemsList;