import { ConfigurationModel, DeleteByIdPayload, UpsertConfigurationPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    processList?: ConfigurationModel[];
    upsertProcessStatus?: UpsertConfigurationPayload;
    deleteProcessStatus?: DeleteByIdPayload;
    processRequestsInFlight: number;
};

const initialState: SliceState = {
    error: undefined,
    processList: undefined,
    upsertProcessStatus: undefined,
    deleteProcessStatus: undefined,
    processRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'processes',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.processList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.processRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.upsertProcessStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteProcessStatus = undefined;
        },

        fetchProcessListSuccess: (state, action: PayloadAction<ConfigurationModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.processList = action.payload;
        },

        captureUpsertProcessStatus: (state, action: PayloadAction<UpsertConfigurationPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertProcessStatus = action.payload;
        },

        captureDeleteProcessStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteProcessStatus = action.payload;
        },

        incrementProcessRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.processRequestsInFlight += 1;
        },

        decrementProcessRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.processRequestsInFlight > 0) state.processRequestsInFlight -= 1;
        }
    },

});

export const selectError = (state: RootState): Error | undefined => state.processes.error;
export const selectProcessList = (state: RootState): ConfigurationModel[] | undefined => state.processes.processList;
export const selectUpsertProcessStatus = (state: RootState): UpsertConfigurationPayload | undefined => state.processes.upsertProcessStatus;
export const selectDeleteProcessStatus = (state: RootState): DeleteByIdPayload | undefined => state.processes.deleteProcessStatus;
export const selectRequestsInFlight = (state: RootState): number => state.processes.processRequestsInFlight;

export const {
    clearError,
    fetchError,
    clearState,
    fetchProcessListSuccess,
    captureUpsertProcessStatus,
    captureDeleteProcessStatus,
    incrementProcessRequestsInFlight,
    decrementProcessRequestsInFlight
} = slice.actions;

export default slice.reducer;