import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { SystemNotificationsQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_SYSTEM_NOTIFICATIONS = gql`
    query systemNotifications {
        systemNotifications {
            id
            message
            notificationType
            dateStart
            dateEnd
            environment
        }
    }
`;

export const querySystemNotifications = async (client: ApolloClient<NormalizedCacheObject>) => {
    const { data, errors } = await client.query<SystemNotificationsQuery>({
        query: QUERY_SYSTEM_NOTIFICATIONS,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.systemNotifications;
}