import { SystemNotificationModel, UpsertSystemNotificationPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    notificationList?: SystemNotificationModel[];
    activeNotification?: SystemNotificationModel;
    upsertNotificationStatus?: UpsertSystemNotificationPayload;
};

const initialState: SliceState = {
    error: undefined,
    notificationList: undefined,
    activeNotification: undefined,
    upsertNotificationStatus: undefined,
};

export const slice = createSlice({
    name: 'systemNotifications',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.notificationList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.activeNotification = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertNotificationStatus = undefined;
        },

        fetchNotificationListSuccess: (state, action: PayloadAction<SystemNotificationModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.notificationList = action.payload;
        },

        fetchActiveNotificationSuccess: (state, action: PayloadAction<SystemNotificationModel | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.activeNotification = action.payload;
        },

        captureUpsertNotificationStatus: (state, action: PayloadAction<UpsertSystemNotificationPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertNotificationStatus = action.payload;
        },
    },

});

export const selectError = (state: RootState): Error | undefined => state.systemNotifications.error;
export const selectNotificationList = (state: RootState): SystemNotificationModel[] | undefined => state.systemNotifications.notificationList;
export const selectActiveNotification = (state: RootState): SystemNotificationModel | undefined => state.systemNotifications.activeNotification;
export const selectUpsertNotificationStatus = (state: RootState): UpsertSystemNotificationPayload | undefined => state.systemNotifications.upsertNotificationStatus;

export const {
    clearError,
    fetchError,
    clearState,
    fetchNotificationListSuccess,
    fetchActiveNotificationSuccess,
    captureUpsertNotificationStatus
} = slice.actions;

export default slice.reducer;