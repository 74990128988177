import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { NotificationFormatterByIdQuery, GetNotificationFormatterInput, NotificationFormatterModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_NOTIFICATION_FORMATTER_BY_ID = gql`
    query notificationFormatterById($input: GetNotificationFormatterInput) {
        notificationFormatters (input: $input) {
            nodes {
                id
                description
                fileType
                formatterType
                subject
                body
                bodyType
                asAttachment
                isForError
                template
                sourceType
                totalTransactions
                notificationFormatterTransactions {
                    id
                    notificationFormatterId
                    transactionId
                    transaction {
                        id
                        name
                        description
                        direction
                        isActive
                    }
                }
            }
        }
    }
`;

export const queryNotificationFormatterById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id?: string | undefined
) => {
    const { data, errors } = await client.query<NotificationFormatterByIdQuery>({
        query: QUERY_NOTIFICATION_FORMATTER_BY_ID,
        variables: {
            input: {
                id
            }
        },
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    if (data?.notificationFormatters && data?.notificationFormatters.nodes && data?.notificationFormatters.nodes?.length > 0) {
        return data?.notificationFormatters?.nodes[0] as NotificationFormatterModel;
    }
    return undefined;
};