import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { captureDeletePartnerNotificationRecipientsStatus, captureUpsertPartnerNotificationRecipientsStatus, clearError, selectPartnerNotificationsRecipients, selectDeletePartnerNotificationsRecipientsStatus, selectError, selectUpsertPartnerNotificationsRecipientsStatus } from "../../features/TradingPartnerDetails/TradingPartnerDetailsSlice";
import { deletePartnerNotificationRecipients, fetchPartnerNotificationRecipients, upsertPartnerNotificationRecipients } from "../../features/TradingPartnerDetails/TradingPartnerDetailsActions";
import { PartnerNotificationRecipientsModel, NotificationType, UpsertPartnerNotificationRecipientsInput, UserRole } from "../../gql-types.generated";
import NotificationsRecipientsList from "./NotificationRecipientsList";


interface PartnerNotificationsRecipientsListProps {
    viewerRole: UserRole | undefined;
    partnerId: string;
}

const PartnerNotificationsRecipientsList: React.FC<PartnerNotificationsRecipientsListProps> = props => {
    const { viewerRole, partnerId } = props;
    const dispatch = useAppDispatch();
    
    const notificationRecipients = useAppSelector(selectPartnerNotificationsRecipients);
    const error = useAppSelector(selectError);
    const upsertRecipientStatus = useAppSelector(selectUpsertPartnerNotificationsRecipientsStatus);
    const deleteRecipientStatus = useAppSelector(selectDeletePartnerNotificationsRecipientsStatus);

    useEffect(() => {
        if (partnerId) {
            dispatch(fetchPartnerNotificationRecipients(partnerId));
        }
    }, [partnerId]);
  
    const refreshList = () => {
        dispatch(fetchPartnerNotificationRecipients(partnerId));
    };

    const onRecipientsDialogClose = () => {
        // clear upsert status
        dispatch(captureUpsertPartnerNotificationRecipientsStatus());
    }

    const onRecipientsDialogSave = (
        notificationType: NotificationType,
        description?: string,
        receivesErrors?: boolean,
        receivesNotifications?: boolean,
        recipients?: string,
        id?: string
    ) => {
        if (notificationType) {
            var upsertRecipient = {
                partnerId: partnerId,
                description: description,
                notificationType: notificationType,
                receivesErrors: receivesErrors,
                receivesNotifications: receivesNotifications,
                recipients: recipients,
                id: id
            } as UpsertPartnerNotificationRecipientsInput;
            dispatch(upsertPartnerNotificationRecipients(upsertRecipient));
        }
    }

    const onDeleteDialogClose = () => {
        // clear delete status
        dispatch(captureDeletePartnerNotificationRecipientsStatus());
    };

    const onDeleteDialogConfirm = (selectedRecipientId: string) => {
        dispatch(deletePartnerNotificationRecipients(selectedRecipientId));
    }

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <NotificationsRecipientsList
            viewerRole={viewerRole}
            notificationRecipients={notificationRecipients as PartnerNotificationRecipientsModel[]}
            deleteStatus={deleteRecipientStatus}
            upsertStatus={upsertRecipientStatus}
            onDeleteDialogConfirm={onDeleteDialogConfirm}
            onDeleteDialogClose={onDeleteDialogClose}
            onRecipientsDialogSave={onRecipientsDialogSave}
            onRecipientsDialogClose={onRecipientsDialogClose}
            refreshList={refreshList}
            clearError={handleClearError}
            error={error}
            
        />
    )
}

export default PartnerNotificationsRecipientsList;