
import NoResultsFoundIcon from '../icons/no-results-found.svg';
import { Grid, Typography } from '@mui/material'

/** Component for displaying no filter results on list pages */
interface MessageProps {
    children?: React.ReactNode;
    header?: string | undefined,
    message?: string | undefined,
    topMargin?: number
}

const NoResultsMessage: React.FC<MessageProps> = (props) => {
    const { header, message, topMargin = 12 } = props;
    const noResultsIcon = <img src={NoResultsFoundIcon} alt="No Results" aria-label='no results found'></img>;
    
    let displayHeader = header ?? 'NO RESULTS FOUND';
    let displayMessage = message ?? 'No results match this filter criteria. Remove filters to show results.';
    
    return (
        <Grid container item marginTop={topMargin} gap={'4px'} direction='column' justifyContent='center' alignItems='center' >
            {noResultsIcon}
            <Typography marginTop={3} fontSize={'26px'} fontWeight={'bold'}>{displayHeader}</Typography>
            <Typography fontSize={'18px'} marginBottom={3}>{displayMessage}</Typography>
            {props.children}
        </Grid>
    )
};

export default NoResultsMessage;