import { FormControl, Grid, InputLabel, MenuItem, Stack, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from "../../app/hooks";

import { AddressModel } from "../../gql-types.generated";
import { selectStatesList, selectCountriesList } from "../../features/EDIContainer/EDIContainerSlice";
import StateField from "../fields/StateField";


interface AddressFieldsProps {
    parentId?: string;
    address?: AddressModel;
    disabled?: boolean;
    onChange: (
        address: AddressModel,
    ) => void;
}

const AddressFields: React.FC<AddressFieldsProps> = props => {
    const { parentId, address, onChange, disabled = false } = props;
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [city, setCity] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [stateId, setStateId] = useState('');
    const [addressId, setAddressId] = useState<string | undefined>(undefined);
    const [isPrimary, setIsPrimary] = useState(false);


    const countries = useAppSelector(selectCountriesList);
    const states = useAppSelector(selectStatesList);

    useEffect(() => {
        if (address) {
            setAddressLine1(address.addressLine1 || '');
            setAddressLine2(address.addressLine2 || '');
            setAddressId(address.id || undefined);
            setCity(address.city || '');
            setCountryCode(address.countryCode?.trim() || '');
            setPostalCode(address.postalCode || '');
            setStateId(address.stateId || '');
            if (address.hasOwnProperty('isPrimary')) {
                setIsPrimary(address.isPrimary ?? false);
            }
        } else {
            setToDefaults();
        }
    }, [address]);

    const setToDefaults = () => {
        setAddressLine1('');
        setAddressLine2('');
        setAddressId(undefined);
        setCity('');
        setCountryCode('');
        setPostalCode('');
        setStateId('');
        setIsPrimary(false);
    };

    const onDataChanged = (fields: {address1?: string, address2?: string, cityField?: string, state?: string, zip?: string, country?: string}) => {
        // set ahead of time.  set string value to property, undefined property to stored value
        // this fixes an issue where empty strings were triggering the stored value to be replaced in the value.
        const { 
            address1=addressLine1, 
            address2=addressLine2, 
            cityField=city, 
            state=stateId, 
            zip=postalCode, 
            country=countryCode
        } = fields;
                
        // send field values in Model to be passed in OnChange
        onChange({
            parentId: parentId,
            id: addressId,
            addressLine1: address1,
            addressLine2: address2,
            city: cityField,
            stateId: state,
            postalCode: zip,
            countryCode: country,
            isPrimary: isPrimary
        } as AddressModel)
    };

    const onAddress1Changed = (event: ChangeEvent<HTMLInputElement>) => {
        setAddressLine1(event.target.value);
        onDataChanged({ address1: event.target.value });
    };
    const onAddress2Changed = (event: ChangeEvent<HTMLInputElement>) => {
        setAddressLine2(event.target.value);
        onDataChanged({ address2: event.target.value });
    };
    const onCityChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setCity(event.target.value);
        onDataChanged({ cityField: event.target.value });
    };
    const onStateChanged = (event: SelectChangeEvent<string | null>) => {
        setStateId(event.target.value ?? '');
        onDataChanged({ state: event.target.value ?? undefined });
    };
    const onPostalCodeChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let newVal = event.target.value;
        let isValid = true;
        if (newVal) {
            const zipRegex = "^[\\d-]+$";
            const match = newVal.match(zipRegex);
            // an invalid character was entered, so don't set the new value
            // this will make it look to user like that entry is not allowed
            // Doing this restriction rather than validating actual format
            // since different countries have different formatting rules
            // Some countries do allow alpha characters and other symbols,
            // so for now, only going to allow digits and hyphen until we have localization
            if (!match) {
                isValid = false;
            }
        }
        if (isValid) {
            setPostalCode(newVal);
            onDataChanged({ zip: newVal });
        }
    };
    const onCountryChanged = (event: SelectChangeEvent<string | null>) => {
        setCountryCode(event.target.value ?? '');
        onDataChanged({ country: event.target.value ?? undefined });
    };
    
    const getCountryOptions = () => {
        if (countries && countries.length) {
            return (
                countries.map((country: string) => (
                    <MenuItem
                        key={country?.trim()}
                        value={country?.trim()}
                    >
                        {country?.trim()}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const address1Props = {
        'aria-label': 'address line 1',
        'maxLength': 100,
    };
    const address2Props = {
        'aria-label': 'address line 2',
        'maxLength': 100,
    };
    const cityProps = {
        'aria-label': 'city',
        'maxLength': 100,
    };
    const countryCodeProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };
    const postalCodeProps = {
        'aria-label': 'postal code',
        'maxLength': 20,
    };

    return (
        <Stack spacing={2}>
            <Grid item >
                <TextField
                    itemID="address-line1"
                    fullWidth
                    disabled={disabled}
                    value={addressLine1}
                    label="Address Line 1"
                    inputProps={address1Props}
                    onChange={onAddress1Changed}
                    autoComplete="off"
                    data-cy="address-Line1"
                    variant="standard"
                />
            </Grid>
            <Grid item >
                <TextField
                    itemID="address-line2"
                    fullWidth
                    disabled={disabled}
                    value={addressLine2}
                    label="Address Line 2"
                    inputProps={address2Props}
                    onChange={onAddress2Changed}
                    autoComplete="off"
                    data-cy="address-Line2"
                    variant="standard"
                />
            </Grid>
            <Grid item >
                <TextField
                    itemID="address-city"
                    fullWidth
                    disabled={disabled}
                    value={city}
                    label="City"
                    inputProps={cityProps}
                    onChange={onCityChanged}
                    autoComplete="off"
                    data-cy="address-city"
                    variant="standard"
                />
            </Grid>
            <Grid item >
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="address-countries-label">Country</InputLabel>
                    <Select
                        labelId="address-countries-label"
                        aria-labelledby="address-countries-label"
                        value={countryCode}
                        onChange={onCountryChanged}
                        disabled={disabled}
                        MenuProps={countryCodeProps}
                        data-cy="address-countryCode"
                    >
                        {getCountryOptions()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="address-states-label">State</InputLabel>
                    <StateField
                        selectedCountryCode={countryCode}
                        stateList={states}
                        disabled={disabled}
                        required={false}
                        labelId="address-states-label"
                        ariaLabelledBy="address-states-label"
                        value={stateId}
                        onChange={onStateChanged}
                        data-cy="address-state"
                    />
                </FormControl>
            </Grid>
            <Grid item >
                <TextField
                    itemID="address-postal-code"
                    fullWidth
                    disabled={disabled}
                    value={postalCode}
                    label="Postal Code"
                    inputProps={postalCodeProps}
                    onChange={onPostalCodeChanged}
                    autoComplete="off"
                    data-cy="address-postalCode"
                    variant="standard"
                />
            </Grid>
        </Stack>
    );
};

export default AddressFields;