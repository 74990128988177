import { PagingResultModelOfMessagingLogModel, MessagingLogModel, ActionPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    eventPagingResult?: PagingResultModelOfMessagingLogModel;
    eventLogList?: MessagingLogModel[];
    eventLogRequestsInFlight: number;
    updateLogsStatus?: ActionPayload;
};

const initialState: SliceState = {
    error: undefined,
    eventPagingResult: undefined,
    eventLogList: undefined,
    eventLogRequestsInFlight: 0,
    updateLogsStatus: undefined
};

export const slice = createSlice({
    name: 'eventLogs',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.eventLogList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.eventLogRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.eventPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateLogsStatus = undefined;
        },

        fetchEventLogListSuccess: (state, action: PayloadAction<PagingResultModelOfMessagingLogModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.eventPagingResult = action.payload;
            let events = state.eventLogList;
            if (!events || !state.eventPagingResult.cursor?.previousPage) {
                events = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!events?.some(i => i.id === node?.id)) {
                        events?.push(node as MessagingLogModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.eventLogList = events;
        },

        incrementEventLogRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.eventLogRequestsInFlight += 1;
        },

        decrementEventLogRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.eventLogRequestsInFlight > 0) state.eventLogRequestsInFlight -= 1;
        },

        captureUpdateLogsStatus: (state, action: PayloadAction<ActionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateLogsStatus = action.payload;
        },
    },

});

export const selectError = (state: RootState): Error | undefined => state.eventLogs.error;
export const selectEventLogList = (state: RootState): MessagingLogModel[] | undefined => state.eventLogs.eventLogList;
export const selectRequestsInFlight = (state: RootState): number => state.eventLogs.eventLogRequestsInFlight;
export const selectEventLogPagingResult = (state: RootState): PagingResultModelOfMessagingLogModel | undefined => state.eventLogs.eventPagingResult;
export const selectUpdateLogsStatus = (state: RootState): ActionPayload | undefined => state.eventLogs.updateLogsStatus;

export const {
    clearError,
    fetchError,
    clearState,
    fetchEventLogListSuccess,
    incrementEventLogRequestsInFlight,
    decrementEventLogRequestsInFlight,
    captureUpdateLogsStatus
} = slice.actions;

export default slice.reducer;