import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { AddClientPartnerTransactionPayload, AddClientPartnerTransactionsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_ADD_CLIENT_PARTNER_TRANSACTIONS = gql`
    mutation addClientPartnerTransactions($input: AddClientPartnerTransactionsInput!){
        addClientPartnerTransactions(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationAddClientPartnerTransactions = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        clientPartnerId: string;
        partnerTransactionIds: string[];
    }
): Promise<AddClientPartnerTransactionPayload | undefined> => {
    const { clientPartnerId, partnerTransactionIds } = options as AddClientPartnerTransactionsInput;
    const { data, errors } = await client.mutate<{ addClientPartnerTransactions: AddClientPartnerTransactionPayload }>({
        variables: {
            input: {
                clientPartnerId,
                partnerTransactionIds
            }
        },
        mutation: MUTATION_ADD_CLIENT_PARTNER_TRANSACTIONS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add client partner transactions.');
    }
    if (data && data.addClientPartnerTransactions) {
        return data.addClientPartnerTransactions;
    }

    return undefined;
};