import { gqlClient } from "../../components/AppProvider";
import { queryPipelineById } from "../../gql/QueryPipelineById";
import { AppDispatch, AppThunk } from "../../store";
import { getErrorMessage } from '../../util/Common';
import { captureDeletePipelineItemStatus, captureUpsertPipelineItemStatus, captureUpsertPipelineStatus, fetchError, fetchPipelineByIdSuccess } from "./PipelineDetailsSlice";
import { mutationUpsertPipeline } from '../../gql/MutationUpsertPipeline';
import { UpsertPipelineInput, RequestResult, UpsertPipelineItemInput } from "../../gql-types.generated";
import { mutationUpsertPipelineItem } from "../../gql/MutationUpsertPipelineItem";
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';

export const fetchPipelineById = (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const pipeline = await queryPipelineById(gqlClient, id);
            if (pipeline) {
                dispatch(fetchPipelineByIdSuccess(pipeline));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertPipeline = (pipelineInput: UpsertPipelineInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPipeline(gqlClient, {
                pipelineInput
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPipelineStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertPipelineItem = (upsertPipelineItemData: UpsertPipelineItemInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPipelineItem(gqlClient, {
                upsertPipelineItemData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPipelineItemStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePipelineItem = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deletePipelineItemStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PipelineItem"
            });
            if (deletePipelineItemStatus) {
                if (deletePipelineItemStatus.result === RequestResult.Fail) {
                    getErrorMessage(deletePipelineItemStatus.message as string, deletePipelineItemStatus.errors, true, true);
                }
                dispatch(captureDeletePipelineItemStatus(deletePipelineItemStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };