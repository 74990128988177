import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { debounce } from 'lodash';
import { CircularProgress, Grid, styled, Typography, Tooltip } from '@mui/material';
import { GridActionsCellItem, GridColumns, GridRowModel, GridRenderCellParams, GridOverlay, GridFilterModel, GridSortModel, GridRowParams, GridLinkOperator } from '@mui/x-data-grid-pro';
import LinkIcon from "@mui/icons-material/Link";
import FileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { DeleteByIdPayload, AttachmentModel, RequestResult, UserRole, UpsertAttachmentInput } from "../../../gql-types.generated";
import { IconAvatar, TabContainer, TabToolbar, TabContent, TabDataGridNoRowHover } from '../../../util/SharedStyles';
import CreateNewButton from '../../buttons/CreateNewButton';
import { viewerCanEdit } from '../../../util/ViewerUtility';
import { getShortDateString, getTimeString } from '../../../util/DateTimeUtility';
import { setToastConfig } from '../../../features/EDIContainer/EDIContainerSlice';
import { ToastSeverity } from '../../../util/Constants';
import DeleteDialog from '../../dialogs/DeleteDialog';
import AttachmentsDialog from '../../dialogs/AttachmentsDialog';
import SearchBar from '../../SearchBar';
import GridCellDualVert from '../../listItems/GridCellDualVert';
import NoResultsMessage from '../../NoResultsMessage';
import NoRecordsMessage from '../../NoRecordsMessage';


const LinkTypeIcon = styled(LinkIcon)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const FileTypeIcon = styled(FileIcon)(({ theme }) => ({
    color: theme.palette.primary.dark
}));

interface AttachmentsListProps {
    viewerRole: UserRole | undefined;
    attachments: AttachmentModel[] | undefined;
    upsertAttachmentResult: RequestResult | undefined;
    upsertAttachmentResultMessage?: string;
    deleteAttachmentStatus: DeleteByIdPayload | undefined;
    error?: Error | undefined;
    saveAttachments: (
        attachments: UpsertAttachmentInput[],
    ) => void;
    deleteAttachment: (
        id: string,
    ) => void;
    openAttachment?: (
        id: string,
    ) => void;
    downloadAttachment?: (
        id: string,
    ) => void;
    onDeleteDialogClose: () => void;
    onAttachmentsDialogClose: () => void;
    refreshList: () => void;
    clearError: () => void;
}


const AttachmentsList: React.FC<AttachmentsListProps> = props => {
    const { viewerRole, attachments, upsertAttachmentResult, upsertAttachmentResultMessage, downloadAttachment, openAttachment, deleteAttachmentStatus, error, refreshList, clearError } = props;
    const dispatch = useAppDispatch();
    const [searchText, setSearchText] = useState('');
    const [openAdd, setOpenAdd] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState<AttachmentModel | undefined>(undefined); //TODO: string-> Attachment
    const [attachmentRows, setAttachmentRows] = useState<GridRowModel[] | undefined>(undefined);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'fileName',
            sort: 'asc',
        },
    ]);
    const [filterModel, setFilterModel] = useState<GridFilterModel>();
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');

    const canEdit = viewerCanEdit(viewerRole);

    // Debounced function would be recreated on every render, so to prevent this,
    // Wrap in React useRef to store the debounced function across renders
    const debouncedOnFilterChange = useRef(
        debounce((searchValue: string) => {
            // once the delay time has elapsed, call actual method to handle filter changes
            onFilterChange(searchValue);
        }, 1000)
    ).current;


    useEffect(() => {
        setAttachmentRows(getAttachmentRows());
    }, [attachments]);

    useEffect(() => {
        if (upsertAttachmentResult === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertAttachmentResultMessage ?? "Attachment(s) successfully saved",
                severity: ToastSeverity.Success
            }));
            attachmentsDialogCloseHandler();
            // Refresh list to bring in potential updates
            refreshList();
        }
    }, [upsertAttachmentResult]);

    useEffect(() => {
        if (deleteAttachmentStatus?.result === RequestResult.Success) {
            deleteDialogCloseHandler();
            dispatch(setToastConfig({
                message: deleteAttachmentStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // Refresh list to bring in potential updates
            refreshList();
        }
        else if (deleteAttachmentStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteAttachmentStatus?.message as string);
        }
    }, [deleteAttachmentStatus?.result]);

    const getSelectedRowAttachment = useCallback((rowId: string) => () => {
        if (rowId && attachments?.length) {
            let attachment = attachments.find(attachment => attachment.id === rowId);
            return attachment;
        }
        return undefined;
    }, [attachments]);

    const deleteAttachmentHandler = useCallback((rowId: string) => () => {
        let attachment = getSelectedRowAttachment(rowId);
        if (attachment) {
            setSelectedAttachment(attachment);
            setOpenDelete(true);
        }
    }, [getSelectedRowAttachment]);

    const downloadAttachmentHandler = useCallback((rowId: string) => () => {
        if (rowId) {
            if (downloadAttachment) {
                downloadAttachment(rowId);
            }
        }
    }, [downloadAttachment]);

    const openAttachmentHandler = useCallback((rowId: string) => () => {
        if (rowId) {
            if (openAttachment) {
                openAttachment(rowId);
            }
        }
    }, [openAttachment]);

    const onDialogClose = () => {
        // Clear selectedRow and error on close.
        setSelectedAttachment(undefined);
        clearError();
    }

    const onAddAttachment = () => {
        setSubmitted(false);
        setSelectedAttachment(undefined);
        setOpenAdd(true);
    }

    const attachmentsDialogCloseHandler = () => {
        setOpenAdd(false);
        setSubmitted(false);
        props.onAttachmentsDialogClose();
        onDialogClose();
    }

    const onAttachmentsDialogSave = (attachmentsToSave: UpsertAttachmentInput[]) => {
        if (attachmentsToSave) {
            setSubmitted(true);
            setSelectedAttachment(undefined);
            props.saveAttachments(attachmentsToSave);
        }
    }

    const deleteDialogCloseHandler = () => {
        setOpenDelete(false);
        props.onDeleteDialogClose();
        onDialogClose();
        setDeleteErrorMessage('');
    };

    const onDeleteDialogConfirm = () => {
        // delete the selected business area
        if (selectedAttachment) {
            props.deleteAttachment(selectedAttachment.id as string);
        }
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        // set searchText with each value change
        let searchValue = event.target.value;
        setSearchText(searchValue);

        // since hit this function for each keystroke, debounce the actual search
        debouncedOnFilterChange(searchValue);
    };

    const clearSearch = () => {
        setSearchText('');
        onFilterChange('');
    }

    const onFilterChange = (searchValue: string) => {
        // create a new filter model using the new search value
        // Since links can have a description, it is set as a hidden column so that
        // it is filterable in the grid
        let newFilterModel: GridFilterModel = {
            items: [
                { id: 1, columnField: 'fileName', operatorValue: 'contains', value: searchValue },
                { id: 2, columnField: 'description', operatorValue: 'contains', value: searchValue },
            ],
            linkOperator: GridLinkOperator.Or
        };
        // setting the new model as the filterModel here will trigger the datagrid to apply the new filters
        setFilterModel(newFilterModel);
    };

    const getAttachmentRows = () => {
        if (attachments && attachments.length > 0) {
            return attachments.map((attachment) => {
                const { id, isLink, fileName, description, attachmentString, createdByName, createdTime, fileLastModifiedTime } = attachment;
                // if there is no file date, default it to be same as created date
                let fileModifiedDate = fileLastModifiedTime ?? createdTime;
                return {
                    _raw: attachment,
                    id,
                    isLink,
                    fileName,
                    description,
                    attachmentString,
                    createdBy: createdByName,
                    createdTime,
                    fileModifiedDate
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    const attachmentColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'ATTACHMENT TYPE',
                field: 'isLink',
                minWidth: 120,
                flex: 1,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild",
                // eslint-disable-next-line react/display-name
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let typeIcon = <FileTypeIcon />;
                    if (value === true) {
                        typeIcon = <LinkTypeIcon />;
                    }
                    let label = value === true ? "URL" : "File";
                    return (
                        <Grid container item direction="row" gap={'8px'} alignItems="center">
                            <IconAvatar aria-label={label}>
                                {typeIcon}
                            </IconAvatar>
                            {label}
                        </Grid>
                    )
                }
            }, {
                headerName: 'FILE/URL',
                field: 'fileName',
                renderCell: (params: GridRenderCellParams) => {
                    const { value, row } = params;
                    const description = row.description;
                    let cellElement = (row.isLink === true ?
                        <GridCellDualVert header={description} sub={value} boldHeader subLink />
                        :
                        <GridCellDualVert header={value} boldHeader headerOnClick={openAttachmentHandler(row.id)} />
                    );
                    return (
                        cellElement
                    );
                },
                minWidth: 140,
                flex: 2,
                sortable: true,
            }, {
                headerName: 'DESCRIPTION',
                field: 'description',
                hide: true,  // don't want to display this column, but need it separate and hidden to be able to search/filter on it                
            }, {
                headerName: 'DATE MODIFIED',
                field: 'fileModifiedDate',
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    let timeValue = getTimeString(value, { includeSeconds: true });
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} boldHeader />
                    )
                },
                minWidth: 120,
                flex: 1,
                sortable: true,
            }, {
                headerName: 'DATE UPLOADED',
                field: 'createdTime',
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = getShortDateString(value);
                    let timeValue = getTimeString(value, { includeSeconds: true });
                    return (
                        <GridCellDualVert header={dateValue} sub={timeValue} boldHeader />
                    )
                },
                minWidth: 120,
                flex: 1,
                sortable: true,
            }, {
                headerName: 'UPLOADED BY',
                field: 'createdBy',
                minWidth: 120,
                flex: 1,
                sortable: true,
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                minWidth: 90,
                headerAlign: 'center',
                align: 'right',
                hide: !canEdit, // hide column for reader role
                cellClassName: "ediDataGridCellLastChild",
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => {
                    let ret = [];
                    if (!params.row.isLink) {
                        ret.push(
                            <GridActionsCellItem
                                label="Download"
                                color="primary"
                                onClick={downloadAttachmentHandler(params.row.id)}
                                icon={<Tooltip title="Download"><DownloadIcon /></Tooltip>}
                            />
                        );
                    };
                    ret.push(<GridActionsCellItem
                        label="Delete"
                        color="error"
                        onClick={deleteAttachmentHandler(params.row.id)}
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                    />);
                    return ret;
                }
            },
        ],
        [deleteAttachmentHandler, canEdit, downloadAttachmentHandler],
    );

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'attachments-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <Typography variant="body2">
                        Unable to load data. Please try again later.
                    </Typography>
                )}
                {!error && (!attachments || attachments.length <= 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const noResultsOverlay = () => (
        <GridOverlay>
            {error && (
                <Typography variant="body2">
                    Unable to load data. Please try again later.
                </Typography>
            )}
            {!error && (attachments && attachments.length > 0 && searchText !== '') && (
                <NoResultsMessage topMargin={6} message="" />
            )}
        </GridOverlay>
    );

    return (
        <TabContainer>
            <TabToolbar justify="space-between">
                <SearchBar
                    searchText={searchText}
                    onSearch={handleSearch}
                    onClearSearch={clearSearch}
                />
                {canEdit &&
                    <CreateNewButton
                        text="Add Attachment"
                        onClick={onAddAttachment}
                        data-cy="add-new-attachment"
                    />}
            </TabToolbar>
            <TabContent>
                <TabDataGridNoRowHover
                    loading={attachmentRows === undefined}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Attachments List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={attachmentRows ?? []}
                    columns={attachmentColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    filterMode="client"
                    filterModel={filterModel}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoResultsOverlay: noResultsOverlay,
                    }}
                />
            </TabContent>
            <AttachmentsDialog
                isOpen={openAdd}
                isSubmitted={submitted}
                onClose={attachmentsDialogCloseHandler}
                onSave={onAttachmentsDialogSave}
                onError={onError}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedAttachment?.id ?? ''}
                heading={'Delete Attachment'}
                message={'Are you sure you want to delete \'' + selectedAttachment?.fileName + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={deleteDialogCloseHandler}
                errorMessage={deleteErrorMessage}
            />
        </TabContainer>
    )

}

export default AttachmentsList;
