import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Switch, TextField, Typography, styled } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import EditIcon from '@mui/icons-material/Edit';
import { TransactionModel, TransactionXPathModel, TransactionDirection, EdiStandardModel, Maybe, NormalizedDocumentType, UpsertTransactionInput, UpsertTransactionXPathInput, RequestResult } from '../../gql-types.generated';
import { fetchEdiStandardList } from '../../features/EDIContainer/EDIContainerActions';
import { selectEdiStandardList } from '../../features/EDIContainer/EDIContainerSlice';
import AddEditDialog from "./AddEditDialog";
import TransactionXPathDialog from "./TransactionXPathDialog";
import FileDropZone from '../FileDropZone';
import { capitalizeWithUnderscoreRemoval, getBase64 } from "../../util/Common";
import { maxFileSize } from "../../util/Constants";
import { BlankMenuItem } from "../../util/SharedStyles";
import CreateNewButton from "../buttons/CreateNewButton";
import theme from "../../Theme";

const XPathLabel = styled(Typography)((props) => ({
    color: theme.palette.grayscale.dark,
    fontSize: '16px'
}));

interface DialogProps {
    isOpen: boolean;
    transaction?: TransactionModel | null | undefined;
    onClose: () => void;
    onSave: (upsertTransactionData: UpsertTransactionInput) => void;
    onSaveXPath: (upsertXPathData: UpsertTransactionXPathInput) => void;
    refreshTransactionData: () => void;
    saveTransactionXPathResult?: RequestResult;
    error?: Error | undefined;
    xPathError?: Error | undefined;
}

const TransactionDialog: React.FC<DialogProps> = props => {
    const dispatch = useAppDispatch();
    const { isOpen, transaction, onClose, onSave, onSaveXPath, refreshTransactionData, saveTransactionXPathResult, error, xPathError } = props;
    const id = transaction?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [direction, setDirection] = useState<TransactionDirection>(TransactionDirection.Inbound);
    const [standardId, setStandardId] = useState<Maybe<string> | undefined>('');
    const [billableFactor, setBillableFactor] = useState<number | undefined>(0);
    const [billableFactorInvalid, setBillableFactorInvalid] = useState(false);
    const [isBillable, setIsBillable] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [skipTargetDocumentCreation, setSkipTargetDocumentCreation] = useState<boolean>(false);
    const [documentType, setDocumentType] = useState<NormalizedDocumentType | undefined>(undefined);
    const [transactionXPath, setTransactionXPath] = useState<TransactionXPathModel | undefined>(undefined);
    const [openXPathDialog, setOpenXPathDialog] = useState<boolean>(false);
    const [schemaFileContents, setSchemaFileContents] = useState<string | undefined>(undefined);
    const [existingSchemaFile, setExistingSchemaFile] = useState<File | undefined>(undefined);
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    useEffect(() => {
        // fetch ediStandardList on component render
        dispatch(fetchEdiStandardList());
    }, []);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            resetInitialState();
        } else {
            setTransactionFromDetails();
        }
    }, [isOpen]);

    useEffect(() => {
        // if have a transaction, then populate for Edit
        if (transaction) {
            setTransactionFromDetails()
        }
        else {
            resetInitialState();
        }
    }, [transaction]);

    useEffect(() => {
        // fetch transactions list when a successful mutation occurs
        if (saveTransactionXPathResult === RequestResult.Success) {
            // close the xpath dialog
            setOpenXPathDialog(false);
        }
    }, [saveTransactionXPathResult]);

    const setTransactionFromDetails = () => {
        if (transaction) {
            setName(transaction.name);
            if (transaction.description) {
                setDescription(transaction.description);
            }
            if (transaction.direction) {
                setDirection(transaction.direction);
            }
            if (transaction.documentTransformationSchema !== undefined && transaction.documentTransformationSchema !== null) {
                setSchemaFileContents(transaction.documentTransformationSchema);
                // this File will be used for display purposes only, and we are not saving the document title.  
                // documentTransformationSchema could be quite large, so using a small string (again display only).
                // Actual data will be used when saving.
                let schemaFile = new File(["Sm9lIG1hZGUgdGhpcyBzYW1wbGUgc3RyaW5nLCB5b3Ugc2hvdWxkbid0IGJlIHNlZWluZyBpdC4gIA=="], transaction.name + "_schema.xslt",{type:"text/xml"});
                setExistingSchemaFile(schemaFile);
            }
            if (transaction.hasOwnProperty('ediStandardId')) {
                setStandardId(transaction.ediStandardId);
            }
            else if (transaction.ediStandard?.id) {
                setStandardId(transaction.ediStandard.id);
            }
            if (transaction.hasOwnProperty('billableFactor')) {
                setBillableFactor(transaction.billableFactor);
            }
            if (transaction.isBillable !== undefined && transaction.isBillable !== null) {
                setIsBillable(transaction.isBillable);
            }
            if (transaction.isActive !== undefined && transaction.isActive !== null) {
                setIsActive(transaction.isActive);
            }
            if (transaction.normalizedDocumentType) {
                setDocumentType(transaction.normalizedDocumentType as NormalizedDocumentType);
            }
            if (transaction.skipTargetDocumentCreation !== undefined && transaction.skipTargetDocumentCreation !== null) {
                setSkipTargetDocumentCreation(transaction.skipTargetDocumentCreation);
            }
            
            setTransactionXPath(transaction.transactionXPath ?? undefined);
           
        }
    }

    const resetInitialState = () => {
        setSubmitted(false);
        setIsFormDirty(false);
        setName('');
        setDescription('');
        setDirection(TransactionDirection.Inbound);
        setStandardId('');
        setBillableFactor(0);
        setIsBillable(false);
        setIsActive(true);
        setSkipTargetDocumentCreation(false);
        setBillableFactorInvalid(false);
        setDocumentType(undefined);
        setSchemaFileContents(undefined);
        setExistingSchemaFile(undefined);
        setTransactionXPath(undefined);
    };

    const onError = () => {
        setSubmitted(false);
    };
    
    const isFormValid = () => {
        let requiredCheck = name?.trim().length > 0 && description?.trim().length > 0 && (standardId !== undefined && standardId !== null && standardId !== '') && (documentType !== undefined && documentType !== null);
        return isFormDirty && requiredCheck && !billableFactorInvalid;
    };

    const submitForm = () => {
        setSubmitted(true);
        
        onSave({
            name: name, 
            description: description, 
            isActive: isActive, 
            isBillable: isBillable, 
            direction: direction, 
            billableFactor: billableFactor, 
            id: id,
            ediStandardId: standardId,
            normalizedDocumentType: documentType,
            documentTransformationSchema: schemaFileContents,
            skipTargetDocumentCreation: skipTargetDocumentCreation
        } as UpsertTransactionInput);
    };

    // onChange handlers
    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setName(event.target.value);
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
    const onDirectionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDirection(event.target.value as TransactionDirection);
    };
    const onStandardChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        setStandardId(event.target.value);
    };
    const onIsBillableChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsBillable(event.target.checked);
        if (!isBillable) {
            // remove any factor that may have been set
            setBillableFactor(0);
        }
    };
    const onBillableFactorChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value);

        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {

            // if value not in valid range, set invalid
            if (targetValue < minValue || targetValue > maxValue) {
                setBillableFactorInvalid(true);
            } // else if value is in range, but length greater than maxLength (too many decimal places), set invalid
            else if (event.target.value.length > event.target.maxLength) {
                setBillableFactorInvalid(true);
            }
            else {
                // value is valid
                setBillableFactorInvalid(false);
            }

            setIsFormDirty(true);
            setBillableFactor(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as invalid
            // and undefined to force user to enter a valid number
            setBillableFactorInvalid(true);
            setBillableFactor(undefined);
        }
    };

    const onIsActiveChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setIsActive(event.target.checked);
    };

    const onDocumentTypeChange = (event: SelectChangeEvent<string | null>) => {
        setIsFormDirty(true);
        const key = event.target.value;
        if (key) {
            setDocumentType(key as NormalizedDocumentType);
        } else {
            setDocumentType(undefined);
        }
    };

    const onSkipTargetDocCreationChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setSkipTargetDocumentCreation(event.target.checked);
    };

    const getSchemaFileContents = async (file: File) => {

        let fileContentBase64 = "";

        // convert file to base64 string to pass to server
        await getBase64(file).then(result => {
            let resultParts = result?.split(',');
            if (resultParts) {
                fileContentBase64 = resultParts[1] ?? '';
            }
        });

        return fileContentBase64;
    };

    const onFileUploadChange = async (filesToUpload: File[]) => {
        setIsFormDirty(true);
        if (filesToUpload && filesToUpload.length > 0) {
            // only 1 file allowed, so just get contents of first file
            let fileContents = await getSchemaFileContents(filesToUpload[0]);
            setSchemaFileContents(fileContents);

        } else {
            // when no file, make sure any previous file fields are cleared
            setSchemaFileContents(undefined);
        }
    };

    const onAddEditXPath = () => {
        setOpenXPathDialog(true);
    };

    const onXPathDialogClose = () => {
        setOpenXPathDialog(false)
    };

    // get the list of standards for the dropdown
    const standards = useAppSelector(selectEdiStandardList);

    const getStandardsDropList = () => {
        if (standards && standards.length) {
            return (
                standards.map((standard: EdiStandardModel) => (
                    <MenuItem
                        key={standard.id}
                        value={standard.id}
                    >
                        {standard.name}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const getDocumentTypeOptions = () => {
        const types = Object.values(NormalizedDocumentType);
        if (types && types.length) {
            // exclude unknown
            let filteredTypes = types.filter(t => t !== NormalizedDocumentType.Unknown);
            let items = [];
            const blankItem = <BlankMenuItem key="NO-SELECTION" value=""></BlankMenuItem>;
            const mappedItems = (
                filteredTypes.map((docType: NormalizedDocumentType) => (
                    <MenuItem
                        key={docType}
                        value={docType}
                    >
                        {capitalizeWithUnderscoreRemoval(docType, ' ')}
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };

    const nameProps = {
        'aria-label': 'name',
        'maxLength': 50,
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    const billableFactorProps = {
        'aria-label': 'billable factor',
        'maxLength': 3,
        'min': '0',
        'max': '9.9',
        'step': '0.1',
        'precision': 1
    };
    const StandardsMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };
    const docTypeProps = {
        'aria-label': 'document-type',
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    }

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="EDI Transaction"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2} columns={1}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-transaction-name"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={name}
                        label="Name"
                        inputProps={nameProps}
                        onChange={onNameChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-transaction-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-transaction-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-transaction-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl>
                        <FormLabel id="dialog-transaction-direction-label"><Typography variant="caption">Direction</Typography></FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="dialog-transaction-direction-label"
                            name="direction-radio-buttons-group"
                            value={direction}
                            onChange={onDirectionChange}
                            data-cy="dialog-transaction-direction"
                        >
                            <FormControlLabel value={TransactionDirection.Inbound} control={<Radio />} label="Inbound" disabled={submitted} aria-label="inbound" />
                            <FormControlLabel value={TransactionDirection.Outbound} control={<Radio />} label="Outbound" disabled={submitted} aria-label="outbound" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                        <InputLabel id="dialog-transaction-standards-label">EDI Standard</InputLabel>
                        <Select
                            labelId="dialog-transaction-standards-label"
                            aria-labelledby="dialog-transaction-standards-label"
                            value={standardId}
                            onChange={onStandardChange}
                            disabled={submitted}
                            MenuProps={StandardsMenuProps}
                            data-cy="dialog-transaction-edistandard"
                        >
                            {getStandardsDropList()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth required>
                        <InputLabel id="dialog-transaction-document-type-label">Normalized Document Type</InputLabel>
                        <Select
                            labelId="dialog-transaction-document-type-label"
                            aria-labelledby="dialog-transaction-document-type-label"
                            value={documentType ?? ''}
                            onChange={onDocumentTypeChange}
                            disabled={submitted}
                            MenuProps={docTypeProps}
                            data-cy="dialog-transaction-document-type"
                        >
                            {getDocumentTypeOptions()}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Is Billable"
                        control={
                            <Switch
                                itemID="dialog-transaction-is-billable"
                                color="primary"
                                disabled={submitted}
                                checked={isBillable}
                                inputProps={{ 'aria-label': 'is billable' }}
                                onChange={onIsBillableChange}
                                data-cy="dialog-transaction-isbillable"
                            />
                        }
                    />
                </Grid>
                {isBillable && (
                    <Grid item xs={12}>
                        <TextField
                            type="number"
                            itemID="dialog-transaction-billable-factor"
                            fullWidth
                            disabled={submitted}
                            value={billableFactor}
                            label="Billable Factor"
                            inputProps={billableFactorProps}
                            onChange={onBillableFactorChange}
                            autoComplete="off"
                            data-cy="dialog-transaction-billablefactor"
                            variant="standard"
                            error={billableFactorInvalid}
                            helperText="Valid value 0 to 9.9"
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Is Active"
                        control={
                            <Switch
                                itemID="dialog-transaction-is-active"
                                color="primary"
                                disabled={submitted}
                                checked={isActive}
                                inputProps={{ 'aria-label': 'is active' }}
                                onChange={onIsActiveChange}
                                data-cy="dialog-transaction-isactive"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        label="Skip Target Document Creation"
                        control={
                            <Switch
                                itemID="dialog-transaction-skip-target-doc-create"
                                color="primary"
                                disabled={submitted}
                                checked={skipTargetDocumentCreation}
                                inputProps={{ 'aria-label': 'skip target document creation' }}
                                onChange={onSkipTargetDocCreationChange}
                                data-cy="dialog-transaction-skip-target-doc-creation"
                            />
                        }
                    />
                </Grid>
                <Grid item xs={12} >
                    <FileDropZone
                        label="Document Transformation Schema File"
                        maxFileSize={maxFileSize}
                        maxNumberFiles={1}
                        dropInstructionText="Drop your schema file here"
                        existingFiles={existingSchemaFile ? [existingSchemaFile] : undefined}
                        acceptedFileTypes={{
                            'text/xml': ['.xslt'],
                        }}
                        onChange={onFileUploadChange}
                    />
                </Grid>
                <Grid item xs={12} >
                    <Grid container item justifyContent="space-between" alignItems="center">
                        <XPathLabel>Document Search Helper</XPathLabel>
                        {id && !transactionXPath &&
                            <CreateNewButton
                                text="Add"
                                tooltip="Click here to add another Transaction Alias"
                                variant="text"
                                onClick={onAddEditXPath}
                                disabled={submitted}
                                data-cy="dialog-transaction-add-xpath"
                            />
                        }
                        {id && transactionXPath &&
                            <Button
                                startIcon={<EditIcon />}
                                variant="text"
                                onClick={onAddEditXPath}
                                disabled={submitted}
                                data-cy="dialog-transaction-edit-xpath"
                            >Edit</Button>
                        }
                    </Grid>
                    {id && transactionXPath &&
                    <Grid item xs={12}>
                        <TextField
                            itemID="dialog-transaction-xpath"
                            fullWidth
                            disabled={submitted}
                            value={transactionXPath?.xPathJson}
                            multiline
                            maxRows={8}
                            inputProps={descriptionProps}
                            InputProps={{
                                readOnly: true,
                            }}
                            onChange={onDescriptionChange}
                            autoComplete="off"
                            data-cy="dialog-transaction-xpath"
                            variant="standard"
                        />
                    </Grid>
                    }
                </Grid>
            </Grid>
            <TransactionXPathDialog
                isOpen={openXPathDialog}
                parentId={id}
                xPathJson={transactionXPath?.xPathJson as string}
                transactionXPathId={transactionXPath?.id}
                onClose={onXPathDialogClose}
                onSave={onSaveXPath}
                refreshTransactionData={refreshTransactionData}
                error={xPathError}
            />
        </AddEditDialog>
    );
};

export default TransactionDialog;