/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license-pro';
import { createRoot } from 'react-dom/client';
import './index.css';
import theme from './Theme';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './components/AppProvider';
import AuthProvider from './util/AuthProvider';
import { MUI_LICENSE_KEY } from './util/Constants';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AuthProvider>
        <AppProvider/>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
