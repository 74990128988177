import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { RequestResult } from '../../gql-types.generated';
import { queryMapList } from '../../gql/QueryMapsList';
import { fetchError, fetchMapListSuccess, captureUpsertMapStatus, captureDeleteMapStatus, incrementMapRequestsInFlight, decrementMapRequestsInFlight } from './MapsSlice';
import { mutationUpsertMap } from '../../gql/MutationUpsertMap';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { GetTransactionMapInput, PagingResultModelOfTransactionMapModel } from '../../gql-types.generated';


export const fetchMapList = (
    filters?: GetTransactionMapInput
) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementMapRequestsInFlight());
        try {
            const mapList = await queryMapList(gqlClient, filters);
            if (mapList) {
                dispatch(fetchMapListSuccess(mapList as PagingResultModelOfTransactionMapModel));
            }
            dispatch(decrementMapRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
            dispatch(decrementMapRequestsInFlight());
        }
    };

export const upsertMapData = (transactionId: string, description: string, documentString?: string, sourceFileName?: string, standardVersion?: string, id?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertMapStatus = await mutationUpsertMap(gqlClient, {
                transactionId,
                description,
                documentString,
                sourceFileName,
                standardVersion,
                id
            });
            if (upsertMapStatus) {
                if (upsertMapStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertMapStatus.message } as Error));
                    getErrorMessage(upsertMapStatus.message as string, upsertMapStatus.errors, true, true);
                }
                dispatch(captureUpsertMapStatus(upsertMapStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteMapData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteMapStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "TransactionMap"
            });
            if (deleteMapStatus) {
                if (deleteMapStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteMapStatus.message as string, deleteMapStatus.errors, true, true);
                }
                dispatch(captureDeleteMapStatus(deleteMapStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };




