import { useEffect, useState } from 'react';
import { alpha, InputBase, IconButton, InputAdornment, styled } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';


/** Component for displaying the keyword search input control on list pages */

export const SearchComponent = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    width: '40%',
    height: '40px',
}));

export const SearchInput = styled(InputBase)(({ theme }) => ({
    padding: theme.spacing(1, 1, 1, 2),
    width: '100%',
    height: '100%',
}));

interface SearchBarProps {
    placeholder?: string | undefined;
    searchText?: string | undefined;
    onSearch: any;
    onClearSearch: () => void;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    const { placeholder = 'Enter keywords to search...', searchText, onSearch, onClearSearch } = props;
    const [isClearButtonVisible, setIsClearButtonVisible] = useState(false);
    
    useEffect(() => {
        // only display clear button if there is a value
        setIsClearButtonVisible(searchText !== undefined && searchText.length > 0); 
    }, [searchText]);

    return (
        <SearchComponent>
            <SearchInput
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                value={searchText}
                onChange={onSearch}
                className="ediSearchInput"
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon fontSize='medium' color="inherit" data-cy="search-icon" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        {isClearButtonVisible && 
                        <IconButton size="small" color="primary" data-cy="clear-text-icon" onClick={onClearSearch}>
                            <CloseIcon fontSize='small' />
                        </IconButton>
                        }
                    </InputAdornment>
                }
            />
        </SearchComponent>
   )
};

export default SearchBar;