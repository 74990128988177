import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientPartnerTransactionPostProcessingInput, UpsertClientPartnerTransactionPostProcessingPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_POST_PROCESSING = gql`
    mutation upsertClientPartnerTransactionPostProcessing($input: UpsertClientPartnerTransactionPostProcessingInput!){
        upsertClientPartnerTransactionPostProcessing(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const MutationUpsertClientPartnerTransactionPostProcessing = async (
    client: ApolloClient<NormalizedCacheObject>,
    clientPartnerTransactionPostProcessingInputData: UpsertClientPartnerTransactionPostProcessingInput
): Promise<UpsertClientPartnerTransactionPostProcessingPayload | undefined> => {
    const { clientPartnerTransactionId, ediProcessName, id, postProcessingBlobId, processArguments, processName, sortOrder} = clientPartnerTransactionPostProcessingInputData;
    const { data, errors } = await client.mutate<{upsertClientPartnerTransactionPostProcessing: UpsertClientPartnerTransactionPostProcessingPayload}>({
        variables:{
            input: {
                clientPartnerTransactionId,
                ediProcessName,
                id,
                postProcessingBlobId,
                processArguments,
                processName,
                sortOrder
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_PARTNER_TRANSACTION_POST_PROCESSING
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add a client partner transaction post processing.');
    }
    if (data && data.upsertClientPartnerTransactionPostProcessing) {
        return data.upsertClientPartnerTransactionPostProcessing;
    }

    return undefined;
}