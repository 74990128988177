import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UserListQuery, GetUsersInput, PagingResultModelOfUserModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_USERS_LIST = gql`
    query userList($input: GetUsersInput!) {
        users (input: $input){
            cursor {
                nextPage
                previousPage
            }
            nodes {
                id
                firstName
                lastName
                email
                role
                isActive
            }
        }
    }
`;

export const queryUsersList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetUsersInput
) : Promise<PagingResultModelOfUserModel | undefined> => {
    const { after, limit, email, firstName, lastName, isActive, role, order } = filters ?? {};
    const { data, errors } = await client.query<UserListQuery>({
        query: QUERY_USERS_LIST,
        variables: { 
            input: {
                after: after,
                limit: limit,
                email: email,
                firstName: firstName,
                lastName: lastName,
                isActive: isActive,
                role: role,
                order: order,
            }  
        }
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.users as PagingResultModelOfUserModel;
};