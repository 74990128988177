import { MouseEvent } from 'react';
import { ApolloError } from '@apollo/client/errors';
import { Alert, AlertTitle, Grid, Typography, Breakpoint, List, ListItemButton, ListItem, ListItemText  } from "@mui/material";
import { PaddedDialogContent, HSCPrimaryTextNoWrap, OffsetDialogStyledTypography, OffsetDialog, OffsetDialogTitleBar, OffsetDialogTitleGrid } from '../../util/SharedStyles';
import DialogCloseButton from "./DialogCloseButton";
import { ClientModel } from '../../gql-types.generated';

interface MissingBillingDialogProps {
    isOpen: boolean;
    clients: ClientModel[];
    onClose: () => void;
    maxWidth?: Breakpoint | undefined;
    height?: string;
    padding?: string;
    offsetTop?: number;
    offsetLeft?: number;
    error?: ApolloError | undefined;
}

const ClientsMissingBillingDialog: React.FC<MissingBillingDialogProps> = props => {
    const { isOpen, clients, onClose, maxWidth, padding = "10px", height = "100%", offsetTop, offsetLeft, error } = props;
    const maxW = maxWidth || 'xs';
    
    const handleListItemClick = (event: MouseEvent<HTMLElement>) => {
        // because this dialog is opened from within a card with an action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();
    }

    const getClientListItems = () => {
        if (clients) {
            return (
                clients?.map((client: ClientModel, index) => {
                    // set the url to have the client details page open with the edit dialog opened
                    const url = '/client/' + client.id + '/edit';
                       
                    return (
                        <ListItem disableGutters disablePadding key={client.id}>
                            <ListItemButton id={client.id} component="a" href={url} onClick={handleListItemClick}>
                                <ListItemText primary={<HSCPrimaryTextNoWrap>{client.name}</HSCPrimaryTextNoWrap>}  />
                            </ListItemButton>
                        </ListItem>
                    )
                })
            );
        } else if (error) {
            return (
                <Alert severity="error">
                    <AlertTitle>Unable to Load Clients that are missing Billing</AlertTitle>
                    <OffsetDialogStyledTypography>{error.message}</OffsetDialogStyledTypography>
                </Alert>
            );
        }
        
    };
    
    return (
        <OffsetDialog
            aria-label={'clients missing billing list'}
            maxWidth={maxW}
            open={isOpen}
            scroll="paper"
            top={offsetTop}
            left={offsetLeft}
            height={height}
        >
            <OffsetDialogTitleBar id='dialog-title'>
                <OffsetDialogTitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="h5">Clients Missing Billing</Typography>
                </OffsetDialogTitleGrid>
                <DialogCloseButton onClick={onClose}></DialogCloseButton>
            </OffsetDialogTitleBar>
            <PaddedDialogContent dividers padding={padding} minwidth="400px">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <List dense>
                            {getClientListItems()}
                        </List>
                    </Grid>
                </Grid>
            </PaddedDialogContent>
        </OffsetDialog>
    );
};

export default ClientsMissingBillingDialog;