import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { AddNotificationFormatterTransactionsPayload, AddNotificationFormatterTransactionsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_ADD_PARTNER_TRANSACTIONS = gql`
    mutation addNotificationFormatterTransactions($input: AddNotificationFormatterTransactionsInput!){
        addNotificationFormatterTransactions(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationAddNotificationFormatterTransactions = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        inputData: AddNotificationFormatterTransactionsInput
    } 
): Promise<AddNotificationFormatterTransactionsPayload | undefined> => {
    const { notificationFormatterId, transactionIds } = args.inputData;
    const { data, errors } = await client.mutate<{ addNotificationFormatterTransactions: AddNotificationFormatterTransactionsPayload}>({
        variables: { 
            input: { 
                notificationFormatterId,
                transactionIds
            } 
        },
        mutation: MUTATION_ADD_PARTNER_TRANSACTIONS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add Notification Formatter transactions.');
    }
    if (data && data.addNotificationFormatterTransactions) {
        return data.addNotificationFormatterTransactions;
    }

    return undefined;
};