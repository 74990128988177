import { useEffect, useState } from 'react';
import { Grid, styled } from '@mui/material';
import { GridRowModel, GridColDef, GridRowParams, GridRenderCellParams, GridSortModel, GridSelectionModel } from '@mui/x-data-grid-pro';

import { TransactionDirection } from '../../gql-types.generated';
import OutboundIcon from '../../icons/outbound.svg';
import InboundIcon from '../../icons/inbound.svg';
import { DialogDataGridWrapper, DialogDataGrid } from '../../util/SharedStyles';

import AddEditDialog from "./AddEditDialog";

interface dialogDataGridProps {
    hideHeaderCheckbox?: boolean;
}
const DialogDataGridNoHeight = styled(DialogDataGrid)<dialogDataGridProps>((props) => ({
    height: 'unset',
    ...(props.hideHeaderCheckbox === true && {
        '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none',
        },
    }),
}));


interface AddTransactionDialogProps {
    isOpen: boolean;
    transactionRows: GridRowModel[] | undefined;
    minWidth?: string;
    singleSelect?: boolean;
    hideMultiselectHeaderCheckbox?: boolean;
    outboundOnly?: boolean;
    onClose: () => void;
    onSave: (transactionIds?: string[]) => void;
    error?: Error | undefined;
}


const AddTransactionDialog: React.FC<AddTransactionDialogProps> = props => {
    const { isOpen, transactionRows, minWidth, singleSelect = false, hideMultiselectHeaderCheckbox = false, outboundOnly = false, onClose, onSave, error } = props;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'type',
            sort: 'asc',
        },
    ]);

    useEffect(() => {
        if (!isOpen) {
            setSelectionModel([]);
            setSubmitted(false);
            setIsFormDirty(false);
        }
    }, [isOpen]);


    // using DataGrid, define each column
    const transactionColumns: GridColDef[] = [
        {
            resizable: false,
            disableReorder: true,
            disableColumnMenu: true,
            field: 'type',
            headerName: 'TRANSACTION',
            minWidth: 200,
            flex: 1,
            sortable: true,
            // eslint-disable-next-line react/display-name
            renderCell: (params: GridRenderCellParams) => {
                const { value, row } = params;
                // eslint-disable-next-line no-underscore-dangle
                const rowData = row._raw;

                // put everything as one value for the type field to have only 1 column
                let nameDescription = value;
                let directionIcon;
                if (rowData && rowData.description && rowData.direction) {
                    nameDescription = `${value} - ${rowData.description}`;
                    directionIcon = (rowData.direction === TransactionDirection.Inbound) ? <img src={InboundIcon} alt="Inbound" aria-label='inbound'></img> : <img src={OutboundIcon} alt="Outbound" aria-label='outbound'></img>;
                }

                return (
                    <Grid container item direction="row" gap={'8px'} alignItems="center">
                        {nameDescription}
                        {directionIcon}
                    </Grid>
                );
            },
        },
    ];

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        setIsFormDirty(true);
        setSelectionModel(currentSelectionModel);
    };

    const isFormValid = () => {
        if (selectionModel && selectionModel.length > 0 && isFormDirty) {
            return true
        }
        return false;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const submitForm = () => {
        if (isFormValid()) {
            setSubmitted(true);
            // convert GridRowId[] to string[] for saving
            let rowIds: string[] = [];
            selectionModel?.forEach(rowId => rowIds.push(rowId.toString()));
            onSave(rowIds);
        }
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            titlePrefix="Select"
            entityName={`${outboundOnly ? 'Outbound ' : ''}Transactions`}
            padding='16px'
            maxWidth='md'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <DialogDataGridWrapper container minwidth={minWidth}>
                <DialogDataGridNoHeight
                    hideHeaderCheckbox={hideMultiselectHeaderCheckbox}
                    autoHeight
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Transactions Pick List"
                    hideFooter
                    rows={transactionRows ?? []}
                    columns={transactionColumns}
                    checkboxSelection={!singleSelect}
                    isRowSelectable={(params: GridRowParams) => params.row.isSelectable === true}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    onSortModelChange={onSortModelChange}
                    selectionModel={selectionModel}
                    onSelectionModelChange={onSelectionModelChange}
                />
            </DialogDataGridWrapper>
        </AddEditDialog>
    );
};

export default AddTransactionDialog;