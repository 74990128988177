import { useEffect, useState, ReactNode } from "react";
import { useDropzone, FileRejection, FileError, Accept } from 'react-dropzone';
import { Chip, Grid, Paper, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import theme from "../Theme";

const FileChip = styled(Chip)((props) => ({
    backgroundColor: theme.palette.toast.main,
    borderColor: theme.palette.toast.contrastText,
    borderWidth: '1px',
    borderStyle: 'solid',
    color: theme.palette.toast.contrastText,
    marginRight: 5,
    marginBottom: 5,
}));

const RejectionChip = styled(Chip)((props) => ({
    backgroundColor: 'transparent',
    borderColor: theme.palette.error.main,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    marginBottom: 5,
    width: '100%',
    justifyContent: 'space-between',
    height: 40,
}));

const RejectionChipText = styled('div')((props) => ({
    color: theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: '0.85em',
}));

const ChipPaper = styled(Paper)((props) => ({
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    listStyle: 'none',
}));

const DropZoneOverlayText = styled('div')((props) => ({
    padding: '0px 24px',
    textAlign: 'center',
    alignContent: 'center',
}));

const BrowseText = styled('span')((props) => ({
    color: theme.palette.primary.main,
}));

const UploadCloud = styled(CloudUploadIcon)((props) => ({
    color: theme.palette.primary.main,
    width: '100%',
    fontSize: 60,
}));

const LabelSection = styled(Grid)((props) => ({
    minHeight: 'unset',
    marginLeft: 16,
    color: theme.palette.grayscale.dark,
    paddingLeft: '0px !important',
    paddingTop: '16px !important',
    paddingBottom: '4px !important',
}));

const DropZoneLabel = styled('div')((props) => ({
    color: theme.palette.grayscale.dark,
    fontSize: '16px'
}));

interface dropSectionProps {
    disabled?: boolean;
    children: Element | Element[] | ReactNode;
}

const DropSection = styled(Grid)<dropSectionProps>((props) => ({
    minHeight: 'unset',
    border: '1px dashed #00000099',
    borderRadius: 4,
    backgroundColor: '#FAFAFF',
    marginLeft: 16,
    paddingLeft: '0px !important',
    paddingTop: '16px !important',
    paddingBottom: '16px !important',
    cursor: 'pointer',
    ...(props.disabled === true && {
        cursor: 'default',
        backgroundColor: theme.palette.grayscale.light,
        opacity: '30%',
    }),
}));

interface FileDropZoneProps {
    maxNumberFiles: number;
    maxFileSize: number;
    acceptedFileTypes?: Accept;
    dropInstructionText?: string;
    label?: string;
    multipleFileSelection?: boolean;
    shouldClearData?: boolean;
    existingFiles?: File[];
    onChange: (acceptedFilesToUpload: File[]) => void;
}

const FileDropZone: React.FC<FileDropZoneProps> = props => {
    const { label, maxNumberFiles, maxFileSize, acceptedFileTypes, existingFiles, shouldClearData = false, multipleFileSelection = false, dropInstructionText = "Drop your file here" } = props;
    const [filesToUpload, setFilesToUpload] = useState<File[]>(existingFiles ?? []);
    const [filesToReject, setFilesToReject] = useState<FileRejection[]>([]);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);
    
    const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
        maxFiles: maxNumberFiles,
        maxSize: maxFileSize,
        multiple: multipleFileSelection,
        accept: acceptedFileTypes,
        disabled: isDisabled
    });

    const setDisabledAsNeeded = (currentFilArray: File[]) => {
        if (maxNumberFiles > 0 && currentFilArray?.length === maxNumberFiles) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    };

    
    useEffect(() => {
        //check disable initially based on existingFiles that there might be
        setDisabledAsNeeded(existingFiles ?? []);
    }, []);

    useEffect(() => {
        if (existingFiles && !acceptedFiles.length && firstLoad) {
            setFilesToUpload(existingFiles);
            setFirstLoad(false);
            setDisabledAsNeeded(existingFiles);
        }
    }, [existingFiles, acceptedFiles]);

    useEffect(() => {
        if (shouldClearData === true) {
            setToDefaults();
        }
    }, [shouldClearData]);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            // purge any duplicate named files that might already be in filesToUpload
            let filteredAcceptedFiles = filterOutDuplicateFiles(acceptedFiles);
            let newArr = filesToUpload.concat(filteredAcceptedFiles);
            setFilesToUpload(newArr);
            setDisabledAsNeeded(newArr);
            props.onChange(newArr);
        }
        
    }, [acceptedFiles]);

    useEffect(() => {
        if (fileRejections && fileRejections.length > 0) {
            // add rejected files to array for display;
            let newArr = filesToReject.concat(fileRejections);
            setFilesToReject(newArr);
        }
    }, [fileRejections]);

    const setToDefaults = () => {
        setFilesToUpload([]);
        setFilesToReject([]);
    };

    

    const filterOutDuplicateFiles = (acceptedFiles: File[]) => {
        // purge any duplicate named files and move the duplicates 
        // to filesToReject to display an error message as to why it wasn't added
        let existingFileNames: string[] = [];
        filesToUpload.forEach(f => existingFileNames.push(f.name));

        let fileDuplicates = acceptedFiles.filter(f => existingFileNames.indexOf(f.name) >= 0);
        if (fileDuplicates.length > 0) {
            setDuplicateFilesAsRejected(fileDuplicates);
            // return filtered files without duplicates
            return acceptedFiles.filter(f => fileDuplicates.indexOf(f) < 0);
        }
        // no duplicates so return all files
        return acceptedFiles;
    };

    const setDuplicateFilesAsRejected = (duplicateFiles: File[]) => {
        if (duplicateFiles && duplicateFiles.length > 0) {
            let rejections = duplicateFiles?.map((file) => {
                let error: FileError = { message: "Duplicate file name.", code: "DUP" };
                return {
                    file: file,
                    errors: [error]
                } as FileRejection;
            }) as FileRejection[];

            let newArray = filesToReject.concat(rejections);
            setFilesToReject(newArray);
        }
    };
    
    const isFormValid = () => {
        let filesValid = filesToUpload && filesToUpload.length > 0;
        return (filesValid);
    };

    const onFileChipDelete = (file: File) => {
        // for advanced logic around maintaining the file list
        if (file && filesToUpload) {
            let newArray = filesToUpload.filter((x) => { return x.name !== file.name });
            if (newArray && newArray.length < filesToUpload.length) {
                setFilesToUpload(newArray);
                setDisabledAsNeeded(newArray);
                props.onChange(newArray);
            }
        }
    };

    const onFileRejectChipDelete = (fileReject: FileRejection) => {
        // in case user doesn't want to see their rejected file anymore, allow removal
        if (fileReject && filesToReject) {
            let newArray = filesToReject.filter((x) => { return x.file.name !== fileReject.file.name });
            if (newArray && newArray.length < filesToReject.length) {
                setFilesToReject(newArray);
            }
        }
    };

    const getFileChips = () => {
        if (filesToUpload && filesToUpload.length) {
            return (
                <ChipPaper elevation={0} >
                    {filesToUpload.map((file: File) => (
                        <Grid key={file.name}>
                            <FileChip label={file.name} onDelete={() => onFileChipDelete(file)} />
                        </Grid>
                    ))}
                </ChipPaper>
            );
        }
        return null;
    };
    
    const getFileRejectionChips = () => {
        return (
            <Paper elevation={0} >
                {filesToReject.map((fileReject: FileRejection) => (
                    <Grid key={fileReject.file.name}>
                        <RejectionChip
                            label={<div><RejectionChipText>{`${fileReject.file.name}: ${fileReject.errors[0].message}`}</RejectionChipText></div>}
                            onDelete={() => onFileRejectChipDelete(fileReject)}
                        />
                    </Grid>
                ))}
            </Paper>
        );
    };

    
    return (
        <Grid container spacing={2}>
            { label && label.length > 0 && 
                <LabelSection>
                    <DropZoneLabel>{label}</DropZoneLabel>
                </LabelSection>   
            }
            <DropSection item xs={12} aria-label='File-Upload' disabled={isDisabled}>
                <div {...getRootProps()}>
                    <input data-cy="file-upload-input" {...getInputProps()} />
                    <div>
                        <UploadCloud fontSize={'large'} />
                        <div>
                            <DropZoneOverlayText>
                                <div>
                                    <span >
                                        {dropInstructionText} or <BrowseText>Browse</BrowseText>
                                    </span>
                                </div>
                            </DropZoneOverlayText>
                        </div>
                    </div>
                </div>
            </DropSection>
            <Grid item xs={12}>
                {getFileChips()}
            </Grid>
            <Grid item xs={12}>
                {getFileRejectionChips()}
            </Grid>
        </Grid >
    );
};

export default FileDropZone;