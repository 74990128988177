import { PartnerTransactionSchemaModel } from "../../../gql-types.generated";
import { GridColumns, GridRowModel, GridOverlay, GridSelectionModel, GridCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { CircularProgress, Typography, Stack } from '@mui/material';
import { DialogDataGrid } from '../../../util/SharedStyles';
import { useEffect, useState, useMemo } from "react";

interface PartnerTransactionSchemaPickerListProps {
    availableSchemas: PartnerTransactionSchemaModel[];
    existingSchemaIds: string[];
    loading: boolean;
    rowSelection: (
        transactionSchemaId: string
    ) => void;
    error?: Error | undefined;
}

const PartnerTransactionSchemaPickerList: React.FC<PartnerTransactionSchemaPickerListProps> = props => {
    const { availableSchemas, existingSchemaIds, loading, rowSelection, error } = props;

    const [schemaRows, setSchemaRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);

    const getSchemaRows = () => {
        if (availableSchemas && availableSchemas.length > 0) {
            return availableSchemas?.map((schema) => {
                const { id, description, standardVersion } = schema;
                return {
                    _raw: schema,
                    id,
                    description, 
                    version: standardVersion
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    useEffect(() => {
        setSchemaRows(getSchemaRows());
    }, [availableSchemas]);

    const schemaColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 180,
                flex: 2,
                sortable: true,
                cellClassName: (cell: GridCellParams) => {
                    // workaround to have the appearance of a disabled row
                    // when a schema already exists on a client partner transaction
                    let rowId = cell.row.id;
                    if (existingSchemaIds?.indexOf(rowId) >= 0) {
                        return 'ediDataGridCellDisabled';
                    }
                    return "";
                },
            },
            {
                field: 'version',
                headerName: 'VERSION',
                minWidth: 80,
                flex: 1,
                sortable: true,
                cellClassName: (cell: GridCellParams) => {
                    // workaround to have the appearance of a disabled row
                    // when a schema already exists on a client partner transaction
                    let rowId = cell.row.id;
                    if (existingSchemaIds?.indexOf(rowId) >= 0) {
                        return 'ediDataGridCellDisabled';
                    }
                    return "";
                },
            }
        ], [],
    );

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        if (currentSelectionModel && availableSchemas?.length) {
            let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
            if (rowId) {
                // workaround for not being able to disable row
                // only set new selection if not "disabled"
                if (existingSchemaIds?.indexOf(rowId) < 0) {
                    setSelectionModel(currentSelectionModel);
                    rowSelection(rowId ?? '');
                }
            }
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'schemas-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && availableSchemas?.length === 0 && (
                    <Typography variant="body2" align="center">
                        No Records found.
                    </Typography>
                )}
            </GridOverlay>
        );
    };

    return (
        <DialogDataGrid
            loading={availableSchemas === undefined || availableSchemas.length <= 0}
            headerHeight={38}
            rowHeight={52}
            aria-label="Schemas List"
            hideFooter
            disableColumnMenu
            disableMultipleSelection
            rows={schemaRows ?? []}
            columns={schemaColumns}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            components={{
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                LoadingOverlay: loadingOverlay,
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                NoRowsOverlay: noRowsOverlay,
            }}
        />
    );
}

export default PartnerTransactionSchemaPickerList;