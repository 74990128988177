import { DeleteByIdPayload, ErpTransactionSchemaModel, UpsertErpTransactionSchemaPayload } from "../../gql-types.generated";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    schemaList?: ErpTransactionSchemaModel[];
    upsertSchemaStatus?: UpsertErpTransactionSchemaPayload;
    deleteSchemaStatus?: DeleteByIdPayload;
    schemaRequestsInFlight: number;
}

const initialState: SliceState = {
    error: undefined,
    schemaList: undefined,
    upsertSchemaStatus: undefined,
    deleteSchemaStatus: undefined,
    schemaRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'erpTransactionShemas',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.schemaList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertSchemaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteSchemaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.schemaRequestsInFlight = 0;
        },

        fetchSchemaListSuccess: (state, action: PayloadAction<ErpTransactionSchemaModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.schemaList = action.payload;
        },

        captureUpsertSchemaStatus: (state, action: PayloadAction<UpsertErpTransactionSchemaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertSchemaStatus = action.payload;
        },

        captureDeleteSchemaStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteSchemaStatus = action.payload;
        },



        incrementSchemaRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.schemaRequestsInFlight += 1;
        },

        decrementSchemaRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.schemaRequestsInFlight > 0) state.schemaRequestsInFlight -= 1;
        }
    }
});

export const selectError = (state: RootState): Error | undefined => state.erpTransactionShemas.error;
export const selectSchemaList = (state: RootState): ErpTransactionSchemaModel[] | undefined => state.erpTransactionShemas.schemaList;
export const selectUpsertSchemaStatus = (state: RootState): UpsertErpTransactionSchemaPayload | undefined => state.erpTransactionShemas.upsertSchemaStatus;
export const selectDeleteSchemaStatus = (state: RootState): DeleteByIdPayload | undefined => state.erpTransactionShemas.deleteSchemaStatus;
export const selectRequestsInFlight = (state: RootState): number => state.erpTransactionShemas.schemaRequestsInFlight;

export const {
    fetchError,
    clearError,
    clearState,
    fetchSchemaListSuccess,
    captureUpsertSchemaStatus,
    captureDeleteSchemaStatus,
    incrementSchemaRequestsInFlight,
    decrementSchemaRequestsInFlight,
} = slice.actions;

export default slice.reducer;