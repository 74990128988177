import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ClonePricingPayload, ClonePricingInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_CLONE_PRICING = gql`
    mutation clonePricing($input: ClonePricingInput!){
        clonePricing(input: $input){
            message
            errors {
                code
                message
            }
            result
            pricing {
                id
            }
        }
    }
`;

export const mutationClonePricing = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        id: string;
        description?: string;
        minServiceFees?: number;
    }
): Promise<ClonePricingPayload | undefined> => {
    const { id, description, minServiceFees } = args as ClonePricingInput;
    const { data, errors } = await client.mutate<{ clonePricing: ClonePricingPayload}>({
        variables: { 
            input: { 
                id,
                description,
                minServiceFees
            } 
        },
        mutation: MUTATION_CLONE_PRICING
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the clone of Pricing.');
    }
    if (data && data.clonePricing) {
        return data.clonePricing;
    }

    return undefined;
};