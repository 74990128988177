import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';

import { queryTradingPartnersList } from '../../gql/QueryTradingPartnersList';
import { captureUpsertTradingPartnerStatus, decrementTradingPartnerRequestsInFlight, fetchError, fetchTradingPartnersListSuccess, incrementTradingPartnerRequestsInFlight, fetchTradingPartnerNameSearchResultsSuccess } from './TradingPartnersSlice';
import { PagingResultModelOfPartnerModel, PartnerStatus, RequestResult, UpsertPartnerInput } from '../../gql-types.generated';
import { mutationUpsertTradingPartner } from '../../gql/MutationUpsertTradingPartner';

export const fetchTradingPartnersList =
    (
        after: string | undefined,
        pageSize: number | undefined,
        name?: string | undefined,
        standardIds?: string[] | undefined,
        statuses?: string[] | undefined,
        partnerCode?: string | undefined,
        businessAreas?: string[] | undefined
    ) => async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementTradingPartnerRequestsInFlight());
        try {
            // convert status string array back into PartnerStatus enum values for correct query type
            let partnerStatuses: PartnerStatus[] = [];
            if (statuses && statuses.length > 0) {
                statuses.forEach(status => {
                    if (status in PartnerStatus) {
                        partnerStatuses.push(status.toUpperCase() as PartnerStatus);
                    }
                });
            }
            const tradingPartnersList = await queryTradingPartnersList(gqlClient, after, pageSize, { name, standardIds, partnerStatuses, partnerCode, businessAreas });
            if (tradingPartnersList) {
                dispatch(fetchTradingPartnersListSuccess(tradingPartnersList as PagingResultModelOfPartnerModel));
            }
            dispatch(decrementTradingPartnerRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
            dispatch(decrementTradingPartnerRequestsInFlight());
        }
    };

export const upsertTradingPartner = (partnerInput: UpsertPartnerInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertTradingPartnerStatus = await mutationUpsertTradingPartner(gqlClient, {
                partnerInput
            });
            if (upsertTradingPartnerStatus) {
                if (upsertTradingPartnerStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertTradingPartnerStatus.message } as Error));
                    getErrorMessage(upsertTradingPartnerStatus.message as string, upsertTradingPartnerStatus.errors, true, true);
                }
                dispatch(captureUpsertTradingPartnerStatus(upsertTradingPartnerStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchTradingPartnerNameSearchResults = (name: string | undefined) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            if (name) {
                // use existing partners list query passing the name for the search and limit to 8 results
                const partnerList = await queryTradingPartnersList(gqlClient, undefined, 8, { name });
                if (partnerList) {
                    dispatch(fetchTradingPartnerNameSearchResultsSuccess(partnerList as PagingResultModelOfPartnerModel));
                }
            } else {
                // dispatch with undefined result to clear out results
                dispatch(fetchTradingPartnerNameSearchResultsSuccess(undefined));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };