import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientPartnerTransactionAliasInput, UpsertClientPartnerTransactionAliasPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_TRANS_ALIAS = gql`
    mutation upsertClientPartnerTransactionAlias($input: UpsertClientPartnerTransactionAliasInput!){
        upsertClientPartnerTransactionAlias(input: $input){
            message
            errors {
                code
                message
            }
            result
            clientPartnerTransactionAlias {
                id
            }
        }
    }
`;

export const mutationUpsertClientPartnerTransactionAlias = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        clientPartnerTransactionAliasInputData: UpsertClientPartnerTransactionAliasInput
    }
): Promise<UpsertClientPartnerTransactionAliasPayload | undefined> => {
    const { clientPartnerTransactionId, alias, id } = args.clientPartnerTransactionAliasInputData;
    const { data, errors } = await client.mutate<{ upsertClientPartnerTransactionAlias: UpsertClientPartnerTransactionAliasPayload}>({
        variables: { 
            input: { 
                clientPartnerTransactionId, 
                alias, 
                id
            } 
        },
        mutation: MUTATION_UPSERT_TRANS_ALIAS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of ClientPartnerTransactionAlias.');
    }
    if (data && data.upsertClientPartnerTransactionAlias) {
        return data.upsertClientPartnerTransactionAlias;
    }

    return undefined;
};