import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ClonePipelinePayload, ClonePipelineInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_CLONE_PIPELINE = gql`
    mutation clonePipeline($input: ClonePipelineInput!){
        clonePipeline(input: $input){
            message
            errors {
                code
                message
            }
            result
            pipeline {
                id
                description
                transaction {
                    id
                    name
                    description
                }
            }
        }
    }
`;

export const mutationClonePipeline = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        pipelineId: string;
    }
): Promise<ClonePipelinePayload | undefined> => {
    const { pipelineId } = args as ClonePipelineInput;
    const { data, errors } = await client.mutate<{ clonePipeline: ClonePipelinePayload}>({
        variables: { 
            input: { 
                pipelineId,
            } 
        },
        mutation: MUTATION_CLONE_PIPELINE
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the clone of Pipeline.');
    }
    if (data && data.clonePipeline) {
        return data.clonePipeline;
    }

    return undefined;
};