import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { NotificationFormatterModel, TransactionModel, NotificationFormatterTransactionModel, RequestResult, UpsertNotificationFormatterInput, AddNotificationFormatterTransactionsInput, PagingResultModelOfNotificationFormatterModel, GetNotificationFormatterInput } from '../../gql-types.generated';
import { 
    fetchError,
    fetchFormatterByIdSuccess,
    fetchFormatterListSuccess, 
    fetchFormatterTransactionsSuccess,
    fetchErrorFormattersSuccess,
    fetchTransactionsSuccess,
    captureUpsertNotificationFormatterStatus, 
    captureDeleteNotificationFormatterStatus,
    captureAddFormatterTransactionsStatus,
    captureDeleteFormatterTransactionStatus, 
    incrementFormatterRequestsInFlight,
    decrementFormatterRequestsInFlight
} from './NotificationFormattersSlice';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';
import { mutationUpsertNotificationFormatter } from '../../gql/MutationUpsertNotificationFormatter';
import { mutationAddNotificationFormatterTransactions } from '../../gql/MutationAddNotificationFormatterTransactions';
import { queryNotificationFormatterById } from '../../gql/QueryNotificationFormatterById';
import { queryNotificationFormatterList } from '../../gql/QueryNotificationFormattersList';
import { queryTransactionList } from '../../gql/QueryTransactionsList';
import { queryNotificationFormatterTransactionList } from '../../gql/QueryNotificationFormatterTransactionsList';
import { queryErrorFormatterList } from '../../gql/QueryErrorFormattersList';

export const fetchNotificationFormatterById = (id: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const formatter = await queryNotificationFormatterById(gqlClient, id);
            if (formatter) {
                dispatch(fetchFormatterByIdSuccess(formatter))
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchNotificationFormatterList = (
        filters?: GetNotificationFormatterInput
    ) => async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementFormatterRequestsInFlight());
        try {
            const formatterList = await queryNotificationFormatterList(gqlClient, filters);
            if (formatterList) {
                dispatch(fetchFormatterListSuccess(formatterList as PagingResultModelOfNotificationFormatterModel))
            }
            dispatch(decrementFormatterRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
            dispatch(decrementFormatterRequestsInFlight());
        }
    };

export const fetchEligibleTransactions = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            // only want active transactions
            const transactionList = await queryTransactionList(gqlClient, { isActive: true });
            if (transactionList) {
                dispatch(fetchTransactionsSuccess(transactionList as TransactionModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchNotificationFormatterTransactions = (formatterId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const transactions = await queryNotificationFormatterTransactionList(gqlClient, { queryInput: {notificationFormatterId: formatterId }});
            if (transactions) {
                dispatch(fetchFormatterTransactionsSuccess(transactions as NotificationFormatterTransactionModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchErrorFormatterList = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const formatterList = await queryErrorFormatterList(gqlClient);
            if (formatterList) {
                dispatch(fetchErrorFormattersSuccess(formatterList as NotificationFormatterModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertNotificationFormatter = (upsertNotificationFormatterData: UpsertNotificationFormatterInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertNotificationFormatter(gqlClient, {
                upsertNotificationFormatterData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertNotificationFormatterStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteNotificationFormatter = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "NotificationFormatter"
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteNotificationFormatterStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const addNotificationFormatterTransactions = (
        inputData: AddNotificationFormatterTransactionsInput
    ): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const addTransactionsStatus = await mutationAddNotificationFormatterTransactions(gqlClient, {
                    inputData
                });
                if (addTransactionsStatus) {
                    if (addTransactionsStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: addTransactionsStatus.message } as Error));
                        getErrorMessage(addTransactionsStatus.message as string, addTransactionsStatus.errors, true, true);
                    }
                    dispatch(captureAddFormatterTransactionsStatus(addTransactionsStatus));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const deleteNotificationFormatterTransaction = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "NotificationFormatterTransaction",
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteFormatterTransactionStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };




