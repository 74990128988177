import { styled, Alert, AlertTitle, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, Typography, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, DateValidationError } from "@mui/x-date-pickers";
import { DateTime } from 'luxon';
import DownloadIcon from '@mui/icons-material/Download';
import { PaddedDialogContent } from '../../util/SharedStyles';
import DialogCloseButton from "./DialogCloseButton";
import { downloadBlobAsFile, getFileDataString } from "../../util/Common";

const TitleBar = styled(DialogTitle)((props) => ({
    height: '56px',
    padding: '4px 24px 0px 24px !important'
}));

const TitleGrid = styled(Grid)((props) => ({
    height: '100%',
}));


interface DialogProps {
    isOpen: boolean;
    isLoading: boolean;
    onClose: () => void;
    onSave: (date: DateTime, aggregates: boolean, sendEmail: boolean, download: boolean) => void;
    preview?: string;
    error?: Error | undefined;
}

const BillingReportDialog: React.FC<DialogProps> = props => {
    const {isOpen, isLoading, onClose, onSave, preview, error} = props;
    const [isSubmitted, setIsSubmitted] = useState(false);

    const currentDate = DateTime.now();
    const defaultDateFrom = currentDate.startOf('month');

    const [dateFrom, setDateFrom] = useState<DateTime>(defaultDateFrom);
    const [dateFromErrorText, setDateFromErrorText] = useState('');
    const [dateFromInvalid, setDateFromInvalid] = useState(false);
    const [generateAggregates, setGenerateAggregates] = useState(true); // defaults on
    const [sendEmail, setSendEmail] = useState(true); // defaults on
    const [downloadPreview, setDownloadPreview] = useState(false); // defaults off
    const [openAttachment, setOpenAttachment] = useState<boolean>(false);

    const inProgressState = (isSubmitted || isLoading) && !preview && !error;

    const clearForm = () => {
        setDateFrom(defaultDateFrom);
        setDateFromErrorText('');
        setDateFromInvalid(false);
        setGenerateAggregates(true);
        setSendEmail(true);
        setDownloadPreview(false);
        setIsSubmitted(false);
        setOpenAttachment(false);
    }

    const handleCancelClick = () => {
        // close
        onClose();
        // clear
        clearForm();
    }

    const handleGenerateClick = () => {
        if (isFormValid()) {
            onSave(
                dateFrom,
                generateAggregates,
                sendEmail,
                downloadPreview
            );
            setIsSubmitted(true);
        }
    }

    const isFormValid = () => {
        let requiredCheck = dateFrom !== undefined;
        let validDateFrom = !dateFromInvalid;
        return validDateFrom && requiredCheck;
    };

    // onChange, onError handlers
    const onDateFromChange = (value: DateTime | null) => {
        let newDateFrom = value ?? defaultDateFrom;
        setDateFrom(newDateFrom);
    };
    const onDateFromError = (reason: DateValidationError, value: DateTime | null) => {
        let errorReason = reason;
        if (errorReason) {
            setDateFromInvalid(true);
            setDateFromErrorText("Invalid Date");
        } else {
            // null errorReason means valid
            setDateFromInvalid(false);
            setDateFromErrorText("");
        }
    };

    const onGenerateAggregatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGenerateAggregates(event.target.checked);
    };
    const onSendEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSendEmail(event.target.checked);
    };
    const onDownloadPreviewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDownloadPreview(event.target.checked);
    };

    const getContent = () => {
        if (preview) {
            return getPreviewContent();
        } else {
            return getFormContent();
        }
    }

    const getDownloadFileName = () => {
        let year = dateFrom.year;
        let month = dateFrom.monthLong;
        return `${year}_${month}_billingReport.csv`;
    }

    useEffect(() => {
        if (!isOpen) {
            clearForm();
        }
    },[isOpen])

    useEffect(() => {
        if (openAttachment && preview){
            const fileData = getFileDataString(true, "text/csv");
            const fileName = getDownloadFileName();
            fetch(fileData + ',' + preview)
            .then(res => res.blob())
            .then(blob => {
                if (openAttachment) {
                    downloadBlobAsFile(blob, fileName);
                }
            });
        }
    },[openAttachment, preview]);

    const downloadPreviewFile = () => {
        setOpenAttachment(true);
    }

    const getPreviewContent = () => {
        return (
            <Grid container spacing={2} justifyContent="center" item xs={12}>
                <Grid item >
                    <Button variant="contained" onClick={downloadPreviewFile} startIcon={<DownloadIcon />}>Download Preview</Button>
                </Grid>
            </Grid>
        );
    }

    const getFormContent = () => {
        return (
            <Grid container spacing={2} item xs={12}>
                <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            label="Date From"
                            value={dateFrom}
                            views={['year','month']}
                            openTo={'month'}
                            disableHighlightToday
                            disabled={inProgressState}
                            onChange={onDateFromChange}
                            onError={onDateFromError}
                            slotProps={{
                                textField: {
                                    variant: "standard",
                                    fullWidth: true,
                                    helperText: dateFromErrorText,
                                    inputProps: {'aria-label': 'date from'}
                                },
                            }}
                            data-cy="dialog-billingItem-date-from"
                        ></DatePicker>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>                    
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={generateAggregates} 
                                onChange={onGenerateAggregatesChange} 
                            />
                        } 
                        label="Generate Aggregates" 
                    />
                </Grid>
                <Grid item xs={12}>                    
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={sendEmail} 
                                onChange={onSendEmailChange} 
                            />
                        } 
                        label="Send Email" 
                    />
                </Grid>
                <Grid item xs={12}>                    
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={downloadPreview} 
                                onChange={onDownloadPreviewChange} 
                            />
                        } 
                        label="Download Report Preview"
                    />
                </Grid>
            </Grid>
        )
    }

    let header = !!preview ? 'Billing Report Preview Ready' : 'Generate Billing Report';

    return (
        <Dialog
            aria-label="generate-billing-report"
            maxWidth='xs'
            open={isOpen}
            scroll="paper"
        >
            <TitleBar id='dialog-title'>
                <TitleGrid container item direction="row" justifyContent="space-between" alignItems="center" xs={11}>
                    <Typography variant="body1">{header}</Typography>
                </TitleGrid>
                <DialogCloseButton onClick={handleCancelClick}></DialogCloseButton>
            </TitleBar>
            <PaddedDialogContent dividers>
                    {error && (
                        <Alert severity="error">
                            <AlertTitle>Unable to Generate Billing Report</AlertTitle>
                            {error.message}
                        </Alert>
                    )}
                    {getContent()}
            </PaddedDialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCancelClick}
                        disabled={inProgressState}
                        data-cy="dialog-billing-report-cancel"
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateClick}
                        disabled={!isFormValid() || inProgressState || !!preview}
                        data-cy="dialog-billing-report-save"
                    >
                        GENERATE
                    </Button>
                </DialogActions>
                {(inProgressState) &&
                    <CircularProgress
                        aria-label={'progress spinner'}
                        size={48}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-24px',
                            marginLeft: '-24px',
                        }}
                    />
                }
        </Dialog>
    )
}

export default BillingReportDialog;