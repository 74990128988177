import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PipelinesListQuery, GetPipelinesInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PIPELINES_LIST = gql`
    query pipelinesList($input: GetPipelinesInput!) {
        pipelines (input: $input){
            nodes {
                id
                description
                isLegacy
                transaction {
                    id
                    name
                    description
                    direction
                }
                pipelineItems {
                    id
                }
            }
        }
    }
`;

export const queryPipelineList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetPipelinesInput
) => {
    const { id, transactionId, isLegacy } = filters ?? {};
    const { data, errors } = await client.query<PipelinesListQuery>({
        query: QUERY_PIPELINES_LIST,
        variables: {
            input: {
                id,
                transactionId,
                isLegacy
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.pipelines?.nodes;
};