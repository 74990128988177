import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { GetPipelinesInput, PipelineModel, Scalars } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PIPELINE_BY_ID = gql`
query getPipelineById($input: GetPipelinesInput!) {
    pipelines (input: $input){
        nodes {
            createdBy
            createdByName
            createdTime
            description
            id
            isTemplate
            isLegacy
            lastModifiedBy
            lastModifiedByName
            lastModifiedTime
            pipelineItems {
                id
                description
                ediProcessClassName
                jsonArgs
                sortOrder
                configuration {
                    id
                    ediProcess
                    description
                }
            }
            transaction {
                description
                direction
                name
                id
            }
            transactionId
        }
    }
}`;

export const queryPipelineById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id: Scalars['UUID']
): Promise<PipelineModel[] | undefined> => {
    const { data, errors } = await client.query({
        variables: {
            input: {
                id: id
            }
        },
        query: QUERY_PIPELINE_BY_ID,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.pipelines?.nodes as PipelineModel[];
};