import { Box, ClickAwayListener, FormControlLabel, FormLabel, IconButton, Paper, Popper, Radio, RadioGroup, Stack, styled, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { Viewer } from '../../util/Constants';
import { getLocalStorageItem, setLocalStorageItem } from '../../util/ViewerUtility';
import RefreshIcon from '@mui/icons-material/Refresh';
import SettingsIcon from '@mui/icons-material/Settings';

const DockedBRFloater = styled(Stack)((props) => ({
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    right: 0,
    margin: '0 auto',
}));

const PaddedPaper = styled(Paper)((props) => ({
    padding: 10,
}));

const CenteredTimer = styled(Typography)((props) => ({
    padding: 8,
}));

interface DashboardRefreshToolProps {
    viewer: Viewer | undefined;
    onRefresh: () => void;
}

interface DashboardCountDownTimes {
    total: number;
    hours: number;
    minutes: number;
    seconds: number;
}

const DashboardRefreshTool: React.FC<DashboardRefreshToolProps> = props => {
    const { viewer, onRefresh } = props;
    const Ref = useRef<any>(null);
    const [timer, setTimer] = useState('00:00:00');
    const [refreshIntervalSeconds, setRefreshIntervalSeconds] = useState(300); //default to 300 seconds (5 minutes)
    const [menuAnchorEl, setMenuAnchoEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);

    useEffect(() => {
        // If a viewer exists, check local storage for their selected refresh interval
        if (viewer) {
            const viewerInterval = getLocalStorageItem(viewer, "dashboardRefreshIntevalSeconds");
            if (viewerInterval != null) {
                const interval = parseInt(viewerInterval)
                if (interval !== refreshIntervalSeconds){
                    setRefreshIntervalSeconds(interval);
                }
            }
        }
    },[viewer]);

    useEffect(() => {
        // the interval was changed, so reset the timer
        clearTimer(getEndTime());
    },[refreshIntervalSeconds])

    // Helper function takes milliseconds and converts them into a format easy to display
    const MillisecondsToTimeComponents = (totalMilliseconds: number) => {
        const seconds = Math.floor((totalMilliseconds / 1000) % 60);
        const minutes = Math.floor((totalMilliseconds / 1000 / 60) % 60);
        const hours = Math.floor((totalMilliseconds / 1000 / 60 / 60) % 24);
        return {
            total: totalMilliseconds, hours, minutes, seconds
        } as DashboardCountDownTimes;
    }

    // Returns the difference between now and the refresh time in DashboardCountDownTimes
    const getTimeRemainingUntilRefresh = (endTime: Date) => {
        const total = endTime.getTime() - new Date().getTime();
        return MillisecondsToTimeComponents(total);
    }

    // Sets the Numerical string display of the timer
    const setTimerWithTimeComponents = (time: DashboardCountDownTimes) => {
        let {total, hours, minutes, seconds} = time;
        if (total >= 0) {  
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            setTimer(
                (hours > 9 ? hours : '0' + hours) + ':' +
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            );
        }
    }

    // Calls the passed in refresh function, and resets the timer
    const timerFinished = () => {
        onRefresh();
        clearTimer(getEndTime());
    }

    // checks whether to update the countdown or initaite a refresh
    const startTimer = (endTime: Date) => {
        const remaining = getTimeRemainingUntilRefresh(endTime);
        if (remaining.total >= 0) {
            setTimerWithTimeComponents(remaining);
        }
        else {
            timerFinished();
        }
    }

    // Sets the update loop
    const clearTimer = (endTime: Date) => {
        // set timer visually back to interval (*1000 seconds to milliseconds)
        setTimerWithTimeComponents(MillisecondsToTimeComponents(refreshIntervalSeconds*1000));
        // start the Ref Interval update
        if (Ref.current) {
            clearInterval(Ref.current);
        }
        const id = setInterval(() => {
            startTimer(endTime);
        }, 1000);
        Ref.current = id;
    }

    // makes a date for the refresh using the stored interval
    const getEndTime = () => {
        let endTime = new Date();
        endTime.setSeconds(endTime.getSeconds() + refreshIntervalSeconds);
        return endTime;
    }

    // component mount, start the process, will be fixed if the viewer has a interval in local storage
    useEffect(() => {
        clearTimer(getEndTime());
    },[]);

    // Settings Menu button handler
    const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
        setMenuAnchoEl(event.currentTarget);
    };

    // Closing Settings Menu  
    const handleMenuClose = () => {
        setMenuAnchoEl(null);
    };

    // Handler for changing of interval via Radio options
    const onIntervalChange = (event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = event.target.value;
        const newInterval = parseInt(eventValue);
        setRefreshIntervalSeconds(newInterval);
        setLocalStorageItem(viewer, "dashboardRefreshIntevalSeconds", eventValue);
        handleMenuClose();
    };

    return (
        <DockedBRFloater direction="row" >
            <Tooltip title="Time Until Next Dashboard Refresh">
                <CenteredTimer>{timer}</CenteredTimer>
            </Tooltip>
            <IconButton aria-label="refresh dashboard" onClick={timerFinished}>
                <Tooltip title="Refresh Dashboard Now"><RefreshIcon /></Tooltip>
            </IconButton>
            <IconButton aria-label="refresh settings"  onClick={handleMenuClick}>
                <Tooltip title="Dashboard Settings"><SettingsIcon /></Tooltip>
            </IconButton> 
            <Popper
                id="dashboard-menu"
                anchorEl={menuAnchorEl}
                open={menuOpen}
                role={undefined}
                placement="top-end"
                >
                    <PaddedPaper>
                        <ClickAwayListener onClickAway={handleMenuClose}>
                            <Box>
                                <FormLabel id="dashboard-menu-refresh-interval-group-label">Refresh Interval</FormLabel>
                                <RadioGroup
                                    aria-labelledby="dashboard-menu-refresh-interval-group-label"
                                    name="dashboard-menu-refresh-interval-group"
                                    onChange={onIntervalChange}
                                    value={refreshIntervalSeconds}
                                >
                                    <FormControlLabel value="30" control={<Radio />} label="30s" />
                                    <FormControlLabel value="60" control={<Radio />} label="1m" />
                                    <FormControlLabel value="300" control={<Radio />} label="5m" />
                                    <FormControlLabel value="600" control={<Radio />} label="10m" />
                                    <FormControlLabel value="900" control={<Radio />} label="15m" />
                                    <FormControlLabel value="1800" control={<Radio />} label="30m" />
                                    <FormControlLabel value="3600" control={<Radio />} label="1h" />
                                    <FormControlLabel value="7200" control={<Radio />} label="2h" />
                                </RadioGroup>
                            </Box>
                        </ClickAwayListener>
                    </PaddedPaper>
            </Popper>
        </DockedBRFloater>        
    )
}

export default DashboardRefreshTool;