import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { TransactionMapByIdQuery, GetTransactionMapInput, TransactionMapModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRANSACTION_MAP_BY_ID = gql`
    query transactionMapById($input: GetTransactionMapInput!) {
        transactionMaps(input: $input) {
            nodes {
                id
                createdBy
                createdByName
                createdTime
                description
                documentString
                isMapDeployed
                isMapLoaded
                lastModifiedBy
                lastModifiedByName
                lastModifiedTime
                sourceFileName
                standardVersion
                totalPartners
                transaction {
                    id
                    name
                    description
                    direction
                }
                transactionId
            }
        }
    }
`;

export const queryTransactionMapById = async (
    client: ApolloClient<NormalizedCacheObject>,
    id?: string | undefined
) => {
    const { data, errors } = await client.query<TransactionMapByIdQuery>({
        query: QUERY_TRANSACTION_MAP_BY_ID,
        variables: {
            input: {
                id
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    if (data?.transactionMaps && data?.transactionMaps.nodes && data?.transactionMaps.nodes?.length > 0) {
        return data?.transactionMaps?.nodes[0] as TransactionMapModel;
    }
    return undefined;
};