import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Grid, styled } from '@mui/material';
import OnboardingCard from '../../components/dashboard/OnboardingCard';
import FavoritesRecentsTile from '../../components/dashboard/FavoritesRecentsTile';
import DashboardCard from '../../components/dashboard/DashboardCard';
import ClientsMissingRecipientsCard from '../../components/dashboard/ClientsMissingRecipientsCard';
import ClientsMissingBillingCard from '../../components/dashboard/ClientsMissingBillingCard';
import { Viewer } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { MainContentBox, FullHeightStack } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import DashboardRefreshTool from '../../components/dashboard/DashboardRefreshTool';
import { fetchOnboardingClients, fetchUserByEmail, fetchDashboardCounts, fetchClientsMissingNotificationsRecipients } from '../EDIContainer/EDIContainerActions';
import { clearClientsOnboardingList, clearClientsMissingRecipientsList, clearDashboardCounts, selectDashboardCounts } from '../EDIContainer/EDIContainerSlice';

const CardsContainer = styled('div')((props) => ({
    display: 'flex',
    flexGrow: 0,
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    
}));

const CardItem = styled(Grid)((props) => ({
    padding: '0 16px 16px 16px',
    minWidth: '350px'
    
}));

const TilesContainer = styled('div')((props) => ({
    display: 'flex',
    flexGrow: 2,
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
}));

interface HomeProps {
    viewer: Viewer | undefined;
}

const Home: React.FC<HomeProps> = props => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    
    const dashboardCounts = useAppSelector(selectDashboardCounts);

    const canEdit = viewerCanEdit(viewer);
    
    useEffect(() => {
        // initial fetch/load of counts
        setIsLoading(true);
        dispatch(fetchDashboardCounts());
    },[]);

    useEffect(() => {
        if (dashboardCounts) {
            setIsLoading(false);
        }
    },[dashboardCounts]);

    useTitle();
    
    const onRefresh = () => {
        setIsLoading(true);
        // this will need to dispatch all the calls the dashboard relies on
        if (viewer && viewer.email) {
            // Recents & Favorites
            dispatch(fetchUserByEmail(viewer.email));
        }
        // Clearing and then fetching shows the loading indicator.
        // If no loading indicator is needed, remove the clear calls.
        // counts
        dispatch(clearDashboardCounts());
        dispatch(fetchDashboardCounts());
        // Onboarding Clients
        dispatch(clearClientsOnboardingList());
        dispatch(fetchOnboardingClients());
        // Clients Missing Notification Recipients
        dispatch(clearClientsMissingRecipientsList());
        dispatch(fetchClientsMissingNotificationsRecipients());
    }

    const haveNotSentCount = dashboardCounts?.notificationNotSentTransactionsCount !== undefined && dashboardCounts.notificationNotSentTransactionsCount > 0;
    
    return (
        <MainContentBox>
            <FullHeightStack rowGap={4} >
                <CardsContainer >
                    <CardItem item xs={10} md={4} lg={3} >
                        <DashboardCard
                            viewer={viewer} 
                            loading={isLoading} 
                            count={dashboardCounts?.errorsCount}
                            countColor='error'
                            title='Errors'
                            secondaryTitle='(Last 7 Days)'
                            navigatePath='/errorlogs/errors'
                        />
                    </CardItem>
                    <CardItem item xs={10} md={4} lg={3} >
                        <DashboardCard
                            viewer={viewer} 
                            loading={isLoading} 
                            count={dashboardCounts?.mappingErrorsCount}
                            countColor='error'
                            title='Mapping Errors'
                            secondaryTitle='(Last 7 Days)'
                            navigatePath='/errorlogs/map-errors'
                        />
                    </CardItem>
                    {canEdit && (
                    <CardItem item xs={10} md={4} lg={3} >
                        <OnboardingCard viewer={viewer} />
                    </CardItem>
                    )}
                    {canEdit && (
                    <CardItem item xs={10} md={4} lg={3} >
                        <ClientsMissingRecipientsCard viewer={viewer} />
                    </CardItem>
                    )}
                    {canEdit && (
                    <CardItem item xs={10} md={4} lg={3} >
                        <ClientsMissingBillingCard viewer={viewer} />
                    </CardItem>
                    )}
                    {haveNotSentCount && (
                    <CardItem item xs={10} md={4} lg={3} >
                        <DashboardCard
                            viewer={viewer} 
                            loading={isLoading} 
                            count={dashboardCounts?.notificationNotSentTransactionsCount}
                            title='Notifications Not Sent'
                            navigatePath='/archives/unsent'
                        />   
                    </CardItem>
                    )}
                    <CardItem item xs={10} md={4} lg={3} >
                        <DashboardCard
                            viewer={viewer} 
                            loading={isLoading} 
                            count={dashboardCounts?.unacknowledgedTransactionsCount}
                            title='Unacknowledged Transactions'
                            navigatePath='/archives/unacknowledged'
                        />  
                    </CardItem>
                    <CardItem item xs={10} md={4} lg={3} >
                        <DashboardCard
                            viewer={viewer} 
                            loading={isLoading} 
                            count={dashboardCounts?.rejectedTransactionsCount}
                            title='Rejected Transactions'
                            navigatePath='/archives/rejected'
                        />    
                    </CardItem>
                </CardsContainer>
                <TilesContainer >
                    <FavoritesRecentsTile viewer={viewer} loading={isLoading} />
                </TilesContainer>
            </FullHeightStack>
            <DashboardRefreshTool viewer={viewer} onRefresh={onRefresh} />
        </MainContentBox>
    );
};

export default Home;