import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';

import { GetPersonByEmailQuery, UserModel } from '../gql-types.generated';

const QUERY_USER_BY_EMAIL = gql`
    query getPersonByEmail($email: String!) {
        user (input: {email: $email}) {
            id
            firstName
            lastName
            email
            role
            recents {
                entityId
                entityType
                name
                lastViewed
                id
            }
            favorites {
                entityId
                entityType
                name
                id
            }
        }
    }
`;

export const queryUserByEmail = async (
    client: ApolloClient<NormalizedCacheObject>,
    email: string
): Promise<UserModel | undefined> => {
    //const headers = await getStandardHeaders();
    const { data, errors } = await client.query<GetPersonByEmailQuery>({
        variables: { email },
        query: QUERY_USER_BY_EMAIL,
        /*: {
            headers,
        },*/
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.user as UserModel;
};