
import { Button } from '@mui/material'
import NoResultsMessage from './NoResultsMessage';

/** Component for displaying when no records on list pages */

interface MessageProps {
    header?: string | undefined,
    message?: string | undefined,
    topMargin?: number,
    actionButtonClick?: () => void,
    actionButtonText?: string,
}

const NoRecordsMessage: React.FC<MessageProps> = (props) => {
    const { header, message, topMargin, actionButtonClick, actionButtonText = 'Add' } = props;
    
    let displayHeader = header ?? 'NO RECORDS FOUND';
    let displayMessage = message ?? 'Would you like to add a new one?';
    
    return (
        <NoResultsMessage header={displayHeader} message={displayMessage} topMargin={topMargin} >
            {(actionButtonClick) && 
                <Button
                    color="primary"
                    onClick={actionButtonClick}
                    aria-label={'Button ' + actionButtonText}
                >
                {actionButtonText}
                </Button>
            }
        </NoResultsMessage>
    )
};

export default NoRecordsMessage;