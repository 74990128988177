import { ChangeEvent, useEffect, useState } from "react";
import { Grid, SelectChangeEvent, TextField, styled } from "@mui/material";
import { UserRole } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import IsaQualifierField from "../fields/IsaQualifierField";

export const GridContainer = styled(Grid)((props) => ({
    width: '440px', 
}));

interface AkaDialogProps {
    isOpen: boolean;
    parentId: string;
    parentName: string;
    akaCode?: string;
    qualifierCode?: string;
    akaId?: string;
    viewerRole?: UserRole | undefined;
    onClose: () => void;
    onSave: (parentId: string, akaCode: string, isaQualifierCode: string, akaId?: string) => void;
    error?: Error | undefined;
}

const AkaDialog: React.FC<AkaDialogProps> = props => {
    const { isOpen, parentId, parentName, akaId, akaCode, qualifierCode, viewerRole, onClose, onSave, error } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [entityCode, setEntityCode] = useState('');
    const [isaQualifierCode, setIsaQualifierCode] = useState('');
    
    useEffect(() => {
        if (akaCode || qualifierCode) {
            setFromEntityAka();
        } else {
            setDefaults();
        }
    }, [akaCode, qualifierCode]);

    useEffect(() => {
        if (!isOpen) {
            setDefaults();
        } else {
            setFromEntityAka();
        }
    }, [isOpen]);

    const setFromEntityAka = () => {
        if (akaCode) {
            setEntityCode(akaCode);
        }
        if (qualifierCode) {
            setIsaQualifierCode(qualifierCode);
        }
    };

    const setDefaults = () => {
        setIsDirty(false);
        setSubmitted(false);
        setEntityCode('');
        setIsaQualifierCode('');
    };

    const saveEntityAka = () => {
        if (isDirty) {
            setSubmitted(true);
            onSave(parentId, entityCode, isaQualifierCode, akaId);
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        let requiredCheck = (isaQualifierCode?.trim().length > 0 && entityCode?.trim().length > 0);

        return isDirty && requiredCheck;
    };

    
    const onCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);
        setEntityCode(event.target.value);
    };
    const onQualifierChange = (event: SelectChangeEvent<string | null>) => {
        setIsDirty(true);
        setIsaQualifierCode(event.target.value ?? '');
    };

    const codeProps = {
        'aria-label': 'code',
        'maxLength': 50,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            titlePrefix={akaId ? "Edit" : "Add"}
            entityName={`Additional ${parentName} Code`}
            id={akaId}
            viewerRole={viewerRole}
            onCancel={onClose}
            onClose={onClose}
            onSave={saveEntityAka}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='lg'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-aka-code"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={entityCode}
                        label="Code"
                        inputProps={codeProps}
                        onChange={onCodeChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-aka-code"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <IsaQualifierField
                        disabled={submitted}
                        value={isaQualifierCode}
                        onChange={onQualifierChange}
                        required
                        data-cy="dialog-aka-qualifier"
                    />
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
}

export default AkaDialog;