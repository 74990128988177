
class FilteredItem {
    public Item: any;
    public Relevance: number;
    public Matching: Array<FilterMatch>;
    public Text: string;

    constructor(item: any) {
        this.Item = item;
        this.Matching = new Array<FilterMatch>();
        this.Text = ''
        this.Relevance = 0;
    }

    public CalculateRelevance() {
        let relevance = 0;
        this.Matching.forEach(function (matching) {
            if (matching.IsExactMatch) {
                relevance += 20
            }

            if (matching.WordPosition) {
                relevance += 6
            }

            if (matching.CharPosition) {
                relevance += 10
            }

            if (matching.SoundsLike) {
                relevance += 3
            }
        });
        this.Relevance = relevance;
    }
}
class FilterMatch {
    public IsMatch: boolean = false;
    public IsExactMatch: boolean = false;
    public Relevance: number = 0;
    public WordPosition: boolean = false;
    public CharPosition: boolean = false;
    public SoundsLike: boolean = false;
    public MatchingWord: string = '';
}

class TextFilter {
    private _filter: string;
    private _filterTerms: Array<string>;
    private _filterFields: Array<string>;
    private _threshold = 1;
    private _filteredItems: Array<FilteredItem>;

    constructor(filter: string, fields: Array<string>) {
        this._filter = filter?.toLowerCase().trim();
        this._filterTerms = [];
        if (this._filter) {
            this._filterTerms = this._filter.split(' ');
        }
        this._filterFields = fields;
        this._filteredItems = new Array<FilteredItem>();
    }

    public Filter(list: Array<any>): Array<any> {
        if (!this._filterTerms) {
            return list;
        }

        if (this._filter.length < this._threshold) {
            return list;
        }

        for (var i = 0; i < list.length; i++) {
            let filteredItem = undefined;
            let itemIsMatched = false;
            for (var termIndex = 0; termIndex < this._filterTerms.length; termIndex++) {
                filteredItem = this.ItemIsMatch(list[i], this._filterTerms[termIndex], termIndex);
                if (filteredItem?.Matching.length) {
                    itemIsMatched = true;
                }
            }

            if (itemIsMatched && filteredItem) {
                this._filteredItems.push(filteredItem);
                filteredItem.CalculateRelevance();
            }
        }

        this._filteredItems.sort((itemA, itemB) => {
            const sortOrder = itemB.Relevance - itemA.Relevance;
            if (sortOrder === 0) {
                return itemA.Text.localeCompare(itemB.Text);
            }
            return sortOrder;
        });

        let returnItems = new Array<any>();
        this._filteredItems.forEach(item => {
            returnItems.push(item.Item);
        });

        return returnItems;
    }

    private ItemIsMatch(item: any, searchTerm: string, termIndex: number): FilteredItem | undefined {

        let filteredItem = new FilteredItem(item);
        for (var i = 0; i < this._filterFields.length; i++) {
            const propertyName = this._filterFields[i];
            const value = item[propertyName as keyof typeof String];
            filteredItem.Text += value;

            let match = this.Contains(value, searchTerm, termIndex);
            if (match) {
                filteredItem.Matching.push(match);
            }

            match = this.SoundsLike(value, searchTerm);
            if (match) {
                filteredItem.Matching.push(match);
            }
        }

        return filteredItem;
    }

    private Contains(fieldValue: string, searchTerm: string, termIndex: number): FilterMatch | undefined {
        if (!fieldValue) return undefined;

        fieldValue = fieldValue.toLowerCase();
        const valueParts = fieldValue.split(' ');
        for (var i = 0; i < valueParts.length; i++) {
            let pos = valueParts[i].indexOf(searchTerm);
            if (pos >= 0) {
                const filterMatch = new FilterMatch();
                filterMatch.IsMatch = true;
                filterMatch.WordPosition = (termIndex === i);
                filterMatch.CharPosition = (pos === 0);
                filterMatch.MatchingWord = searchTerm;
                return filterMatch;
            }
        }

        return undefined;
    }

    private SoundsLike(fieldValue: string, searchTerm: string): FilterMatch | undefined {
        if (!fieldValue) return undefined;
        
        const searchTermSoundEx = this.GetSoundEx(searchTerm);
        const startSoundEx = this.GetProminentPart(searchTermSoundEx);

        const parts = fieldValue.split(' ');
        for (let i = 0; i < parts.length; i++) {
            var wordSoundex = this.GetSoundEx(parts[i]);
            if (wordSoundex === searchTermSoundEx) {
                const filterMatch = new FilterMatch();
                filterMatch.IsMatch = true;
                filterMatch.SoundsLike = true;
                filterMatch.MatchingWord = parts[i];
                return filterMatch;
            }

            // Check the soundex on part of the typed in word, as the user is typing
            // if only the first characters match we want to show the results
            if (wordSoundex.indexOf(startSoundEx) === 0) {
                const filterMatch = new FilterMatch();
                filterMatch.IsMatch = true;
                filterMatch.SoundsLike = true;
                return filterMatch;
            }
        }

        return undefined;
    }

    private GetSoundEx(value: string): string {
        const charArray = value.toLowerCase().split('');
        const firstChar = charArray.shift();
        const me = this;

        const soundCode = firstChar +
            charArray
                .map(function (value, index, charArray) {
                    // Create a new array, maping the original characters onto the character weighting codes
                    return me.GetSoundExCode(value)
                })
                .filter(function (value, index, charArray) {
                    // Check that t
                    return ((index === 0) ? value !== me.GetSoundExCode(firstChar) : value !== charArray[index - 1]);
                })
                .join('');

        return (soundCode + '000').slice(0, 4).toUpperCase();
    }

    private GetSoundExCode(value: string | undefined): string {
        switch (value) {
            case 'a':
            case 'e':
            case 'i':
            case 'o':
            case 'u': {
                return '';
            }

            case 'b':
            case 'f':
            case 'p':
            case 'v': {
                return '1';
            }

            case 'c':
            case 'g':
            case 'j':
            case 'k':
            case 'q':
            case 's':
            case 'x':
            case 'z': {
                return '2';
            }

            case 'd':
            case 't': {
                return '3';
            }

            case 'l': {
                return '4';
            }

            case 'm':
            case 'n': {
                return '5';
            }

            case 'r': {
                return '6';
            }
        }

        return ''
    }

    private GetProminentPart(soundex: string): string {
        var i = soundex.indexOf('0');
        if (i < 0) {
            return soundex;
        }

        return soundex.substring(0, i);
    }
}

const FilterArray = function name(list: Array<any>, filter: string, properties: Array<string>) {
    const filterHelper = new TextFilter(filter, properties);
    return filterHelper.Filter(list);
};

export default FilterArray;