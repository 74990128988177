import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { PagingResultModelOfPricingModel, RequestResult} from '../../gql-types.generated';
import { queryPricingById } from '../../gql/QueryPricingById';
import { captureUpsertPricingStatus, captureDeletePricingStatus, captureUpsertPricingItemStatus, captureDeletePricingItemStatus, fetchPricingByIdSuccess, fetchError } from './PricingDetailsSlice';
import { mutationUpsertPricing } from '../../gql/MutationUpsertPricing';
import { mutationUpsertPricingItem } from '../../gql/MutationUpsertPricingItem';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';

export const fetchPricingById =
    (id: string) =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const pricing = await queryPricingById(gqlClient, id);
                if (pricing) {
                    dispatch(fetchPricingByIdSuccess(pricing as PagingResultModelOfPricingModel));
                }
            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        };

export const deletePricing = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deletePricingStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "Pricing"
            });
            if (deletePricingStatus) {
                if (deletePricingStatus.result === RequestResult.Fail) {
                    getErrorMessage(deletePricingStatus.message as string, deletePricingStatus.errors, true, true);
                }
                dispatch(captureDeletePricingStatus(deletePricingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const upsertPricing = (description: string, currencyCode: string, minServiceFees: number, id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertPricingStatus = await mutationUpsertPricing(gqlClient, {
                description,
                currencyCode,
                minServiceFees,
                id,
            });
            if (upsertPricingStatus) {
                if (upsertPricingStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertPricingStatus.message } as Error));
                    getErrorMessage(upsertPricingStatus.message as string, upsertPricingStatus.errors, true, true);
                }
                dispatch(captureUpsertPricingStatus(upsertPricingStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertPricingItem = (
    pricingId: string,
    maxNumberOfTransactions: number,
    feePerDocument: number,
    feePerLine: number,
    id?: string,
): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertPricingItem(gqlClient, {
                pricingId,
                maxNumberOfTransactions,
                feePerDocument,
                feePerLine,
                id,
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertPricingItemStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deletePricingItem = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deletePricingItemStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "PricingItem"
            });
            if (deletePricingItemStatus) {
                if (deletePricingItemStatus.result === RequestResult.Fail) {
                    getErrorMessage(deletePricingItemStatus.message as string, deletePricingItemStatus.errors, true, true);
                }
                dispatch(captureDeletePricingItemStatus(deletePricingItemStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };



