import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { BusinessAreaModel, Maybe } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";

interface BusinessAreaDialogProps {
    isOpen: boolean;
    businessArea?: BusinessAreaModel | null | undefined;
    onClose: () => void;
    onSave: (name: string, description: string, id?: Maybe<string>) => void;
    error?: Error | undefined;
}

const BusinessAreaDialog: React.FC<BusinessAreaDialogProps> = props => {
    const { isOpen, businessArea, onClose, onSave, error } = props;
    const id = businessArea?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [name, setName] = useState<string>(''); 
    const [description, setDescription] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    
    useEffect(() => {
        // if have a businessArea, then populate for Edit
        if (businessArea){
            setName(businessArea.name);
            if (businessArea.description) {
                setDescription(businessArea.description);
            }
        }
        else {
            setName('');
            setDescription('');
        }
    },[businessArea]);

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setSubmitted(false);
            setName('');
            setDescription('');
            setIsFormDirty(false);
        }
    }, [isOpen]);

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        return isFormDirty && name?.trim().length > 0 && description?.trim().length > 0;
    };
    const submitForm = () => {
        setSubmitted(true);
        onSave(name, description, id);
    };

    // onChange handlers
    const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setName(event.target.value);
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const nameProps = {
        'aria-label': 'name',
        'maxLength': 50,
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isSubmitted={submitted}
            id={id} 
            entityName="Business Area" 
            onClose={onClose} 
            onSave={submitForm} 
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-business-area-name"
                        fullWidth
                        disabled={submitted}
                        autoFocus
                        value={name}
                        label="Name"
                        inputProps={nameProps}
                        onChange={onNameChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-business-area-name"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-business-area-description"
                        fullWidth
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        required
                        data-cy="dialog-business-area-description"
                        variant="standard"
                    />
                </Grid>
            </Grid>
                
        </AddEditDialog>
    );
};

export default BusinessAreaDialog;