import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    ClientPartnerModel,
    ClientPartnerTransactionModel,
    PartnerTransactionModel,
    AttachmentModel,
    DeleteByIdPayload,
    AddClientPartnerTransactionPayload,
    UpsertClientPartnerAttachmentPayload,
    PagingResultModelOfAttachmentModel,
    UpdateClientPartnerTransactionPayload,
    UpsertClientPartnerTransactionMapPayload,
    UpsertClientPartnerTransactionSchemaPayload,
    UpsertClientPartnerTransactionPostProcessingPayload,
    PostProcessingBlobModel,
    PagingResultModelOfPostProcessingBlobModel,
    UpsertClientPartnerTransactionAliasPayload,
    UpdateClientPartnerPayload
} from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    clientPartner?: ClientPartnerModel;
    clientPartnerTransactions?: ClientPartnerTransactionModel[];
    partnerTransactions?: PartnerTransactionModel[];
    clientPartnerAttachments?: AttachmentModel[];
    deleteClientPartnerStatus?: DeleteByIdPayload;
    addClientPartnerTransactionsStatus?: AddClientPartnerTransactionPayload;
    deleteClientPartnerTransactionStatus?: DeleteByIdPayload;
    upsertClientPartnerAttachmentsStatus?: UpsertClientPartnerAttachmentPayload;
    deleteClientPartnerAttachmentStatus?: DeleteByIdPayload;
    attachmentsPagingResult?: AttachmentModel[];
    updateClientPartnerTransactionStatus?: UpdateClientPartnerTransactionPayload;
    addTransactionMapToClientPartnerTransactionStatus?: UpsertClientPartnerTransactionMapPayload;
    deleteClientPartnerTransactionMapStatus?: DeleteByIdPayload;
    addPartnerTransactionSchemaToClientPartnerTransactionStatus?: UpsertClientPartnerTransactionSchemaPayload;
    deleteClientPartnerTransactionSchemaStatus?: DeleteByIdPayload;
    postProcessingBlobs?: PostProcessingBlobModel[];
    upsertClientPartnerTransactionPostProcessingStatus?: UpsertClientPartnerTransactionPostProcessingPayload;
    deleteClientPartnerTransactionPostProcessingStatus?: DeleteByIdPayload;
    upsertClientPartnerTransactionAliasStatus?: UpsertClientPartnerTransactionAliasPayload;
    deleteClientPartnerTransactionAliasStatus?: DeleteByIdPayload;
    updateClientPartnerStatus?: UpdateClientPartnerPayload;
}

const initialState: SliceState = {
    error: undefined,
    clientPartner: undefined,
    clientPartnerTransactions: undefined,
    partnerTransactions: undefined,
    clientPartnerAttachments: undefined,
    deleteClientPartnerStatus: undefined,
    addClientPartnerTransactionsStatus: undefined,
    deleteClientPartnerTransactionStatus: undefined,
    upsertClientPartnerAttachmentsStatus: undefined,
    deleteClientPartnerAttachmentStatus: undefined,
    attachmentsPagingResult: undefined,
    updateClientPartnerTransactionStatus: undefined,
    addTransactionMapToClientPartnerTransactionStatus: undefined,
    deleteClientPartnerTransactionMapStatus: undefined,
    addPartnerTransactionSchemaToClientPartnerTransactionStatus: undefined,
    deleteClientPartnerTransactionSchemaStatus: undefined,
    postProcessingBlobs: undefined,
    upsertClientPartnerTransactionPostProcessingStatus: undefined,
    deleteClientPartnerTransactionPostProcessingStatus: undefined,
    upsertClientPartnerTransactionAliasStatus: undefined,
    deleteClientPartnerTransactionAliasStatus: undefined,
    updateClientPartnerStatus: undefined
}

export const slice = createSlice({
    name: 'clientPartnerDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientPartner = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientPartnerTransactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.partnerTransactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.clientPartnerAttachments = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addClientPartnerTransactionsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerAttachmentsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerAttachmentStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateClientPartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addTransactionMapToClientPartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionMapStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addPartnerTransactionSchemaToClientPartnerTransactionStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionSchemaStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.postProcessingBlobs = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerTransactionPostProcessingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionPostProcessingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerTransactionAliasStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionAliasStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.updateClientPartnerStatus = undefined;
        },

        fetchClientPartnerByIdSuccess: (state, action: PayloadAction<ClientPartnerModel | undefined>) => {
            let clientPartner = action.payload;
            // eslint-disable-next-line no-param-reassign
            state.clientPartner = clientPartner;
            if (clientPartner) {
                if (clientPartner.clientPartnerTransactions) {
                    // eslint-disable-next-line no-param-reassign
                    state.clientPartnerTransactions = clientPartner.clientPartnerTransactions as ClientPartnerTransactionModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.clientPartnerTransactions = undefined;
                }

                if (clientPartner.partnerTransactions) {
                    // eslint-disable-next-line no-param-reassign
                    state.partnerTransactions = clientPartner.partnerTransactions as PartnerTransactionModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.partnerTransactions = undefined;
                }

                if (clientPartner.attachments) {
                    // eslint-disable-next-line no-param-reassign
                    state.clientPartnerAttachments = clientPartner.attachments as AttachmentModel[];
                } else {
                    // eslint-disable-next-line no-param-reassign
                    state.clientPartnerAttachments = undefined;
                }
            }
        },

        captureDeleteClientPartnerStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerStatus = action.payload;
        },

        captureAddClientPartnerTransactionsStatus: (state, action: PayloadAction<AddClientPartnerTransactionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addClientPartnerTransactionsStatus = action.payload;
        },

        captureDeleteClientPartnerTransactionStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionStatus = action.payload;
        },

        captureUpsertClientPartnerAttachmentsStatus: (state, action: PayloadAction<UpsertClientPartnerAttachmentPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerAttachmentsStatus = action.payload;
        },

        captureDeleteClientPartnerAttachmentStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerAttachmentStatus = action.payload;
        },

        fetchClientPartnerAttachmentSuccess: (state, action: PayloadAction<PagingResultModelOfAttachmentModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = action.payload.nodes as AttachmentModel[];
        },

        clearClientPartnerAttachment: state => {
            // eslint-disable-next-line no-param-reassign
            state.attachmentsPagingResult = undefined;
        },

        captureUpdateClientPartnerTransactionStatus: (state, action: PayloadAction<UpdateClientPartnerTransactionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateClientPartnerTransactionStatus = action.payload;
        },

        captureAddTransactionMapToClientPartnerTransactionStatus: (state, action: PayloadAction<UpsertClientPartnerTransactionMapPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addTransactionMapToClientPartnerTransactionStatus = action.payload;
        },

        captureDeleteClientPartnerTransactionMapStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionMapStatus = action.payload;
        },

        captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus: (state, action: PayloadAction<UpsertClientPartnerTransactionSchemaPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addPartnerTransactionSchemaToClientPartnerTransactionStatus = action.payload;
        },

        captureDeleteClientPartnerTransactionSchemaStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionSchemaStatus = action.payload;
        },

        fetchPostProcessingBlobsSuccess: (state, action: PayloadAction<PagingResultModelOfPostProcessingBlobModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.postProcessingBlobs = action.payload.nodes as PostProcessingBlobModel[];
        },

        captureUpsertClientPartnerTransactionPostProcessingStatus: (state, action: PayloadAction<UpsertClientPartnerTransactionPostProcessingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerTransactionPostProcessingStatus = action.payload;
        },

        captureDeleteClientPartnerTransactionPostProcessingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionPostProcessingStatus = action.payload;
        },
        captureUpsertClientPartnerTransactionAliasStatus: (state, action: PayloadAction<UpsertClientPartnerTransactionAliasPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertClientPartnerTransactionAliasStatus = action.payload;
        },

        captureDeleteClientPartnerTransactionAliasStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteClientPartnerTransactionAliasStatus = action.payload;
        },

        captureUpdateClientPartnerStatus: (state, action: PayloadAction<UpdateClientPartnerPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.updateClientPartnerStatus = action.payload;
        },

    }
});

export const selectError = (state: RootState): Error | undefined => state.clientPartnerDetails.error;
export const selectClientPartner = (state: RootState): ClientPartnerModel | undefined => state.clientPartnerDetails.clientPartner;
export const selectAttachments = (state: RootState): AttachmentModel[] | undefined => state.clientPartnerDetails.clientPartnerAttachments;
export const selectClientPartnerTransactions = (state: RootState): ClientPartnerTransactionModel[] | undefined => state.clientPartnerDetails.clientPartnerTransactions;
export const selectPartnerTransactions = (state: RootState): PartnerTransactionModel[] | undefined => state.clientPartnerDetails.partnerTransactions;
export const selectDeleteClientPartnerStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerStatus;
export const selectAddClientPartnerTransactionsStatus = (state: RootState): AddClientPartnerTransactionPayload | undefined => state.clientPartnerDetails.addClientPartnerTransactionsStatus;
export const selectDeleteClientPartnerTransactionStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerTransactionStatus;
export const selectUpsertClientPartnerAttachmentsStatus = (state: RootState): UpsertClientPartnerAttachmentPayload | undefined => state.clientPartnerDetails.upsertClientPartnerAttachmentsStatus;
export const selectDeleteClientPartnerAttachmentStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerAttachmentStatus;
export const selectAttachmentToDownload = (state: RootState): AttachmentModel[] | undefined => state.clientPartnerDetails.attachmentsPagingResult;
export const selectUpdateClientPartnerTransactionStatus = (state: RootState): UpdateClientPartnerTransactionPayload | undefined => state.clientPartnerDetails.updateClientPartnerTransactionStatus;
export const selectAddTransactionMapToClientPartnerTransactionStatus = (state: RootState): UpsertClientPartnerTransactionMapPayload | undefined => state.clientPartnerDetails.addTransactionMapToClientPartnerTransactionStatus;
export const selectDeleteTransactionMapFromClientPartnerTransactionStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerTransactionMapStatus;
export const selectAddPartnerTransactionSchemaToClientPartnerTransactionStatus = (state: RootState): UpsertClientPartnerTransactionSchemaPayload | undefined => state.clientPartnerDetails.addPartnerTransactionSchemaToClientPartnerTransactionStatus;
export const selectDeleteClientPartnerTransactionSchemaStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerTransactionSchemaStatus;
export const selectPostProcessingBlobs = (state: RootState): PostProcessingBlobModel[] | undefined => state.clientPartnerDetails.postProcessingBlobs;
export const selectUpsertClientPartnerTransactionPostProcessingStatus = (state: RootState): UpsertClientPartnerTransactionPostProcessingPayload | undefined => state.clientPartnerDetails.upsertClientPartnerTransactionPostProcessingStatus;
export const selectDeleteClientPartnerTransactionPostProcessingStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerTransactionPostProcessingStatus;
export const selectUpsertClientPartnerTransactionAliasStatus = (state: RootState): UpsertClientPartnerTransactionAliasPayload | undefined => state.clientPartnerDetails.upsertClientPartnerTransactionAliasStatus;
export const selectDeleteClientPartnerTransactionAliasStatus = (state: RootState): DeleteByIdPayload | undefined => state.clientPartnerDetails.deleteClientPartnerTransactionAliasStatus;
export const selectUpdateClientPartnerStatus = (state: RootState): UpdateClientPartnerPayload | undefined => state.clientPartnerDetails.updateClientPartnerStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchClientPartnerByIdSuccess,
    captureDeleteClientPartnerStatus,
    captureAddClientPartnerTransactionsStatus,
    captureDeleteClientPartnerTransactionStatus,
    captureUpsertClientPartnerAttachmentsStatus,
    captureDeleteClientPartnerAttachmentStatus,
    fetchClientPartnerAttachmentSuccess,
    clearClientPartnerAttachment,
    captureUpdateClientPartnerTransactionStatus,
    captureAddTransactionMapToClientPartnerTransactionStatus,
    captureDeleteClientPartnerTransactionMapStatus,
    captureAddPartnerTransactionSchemaToClientPartnerTransactionStatus,
    captureDeleteClientPartnerTransactionSchemaStatus,
    fetchPostProcessingBlobsSuccess,
    captureDeleteClientPartnerTransactionPostProcessingStatus,
    captureUpsertClientPartnerTransactionPostProcessingStatus,
    captureDeleteClientPartnerTransactionAliasStatus,
    captureUpsertClientPartnerTransactionAliasStatus,
    captureUpdateClientPartnerStatus
} = slice.actions;

export default slice.reducer;