import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ClientsOnboardingQuery, PagingResultModelOfClientModel, GetClientsInput, OrderDirection, ClientOrderByInput, ClientStatus } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENTS_MISSING_BILLING = gql`
  query clientsMissingBilling($input: GetClientsInput!) {
    clients(input: $input) {
      cursor {
        nextPage
        previousPage
      }
      nodes {
        name
        id
        statusCode
        lastModifiedBy
        lastModifiedByName
        lastModifiedTime
      }
    }
  }
`;

export const queryClientsMissingBilling = async (
    client: ApolloClient<NormalizedCacheObject>,
    after?: string | undefined,
    limit?: number | undefined,
): Promise<PagingResultModelOfClientModel | undefined> => {
    const order: ClientOrderByInput = { name: OrderDirection.Asc };
    // Get all active clients that do not have billing set (BillingId and/or BillingStartDate are null)
    const { data, errors } = await client.query<ClientsOnboardingQuery>({
        query: QUERY_CLIENTS_MISSING_BILLING,
        variables: {
            input: {
                after: after,
                limit: limit,
                order: order,
                hasBillingSet: false,
                statusCodes: [ClientStatus.Active],
            }
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.clients as PagingResultModelOfClientModel;
};