import { styled, FormControlLabel, Grid, Stack, Switch, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { ClientNotificationRecipientsModel, PartnerNotificationRecipientsModel, NotificationType, UpsertClientNotificationRecipientsInput, UpsertPartnerNotificationRecipientsInput, UserRole } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import { isEmailValid } from "../../util/Validation";

const EmailTypeIcon = styled(EmailIcon)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const SmsTypeIcon = styled(SmsIcon)(({ theme }) => ({
    color: theme.palette.primary.dark
}));

const TypeSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.secondary.main,
    },
    '& .Mui-checked': {
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.primary.dark,
        }
    },
    '& .MuiSwitch-switchBase': {
        '& + .MuiSwitch-track': {
            backgroundColor: 'rgba(0,0,0,.35) !important',
        },
    },
}));

interface NotificationRecipientsDialogProps {
    isOpen: boolean;
    viewerRole?: UserRole | undefined;
    notificationRecipients?: ClientNotificationRecipientsModel | PartnerNotificationRecipientsModel | undefined;
    onClose: () => void;
    onSave: (
        notificationType: NotificationType,
        description?: string,
        receivesErrors?: boolean,
        receivesNotifications?: boolean,
        recipients?: string,
        id?: string
    ) => void;
    error?: Error | undefined;
}

const NotificationRecipientsDialog: React.FC<NotificationRecipientsDialogProps> = props => {
    const { isOpen, viewerRole, notificationRecipients, onClose, onSave, error } = props;
    const id = notificationRecipients?.id;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    const [description, setDescription] = useState<string>('');
    const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.Email);
    const [receivesErrors, setReceivesErrors] = useState<boolean>(true);
    const [receivesNotifications, setReceivesNotifications] = useState<boolean>(true);
    const [contactInfo, setContactInfo] = useState<string>('');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    useEffect(() => {
        if (!isOpen) {
            // set submitted to false and clear fields when dialog is closed
            resetInitialState();
        } else {
            setFromDetails();
        }
    }, [isOpen]);

    useEffect(() => {
        // if have a map, then populate for Edit
        if (notificationRecipients) {
            setFromDetails();
        }
        else {
            resetInitialState();
        }
    }, [notificationRecipients]);

    const setFromDetails = () => {
        if (notificationRecipients) {
            if (notificationRecipients.description) {
                setDescription(notificationRecipients.description);
            }
            if (notificationRecipients.notificationType) {
                setNotificationType(notificationRecipients.notificationType);
            }
            if (typeof notificationRecipients.receivesErrors === "boolean") {
                setReceivesErrors(notificationRecipients.receivesErrors);
            }
            if (typeof notificationRecipients.receivesNotifications === "boolean") {
                setReceivesNotifications(notificationRecipients.receivesNotifications);
            }
            if (notificationRecipients.recipients) {
                setContactInfo(notificationRecipients.recipients);
            }
        }
    }

    const resetInitialState = () => {
        setSubmitted(false);
        setIsFormDirty(false);
        setDescription('');
        setNotificationType(NotificationType.Email);
        setReceivesErrors(true);
        setReceivesNotifications(true);
        setContactInfo('');
    }

    const submitForm = () => {
        setSubmitted(true);
        onSave(
            notificationType,
            description,
            receivesErrors,
            receivesNotifications,
            contactInfo,
            id
        );
    }

    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = description?.trim().length > 0 && contactInfo?.trim().length > 0;
        let emailCheck = notificationType === NotificationType.Email ? !emailInvalid : true;

        return emailCheck && isFormDirty && requiredCheck;
    }

    // onChange handlers
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };

    const onNotificationTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setNotificationType(event.target.checked ? NotificationType.Sms : NotificationType.Email);
        setContactInfo('');
    };

    const onReceivesErrorsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setReceivesErrors(event.target.checked);
    };

    const onReceivesNotificationsChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setReceivesNotifications(event.target.checked);
    };

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (notificationType === NotificationType.Email) {
            const isValid = isEmailValid(event.target.value);
            setEmailInvalid(!isValid);
            if (isValid) {
                setIsFormDirty(true);
                setEmailErrorText('');
            } else {
                setEmailErrorText('Invalid format.  Enter a valid email address');
            }
            setContactInfo(event.target.value);
        } else {
            setEmailInvalid(false);
            setEmailErrorText('');
        }
    };

    const onPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (notificationType === NotificationType.Sms) {
            let newVal = event.target.value;
            let isValid = true;
            if (newVal) {
                const phoneNumberRegex = "^[\\d_*./#()+-]+$";
                const match = newVal.match(phoneNumberRegex);
                // an invalid character was entered, so don't set the new value
                // this will make it look to user like that entry is not allowed
                // Doing this restriction rather than validating actual format
                // since different countries have different formatting rules
                if (!match) {
                    isValid = false;
                }
            }
            if (isValid) {
                setIsFormDirty(true);
                setContactInfo(newVal);
            }
        }
    };

    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    const typeProps = {
        'aria-label': 'Notification Type'
    };

    const errorsProps = {
        'aria-label': 'Receives Error Notifications'
    };

    const notificationProps = {
        'aria-label': 'Receives General Notifications'
    };

    const emailProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };

    const phoneNumberProps = {
        'aria-label': 'phoneNumber',
        'maxLength': 20,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName="Notification Recipient"
            maxWidth='sm'
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-notification-recipients-description"
                        fullWidth
                        autoFocus
                        disabled={submitted}
                        value={description}
                        label="Description"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        required
                        autoComplete="off"
                        data-cy="dialog-notification-recipients-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <EmailTypeIcon />
                        <Typography>Email</Typography>
                        <TypeSwitch
                            itemID="dialog-notification-recipients-type"
                            disabled={submitted}
                            checked={notificationType === NotificationType.Sms}
                            inputProps={typeProps}
                            onChange={onNotificationTypeChange}
                            data-cy="dialog-notification-recipients-type"
                        />
                        <Typography>SMS</Typography>
                        <SmsTypeIcon />
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    {notificationType === NotificationType.Email && (
                        <TextField
                            itemID="dialog-notification-recipients-email"
                            fullWidth
                            error={emailInvalid}
                            helperText={emailErrorText}
                            disabled={submitted}
                            value={contactInfo}
                            label="Email"
                            inputProps={emailProps}
                            onChange={onEmailChange}
                            required
                            autoComplete="off"
                            data-cy="dialog-notification-recipients-email"
                            variant="standard"
                        />)
                    }
                    {notificationType === NotificationType.Sms && (
                        <TextField
                            itemID="dialog-notification-recipients-sms"
                            fullWidth
                            disabled={submitted}
                            value={contactInfo}
                            label="Phone Number"
                            inputProps={phoneNumberProps}
                            onChange={onPhoneChange}
                            required
                            autoComplete="off"
                            data-cy="dialog-notification-recipients-sms"
                            variant="standard"
                        />)
                    }
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-notification-recipients-errors"
                                disabled={submitted}
                                checked={receivesErrors as boolean}
                                inputProps={errorsProps}
                                onChange={onReceivesErrorsChange}
                                data-cy="dialog-notification-recipients-errors"
                            />
                        }
                        label="Receives Error Notifications"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                itemID="dialog-notification-recipients-notifications"
                                disabled={submitted}
                                checked={receivesNotifications as boolean}
                                inputProps={notificationProps}
                                onChange={onReceivesNotificationsChange}
                                data-cy="dialog-notification-recipients-notifications"
                            />
                        }
                        label="Receives General Notifications"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
}

export default NotificationRecipientsDialog;