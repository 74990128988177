import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertEdiXmlConfigInput, UpsertEdiXmlConfigPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_EDI_XML_CONFIG = gql`
    mutation upsertEdiXmlConfig($input: UpsertEdiXmlConfigInput!){
        upsertEdiXmlConfig(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertEdiXmlConfig = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        upsertEdiXmlConfigData: UpsertEdiXmlConfigInput
    }
): Promise<UpsertEdiXmlConfigPayload | undefined> => {
    const { clientId, description, documentString, fileName, id } = args.upsertEdiXmlConfigData;
    const { data, errors } = await client.mutate<{ upsertEdiXmlConfig: UpsertEdiXmlConfigPayload }>({
        variables: {
            input: {
                clientId,
                description,
                documentString,
                fileName,
                id
            }
        },
        mutation: MUTATION_UPSERT_EDI_XML_CONFIG
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of EdiXmlConfig.');
    }
    if (data && data.upsertEdiXmlConfig) {
        return data.upsertEdiXmlConfig;
    }

    return undefined;
};