import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertClientAkaInput, UpsertClientAkaPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_AKA = gql`
    mutation upsertClientAKA($input: UpsertClientAkaInput!){
        upsertClientAka(input: $input){
            message
            errors {
                code
                message
            }
            result
            clientAKA {
                id
            }
        }
    }
`;

export const mutationUpsertClientAKA = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        clientAkaInputData: UpsertClientAkaInput
    }
): Promise<UpsertClientAkaPayload | undefined> => {
    const { clientId, clientCode, isaQualifierCode, id } = args.clientAkaInputData;
    const { data, errors } = await client.mutate<{ upsertClientAka: UpsertClientAkaPayload}>({
        variables: { 
            input: { 
                clientId, 
                clientCode, 
                isaQualifierCode, 
                id
            } 
        },
        mutation: MUTATION_UPSERT_CLIENT_AKA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of ClientAKA.');
    }
    if (data && data.upsertClientAka) {
        return data.upsertClientAka;
    }

    return undefined;
};