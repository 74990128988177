import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DeleteByIdPayload, PagingResultModelOfPostProcessingBlobModel, PostProcessingBlobModel, UpsertPostProcessingBlobPayload } from "../../gql-types.generated";
import { RootState } from "../../store";


interface SliceState {
    error?: Error;
    postProcessingBlobs?: PostProcessingBlobModel[];
    postPocessingblobPagingResult?: PagingResultModelOfPostProcessingBlobModel;
    upsertPostProcessingBlobStatus?: UpsertPostProcessingBlobPayload;
    deletePostProcessingBlobStatus?: DeleteByIdPayload;
    postProcessingblobsRequestsInFlight: number;
};

const initialState: SliceState = {
    error: undefined,
    postProcessingBlobs: undefined,
    postPocessingblobPagingResult: undefined,
    upsertPostProcessingBlobStatus: undefined,
    deletePostProcessingBlobStatus: undefined,
    postProcessingblobsRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'postProcessingBlobs',
    initialState,
    reducers:{
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },
        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },
        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.postProcessingBlobs = undefined;
            // eslint-disable-next-line no-param-reassign
            state.postPocessingblobPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPostProcessingBlobStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePostProcessingBlobStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.postProcessingblobsRequestsInFlight = 0;
        },
        fetchPostProcessingBlobsSuccess: (state, action: PayloadAction<PagingResultModelOfPostProcessingBlobModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.postPocessingblobPagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let blobs = state.postProcessingBlobs;
            if (!blobs || !state.postPocessingblobPagingResult.cursor?.previousPage) {
                blobs = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!blobs?.some(i => i.id === node.id)) {
                        blobs?.push(node as PostProcessingBlobModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.postProcessingBlobs = blobs;
        },
        incrementPostProcessingblobsRequestsInFlight: state => {            
            // eslint-disable-next-line no-param-reassign
            state.postProcessingblobsRequestsInFlight += 1;
        },
        decrementPostProcessingblobsRequestsInFlight: state => { 
            // eslint-disable-next-line no-param-reassign
            if (state.postProcessingblobsRequestsInFlight > 0) state.postProcessingblobsRequestsInFlight -= 1;
        },
        captureUpsertPostProcessingBlobStatus: (state, action: PayloadAction<UpsertPostProcessingBlobPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPostProcessingBlobStatus = action.payload;
        },
        captureDeletePostProcessingBlobStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePostProcessingBlobStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.postProcessingBlobs.error;
export const selectPostProcessingblobsRequestsInFlight = (state: RootState): number => state.postProcessingBlobs.postProcessingblobsRequestsInFlight;
export const selectPostProcessingblobs = (state: RootState): PostProcessingBlobModel[] | undefined => state.postProcessingBlobs.postProcessingBlobs;
export const selectPostProcessingblobsPagingResult = (state: RootState): PagingResultModelOfPostProcessingBlobModel | undefined => state.postProcessingBlobs.postPocessingblobPagingResult;
export const selectUpsertPostProcessingblobsStatus = (state: RootState): UpsertPostProcessingBlobPayload | undefined => state.postProcessingBlobs.upsertPostProcessingBlobStatus;
export const selectDeletePostProcessingblobsStatus = (state: RootState): DeleteByIdPayload | undefined => state.postProcessingBlobs.deletePostProcessingBlobStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchPostProcessingBlobsSuccess,
    incrementPostProcessingblobsRequestsInFlight,
    decrementPostProcessingblobsRequestsInFlight,
    captureUpsertPostProcessingBlobStatus,
    captureDeletePostProcessingBlobStatus
} = slice.actions;

export default slice.reducer;