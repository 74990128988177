import { useAppSelector } from "../../app/hooks";
import { MenuItem, FormControl, InputLabel, Select, SelectChangeEvent, styled } from "@mui/material";
import { BlankMenuItem } from "../../util/SharedStyles";
import { LocaleModel } from "../../gql-types.generated";
import { selectLocaleList } from "../../features/EDIContainer/EDIContainerSlice";

const DivEllipsis = styled('div')((props) => ({
    display: 'block',
    width: '340px',
    textOverflow: "ellipsis",
    overflow: 'hidden',
}));

interface LocaleFieldProps {
    disabled: boolean;
    required?: boolean;
    value?: string;
    onChange: (event: SelectChangeEvent<string | null>) => void;
}

const LocaleField: React.FC<LocaleFieldProps> = props => {
    const { disabled, required = false, value, onChange } = props;
    
    const locales = useAppSelector(selectLocaleList);

    const getLocaleOptions = () => {
        if (locales && locales.length) {
            let items = [];
            const blankItem = <BlankMenuItem key="0" value=""></BlankMenuItem>;
            const mappedItems = (
                locales.map((locale: LocaleModel) => (
                    <MenuItem
                        key={locale.localeCode}
                        value={locale.localeCode}
                    >
                        
                        <DivEllipsis>{`${locale.localeCode} : ${locale.description}`}</DivEllipsis>
                    </MenuItem>
                ))
            );
            items.push(blankItem);
            items.push(...mappedItems);
            return items;
        }
        return null;
    };
   
    const LocalesMenuProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
                width: '340px',
                display: 'block',
                textOverflow: "ellipsis", 
            },
        },
        display: 'block',
            textOverflow: "ellipsis", 
    };

    return (
        <FormControl variant="standard" fullWidth>
            <InputLabel id="locales-label" required={required}>Locale</InputLabel>
            <Select
                labelId="locales-label"
                aria-labelledby="locales-label"
                value={value}
                onChange={onChange}
                disabled={disabled}
                data-cy="locales-list"
                MenuProps={LocalesMenuProps}
                required={required}
            >
                {getLocaleOptions()}
            </Select>
        </FormControl>
    );
};

export default LocaleField;