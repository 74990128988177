import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertBusinessAreaPayload, Maybe } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_BUSINESS_AREA = gql`
    mutation upsertBusinessArea($input: UpsertBusinessAreaInput!){
        upsertBusinessArea(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertBusinessArea = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        name?: string;
        description?: string;
        id?: Maybe<string>;
    }
): Promise<UpsertBusinessAreaPayload | undefined> => {
    const { name, description, id } = options;
    const { data, errors } = await client.mutate<{ upsertBusinessArea: UpsertBusinessAreaPayload}>({
        variables: { input: { name, description, id } },
        mutation: MUTATION_UPSERT_BUSINESS_AREA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of business area.');
    }
    if (data && data.upsertBusinessArea) {
        return data.upsertBusinessArea;
    }

    return undefined;
};