import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteByIdPayload, PipelineModel, UpsertPipelineItemPayload, UpsertPipelinePayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    pipeline?: PipelineModel;
    upsertPipelineStatus?: UpsertPipelinePayload;
    upsertPipelineItemStatus?: UpsertPipelineItemPayload;
    deletePipelineItemStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    pipeline: undefined,
    upsertPipelineStatus: undefined,
    upsertPipelineItemStatus: undefined,
    deletePipelineItemStatus: undefined,
}

export const slice = createSlice({
    name: 'pipelineDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.pipeline = undefined;
        },

        fetchPipelineByIdSuccess: (state, action: PayloadAction<PipelineModel[] | undefined>) => {
            if (action.payload && action.payload.length && action.payload.length > 0) {
                // eslint-disable-next-line no-param-reassign
                state.pipeline = action.payload[0];
            } else {
                // eslint-disable-next-line no-param-reassign
                state.pipeline = undefined;
            }
        },

        captureUpsertPipelineStatus: (state, action: PayloadAction<UpsertPipelinePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPipelineStatus = action.payload;
        },

        captureUpsertPipelineItemStatus: (state, action: PayloadAction<UpsertPipelineItemPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPipelineItemStatus = action.payload;
        },

        captureDeletePipelineItemStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePipelineItemStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.pipelineDetails.error;
export const selectPipeline = (state: RootState): PipelineModel | undefined => state.pipelineDetails.pipeline;
export const selectUpsertPipelineStatus = (state: RootState): UpsertPipelinePayload | undefined => state.pipelineDetails.upsertPipelineStatus;
export const selectUpsertPipelineItemStatus = (state: RootState): UpsertPipelineItemPayload | undefined => state.pipelineDetails.upsertPipelineItemStatus;
export const selectDeletePipelineItemStatus = (state: RootState): DeleteByIdPayload | undefined => state.pipelineDetails.deletePipelineItemStatus;

export const {
    fetchError,
    clearError,
    clearState,
    fetchPipelineByIdSuccess,
    captureUpsertPipelineStatus,
    captureUpsertPipelineItemStatus,
    captureDeletePipelineItemStatus
} = slice.actions;

export default slice.reducer;