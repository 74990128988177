import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertCounterInput, UpsertCounterPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_COUNTER = gql`
    mutation upsertCounter($input: UpsertCounterInput!){
        upsertCounter(input: $input){
            message
            errors {
                code
                message
            }
            result
            counter {
                id
            }
        }
    }
`;

export const mutationUpsertCounter = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        counterInput: UpsertCounterInput
    }
): Promise<UpsertCounterPayload | undefined> => {
    const {
        id,
        segment,
        senderId,
        controlNumber
    } = args.counterInput;
    const { data, errors} = await client.mutate<{ upsertCounter: UpsertCounterPayload}>({
        variables: {
            input: {
                id,
                segment,
                senderId,
                controlNumber
            }
        },
        mutation: MUTATION_UPSERT_COUNTER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the upsert Counter save.');
    }
    if (data && data.upsertCounter) {
        return data.upsertCounter;
    }
    return undefined;
}