import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertErpTransactionSchemaInput, UpsertErpTransactionSchemaPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_ERP_TRANSACTION_SCHEMA = gql`
    mutation upsertErpTransactionSchema($input: UpsertErpTransactionSchemaInput!){
        upsertErpTransactionSchema(input: $input){
            message
            errors {
                code
                message
            }
            result
            transactionSchemaId
        }
    }
`;

export const mutationUpsertErpTransactionSchema = async (
    client: ApolloClient<NormalizedCacheObject>, args: {
        upsertErpTransactionSchemaData: UpsertErpTransactionSchemaInput
    }
): Promise<UpsertErpTransactionSchemaPayload | undefined> => {
    const { description, documentString, id, transactionId, sourceFileName } = args.upsertErpTransactionSchemaData;
    const { data, errors } = await client.mutate<{ upsertErpTransactionSchema: UpsertErpTransactionSchemaPayload }>({
        variables: {
            input: {
                description,
                documentString,
                id,
                transactionId,
                sourceFileName
            }
        },
        mutation: MUTATION_UPSERT_ERP_TRANSACTION_SCHEMA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the ERP Transaction Schema save.');
    }
    if (data && data.upsertErpTransactionSchema) {
        return data.upsertErpTransactionSchema;
    }

    return undefined;
}