import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertBillingPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_BILLING = gql`
    mutation upsertBilling($input: UpsertBillingInput!){
        upsertBilling(input: $input){
            message
            errors {
                code
                message
            }
            result
            billing {
                id
            }
        }
    }
`;

export const mutationUpsertBilling = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        currencyCode?: string;
        description?: string;
        id?: string;
    }
): Promise<UpsertBillingPayload | undefined> => {
    const { currencyCode, description, id } = options;
    const { data, errors } = await client.mutate<{ upsertBilling: UpsertBillingPayload}>({
        variables: { 
            input: { 
                currencyCode, 
                description, 
                id 
            } 
        },
        mutation: MUTATION_UPSERT_BILLING
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of Billing.');
    }
    if (data && data.upsertBilling) {
        return data.upsertBilling;
    }

    return undefined;
};