import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionModel, DeleteTransactionPayload, UpsertTransactionPayload, DeleteByIdPayload, UpsertTransactionXPathPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    xPathError?: Error;
    transaction?: TransactionModel;
    transactionList?: TransactionModel[];
    upsertTransactionStatus?: UpsertTransactionPayload;
    deleteTransactionStatus?: DeleteTransactionPayload;
    transactionRequestsInFlight: number;
    upsertTransactionXPathStatus?: UpsertTransactionXPathPayload;
    deleteTransactionXPathStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    xPathError: undefined,
    transaction: undefined,
    transactionList: undefined,
    upsertTransactionStatus: undefined,
    deleteTransactionStatus: undefined,
    transactionRequestsInFlight: 0,
    upsertTransactionXPathStatus: undefined,
    deleteTransactionXPathStatus: undefined    
};

export const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        fetchXPathError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.xPathError = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.xPathError = undefined;
        },

        clearXPathError: state => {
            // eslint-disable-next-line no-param-reassign
            state.xPathError = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.xPathError = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transaction = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactionList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactionRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.upsertTransactionXPathStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteTransactionXPathStatus = undefined;
        },

        fetchTransactionByIdSuccess: (state, action: PayloadAction<TransactionModel | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.transaction = action.payload;
        },

        fetchTransactionListSuccess: (state, action: PayloadAction<TransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactionList = action.payload;
        },

        captureUpsertTransactionStatus: (state, action: PayloadAction<UpsertTransactionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertTransactionStatus = action.payload;
        },

        captureDeleteTransactionStatus: (state, action: PayloadAction<DeleteTransactionPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteTransactionStatus = action.payload;
        },

        incrementTransactionRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.transactionRequestsInFlight += 1;
        },

        decrementTransactionRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.transactionRequestsInFlight > 0) state.transactionRequestsInFlight -= 1;
        },

        captureUpsertTransactionXPathStatus: (state, action: PayloadAction<UpsertTransactionXPathPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertTransactionXPathStatus = action.payload;
        },

        captureDeleteTransactionXPathStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteTransactionXPathStatus = action.payload;
        }
    },
});

export const selectError = (state: RootState): Error | undefined => state.transactions.error;
export const selectXPathError = (state: RootState): Error | undefined => state.transactions.xPathError;
export const selectTransaction = (state: RootState): TransactionModel | undefined => state.transactions.transaction;
export const selectTransactionList = (state: RootState): TransactionModel[] | undefined => state.transactions.transactionList;
export const selectUpsertTransactionStatus = (state: RootState): UpsertTransactionPayload | undefined => state.transactions.upsertTransactionStatus;
export const selectDeleteTransactionStatus = (state: RootState): DeleteTransactionPayload | undefined => state.transactions.deleteTransactionStatus;
export const selectRequestsInFlight = (state: RootState): number => state.transactions.transactionRequestsInFlight;
export const selectUpsertTransactionXPathStatus = (state: RootState): UpsertTransactionXPathPayload | undefined => state.transactions.upsertTransactionXPathStatus;
export const selectDeleteTransactionXPathStatus = (state: RootState): DeleteByIdPayload | undefined => state.transactions.deleteTransactionXPathStatus;

export const {
    clearError,
    clearXPathError,
    fetchError,
    fetchXPathError,
    clearState,
    fetchTransactionByIdSuccess,
    fetchTransactionListSuccess,
    captureUpsertTransactionStatus,
    captureDeleteTransactionStatus,
    incrementTransactionRequestsInFlight,
    decrementTransactionRequestsInFlight,
    captureUpsertTransactionXPathStatus,
    captureDeleteTransactionXPathStatus
} = slice.actions;

export default slice.reducer;