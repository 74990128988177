import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { BillingAggregatesModel, GetBillingAggregatesInput, GetClientBillingAggregatesByClientKeyQuery, Scalars } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_CLIENT_BILLING_AGGREGATES_BY_CLIENT_KEY = gql`
    query getClientBillingAggregatesByClientKey($input: GetBillingAggregatesInput!) {
        clientBillingAggregates(input: $input) {
            nodes {
                id
                internalClientCode
                billingId
                year
                month
                serviceFee
                totalCharges
                totalDocuments
                totalLines
                lineCharges
                documentCharges
                currencyCode
                createdBy
                createdTime
                lastModifiedBy
                lastModifiedTime
            }
        }
    }
`;

export const queryClientBillingAggregatesByClientKey = async (
    client: ApolloClient<NormalizedCacheObject>,
    internalClientCode: string
): Promise<BillingAggregatesModel[] | undefined> => {
    const { data, errors } = await client.query<GetClientBillingAggregatesByClientKeyQuery>({
        variables: {
            input: {
                internalClientCode
            }
        },
        query: QUERY_CLIENT_BILLING_AGGREGATES_BY_CLIENT_KEY,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }
    return data?.clientBillingAggregates?.nodes as BillingAggregatesModel[];
}