import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteByIdPayload, UpsertPricingPayload, ClonePricingPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    upsertPricingStatus?: UpsertPricingPayload;
    deletePricingStatus?: DeleteByIdPayload;
    clonePricingStatus?: ClonePricingPayload;
}

const initialState: SliceState = {
    error: undefined,
    upsertPricingStatus: undefined,
    deletePricingStatus: undefined,
    clonePricingStatus: undefined,
};

export const slice = createSlice({
    name: 'pricings',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertPricingStatus: (state, action: PayloadAction<UpsertPricingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingStatus = action.payload;
        },

        captureDeletePricingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePricingStatus = action.payload;
        },

        captureClonePricingStatus: (state, action: PayloadAction<ClonePricingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.clonePricingStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.pricings.error;
export const selectUpsertPricingStatus = (state: RootState): UpsertPricingPayload | undefined => state.pricings.upsertPricingStatus;
export const selectDeletePricingStatus = (state: RootState): DeleteByIdPayload | undefined => state.pricings.deletePricingStatus;
export const selectClonePricingStatus = (state: RootState): ClonePricingPayload | undefined => state.pricings.clonePricingStatus;

export const {
    clearError,
    fetchError,
    captureUpsertPricingStatus,
    captureDeletePricingStatus,
    captureClonePricingStatus
} = slice.actions;

export default slice.reducer;