import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { PagingResultModelOfClientModel, ClientStatus, RequestResult, UpsertClientInput } from '../../gql-types.generated';
import { queryClientList } from '../../gql/QueryClientsList';
import { mutationUpsertClient } from '../../gql/MutationUpsertClient';
import { fetchError, fetchClientListSuccess, fetchClientNameSearchResultsSuccess, incrementClientRequestsInFlight, decrementClientRequestsInFlight, captureUpsertClientStatus } from './ClientsSlice';
import { getErrorMessage } from '../../util/Common';
import { ApolloError } from '@apollo/client';

export const fetchClientList =
    (after: string | undefined, pageSize: number | undefined, name?: string | undefined, businessAreaIds?: string[] | undefined, productIds?: string[] | undefined, statuses?: string[] | undefined, city?: string | undefined) =>
        async (dispatch: AppDispatch): Promise<void> => {
            dispatch(incrementClientRequestsInFlight());
            try {
                // convert status string array back into ClientStatus enum values for correct query type
                let clientStatuses: ClientStatus[] = [];
                if (statuses && statuses.length > 0) {
                    statuses.forEach(status => {
                        if (status in ClientStatus) {
                            clientStatuses.push(status.toUpperCase() as ClientStatus);
                        }
                    });
                }

                const clientList = await queryClientList(gqlClient, after, pageSize, { name, businessAreaIds, productIds, clientStatuses, city });
                if (clientList) {
                    dispatch(fetchClientListSuccess(clientList as PagingResultModelOfClientModel));
                }
                dispatch(decrementClientRequestsInFlight());
            } catch (e) {
                dispatch(fetchError(e as ApolloError));
                dispatch(decrementClientRequestsInFlight());
            }
        };

export const upsertClient = (clientInput: UpsertClientInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertClientStatus = await mutationUpsertClient(gqlClient, {
                clientInput
            });
            if (upsertClientStatus) {
                if (upsertClientStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertClientStatus.message } as Error));
                    getErrorMessage(upsertClientStatus.message as string, upsertClientStatus.errors, true, true);
                }
                dispatch(captureUpsertClientStatus(upsertClientStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchClientNameSearchResults = (name: string | undefined) =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            if (name) {
                // use existing client list query passing the name for the search and limit to 8 results
                const clientList = await queryClientList(gqlClient, undefined, 8, { name });
                if (clientList) {
                    dispatch(fetchClientNameSearchResultsSuccess(clientList as PagingResultModelOfClientModel));
                }
            } else {
                // dispatch with undefined result to clear out results
                dispatch(fetchClientNameSearchResultsSuccess(undefined));
            }
        } catch (e) {
            dispatch(fetchError(e as ApolloError));
        }
    };
