import { ChangeEvent, useEffect, useState } from "react";
import { Grid, TextField, styled } from "@mui/material";
import { ClientPartnerModel, UpdateClientPartnerInput } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";

export const GridContainer = styled(Grid)((props) => ({
    width: '440px', 
}));

interface ClientPartnerDialogProps {
    isOpen: boolean;
    clientPartnerInfo?: ClientPartnerModel;
    onClose: () => void;
    onSave: (
        clientPartnerInput: UpdateClientPartnerInput
    ) => void;
    error?: Error | undefined;
}

const EditClientPartnerDialog: React.FC<ClientPartnerDialogProps> = props => {
    const { isOpen, clientPartnerInfo, onClose, onSave, error } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [clientPartnerId, setClientPartnerId] = useState<string>('');
    // Form Fields
    const [currencyCode, setCurrencyCode] = useState<string | undefined>(undefined);
      
    useEffect(() => {
        if (clientPartnerInfo) {
            setFromClientPartnerInfo();
        } else {
            setToDefaults();
        }
    }, [clientPartnerInfo]);

    const setFromClientPartnerInfo = () => {
        if (clientPartnerInfo) {
            if (clientPartnerInfo.id) {
                setClientPartnerId(clientPartnerInfo.id);
            }

            if (clientPartnerInfo.defaultCurrencyCode) {
                setCurrencyCode(clientPartnerInfo.defaultCurrencyCode);
            }
        }
    };

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
            setSubmitted(false);
            setIsDirty(false);
        } else {
            setFromClientPartnerInfo();
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setClientPartnerId('');
        setCurrencyCode(undefined);
    };
    
    const saveClientPartner = () => {
        if (isDirty) {
            setSubmitted(true);
            onSave({clientPartnerId: clientPartnerId, defaultCurrencyCode: currencyCode});
        }
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        return isDirty;
    };
    
    const onCurrencyCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsDirty(true);

        if (event.target.value) {
            setCurrencyCode(event.target.value?.toUpperCase());
        } else {
            setCurrencyCode(undefined);
        }
    };
    
    const currencyCodeProps = {
        'aria-label': 'default currency code',
        'maxLength': 3,
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            entityName="Client Partner"
            id={clientPartnerId}
            onCancel={onClose}
            onClose={onClose}
            onSave={saveClientPartner}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='md'
        >
            <GridContainer container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-client-partner-default-currency-code"
                        autoFocus
                        fullWidth
                        disabled={submitted}
                        value={currencyCode ?? ''}
                        label="Default Currency Code"
                        inputProps={currencyCodeProps}
                        onChange={onCurrencyCodeChange}
                        autoComplete="off"
                        data-cy="dialog-client-partner-default-currency-code"
                        variant="standard"
                        onFocus={event => {
                            // highlight value for easy editing
                            event.target.select();
                        }}
                    />
                </Grid>
            </GridContainer>
        </AddEditDialog>
    );
}

export default EditClientPartnerDialog;