import { useState } from 'react';
import { Divider, Grid, IconButton, Skeleton, Menu, MenuItem, Typography } from '@mui/material';
import { PricingModel } from '../../gql-types.generated';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CardChip, CardFooter, RootCardHeader, ClickableRootCard } from '../../util/SharedStyles';

interface PricingListItemProps {
    loading?: boolean | false;
    pricing?: PricingModel | undefined;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    onCloneClick: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const PricingListItem: React.FC<PricingListItemProps> = (props: PricingListItemProps) => {
    const { pricing, loading = false, clickAction } = props;
    const minServiceFees = pricing?.minServiceFees;
    const description = pricing?.description;
    const currencyCode = pricing?.currencyCode;
    const id = pricing?.id as string;
    const billingItemCount = pricing?.billingItemCount;
  
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (!loading) {
            clickAction(id)
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();
        
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(id);
        }
        else if (buttonDataSet.operation === "clone")
        {
            props.onCloneClick(id);
        }
        
        setAnchorEl(null);
    };

    let action = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
       description
    );
    
    let currencyChip;
    if (currencyCode) {
        currencyChip = <CardChip label={currencyCode} />
    }
    
    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={title} 
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light/>
            <CardFooter justify='space-between'>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                        <Typography>Minimum Service Fees: {minServiceFees.toFixed(2)}</Typography>
                        <Grid item xs={'auto'}>
                            {currencyChip}
                        </Grid>  
                        </>
                    )
                }
            </CardFooter>
           
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    disabled={billingItemCount && billingItemCount > 0 ? true : false}
                    >
                    Delete    
                </MenuItem>
                <MenuItem
                    data-operation="clone"
                    onClick={handleMenuClose}
                    >
                    Clone    
                </MenuItem>
            </Menu> 
        </ClickableRootCard>
    );
};

export default PricingListItem;