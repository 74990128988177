import { useState } from 'react';
import { Divider, Grid, IconButton, Skeleton, Menu, MenuItem, Typography } from '@mui/material';
import { BillingModel } from '../../gql-types.generated';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PricingIcon from "@mui/icons-material/ReceiptLong";
import { CardChip, CardFooter, RootCardHeader, ClickableRootCard } from '../../util/SharedStyles';

interface BillingListItemProps {
    loading?: boolean | false;
    billingItem?: BillingModel | undefined;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
    clickAction: (id: string | undefined) => void;
}

const BillingListItem: React.FC<BillingListItemProps> = (props: BillingListItemProps) => {
    const { billingItem, loading = false, clickAction } = props;
    const currencyCode = billingItem?.currencyCode;
    const description = billingItem?.description;
    const id = billingItem?.id as string;
    const pricingCount = billingItem?.billingItemCount;
    const clientCount = billingItem?.clientCount ?? 0;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const cardClick = () => {
        if (!loading) {
            clickAction(id)
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();

        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        // the menu button is inside of a card action area that has a click event,
        // stopPropagation to prevent that click event from firing
        event.stopPropagation();
        
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(id);
        }
        
        setAnchorEl(null);
    };

    let action = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
       description
    );

    let pricingContainerJustify = "flex-end";
    let pricingElement = null;
    if (pricingCount && pricingCount > 0) {
        pricingContainerJustify = "space-between";
        pricingElement = (
            <Grid container item direction="row" gap={'4px'} justifyContent="flex-start" alignItems="center" xs={'auto'}>
                <PricingIcon fontSize='small' />
                <Typography>Pricing: {pricingCount}</Typography>
            </Grid>
        )
    }
    
    let currencyChip;
    if (currencyCode) {
        currencyChip = <CardChip label={currencyCode} />
    }
    let currencyElement = (
        <Grid item xs={'auto'}>
            {currencyChip}
        </Grid>
    )

    return (
        <ClickableRootCard onClick={cardClick} disabled={loading}>
            <RootCardHeader
                title={title} 
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Divider light/>
            <CardFooter justify={pricingContainerJustify}>
                {
                    loading ? (
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Skeleton animation="wave" height={24} width="30%" />
                            <Skeleton variant="text" animation="wave" height={32} width={92} />
                        </Grid>
                    ) : (
                        <>
                            {pricingElement}                        
                            {currencyElement}
                        </>
                    )
                }
            </CardFooter>
           
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    disabled={clientCount > 0}
                    >
                    Delete    
                </MenuItem>
            </Menu> 
        </ClickableRootCard>
    );
};

export default BillingListItem;