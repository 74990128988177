import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { capitalize } from 'lodash';
import { CircularProgress, Tooltip, styled } from '@mui/material';
import { MainContentBox, DataGridListScrollBox, PageTitleToolbarGrid, MainDataGridNoRowHover } from '../../util/SharedStyles';
import { GridActionsCellItem, GridRenderCellParams, GridColumns, GridRowModel, GridOverlay, GridSortModel, GridRowParams, useGridApiContext, GridSortItem, GridSelectionModel, GridSortDirection } from '@mui/x-data-grid-pro';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { OrderDirection, GetUsersInput, UserOrderByInput, UserModel, UserRole, RequestResult, Maybe } from '../../gql-types.generated';
import { 
    clearState,
    clearError, 
    selectError, 
    selectDeleteUserStatus, 
    selectUpsertUserStatus, 
    selectRequestsInFlight, 
    selectUserList, 
    selectUsersPagingResult,
    captureDeleteUserStatus, 
    captureUpsertUserStatus 
} from './UsersSlice';
import { deleteUserData, fetchUserList, upsertUserData } from './UsersActions';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';

import { Viewer, defaultPageSize, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete, viewerCanEdit } from '../../util/ViewerUtility'
import { useTitle } from '../../util/Common';

import PageTitleBar from '../../components/PageTitleBar';
import UsersFilterBar from '../../components/filters/UsersFilterBar';
import FiltersButton from '../../components/buttons/FiltersButton';
import UserDialog from '../../components/dialogs/UserDialog';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import NoResultsMessage from '../../components/NoResultsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import DeleteDialog from '../../components/dialogs/DeleteDialog';

const ActiveStatusCellDiv = styled('div')(({ theme }) => ({
    color: theme.palette.success.light
}));

interface UserListProps {
    // Search text applied from the global search
    searchText: string;
    viewer: Viewer | undefined;
}

const Users: React.FC<UserListProps> = props => {
    const { user } = useAuth0<{ email: string }>();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [contentAreaHeight, setContentAreaHeight] = useState('200px'); //need an actual default height so grid doesn't error on load
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState<undefined | UserModel>(undefined);
    const [filterFirstName, setFilterFirstName] = useState<string | undefined>(undefined);
    const [filterLastName, setFilterLastName] = useState<string | undefined>(undefined);
    const [filterEmail, setFilterEmail] = useState<string | undefined>(undefined);
    const [filterRole, setFilterRole] = useState<UserRole | undefined>(undefined);
    const [filterIsActive, setFilterIsActive] = useState<boolean | undefined>(undefined);
    const [filterCount, setFilterCount] = useState(0);
    const [userRows, setUserRows] = useState<GridRowModel[] | undefined>(undefined);
    const [sortFirstLoad, setSortFirstLoad] = useState(true);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'lastName',
            sort: 'asc',
        },
    ]);
    const [serverSortModel, setServerSortModel] = useState<UserOrderByInput[] | undefined>([{
        lastName: OrderDirection.Asc
    } as UserOrderByInput]);

    const upsertUserStatus = useAppSelector(selectUpsertUserStatus);
    const deleteUserStatus = useAppSelector(selectDeleteUserStatus);
    const error = useAppSelector(selectError);
    const usersPagingResult = useAppSelector(selectUsersPagingResult);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const users = useAppSelector(selectUserList);

    const pageSize = defaultPageSize;
    
    const viewer = props.viewer;
    const canEdit = viewerCanEdit(viewer);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Users");

    useEffect(() => {
        // fetch userlist when a successful mutation occurs
        if (upsertUserStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertUserStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // Remove upsert status.
            dispatch(captureUpsertUserStatus());
            // close the modify dialog and refresh list
            onUserDialogClose();
        }
        if (deleteUserStatus?.result === RequestResult.Success) {
            // close the delete dialog and refresh list
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteUserStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        if (deleteUserStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteUserStatus.message as string);
        }
    }, [upsertUserStatus?.result, deleteUserStatus?.result]);
    
    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('users-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('users-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [users, filtersOpen]);

    useEffect(() => {
        // make sure users has loaded before we make additional calls
        if (users) {
            // keep track of the number of filters to know if
            // should display badge in Filters button
            let numFilters = 0;
            if (filterFirstName && filterFirstName.length > 0) {
                numFilters += 1;
            }
            if (filterLastName && filterLastName.length > 0) {
                numFilters += 1;
            }
            if (filterEmail && filterEmail.length > 0) {
                numFilters += 1;
            }
            if (filterRole && filterRole.length > 0) {
                numFilters += 1;
            }
            if (filterIsActive === true) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [users, filterFirstName, filterLastName, filterEmail, filterRole, filterIsActive]);
     
    useEffect(() => {
        if (!sortFirstLoad) {
            const sortRefreshData = getRefreshInput(undefined);
            onSortModelChanged(sortRefreshData);
        } else {
            setSortFirstLoad(false);
        }
    }, [serverSortModel]);

    useEffect(() => {
        setUserRows(getUserRows());

        if (!users && requestsInFlight > 0) {
            setIsLoading(true);
        }
        else {
            setIsLoading(false);
        }
    }, [users, requestsInFlight]);

    const getSelectedRowUser = useCallback((rowId: string) => () => {
        if (rowId && users?.length) {
            let user = users.find(user => user.id === rowId);
            return user;
        }
        return undefined;
    }, [users]);

    const deleteProcessHandler = useCallback((rowId: string) => () => {
        let user = getSelectedRowUser(rowId);
        if (user) {
            setSelectedUser(user);
            dispatch(captureDeleteUserStatus());
            setDeleteErrorMessage('');
            setOpenDelete(true);
        }
    }, [getSelectedRowUser, dispatch]);

    const editProcessHandler = useCallback((rowId: string) => () => {
        let user = getSelectedRowUser(rowId);
        if (user) {
            setSelectedUser(user);
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowUser, dispatch]);
    
    const getRefreshInput = (endEdge: string | undefined) => {
        return {
            after: endEdge,
            limit: pageSize,
            order: serverSortModel,
            firstName: filterFirstName,
            lastName: filterLastName,
            email: filterEmail,
            role: filterRole,
            isActive: filterIsActive,
        } as GetUsersInput
    };

    const loadPage = (endEdge: string | undefined) => {
        let input = getRefreshInput(endEdge);
        dispatch(fetchUserList(input));
    };
    
    const handlePageLoad = () => {
        if (!usersPagingResult) {
            return;
        }
        if (!usersPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(usersPagingResult.cursor.nextPage);
    };

    const getUserRows = () => {
        if (users && users.length > 0) {
            return users.map((user) => {
                const { id, firstName, lastName, email, role, isActive } = user;

                return {
                    _raw: user,
                    id,
                    firstName,
                    lastName,
                    email,
                    role,
                    isActive
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    };

    const userColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'FIRST NAME',
                field: 'firstName',
                flex: 2,
                sortable: true,
                cellClassName: "ediDataGridCellFirstChild ediDataGridWrapCellContent-alwaysLeft"
            }, {
                headerName: 'LAST NAME',
                field: 'lastName',
                flex: 2,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent",
            }, {
                headerName: 'EMAIL',
                field: 'email',
                flex: 3,
                sortable: true,
                cellClassName: "ediDataGridWrapCellContent",
            }, {
                headerName: 'ROLE',
                field: 'role',
                sortable: true,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    const displayValue = capitalize(value);
                    return (displayValue);
                },
            }, {
                headerName: 'STATUS',
                field: 'isActive',
                type: 'boolean',
                sortable: true,
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    if (value) {
                        return <ActiveStatusCellDiv>Active</ActiveStatusCellDiv>
                    }
                    return ("Inactive");
                },
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editProcessHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                        label="Delete"
                        color="error"
                        hidden={!canAddOrDelete}
                        onClick={deleteProcessHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editProcessHandler, deleteProcessHandler, canEdit, canAddOrDelete],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Users' error={error}></ErrorMessage>
                )}
                {!error && (users && users.length > 0 && requestsInFlight > 0) && (
                    <CircularProgress aria-label={'progress spinner'} key={'logs-spinner'} size={42} sx={{ zIndex: 1 }} />
                )}
                {!error && (!users) && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the Users' error={error}></ErrorMessage>
                )}
                {!error && (users && users.length <= 0 && filterCount === 0) && canAddOrDelete && (
                    <NoRecordsMessage topMargin={6} actionButtonText="Add New User" actionButtonClick={onAddUserClick} />
                )}
                {!error && (users && users.length <= 0 && filterCount === 0) && !canAddOrDelete && (
                    <NoRecordsMessage topMargin={6} />
                )}
                {!error && (users && users.length <= 0 && filterCount > 0) && (
                    <NoResultsMessage topMargin={6} />
                )}
            </GridOverlay>
        );
    };
    
    const onAddUserClick = () => {
        // Clear error 
        dispatch(clearError());
        // ensure no previously selected user is set
        if (selectedUser) {
            setSelectedUser(undefined);
        }
        setOpenModify(true);
    };

    const onDeleteUserConfirm = (userId: string) => {
        dispatch(deleteUserData(userId));
    };

    const onUserDialogSave = (firstName: string, lastName: string, email: string, role: UserRole, isActive: boolean, id?: Maybe<string>) => {
        // upsert to save data
        dispatch(upsertUserData(firstName, lastName, email, role, isActive, id));
    };

    const onUserDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteUserStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedUser on close.
        dispatch(clearError());
        setSelectedUser(undefined);
        // Refresh list to bring in potential updates
        loadPage(undefined);
    };

    const getServerSortEntryFromGridSortEntry = (entry: GridSortItem) => {
        let newModel = {} as UserOrderByInput;
        let orderDirection = entry.sort === 'asc' ? OrderDirection.Asc : OrderDirection.Desc;
        switch (entry.field) {
            case 'firstName':
                newModel.firstName = orderDirection;
                break;
            case 'lastName':
                newModel.lastName = orderDirection;
                break;
            case 'email':
                newModel.email = orderDirection;
                break;
            case 'role':
                newModel.role = orderDirection;
                break;
            case 'isActive':
                newModel.isActive = orderDirection;
                break;
        }
        return newModel;
    };

    const createdAndSetServerSortModel = (model: GridSortModel) => {
        if (model && model.length > 0) {
            let newArray = [] as UserOrderByInput[];
            let i = 0;
            for (i; i < model.length; ++i) {
                newArray.push(getServerSortEntryFromGridSortEntry(model[i]));
            }
            setServerSortModel(newArray);
        } else {
            setServerSortModel(undefined);
        }

    };

    const onSortModelChange = (model: GridSortModel) => {
        createdAndSetServerSortModel(model);
        setSortModel(model);
    };

    const onSortModelChanged = (refreshInput: GetUsersInput) => {
        dispatch(clearState());
        dispatch(fetchUserList(refreshInput));
    }

    // Filter the user list
    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (firstNameFilter: string | undefined, lastNameFilter: string | undefined, emailFilter: string | undefined, roleFilter: UserRole | undefined, isActiveFilter: boolean | undefined) => {
        setFilterFirstName(firstNameFilter);
        setFilterLastName(lastNameFilter);
        setFilterEmail(emailFilter);
        setFilterRole(roleFilter);
        setFilterIsActive(isActiveFilter);

        // filters changed so refresh the list
        dispatch(clearState());
        dispatch(fetchUserList({
            after: undefined,
            limit: pageSize,
            order: serverSortModel,
            firstName: firstNameFilter,
            lastName: lastNameFilter,
            email: emailFilter,
            role: roleFilter,
            isActive: isActiveFilter
        }));
    };
    
    return (
        <MainContentBox>
            <PageTitleBar text='Users' id="users-title-comp">
                {users && users.length > 0 && (
                    <PageTitleToolbarGrid item>
                        <FiltersButton
                            onClick={onFiltersClick}
                            filterCount={filterCount}
                            filtersCleared={filtersCleared}
                            disabled={isLoading}
                            aria-label="filter button"
                            data-cy="filters"
                        />
                        {canAddOrDelete &&
                            <CreateNewButton
                                text="New User"
                                onClick={onAddUserClick}
                                data-cy="add-new-user"
                            />}
                    </PageTitleToolbarGrid>
                )}
            </PageTitleBar>
            <UsersFilterBar
                id="users-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}/>
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="Users List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={userRows ?? []}
                    columns={userColumns}
                    sortingOrder={['asc', 'desc']}
                    sortModel={sortModel}
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    onRowsScrollEnd={handlePageLoad}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />
            </DataGridListScrollBox>
            <UserDialog
                isOpen={openModify}
                user={selectedUser}
                onClose={onUserDialogClose}
                onSave={onUserDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                heading={'Delete User'}
                message={'Are you sure you want to delete \'' + selectedUser?.firstName + ' ' + selectedUser?.lastName + '\'?'}
                id={selectedUser?.id as string}
                onConfirm={onDeleteUserConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Users;