import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetAttachmentQuery, PagingResultModelOfAttachmentModel, Scalars } from '../gql-types.generated';

const QUERY_ATTACHMENT = gql`
    query getAttachment($input: GetAttachmentInput!) {
        attachments (input: $input){
            nodes {
                id
                parentId
                attachmentString
                fileName
                fileLastModifiedTime
                description
                mimeType
            }
        }
    }
`;

export const queryAttachments = async (
    client: ApolloClient<NormalizedCacheObject>,
    attachmentIds: Scalars['UUID'][],
    attachmentType: string,
    parentId?: Scalars['UUID'],
): Promise<PagingResultModelOfAttachmentModel | null | undefined> => {
    const { data, errors } = await client.query<GetAttachmentQuery>({
        variables: {
            input: {
                attachmentIds,
                attachmentType,
                parentId
            }
        },
        query: QUERY_ATTACHMENT,
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.attachments as PagingResultModelOfAttachmentModel;
}