import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { 
    NotificationFormatterModel,
    NotificationFormatterTransactionModel,
    TransactionModel,
    DeleteByIdPayload, 
    UpsertNotificationFormatterPayload, 
    AddNotificationFormatterTransactionsPayload,
    PagingResultModelOfNotificationFormatterModel
} from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    formatterPagingResult?: PagingResultModelOfNotificationFormatterModel;
    formatterRequestsInFlight: number;
    notificationFormatterList?: NotificationFormatterModel[],
    notificationFormatter?: NotificationFormatterModel;
    formatterTransactions?: NotificationFormatterTransactionModel[];
    errorFormatters?: NotificationFormatterModel[],
    transactions?: TransactionModel[];
    upsertNotificationFormatterStatus?: UpsertNotificationFormatterPayload;
    deleteNotificationFormatterStatus?: DeleteByIdPayload;
    addFormatterTransactionsStatus?: AddNotificationFormatterTransactionsPayload;
    deleteFormatterTransactionStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    formatterPagingResult: undefined,
    formatterRequestsInFlight: 0,
    notificationFormatterList: undefined,
    notificationFormatter: undefined,
    formatterTransactions: undefined,
    errorFormatters: undefined,
    transactions: undefined,
    upsertNotificationFormatterStatus: undefined,
    deleteNotificationFormatterStatus: undefined,
    addFormatterTransactionsStatus: undefined,
    deleteFormatterTransactionStatus: undefined,
};

export const slice = createSlice({
    name: 'notificationFormatter',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearNotificationFormatterByIdState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatter = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.formatterPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.formatterRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatter = undefined;
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatterList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.formatterTransactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.errorFormatters = undefined;
            // eslint-disable-next-line no-param-reassign
            state.transactions = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertNotificationFormatterStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteNotificationFormatterStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.addFormatterTransactionsStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteFormatterTransactionStatus = undefined;
        },

        fetchFormatterByIdSuccess: (state, action: PayloadAction<NotificationFormatterModel | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatter = action.payload;
        },

        fetchFormatterListSuccess: (state, action: PayloadAction<PagingResultModelOfNotificationFormatterModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.formatterPagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let formatters = state.notificationFormatterList;
            if (!formatters || !state.formatterPagingResult.cursor?.previousPage) {
                formatters = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!formatters?.some(i => i.id === node?.id)) {
                        formatters?.push(node as NotificationFormatterModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.notificationFormatterList = formatters;
        },

        incrementFormatterRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.formatterRequestsInFlight += 1;
        },

        decrementFormatterRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.formatterRequestsInFlight > 0) state.formatterRequestsInFlight -= 1;
        },

        fetchTransactionsSuccess: (state, action: PayloadAction<TransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.transactions = action.payload;
        },

        fetchFormatterTransactionsSuccess: (state, action: PayloadAction<NotificationFormatterTransactionModel[]>) => {
            // eslint-disable-next-line no-param-reassign
            state.formatterTransactions = action.payload;
        },

        fetchErrorFormattersSuccess: (state, action: PayloadAction<NotificationFormatterModel[] | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.errorFormatters = action.payload;
        },

        captureUpsertNotificationFormatterStatus: (state, action: PayloadAction<UpsertNotificationFormatterPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertNotificationFormatterStatus = action.payload;
        },

        captureDeleteNotificationFormatterStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteNotificationFormatterStatus = action.payload;
        },

        captureAddFormatterTransactionsStatus: (state, action: PayloadAction<AddNotificationFormatterTransactionsPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.addFormatterTransactionsStatus = action.payload;
        },

        captureDeleteFormatterTransactionStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteFormatterTransactionStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.notificationFormatters.error;
export const selectNotificationFormatter = (state: RootState): NotificationFormatterModel | undefined => state.notificationFormatters.notificationFormatter;
export const selectFormatterPagingResult = (state: RootState): PagingResultModelOfNotificationFormatterModel | undefined => state.notificationFormatters.formatterPagingResult;
export const selectNotificationFormatterList = (state: RootState): NotificationFormatterModel[] | undefined => state.notificationFormatters.notificationFormatterList;
export const selectRequestsInFlight = (state: RootState): number => state.notificationFormatters.formatterRequestsInFlight;
export const selectUpsertNotificationFormatterStatus = (state: RootState): UpsertNotificationFormatterPayload | undefined => state.notificationFormatters.upsertNotificationFormatterStatus;
export const selectDeleteNotificationFormatterStatus = (state: RootState): DeleteByIdPayload | undefined => state.notificationFormatters.deleteNotificationFormatterStatus;
export const selectFormatterTransactions = (state: RootState): NotificationFormatterTransactionModel[] | undefined => state.notificationFormatters.formatterTransactions;
export const selectErrorFormatterList = (state: RootState): NotificationFormatterModel[] | undefined => state.notificationFormatters.errorFormatters;
export const selectEligibleTransactions = (state: RootState): TransactionModel[] | undefined => state.notificationFormatters.transactions;
export const selectAddFormatterTransactionsStatus = (state: RootState): AddNotificationFormatterTransactionsPayload | undefined => state.notificationFormatters.addFormatterTransactionsStatus;
export const selectDeleteFormatterTransactionStatus = (state: RootState): DeleteByIdPayload | undefined => state.notificationFormatters.deleteFormatterTransactionStatus;

export const {
    clearError,
    clearState,
    clearNotificationFormatterByIdState,
    fetchError,
    fetchFormatterByIdSuccess,
    fetchFormatterListSuccess,
    fetchTransactionsSuccess, 
    fetchFormatterTransactionsSuccess,
    fetchErrorFormattersSuccess,
    captureUpsertNotificationFormatterStatus,
    captureDeleteNotificationFormatterStatus,
    captureAddFormatterTransactionsStatus,
    captureDeleteFormatterTransactionStatus,
    incrementFormatterRequestsInFlight,
    decrementFormatterRequestsInFlight,
} = slice.actions;

export default slice.reducer;