import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPartnerAkaInput, UpsertPartnerAkaPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_PARTNER_AKA = gql`
    mutation upsertPartnerAKA($input: UpsertPartnerAkaInput!){
        upsertPartnerAka(input: $input){
            message
            errors {
                code
                message
            }
            result
            partnerAKA {
                id
            }
        }
    }
`;

export const mutationUpsertPartnerAKA = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        partnerAkaInputData: UpsertPartnerAkaInput
    }
): Promise<UpsertPartnerAkaPayload | undefined> => {
    const { partnerId, partnerCode, isaQualifierCode, id } = args.partnerAkaInputData;
    const { data, errors } = await client.mutate<{ upsertPartnerAka: UpsertPartnerAkaPayload}>({
        variables: { 
            input: { 
                partnerId, 
                partnerCode, 
                isaQualifierCode, 
                id
            } 
        },
        mutation: MUTATION_UPSERT_PARTNER_AKA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of PartnerAKA.');
    }
    if (data && data.upsertPartnerAka) {
        return data.upsertPartnerAka;
    }

    return undefined;
};