import { TransactionMapModel } from "../../gql-types.generated";
import { GridColumns, GridRowModel, GridOverlay, GridSelectionModel, GridCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { CircularProgress, Typography, Stack } from '@mui/material';
import { DialogDataGrid } from '../../util/SharedStyles';
import { useEffect, useState, useMemo } from "react";

interface TransactionMapPickerListProps {
    availableMaps: TransactionMapModel[];
    existingMapIds: string[];
    loading: boolean;
    rowSelection: (
        transactionMapId: string
    ) => void;
    error?: Error | undefined;
}

const TransactionMapPickerList: React.FC<TransactionMapPickerListProps> = props => {
    const { availableMaps, existingMapIds, loading, rowSelection, error } = props;

    const [mapRows, setMapRows] = useState<GridRowModel[] | undefined>(undefined);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>();
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'description',
            sort: 'asc',
        },
    ]);

    const getMapRows = () => {
        if (availableMaps && availableMaps.length > 0) {
            return availableMaps?.map((map) => {
                const { id, description } = map;
                return {
                    _raw: map,
                    id,
                    description
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }

    useEffect(() => {
        setMapRows(getMapRows());
    }, [availableMaps]);

    const mapColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                field: 'description',
                headerName: 'DESCRIPTION',
                minWidth: 300,
                flex: 1,
                sortable: true,
                cellClassName: (cell: GridCellParams) => {
                    // workaround to have the appearance of a disabled row
                    // when a map already exists on a client partner transaction
                    let rowId = cell.row.id;
                    if (existingMapIds?.indexOf(rowId) >= 0) {
                        return 'ediDataGridCellDisabled';
                    }
                    return "";
                },
            }
        ], [],
    );

    const onSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
    };

    const onSelectionModelChange = (currentSelectionModel: GridSelectionModel) => {
        if (currentSelectionModel && availableMaps?.length) {
            let rowId = currentSelectionModel && currentSelectionModel[0] ? currentSelectionModel[0].toString() : undefined;
            if (rowId) {
                // workaround for not being able to disable row
                // only set new selection if not "disabled"
                if (existingMapIds?.indexOf(rowId) < 0) {
                    setSelectionModel(currentSelectionModel);
                    rowSelection(rowId ?? '');
                }
            }
        }
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <CircularProgress aria-label={'progress spinner'} key={'maps-spinner'} size={42} />
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {(error) && (
                    <Stack justifyContent="center">
                        <Typography variant="body2" align="center">
                            Unable to load data.
                        </Typography>
                        <Typography variant="body2" align="center">
                            Please try again later.
                        </Typography>
                    </Stack>
                )}
                {!error && availableMaps?.length === 0 && (
                    <Typography variant="body2" align="center">
                        No Records found.
                    </Typography>
                )}
            </GridOverlay>
        );
    };

    return (
        <DialogDataGrid
            loading={availableMaps === undefined || availableMaps.length <= 0}
            headerHeight={38}
            rowHeight={52}
            aria-label="Maps List"
            hideFooter
            disableColumnMenu
            disableMultipleSelection
            rows={mapRows ?? []}
            columns={mapColumns}
            sortingOrder={['asc', 'desc']}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            components={{
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                LoadingOverlay: loadingOverlay,
                // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                NoRowsOverlay: noRowsOverlay,
            }}
        />
    );
}

export default TransactionMapPickerList;