import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { IsaQualifiersListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_QUALIFIERS_LIST = gql`
    query isaQualifiersList {
        isaQualifiers {
            nodes {
                isaQualifierCode
                description
            }
        }
    }
`;

export const queryIsaQualifierList = async (client: ApolloClient<NormalizedCacheObject>) => {
    
    const { data, errors } = await client.query<IsaQualifiersListQuery>({
        query: QUERY_QUALIFIERS_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.isaQualifiers?.nodes;
};