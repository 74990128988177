import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { SystemConfigurationQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_SYSTEM_CONFIGURATION = gql`
    query systemConfiguration {
        systemConfiguration {
            id
            billingRecipientsEmail
            errorRecipientsEmail
            ediDirectRecipientsEmail
        }
    }
`;

export const querySystemConfiguration = async (client: ApolloClient<NormalizedCacheObject>) => {
    const { data, errors } = await client.query<SystemConfigurationQuery>({
        query: QUERY_SYSTEM_CONFIGURATION,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.systemConfiguration;
}