import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { RequestResult, UpsertCurrencyInput } from '../../gql-types.generated';
import { getErrorMessage } from '../../util/Common';
import { fetchError, captureDeleteCurrencyStatus, captureUpsertCurrencyStatus } from './CurrencySlice';
import { mutationUpsertCurrency } from '../../gql/MutationUpsertCurrency';
import { mutationDeleteCurrency } from '../../gql/MutationDeleteCurrency';

export const upsertCurrency = (upsertCurrencyData: UpsertCurrencyInput): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStatus = await mutationUpsertCurrency(gqlClient, {
                upsertCurrencyData
            });
            if (upsertStatus) {
                if (upsertStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStatus.message } as Error));
                    getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
                }
                dispatch(captureUpsertCurrencyStatus(upsertStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteCurrency = (currencyCode: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStatus = await mutationDeleteCurrency(gqlClient, {
                currencyCode,
            });
            if (deleteStatus) {
                if (deleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStatus.message as string, deleteStatus.errors, true, true);
                }
                dispatch(captureDeleteCurrencyStatus(deleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };



