import { useEffect, useState } from "react";
import { Box, Button, IconButton, InputAdornment, ListItem, TextField } from "@mui/material";
import { DetailsTabPanelContainer, DetailsTabHeader, DetailsTabHeaderWrapper, TabbedListCard, TabbedListCardTabList, TabHeader } from "../../util/SharedStyles";
import { SystemConfigurationModel, UpsertSystemConfigurationInput } from "../../gql-types.generated";
import AddEditDialog from "./AddEditDialog";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import { isEmailValid } from "../../util/Validation";
import TabPanel from "../TabPanel";

export enum RecipientType {
    Billing,
    Error,
    Direct
};

interface EmailObject {
    email: string;
    emailInvalid: boolean;
    emailErrorText: string;
    isHidden: boolean;
}

interface SystemConfigurationDialogProps {
    isOpen: boolean;
    onClose: () => void;
    systemConfiguration?: SystemConfigurationModel | null | undefined;
    error?: Error | undefined;
    onSave: (upsertSystemConfigurationData: UpsertSystemConfigurationInput) => void;
}

const SystemConfigurationDialog: React.FC<SystemConfigurationDialogProps> = props => {
    const { isOpen, systemConfiguration, error, onClose, onSave } = props;
    const id = systemConfiguration?.id;
    const [tabValue, setTabValue] = useState(0);
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form.
    const [billingEmails, setBillingEmails] = useState<EmailObject[]>([]);
    const [errorEmails, setErrorEmails] = useState<EmailObject[]>([]);
    const [ediDirectEmails, setEdiDirectEmails] = useState<EmailObject[]>([]);

    useEffect(() => {
        if (systemConfiguration) {
            if (systemConfiguration.billingRecipientsEmail) {
                setBillingEmails(stringToEmailObjectArray(systemConfiguration.billingRecipientsEmail))
            } else {
                setBillingEmails([{email: '', emailInvalid: false, emailErrorText: ''} as EmailObject]);
            }
            if (systemConfiguration.errorRecipientsEmail) {
                setErrorEmails(stringToEmailObjectArray(systemConfiguration.errorRecipientsEmail))
            } else {
                setErrorEmails([{email: '', emailInvalid: false, emailErrorText: ''} as EmailObject]);
            }
            if (systemConfiguration.ediDirectRecipientsEmail) {
                setEdiDirectEmails(stringToEmailObjectArray(systemConfiguration.ediDirectRecipientsEmail))
            } else {
                setEdiDirectEmails([{email: '', emailInvalid: false, emailErrorText: ''} as EmailObject]);
            }
        } else {
            setToDefaults();
        }
    }, [systemConfiguration]);

    useEffect(() => {
        if (!isOpen) {
            setToDefaults();
            setIsFormDirty(false);
            setSubmitted(false);
        }
    }, [isOpen]);

    const setToDefaults = () => {
        setBillingEmails([{email: '', emailInvalid: false, emailErrorText: '', isHidden: false} as EmailObject]);
        setErrorEmails([{email: '', emailInvalid: false, emailErrorText: '', isHidden: false} as EmailObject]);
        setEdiDirectEmails([{email: '', emailInvalid: false, emailErrorText: '', isHidden: false} as EmailObject]);
    };

    const stringToEmailObjectArray = (input: string) => {
        const split = stringToEmailArray(input);
        return split.map((email, i) => {
            return ({
                email: email,
                emailInvalid: false,
                emailErrorText: ''
            } as EmailObject)
        });
    };

    const stringToEmailArray = (input:string) => {
        return input.split(',');
    };

    const getEmailReturnStringFromObject = (input: EmailObject[]) => {
        let uniqueEmails = [] as string[];
        input.forEach((email)=>{
            let toTest = email.email.toLowerCase();
            if (!uniqueEmails.includes(toTest)){
                uniqueEmails.push(toTest);
            }
        });
        return uniqueEmails.toString();
    };

    const sanitizeEmailArray = (input: EmailObject[]) => {
        // removes empty strings as well as various pesky new lines
        return input.filter(email => email.email.replace(/(\r\n|\n|\r)/gm,""));
    };

    const submitForm = () => {
        const billings = sanitizeEmailArray(billingEmails);
        const errors = sanitizeEmailArray(errorEmails);
        const directs = sanitizeEmailArray(ediDirectEmails);
        setSubmitted(true);
        onSave({
            billingRecipientsEmail: getEmailReturnStringFromObject(billings),
            errorRecipientsEmail: getEmailReturnStringFromObject(errors),
            ediDirectRecipientsEmail: getEmailReturnStringFromObject(directs),
            id: id
        } as UpsertSystemConfigurationInput);
    };

    const isFormValid = () => {
        var invalidBillingIndex = billingEmails.findIndex(b => b.emailInvalid === true);
        var invalidErrorIndex = errorEmails.findIndex(b => b.emailInvalid === true);
        var invalidDirectIndex = ediDirectEmails.findIndex(b => b.emailInvalid === true);
        if (invalidBillingIndex >= 0 || invalidErrorIndex >= 0 || invalidDirectIndex >= 0) {
            return false;
        }
        return isFormDirty;
    };

    const onError = () => {
        setSubmitted(false);
    };

    const onBillingEmailChange = (index: number, newVal: string) => {
        const newBillings = billingEmails.map((email, i) => {
            if (i === index) {
                const isValid = isEmailValid(newVal);
                const errorText = isValid ? '' : 'Invalid format.  Enter a valid email address';
                return { 
                    email: newVal, 
                    emailInvalid: !isValid,
                    emailErrorText: errorText
                } as EmailObject
            } else {
                return email;
            }
        });
        setIsFormDirty(true);
        setBillingEmails(newBillings);
    };

    const onErrorEmailChange = (index: number, newVal: string) => {
        const newErrors = errorEmails.map((email, i) => {
            if (i === index) {
                const isValid = isEmailValid(newVal);
                const errorText = isValid ? '' : 'Invalid format.  Enter a valid email address';
                return { 
                    email: newVal, 
                    emailInvalid: !isValid,
                    emailErrorText: errorText
                } as EmailObject
            } else {
                return email;
            }
        });
        setIsFormDirty(true);
        setErrorEmails(newErrors);
    };

    const onEdiDirectEmailChange = (index: number, newVal: string) => {
        const newDirects = ediDirectEmails.map((email, i) => {
            if (i === index) {
                const isValid = isEmailValid(newVal);
                const errorText = isValid ? '' : 'Invalid format.  Enter a valid email address';
                return { 
                    email: newVal, 
                    emailInvalid: !isValid,
                    emailErrorText: errorText
                } as EmailObject
            } else {
                return email;
            }
        });
        setIsFormDirty(true);
        setEdiDirectEmails(newDirects);
    };

    const removeRecipient = (recipientIndex: number, recipientType: RecipientType) => {
        const hiddenEmail = { 
            isHidden: true,
            emailInvalid: false,
            email: '',
            emailErrorText: ''
        } as EmailObject;

        // physically removing would mess up the originally set indexes, 
        // so flagging as hidden to visibly remove from list 
        switch (recipientType) {
            case RecipientType.Billing:
                const newBillings = billingEmails.map((email, i) => {
                    if (i === recipientIndex) {
                        return hiddenEmail;
                    } else {
                        return email;
                    }
                });
                setBillingEmails(newBillings);
                break;
            case RecipientType.Error:
                const newErrors = errorEmails.map((email, i) => {
                    if (i === recipientIndex) {
                        return hiddenEmail;
                    } else {
                        return email;
                    }
                });
                setErrorEmails(newErrors);
                break;
            case RecipientType.Direct:
                const newDirects = ediDirectEmails.map((email, i) => {
                    if (i === recipientIndex) {
                        return hiddenEmail;
                    } else {
                        return email;
                    }
                });
                setEdiDirectEmails(newDirects);
                break;
        };
        setIsFormDirty(true);
    };

    const emailProps = {
        "aria-label": "email",
        maxLength: 100,
    }; 

    const getEndAdornment = (index: number, recipientType: RecipientType) => {
        return (
            <InputAdornment position="end">
                    <IconButton
                        aria-label="remove recipient"
                        title="Remove recipient from list"
                        onClick={() => removeRecipient(index, recipientType)}
                    >
                        <CloseIcon fontSize='small' sx={{ padding: '2px' }} />
                    </IconButton>
            </InputAdornment>
        );
    };

    const getBillingContent = () => {
        return (
            billingEmails.map((email: EmailObject, index) => {
                const myKey = 'billing-email-' + index;
                return (
                    <ListItem key={myKey} sx={{ display: email.isHidden ? "none" : "block" }}>
                        <TextField
                            fullWidth
                            disabled={submitted}
                            value={email.email}
                            error={email.emailInvalid}
                            helperText={email.emailErrorText}
                            inputProps={emailProps}
                            InputProps={{
                                endAdornment: 
                                getEndAdornment(index, RecipientType.Billing)
                            }}
                            autoComplete="off"                            
                            variant="standard"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onBillingEmailChange(index, event.target.value);
                            }}
                        />
                    </ListItem>
                )
            })
        );
    };

    const getErrorContent = () => {
        return (
            errorEmails.map((email: EmailObject, index) => {
                const myKey = 'error-email-' + index;
                return (
                    <ListItem key={myKey} sx={{ display: email.isHidden ? "none" : "block" }}>
                        <TextField
                            fullWidth
                            disabled={submitted}
                            value={email.email}
                            error={email.emailInvalid}
                            helperText={email.emailErrorText}
                            inputProps={emailProps}
                            InputProps={{
                                endAdornment: 
                                getEndAdornment(index, RecipientType.Error)
                            }}
                            autoComplete="off"                            
                            variant="standard"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onErrorEmailChange(index, event.target.value);
                            }}
                        />
                    </ListItem>
                )
            })
        );
    };

    const getEdiDirectContent = () => {
        return (
            ediDirectEmails.map((email: EmailObject, index) => {
                const myKey = 'edi-direct-email-' + index;
                return (
                    <ListItem key={myKey} sx={{ display: email.isHidden ? "none" : "block" }}>
                        <TextField
                            fullWidth
                            disabled={submitted}
                            value={email.email}
                            error={email.emailInvalid}
                            helperText={email.emailErrorText}
                            inputProps={emailProps}
                            InputProps={{
                                endAdornment: 
                                getEndAdornment(index, RecipientType.Direct)
                            }}
                            autoComplete="off"                            
                            variant="standard"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                onEdiDirectEmailChange(index, event.target.value);
                            }}
                        />
                    </ListItem>
                )
            })
        );
    };

    const onAddBillingClick = () => {
        setBillingEmails(billingEmails.concat({
            email: '',
            emailInvalid: false,
            emailErrorText: ''
        } as EmailObject));
    } ;

    const onAddErrorClick = () => {
        setErrorEmails(errorEmails.concat({
            email: '',
            emailInvalid: false,
            emailErrorText: ''
        } as EmailObject));
    }

    const onAddEdiDirectClick = () => {
        setEdiDirectEmails(ediDirectEmails.concat({
            email: '',
            emailInvalid: false,
            emailErrorText: ''
        } as EmailObject));
    }

    const getAddBillingButton = () => {
        if (billingEmails && billingEmails.length < 100) {
            return (
                <ListItem   key='add-billing-button'>
                    <Button color="primary" onClick={onAddBillingClick} startIcon={<AddCircleIcon />}>
                        Add Recipient
                    </Button>
                </ListItem>
            )
        }
    }

    const getAddErrorButton = () => {
        if (errorEmails && errorEmails.length < 100) {
            return (
                <ListItem   key='add-error-button'>
                    <Button color="primary" onClick={onAddErrorClick} startIcon={<AddCircleIcon />}>
                        Add Recipient
                    </Button>
                </ListItem>
            );
        }
    };

    const getAddEdiDirectButton = () => {
        if (ediDirectEmails && ediDirectEmails.length < 100) {
            return (
                <ListItem   key='add-edi-direct-button'>
                    <Button color="primary" onClick={onAddEdiDirectClick} startIcon={<AddCircleIcon />}>
                        Add Recipient
                    </Button>
                </ListItem>
            );
        }
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    const getTabbedContent = () => {
        return (
            <Box>
                <DetailsTabHeaderWrapper>
                    <DetailsTabHeader
                        value={tabValue}
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable editable recipients tabs"
                    >
                        <TabHeader label="Billing" aria-label="Billing Recipients list"/>
                        <TabHeader label="Error" aria-label="Error Recipients list"/>
                        <TabHeader label="EDI Direct" aria-label="EDI Direct Recipients list"/>
                    </DetailsTabHeader>
                </DetailsTabHeaderWrapper>                    
                <DetailsTabPanelContainer>
                    <TabPanel value={tabValue} index={0} verticalAlign>
                        <TabbedListCardTabList>
                            {getBillingContent()}
                            {getAddBillingButton()}
                        </TabbedListCardTabList>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1} verticalAlign>
                        <TabbedListCardTabList>
                            {getErrorContent()}
                            {getAddErrorButton()}
                        </TabbedListCardTabList>
                    </TabPanel>
                    <TabPanel value={tabValue} index={2} verticalAlign>
                        <TabbedListCardTabList>
                            {getEdiDirectContent()}
                            {getAddEdiDirectButton()}
                        </TabbedListCardTabList>
                    </TabPanel>
                </DetailsTabPanelContainer>
            </Box>
        );
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id ?? undefined}
            entityName="System Configuration Recipients"
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='md'
        >
            <TabbedListCard>
                {getTabbedContent()}
            </TabbedListCard>
        </AddEditDialog>
    )
}

export default SystemConfigurationDialog;