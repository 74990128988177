import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { BillingListQuery, GetBillingsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_BILLING_LIST = gql`
    query billingList($input: GetBillingsInput!) {
        billings(input: $input) {
            nodes {
                id
                description
                currencyCode
                billingItemCount
                clientCount
            }
        }
    }
`;

export const queryBillingList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters: {
        id?: string | undefined;
        description?: string | undefined;
        currencyCode?: string | undefined;
    }
) => {
    
    const { id, description, currencyCode } = filters as GetBillingsInput;

    const { data, errors } = await client.query<BillingListQuery>({
        query: QUERY_BILLING_LIST,
        variables: { 
            input: {
              id,
              description,
              currencyCode
            }  
        }
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.billings?.nodes;
};