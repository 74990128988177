import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { PagingResultModelOfDocumentArchiveModel, GetDocumentArchivesInput, RequestResult } from '../../gql-types.generated';
import { queryDocumentArchiveList } from "../../gql/QueryDocumentArchiveList";
import { 
    decrementDocumentArchiveRequestsInFlight, 
    fetchError,  
    fetchDocumentArchiveByIdSuccess,
    fetchDocumentArchiveListSuccess,
    incrementDocumentArchiveRequestsInFlight, 
    captureDeleteDocumentArchivesStatus, 
    captureAbandonDocumentArchivesStatus,
    captureAcknowledgeDocumentArchivesStatus,
    captureResendEmailsStatus,
    captureResendDocumentStatus,
    captureGetDocumentHtmlFileStatus,
    captureGetDocumentAcknowledgementFileStatus
} from './DocumentArchivesSlice';
import { mutationMassDeleteEntity } from '../../gql/MutationMassDeleteEntity';
import { mutationExecuteEdiActionSvcAction } from '../../gql/MutationExecuteEdiActionSvcAction';
import { getErrorMessage } from '../../util/Common';
import { queryDocumentArchiveById } from '../../gql/QueryDocumentArchiveById';

export const fetchDocumentArchiveList = (
    filters?: GetDocumentArchivesInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementDocumentArchiveRequestsInFlight());
    try {
        const documentArchiveList = await queryDocumentArchiveList(gqlClient, filters);
        if (documentArchiveList) {
            dispatch(fetchDocumentArchiveListSuccess(documentArchiveList as PagingResultModelOfDocumentArchiveModel))
        }
        dispatch(decrementDocumentArchiveRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementDocumentArchiveRequestsInFlight());
    }
};


export const deleteDocumentArchives = (ids: string[]): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const massDeleteStatus = await mutationMassDeleteEntity(gqlClient, {
                ids,
                entityName: "DocumentArchive"
            });
            if (massDeleteStatus) {
                if (massDeleteStatus.result === RequestResult.Fail) {
                    getErrorMessage(massDeleteStatus.message as string, massDeleteStatus.errors, true, true);
                }
                dispatch(captureDeleteDocumentArchivesStatus(massDeleteStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

// this is to abandon a list of document archives. 
// if in the future, there is a desire to revert an abandoned record, another action
// will be needed that passes a flag for false (flag defaulted to true on backend)
export const abandonDocumentArchives = (ids: string[]): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{DocumentArchiveIds: ${JSON.stringify(ids)}}`;
            const abandonStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "UpdateDocumentArchiveIsAbandonedAction",
                actionArguments: actionArguments
            });
            if (abandonStatus) {
                if (abandonStatus.result === RequestResult.Fail) {
                    getErrorMessage(abandonStatus.message as string, abandonStatus.errors, true, true);
                }
                dispatch(captureAbandonDocumentArchivesStatus(abandonStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

export const acknowledgeDocumentArchives = (ids: string[]): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{DocumentArchiveIds: ${JSON.stringify(ids)}}`;
            const acknowledgeStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "AcknowledgeDocumentArchivesAction",
                actionArguments: actionArguments
            });
            if (acknowledgeStatus) {
                if (acknowledgeStatus.result === RequestResult.Fail) {
                    getErrorMessage(acknowledgeStatus.message as string, acknowledgeStatus.errors, true, true);
                }
                dispatch(captureAcknowledgeDocumentArchivesStatus(acknowledgeStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

    export const resendEmails = (documentArchiveIds: string[]): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{DocumentArchiveIds: ${JSON.stringify(documentArchiveIds)}}`;
            const actionStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "SendMultipleNotificationsEmailAction",
                actionArguments: actionArguments
            });
            if (actionStatus) {
                if (actionStatus.result === RequestResult.Fail) {
                    getErrorMessage(actionStatus.message as string, actionStatus.errors, true, true);
                }
                dispatch(captureResendEmailsStatus(actionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

    export const resendDocument = (
        internalTransactionId?: string
    ): AppThunk => async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{InternalTransactionId: '${internalTransactionId}'}`;
            const actionStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "ResendDocumentAction",
                actionArguments: actionArguments
            });
            if (actionStatus) {
                if (actionStatus.result === RequestResult.Fail) {
                    getErrorMessage(actionStatus.message as string, actionStatus.errors, true, true);
                }
                dispatch(captureResendDocumentStatus(actionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

    export const getDocumentHtmlFile = (internalTransactionId?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{InternalTransactionId: ${JSON.stringify(internalTransactionId)}}`;
            const actionStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "GetHtmlFileAction",
                actionArguments: actionArguments
            });
            if (actionStatus) {
                if (actionStatus.result === RequestResult.Fail) {
                    getErrorMessage(actionStatus.message as string, actionStatus.errors, true, true);
                }
                dispatch(captureGetDocumentHtmlFileStatus(actionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

    export const getDocumentAcknowledgementFile = (internalTransactionId?: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            let actionArguments = `{InternalTransactionId: ${JSON.stringify(internalTransactionId)}}`;
            const actionStatus = await mutationExecuteEdiActionSvcAction(gqlClient, {
                actionName: "GetAcknowledgementFileAction",
                actionArguments: actionArguments
            });
            if (actionStatus) {
                if (actionStatus.result === RequestResult.Fail) {
                    getErrorMessage(actionStatus.message as string, actionStatus.errors, true, true);
                }
                dispatch(captureGetDocumentAcknowledgementFileStatus(actionStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }

    };

    export const fetchDocumentArchiveById = (
        id: string
    ) => async (dispatch: AppDispatch): Promise<void> => {
        dispatch(incrementDocumentArchiveRequestsInFlight());
        try {
            const documentArchive = await queryDocumentArchiveById(gqlClient, id);
            if (documentArchive) {
                dispatch(fetchDocumentArchiveByIdSuccess(documentArchive))
            }
            dispatch(decrementDocumentArchiveRequestsInFlight());
        } catch (e) {
            dispatch(fetchError(e as Error));
            dispatch(decrementDocumentArchiveRequestsInFlight());
        }
    };