import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { EdiStandardModel, RequestResult, Maybe } from '../../gql-types.generated';
import { selectError, clearError, selectUpsertEdiStandardStatus, captureUpsertEdiStandardStatus, selectDeleteEdiStandardStatus, captureDeleteEdiStandardStatus } from './EDIStandardsSlice';
import { upsertEdiStandardData, deleteEdiStandardData } from './EDIStandardsActions';
import { selectEdiStandardList } from '../EDIContainer/EDIContainerSlice';
import { fetchEdiStandardList } from '../EDIContainer/EDIContainerActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import EDIStandardListItem from '../../components/listItems/EDIStandardListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import EDIStandardDialog from '../../components/dialogs/EDIStandardDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';

interface EDIStandardsProps {
    viewer: Viewer | undefined;
}

const EDIStandards: React.FC<EDIStandardsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedStandard, setSelectedStandard] = useState<undefined | EdiStandardModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const upsertEdiStandardStatus = useAppSelector(selectUpsertEdiStandardStatus);
    const deleteEdiStandardStatus = useAppSelector(selectDeleteEdiStandardStatus);
    const error = useAppSelector(selectError);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Standards");

    useEffect(() => {
        // fetch standards list when a successful mutation occurs
        if (upsertEdiStandardStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertEdiStandardStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertEdiStandardStatus());
            // close the modify dialog
            onEdiStandardDialogClose();
        }
        if (deleteEdiStandardStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteEdiStandardStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteEdiStandardStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteEdiStandardStatus.message as string);
        }
    }, [upsertEdiStandardStatus?.result, deleteEdiStandardStatus?.result]);

    const standards = useAppSelector(selectEdiStandardList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('edistandards-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [standards]);

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the EDI Standards' error={error}></ErrorMessage>
            );
        }

        if (standards && standards.length === 0) {
            // No standards to show 
            return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New EDI Standard" actionButtonClick={onAddEdiStandardClick} />
                :
                <NoRecordsMessage message="" />
            );
        } else if (standards && standards.length) {
            return (
                standards.map((standard: EdiStandardModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={standard.id}>
                        <EDIStandardListItem
                            name={standard.name}
                            id={standard.id}
                            description={standard.description}
                            canDelete={canAddOrDelete}
                            onEditClick={editStandard}
                            onDeleteClick={deleteStandard}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'ediStandard-skeleton-' + i;
                loaders.push(
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <EDIStandardListItem loading={true} onEditClick={editStandard} onDeleteClick={deleteStandard} />
                    </CardListItemWrapperGrid>
                )
            }
            return loaders;

        }
    };

    const onAddEdiStandardClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedStandard) {
            setSelectedStandard(undefined);
        }
        setOpenModify(true);
    };

    const onEdiStandardDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteEdiStandardStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedStandard on close.
        dispatch(clearError());
        setSelectedStandard(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchEdiStandardList());
    };

    const onEdiStandardDialogSave = (name: string, description: string, id?: Maybe<string>) => {
        // upsert to save data
        dispatch(upsertEdiStandardData(name, description, id));
    };

    const onDeleteDialogConfirm = (standardId: string) => {
        // delete the selected standard
        dispatch(deleteEdiStandardData(standardId));
    };

    //edit selected standard
    const editStandard = (id: string | undefined) => {
        if (id && standards?.length) {
            let standard = standards?.find(s => s.id === id);
            if (standard) {
                setSelectedStandard(standard);
                dispatch(clearError());
                setOpenModify(true);
            }
        }
    };

    //delete selected standard
    const deleteStandard = (standardId: string | undefined) => {
        if (standards) {
            if (standardId && standards.length) {
                let standard = standards.find(s => s.id === standardId);
                if (standard) {
                    setSelectedStandard(standard);
                    dispatch(captureDeleteEdiStandardStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    };

    return (
        <MainContentBox>
            <PageTitleBar text='EDI Standards' id="edistandards-title-comp">
                <PageTitleToolbarGrid item>
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New EDI Standard"
                            onClick={onAddEdiStandardClick}
                            data-cy="add-new-edi-standard"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <EDIStandardDialog
                isOpen={openModify}
                ediStandard={selectedStandard}
                onClose={onEdiStandardDialogClose}
                onSave={onEdiStandardDialogSave}
                error={error}
            ></EDIStandardDialog>
            <DeleteDialog
                isOpen={openDelete}
                id={selectedStandard?.id}
                heading={'Delete EDI Standard'}
                message={`Are you sure you want to delete '${selectedStandard?.name}'?`}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default EDIStandards;