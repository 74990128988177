import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingResultModelOfUserModel, UserModel, UpsertUserPayload, DeleteUserPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    usersPagingResult?: PagingResultModelOfUserModel;
    userRequestsInFlight: number;
    userList?: UserModel[];
    upsertUserStatus?: UpsertUserPayload;
    deleteUserStatus?: DeleteUserPayload;
}

const initialState: SliceState = {
    error: undefined,
    usersPagingResult: undefined,
    userRequestsInFlight: 0,
    userList: [],
    upsertUserStatus: undefined,
    deleteUserStatus: undefined,
};

export const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.userList = undefined;
            // eslint-disable-next-line no-param-reassign
            state.userRequestsInFlight = 0;
            // eslint-disable-next-line no-param-reassign
            state.usersPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteUserStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertUserStatus = undefined;
        },

        captureUpsertUserStatus: (state, action: PayloadAction<UpsertUserPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertUserStatus = action.payload;
        },

        captureDeleteUserStatus: (state, action: PayloadAction<DeleteUserPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteUserStatus = action.payload;
        },

        fetchUserListSuccess: (state, action: PayloadAction<PagingResultModelOfUserModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.usersPagingResult = action.payload;
            let users = state.userList;
            if (!users || !state.usersPagingResult.cursor?.previousPage) {
                users = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!users?.some(i => i.id === node?.id)) {
                        users?.push(node as UserModel)
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.userList = users;
        },

        incrementUserRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            state.userRequestsInFlight += 1;
        },

        decrementUserRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.userRequestsInFlight > 0) state.userRequestsInFlight -= 1;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.users.error;
export const selectUpsertUserStatus = (state: RootState): UpsertUserPayload | undefined => state.users.upsertUserStatus;
export const selectDeleteUserStatus = (state: RootState): DeleteUserPayload | undefined => state.users.deleteUserStatus;
export const selectUserList = (state: RootState): UserModel[] | undefined => state.users.userList;
export const selectRequestsInFlight = (state: RootState): number => state.users.userRequestsInFlight;
export const selectUsersPagingResult = (state: RootState): PagingResultModelOfUserModel | undefined => state.users.usersPagingResult;

export const { 
    captureDeleteUserStatus, 
    captureUpsertUserStatus, 
    clearError, 
    clearState,
    fetchError, 
    fetchUserListSuccess,
    incrementUserRequestsInFlight,
    decrementUserRequestsInFlight,
} = slice.actions;

export default slice.reducer;