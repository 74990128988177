import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteBusinessAreaPayload, DeleteBusinessAreaInput, Scalars } from '../gql-types.generated';

export const MUTATION_DELETE_BUSINESS_AREA = gql`
    mutation deleteBusinessArea($input: DeleteBusinessAreaInput!){
        deleteBusinessArea(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationDeleteBusinessArea = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        id: Scalars['UUID'];
    }
): Promise<DeleteBusinessAreaPayload | undefined> => {
    const { id } = options;
    const { data, errors } = await client.mutate<{ deleteBusinessArea: DeleteBusinessAreaPayload }>({
        variables: { 
            input: {
                id: id
            }
         },
        mutation: MUTATION_DELETE_BUSINESS_AREA
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error('An error has occurred during the business area delete.');
    }
    if (data && data.deleteBusinessArea) {
        return data.deleteBusinessArea;
    }

    return undefined;
};