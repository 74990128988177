import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { Maybe, RequestResult } from '../../gql-types.generated';
import { fetchError, captureDeleteProductStatus, captureUpsertProductStatus } from './ProductsSlice';
import { mutationUpsertProduct } from '../../gql/MutationUpsertProduct';
import { mutationDeleteProduct } from '../../gql/MutationDeleteProduct';

export const upsertProductData = (name: string, description: string, id?: Maybe<string>, isAptean?: boolean): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertProductStatus = await mutationUpsertProduct(gqlClient, {
                name,
                description,
                id,
                isAptean
            });
            if (upsertProductStatus) {
                if (upsertProductStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertProductStatus.message } as Error));
                    getErrorMessage(upsertProductStatus.message as string, upsertProductStatus.errors, true, true);
                }
                dispatch(captureUpsertProductStatus(upsertProductStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteProductData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteProductStatus = await mutationDeleteProduct(gqlClient, {
                id,
            });
            if (deleteProductStatus) {
                if (deleteProductStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteProductStatus.message as string, deleteProductStatus.errors, true, true);
                }
                dispatch(captureDeleteProductStatus(deleteProductStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };


