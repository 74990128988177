import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { RequestResult, SystemNotificationModel, UpsertSystemNotificationInput } from '../../gql-types.generated';
import { styled, Tooltip, Typography } from '@mui/material';
import { MainContentBox, DataGridListScrollBox, MainDataGridNoRowHover } from '../../util/SharedStyles';
import { GridActionsCellItem, GridColumns, GridRowModel, GridOverlay, GridSortModel, GridRowParams, useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-pro';
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';
import { getShortDateString } from '../../util/DateTimeUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import { captureUpsertNotificationStatus, clearError, selectError, selectNotificationList, selectUpsertNotificationStatus } from './SystemNotificationsSlice';
import { fetchSystemNotifications, upsertSystemNotification } from './SystemNotificationsActions';
import MainDataGridLoadingSkeleton from '../../components/MainDataGridLoadingSkeleton';
import SystemNotificationDialog from '../../components/dialogs/SystemNotificationDialog';


const GridTitle = styled(Typography)((props) => ({
    paddingLeft: '20px',
    paddingTop: '10px',
    textDecoration: 'underline',
}));

interface SystemNotificationsProps {
    viewer: Viewer | undefined;
}

const SystemNotifications: React.FC<SystemNotificationsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState<SystemNotificationModel | undefined>(undefined);
    const [selectedApplicationNotifications, setSelectedApplicationNotifications] = useState<SystemNotificationModel[] | undefined>(undefined);
    const [ediNotifications, setEdiNotifications] = useState<SystemNotificationModel[] | undefined>(undefined);
    const [portalNotifications, setPortalNotifications] = useState<SystemNotificationModel[] | undefined>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [notificationEdiRows, setNotificationEdiRows] = useState<GridRowModel[] | undefined>(undefined);
    const [notificationPortalRows, setNotificationPortalRows] = useState<GridRowModel[] | undefined>(undefined);
    
    const notifications = useAppSelector(selectNotificationList);
    const upsertNotificationStatus = useAppSelector(selectUpsertNotificationStatus);
    const error = useAppSelector(selectError);
    const canEdit = viewerCanEdit(viewer);
    
    useTitle("System Notifications");

    useEffect(() => {
        dispatch(fetchSystemNotifications());
    }, []);

    useEffect(() => {
        setNotificationEdiRows(getEdiNotificationRows());
        setNotificationPortalRows(getPortalNotificationRows());

        if (error) {
            setIsLoading(false);
        } else if (notifications) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [notifications, error]);
   
    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('notifications-title-comp')?.clientHeight || 0;
        
        if (titleHeight > 0)
            setContentAreaHeight(`calc(50% - ${titleHeight}px)`);
    }, [notifications]);

    useEffect(() => {
        if (upsertNotificationStatus?.result === RequestResult.Success) {
            onNotificationDialogClose();
            dispatch(setToastConfig({
                message: upsertNotificationStatus.message as string,
                severity: ToastSeverity.Success
            }));
            dispatch(captureUpsertNotificationStatus());
        }
    }, [upsertNotificationStatus?.result]);

    const getSelectedRowNotification = useCallback((rowId: string) => () => {
        if (rowId && notifications?.length) {
            let notification = notifications.find(notification => notification.id === rowId);
            return notification;
        }
        return undefined;
    }, [notifications]);

    const editNotificationHandler = useCallback((rowId: string) => () => {
        let notification = getSelectedRowNotification(rowId);
        if (notification) {
            setSelectedNotification(notification);
            if (notification()?.environment === 'EDI') {
                setSelectedApplicationNotifications(ediNotifications);
            } else {
                setSelectedApplicationNotifications(portalNotifications);
            }
            dispatch(clearError());
            setOpenModify(true);
        }
    }, [getSelectedRowNotification, dispatch, ediNotifications, portalNotifications]);

    const clearNotificationHandler = useCallback((rowId: string) => () => {
        if (rowId) {
            dispatch(upsertSystemNotification(
                {
                    id: rowId,
                    message: undefined,
                    dateStart: undefined,
                    dateEnd: undefined,
                } as UpsertSystemNotificationInput
            ));
        }
    }, [dispatch]);

    const mapNotifications = (notifications: SystemNotificationModel[] | undefined) => {
        if (notifications && notifications.length > 0) {
            return notifications.map((notification) => {
                const { id, message, dateStart, dateEnd, environment, notificationType } = notification;

                return {
                    _raw: notification,
                    id,
                    environment,
                    type: notificationType,
                    message,
                    dateStart,
                    dateEnd,
                } as GridRowModel;
            }) as GridRowModel[];
        } else {
            return [];
        }
    }
    const getEdiNotificationRows = () => {
        let ediNotifications = notifications?.filter((notification) => notification.environment === 'EDI');
        setEdiNotifications(ediNotifications);
        return mapNotifications(ediNotifications);
    };

    const getPortalNotificationRows = () => {
        let portalNotifications = notifications?.filter((notification) => notification.environment === 'EDI_PORTAL');
        setPortalNotifications(portalNotifications);
        return mapNotifications(portalNotifications);
    };

    const notificationColumns = useMemo<GridColumns<GridRowModel>>(
        () => [
            {
                headerName: 'SEVERITY',
                field: 'type',
                minWidth: 180,
                cellClassName: "ediDataGridCellFirstChild",
                sortable: false,
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    return capitalize(value);
                },
            }, {
                headerName: 'MESSAGE',
                field: 'message',
                flex: 2,
                cellClassName: "ediDataGridCellOverflowHidden",
                sortable: false,
            },{
                headerName: 'START DATE',
                field: 'dateStart',
                minWidth: 180,
                sortable: false,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : '';
                    return (
                        dateValue
                    );
                },
            }, {
                headerName: 'END DATE',
                field: 'dateEnd',
                minWidth: 180,
                sortable: false,
                type: 'dateTime',
                renderCell: (params: GridRenderCellParams) => {
                    const { value } = params;
                    let dateValue = value ? getShortDateString(value) : '';
                    return (
                        dateValue
                    );
                },
            }, {
                field: 'actions',
                type: 'actions',
                sortable: false,
                headerName: '',
                width: 100,
                headerAlign: 'right',
                align: 'center',
                hide: !canEdit, // hide column for reader role
                // eslint-disable-next-line react/display-name
                getActions: (params: GridRowParams<GridRowModel>) => [
                    <GridActionsCellItem
                        icon={<Tooltip title="Edit"><EditIcon /></Tooltip>}
                        label="Edit"
                        color="primary"
                        onClick={editNotificationHandler(params.row.id)}
                        showInMenu={false}
                    />,
                    <GridActionsCellItem
                        icon={<Tooltip title="Clear Message and Dates"><ClearIcon /></Tooltip>}
                        label="Clear"
                        color="primary"
                        onClick={clearNotificationHandler(params.row.id)}
                        showInMenu={false}
                    />
                ],
            }
        ],
        [editNotificationHandler, clearNotificationHandler, canEdit],
    );

    const GetApiRef = () => {
        return useGridApiContext();
    }
    const loadingOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the System Notifications' error={error}></ErrorMessage>
                )}
                {!error && (
                    <MainDataGridLoadingSkeleton apiRef={GetApiRef()} rowBottomMargin={6} />
                )}
            </GridOverlay>
        );
    };

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                {error && (
                    <ErrorMessage title='Unable to load the System Notifications' error={error}></ErrorMessage>
                )}
                {!error && (notifications && notifications.length <= 0) && (
                    <NoRecordsMessage topMargin={6} message="" />
                )}
            </GridOverlay>
        );
    };

    const onNotificationDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDialogClose = () => {
        dispatch(clearError());
        setSelectedNotification(undefined);
        dispatch(fetchSystemNotifications());
    };

    const onNotificationDialogSave = (id: string, startDate?: DateTime, endDate?: DateTime, message?: string) => {
        dispatch(upsertSystemNotification(
            {
                id: id,
                message: message,
                dateStart: startDate?.startOf('day'),
                dateEnd: endDate?.startOf('day'),
            } as UpsertSystemNotificationInput
        ));
    };

    return (
        <MainContentBox>
            <PageTitleBar text='System Notifications' id="notifications-title-comp" />
            
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
            <GridTitle variant="subtitle1" >EDI Customer Portal</GridTitle>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="EDI Customer Portal System Notifications List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={notificationPortalRows ?? []}
                    columns={notificationColumns}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            
            <DataGridListScrollBox scrollheight={contentAreaHeight}>
            <GridTitle variant="subtitle1" >EDI One</GridTitle>
                <MainDataGridNoRowHover
                    loading={isLoading}
                    headerHeight={38}
                    rowHeight={52}
                    aria-label="EDI One System Notifications List"
                    hideFooter
                    disableColumnMenu
                    disableColumnFilter
                    disableSelectionOnClick
                    rows={notificationEdiRows ?? []}
                    columns={notificationColumns}
                    components={{
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        LoadingOverlay: loadingOverlay,
                        // eslint-disable-next-line react/display-name,@typescript-eslint/naming-convention
                        NoRowsOverlay: noRowsOverlay,
                    }}
                />

            </DataGridListScrollBox>
            <SystemNotificationDialog
                isOpen={openModify}
                notification={selectedNotification}
                applicationNotifications={selectedApplicationNotifications}
                onClose={onNotificationDialogClose}
                onSave={onNotificationDialogSave}
                error={error}
            />
        </MainContentBox>
    );
}

export default SystemNotifications;