/*
    TransactionPipelineDialog queries to get the pipelines
    If you already have pipelines and don't need the dialog to make the query, use PipelinePickerDialog
*/
import { CircularProgress, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchPipelineList } from "../../features/Pipelines/PipelinesActions";
import { selectPipelineList } from "../../features/Pipelines/PipelinesSlice";
import { PipelineModel } from "../../gql-types.generated";
import { DialogDataGridWrapper } from "../../util/SharedStyles";
import AddEditDialog from "./AddEditDialog";
import PipelinePickerList from '../lists/PipelinePickerList';

interface TransactionPipelineDialogProps {
    isOpen: boolean;
    pipeline?: PipelineModel;
    onClose: () => void;
    onSave: (
        pipeline?: PipelineModel
    ) => void;
    error?: Error | undefined;
}

const TransactionPipelineDialog: React.FC<TransactionPipelineDialogProps> = props => {
    const { isOpen, pipeline, onClose, onSave, error } = props;
    const dispatch = useAppDispatch();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedPipeline, setSelectedPipeline] = useState<PipelineModel | undefined>(pipeline);
    const transactionPipelines = useAppSelector(selectPipelineList);

    useEffect(() => {
        dispatch(fetchPipelineList({isLegacy: false}));
    }, []);

    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
        }
    }, [isOpen]);

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(selectedPipeline);
        }
    }

    const closeEditDialog = () => {
        onClose();
    }

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    }

    const onError = () => {
        setSubmitted(false);
    }

    const getSelectedPipeline = (pipelineId: string) => {
        if (pipelineId && transactionPipelines?.length) {
            let pipelineMatch = transactionPipelines.find(pipe => pipe.id === pipelineId);
            return pipelineMatch;
        }
        return undefined;
    };

    const onPipelineChange = (pipelineId: string) => {
        setIsFormDirty(true);
        if (pipelineId) {
            // find id in transactionPipelines to return entire model
            let pipelineModel = getSelectedPipeline(pipelineId);
            setSelectedPipeline(pipelineModel);
        } else {
            setSelectedPipeline(undefined);
        }
    };

    const getPipelineContent = () => {
        if (transactionPipelines) {
            if (transactionPipelines.length > 0) {
                return (

                    <PipelinePickerList
                        availablePipelines={transactionPipelines}
                        selectedPipelineId={pipeline?.id as string}
                        loading={false}
                        rowSelection={onPipelineChange}
                    />
                )
            } else {
                return "No pipelines found"
            }
        } else {
            return <CircularProgress />
        }
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={pipeline?.id}
            titlePrefix="Select a"
            entityName="Pipeline"
            maxWidth='md'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <DialogDataGridWrapper container>
                <Grid item xs={12}>
                    {getPipelineContent()}
                </Grid>
            </DialogDataGridWrapper>

        </AddEditDialog>
    );
}

export default TransactionPipelineDialog;