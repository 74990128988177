import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { PartnerTransactionSchemaModel } from "../../gql-types.generated";
import { DialogDataGridWrapper } from "../../util/SharedStyles";
import PartnerTransactionSchemaPickerList from "../lists/transactions/PartnerTransactionSchemaPickerList";
import AddEditDialog from "./AddEditDialog";

interface PartnerTransactionSchemaPickerDialogProps {
    isOpen: boolean;
    availableSchemas: PartnerTransactionSchemaModel[];
    existingSchemaIds: string[];
    onClose: () => void;
    onSave: (
        partnerTransactionSchemaId: string
    ) => void;
    error?: Error | undefined;
}

const PartnerTransactionSchemaPickerDialog: React.FC<PartnerTransactionSchemaPickerDialogProps> = props => {
    const { isOpen, availableSchemas, existingSchemaIds, onClose, onSave, error } = props;
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedSchemaId, setSelectedSchemaId] = useState<string>('');

    useEffect(() => {
        if (!isOpen) {
            setSubmitted(false);
            setIsFormDirty(false);
            setSelectedSchemaId("");
        }
    }, [isOpen]);

    const submitForm = () => {
        if (isFormDirty) {
            setSubmitted(true);
            onSave(selectedSchemaId);
        }

        closeEditDialog();
    }
    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    }

    const closeEditDialog = () => {
        onClose();
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onSchemaSelection = (schemaId: string) => {
        setIsFormDirty(true);
        if (schemaId) {
            setSelectedSchemaId(schemaId);
        } else {
            setSelectedSchemaId('');
        }
    }

    const getSchemaContent = () => {
        if (availableSchemas && availableSchemas.length > 0) {
            return (
                <PartnerTransactionSchemaPickerList
                    availableSchemas={availableSchemas}
                    existingSchemaIds={existingSchemaIds}
                    loading={false}
                    rowSelection={onSchemaSelection}
                />
            )
        } else {
            return "No transaction EDI rules schemas available to be added"
        }
    };

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id="schema-picker"
            titlePrefix="Select"
            entityName="EDI Rules Schema"
            maxWidth='md'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <DialogDataGridWrapper container>
                <Grid item xs={12}>
                    {getSchemaContent()}
                </Grid>
            </DialogDataGridWrapper>

        </AddEditDialog>
    );
};

export default PartnerTransactionSchemaPickerDialog;