import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PagingResultModelOfPartnerModel, PartnersListQuery, GetPartnersInput, OrderDirection, PartnerOrderByInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRADING_PARTNERS_LIST = gql`
query partnersList($input: GetPartnersInput!) {
    partners (input: $input) {
      cursor {
        nextPage
        previousPage
      }
      nodes {
        name
        isaQualifierCode
        id
        partnerCode
        website
        totalTransactions
        businessArea{
            id
            description
            name
        }
        ediStandards{
          description
          id
          name
        }
      }
    }
  }
`;

export const queryTradingPartnersList = async (
  client: ApolloClient<NormalizedCacheObject>,
  after: string | undefined,
  limit: number | undefined,
  filters: {
    name?: string | undefined;
    standardIds?: string[] | undefined;
    partnerStatuses?: string[] | undefined;
    partnerCode?: string | undefined;
    businessAreas?: string[] | undefined;
  }
): Promise<PagingResultModelOfPartnerModel | undefined> => {

  const { name, standardIds, partnerStatuses, partnerCode, businessAreas } = filters;
  const order: PartnerOrderByInput = { name: OrderDirection.Asc };
  const { data, errors } = await client.query<PartnersListQuery>({
    query: QUERY_TRADING_PARTNERS_LIST,
    variables: {
      input: {
        after: after,
        limit: limit,
        order: order,
        name: name,
        statusCodes: partnerStatuses,
        ediStandardIds: standardIds,
        partnerCode: partnerCode,
        businessAreaIds: businessAreas
      }
    }
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  return data?.partners as PagingResultModelOfPartnerModel;
};