import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { DeleteMultipleByIdsPayload, DeleteMultipleByIdsInput } from '../gql-types.generated';

export const MUTATION_MASS_DELETE_ENTITY = gql`
    mutation massDeleteEntityByIds($input: DeleteMultipleByIdsInput!){
        deleteMultipleByIds(input: $input){
            message
            result
            errors {
                code
                message
            }
        }
    }
`;

export const mutationMassDeleteEntity = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        ids: string[];
        entityName: string;
    }
): Promise<DeleteMultipleByIdsPayload | undefined> => {
    const { ids, entityName } = options;
    const { data, errors } = await client.mutate<{ deleteMultipleByIds: DeleteMultipleByIdsPayload }>({
        variables: {
            input: {
                ids,
                entityName
            }
        },
        mutation: MUTATION_MASS_DELETE_ENTITY
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // friendly error to user
        throw new Error(`An error has occurred during the ${entityName} delete.`);
    }
    if (data && data.deleteMultipleByIds) {
        return data.deleteMultipleByIds;
    }

    return undefined;
};