import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { captureDeleteClientNotificationRecipientsStatus, captureUpsertClientNotificationRecipientsStatus, clearError, selectClientNotificationsRecipients, selectDeleteClientNotificationsRecipientsStatus, selectError, selectUpsertClientNotificationsRecipientsStatus } from "../../features/ClientDetails/ClientDetailsSlice";
import { deleteClientNotificationRecipients, fetchClientNotificationRecipients, upsertClientNotificationRecipients } from "../../features/ClientDetails/ClientDetailsActions";
import { ClientNotificationRecipientsModel, NotificationType, UpsertClientNotificationRecipientsInput, UserRole } from "../../gql-types.generated";
import NotificationsRecipientsList from "./NotificationRecipientsList";


interface ClientNotificationsRecipientsListProps {
    viewerRole: UserRole | undefined;
    clientId: string;
}

const ClientNotificationsRecipientsList: React.FC<ClientNotificationsRecipientsListProps> = props => {
    const { viewerRole, clientId } = props;
    const dispatch = useAppDispatch();
    
    const notificationRecipients = useAppSelector(selectClientNotificationsRecipients);
    const error = useAppSelector(selectError);
    const upsertRecipientStatus = useAppSelector(selectUpsertClientNotificationsRecipientsStatus);
    const deleteRecipientStatus = useAppSelector(selectDeleteClientNotificationsRecipientsStatus);

    useEffect(() => {
        if (clientId) {
            dispatch(fetchClientNotificationRecipients(clientId));
        }
    }, [clientId]);
  
    const refreshList = () => {
        dispatch(fetchClientNotificationRecipients(clientId));
    };

    const onRecipientsDialogClose = () => {
        // clear upsert status
        dispatch(captureUpsertClientNotificationRecipientsStatus());
    }

    const onRecipientsDialogSave = (
        notificationType: NotificationType,
        description?: string,
        receivesErrors?: boolean,
        receivesNotifications?: boolean,
        recipients?: string,
        id?: string
    ) => {
        if (notificationType) {
            var upsertRecipient = {
                clientId: clientId,
                description: description,
                notificationType: notificationType,
                receivesErrors: receivesErrors,
                receivesNotifications: receivesNotifications,
                recipients: recipients,
                id: id
            } as UpsertClientNotificationRecipientsInput;
            dispatch(upsertClientNotificationRecipients(upsertRecipient));
        }
    }

    const onDeleteDialogClose = () => {
        // clear delete status
        dispatch(captureDeleteClientNotificationRecipientsStatus());
    };

    const onDeleteDialogConfirm = (selectedRecipientId: string) => {
        dispatch(deleteClientNotificationRecipients(selectedRecipientId));
    }

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <NotificationsRecipientsList
            viewerRole={viewerRole}
            notificationRecipients={notificationRecipients as ClientNotificationRecipientsModel[]}
            deleteStatus={deleteRecipientStatus}
            upsertStatus={upsertRecipientStatus}
            onDeleteDialogConfirm={onDeleteDialogConfirm}
            onDeleteDialogClose={onDeleteDialogClose}
            onRecipientsDialogSave={onRecipientsDialogSave}
            onRecipientsDialogClose={onRecipientsDialogClose}
            refreshList={refreshList}
            clearError={handleClearError}
            error={error}
            
        />
    )
}

export default ClientNotificationsRecipientsList;