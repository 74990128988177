import { gqlClient } from '../../components/AppProvider';
import { getErrorMessage } from '../../util/Common';
import { Maybe, RequestResult, UserRole, UserModel, GetUsersInput, PagingResultModelOfUserModel } from '../../gql-types.generated';
import { queryUsersList } from '../../gql/QueryUsersList';
import { AppDispatch, AppThunk } from '../../store';
import { 
    captureUpsertUserStatus, 
    captureDeleteUserStatus, 
    decrementUserRequestsInFlight,
    fetchError, 
    fetchUserListSuccess,
    incrementUserRequestsInFlight
 } from './UsersSlice';
import { mutationUpsertUser } from '../../gql/MutationUpsertUser';
import { mutationDeleteUser } from '../../gql/MutationDeleteUser';


export const fetchUserList = (
    filters?: GetUsersInput
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(incrementUserRequestsInFlight());
    try {
        const userList = await queryUsersList(gqlClient, filters);
        if (userList) {
            dispatch(fetchUserListSuccess(userList as PagingResultModelOfUserModel))
        }
        dispatch(decrementUserRequestsInFlight());
    } catch (e) {
        dispatch(fetchError(e as Error));
        dispatch(decrementUserRequestsInFlight());
    }
};

export const upsertUserData =
    (firstName: string, lastName: string, email: string, role: UserRole, isActive?: boolean, id?: Maybe<string>): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                //dont send email if its an update operation
                var emailAddress = id ? undefined : email;
                const upsertUserStatus = await mutationUpsertUser(gqlClient, {
                    role,
                    email: emailAddress,
                    firstName,
                    lastName,
                    id,
                    isActive
                });
                if (upsertUserStatus) {
                    if (upsertUserStatus.result === RequestResult.Fail) {
                        dispatch(fetchError({ message: upsertUserStatus.message } as Error));
                        getErrorMessage(upsertUserStatus.message as string, upsertUserStatus.errors, true, true);
                    }
                    dispatch(captureUpsertUserStatus(upsertUserStatus));
                }

            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        }

export const deleteUserData =
    (id: string): AppThunk =>
        async (dispatch: AppDispatch): Promise<void> => {
            try {
                const deleteUserStatus = await mutationDeleteUser(gqlClient, {
                    id,
                });
                if (deleteUserStatus) {
                    if (deleteUserStatus.result === RequestResult.Fail) {
                        getErrorMessage(deleteUserStatus.message as string, deleteUserStatus.errors, true, true);
                    }
                    dispatch(captureDeleteUserStatus(deleteUserStatus));
                }

            } catch (e) {
                dispatch(fetchError(e as Error));
            }
        }