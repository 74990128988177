import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink } from '@apollo/client';
import { useState } from 'react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ENDPOINT } from '../util/Constants';
import store from '../store';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import EDIContainer from '../features/EDIContainer/EDIContainer';
import { GraphQLError } from 'graphql';

const uploadLink = createUploadLink({
    uri: `${API_ENDPOINT}`,
    credentials: 'same-origin'
}) as unknown;

export const gqlClient = new ApolloClient({
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
        watchQuery: {
            fetchPolicy: 'no-cache',
        },
    },
    cache: new InMemoryCache({ addTypename: false }),
});



const AppProvider: React.FC = () => {
    const { getIdTokenClaims } = useAuth0();
    const [authError, setAuthError] = useState<GraphQLError | undefined>(undefined);

    const authLink = setContext(async (_, { headers, ...rest }) => {
        let token;
        try {
            const claims = await getIdTokenClaims();
            token = claims?.__raw;
        } catch (error) {
            console.log(error);
        }
        if (!token) {
            return { headers, ...rest };
        }

        return {
            ...rest,
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(error => {
                if (error.extensions?.code === 'AUTH_NOT_AUTHENTICATED') {
                    setAuthError(error);
                } else {
                    console.log(`[GraphQL error]: Message: ${error.message}, Location: ${error.locations}, Path: ${error.path}`);
                }
            });
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
    });

    if (gqlClient) {
        const linkWithAuth = authLink.concat(errorLink).concat(uploadLink as ApolloLink);
        gqlClient.setLink(linkWithAuth);
    }

    return (
        <ApolloProvider client={gqlClient}>
            <Provider store={store}>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <EDIContainer authError={authError} />
            </Provider>
        </ApolloProvider>
    );
};

export default AppProvider;