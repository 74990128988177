import { Grid, MenuItem, TextField, Stack, FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { PhoneModel, PhoneTypeModel } from "../../gql-types.generated";
import { selectPhoneTypeList } from "../../features/EDIContainer/EDIContainerSlice";



interface PhoneFieldsProps {
    phone?: PhoneModel;
    disabled: boolean;
    parentId: string | undefined;
    onChange: (
        phone: PhoneModel,
    ) => void;
}

const PhoneFields: React.FC<PhoneFieldsProps> = props => {
    const { phone, disabled, parentId, onChange } = props;
    const [number, setNumber] = useState('');
    const [typeCode, setTypeCode] = useState('');
    const [isPrimary, setIsPrimary] = useState(false);
    const id = phone?.id;

    const phoneTypes = useAppSelector(selectPhoneTypeList);

    useEffect(() => {
        if (phone) {
            setNumber(phone.phoneNumber || '');
            setIsPrimary(phone.isPrimary || false);
            setTypeCode(phone.phoneTypeCode || '');
        }
    }, [phone]);

    /*const formatPhoneNumber = (phoneInput: string) => {
        const clean = phoneInput.replace(/\D/g, '');
        const match = clean.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }*/

    const getPhoneTypeOptions = () => {
        if (phoneTypes && phoneTypes.length) {
            return (
                phoneTypes.map((phoneType: PhoneTypeModel) => (
                    <MenuItem
                        key={phoneType.phoneTypeCode ?? ''}
                        value={phoneType.phoneTypeCode ?? ''}
                    >
                        {phoneType.description}
                    </MenuItem>
                ))
            );
        }
        return null;
    };

    const onDataChanged = (fields: {numVal?: string, typeVal?: string}) => {
        // set ahead of time.  set string value to property, undefined property to stored value
        // this fixes an issue where empty strings were triggering the stored value to be replaced in the value.
        const {
            numVal = number,
            typeVal = typeCode
        } = fields;
        
        // send #, desc, id, isPrimary, parentId in Model to passed in OnChange
        onChange({
            parentId: parentId,
            id: id,
            phoneNumber: numVal,
            phoneTypeCode: typeVal,
            isPrimary: isPrimary,
        } as PhoneModel)
    };

    const onNumberChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let newVal = event.target.value;
        let isValid = true;
        if (newVal) {
            const phoneNumberRegex = "^[\\d_*./#()+-]+$";
            const match = newVal.match(phoneNumberRegex);
            // an invalid character was entered, so don't set the new value
            // this will make it look to user like that entry is not allowed
            // Doing this restriction rather than validating actual format
            // since different countries have different formatting rules
            if (!match) {
                isValid = false;
            }
        }
        if (isValid) {
            setNumber(newVal);
            onDataChanged({ numVal: newVal });
        }
    };
    
    const onTypeChanged = (event: SelectChangeEvent<string | null>) => {
        setTypeCode(event.target.value ?? '');
        onDataChanged({ typeVal: event.target.value ?? undefined } );
    };

    const phoneNumberProps = {
        'aria-label': 'phoneNumber',
        'maxLength': 20,
    };

    const phoneTypeProps = {
        PaperProps: {
            style: {
                maxHeight: '200px',
            },
        },
    };
    
    return (
        <Stack spacing={2}>
            <Grid item>
                <TextField
                    disabled={disabled}
                    value={number}
                    label="Phone Number"
                    fullWidth
                    inputProps={phoneNumberProps}
                    onChange={onNumberChanged}
                    autoComplete="off"
                    data-cy="phoneNumber"
                    variant="standard"
                />
            </Grid>
            <Grid item >
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="phone-types-label">Phone Type</InputLabel>
                    <Select
                        labelId="phone-types-label"
                        aria-labelledby="phone-types-label"
                        value={typeCode}
                        onChange={onTypeChanged}
                        disabled={disabled}
                        MenuProps={phoneTypeProps}
                        data-cy="phone-types-list"
                    >
                        {getPhoneTypeOptions()}
                    </Select>
                </FormControl>
            </Grid>
        </Stack>
    );
}

export default PhoneFields;