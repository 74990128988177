import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { TransactionsListQuery, GetTransactionsInput, TransactionDirection, TransactionModel } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_TRANSACTION_BY_ID = gql`
  query getTransactionById($input: GetTransactionsInput!) {
    transactions(input: $input) {
      nodes {
          id 
          name 
          description 
          ediStandard {
            id 
            name 
          } 
          direction 
          isActive 
          isBillable 
          ediStandardId 
          billableFactor
          normalizedDocumentType
          documentTransformationSchema
          skipTargetDocumentCreation
          transactionXPath {
            id
            transactionId
            xPathJson
          }
      }
    }
  }
`;

export const queryTransactionById = async (
  client: ApolloClient<NormalizedCacheObject>,
  id?: string
) => {

  const { data, errors } = await client.query<TransactionsListQuery>({
    query: QUERY_TRANSACTION_BY_ID,
    variables: { 
      input: {
        id: id,
      }  
    }
  });

  if (errors && errors.length > 0) {
    errors.forEach(error => {
      // Log error details in the console.
      console.error(error);
    });
    // Friendly error to the user.
    throw new Error('An error has occurred during the query.');
  }

  if (data?.transactions && data?.transactions.nodes && data?.transactions.nodes?.length > 0) {
    return data?.transactions?.nodes[0] as TransactionModel;
  }
  return undefined;
};