import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { GetActiveSystemNotificationQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const QUERY_ACTIVE_SYSTEM_NOTIFICATION = gql`
    query getActiveSystemNotification {
        activeSystemNotification {
            id
            message
            notificationType
            dateStart
            dateEnd
            environment
        }
    }
`;

export const queryActiveSystemNotification = async (client: ApolloClient<NormalizedCacheObject>) => {
    const { data, errors } = await client.query<GetActiveSystemNotificationQuery>({
        query: QUERY_ACTIVE_SYSTEM_NOTIFICATION,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.activeSystemNotification ?? undefined;
}