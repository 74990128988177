import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AttachmentModel, UserRole, DeleteByIdPayload, RequestResult, UpsertAttachmentInput } from '../../../gql-types.generated';
import { selectError, clearError, captureDeleteClientPartnerAttachmentStatus, captureUpsertClientPartnerAttachmentsStatus, selectDeleteClientPartnerAttachmentStatus, selectUpsertClientPartnerAttachmentsStatus, selectAttachmentToDownload, clearClientPartnerAttachment } from '../../../features/ClientPartnerDetails/ClientPartnerDetailsSlice';
import { deleteClientPartnerAttachment, fetchClientPartnerAttachment, upsertClientPartnerAttachments } from '../../../features/ClientPartnerDetails/ClientPartnerDetailsActions';
import AttachmentList from './AttachmentList';
import { downloadBlobAsFile, getFileDataString, openBlobAsFileInNewTab } from '../../../util/Common';


interface ClientPartnerAttachmentListProps {
    viewerRole: UserRole | undefined;
    clientPartnerId: string;
    attachments: AttachmentModel[] | undefined;
    refreshClientPartnerData: () => void;
}

const ClientPartnerAttachmentList: React.FC<ClientPartnerAttachmentListProps> = (props: ClientPartnerAttachmentListProps) => {
    const { viewerRole, clientPartnerId, attachments, refreshClientPartnerData } = props;
    const dispatch = useAppDispatch();
    const [deleteStatus, setDeleteStatus] = useState<DeleteByIdPayload | undefined>();
    const [addResult, setAddResult] = useState<RequestResult | undefined>();
    const [addResultMessage, setAddResultMessage] = useState<string | undefined>();
    const [openAttachment, setOpenAttachment] = useState<boolean | undefined>();

    const upsertClientPartnerAttachmentStatus = useAppSelector(selectUpsertClientPartnerAttachmentsStatus);
    const deleteClientPartnerAttachmentStatus = useAppSelector(selectDeleteClientPartnerAttachmentStatus);
    const attachmentToDownload = useAppSelector(selectAttachmentToDownload);
    const error = useAppSelector(selectError);


    useEffect(() => {
        // save off add result for base component
        if (upsertClientPartnerAttachmentStatus && upsertClientPartnerAttachmentStatus.result) {
            // set the result to be passed to base component
            setAddResult(upsertClientPartnerAttachmentStatus.result);
            setAddResultMessage(upsertClientPartnerAttachmentStatus.message as string);
        }
        else {
            setAddResult(undefined);
        }

        if (deleteClientPartnerAttachmentStatus && deleteClientPartnerAttachmentStatus.result) {
            // set the status to be passed to base component
            setDeleteStatus(deleteClientPartnerAttachmentStatus);
        }
        else {
            setDeleteStatus(undefined);
        }
    }, [deleteClientPartnerAttachmentStatus?.result, upsertClientPartnerAttachmentStatus?.result]);

    useEffect(() => {
        if (attachmentToDownload && attachmentToDownload[0] && attachmentToDownload[0] && attachmentToDownload[0].fileName) {
            const attachment = attachmentToDownload[0];
            if (attachment.attachmentString !== undefined && attachment.attachmentString !== null && attachment.fileName && attachment.mimeType) {
                const fileData = getFileDataString(true, attachment.mimeType);
                fetch(fileData + "," + attachment.attachmentString)
                    .then(res => res.blob())
                    .then(blob => {
                        if (openAttachment) {
                            openBlobAsFileInNewTab(blob, attachment.fileName as string);
                        } else {
                            downloadBlobAsFile(blob, attachment.fileName as string);
                        }

                        dispatch(clearClientPartnerAttachment());
                        setOpenAttachment(undefined);
                    });
            }
        }
    }, [attachmentToDownload])

    const onDeleteDialogClose = () => {
        // clear the delete status
        dispatch(captureDeleteClientPartnerAttachmentStatus());
    };

    const onAttachmentsDialogClose = () => {
        // clear the upsert status
        dispatch(captureUpsertClientPartnerAttachmentsStatus());
    };

    const onAttachmentsSave = (attachments: UpsertAttachmentInput[]) => {
        if (attachments && clientPartnerId) {
            dispatch(
                upsertClientPartnerAttachments(
                    clientPartnerId,
                    attachments
                )
            );
        }
    };

    const onAttachmentDelete = (id: string) => {
        if (id) {
            dispatch(deleteClientPartnerAttachment(id));
        }
    }

    const onAttachmentDownload = (id: string) => {
        if (id) {
            setOpenAttachment(false);
            dispatch(fetchClientPartnerAttachment(id));
        }
    }

    const onAttachmentOpen = (id: string) => {
        if (id) {
            setOpenAttachment(true);
            dispatch(fetchClientPartnerAttachment(id));
        }
    }

    const handleClearError = () => {
        dispatch(clearError());
    };

    return (
        <AttachmentList
            viewerRole={viewerRole}
            attachments={attachments}
            deleteAttachmentStatus={deleteStatus}
            upsertAttachmentResult={addResult}
            upsertAttachmentResultMessage={addResultMessage}
            deleteAttachment={onAttachmentDelete}
            downloadAttachment={onAttachmentDownload}
            openAttachment={onAttachmentOpen}
            onDeleteDialogClose={onDeleteDialogClose}
            onAttachmentsDialogClose={onAttachmentsDialogClose}
            saveAttachments={onAttachmentsSave}
            refreshList={refreshClientPartnerData}
            clearError={handleClearError}
            error={error}
        />

    );

}

export default ClientPartnerAttachmentList;