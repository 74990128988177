import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ErpProductModel, RequestResult, Maybe } from '../../gql-types.generated';
import { selectError, clearError, selectDeleteProductStatus, selectUpsertProductStatus, captureDeleteProductStatus, captureUpsertProductStatus } from './ProductsSlice';
import { selectErpProductList } from '../EDIContainer/EDIContainerSlice';
import { upsertProductData, deleteProductData } from './ProductsActions';
import { fetchErpProductList } from '../EDIContainer/EDIContainerActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import ProductListItem from '../../components/listItems/ProductListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import ProductDialog from '../../components/dialogs/ProductDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';

interface ProductsProps {
    viewer: Viewer | undefined;
}

const Products: React.FC<ProductsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedProduct, setSelectedProduct] = useState<undefined | ErpProductModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const upsertProductStatus = useAppSelector(selectUpsertProductStatus);
    const deleteProductStatus = useAppSelector(selectDeleteProductStatus);
    const error = useAppSelector(selectError);
    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Products");

    useEffect(() => {
        // fetch products list when a successful mutation occurs
        if (upsertProductStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertProductStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertProductStatus());
            // close the modify dialog
            onProductDialogClose();
        }
        if (deleteProductStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteProductStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteProductStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteProductStatus.message as string);
        }
    }, [upsertProductStatus?.result, deleteProductStatus?.result]);

    const products = useAppSelector(selectErpProductList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('products-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [products]);

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the ERP Products' error={error}></ErrorMessage>
            );
        }

        if (products && products.length === 0) {
            // No products to show 
            return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New ERP Product" actionButtonClick={onAddProductClick} />
                :
                <NoRecordsMessage message="" />
            );
        }

        else if (products && products.length) {
            return (
                products.map((product: ErpProductModel) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={product.id}>
                        <ProductListItem
                            name={product.name}
                            id={product.id}
                            description={product.description}
                            isAptean={product.isAptean}
                            canDelete={canAddOrDelete}
                            onEditClick={editProduct}
                            onDeleteClick={deleteProduct}
                        />
                    </Grid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'product-skeleton-' + i;
                loaders.push(
                    <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <ProductListItem loading={true} onEditClick={editProduct} onDeleteClick={deleteProduct} />
                    </Grid>
                )
            }
            return loaders;
        }
    };

    const onAddProductClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected standard is set
        if (selectedProduct) {
            setSelectedProduct(undefined);
        }
        setOpenModify(true);
    };

    const onProductDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteProductStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedProduct on close.
        dispatch(clearError());
        setSelectedProduct(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchErpProductList());
    };

    const onProductDialogSave = (name: string, description: string, isAptean: boolean, id?: Maybe<string>) => {
        // upsert to save data
        dispatch(upsertProductData(name, description, id, isAptean));
    };

    const onDeleteDialogConfirm = (productId: string) => {
        // delete the selected product
        dispatch(deleteProductData(productId));
    }

    //edit selected product
    const editProduct = (id: string | undefined) => {
        if (products) {
            if (id && products.length) {
                let product = products.find(p => p.id === id);
                if (product) {
                    setSelectedProduct(product);
                    dispatch(clearError());
                    setOpenModify(true);
                }
            }
        }
    };

    //delete selected product
    const deleteProduct = (productId: string | undefined) => {
        if (products) {
            if (productId && products.length) {
                let product = products.find(p => p.id === productId);
                if (product) {
                    setSelectedProduct(product);
                    dispatch(captureDeleteProductStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    }

    return (
        <MainContentBox>
            <PageTitleBar text='ERP Products' id="products-title-comp">
                <PageTitleToolbarGrid item>
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Product"
                            onClick={onAddProductClick}
                            data-cy="add-new-product"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <ProductDialog
                isOpen={openModify}
                product={selectedProduct}
                onClose={onProductDialogClose}
                onSave={onProductDialogSave}
                error={error}
            ></ProductDialog>
            <DeleteDialog
                isOpen={openDelete}
                id={selectedProduct?.id}
                heading={'Delete ERP Product'}
                message={`Deletion of this product will also delete it from any associated Clients.\nAre you sure you want to delete '${selectedProduct?.name}'?`}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Products;