import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteCurrencyPayload, UpsertCurrencyPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    upsertCurrencyStatus?: UpsertCurrencyPayload;
    deleteCurrencyStatus?: DeleteCurrencyPayload;
}

const initialState: SliceState = {
    error: undefined,
    upsertCurrencyStatus: undefined,
    deleteCurrencyStatus: undefined,
};

export const slice = createSlice({
    name: 'currencies',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertCurrencyStatus: (state, action: PayloadAction<UpsertCurrencyPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertCurrencyStatus = action.payload;
        },

        captureDeleteCurrencyStatus: (state, action: PayloadAction<DeleteCurrencyPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteCurrencyStatus = action.payload;
        }
    }
});

export const selectCurrencyError = (state: RootState): Error | undefined => state.currencies.error;
export const selectUpsertCurrencyStatus = (state: RootState): UpsertCurrencyPayload | undefined => state.currencies.upsertCurrencyStatus;
export const selectDeleteCurrencyStatus = (state: RootState): DeleteCurrencyPayload | undefined => state.currencies.deleteCurrencyStatus;

export const {
    clearError,
    fetchError,
    captureUpsertCurrencyStatus,
    captureDeleteCurrencyStatus
} = slice.actions;

export default slice.reducer;