import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { NotificationFormatterTransactionsListQuery, GetNotificationFormatterTransactionsInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_NOTIFICATION_FORMATTER_TRANSACTIONS_LIST = gql`
    query notificationFormatterTransactionsList($input: GetNotificationFormatterTransactionsInput!) {
        notificationFormatterTransactions (input: $input) {
            nodes {
                id
                notificationFormatterId
                transactionId
                transaction {
                    id
                    name
                    description
                    direction
                    isActive
                }
            }
        }
    }
`;

export const queryNotificationFormatterTransactionList = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        queryInput: GetNotificationFormatterTransactionsInput
    }
) => {
    const { notificationFormatterId, transactionId, id } = args.queryInput;
    const { data, errors } = await client.query<NotificationFormatterTransactionsListQuery>({
        variables: {
            input: {
                notificationFormatterId,
                transactionId,
                id,
            }
        },
        query: QUERY_NOTIFICATION_FORMATTER_TRANSACTIONS_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.notificationFormatterTransactions?.nodes;
};