import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { CurrencyModel, RequestResult, UpsertCurrencyInput } from '../../gql-types.generated';
import { selectCurrencyError, clearError, selectUpsertCurrencyStatus, selectDeleteCurrencyStatus, captureUpsertCurrencyStatus, captureDeleteCurrencyStatus } from './CurrencySlice';
import { selectCurrencyList, setToastConfig, selectError } from '../EDIContainer/EDIContainerSlice';
import { upsertCurrency, deleteCurrency } from './CurrencyActions';
import { fetchCurrencyList } from '../EDIContainer/EDIContainerActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanAddDelete } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import CurrencyListItem from '../../components/listItems/CurrencyListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import CurrencyDialog from '../../components/dialogs/CurrencyDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';

interface CurrenciesProps {
    viewer: Viewer | undefined;
}

const Currencies: React.FC<CurrenciesProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState<undefined | CurrencyModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const deleteCurrencyStatus = useAppSelector(selectDeleteCurrencyStatus);
    const upsertCurrencyStatus = useAppSelector(selectUpsertCurrencyStatus);
    const error = useAppSelector(selectCurrencyError);
    const fetchError = useAppSelector(selectError);

    const canAddOrDelete = viewerCanAddDelete(viewer);

    useTitle("Currencies");

    useEffect(() => {
        // fetch areas list when a successful mutation occurs
        if (upsertCurrencyStatus?.result === RequestResult.Success) {
            dispatch(setToastConfig({
                message: upsertCurrencyStatus.message as string,
                severity: ToastSeverity.Success
            }));
            // remove upsert status
            dispatch(captureUpsertCurrencyStatus());
            // close the modify dialog
            onCurrencyDialogClose();
        }
        if (deleteCurrencyStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteCurrencyStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteCurrencyStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteCurrencyStatus.message as string);
        }
    }, [upsertCurrencyStatus?.result, deleteCurrencyStatus?.result]);

    const currencies = useAppSelector(selectCurrencyList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('currency-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [currencies]);

    const getContent = () => {
        if (error || fetchError) {
            return (
                <ErrorMessage title='Unable to load the Currencies' error={error ?? fetchError}></ErrorMessage>
            );
        }

        if (currencies && currencies.length === 0) {
            // No areas to show 
            return (canAddOrDelete ? <NoRecordsMessage actionButtonText="Add New Currency" actionButtonClick={onAddCurrencyClick} />
                :
                <NoRecordsMessage message="" />
            );
        } else if (currencies && currencies.length) {
            return (
                currencies.map((currency: CurrencyModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={currency.currencyCode}>
                        <CurrencyListItem
                            code={currency.currencyCode}
                            description={currency.description}
                            canDelete={canAddOrDelete}
                            onEditClick={handleEdit}
                            onDeleteClick={handleDelete}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'currency-skeleton-' + i;
                loaders.push(
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <CurrencyListItem code={''} description={''} loading={true} onEditClick={handleEdit} onDeleteClick={handleDelete} />
                    </CardListItemWrapperGrid>
                )
            }
            return loaders;
        }
    };

    const onAddCurrencyClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected currency is set
        if (selectedCurrency) {
            setSelectedCurrency(undefined);
        }
        setOpenModify(true);
    };

    const onCurrencyDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteCurrencyStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedCurrency on close.
        dispatch(clearError());
        setSelectedCurrency(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchCurrencyList());
    };

    const onCurrencyDialogSave = (upsertCurrencyData: UpsertCurrencyInput) => {
        // upsert to save data
        dispatch(upsertCurrency(upsertCurrencyData));
    };

    const onDeleteDialogConfirm = (currencyId: string) => {
        // delete the selected currency
        dispatch(deleteCurrency(currencyId));
    }

    //edit selected currency
    const handleEdit = (currencyId: string | undefined) => {
        if (currencies) {
            if (currencyId && currencies.length) {
                let currency = currencies.find(c => c.currencyCode === currencyId);
                if (currency) {
                    setSelectedCurrency(currency);
                    dispatch(clearError());
                    setOpenModify(true);
                }
            }
        }
    }

    //delete selected currency
    const handleDelete = (currencyId: string | undefined) => {
        if (currencies) {
            if (currencyId && currencies.length) {
                let currency = currencies.find(c => c.currencyCode === currencyId);
                if (currency) {
                    setSelectedCurrency(currency);
                    dispatch(captureDeleteCurrencyStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Currencies' id="currency-title-comp">
                <PageTitleToolbarGrid item>
                    {canAddOrDelete &&
                        <CreateNewButton
                            text="New Currency"
                            onClick={onAddCurrencyClick}
                            data-cy="add-new-currency"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <CurrencyDialog
                isOpen={openModify}
                currency={selectedCurrency}
                onClose={onCurrencyDialogClose}
                onSave={onCurrencyDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedCurrency?.currencyCode ?? ''}
                heading={'Delete Currency'}
                message={'Are you sure you want to delete \'' + selectedCurrency?.currencyCode + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Currencies;