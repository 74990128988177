import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeleteErpProductPayload, UpsertErpProductPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    upsertProductStatus?: UpsertErpProductPayload;
    deleteProductStatus?: DeleteErpProductPayload;
}

const initialState: SliceState = {
    error: undefined,
    upsertProductStatus: undefined,
    deleteProductStatus: undefined,
};

export const slice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertProductStatus: (state, action: PayloadAction<UpsertErpProductPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertProductStatus = action.payload;
        },

        captureDeleteProductStatus: (state, action: PayloadAction<DeleteErpProductPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteProductStatus = action.payload;
        }
    }
});

export const selectError = (state: RootState): Error | undefined => state.products.error;
export const selectUpsertProductStatus = (state: RootState): UpsertErpProductPayload | undefined => state.products.upsertProductStatus;
export const selectDeleteProductStatus = (state: RootState): DeleteErpProductPayload | undefined => state.products.deleteProductStatus;

export const {
    clearError,
    fetchError,
    captureUpsertProductStatus,
    captureDeleteProductStatus,
} = slice.actions;

export default slice.reducer;