import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ActionPayload, EdiActionServiceInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_EXECUTE_EDI_ACTION_SVC_ACTION = gql`
    mutation executeEdiActionSvcAction ($input: EdiActionServiceInput!){
        executeEdiActionSvcAction(input: $input) {
            message
            errors {
                code
                message
            }
            result
            data
        }
    }
`;

export const mutationExecuteEdiActionSvcAction = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        actionName: string;
        actionArguments?: string;
    }
    ): Promise<ActionPayload | undefined> => {
        const { actionName, actionArguments } = options as EdiActionServiceInput;
        const {data, errors } = await client.mutate<{executeEdiActionSvcAction: ActionPayload}>({
            variables: {
                input: {
                    actionName,
                    actionArguments
                }
            },
            mutation: MUTATION_EXECUTE_EDI_ACTION_SVC_ACTION
        });
        if (errors && errors.length > 0) {
            errors.forEach(error => {
                // Log error details in the console
                console.error(error);
            });
            // Friendly error to active user
            throw new Error('An error has occurred trying to execute edi service action');
        }
        if (data && data.executeEdiActionSvcAction) {
            return data.executeEdiActionSvcAction;
        }
    
        return undefined;
    };