import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EdiStandardModel, UpsertEdiStandardPayload, DeleteByIdPayload } from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    upsertEdiStandardStatus?: UpsertEdiStandardPayload;
    deleteEdiStandardStatus?: DeleteByIdPayload;
}

const initialState: SliceState = {
    error: undefined,
    upsertEdiStandardStatus: undefined,
    deleteEdiStandardStatus: undefined
};

export const slice = createSlice({
    name: 'ediStandards',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        captureUpsertEdiStandardStatus: (state, action: PayloadAction<UpsertEdiStandardPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertEdiStandardStatus = action.payload;
        },

        captureDeleteEdiStandardStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteEdiStandardStatus = action.payload;
        }
    }
});

export const selectError = (state: RootState): Error | undefined => state.ediStandards.error;
export const selectUpsertEdiStandardStatus = (state: RootState): UpsertEdiStandardPayload | undefined => state.ediStandards.upsertEdiStandardStatus;
export const selectDeleteEdiStandardStatus = (state: RootState): DeleteByIdPayload | undefined => state.ediStandards.deleteEdiStandardStatus;

export const { 
    clearError, 
    fetchError, 
    captureUpsertEdiStandardStatus,
    captureDeleteEdiStandardStatus
} = slice.actions;

export default slice.reducer;