import { ReactElement, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { TransactionMapModel, RequestResult, GetTransactionMapInput } from '../../gql-types.generated';
import { selectMapList, selectError, clearError, clearState, selectDeleteMapStatus, selectUpsertMapStatus, captureUpsertMapStatus, captureDeleteMapStatus, selectRequestsInFlight, selectMapPagingResult } from './MapsSlice';
import { fetchMapList, deleteMapData, upsertMapData } from './MapsActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../EDIContainer/EDIContainerSlice';
import { Viewer, defaultPageSize, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import MapListItem from '../../components/listItems/MapListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import MapDialog from '../../components/dialogs/MapDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import FiltersButton from '../../components/buttons/FiltersButton';
import MapsFilterBar from '../../components/filters/MapsFilterBar';
import { useNavigate } from 'react-router-dom';
import NoResultsMessage from '../../components/NoResultsMessage';


const WrappedLoadingGrid = styled(Grid)((props) => ({
    paddingTop: '16px',
    width: '100% !important',
    marginLeft: '0px !important',
}));

let skeletonKey = 0; // add multiple times when scrolling, need unique Ids

interface MapsProps {
    viewer: Viewer | undefined;
}

const Maps: React.FC<MapsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [openModify, setOpenModify] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedMap, setSelectedMap] = useState<undefined | TransactionMapModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [filtersCleared, setFiltersCleared] = useState(false);
    const [filterVersion, setFilterVersion] = useState<string | undefined>(undefined);
    const [filterDescription, setFilterDescription] = useState<string | undefined>(undefined);
    const [filterTransactionIds, setFilterTransactionIds] = useState<string[]>([]);
    const [filterCount, setFilterCount] = useState(0);

    const deleteMapStatus = useAppSelector(selectDeleteMapStatus);
    const upsertMapStatus = useAppSelector(selectUpsertMapStatus);
    const error = useAppSelector(selectError);
    const requestsInFlight = useAppSelector(selectRequestsInFlight);
    const mapPagingResult = useAppSelector(selectMapPagingResult);

    const pageSize = defaultPageSize;

    const maps = useAppSelector(selectMapList);

    const canEdit = viewerCanEdit(viewer);

    useTitle("Maps");

    useEffect(() => {
        // fetch maps list when a successful mutation occurs
        if (upsertMapStatus?.result === RequestResult.Success) {

            // navigate to the new map if it was just added
            if (selectedMap === undefined) {
                // if a mapId was successfully returned, navigate 
                // to the new map details to allow user to 
                // add partners right away
                if (upsertMapStatus.transactionMapId) {
                    // simulate a card click to navigate
                    cardClickAction(upsertMapStatus.transactionMapId);
                }
            }

            // close the modify dialog
            onMapDialogClose();

            dispatch(setToastConfig({
                message: upsertMapStatus.message as string,
                severity: ToastSeverity.Success
            }));

            // remove upsert status
            dispatch(captureUpsertMapStatus());
        }
    }, [upsertMapStatus?.result]);

    useEffect(() => {
        if (deleteMapStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deleteMapStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deleteMapStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deleteMapStatus.message as string);
        }
    }, [deleteMapStatus?.result]);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const titleHeight = document.getElementById('maps-title-comp')?.clientHeight || 0;
        // if filters are open, include that in the calculation
        let filterBarHeight = 0;
        if (filtersOpen) {
            filterBarHeight = document.getElementById('maps-filter-bar')?.clientHeight || 0;
        }
        let totalHeaderAreaHeight = titleHeight + filterBarHeight;
        if (totalHeaderAreaHeight > 0)
            setContentAreaHeight(`calc(100% - ${totalHeaderAreaHeight}px)`);
    }, [maps, filtersOpen]);

    useEffect(() => {
        if (maps) {
            let numFilters = 0;
            if (filterVersion && filterVersion.length > 0) {
                numFilters += 1;
            }
            if (filterDescription && filterDescription.length > 0) {
                numFilters += 1;
            }
            if (filterTransactionIds && filterTransactionIds.length > 0) {
                numFilters += 1;
            }
            setFilterCount(numFilters);
        }
    }, [maps, filterVersion, filterDescription, filterTransactionIds]);

    useEffect(() => {
        if (!maps && requestsInFlight > 0) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [maps, requestsInFlight]);

    const getRefreshInput = (endEdge: string | undefined, filterVrsn: string | undefined, filterDesc: string | undefined, filterTranIds: string[]) => {
        return {
            after: endEdge,
            limit: pageSize,
            description: filterDesc,
            standardVersion: filterVrsn,
            transactionIds: filterTranIds
        } as GetTransactionMapInput
    };

    const loadPage = (endEdge: string | undefined) => {
        let input = getRefreshInput(endEdge, filterVersion, filterDescription, filterTransactionIds);
        dispatch(fetchMapList(input));
    };

    const handlePageLoad = (page: number) => {
        if (!mapPagingResult) {
            return;
        }
        if (!mapPagingResult.cursor?.nextPage) {
            return;
        }

        loadPage(mapPagingResult.cursor.nextPage);
    };


    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/transactionmap/" + id;
            navigate(route);
        }
    };

    const hasMoreMaps = (mapPagingResult?.cursor?.nextPage !== null && mapPagingResult?.cursor?.nextPage !== undefined && mapPagingResult?.cursor?.nextPage !== '') as boolean;
    
    const getLoaders = (wrapped?: boolean | undefined) => {
        let loaders = [];
        skeletonKey++;
        for (let i = 0; i < 12; i++) {
            const key = 'map-skeleton-' + skeletonKey++;
            loaders.push(
                <CardListItemWrapperGrid item xs={12} sm={12} md={6} lg={4} xl={3} key={key}>
                    <MapListItem loading={true} onEditClick={editMap} onDeleteClick={deleteMap} clickAction={cardClickAction} />
                </CardListItemWrapperGrid>
            )
        }
        if (wrapped) {
            const gridKey = 'map-skeleton-grid-' + skeletonKey++;
            return <WrappedLoadingGrid container spacing={2} key={gridKey}> {loaders}</WrappedLoadingGrid>
        }
        return loaders;
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load the Transaction Maps' error={error}></ErrorMessage>
            );
        }

        if (!maps && requestsInFlight > 0) {
            return getLoaders();
        }
        else if (maps && maps.length > 0) {
            return (
                maps.map((map: TransactionMapModel) => (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={map.id}>
                        <MapListItem
                            map={map}
                            onEditClick={editMap}
                            onDeleteClick={deleteMap}
                            clickAction={cardClickAction}
                        />
                    </Grid>
                ))
            );
        } else {
            // No maps to show 
            // Display appropriate no results/records found image/message
            if (filterCount > 0) {
                return (<NoResultsMessage />);
            }
            else {
                return (canEdit ? <NoRecordsMessage actionButtonText="Add New Transaction Map" actionButtonClick={onAddMapClick} />
                    :
                    <NoRecordsMessage message="" />
                );
            }
        }
    };

    const handleClearError = () => {
        dispatch(clearError());
    };

    const onAddMapClick = () => {
        // Clear error and open dialog
        handleClearError();
        // ensure no previously selected standard is set
        if (selectedMap) {
            setSelectedMap(undefined);
        }
        setOpenModify(true);
    };

    const onMapDialogClose = () => {
        setOpenModify(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeleteMapStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedMap on close.
        handleClearError();
        setSelectedMap(undefined);
        // Refresh list to bring in potential updates
        loadPage(undefined);
    };

    const onMapDialogSave = (transactionId: string, description: string, documentString?: string, sourceFileName?: string, standardVersion?: string, id?: string) => {
        // upsert to save data
        dispatch(upsertMapData(
            transactionId,
            description,
            documentString,
            sourceFileName,
            standardVersion,
            id
        ));
    };

    const onDeleteDialogConfirm = (id: string) => {
        // delete the selected map
        dispatch(deleteMapData(id));
    }

    //edit selected map
    const editMap = (id: string | undefined) => {
        if (id && maps?.length) {
            let map = maps.find(t => t.id === id);
            if (map) {
                setSelectedMap(map);
                dispatch(clearError());
                setOpenModify(true);
            }
        }
    };

    //delete selected map
    const deleteMap = (id: string | undefined) => {
        if (id && maps?.length) {
            let map = maps.find(t => t.id === id);
            if (map) {
                setSelectedMap(map);
                dispatch(captureDeleteMapStatus());
                setDeleteErrorMessage('');
                setOpenDelete(true);
            }
        }
    }

    const onFiltersClick = () => {
        // Show/hide filters bar
        setFiltersOpen(!filtersOpen);
        // reset filters cleared to false since not clicking close button on bar at this point
        setFiltersCleared(false);
    };

    const onFilterBarClose = () => {
        // set filters as cleared
        setFiltersCleared(true);
        setFilterCount(0);
        // hide filter bar
        setFiltersOpen(false);
    };

    const refreshFilters = (filterVrsn: string | undefined, filterDesc: string | undefined, filterTranIds: string[]) => {
        setFilterVersion(filterVrsn);
        setFilterDescription(filterDesc);
        setFilterTransactionIds(filterTranIds);
        dispatch(clearState());
        let input = getRefreshInput(undefined, filterVrsn, filterDesc, filterTranIds)
        dispatch(fetchMapList(input));
    }

    return (
        <MainContentBox>
            <PageTitleBar text='Transaction Maps' id="maps-title-comp">
                <PageTitleToolbarGrid item>
                    <FiltersButton
                        onClick={onFiltersClick}
                        filterCount={filterCount}
                        filtersCleared={filtersCleared}
                        disabled={isLoading}
                        aria-label="filter button"
                        data-cy="map-filters"
                    />
                    {canEdit &&
                        <CreateNewButton
                            text="New Map"
                            onClick={onAddMapClick}
                            data-cy="add-new-map"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <MapsFilterBar
                id="map-filter-bar"
                visible={filtersOpen}
                loading={isLoading}
                viewer={viewer}
                onFilterChanged={refreshFilters}
                onClose={onFilterBarClose}
            />
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={handlePageLoad}
                    hasMore={hasMoreMaps}
                    loader={getLoaders(true) as ReactElement}
                    useWindow={false}
                    initialLoad={false}
                >
                    <CardListContentGrid container spacing={2}>
                        {getContent()}
                    </CardListContentGrid>
                </InfiniteScroll>
            </CardListScrollBox>
            <MapDialog
                isOpen={openModify}
                map={selectedMap}
                onClose={onMapDialogClose}
                onSave={onMapDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedMap?.id}
                heading={'Delete Transaction Map'}
                message={'Are you sure you want to delete \'' + selectedMap?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
        </MainContentBox>
    );
};

export default Maps;