import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertPostProcessingBlobInput, UpsertPostProcessingBlobPayload } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_POST_PROCESSING_BLOB = gql`
    mutation upsertPostProcessingBlob($input: UpsertPostProcessingBlobInput!) {
        upsertPostProcessingBlob(input: $input){
            message
            errors {
                code
                message
            }
            result
            postProcessingBlob {
                id
            }
        }
    }
`;

export const mutationUpsertPostProcessingBlob = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: { upsertPostProcessingData: UpsertPostProcessingBlobInput }
): Promise< UpsertPostProcessingBlobPayload | undefined> => {
    const { description, id, processBlobString } = args.upsertPostProcessingData;
    const { data, errors } = await client.mutate<{ upsertPostProcessingBlob: UpsertPostProcessingBlobPayload}>({
        variables: { input: {
            description,
            id,
            processBlobString,
        }},
        mutation: MUTATION_UPSERT_POST_PROCESSING_BLOB
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of postProcessingBlob.');
    }
    if (data && data.upsertPostProcessingBlob) {
        return data.upsertPostProcessingBlob;
    }

    return undefined;
};