import { useState } from 'react';

import { ButtonGroup, IconButton, Menu, MenuItem, styled } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import OpenIcon from '@mui/icons-material/OpenInBrowser';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DocumentArchiveModel } from '../../gql-types.generated';
import theme from '../../Theme';
import { DownloadOperation } from '../../features/DocumentArchives/DocumentArchivesSlice';

const DownloadIconButton = styled(IconButton)((props) => ({
  padding: '0',
  '&:hover': {
    color: theme.palette.primary.dark,
    fontWeight: 'bold'
  }
}));

const ArrowIconButton = styled(IconButton)((props) => ({
  padding: '0',
  '&:hover': {
    color: theme.palette.primary.dark,
    fontWeight: 'bold'
  }
}));
  
interface DownloadSplitButtonProps {
  onDownloadHtmlClick: () => void;
  onDownloadRawEdiClick: () => void;
  onDownloadXmlClick: () => void;
  onDownloadAcknowledgementClick?: () => void;
  openInTab?: boolean;
}
  
export const DownloadSplitButton: React.FC<DownloadSplitButtonProps> = (props: DownloadSplitButtonProps) => {
    const { onDownloadHtmlClick, onDownloadRawEdiClick, onDownloadXmlClick, onDownloadAcknowledgementClick, openInTab=false } = props;
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isDownloadMenuOpen = Boolean(anchorEl);

    const onDownloadMenuClick = (event: React.MouseEvent<HTMLButtonElement>, selectedRow?: DocumentArchiveModel) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();

      setAnchorEl(event.currentTarget);
    };

    const handleDownloadMenuClose = (event: React.MouseEvent<any>) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();
      
      let buttonDataSet = (event.target as HTMLElement).dataset;
      
      if (buttonDataSet.operation === DownloadOperation.html) {
        onDownloadHtmlClick();
      }
      else if (buttonDataSet.operation === DownloadOperation.raw) {
        onDownloadRawEdiClick();
      }
      else if (buttonDataSet.operation === DownloadOperation.xml) {
        onDownloadXmlClick();
      }
      else if (buttonDataSet.operation === DownloadOperation.ack) {
        if (onDownloadAcknowledgementClick) {
          onDownloadAcknowledgementClick();
        }
      }
      
      setAnchorEl(null);
    };

    const handleDownloadIconButtonClick = (event: React.MouseEvent<any>) => {
      // this button could be inside of a grid that could have a click event,
      // stopPropagation to prevent that click event from firing
      event.stopPropagation();
      
      onDownloadHtmlClick();
            
      setAnchorEl(null);
    };

    const labelPrefix = openInTab ? "Open" : "Download";
    const buttonIcon = openInTab ? <OpenIcon /> : <DownloadIcon />;

    return (
      <>
        <ButtonGroup variant="text" aria-label="download button group">
          <DownloadIconButton
              aria-label={`${labelPrefix} HTML`}
              title={`${labelPrefix} HTML`}
              color="primary"
              size="medium"
              onClick={handleDownloadIconButtonClick}
          >
            {buttonIcon}
          </DownloadIconButton >
          <ArrowIconButton
              id="options-button"
              aria-label={`${labelPrefix} Options Menu`}
              aria-haspopup="menu"
              title={`Options for ${labelPrefix}`}
              color="primary"
              size="small"
              onClick={onDownloadMenuClick}
          >
            <ArrowDropDownIcon />
          </ArrowIconButton >
          
        </ButtonGroup>
        <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={isDownloadMenuOpen}
            onClose={handleDownloadMenuClose}
        >
            <MenuItem
                data-operation="html"
                onClick={handleDownloadMenuClose}
              >
                {`${labelPrefix} HTML`}
            </MenuItem>
            <MenuItem
                data-operation="raw"
                onClick={handleDownloadMenuClose}
            >
                {`${labelPrefix} EDI`}
            </MenuItem>
            <MenuItem
                data-operation="xml"
                onClick={handleDownloadMenuClose}
            >
                {`${labelPrefix} XML`}
            </MenuItem>
            {onDownloadAcknowledgementClick &&
              <MenuItem
                  data-operation="ack"
                  onClick={handleDownloadMenuClose}
              >
                  {`${labelPrefix} 997`}
              </MenuItem>
            }
        </Menu> 
      </>
    );
};
  
