import { useState } from 'react';
import { IconButton, Skeleton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RootCard, RootCardHeader } from '../../util/SharedStyles';

interface CurrencyListItemProps {
    code: string;
    description: string;
    canDelete?: boolean;
    loading?: boolean | false;
    onEditClick: (currencyCode: string) => void;
    onDeleteClick: (currencyCode: string) => void;
}

const CurrencyListItem: React.FC<CurrencyListItemProps> = (props: CurrencyListItemProps) => {
    const { code, description, loading = false, canDelete = false } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        let buttonDataSet = (event.target as HTMLElement).dataset;
        
        if (buttonDataSet.operation === "edit") {
            props.onEditClick(code);
        }
        else if (buttonDataSet.operation === "delete")
        {
            props.onDeleteClick(code);
        }
        setAnchorEl(null);
    };

    let currencyAction = loading? undefined: (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let currencyTitle = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        code
    );

    let currencySubheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
   ) : (
       description
   );

    return (
        <RootCard>
            <RootCardHeader
                title={currencyTitle} 
                subheader={currencySubheader}
                //action prop for adding 3-dot menu options button
                action={currencyAction}
            />
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                    >
                    Edit
                </MenuItem>
                { canDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    >
                    Delete    
                </MenuItem>
                }
            </Menu> 
        </RootCard>
    );
};

export default CurrencyListItem;

