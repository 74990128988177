import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { Maybe, RequestResult } from '../../gql-types.generated';
import { getErrorMessage } from '../../util/Common';
import { fetchError, captureUpsertEdiStandardStatus, captureDeleteEdiStandardStatus } from './EDIStandardsSlice';
import { mutationUpsertEdiStandard } from '../../gql/MutationUpsertEdiStandard';
import { mutationDeleteEntity } from '../../gql/MutationDeleteEntity';


export const upsertEdiStandardData = (name: string, description: string, id?: Maybe<string>): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertStandardStatus = await mutationUpsertEdiStandard(gqlClient, {
                name,
                description,
                id,
            });
            if (upsertStandardStatus) {
                if (upsertStandardStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertStandardStatus.message } as Error));
                    getErrorMessage(upsertStandardStatus.message as string, upsertStandardStatus.errors, true, true);
                }
                dispatch(captureUpsertEdiStandardStatus(upsertStandardStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteEdiStandardData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteStandardStatus = await mutationDeleteEntity(gqlClient, {
                id,
                entityName: "EdiStandard"
            });
            if (deleteStandardStatus) {
                if (deleteStandardStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteStandardStatus.message as string, deleteStandardStatus.errors, true, true);
                }
                dispatch(captureDeleteEdiStandardStatus(deleteStandardStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };




