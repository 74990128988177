import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertAttachmentInput, UpsertClientPartnerAttachmentPayload, UpsertClientPartnerAttachmentInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CLIENT_PARTNER_ATTACHMENTS = gql`
    mutation upsertClientPartnerAttachments($input: UpsertClientPartnerAttachmentInput!){
        upsertClientPartnerAttachment(input: $input){
            message
            errors {
                code
                message
            }
            result
        }
    }
`;

export const mutationUpsertClientPartnerAttachments = async (
    client: ApolloClient<NormalizedCacheObject>,
    options: {
        attachments?: UpsertAttachmentInput[];
        clientPartnerId: string;
    }
): Promise<UpsertClientPartnerAttachmentPayload | undefined> => {
    const { attachments, clientPartnerId } = options as UpsertClientPartnerAttachmentInput;
    const { data, errors } = await client.mutate<{ upsertClientPartnerAttachment: UpsertClientPartnerAttachmentPayload }>({
        variables: {
            input: {
                attachments,
                clientPartnerId,
            }
        },
        mutation: MUTATION_UPSERT_CLIENT_PARTNER_ATTACHMENTS
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add client partner attachments.');
    }
    if (data && data.upsertClientPartnerAttachment) {
        return data.upsertClientPartnerAttachment;
    }

    return undefined;
};