import { useState } from 'react';
import { IconButton, Skeleton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { RootCard, RootCardHeader } from '../../util/SharedStyles';

interface StandardListItemProps {
    name?: string | undefined;
    id?: string | undefined;
    description?: string | undefined;
    loading?: boolean | false;
    canDelete?: boolean;
    onEditClick: (id: string | undefined) => void;
    onDeleteClick: (id: string) => void;
}

const EDIStandardListItem: React.FC<StandardListItemProps> = (props: StandardListItemProps) => {
    const { name, description, loading = false, canDelete = false } = props;
    const id = props.id as string;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: React.MouseEvent<any>) => {
        let buttonDataSet = (event.target as HTMLElement).dataset;

        if (buttonDataSet.operation === "edit") {
            props.onEditClick(id);
        }
        else if (buttonDataSet.operation === "delete") {
            props.onDeleteClick(id);
        }

        setAnchorEl(null);
    };

    let action = loading ? undefined : (
        < IconButton
            aria-label="options-button"
            id="options-button"
            onClick={handleMenuClick}
        >
            <MoreVertIcon />
        </IconButton >
    );

    let title = loading ? (
        <Skeleton animation="wave" height={32} width="50%" />
    ) : (
        name
    );

    let subheader = loading ? (
        <Skeleton animation="wave" height={24} width="30%" />
    ) : (
        description
    );

    return (
        <RootCard>
            <RootCardHeader
                title={title}
                subheader={subheader}
                //action prop for adding 3-dot menu options button
                action={action}
            />
            <Menu
                id="card-options-menu"
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    data-operation="edit"
                    onClick={handleMenuClose}
                >
                    Edit
                </MenuItem>
                { canDelete &&
                <MenuItem
                    data-operation="delete"
                    onClick={handleMenuClose}
                    >
                    Delete    
                </MenuItem>
                }
            </Menu>
        </RootCard>
    );
};

export default EDIStandardListItem;