import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { Maybe, RequestResult } from '../../gql-types.generated';
import { getErrorMessage } from '../../util/Common';
import { fetchError, captureDeleteBusinessAreaStatus, captureUpsertBusinessAreaStatus } from './BusinessAreasSlice';
import { mutationUpsertBusinessArea } from '../../gql/MutationUpsertBusinessArea';
import { mutationDeleteBusinessArea } from '../../gql/MutationDeleteBusinessArea';

export const upsertBusinessAreaData = (name: string, description: string, id?: Maybe<string>): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const upsertAreaStatus = await mutationUpsertBusinessArea(gqlClient, {
                name,
                description,
                id,
            });
            if (upsertAreaStatus) {
                if (upsertAreaStatus.result === RequestResult.Fail) {
                    dispatch(fetchError({ message: upsertAreaStatus.message } as Error));
                    getErrorMessage(upsertAreaStatus.message as string, upsertAreaStatus.errors, true, true);
                }
                dispatch(captureUpsertBusinessAreaStatus(upsertAreaStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const deleteBusinessAreaData = (id: string): AppThunk =>
    async (dispatch: AppDispatch): Promise<void> => {
        try {
            const deleteAreaStatus = await mutationDeleteBusinessArea(gqlClient, {
                id,
            });
            if (deleteAreaStatus) {
                if (deleteAreaStatus.result === RequestResult.Fail) {
                    getErrorMessage(deleteAreaStatus.message as string, deleteAreaStatus.errors, true, true);
                }
                dispatch(captureDeleteBusinessAreaStatus(deleteAreaStatus));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };



