import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { AddClientPartnerPayload, AddClientPartnerInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_ADD_CLIENT_PARTNER = gql`
    mutation addClientPartner($input: AddClientPartnerInput!){
        addClientPartner(input: $input){
            message
            errors {
                code
                message
            }
            result
            clientPartnerId
        }
    }
`;

export const mutationAddClientPartner = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: {
        clientId: string;
        partnerId: string;
    } 
): Promise<AddClientPartnerPayload | undefined> => {
    const { clientId, partnerId } = args as AddClientPartnerInput;
    const { data, errors } = await client.mutate<{ addClientPartner: AddClientPartnerPayload}>({
        variables: { 
            input: { 
                clientId,
                partnerId
            } 
        },
        mutation: MUTATION_ADD_CLIENT_PARTNER
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred trying to add client partner.');
    }
    if (data && data.addClientPartner) {
        return data.addClientPartner;
    }

    return undefined;
};