import { ChangeEvent, useEffect, useState } from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker, DateValidationError } from "@mui/x-date-pickers";
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import { Grid, TextField, styled } from "@mui/material";
import { DialogGridWithTopMargin, DialogGridColumn } from "../../util/SharedStyles";
import { SystemNotificationModel } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";


const DatePickerWrapper = styled('div')((props) => ({
    width: '100%',
}));

interface DialogProps {
    isOpen: boolean;
    notification: SystemNotificationModel | undefined;
    applicationNotifications: SystemNotificationModel[] | undefined;
    onClose: () => void;
    onSave: (id: string, startDate?: DateTime, endDate?: DateTime, message?: string) => void;
    error?: Error | undefined;
}

const SystemNotificationDialog: React.FC<DialogProps> = props => {
    const { isOpen, applicationNotifications, notification, onClose, onSave, error } = props;
    const id = notification?.id;
    
    const currentDate = DateTime.now();
    // make the minimum date to be today so that can't go previous to today
    const minDateStart = currentDate;
    
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [dateStart, setDateStart] = useState<DateTime | undefined>(undefined);
    const [dateEnd, setDateEnd] = useState<DateTime | undefined>(undefined);
    const [dateStartErrorText, setDateStartErrorText] = useState('');
    const [dateStartInvalid, setDateStartInvalid] = useState(false);
    const [dateEndErrorText, setDateEndErrorText] = useState('');
    const [dateEndInvalid, setDateEndInvalid] = useState(false);
    const [unavailableDates, setUnavailableDates] = useState<DateTime[]>([]);
     
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form

    const setDatesToDisable = () => {
        let dates: DateTime[] = [];
        let notificationStartCompareDate = DateTime.fromISO(notification?.dateStart).minus({ days: 1});
        let notificationEndCompareDate = DateTime.fromISO(notification?.dateEnd).plus({ days: 1});
        applicationNotifications?.forEach(n => {
            if (n.id !== id && n.dateStart && n.dateEnd) {
                let compareDate = DateTime.fromISO(n.dateStart);
                let lastDate = DateTime.fromISO(n.dateEnd);
                while (compareDate <= lastDate) {
                    dates.push(compareDate);
                    compareDate = compareDate.plus({ days: 1});
                }
                while (notificationStartCompareDate > lastDate) {
                    dates.push(notificationStartCompareDate);
                    notificationStartCompareDate = notificationStartCompareDate.minus({ days: 1});
                }
                while (notificationEndCompareDate < lastDate) {
                    dates.push(notificationEndCompareDate);
                    notificationEndCompareDate = notificationEndCompareDate.plus({ days: 1});
                }
            }
        });
        setUnavailableDates(dates);
    };

    const setSystemNotificationState = () => {
        // if have a notification, then populate for Edit
        if (notification) {
            if (notification.dateStart) {
                setDateStart(DateTime.fromISO(notification.dateStart));
            }
            if (notification.dateEnd) {
                setDateEnd(DateTime.fromISO(notification.dateEnd));
            }
            if (notification.message) {
                setMessage(notification.message);
            }
        }
        else {
            resetInitialState();
        }

        if (applicationNotifications?.length) {
            setDatesToDisable();
        }
    };

    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (isOpen) {
            setSystemNotificationState();
        } else {
            resetInitialState();
        }
    }, [isOpen]);

    const resetInitialState = () => {
        setUnavailableDates([]);
        setSubmitted(false);
        setIsFormDirty(false);
        setDateStart(undefined);
        setDateEnd(undefined);
        setDateStartErrorText('');
        setDateEndErrorText('');
        setDateStartInvalid(false);
        setDateEndInvalid(false);
        setMessage(undefined);
    };

    const onError = () => {
        setSubmitted(false);
    };

    const isFormValid = () => {
        // both dates either need be set or not set
        if ((dateStart !== undefined && dateEnd === undefined) || (dateStart === undefined && dateEnd !== undefined)) {
            return false;
        }
        let validDateStart = !dateStartInvalid;
        let validDateEnd = !dateEndInvalid;
        
        return isFormDirty && validDateStart && validDateEnd;
    };

    const submitForm = () => {
        if (isFormValid()) {
            setSubmitted(true);
            onSave(id, dateStart, dateEnd, message);
        }
    };

    // onChange, onError handlers
    const onMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setMessage(event.target.value);
    };

    const onDateStartChange = (value: DateTime | null) => {
        setIsFormDirty(true);
        setDateStart(value ?? undefined);
        if (value) {
            setDateStart(value);
            let endDate = value.plus({ days: 7});
            setDateEnd(endDate);
            validateDateEnd(endDate, value);
        }
    };

    const onDateEndChange = (value: DateTime | null) => {
        setIsFormDirty(true);
        setDateEnd(value ?? undefined);
        validateDateEnd(value, dateStart);
    };

    const validateDateEnd = (endDate: DateTime | null, startDate?: DateTime) => {
        if (endDate) {
            if (!endDate.isValid) {
                setDateEndInvalid(true);
                setDateEndErrorText("Invalid Date");
                return;
            }
            if (startDate) {
                endDate = endDate.startOf('day');
                let compareStartDate = startDate.startOf('day');
                if (endDate <= compareStartDate) {
                    setDateEndInvalid(true);
                    setDateEndErrorText("Date cannot be 'Date Start' or prior.");
                    return;
                } else {
                    var valid = validateDateRange(compareStartDate, endDate);
                    if (!valid) {
                        setDateEndInvalid(true);
                        setDateEndErrorText("A notification already exists for range.");
                        return;
                    }
                }
            }
        } 

        // no errors
        setDateEndErrorText("");
        setDateEndInvalid(false);
        
    };

    const validateDateRange = (startDate: DateTime, endDate: DateTime) => {
        // can't have a date range that overlaps with another notification date range
        let dates: DateTime[] = [];
        while (startDate <= endDate) {
            dates.push(startDate);
            startDate = startDate.plus({ days: 1});
        }
        let invalidDates = dates.filter(d => {
            return unavailableDates.some(u => u.equals(d));
        });
        return invalidDates?.length === 0;
    };

    const disableNotificationOverlap = (date: DateTime) => {
        let compareDate = date.startOf('day');
        let invalidDate = unavailableDates.some(u => u.equals(compareDate));
        return !!invalidDate;
    };

    const onDateStartError = (reason: DateValidationError, value: DateTime | null) => {
        let errorReason = reason;
        if (errorReason) {
            setDateStartInvalid(true);
            if (errorReason === "minDate" || errorReason === "disablePast") {
                setDateStartErrorText("Date must be today or later.")
            } else if (errorReason === "shouldDisableDate") {
                setDateStartErrorText("A notification already exists for range.")
            } else { 
                setDateStartErrorText("Invalid Date");
            }
        } else {
            // null errorReason means valid
            setDateStartInvalid(false);
            setDateStartErrorText("");
        }
    };
         
    const messageProps = {
        'aria-label': 'message',
    };

    // form
    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id={id}
            entityName={`${capitalize(notification?.notificationType || '')} Notification`}
            onClose={onClose}
            onSave={submitForm}
            validate={isFormValid}
            onError={onError}
            error={error}
            maxWidth='sm'
        >
            <Grid container spacing={2} item xs={12}>
                <DialogGridWithTopMargin container item columns={{ xs: 1, sm: 2 }} spacing={4} xs={12}>
                    <DialogGridColumn container item xs={12} sm={6}>
                        <Grid item xs={12}>
                           <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePicker
                                    label="Start Date"
                                    value={dateStart ?? null}
                                    views={['year','month','day']}
                                    openTo={'day'}
                                    minDate={minDateStart}
                                    disabled={submitted}
                                    disablePast={true}
                                    disableHighlightToday
                                    shouldDisableDate={disableNotificationOverlap}
                                    onChange={onDateStartChange}
                                    onError={onDateStartError}
                                    slotProps={{
                                        field: { clearable: true },
                                        textField: {
                                            variant: "standard",
                                            fullWidth: true,
                                            helperText: dateStartErrorText,
                                            inputProps: {'aria-label': 'date start'}
                                        },
                                    }}
                                    data-cy="dialog-notification-date-start"
                                ></DatePicker>
                            </LocalizationProvider>
                       </Grid>
                    </DialogGridColumn>
                    <DialogGridColumn container item xs={12} sm={6}>
                        <Grid item xs={12}>
                           <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePickerWrapper>
                                    <DatePicker
                                        label="End Date (will be hidden on this date)"
                                        value={dateEnd ?? null}
                                        views={['year','month','day']}
                                        openTo={'day'}
                                        minDate={dateStart?.plus({ days: 1 })}
                                        disableHighlightToday
                                        shouldDisableDate={disableNotificationOverlap}
                                        disabled={submitted}
                                        onChange={onDateEndChange}
                                        slotProps={{
                                            field: { clearable: true },
                                            textField: {
                                                variant: "standard",
                                                fullWidth: true,
                                                helperText: dateEndErrorText,
                                                error: dateEndInvalid,
                                                inputProps: {'aria-label': 'date end'}
                                            },
                                        }}
                                        data-cy="dialog-notification-date-end"
                                    ></DatePicker>
                                </DatePickerWrapper>
                            </LocalizationProvider>
                        </Grid>
                    </DialogGridColumn>
                </DialogGridWithTopMargin>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-notification-message"
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={6}
                        disabled={submitted}
                        value={message ?? ''}
                        label="Message"
                        inputProps={messageProps}
                        onChange={onMessageChange}
                        autoComplete="off"
                        data-cy="dialog-notification-message"
                        variant="standard"
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default SystemNotificationDialog;