
import { Alert, AlertTitle, Grid } from '@mui/material'

/** Component for dislpaying the filter input control on list pages */

interface FilterInputProps {
    error?: Error | undefined;
    title?: string | undefined
}

const ErrorMessage: React.FC<FilterInputProps> = (props: FilterInputProps) => {
    const { error, title = 'Unexpected Error' } = props;
    let errorMessage = error?.message;
    
    return (
        <Grid>
            <Alert severity="error">
                <AlertTitle>{title}</AlertTitle>
                {errorMessage}
            </Alert>
        </Grid>
    )
};

export default ErrorMessage;