import { Grid, TextField } from "@mui/material";
import { ChangeEvent, useEffect, useState } from 'react';
import { PricingModel } from '../../gql-types.generated';
import AddEditDialog from "./AddEditDialog";

interface DialogProps {
    isOpen: boolean;
    pricing?: PricingModel | null | undefined;
    onClose: () => void;
    onClone: (pricingToCloneId: string, description: string, minServiceFees?: number) => void;
    error?: Error | undefined;
}

const ClonePricingDialog: React.FC<DialogProps> = props => {
    const { isOpen, pricing, onClose, onClone, error } = props;
    const idOfPricingToClone = pricing?.id;
    const descriptionOfPricingToClone = pricing?.description;
    const [isFormDirty, setIsFormDirty] = useState(false); // Dirty state of the form.
    const [minServiceFees, setMinServiceFees] = useState<number | undefined>(undefined); 
    const [serviceFeesInvalid, setServiceFeesInvalid] = useState(false);
    const [description, setDescription] = useState<string>('');
    const [submitted, setSubmitted] = useState(false); // Submitted state of the form
    
    useEffect(() => {
        // set submitted to false and clear fields when dialog is closed
        if (!isOpen) {
            setSubmitted(false);
            setMinServiceFees(undefined);
            setDescription('');
            setIsFormDirty(false);
        }
    }, [isOpen]);
    
    const onError = () => {
        setSubmitted(false);
    }

    const isFormValid = () => {
        let requiredCheck = (description && description?.length > 0) ? true : false;
        return isFormDirty && requiredCheck && !serviceFeesInvalid;
    };

    const submitForm = () => {
        // if same description was entered as previous, add COPY prefix
        let cloneDescription = description;
        if (cloneDescription === descriptionOfPricingToClone) {
            cloneDescription = `[COPY] ${description}`;
        }
        setSubmitted(true);
        onClone(idOfPricingToClone, cloneDescription, minServiceFees);
    };

    // onChange handlers
    const onServiceFeesChange = (event: ChangeEvent<HTMLInputElement>) => {
        let minValue = parseFloat(event.target.min);
        let maxValue = parseFloat(event.target.max);
        let targetValue = parseFloat(event.target.value);

        // only validate and set new value if a nonNumeric character was not manually entered
        if (!isNaN(targetValue)) {

            // if value not in valid range, set invalid
            if (targetValue < minValue || targetValue > maxValue) {
                setServiceFeesInvalid(true);
            } // else if value is in range, but length greater than maxLength (too many decimal places), set invalid
            else if (event.target.value.length > event.target.maxLength) {
                setServiceFeesInvalid(true);
            }
            else {
                // value is valid
                setServiceFeesInvalid(false);
            }

            setIsFormDirty(true);
            setMinServiceFees(targetValue);
        }
        else if (event.target.value === '') {
            // if the user clears the value, set as valid to use existing value on server
            setServiceFeesInvalid(false);
            setMinServiceFees(undefined);
        }
    };
    const onDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsFormDirty(true);
        setDescription(event.target.value);
    };
           
    const serviceFeesProps = {
        'aria-label': 'min-service-fees',
        'maxLength': 9,
        'min': '0',
        'max': '999999.99',
        'step': '0.1',
        'precision': 2
    };
    const descriptionProps = {
        'aria-label': 'description',
        'maxLength': 255,
    };
    
    // form
    return (
        <AddEditDialog 
            isOpen={isOpen} 
            isSubmitted={submitted}
            id={idOfPricingToClone} 
            titlePrefix="Clone"
            entityName={descriptionOfPricingToClone ?? 'Pricing'}
            saveButtonText="CLONE" 
            onClose={onClose} 
            onSave={submitForm} 
            validate={isFormValid}
            onError={onError}
            error={error}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        itemID="dialog-clone-pricing-description"
                        fullWidth
                        autoFocus
                        required
                        disabled={submitted}
                        value={description}
                        label="Description for Cloned Pricing"
                        inputProps={descriptionProps}
                        onChange={onDescriptionChange}
                        autoComplete="off"
                        data-cy="dialog-clone-pricing-description"
                        variant="standard"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="number"
                        itemID="dialog-clone-pricing-serviceFees"
                        fullWidth
                        disabled={submitted}
                        value={minServiceFees}
                        label="Minimum Service Fees for Cloned Pricing"
                        inputProps={serviceFeesProps}
                        onChange={onServiceFeesChange}
                        autoComplete="off"
                        data-cy="dialog-clone-pricing-serviceFees"
                        variant="standard"
                        error={serviceFeesInvalid}
                        helperText={serviceFeesInvalid ? "Valid value 0 to 999,999.99" : "Leave blank to clone same value"}
                    />
                </Grid>
            </Grid>
        </AddEditDialog>
    );
};

export default ClonePricingDialog;