import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { UpsertCurrencyPayload, UpsertCurrencyInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
const MUTATION_UPSERT_CURRENCY = gql`
    mutation upsertCurrency($input: UpsertCurrencyInput!){
        upsertCurrency(input: $input){
            message
            errors {
                code
                message
            }
            result
            currency {
                currencyCode,
                description
            }
        }
    }
`;

export const mutationUpsertCurrency = async (
    client: ApolloClient<NormalizedCacheObject>,
    args: { upsertCurrencyData: UpsertCurrencyInput }
): Promise<UpsertCurrencyPayload | undefined> => {
    const { currencyCode, description } = args.upsertCurrencyData;
    const { data, errors } = await client.mutate<{ upsertCurrency: UpsertCurrencyPayload}>({
        variables: { input: { 
                currencyCode,
                description 
            } 
        },
        mutation: MUTATION_UPSERT_CURRENCY
    });
    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console
            console.error(error);
        });
        // Friendly error to active user
        throw new Error('An error has occurred during the save of currency.');
    }
    if (data && data.upsertCurrency) {
        return data.upsertCurrency;
    }

    return undefined;
};