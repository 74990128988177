import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { PricingModel, RequestResult } from '../../gql-types.generated';
import { selectError, clearError, selectUpsertPricingStatus, selectDeletePricingStatus, selectClonePricingStatus, captureUpsertPricingStatus, captureDeletePricingStatus, captureClonePricingStatus } from './PricingSlice';
import { upsertPricingData, deletePricingData, clonePricingData} from './PricingActions';
import { selectPricingList } from '../EDIContainer/EDIContainerSlice';
import { fetchPricingList } from '../EDIContainer/EDIContainerActions';
import { MainContentBox, CardListContentGrid, CardListScrollBox, PageTitleToolbarGrid, CardListItemWrapperGrid } from '../../util/SharedStyles';
import { useTitle } from '../../util/Common';
import { setToastConfig } from '../../features/EDIContainer/EDIContainerSlice';
import { Viewer, ToastSeverity } from '../../util/Constants';
import { viewerCanEdit } from '../../util/ViewerUtility';

import PageTitleBar from '../../components/PageTitleBar';
import PricingListItem from '../../components/listItems/PricingListItem';
import ErrorMessage from '../../components/ErrorMessage';
import NoRecordsMessage from '../../components/NoRecordsMessage';
import CreateNewButton from '../../components/buttons/CreateNewButton';
import PricingDialog from '../../components/dialogs/PricingDialog';
import DeleteDialog from '../../components/dialogs/DeleteDialog';
import ClonePricingDialog from '../../components/dialogs/ClonePricingDialog';

interface PricingsProps {
    viewer: Viewer | undefined;
}

const Pricings: React.FC<PricingsProps> = (props) => {
    const { viewer } = props;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openEdit, setOpenEdit] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openClone, setOpenClone] = useState(false);
    const [deleteErrorMessage, setDeleteErrorMessage] = useState('');
    const [selectedPricing, setSelectedPricing] = useState<undefined | PricingModel>(undefined);
    const [contentAreaHeight, setContentAreaHeight] = useState('auto');
    const deletePricingStatus = useAppSelector(selectDeletePricingStatus);
    const upsertPricingStatus = useAppSelector(selectUpsertPricingStatus);
    const clonePricingStatus = useAppSelector(selectClonePricingStatus);
    const error = useAppSelector(selectError);

    const canEdit = viewerCanEdit(viewer);

    useTitle("Pricing");

    useEffect(() => {
        if (upsertPricingStatus?.result === RequestResult.Success) {
            let wasAddMode = openAdd;

            // close the modify dialog
            onPricingDialogClose();

            // display success message
            dispatch(setToastConfig({
                message: upsertPricingStatus.message as string,
                severity: ToastSeverity.Success
            }));

            // remove upsert status
            dispatch(captureUpsertPricingStatus());
                       
            // if coming from adding new, and a pricingId was successfully returned, navigate 
            // to the new Pricing details to allow user to 
            // add prices right away
            if (wasAddMode && upsertPricingStatus.pricing?.id) {
                // simulate a card click to navigate
                cardClickAction(upsertPricingStatus.pricing.id);
            }
        }
    }, [upsertPricingStatus?.result]);

    useEffect(() => {
        if (deletePricingStatus?.result === RequestResult.Success) {
            // close the delete dialog
            onDeleteDialogClose();
            dispatch(setToastConfig({
                message: deletePricingStatus.message as string,
                severity: ToastSeverity.Success
            }));
        }
        else if (deletePricingStatus?.result === RequestResult.Fail) {
            setDeleteErrorMessage(deletePricingStatus.message as string);
        }
    }, [deletePricingStatus?.result]);

    useEffect(() => {
        if (clonePricingStatus?.result === RequestResult.Success) {
            // close the clone dialog
            onCloneDialogClose();

            // display success message
            dispatch(setToastConfig({
                message: clonePricingStatus.message as string,
                severity: ToastSeverity.Success
            }));

            // remove clone status
            dispatch(captureClonePricingStatus());

            // get the newly cloned pricing and open it right away for edit
            if (clonePricingStatus.pricing?.id) {
                // simulate a card click to navigate
                cardClickAction(clonePricingStatus.pricing.id);
            }
        }
    }, [clonePricingStatus?.result]);

    const pricings = useAppSelector(selectPricingList);

    useEffect(() => {
        // we have content, so lets properly size that content area        
        const listHeaderHeight = document.getElementById('pricing-title-comp')?.clientHeight || 0;
        if (listHeaderHeight)
            setContentAreaHeight(`calc(100% - ${listHeaderHeight}px)`);
    }, [pricings]);

    const cardClickAction = (id: string | undefined) => {
        if (id) {
            let route = "/pricingdetails/" + id;
            navigate(route);
        }
    };

    const getContent = () => {
        if (error) {
            return (
                <ErrorMessage title='Unable to load Pricing' error={error}></ErrorMessage>
            );
        }

        if (pricings && pricings.length === 0) {
            // No areas to show 
            return (canEdit ? <NoRecordsMessage actionButtonText="Add New Pricing" actionButtonClick={onAddPricingClick} />
                :
                <NoRecordsMessage message="" />
            );
        } else if (pricings && pricings.length) {
            return (
                pricings.map((pricing: PricingModel) => (
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={pricing.id}>
                        <PricingListItem
                            pricing={pricing}
                            onEditClick={editPricing}
                            onDeleteClick={deletePricing}
                            onCloneClick={clonePricing}
                            clickAction={cardClickAction}
                        />
                    </CardListItemWrapperGrid>
                ))
            );
        } else {
            // loading
            let loaders = [];
            for (let i = 0; i < 12; i++) {
                const key = 'pricing-skeleton-' + i;
                loaders.push(
                    <CardListItemWrapperGrid item xs={12} sm={6} md={4} lg={3} key={key}>
                        <PricingListItem loading={true} onEditClick={editPricing} onDeleteClick={deletePricing} onCloneClick={clonePricing} clickAction={cardClickAction}/>
                    </CardListItemWrapperGrid>
                )
            }
            return loaders;
        }
    };

    const onAddPricingClick = () => {
        // Clear error and open dialog
        dispatch(clearError());
        // ensure no previously selected pricing is set
        if (selectedPricing) {
            setSelectedPricing(undefined);
        }
        setOpenAdd(true);
    };

    const onPricingDialogClose = () => {
        setOpenEdit(false);
        setOpenAdd(false);
        onDialogClose();
    };

    const onCloneDialogClose = () => {
        setOpenClone(false);
        onDialogClose();
    };

    const onDeleteDialogClose = () => {
        setOpenDelete(false);
        onDialogClose();
        dispatch(captureDeletePricingStatus());
        setDeleteErrorMessage('');
    };

    const onDialogClose = () => {
        // Clear error and selectedPricing on close.
        dispatch(clearError());
        setSelectedPricing(undefined);
        // Refresh list to bring in potential updates
        dispatch(fetchPricingList());
    };

    const onPricingDialogSave = (description: string, currencyCode: string, minServiceFees: number, id?: string) => {
        // upsert to save data
        dispatch(upsertPricingData(description, currencyCode, minServiceFees, id));
    };

    const onCloneDialogSave = (pricingToCloneId: string, description: string, minServiceFees?: number) => {
        // clone data
        dispatch(clonePricingData(pricingToCloneId, description, minServiceFees));
    };

    const onDeleteDialogConfirm = (pricingId: string) => {
        // delete the selected pricing
        dispatch(deletePricingData(pricingId));
    };

    //edit selected pricing
    const editPricing = (pricingId: string | undefined) => {
        if (pricings) {
            if (pricingId && pricings.length) {
                let pricing = pricings.find(area => area.id === pricingId);
                if (pricing) {
                    setSelectedPricing(pricing);
                    dispatch(clearError());
                    setOpenEdit(true);
                }
            }
        }
    };

    //delete selected pricing
    const deletePricing = (pricingId: string | undefined) => {
        if (pricings) {
            if (pricingId && pricings.length) {
                let pricing = pricings.find(pr => pr.id === pricingId);
                if (pricing) {
                    setSelectedPricing(pricing);
                    dispatch(captureDeletePricingStatus());
                    setDeleteErrorMessage('');
                    setOpenDelete(true);
                }
            }
        }
    };

    //clone selected pricing
    const clonePricing = (pricingId: string | undefined) => {
        if (pricings) {
            if (pricingId && pricings.length) {
                let pricing = pricings.find(pr => pr.id === pricingId);
                if (pricing) {
                    setSelectedPricing(pricing);
                    dispatch(clearError());
                    setOpenClone(true);
                }
            }
        }
    };

    return (
        <MainContentBox>
            <PageTitleBar text='Pricing' id="pricing-title-comp">
                <PageTitleToolbarGrid item>
                    {canEdit &&
                        <CreateNewButton
                            text="New Pricing"
                            onClick={onAddPricingClick}
                            data-cy="add-new-pricing"
                        />}
                </PageTitleToolbarGrid>
            </PageTitleBar>
            <CardListScrollBox scrollheight={contentAreaHeight}>
                <CardListContentGrid container spacing={2}>
                    {getContent()}
                </CardListContentGrid>
            </CardListScrollBox>
            <PricingDialog
                isOpen={openEdit || openAdd}
                pricing={selectedPricing}
                onClose={onPricingDialogClose}
                onSave={onPricingDialogSave}
                error={error}
            />
            <DeleteDialog
                isOpen={openDelete}
                id={selectedPricing?.id}
                heading={'Delete Pricing'}
                message={'Are you sure you want to delete \'' + selectedPricing?.description + '\'?'}
                onConfirm={onDeleteDialogConfirm}
                onReject={onDeleteDialogClose}
                errorMessage={deleteErrorMessage}
            />
            <ClonePricingDialog
                isOpen={openClone}
                pricing={selectedPricing}
                onClose={onCloneDialogClose}
                onClone={onCloneDialogSave}
                error={error}
            />
        </MainContentBox>
    );
};

export default Pricings;