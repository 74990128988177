import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CrossReferenceModel, DeleteByIdPayload, PagingResultModelOfCrossReferenceModel, UpsertCrossReferencePayload } from "../../gql-types.generated";
import { RootState } from "../../store";



interface SliceState {
    error?: Error;
    crossReferences?: CrossReferenceModel[];
    crossReferencePagingResult?: PagingResultModelOfCrossReferenceModel;
    upsertCrossReferenceStatus?: UpsertCrossReferencePayload;
    deleteCrossReferenceStatus?: DeleteByIdPayload;
    crossReferenceRequestsInFlight: number;
};

const initialState: SliceState = {
    error: undefined,
    crossReferences: undefined,
    crossReferencePagingResult: undefined,
    upsertCrossReferenceStatus: undefined,
    deleteCrossReferenceStatus: undefined,
    crossReferenceRequestsInFlight: 0,
};

export const slice = createSlice({
    name: 'crossReferenceTable',
    initialState,
    reducers:{
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.crossReferences = undefined;
            // eslint-disable-next-line no-param-reassign
            state.crossReferencePagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertCrossReferenceStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deleteCrossReferenceStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.crossReferenceRequestsInFlight = 0;
        },

        fetchCrossReferenceTableSuccess: (state, action: PayloadAction<PagingResultModelOfCrossReferenceModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.crossReferencePagingResult = action.payload;
            // eslint-disable-next-line no-param-reassign
            let crossRefs = state.crossReferences;
            if (!crossRefs || !state.crossReferencePagingResult.cursor?.previousPage) {
                crossRefs = [];
            }
            if (action.payload.nodes) {
                action.payload.nodes.map(node => {
                    if (!crossRefs?.some(i => i.id === node.id)) {
                        crossRefs?.push(node as CrossReferenceModel);
                    }
                    return node;
                });
            }
            // eslint-disable-next-line no-param-reassign
            state.crossReferences = crossRefs;
        },

        incrementCrossReferenceRequestInFlight: state => {            
            // eslint-disable-next-line no-param-reassign
            state.crossReferenceRequestsInFlight += 1;
        },

        decrementCrossReferenceRequestsInFlight: state => {
            // eslint-disable-next-line no-param-reassign
            if (state.crossReferenceRequestsInFlight > 0) state.crossReferenceRequestsInFlight -= 1;
        },

        captureUpsertCrossReferenceStatus: (state, action: PayloadAction<UpsertCrossReferencePayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertCrossReferenceStatus = action.payload;
        },

        captureDeleteCrossReferenceStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deleteCrossReferenceStatus = action.payload;
        },
    }
});

export const selectError = (state: RootState): Error | undefined => state.crossReferenceTable.error;
export const selectRequestsInFlight = (state: RootState): number => state.crossReferenceTable.crossReferenceRequestsInFlight;
export const selectCrossReferences = (state: RootState): CrossReferenceModel[] | undefined => state.crossReferenceTable.crossReferences;
export const selectCrossReferencePagingResult = (state: RootState): PagingResultModelOfCrossReferenceModel | undefined => state.crossReferenceTable.crossReferencePagingResult;
export const selectUpsertCrossReferenceStatus = (state: RootState): UpsertCrossReferencePayload | undefined => state.crossReferenceTable.upsertCrossReferenceStatus;
export const selectDeleteCrossReferenceStatus = (state: RootState): DeleteByIdPayload | undefined => state.crossReferenceTable.deleteCrossReferenceStatus;

export const {
    clearError,
    clearState,
    fetchCrossReferenceTableSuccess,
    fetchError,
    incrementCrossReferenceRequestInFlight,
    decrementCrossReferenceRequestsInFlight,
    captureUpsertCrossReferenceStatus,
    captureDeleteCrossReferenceStatus
} = slice.actions;

export default slice.reducer;