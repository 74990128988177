
import { createTheme } from '@mui/material';
declare module '@mui/material/styles/createPalette' {
    interface PaletteOptions {
        grayscale?: PaletteColorOptions;
        toast?: PaletteColorOptions;
        toastError?: PaletteColorOptions;
        toastInfo?: PaletteColorOptions;
        toastSuccess?: PaletteColorOptions;
        toastWarning?: PaletteColorOptions;
        chip?: PaletteColorOptions;
        border?: PaletteColorOptions;
    }
    interface Palette {
        grayscale: PaletteColor;
        toast: PaletteColor;
        toastError: PaletteColor;
        toastInfo: PaletteColor;
        toastSuccess: PaletteColor;
        toastWarning: PaletteColor;
        chip: PaletteColor;
        border: PaletteColor;
    }
    interface TypeBackground {
        subbar: string;
        icon: string;
        selected: string;
    }
}
// A custom theme for this app
// https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=266ef2&secondary.color=2056BA
// Color Picking:https://material.io/design/color/the-color-system.html#tools-for-picking-colorshttps://material.io/design/color/the-color-system.html#tools-for-picking-colors
const theme = createTheme({
    palette: {
        // Switch mode to test theming
        mode: 'light',
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#266EF2'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#54B3BE',
            // dark: will be calculated from palette.primary.main,  
            contrastText: '#FFFFFF'
        },
        success: {
            light: '#34C759',
            main: '#1F7A3C'
            // dark: will be calculated from palette.primary.main,  
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        error: {
            main: '#FF3B30'
        },
        warning: {
            main: '#FF952A'
        },
        toast: {
            main: '#EAF9E0',
            contrastText: '#1F7A3C',
        },
        // Following toast palettes taken from Pay Style Guide
        toastError: {
            main: '#c70a00',
            light: '#FFEFEE',
        },
        toastInfo: {
            main: '#2056BA',
            light: '#EEF4FE',
        },
        toastSuccess: {
            main: '#1F7A3C',
            light: '#EAF9E0',
        },
        toastWarning: {
            main: '#C63E15',
            light: '#FFF7EE',
        },
        // End Pay Toast Palettes
        chip: {
            main: '#666666'
        },
        grayscale: {
            light: '#9E9E9E',
            main: '#757575',
            dark: '#666666',
        },
        border: {
            main: '#E6E6E6'
        },
        background: {
            default: '#F5F5F5',
            subbar: '#EBEBEB',
            icon: '#F2F2F2',
            selected: '#EEF4FE'
        },

    }
});

export default theme;