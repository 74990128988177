import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PricingModel, PricingItemModel, UpsertPricingPayload, UpsertPricingItemPayload, PagingResultModelOfPricingModel, DeleteByIdPayload, RequestResult} from '../../gql-types.generated';
import { RootState } from '../../store';

interface SliceState {
    error?: Error;
    pricingPagingResult?: PagingResultModelOfPricingModel;
    pricing?: PricingModel;
    upsertPricingStatus?: UpsertPricingPayload;
    deletePricingStatus?: DeleteByIdPayload;
    upsertPricingItemStatus?: UpsertPricingItemPayload;
    deletePricingItemStatus?: DeleteByIdPayload;
    pricingItems?: PricingItemModel[];
}

const initialState: SliceState = {
    error: undefined,
    pricingPagingResult: undefined,
    pricing: undefined,
    upsertPricingStatus: undefined,
    deletePricingStatus: undefined,
    upsertPricingItemStatus: undefined,
    deletePricingItemStatus: undefined,
    pricingItems: undefined,
};

export const slice = createSlice({
    name: 'pricingDetails',
    initialState,
    reducers: {
        fetchError: (state, action: PayloadAction<Error>) => {
            // eslint-disable-next-line no-param-reassign
            state.error = action.payload;
        },

        clearError: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
        },

        clearState: state => {
            // eslint-disable-next-line no-param-reassign
            state.error = undefined;
            // eslint-disable-next-line no-param-reassign
            state.pricingPagingResult = undefined;
            // eslint-disable-next-line no-param-reassign
            state.pricing = undefined;
            // eslint-disable-next-line no-param-reassign
            state.pricingItems = undefined;
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingStatus = undefined;
            // eslint-disable-next-line no-param-reassign
            state.deletePricingStatus = undefined;
        },

        fetchPricingByIdSuccess: (state, action: PayloadAction<PagingResultModelOfPricingModel>) => {
            // eslint-disable-next-line no-param-reassign
            state.pricingPagingResult = action.payload;
            
            if (action.payload.nodes && action.payload.nodes[0]) {
                let pricing = action.payload.nodes[0];
                if (pricing) {
                    // eslint-disable-next-line no-param-reassign
                    state.pricing = pricing;

                    if (pricing.pricingItems?.length) {
                        state.pricingItems = pricing.pricingItems as PricingItemModel[];
                    } else {
                        state.pricingItems = [];
                    }
                }
            }
        },

        captureUpsertPricingStatus: (state, action: PayloadAction<UpsertPricingPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingStatus = action.payload;
        },

        captureDeletePricingStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePricingStatus = action.payload;
        },

        captureUpsertPricingItemStatus: (state, action: PayloadAction<UpsertPricingItemPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.upsertPricingItemStatus = action.payload;
        },

        captureDeletePricingItemStatus: (state, action: PayloadAction<DeleteByIdPayload | undefined>) => {
            // eslint-disable-next-line no-param-reassign
            state.deletePricingItemStatus = action.payload;
        },

    }
});

export const selectError = (state: RootState): Error | undefined => state.pricingDetails.error;
export const selectPricingPagingResult = (state: RootState): PagingResultModelOfPricingModel | undefined => state.pricingDetails.pricingPagingResult;
export const selectPricing = (state: RootState): PricingModel | undefined => state.pricingDetails.pricing;
export const selectPricingItems = (state: RootState): PricingItemModel[] | undefined => state.pricingDetails.pricingItems;
export const selectUpsertPricingStatus = (state: RootState): UpsertPricingPayload | undefined => state.pricingDetails.upsertPricingStatus;
export const selectDeletePricingStatus = (state: RootState): DeleteByIdPayload | undefined => state.pricingDetails.deletePricingStatus;
export const selectUpsertPricingItemStatus = (state: RootState): UpsertPricingItemPayload | undefined => state.pricingDetails.upsertPricingItemStatus;
export const selectDeletePricingItemStatus = (state: RootState): DeleteByIdPayload | undefined => state.pricingDetails.deletePricingItemStatus;

export const {
    clearError,
    clearState,
    fetchError,
    fetchPricingByIdSuccess,
    captureUpsertPricingStatus,
    captureDeletePricingStatus,
    captureUpsertPricingItemStatus,
    captureDeletePricingItemStatus,
} = slice.actions;

export default slice.reducer;