import { gqlClient } from '../../components/AppProvider';
import { AppDispatch, AppThunk } from '../../store';
import { getErrorMessage } from '../../util/Common';
import { RequestResult, SystemNotificationModel, UpsertSystemNotificationInput } from '../../gql-types.generated';
import { fetchError, fetchNotificationListSuccess, captureUpsertNotificationStatus, fetchActiveNotificationSuccess } from './SystemNotificationsSlice';
import { querySystemNotifications } from '../../gql/QuerySystemNotificationList';
import { queryActiveSystemNotification } from '../../gql/QuerySystemNotificationForActive';
import { mutationUpsertSystemNotification } from '../../gql/MutationUpsertSystemNotification';

export const fetchSystemNotifications = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try { 
            const systemNotifications = await querySystemNotifications(gqlClient);
            if (systemNotifications) {
                dispatch(fetchNotificationListSuccess(systemNotifications as SystemNotificationModel[]));
            }
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const fetchActiveSystemNotification = () =>
    async (dispatch: AppDispatch): Promise<void> => {
        try { 
            const systemNotification = await queryActiveSystemNotification(gqlClient);
            dispatch(fetchActiveNotificationSuccess(systemNotification));
        } catch (e) {
            dispatch(fetchError(e as Error));
        }
    };

export const upsertSystemNotification = (upsertSystemNotificationData: UpsertSystemNotificationInput): AppThunk =>
async (dispatch: AppDispatch): Promise<void> => {
    try {
        const upsertStatus = await mutationUpsertSystemNotification(gqlClient, {
            upsertSystemNotificationData
        });
        if (upsertStatus) {
            if (upsertStatus.result === RequestResult.Fail) {
                dispatch(fetchError({ message: upsertStatus.message } as Error));
                getErrorMessage(upsertStatus.message as string, upsertStatus.errors, true, true);
            }
            dispatch(captureUpsertNotificationStatus(upsertStatus));
        }
    } catch (e) {
        dispatch(fetchError(e as Error));
    }
};