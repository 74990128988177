import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { PhoneTypesListQuery, GetPhoneTypeInput } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PHONE_TYPES_LIST = gql`
    query phoneTypesList($input: GetPhoneTypeInput!) {
        phoneTypes (input: $input) {
            nodes {
                phoneTypeCode
                description
            }
        }
    }
`;

export const queryPhoneTypeList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: {
        phoneTypeCode?: string | undefined;
    }
  ) => {
    const typeCode = filters?.phoneTypeCode;
    const { data, errors } = await client.query<PhoneTypesListQuery>({
      query: QUERY_PHONE_TYPES_LIST,
      variables: { 
        input: {
            phoneTypeCode: typeCode && typeCode.length > 0 ? typeCode : null
        }  
      }
    });
  
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        // Log error details in the console.
        console.error(error);
      });
      // Friendly error to the user.
      throw new Error('An error has occurred during the query.');
    }
  
    return data?.phoneTypes?.nodes;
  };