import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import gql from 'graphql-tag';
import { ConfigurationModel, GetConfigurationsInput, ProcessListQuery } from '../gql-types.generated';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const QUERY_PROCESS_LIST = gql`
    query processList($input: GetConfigurationsInput!) {
        configurations (input: $input) {
            nodes {
                description
                destPath
                ediProcess
                executionMode
                extension
                id
                p1
                p2
                p3
                p4
                p5
                p6
                tpgClient
                watcherPath
            }
        }
    }
`;

export const queryProcessList = async (
    client: ApolloClient<NormalizedCacheObject>,
    filters?: GetConfigurationsInput
): Promise<ConfigurationModel[] | undefined> => {
    const { id, pipelineId, processName, processDescription } = filters ?? {};
    const { data, errors } = await client.query<ProcessListQuery>({
        variables: {
            input: {
                id,
                pipelineId,
                processName,
                processDescription
            }
        },
        query: QUERY_PROCESS_LIST,
    });

    if (errors && errors.length > 0) {
        errors.forEach(error => {
            // Log error details in the console.
            console.error(error);
        });
        // Friendly error to the user.
        throw new Error('An error has occurred during the query.');
    }

    return data?.configurations?.nodes as ConfigurationModel[];
}