import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Grid } from "@mui/material";

import { selectProcessList } from "../../features/Processes/ProcessesSlice";
import { fetchProcessList } from "../../features/Processes/ProcessesActions";
import AddEditDialog from "./AddEditDialog";
import ProcessPickerList from "../lists/ProcessPickerList";
import { DialogDataGridWrapper } from "../../util/SharedStyles";
import { ConfigurationModel } from "../../gql-types.generated";

interface ProcessPickerDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (
        process: ConfigurationModel
    ) => void;
    error?: Error | undefined;
}

const ProcessPickerDialog: React.FC<ProcessPickerDialogProps> = props => {
    const { isOpen, onClose, onSave, error } = props;
    const dispatch = useAppDispatch();

    const [isFormDirty, setIsFormDirty] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [selectedProcess, setSelectedProcess] = useState<ConfigurationModel | undefined>();

    const processes = useAppSelector(selectProcessList);

    useEffect(() => {
        dispatch(fetchProcessList());
    }, [])

    const validation = () => {
        if (isFormDirty) {
            return true;
        }
        return false;
    }

    const closeEditDialog = () => {
        onClose();
    }

    const submitForm = () => {
        if (isFormDirty && selectedProcess) {
            setSubmitted(true);
            onSave(selectedProcess);
        }
        closeEditDialog();
    }

    const onError = () => {
        setSubmitted(false);
    }

    const onProcessSelected = (processId: string) => {
        if (processId && processes) {
            let process = processes.find(p => p.id === processId);
            if (process) {
                setIsFormDirty(true);
                setSelectedProcess(process);
            }
        }
    }

    return (
        <AddEditDialog
            isOpen={isOpen}
            isSubmitted={submitted}
            id="process-picker"
            entityName="Process Picker"
            maxWidth='lg'
            validate={validation}
            onClose={closeEditDialog}
            onSave={submitForm}
            onError={onError}
            error={error}
        >
            <DialogDataGridWrapper container>
                <Grid item xs={12}>
                    <ProcessPickerList
                        processes={processes}
                        selectedProcessId={selectedProcess?.id}
                        loading={!processes}
                        rowSelection={onProcessSelected}
                        error={error}
                    />
                </Grid>
            </DialogDataGridWrapper>
        </AddEditDialog>
    );
};

export default ProcessPickerDialog;